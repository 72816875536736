<template>
  <div>
    <v-row class="pb-4">
      <v-col md="2" class="mt-1" v-if="workpoints.length > 1">
        <template>
          <v-select
            style="width: 100%"
            v-model="workPoint"
            :items="workpoints"
            @change="
              () => {
                getAppointments();
                getWorkResources();
              }
            "
            :label="$t('scheduler.work_point')"
            item-text="name"
            item-value="id"
            menu-props="auto"
            hide-details
            outlined
            dense
            return-object
          ></v-select>
        </template>
      </v-col>
      <v-col md="2">
        <v-select
          v-model="viewType"
          :items="formattedCalendarViewTypes"
          :label="$t('scheduler.calendar_type')"
          item-text="text"
          item-value="id"
          outlined
          dense
          return-object
          class="pt-1"
          style="min-width: 150px !important; z-index: 99"
        >
        </v-select>
      </v-col>
      <v-col md="2" class="mt-1" v-if="filteredResourceTypes.length > 1">
        <v-select
          style="width: 100%"
          v-model="resourceType"
          :items="filteredResourceTypes"
          :label="$t('resource_type')"
          item-text="text"
          item-value="id"
          menu-props="auto"
          hide-details
          outlined
          dense
          return-object
        ></v-select>
      </v-col>
      <v-col>
        <div class="mt-2">
          <strong>
            <v-icon @click="setPrevious()" style="font-size: 24px"
              >mdi-chevron-left</v-icon
            >
            <v-icon right>mdi-calendar-month</v-icon>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="300px"
            >
              <template v-slot:activator="{ on, attrs }" #activator="">
                <span v-bind="attrs" v-on="on" style="color: #1976d2">
                  <span v-if="showDayPicker"
                    >{{ getDay(selectedDate) }}
                    {{ formatDate(selectedDate, "dd-mm-yyyy") }}
                  </span>
                  <span v-if="showWeekPicker">
                    {{
                      $t("measurement_units.week") +
                      " " +
                      getWeekNumber(selectedDate) +
                      ", " +
                      getMonthAndYearInText(selectedDate)
                    }}
                  </span>
                  <span v-if="showMonthPicker">
                    {{ getMonthAndYearInText(selectedDate) }}
                  </span>
                </span>
              </template>
              <v-date-picker
                v-if="viewType"
                id="datepicker"
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="selectedDate"
                :allowed-dates="allowedDates(checkNotAvailableDays())"
                elevation="2"
                no-title
                :locale="userLanguageId"
                full-width
                :events="functionEvents"
                :type="datePickerType"
                :picker-date.sync="pickerDate"
              >
              </v-date-picker>
            </v-menu>
            <v-icon @click="setNext()" style="font-size: 24px"
              >mdi-chevron-right</v-icon
            >
          </strong>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          class="mr-3 pt-1 to-upper"
          :label="$t('users.search')"
          prepend-inner-icon="mdi-magnify"
          v-bind:clearable="true"
          clear-icon="mdi-close"
          v-model="search"
          @input="updateLocalStorageValues()"
          @change="updateLocalStorageValues()"
          @keydown.enter.prevent="searchAppointmentOnEnter()"
          :outlined="false"
          :dense="false"
        ></v-text-field>
        <v-btn
          color="primary"
          class="mt-2"
          dark
          @click="searchAppointmentOnEnter()"
        >
          <v-icon dark>mdi-database-search</v-icon>
        </v-btn>
        <v-btn
          class="mt-2 ml-2"
          color="primary"
          @click="
            resetDate();
            resetSearchValue();
          "
        >
          {{ $t("scheduler.today") }}
          <v-icon dark right> mdi-calendar-today</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- <v-overlay :value="isLoadingInProgress">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
    </v-overlay> -->

    <br />
    <v-row :class="search && search.length > 0 ? 'inactive' : 'active'">
      <!-- <v-col cols="12" sm="2">
          <v-row>
              <v-col class="ml-2 pr-3">
                  <v-date-picker
                      :first-day-of-week="1"
                      :weekday-format="getDay"
                      v-model="selectedDate"
                      :allowed-dates="allowedDates(checkNotAvailableDays())"
                      elevation="2"
                      no-title
                      :locale="userLanguageId"
                      full-width
                      :events="functionEvents"
                      :picker-date.sync="pickerDate"
                  >
                  </v-date-picker>
              </v-col>
          </v-row>
          <v-row>
          </v-row>
      </v-col> -->
      <v-col
        id="dayview"
        class="pa-0"
        cols="12"
        sm="12"
        v-if="
          workpoints.length > 0 &&
          timeslots.length > 0 &&
          workresources.length > 0 &&
          viewType &&
          viewType.key === 'day'
        "
        style="min-height: 80vh; overflow: scroll !important"
      >
        <day-view
          v-if="
            workpoints.length > 0 &&
            timeslots.length > 0 &&
            workresources.length > 0 &&
            viewType.key === 'day' &&
            minimumTimeTask > 0
          "
          :workpoints.sync="workpoints"
          :worktype.sync="worktype"
          :timeslots.sync="timeslots"
          :workresources.sync="workresources"
          :appointments.sync="appointments"
          :selectedDate.sync="selectedDate"
          :minimumTimeTask.sync="minimumTimeTask"
          :resourceType.sync="resourceType"
          :readonlygrids="false"
        ></day-view>
      </v-col>

      <v-col
        v-if="viewType && (viewType.key === 'week' || viewType.key === 'month')"
      >
        <WeekView
          :selected-date="selectedDate"
          @selectedDateChanged="(val) => (selectedDate = val)"
          :workResources.sync="workresources"
          :calendarType="viewType.key.toLowerCase()"
          :workPoint.sync="workPoint"
          :resourceType.sync="resourceType"
          :timeSlots="workpointstimings"
          :minimum-work-duration.sync="minimumTimeTask"
        />
      </v-col>

      <!-- In case of selected date is Sunday then will set default work point id   -->
      <v-col
        id="dayview"
        class="pa-0"
        cols="12"
        sm="12"
        v-if="
          workpoints.length > 0 &&
          timeslots.length == 0 &&
          workresources.length > 0 &&
          new Date(selectedDate).getDay() == 0 &&
          viewType &&
          viewType.key === 'day' &&
          minimumTimeTask > 0
        "
      >
        <day-view
          :workpoints.sync="workpoints"
          :worktype.sync="worktype"
          :timeslots="[
            {
              id: 13,
              work_point_id: 0,
              dow: 0,
              start_time: '07:00:00',
              end_time: '20:30:00',
              is_excluded: 0,
            },
          ]"
          :workresources.sync="workresources"
          :appointments.sync="appointments"
          :selectedDate.sync="selectedDate"
          :minimumTimeTask.sync="minimumTimeTask"
          :resourceType.sync="resourceType"
          :readonlygrids="true"
        ></day-view>
      </v-col>

      <v-col
        cols="12"
        v-if="
          (workpoints.length == 0 ||
            (timeslots.length == 0 && new Date(selectedDate).getDay() != 0) ||
            workresources.length == 0) &&
          isLoadedCriticalThingsForDayView == true
        "
        sm="12"
        style="min-height: 80vh"
      >
        <template>
          <div style="position: relative; top: 30vh; left: 15vw">
            {{
              $t(
                "scheduler.text_messages.work_points_or_work_resources_or_work_time_slots_not_defined_for_company"
              )
            }}
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row
      v-if="resetDataTableComp"
      :class="search && search.length > 0 ? 'active' : 'inactive'"
      class="mt-n9"
    >
      <v-data-table
        fixed-header
        :footer-props="{
          'items-per-page-options': dataTableOptions,
        }"
        :options="options"
        :server-items-length="totalItems"
        :loading-text="this.$t('loading')"
        :headers="searchHeaders"
        :items="searchData"
        :items-per-page="5"
        class="elevation-0"
        :height="'calc(-210px + 100vh)'"
        style="width: 100%"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-by="updateSort"
        @update:sort-desc="updateSortOrder"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        :key="forceReloadComponentKey"
      >
        <template v-slot:item="{ item }">
          <tr
            :style="addTaskStyle(item)"
            :class="addClass(item)"
            @click="editScheduler(item)"
          >
            <td>
              <v-icon
                color="white"
                style="font-size: 20px"
                v-if="
                  item &&
                  item.appointment_status &&
                  item.appointment_status.key == 'appointment_is_executed'
                "
              >
                mdi-account-check
              </v-icon>
              {{ item.work_type.name }}
            </td>
            <td>
              {{ formatDateTime(item.start_time ?? null)  }}
            </td>
            <td>
              {{ item.client.name }}
              {{ item.client.first_name ? item.client.first_name : "" }}
            </td>
            <!-- Registration Number -->
            <td>
              <template v-if="item.vehicle">
                {{ item.vehicle.registration_number }}
              </template>
            </td>
            <!-- Payment -->
            <td>
              <template v-if="item.payments">
                {{ generatePaymentText(item.payments) }}
              </template>
            </td>
            <!-- Article Suppliers -->
            <td>
              <span v-if="calculateSuppliersPresent(item)">
                {{ calculateSuppliersPresent(item) }}
              </span>
              <span v-else>
                <!-- {{
                  $t(
                    "scheduler.text_messages.all_articles_from_internal_or_received"
                  )
                }} -->
                  INT
              </span>
            </td>
            <!-- Emails -->
            <td>
              <template v-if="item && item.email_to">
                <v-icon
                  color="white"
                  :style="{
                    fontSize: '15px',
                    paddingBottom: '2px',
                  }"
                >
                  mdi-check
                </v-icon>
              </template>
            </td>
            <!-- Document Type -->
            <td>
              <template v-if="item">
                {{ getOrderTypeById(item.order_type_id)?.text }}
              </template>
            </td>
            <!-- Appointment Status -->
            <td>
              <template v-if="item">
                {{
                  $t(
                    "schedulestab.appointment_status." +
                      item.appointment_status.key
                  )
                }}
              </template>
            </td>
            <!-- Current KM -->
            <td>
              <template v-if="item.current_km && item.current_km > 0">
                {{ item.current_km }}
              </template>
            </td>
            <!-- VIN -->
            <!-- <td>
              <template v-if="item.vehicle">
                {{ item.vehicle.vin }}
              </template>
            </td> -->
            <!-- Created At -->
            <td>
              {{ formatDateTime(item.created_at) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-row>
    <v-btn
      @click="createCalendar()"
      class="d-none"
      id="create-calendar"
    ></v-btn>
    <v-btn
      @click="printReportFromTopButton()"
      class="d-none"
      id="print-report"
    ></v-btn>
    <span
      id="pickup-address-map"
      @click="
        () => {
          pickupAddressMapDialog = false;
          $nextTick(() => (pickupAddressMapDialog = true));
        }
      "
      >..<PickupAddressMap
        v-if="pickupAddressMapDialog"
        v-model="pickupAddressMapDialog"
        :appointments="appointments"
        :selectedDate="selectedDate"
        :resourceType="resourceType"
        :workPoint="workPoint"
    /></span>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { SCHEDULER_LISTING_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import DayView from "@/components/Calendar/DayView.vue";
import WeekView from "@/components/Calendar/WeekView.vue";
import moment from "moment";
import PickupAddressMap from "@/components/Calendar/PickupAddressMap.vue";

export default {
  name: "SchedulerListing",
  components: {
    WeekView,
    "day-view": DayView,
    PickupAddressMap,
  },
  async mounted() {
    await this.getCalendarViewTypes();
    this.$store.commit("setCopiedAppointment", null);

    this.viewType = this.calendarViewTypes[0];
    this.worktype = 0;
    this.autocompleteValueLocal = this.autocompleteValue();
    if (this.resourceTypes.length > 0) {
      this.resourceType = this.resourceTypes[0];
    }
    this.applyPreviousFilters();
  },
  activated() {
    this.pickupAddressMapDialog = false;
    if (this.$store.state.refreshList) {
      this.search = null;
      this.$store.commit("setRefreshList", false);
    }
    this.search = null;
    // check if we have appointmentUpdate in store
    if (this.$store.state.appointmentUpdated) {
      let newAppointments = this.$store.state.appointmentUpdated;
      this.selectedDate = moment(newAppointments.start_time).format(
        "YYYY-MM-DD"
      );
      this.pickerDate = this.selectedDate;
      this.$store.state.appointmentUpdated = null;
    } else {
      this.selectedDate = this.$store.state.schedulerDate
        ? this.$store.state.schedulerDate.substr(0, 10)
        : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10);
      this.pickerDate = this.$store.state.schedulerDate
        ? this.$store.state.schedulerDate.substr(0, 8)
        : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 8);
    }

    this.$store.commit("setFormMode", SCHEDULER_LISTING_MODE);
    if (
      this.workpoints.length == 0 ||
      this.timeslots.length == 0 ||
      this.workresources.length == 0
    ) {
      this.isLoadedCriticalThingsForDayView = false;
      this.getWorkTypes().then(() => {
        this.getWorkPoints().then(() => {
          this.getWorkResources().then(() => {
            this.getTimeSlotsForWorkPoints().then(() => {
              // this.getAppointments().then(() => {
              this.isLoadedCriticalThingsForDayView = true;
              // });
            });
          });
          this.getWorkingDaysForThePoints();
        });
      });
    } else {
      this.getAppointments().then(() => {
        this.isLoadedCriticalThingsForDayView = true;
      });
      this.getMonthlyAppointments();
    }

    this.applyPreviousFilters();
    this.appointmentInterval = setInterval(() => {
      this.getAppointments();
    }, 60000);
    this.storeInterval(this.appointmentInterval);
  },
  deactivated() {
    if (this.appointmentInterval) {
      clearInterval(this.appointmentInterval);
      this.appointmentInterval = null;
    }
  },
  beforeDestroy() {
    if (this.appointmentInterval) {
      clearInterval(this.appointmentInterval);
      this.appointmentInterval = null;
    }
  },
  data() {
    return {
      pickupAddressMapDialog: false,
      resetDataTableComp: true,
      appointmentInterval: null,
      resourceType: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      token: this.$store.state.AccessToken,
      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      selectedDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      autocompleteValueLocal: null,
      viewType: null,
      viewTypes: [
        {
          id: 1,
          text: this.$t("measurement_units.day"),
        },
        {
          id: 2,
          text: this.$t("measurement_units.week"),
        },
        {
          id: 3,
          text: this.$t("measurement_units.month"),
        },
      ],
      worktype: null,
      worktypes: [],
      workPoint: null,
      workpoints: [],
      workpointstimings: [],
      workresources: [],
      storedworkresources: [],
      appointments: [],
      timeslots: [],
      search: null,
      minimumTimeTask: 1440,
      isLoadedCriticalThingsForDayView: false,
      isDebounceCancelled: false,
      monthlyappointments: [],
      pickerDate: null,
      searchData: [],
      menu: false,
      monthPickerMenu: false,
      date: null,
      isLoading: false,
      noofcolumns: 0,
    };
  },
  methods: {
    calculateSuppliersPresent(appointment) {
      const supplierNames = appointment.supplier_orders
        .map((element) => {
          const supplier = this.allSuppliers.find(
            (supplier) => supplier.id === element.supplier_id
          );
          return supplier ? supplier.name : "";
        })
        .filter((name) => name !== "");

      return supplierNames.join(", ");
    },
    searchAppointmentOnEnter() {
      this.resetDataTableComp = false;
      // this.resetPage();
      if (this.search) {
        this.updatePagination(1);
        // this.searchAppointment(this.search);
      } else {
        this.searchData = [];
      }
      this.$nextTick(() => {
        this.resetDataTableComp = true;
      });
    },
    resetSearchValue() {
      this.search = null;
      this.updateLocalStorageValues();
    },
    applyPreviousFilters() {
      let listingFilters = JSON.parse(
        localStorage.getItem("SCHEDULER_MODEL_FILTERS")
      );
      if (listingFilters != null) {
        this.search = listingFilters.search;
      }
    },
    updateLocalStorageValues() {
      let filters = {
        search: this.search,
      };
      localStorage.setItem("SCHEDULER_MODEL_FILTERS", JSON.stringify(filters));
    },
    editScheduler(item) {
      this.$store.commit("setAppointmentRedirectData", {
        id: item.id,
        resourceType: this.resourceType,
      });
      this.$router.push("/scheduler/edit").catch(() => {});
    },
    updatePagination(page) {
      this.page = page;
      this.searchAppointment(this.search);
    },
    updateItemsPerPage(itemsPerPage) {
      itemsPerPage == -1
        ? (this.itemsPerPage = this.totalItems)
        : (this.itemsPerPage = itemsPerPage);
      this.searchAppointment(this.search);
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
    },
    addClass(item) {
      let classToBeAdded = this.isBill(item) ? "bill" : "";
      if (
        item &&
        item.appointment_status &&
        item.appointment_status.key == "appointment_not_executed"
      ) {
        classToBeAdded += " not-executed";
      }
      return classToBeAdded;
    },
    isBill(item) {
      return item ? (item.order_type_id == 10241002 ? true : false) : false;
    },
    addTaskStyle(item) {
      let style = {
        "background-color": `#${item.color}`,
        color: "white",
        cursor: "pointer",
      };

      return style;
    },
    setPrevious() {
      if (this.viewType?.key === "day") {
        this.setPreviousDate();
      } else if (this.viewType?.key === "week") {
        this.setPreviousWeek();
      } else if (this.viewType?.key === "month") {
        this.setPreviousMonth();
      }
    },
    setNext() {
      if (this.viewType?.key === "day") {
        this.setNextDate();
      } else if (this.viewType?.key === "week") {
        this.setNextWeek();
      } else if (this.viewType?.key === "month") {
        this.setNextMonth();
      }
    },
    setPreviousDate() {
      let today = new Date(this.selectedDate);
      let previousDay = new Date(today);
      previousDay.setDate(today.getDate() - 1);
      this.selectedDate = this.getDatePartFromDateTimeStr(
        previousDay.toISOString()
      );
    },
    setNextDate() {
      let today = new Date(this.selectedDate);
      let nextDay = new Date(today);
      nextDay.setDate(today.getDate() + 1);
      this.selectedDate = this.getDatePartFromDateTimeStr(
        nextDay.toISOString()
      );
    },
    setNextWeek() {
      let today = new Date(this.selectedDate);
      let nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 7);
      this.selectedDate = this.getDatePartFromDateTimeStr(
        nextWeek.toISOString()
      );
    },
    setPreviousWeek() {
      let today = new Date(this.selectedDate);
      let previousWeek = new Date(today);
      previousWeek.setDate(today.getDate() - 7);
      this.selectedDate = this.getDatePartFromDateTimeStr(
        previousWeek.toISOString()
      );
    },
    setPreviousMonth() {
      let today = new Date(this.selectedDate);
      let previousMonth = new Date(today);
      previousMonth.setMonth(today.getMonth() - 1);
      this.selectedDate = this.getDatePartFromDateTimeStr(
        previousMonth.toISOString()
      );
    },
    setNextMonth() {
      let today = new Date(this.selectedDate);
      let nextMonth = new Date(today);
      nextMonth.setMonth(today.getMonth() + 1);
      this.selectedDate = this.getDatePartFromDateTimeStr(
        nextMonth.toISOString()
      );
    },
    printReportFromTopButton() {
      axios
        .get(
          API_BASE_URL + "/report/1?requested_out_for=" + this.selectedDate,
          {
            headers: this.header,
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "depot-report-" + this.selectedDate + ".csv"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {});
    },
    functionEvents(date) {
      const [, , day] = date.split("-");
      for (let [key, value] of Object.entries(this.monthlyappointments)) {
        if (value["day"] == parseInt(day, 10)) {
          console.log("key", key);
          return ["black"];
        }
      }
      return false;
      // let uniqueColorCodes = this.getUniqueColorCodesFromCurrentDayAppointments(this.monthlyappointments[0]);
    },
    // debounceInput: _.debounce(function () {
    //   if (this.isDebounceCancelled) {
    //     this.isDebounceCancelled = false;
    //     return false;
    //   }
    // }, 500),
    allowedDates: function (a) {
      return (val) => !a.includes(new Date(val).getDay());
    },
    resetDate() {
      this.selectedDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.pickerDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 8);
    },
    deleteItem() {},
    async getAppointments() {
      return new Promise((resolve, reject) => {
        if (this.viewType.key !== "day") {
          this.appointments = [];
          return resolve();
        }

        if (this.resourceType) {
          let url =
            API_BASE_URL +
            "/appointments?relation_data=true&date=" +
            this.selectedDate +
            "&resource_type_id=" +
            this.resourceType.id;
          if (this.workPoint) {
            url += "&work_point_id=" + this.workPoint.id;
          }

          axios
            .get(url, { headers: this.header })
            .then(({ data }) => {
              this.appointments = data.data;
              if (this.appointments.some((i) => i.pickup_address_id)) {
                this.$store.state.showPickupAddressMapButton = true;
              } else {
                this.$store.state.showPickupAddressMapButton = false;
              }

              resolve();
            })
            .catch(function () {
              reject();
            })
            .finally(() => {});
        } else {
          resolve();
        }
      });
    },
    getMonthlyAppointments() {
      return new Promise((resolve, reject) => {
        if (!this.isset(this.resourceType) || !this.isset(this.workPoint)) {
          return resolve();
        }
        if (!this.pickerDate.split("-")[1]) {
          return resolve();
        }
        axios
          .get(
            API_BASE_URL +
              "/appointments/count?resource_type_id=" +
              (this.resourceType?.id || "") +
              "&work_point_id=" +
              (this.workPoint?.id || "") +
              "&month=" +
              parseInt(this.pickerDate.split("-")[1], 10) +
              "&year=" +
              parseInt(this.pickerDate.split("-")[0], 10),
            { headers: this.header }
          )
          .then(({ data }) => {
            this.monthlyappointments = [];
            data.data.forEach((element) => {
              this.monthlyappointments.push(element);
            });
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {});
      });
    },
    getWorkTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/work_types", { headers: this.header })
          .then(({ data }) => {
            data.data.forEach((worktype) => {
              if (
                parseInt(worktype.work_duration) <
                parseInt(this.minimumTimeTask)
              ) {
                this.minimumTimeTask = parseInt(worktype.work_duration);
              }
            });
            this.worktypes = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {});
      });
    },
    getWorkResources() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_BASE_URL + "/work_resources?work_point_id=" + this.workPoint.id,
            { headers: this.header }
          )
          .then(({ data }) => {
            // this.workresources = data.data;
            this.storedworkresources = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {});
      });
    },
    getWorkPoints() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/work_points", {
            headers: this.header,
          })
          .then(({ data }) => {
            this.workpoints = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {});
      });
    },
    getWorkingDaysForThePoints() {
      if (this.workpoints.length > 0) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              API_BASE_URL +
                "/work_points/timings?work_point_id=" +
                this.workPoint.id,
              { headers: this.header }
            )
            .then(({ data }) => {
              this.workpointstimings = data.data;
              resolve();
            })
            .catch(function () {
              reject();
            })
            .finally(() => {});
        });
      }
    },
    checkNotAvailableDays() {
      let doweeks = [0, 0, 0, 0, 0, 0, 0];
      let notAvailableWeekDay = [];
      if (this.workpointstimings && this.workpointstimings.length > 0) {
        this.workpointstimings.forEach((timing) => {
          doweeks[timing.dow]++;
        });

        doweeks.forEach((dow, index) => {
          if (dow == 0) {
            notAvailableWeekDay.push(index);
          }
        });
      }
      return notAvailableWeekDay;
    },
    getTimeSlotsForWorkPoints() {
      return new Promise((resolve, reject) => {
        // if(this.viewType?.text !== 'Day'){
        //     resolve();
        // }
        axios
          .get(
            API_BASE_URL +
              "/work_points/timings?date=" +
              this.selectedDate +
              "&work_point_id=" +
              this.workPoint.id,
            { headers: this.header }
          )
          .then(({ data }) => {
            this.timeslots = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {
            this.$nextTick(() => {
              let width = this.getDayViewWidth() / this.workresources.length;
              this.setTableThTdWidth(width);
            });
          });
      });
    },
    searchAppointment(searchVal) {
      const payload = {
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortOrd,
        search: searchVal ?? null,
      };
      axios
        .get(API_BASE_URL + "/appointments?relation_data=true&paginate=true", {
          headers: this.header,
          params: payload,
        })
        .then((data) => {
          this.searchData = data.data.data.data;
          this.lastPage = data.data.data.last_page;
          this.page = data.data.data.current_page;
          this.totalItems = parseInt(data.data.data.total);
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        });
    },
    setWorkResources() {
      this.workresources = this.storedworkresources.filter((element) => {
        if (
          this.resourceType &&
          element.resource_type_id == this.resourceType.id
        ) {
          return true;
        }
        return false;
      });
      this.noofcolumns = this.workresources.length;
    },
    resetPage() {
      this.page = 1;
      this.options["page"] = 1;
    },
  },
  computed: {
    allSuppliers() {
      return this.$store.state.allSuppliers ?? [];
    },
    datePickerType() {
      return this.viewType?.key === "month" ? "month" : "date";
    },
    showDayPicker() {
      return ["day"].includes(this.viewType?.key);
    },
    showWeekPicker() {
      return ["week"].includes(this.viewType?.key);
    },
    showMonthPicker() {
      return ["month"].includes(this.viewType?.key);
    },
    filteredResourceTypes() {
      // Return resource types with a unique "key" property
      return this.resourceTypes.filter(
        (resourceType, index, self) =>
          index === self.findIndex((t) => t.key === resourceType.key)
      );
    },
    formattedCalendarViewTypes() {
      return this.calendarViewTypes.map((item) => {
        item.text = this.$t("measurement_units." + item.key);
        return item;
      });
    },
    invertNavDrawerVisibility() {
      return this.$store.state.showNavDrawer;
    },
    searchHeaders() {
      return [
        {
          text: this.$t("scheduler.work"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("scheduler.datetime"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("scheduler.client_name"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("scheduler.registration_number"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("billings.payment"),
          value: "payment",
          sortable: false,
        },
        {
          text: this.$t("billings.article_suppliers"),
          value: "article_suppliers",
          sortable: false,
        },
        {
          text: this.$t("billings.email"),
          value: "email",
          sortable: false,
        },
        {
          text: this.$t("billings.document_type"),
          value: "document_type",
          sortable: false,
        },
        {
          text: this.$t("billings.appointment_status"),
          value: "appointment_status",
          sortable: false,
        },
        {
          text: this.$t("billings.km"),
          value: "km",
          sortable: false,
        },
        // {
        //   text: "VIN",
        //   value: "vin",
        //   sortable: false,
        // },
        {
          text: this.$t("scheduler.created_at"),
          value: "id",
          sortable: false,
        },
        // { text: this.$t('scheduler.arrived_at'), value: 'id', sortable: false },
      ];
    },
  },
  watch: {
    viewType(val) {
      if (!val) {
        return;
      }

      if (val.key === "day") {
        this.getAppointments();
      }

      let workResource = this.storedworkresources.find(
        (item) => item.calendar_type_id === val.id
      );

      if (workResource) {
        this.resourceType = this.resourceTypes.find(
          (item) => item.id === workResource.resource_type_id
        );
      } else {
        if (val.key === "month" || val.key === "week") {
          let inverseKey = val.key === "month" ? "week" : "month";
          let workResource = this.storedworkresources.find(
            (item) =>
              item.calendar_type_id ===
              this.calendarViewTypes.find((vt) => vt.key === inverseKey).id
          );
          if (workResource) {
            this.resourceType = this.resourceTypes.find(
              (item) => item.id === workResource.resource_type_id
            );
          }
        }
      }

      this.getMonthlyAppointments();
    },
    invertNavDrawerVisibility() {
      this.$nextTick(() => {
        let width = this.getDayViewWidth() / this.workresources.length;
        this.setTableThTdWidth(width);
      });
    },
    resourceTypes() {
      this.resourceType = this.resourceTypes[0];
    },
    resourceType(val) {
      if (val) {
        this.minimumTimeTask = 0;
        this.appointments = [];
        this.setWorkResources();
        this.getAppointments();
        this.getMonthlyAppointments();

        this.$nextTick(() => {
          let width = this.getDayViewWidth() / this.workresources.length;
          this.setTableThTdWidth(width);
        });
        // Set Default Calendar View Type for Resource Type
        if (this.workresources.length > 0) {
          let workResource = this.workresources.find(
            (item) => item.resource_type_id === val.id
          );
          let calendarViewType = this.calendarViewTypes.find(
            (item) => item.id === workResource.calendar_type_id
          );
          if (calendarViewType !== undefined) {
            this.viewType = calendarViewType;
          }
        }
        this.$store.commit("setAppointmentRedirectData", {
          resourceType: this.resourceType,
        });
        // console.log("resource type", val);
        this.$nextTick(() => {
          let maxTime = 0;
          let minTime = 240;

          this.worktypes.forEach((workType) => {
            (workType.resource_work_types ?? []).forEach((element) => {
              let workResource = this.workresources.find(
                (item) => item.id == element.work_resource_id
              );
              if (workResource?.resource_type_id == val.id) {
                if (workType.work_duration >= maxTime) {
                  maxTime = workType.work_duration;
                }
                if (workType.work_duration <= minTime) {
                  minTime = workType.work_duration;
                }
              }
            });
          });
          // this.minimumTimeTask = parseInt(maxTime);
          this.minimumTimeTask = parseInt(minTime);
        });
      }
    },
    storedworkresources(val) {
      if (this.resourceType) {
        this.setWorkResources();
      }
      if (val && val.length > 0) {
        let tempresourceTypes = [];
        val.forEach((element) => {
          if (element.resource_type_id && element.resource_type) {
            tempresourceTypes.push(element.resource_type);
          }
        });
        this.$store.commit(
          "setResourceTypes",
          this.translateKeys(tempresourceTypes, "key", "resource_types")
        );
      }
    },
    selectedDate(val) {
      if (val && val.split("-").length === 2) {
        this.pickerDate = val;
        this.selectedDate = val + "-01";
      }

      this.appointments = [];
      this.getAppointments();
      this.getTimeSlotsForWorkPoints();
    },
    pickerDate() {
      this.getMonthlyAppointments();
    },
    workpoints(val) {
      if (!val) {
        return;
      }

      this.workPoint = val[0];
    },
    workPoint(val) {
      if (!val) {
        return;
      }

      this.getMonthlyAppointments();
      this.getWorkingDaysForThePoints();
      this.getTimeSlotsForWorkPoints();
      //
    },
  },
};
</script>

<style scoped>
.articlenotset {
  display: flex !important;
}

.articleset {
  display: none !important;
}

.selectedArticleTextBox {
  background-color: rgb(220, 255, 220);
}

.calendar-heading {
  font-size: 20px;
}

.inactive {
  display: none;
}

.active {
  display: block;
}

#datepicker {
  padding-bottom: 12px !important;
}

.mr-20 {
  margin-right: 90px !important;
}
</style>
