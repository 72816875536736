import { mapActions, mapState } from "vuex";
import moment from "moment";
import {
  API_BASE_URL,
  COLOR_BEST_SUPPLIER_PRICE,
  COLOR_LONG_SUPPLIER_DELIVERY_DATE,
  COLOR_SHORT_SUPPLIER_DELIVERY_DATE,
} from "@/config";
import axios from "@/plugins/axios";

let apiCache = {}; // This will act as a global cache shared by all components
let cacheTimestamps = {};

export default {
  data() {
    return {
      timerId: null,
      defaultMuToSelect: {
        id: 10311000,
        class_id: 1031,
        key: "piece",
        value: "pc",
        order: 1,
      },
      multiselectsValues: [],
      multiselects: [],
      isVerifiedTVA: null,
      isVerifiedTVALoading: false,
      calendarViewTypes: [],
      classCodes: {},
      expeditionStatuses: [],
      expeditionExchangeContainersTypes: [],
      expeditionEquipmentTypes: [],
      expeditionLoadSecuringTypes: [],
      expeditionCertificatesTypes: [],
      merchandiseTypes: [],
      memberTypes: [],
      company_bank_attachments: [],
      defaultPdfEmailConfig: {
        billing: {
          players: ["client", "driver"],
          documents: [
            "estimation",
            "purchase_order",
            "bill",
            "credit_note",
            "depot_report",
            "worksheet",
            "work_report",
            "expedition_report",
          ],
        },
        expedition: {
          players: ["expeditor", "transporter"],
          documents: ["expedition_report"],
        },
      },
      years: [],
      dynamicModules: {},
      validationFieldValues: {
        title: "Title",
        name: "Name",
        email: "Email",
        tva: "TVA",
        currency: "Currency",
        language: "Language",
        familyClient: "Family Client",
        memberType: "Member Type",
        address: "Address",
        denomination: "Denomination",
        password: "Password",
        confirmPassword: "Confirm Password",
        parent_familyClient: "Family Client",
        red_percentage: "Reduction (%)",
        reference_client: "Reference Client",
        note: "Note",
        selectedRecurrence: "Selected Recurrence",
        start_at: "Start At",
        run_day: "Run Day",
        bank_account: "Bank Account",
        bank_name: "Bank Name",
        swift_code: "Swift Code",
        peppol_endpoint_id: "Peppol End Point",
        peppol_scheme_id: "Peppol Scheme ID",
        company_client_name: "Client Name",
        company_client_gsm: "Client GSM",
        company_client_phone: "Client Phone",
        company_client_gsm_2: "GSM",
        company_client_phone_2: "Phone",
        company_client_gsm_3: "GSM",
        company_client_phone_3: "Phone",
        company_client_email: "Email",
        company_client_email_2: "Email",
        company_client_email_3: "Email",
        parent_registration_no: "Registration Number",
        zipcode: "Zip Code",
        city: "City",
        country: "Country",
        driver_tva: "Driver TVA",
        percent: "Percent",
        quantity: "Quantity",
        dot_property: "Dot Property",
        selected_article: "Selected Article",
        ordered_quantity: "Ordered Quantity",
        supplier: "Supplier",
        rec_status: "Reception Status",
        counter: "Counter",
        property: "Property",
        front_left: "Front Left",
        front_right: "Front Right",
        back_left: "Back Left",
        back_right: "Back Right",
        front_left_2: "Front Left",
        front_right_2: "Front Right",
        back_left_2: "Back Left",
        back_right_2: "Back Right",
        frontParentSearchSelectedArticleId: "Selected Article",
        backParentSearchSelectedArticleId: "Selected Article",
        atLeastOneShouldBeActive: "At least one should be Active",
        default_quantity: "Default Quantity",
        quantity_decimals: "Quantity Decimals",
        mu: "Measurement Units",
        ruleType: "Rule Type",
        startDate: "Start Date",
        condition: "Condition",
        amount: "Amount",
        document_type: "Document Type",
        recurring_name: "Recurring Name",
        resource_name: "Resource Name",
        resource_description: "Resource Description",
        work_resource: "Work resource",
        work_type: "Work Type",
        supplierStatus: "Supplier Status",
        familyArticle: "Family Article",
        registrationNo: "Registration Number",
        registration_no: "Registration Number",
      },
    };
  },
  created() {
    if (this?.page == undefined) {
      this.page = 1;
    }
    this.computeYears();
  },
  computed: {
    ...mapState(["forceReloadComponentKey"]),
    previewUrl() {
      return this.$store?.state?.previewUrl;
    },
    allowEditGlobal() {
      let allowEditGlobal = this.getCompanyPropertyFromLocalStorage(
        "ALLOW_EDIT_GLOBAL_ARTICLES"
      );
      if (allowEditGlobal && allowEditGlobal.value == "0") {
        return false;
      } else if (allowEditGlobal && allowEditGlobal.value == "1") {
        return true;
      } else {
        return false;
      }
    },
    showGridTotal() {
      // return false;
      return this.checkUserTag("show_grid_total");
    },
    userRole() {
      let userData = JSON.parse(localStorage.getItem("data"));
      return userData?.userDetails?.role;
    },
    isAdmin() {
      let userRole = JSON.parse(localStorage.getItem("data")).userDetails.role;

      if (userRole.name === "admin") {
        return true;
      }
      return false;
    },
    currentUser() {
      let userData = JSON.parse(localStorage.getItem("data"));
      return userData?.userDetails;
    },
    userCountry() {
      return localStorage.getItem("userCountry");
    },
    userCompany() {
      let userData = JSON.parse(localStorage.getItem("data"));
      return userData?.userDetails?.company;
    },
    requestInProgress() {
      return this.$store.state.progressBarLoading;
    },
    defaultCurrencyId() {
      return localStorage.getItem("currencyId") || "EUR";
    },
    attachmentTypes() {
      return [
        {
          id: 1,
          key: "cmr_lv",
          icon: "mdi-file-document-outline",
          text: this.$t("attachment.types.cmr_lv"),
        },
        {
          id: 11,
          key: "cmr_bl",
          icon: "mdi-file-document-outline",
          text: this.$t("attachment.types.cmr_bl"),
        },
        {
          id: 2,
          key: "delivery_note",
          icon: "mdi-package-variant-closed",
          text: this.$t("attachment.types.delivery_note"),
        },
        {
          id: 3,
          key: "order_of_transport",
          icon: "mdi-text-box-outline",
          text: this.$t("attachment.types.order_of_transport"),
        },
        {
          id: 4,
          key: "weighing_slip",
          icon: "mdi-weight",
          text: this.$t("attachment.types.weighing_slip"),
        },
        {
          id: 5,
          key: "bill",
          icon: "mdi-file-document",
          text: this.$t("attachment.types.bill"),
        },
        {
          id: 6,
          key: "washing_voucher",
          icon: "mdi-washing-machine",
          text: this.$t("attachment.types.washing_voucher"),
        },
        {
          id: 7,
          key: "photo_of_merchandise",
          icon: "mdi-image-multiple",
          text: this.$t("attachment.types.photo_of_merchandise"),
        },
        {
          id: 8,
          key: "waste_tracking_slip",
          icon: "mdi-tire",
          text: this.$t("attachment.types.waste_tracking_slip"),
        },
        {
          id: 9,
          key: "media_exchange_voucher",
          icon: "mdi-play-network-outline",
          text: this.$t("attachment.types.media_exchange_voucher"),
        },
        {
          id: 10,
          key: "other",
          icon: "mdi-text-box-multiple-outline",
          text: this.$t("attachment.types.other"),
        },
        {
          id: 12,
          key: "expedition_report",
          icon: "mdi-text-box-multiple-outline",
          text: this.$t("attachment.types.expedition_report"),
        },
        {
          id: 13,
          key: "client_bill",
          icon: "mdi-text-box-multiple-outline",
          text: this.$t("attachment.types.client_bill"),
        },
      ];
    },
    functionsToExecuteAgain() {
      return this.$store.state.functionsToExecuteAgain;
    },
    isLoggedInCompanyPeppolSupported() {
      let value = localStorage.getItem("is_peppol") ?? 0;
      return parseInt(value) == 1 ? true : false;
    },
    userCurrencySymbol() {
      return localStorage.getItem("currencySymbol");
    },
    mapApiKey() {
      return this.$store.state.mapApiKey;
    },
    currencies() {
      return this.$store.state.currencies;
    },
    bankAccounts() {
      return this.$store.state.bankAccounts;
    },
    defaultBankAccount() {
      return this.$store.state.defaultBankAccount;
    },
    userLanguageId() {
      return this.$store.state.languageId;
    },
    cachedAdressTypes() {
      return this.$store.state.cachedAdressTypes;
    },
    taxationReasons() {
      return this.$store.state.taxationReasons;
    },
    cachedData() {
      return this.$store.state.cachedData;
    },
    orderReminderTypes() {
      return this.$store.state.orderReminderTypes;
    },
    familyArticles() {
      return this.$store.state.familyArticles;
    },
    countries() {
      return this.$store.state.countries;
    },
    approvals() {
      return this.$store.state.approvals;
    },
    projectStatuses() {
      return this.$store.state.projectStatuses;
    },
    tyreTypes() {
      return this.$store.state.tyreTypes;
    },
    wheelTypes() {
      return this.$store.state.wheelTypes;
    },
    wheelMaterials() {
      return this.$store.state.wheelMaterials;
    },
    tyreSeasons() {
      return this.$store.state.tyreSeasons;
    },
    statuses() {
      return this.$store.state.statuses;
    },
    familyClients() {
      return this.$store.state.familyClients;
    },
    billTypes() {
      return this.$store.state.billTypes;
    },
    expensesTypes() {
      return this.$store.state.expensesTypes;
    },
    resourceTypes() {
      return this.$store.state.resourceTypes;
    },
    allCodes() {
      let codes = [];
      if (this.tyreTypes) {
        codes = codes.concat(
          this.translateKeys(this.tyreTypes, "key", "types")
        );
      }
      if (this.tyreSeasons) {
        codes = codes.concat(
          this.translateKeys(this.tyreSeasons, "key", "seasons")
        );
      }
      if (this.wheelTypes) {
        codes = codes.concat(
          this.translateKeys(this.wheelTypes, "key", "types")
        );
      }
      return codes;
    },
    onlyCountries() {
      let countries = [];
      this.countries.forEach((element) => {
        countries.push(element.key);
      });
      return countries;
    },
    preferredCountry() {
      return [
        localStorage.getItem("userCountry"),
        "LU",
        "BE",
        "FR",
        "DE",
        "PT",
      ];
    },
  },
  methods: {
    validateHhMm(value) {
      let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        value
      );
      return isValid;
    },
    checkIfWeHaveToShowSupplier(item) {
      if (item.supplier_order_details_id) {
        return true;
      }
      return false;
    },
    determineObject(objectToBeDetermined) {
      // Define the criteria for different object types
      const criteria = {
        clients: ["first_name", "name", "representative_company_id", "id"],
        billing: [
          "bank_id",
          "client_id",
          "company_id",
          "due_date",
          "parent_order_id",
          "type_id",
          "id",
        ],
        expenses: [
          "bank_id",
          "client_id",
          "company_id",
          "due_date",
          "reception_date",
          "type_id",
          "id",
        ],
        articles: ["identifier", "id", "is_global"],
        vehicles: ["client_id", "registration_number", "brand_id", "id"],
        tyre_depot: ["vehicle_id", "requested_out_for", "localisation", "id"],
        expedition: [
          "company_id",
          "user_id",
          "document_number",
          "identifier",
          "expeditor_id",
          "id",
        ],
      };
      const shouldNotBePresentCriteria = {
        clients: [],
        billing: ["reception_date"], // this helps in identifying if object is expense or not
        expenses: [],
        articles: [],
        vehicles: [],
        tyre_depot: [],
        expedition: [],
      };
      return this.searchObject(
        objectToBeDetermined,
        criteria,
        shouldNotBePresentCriteria
      );
    },
    matchesCriteria(obj, criteriaKeys, shouldNotBePresentKeys) {
      if (obj) {
        return (
          criteriaKeys.every((key) => key in obj) &&
          shouldNotBePresentKeys.every((key) => !(key in obj))
        );
      }
      return false;
    },
    searchObject(obj, criteria, shouldNotBePresentCriteria) {
      const results = [];

      // Check if the main object matches any of the criteria
      for (const [type, keys] of Object.entries(criteria)) {
        const shouldNotBePresentKeys = shouldNotBePresentCriteria[type] || [];
        if (this.matchesCriteria(obj, keys, shouldNotBePresentKeys)) {
          results.push({ type, object: obj });
        }
      }

      // Recursively search nested objects
      for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          results.push(
            ...this.searchObject(obj[key], criteria, shouldNotBePresentCriteria)
          );
        }
      }

      return results;
    },
    isPasswordField(property) {
      if (property.company_setting_key.includes("PASSWORD")) {
        return true;
      }
      return false;
    },
    getPropertiesForSection(company_properties, section = null) {
      let properties = [];
      if (section == "email") {
        properties = [
          "SEND_MAIL_SERVICE",
          "SMTP_HOST",
          "SMTP_PORT",
          "SMTP_ENCRYPTION",
          "MAIL_BCC_COLLECT",

          "SEND_MAIL_FROM_ADDRESS",
          "SEND_MAIL_FROM_NAME",

          "SMTP_USERNAME",
          "SMTP_PASSWORD",
          "COMPANY_EMAIL_SIGNATURE",
        ];
      } else if (section == "others") {
        properties = [
          "MENU_BACKGROUND_COLOR",
          "MENU_FONT_COLOR",
          "MENU_ICON_COLOR",
          "TIRE_SCANNER_DEALER_ID",
          "CREDIT_NOTE_MULTIPLICATION_VALUE",
          "DEFAULT_BILLING_DOCUMENT",
          "DEFAULT_DOCUMENT_DUE_DAYS",
          "GROUP_ARTICLES",
        ];
      }

      return company_properties
        .filter((element) => {
          return properties.includes(element.company_setting_key.toUpperCase());
        })
        .map((element) => {
          element.menu = false;
          if (typeof element.value === "string") {
            // Check if the string can be converted to a number
            let numberValue = Number(element.value);
            if (!isNaN(numberValue)) {
              element.value = numberValue;
            }
          } else if (typeof element.value === "number") {
            element.value = Number(element.value); // It's already a number
          }
          return element;
        })
        .sort((a, b) => {
          const indexA =
            properties.indexOf(a.company_setting_key) !== -1
              ? properties.indexOf(a.company_setting_key)
              : properties.length;
          const indexB =
            properties.indexOf(b.company_setting_key) !== -1
              ? properties.indexOf(b.company_setting_key)
              : properties.length;
          return indexA - indexB;
        });
      // return company_properties;
    },
    clickOutside() {
      document.getElementById("inspire").click();
    },
    checkIfComponentExists(moduleName) {
      let data = JSON.parse(localStorage.getItem("data"));
      let companyIdentifier = data.userDetails.company.identifier;
      let modulePath = `@/components/${companyIdentifier}/${moduleName}.vue`;

      // Using Webpack's require.context to check for component existence
      const context = require.context("@/components", true, /\.vue$/);

      // Construct the full path relative to the context's base path
      const fullPath = `./${companyIdentifier}/${moduleName}.vue`;

      if (context.keys().includes(fullPath)) {
        return true;
      } else {
        console.error(`Module ${moduleName} not found at ${modulePath}`);
        return false;
      }
    },
    // Load Module/Component
    async loadModuleOrComponent(moduleName) {
      try {
        let isLoadedModuleOrComponent = await this.checkIfComponentExists(
          moduleName
        );
        if (isLoadedModuleOrComponent) {
          this.dynamicModules[moduleName] = isLoadedModuleOrComponent;
          this.$store.commit("setDynamicLoadedComponents", this.dynamicModules);
          return true;
        }
        return false;
      } catch (error) {
        console.error(`Failed to load module ${moduleName}:`, error);
        return false;
      }
    },
    // Check if Component is Loaded
    checkIfComponentLoaded(moduleName) {
      let allLoadedComponents = Object.keys(
        this.$store.state.dynamicLoadedComponents
      );
      return allLoadedComponents.includes(moduleName);
    },
    moveLastItemOnePositionBack(array) {
      // If array has less than 2 items, no need to move anything
      if (array.length < 2) {
        // console.warn("Array has less than 2 items. No item to move.");
        return array;
      }

      // Remove the last item from the array
      const lastItem = array.pop();

      // Insert the last item one position back
      array.splice(array.length - 1, 0, lastItem);
      return array;
    },
    computeYears() {
      for (let i = new Date().getFullYear(); i > 2018; i--) {
        this.years.push({ year: i });
      }
    },
    areObjectsEqual(obj1, obj2, keysToSkip = []) {
      // Check if both objects are valid
      if (typeof obj1 !== "object" || typeof obj2 !== "object") {
        return false;
      }

      // Get the keys of both objects
      const obj1Keys = Object.keys(obj1 ?? {}).filter(
        (key) => !keysToSkip.includes(key)
      );
      const obj2Keys = Object.keys(obj2 ?? {}).filter(
        (key) => !keysToSkip.includes(key)
      );

      // Check if the number of keys is the same
      if (obj1Keys.length !== obj2Keys.length) {
        return false;
      }

      // Check if all keys and values are the same
      for (let key of obj1Keys) {
        // Check if the key exists in both objects
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
          return false;
        }

        // If both values are objects, recursively compare them
        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          if (!this.areObjectsEqual(obj1[key], obj2[key], keysToSkip)) {
            return false;
          }
        } else {
          // Check if the values are NaN or null
          if (
            (Number.isNaN(obj1[key]) && Number.isNaN(obj2[key])) ||
            (obj1[key] === null && obj2[key] === null)
          ) {
            continue;
          }

          // Check if the values are the same
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      // If all checks passed, the objects are equal
      return true;
    },
    getMemberTypeById(id) {
      return new Promise((resolve) => {
        let memberType = null;
        (this.$store.state.classCodes["member_types"] ?? []).forEach(
          (element) => {
            if (element.id == id) {
              memberType = element;
            }
          }
        );
        resolve(memberType);
      });
    },
    selectEntityType(val) {
      let entityType = this.entityTypes[0];
      this.entityTypes.forEach((element) => {
        if (element.value.toLowerCase() == val.toLowerCase()) {
          entityType = element;
        }
      });

      return entityType;
    },
    showFile(file) {
      let url = file.signed_url;
      let fileName = file.title;
      this.setPreviewData(url, fileName, true, file.mime_type);
    },
    setMultiLevelFallback(findCompanyLocale) {
      let data = JSON.parse(localStorage.getItem("data"));
      let companyIdentifier = data.userDetails.company.identifier;
      if (findCompanyLocale.includes(companyIdentifier)) {
        findCompanyLocale = findCompanyLocale.replaceAll(companyIdentifier, "");
      }
      if (findCompanyLocale != this.$i18n.fallbackLocale) {
        this.$i18n.fallbackLocale = [
          findCompanyLocale,
          this.$i18n.fallbackLocale,
        ];
      }
    },
    async copyText(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toast.success(this.$t("copied"));
      } catch ($e) {
        this.$toast.error(this.$t("cannot_copied"));
      }
    },
    findTaxationReasonByCode(code_id) {
      let isTaxationAllowed = true;
      this.taxationReasons.forEach((element) => {
        if (element.id == code_id) {
          isTaxationAllowed = !!parseInt(element.value);
        }
      });
      return isTaxationAllowed;
    },
    // Generalized cache function for API calls
    getDataWithCache(apiUrl, key, storeVariable, translate = false) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // Log the cache before any operations
          if (apiCache[key]) {
            // If the data is already cached, use the cached data
            if (storeVariable) {
              this[storeVariable] = apiCache[key];
            }
            resolve(apiCache[key]); // Resolve with cached data
          } else {
            // Make the API call if the data is not cached
            const { data } = await axios.get(API_BASE_URL + apiUrl, {
              headers: this.header,
            });

            let newData = data;
            if (translate) {
              const translatedData = this.translateKeys(data.data, "key", key);
              newData = translatedData;
            }

            apiCache[key] = newData; // Store data in the global cache

            if (storeVariable) {
              this[storeVariable] = newData; // Assign data to the component's storeVariable
            }

            resolve(newData); // Resolve with the new data
          }
        } catch (error) {
          reject(error); // Reject with the error
        }
      });
    },
    // Function to cache client members based on clientId
    getClientMembersCache(clientId) {
      const key = `clientMembers_${clientId}`; // Unique cache key for each client
      const cacheExpiration = 60 * 1000; // Cache expiration time: 1 minute in milliseconds
      const currentTime = new Date().getTime(); // Current timestamp

      // Check if the data is cached and within the 1-minute window
      if (
        apiCache[key] &&
        cacheTimestamps[key] &&
        currentTime - cacheTimestamps[key] < cacheExpiration
      ) {
        return Promise.resolve(apiCache[key]);
      } else {
        // Make the API call and cache the result
        return axios
          .get(
            `${API_BASE_URL}/clients?searchRegNumber=true&membersOnly=true&page=1&items_per_page=500&representative_company_id=${clientId}`,
            {
              headers: this.header,
            }
          )
          .then((res) => {
            const members = res.data.data;
            apiCache[key] = members; // Cache the response
            cacheTimestamps[key] = new Date().getTime(); // Store the current time for expiration check
            return members;
          })
          .catch((error) => {
            throw error;
          });
      }
    },
    getAlphabets(str) {
      return (str ?? "").replace(/[^A-Za-z]+/g, "");
    },
    checkReferenceInExpeditions(
      reference,
      expedition_id,
      checkIn = "expedition_details"
    ) {
      return new Promise((resolve, reject) => {
        if (reference) {
          axios
            .get(
              API_BASE_URL +
                "/expeditions/check/reference?reference=" +
                reference +
                (expedition_id
                  ? "&except_expedition_id=" + expedition_id
                  : "") +
                (checkIn == "expedition_details"
                  ? "&check_inside_details=" + true
                  : ""),
              { headers: this.header }
            )
            .then(({ data }) => {
              if (data.data) {
                this.$toast.error(
                  this.$t("expeditions.text_messages.reference_already_exists")
                );
              }
              resolve(data.data);
            })
            .catch(() => {
              reject();
            })
            .finally(() => {
              reject();
            });
        } else {
          resolve();
        }
      });
    },
    checkReferenceInExpenses(
      reference,
      expense_id,
      checkIn = "expense_details"
    ) {
      return new Promise((resolve, reject) => {
        if (reference) {
          axios
            .get(
              API_BASE_URL +
                "/expenses/check/reference?reference=" +
                reference +
                (expense_id ? "&except_expense_id=" + expense_id : "") +
                (checkIn == "expense_details"
                  ? "&check_inside_details=" + true
                  : ""),
              { headers: this.header }
            )
            .then(({ data }) => {
              if (data.data) {
                this.$toast.error(
                  this.$t("expeditions.text_messages.reference_already_exists")
                );
              }
              resolve(data.data);
            })
            .catch(() => {
              reject();
            })
            .finally(() => {
              reject();
            });
        } else {
          resolve();
        }
      });
    },
    findFileIconWithDescription(item) {
      let iconFound = null;
      if (item.description) {
        this.attachmentTypes.forEach((attachmentType) => {
          if (
            attachmentType.key.toUpperCase() == item.description.toUpperCase()
          ) {
            iconFound = attachmentType.icon;
          }
        });
      }
      if (iconFound) {
        return iconFound;
      }
      return "mdi-file-document-outline";
    },
    setExpeditionRowColorClass(itemStatus) {
      let className = "";
      if (itemStatus && typeof itemStatus == "object") {
        if (itemStatus.key == "new") {
          className = "white";
        }
        if (itemStatus.key == "in_process") {
          className = "orangecolor";
        }
        if (itemStatus.key == "finished") {
          className = "greencolor";
        }
        if (itemStatus.key == "billed") {
          className = "bluecolor";
        }
        if (itemStatus.key == "dispute") {
          className = "redcolor";
        }
        if (itemStatus.key == "canceled") {
          className = "greycolor";
        }
      }

      return className;
    },
    setSupplierRowColorClass(supplier) {
      let className = "greencolor";
      if (supplier && typeof supplier == "object") {
        if (supplier.is_active == 1) {
          className = "greencolor";
        }
        if (supplier.is_active == 0) {
          className = "orangecolor";
        }
      } else {
        className = "orangecolor";
      }

      return className;
    },
    computeAddressLabel(address) {
      let label = "";

      if (this.isset(address.building_id)) {
        label += address.building_id;
      }

      if (this.isset(address.street)) {
        if (label != "") {
          label += ", ";
        }
        label += address.street;
      }

      if (this.isset(address.zip)) {
        if (label != "") {
          label += ", ";
        }
        label += address.zip;
      }

      if (this.isset(address.county)) {
        if (label != "") {
          label += ", ";
        }
        label += address.county;
      }

      if (this.isset(address.state)) {
        if (label != "") {
          label += ", ";
        }
        label += address.state;
      }

      if (this.isset(address.city)) {
        if (label != "") {
          label += ", ";
        }
        if (typeof address.city === "object") {
          label += address.city?.name || "";
        } else {
          label += address.city;
        }
      }

      if (this.isset(address.city)) {
        if (label != "") {
          label += ", ";
        }
        if (typeof address.city === "object") {
          label += address.city?.country_id || "";
        } else {
          label += address.city;
        }
      }

      return label.toUpperCase();
    },
    computeExpeditionListingAddressLabel(address) {
      let label = "";

      // Country
      if (this.isset(address.city)) {
        if (label != "") {
          label += ", ";
        }
        if (typeof address.city === "object") {
          label += address.city?.country_id || "";
        } else {
          label += address.city;
        }
      }

      if (this.isset(address.zip)) {
        if (label != "") {
          label += ", ";
        }
        label += address.zip;
      }

      if (this.isset(address.city)) {
        if (label != "") {
          label += ", ";
        }
        if (typeof address.city === "object") {
          label += address.city?.name || "";
        } else {
          label += address.city;
        }
      }

      return label.toUpperCase();
    },
    checkIfCodeExists(classKey) {
      let classCodes = this.$store.state.classCodes;
      return classKey in classCodes;
    },
    processCodesApiData(data, classKey, translationKey) {
      let translatedData = data;

      //apply filter for clocking types
      if (classKey === "clocking_types") {
        translatedData = translatedData.filter((element) => {
          return element.key !== "deleted";
        });
      }
      if (translationKey && Array.isArray(translatedData)) {
        translatedData = this.translateKeys(
          data.data ? data.data : data,
          "key",
          translationKey
        );
      } else if (translationKey === null && Array.isArray(translatedData)) {
        translatedData = this.translateKeys(
          data.data ? data.data : data,
          classKey,
          "key",
          classKey
        );
      }
      this.$store.commit("setClassCodes", {
        key: classKey,
        values: translatedData,
      });
    },
    // If Class Key is Number then we have to fetch by class id and if it is string then we have to fetch by class key
    getCodes(classKey, translationKey = null) {
      return new Promise((resolve) => {
        if (!this.checkIfCodeExists(classKey)) {
          let url =
            typeof classKey == "number"
              ? "/codes?class_id="
              : "/codes?class_key=";
          axios
            .get(API_BASE_URL + url + classKey, {
              headers: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
              },
            })
            .then(({ data }) => {
              this.processCodesApiData(data.data, classKey, translationKey);
              resolve(this.$store.state.classCodes[classKey]);
            })
            .catch(() => {});
        } else {
          resolve(this.$store.state.classCodes[classKey]);
        }
      });
    },
    storeInDifferentStoreVariableAlso(classKey, data) {
      if (classKey == "tyre_types") {
        this.$store.commit("setTyreTypes", data);
      } else if (classKey == "project_status") {
        this.$store.commit("setClientProjectsStatus", data);
      } else if (classKey == "wheel_types") {
        this.$store.commit("setWheelTypes", data);
      } else if (classKey == "wheel_material") {
        this.$store.commit("setWheelMaterials", data);
      } else if (classKey == "tyre_season") {
        this.$store.commit("setTyreSeasons", data);
      } else if (classKey == "article_statuses") {
        this.$store.commit("setStatuses", data);
      } else if (classKey == "article_approval_statuses") {
        this.$store.commit("setApprovals", data);
      } else if (classKey == "order_reminders_types") {
        this.$store.commit("setOrderRemindersTypes", data);
      } else if (classKey == "taxation_reasons") {
        this.$store.commit("setTaxationReasons", data);
      } else if (classKey == "address_types") {
        this.$store.commit("setCachedAdressTypes", data);
      }
    },
    getBulkCodes(classKeys) {
      return new Promise((resolve) => {
        axios
          .get(API_BASE_URL + "/codes?class_key=" + classKeys, {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(({ data }) => {
            data.forEach((eachElement) => {
              let key = Object.keys(eachElement)[0].trim();
              let codesData = Object.values(eachElement)[0];
              let translationKey = this.getTranslationKeyForElement(key);
              this.processCodesApiData(codesData, key, translationKey);
              this.storeInDifferentStoreVariableAlso(
                key,
                this.$store.state.classCodes[key]
              );
            });
          })
          .catch(() => {})
          .finally(() => {
            resolve();
          });
      });
    },
    getTranslationKeyForElement(key) {
      let keysForTranslation = {
        tyre_types: false,
        project_status: "project_statuses",
        wheel_types: "types",
        wheel_material: "tyre_hotel.wheel_materials",
        tyre_season: "tyre_season",
        article_statuses: "article_statuses",
        article_approval_statuses: "article_approval_statuses",
        order_reminders_types: "order_reminders_types",
        taxation_reasons: "taxation_reasons",
        member_types: "member_types",
        address_types: "address_types",
        order_types: "billings.types",
        calendar_view_types: false,
        vehicle_types: "vehicles.types",
        order_status: "billings.statuses",
        payment_types: "billings.payment_methods",
        measurement_units: "measurement_units",
        company_denomination: "company_denomination",
        client_statuses: "client_statuses",
        supplier_order_statuses: "receptions.statuses",
        article_conditions: "stocks.conditions",
        order_counter_types: "order_counter.types",
        order_counter_statuses: "order_counter.statuses",
        expedition_statuses: "expedition_statuses",
        order_next_event: "billings.nextEvents",
        depot_details_statuses: "tyre_hotel.statuses",
        vehicle_ger_box: "vehicles.gearboxes",
        vehicle_transmission: "vehicles.transmissions",
        vehicle_fuel: "vehicles.fuels",
        client_notification_types: "scheduler.notification_types",
      };
      return keysForTranslation[key] ?? false;
    },
    getInvalidFields(ref) {
      const invalidFields = [];

      if (!ref || !ref.$v) return invalidFields;

      // Check top-level validation
      for (const field in ref.$v) {
        if (field !== "$model" && ref.$v[field]?.$invalid) {
          let fieldGoodName = this.validationFieldValues[field] ?? field;
          invalidFields.push(fieldGoodName);
        }
      }

      // Check sub-components (if they exist in $refs)
      for (const key in ref.$refs) {
        const subInvalidFields = this.getInvalidFields(ref.$refs[key]);
        if (subInvalidFields.length > 0) {
          invalidFields.push(`${key}: [${subInvalidFields.join(", ")}]`);
        }
      }

      return invalidFields;
    },
    isset(payload) {
      if (Array.isArray(payload)) {
        return payload.length > 0;
      }

      return (
        payload !== undefined &&
        payload !== null &&
        payload !== "" &&
        payload !== false
      );
    },
    async getCalendarViewTypes() {
      return await this.getCodes("calendar_view_types", false).then((data) => {
        this.calendarViewTypes = data;
      });
    },
    resetVariables() {
      this.isVerifiedTVA = null;
      this.isVerifiedTVALoading = false;
    },
    verifyTVA(tva) {
      return new Promise((resolve, reject) => {
        if (tva) {
          this.isVerifiedTVALoading = true;
          axios
            .get(API_BASE_URL + "/clients/checkvat/" + tva, {
              headers: this.header,
            })
            .then(({ data }) => {
              this.isVerifiedTVA = data.data.isValid;
              if (data.data.isValid == false) {
                this.$toast.error(tva + " " + this.$t("invalid_tva"));
              }
              resolve(data.data);
            })
            .catch(() => {
              this.isVerifiedTVA = false;
              this.$toast.error(this.$t("invalid_tva"));
              reject();
            })
            .finally(() => {
              this.isVerifiedTVALoading = false;
              reject();
            });
        } else {
          this.resetVariables();
          resolve();
        }
      });
    },
    ...mapActions(["forceReloadComponent"]),
    setFunctionToExecuteGlobally(val, isToRemove = false) {
      let allFunctionToExecute = this.functionsToExecuteAgain ?? [];
      if (isToRemove) {
        let index = allFunctionToExecute.indexOf(val);
        if (index > -1) {
          // only splice array when item is found
          allFunctionToExecute.splice(index, 1);
        }
      } else {
        allFunctionToExecute.push(val);
      }
      this.$store.commit("setFunctionsToExecuteAgain", allFunctionToExecute);
    },
    setPreviewData(
      previewUrl,
      previewFileName,
      previewIsAuthDependent,
      mimeType = null,
      showFullScreen = true,
      openInNewWindow = false
    ) {
      this.$store.commit("setPreviewUrl", previewUrl);
      this.$store.commit("setPreviewFileName", previewFileName);
      this.$store.commit("setPreviewIsAuthDependent", previewIsAuthDependent);
      this.$store.commit("setPreviewMimeType", mimeType);
      this.$store.commit("setIsShowFullScreen", showFullScreen ?? true);
      this.$store.commit("setOpenInNewWindow", openInNewWindow ?? false);
    },
    translateKey(key) {
      let translation = this.$t(key);

      if (translation !== key) {
        return translation;
      }

      // remove _ from key and make it sentence case
      return key.split(".").pop().replace(/_/g, " ").toString().toUpperCase();
    },
    resetDataTable(page = null) {
      if (!page) {
        this.forceReloadComponent();
        this.page = 1;
        if (this?.options) {
          this.options.page = 1;
        }
      }
    },
    computeSlotBackgroundColor(item, slot) {
      let subSlots = this.subSlots;

      if (!(subSlots.length > 0)) {
        return "white";
      }

      // if item field price is empty return white
      if (
        item[`${slot.field_price}`] === null ||
        item[`${slot.field_price}`] == 0
      ) {
        return "white";
      }

      if (
        item[`${slot.field_price}`] == 0 ||
        item[`${slot.field_stock}`] == 0
      ) {
        return "white";
      }

      let fieldPrices = subSlots.map((subSlot) => {
        return {
          field: subSlot.value,
          price: item[`${subSlot.field_price}`],
        };
      });

      // remove null values
      let filteredPrices = fieldPrices.filter((fieldPrice) => {
        return fieldPrice.price !== null;
      });

      // sort by price from lowest to highest
      let sortedPrices = filteredPrices.sort((a, b) => {
        return a.price - b.price;
      });

      // get the lowest price
      let lowestPrice = sortedPrices[0];

      let isLowestPrice = false;

      // if field of lowest price is equal to slot value return light green
      if (lowestPrice && lowestPrice.field === slot.value) {
        isLowestPrice = true;
      }

      // get delivery date from Item
      let deliveryDate = item[`${slot.field_delivery_date}`];

      // if delivery date is null return limegreen
      if (deliveryDate === null && !isLowestPrice) {
        return COLOR_SHORT_SUPPLIER_DELIVERY_DATE;
      }

      // format delivery date with moment JS
      let formattedDeliveryDate = moment(deliveryDate);

      let now = moment();

      let difference = formattedDeliveryDate.diff(now, "days");

      // if deliveryDate is greater Than 4 Days return Light Salmon
      if (difference > 4) {
        return COLOR_LONG_SUPPLIER_DELIVERY_DATE;
      }

      return isLowestPrice
        ? COLOR_BEST_SUPPLIER_PRICE
        : COLOR_SHORT_SUPPLIER_DELIVERY_DATE;
    },
    processSlots(timeSlots) {
      let workpointslots = [];
      let arrayposition = -1;
      let lasttimeslot = null;

      timeSlots.forEach((element) => {
        // as timeslots is already sorted so on change of work point id will jump to next array positions
        if (
          !lasttimeslot ||
          lasttimeslot.work_point_id != element.work_point_id
        ) {
          arrayposition++;
          workpointslots.push({
            workpointid: element.work_point_id,
            starttimeslots: [],
            endtimeslots: [],
          });
        }
        // push to the array and then sort the time array in ascending order, so that it will help in finding out min and max time slot
        workpointslots[arrayposition].starttimeslots.push(element.start_time);
        workpointslots[arrayposition].starttimeslots.sort(function (a, b) {
          return new Date("1970/01/01 " + a) - new Date("1970/01/01 " + b);
        });
        workpointslots[arrayposition].endtimeslots.push(element.end_time);
        workpointslots[arrayposition].endtimeslots.sort(function (a, b) {
          return new Date("1970/01/01 " + a) - new Date("1970/01/01 " + b);
        });
        lasttimeslot = element;
      });

      let mintime = "23:59";
      let maxtime = "00:00";

      workpointslots.forEach((element) => {
        if (
          element.starttimeslots.length > 0 &&
          new Date("1970/01/01 " + element.starttimeslots[0]) <
            new Date("1970/01/01 " + mintime)
        ) {
          mintime = element.starttimeslots[0];
        }
        if (
          element.endtimeslots.length > 0 &&
          new Date(
            "1970/01/01 " +
              element.endtimeslots[element.endtimeslots.length - 1]
          ) > new Date("1970/01/01 " + maxtime)
        ) {
          maxtime = element.endtimeslots[element.endtimeslots.length - 1];
        }
      });

      return {
        minTime: mintime,
        maxTime: maxtime,
        workPointSlots: workpointslots,
      };
    },
    formatDateWithLocale(dateString, dateAlone = false, format = null) {
      if (format) {
        return moment(dateString).format(format);
      }

      // let finalFormat = "L LT";
      // if (dateAlone) {
      //     finalFormat = "L";
      // }
      // if (format) {
      //     finalFormat = format;
      // }
      return moment(dateString)
        .locale(this.$i18n.locale)
        .format(dateAlone ? "L" : "L LT");
    },
    formatTimeWithLocale(timeString, format = "H:i:s") {
      if (!this.isset(timeString) || timeString === "00:00:00") return null;
      return moment(timeString, [format])
        .locale(this.$store.state.languageId)
        .format("LT");
    },

    translationHelper(key) {
      let translation = this.$t(key);

      if (translation !== key) {
        return translation;
      }

      // remove _ from key and make it sentence case
      return key.split(".").pop().replace(/_/g, " ").toString().toUpperCase();
    },
    getCompanyPropertyValue(propertyKey) {
      try {
        let companyProperties = JSON.parse(
          localStorage.getItem("company_properties")
        );

        let property = companyProperties.find(
          (item) => item.company_setting_key === propertyKey
        );

        if (!property) {
          return null;
        }

        return property.value;
      } catch (e) {
        return null;
      }
    },
    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    getElementById(id) {
      return document.getElementById(id);
    },
  },
  watch: {
    async previewUrl(val) {
      if (!val) {
        // When closed clear all the cache in cache api
        const cache = await caches.open("pdf-cache");
        const keys = await cache.keys();

        // Delete all cached PDFs (or adjust this to only clear specific items)
        for (const request of keys) {
          if (request.url.includes("/fake-path/")) {
            await cache.delete(request);
          }
        }
      }
    },
  },
};
