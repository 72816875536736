<template>
  <v-card flat>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-form
      ref="form"
      class="main_form"
      id="pricing-rule-form"
      @submit.prevent="submit"
    >
      <v-btn type="submit" class="d-none" id="submit-pricing-rule"></v-btn>
      <input type="hidden" name="id" v-model="form.id" />
      <v-container fluid>
        <v-row>
          <v-text-field
            class="mr-2 to-upper"
            :label="$t('pricing_rule.name')"
            v-model="form.name"
            required
            :error-messages="
              $store.state.validation && !$v.form.name.required
                ? [this.$t('pricing_rule.text_messages.name_required')]
                : []
            "
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
          <v-autocomplete
            flat
            ref="ruleType"
            :items="ruleTypes"
            v-model="ruleType"
            :label="$t('pricing_rule.rule_type')"
            class="mr-2 to-upper"
            item-value="id"
            item-text="text"
            required
            :outlined="is_boxes"
            :dense="is_boxes"
            :error-messages="
              $store.state.validation && !$v.ruleType.required
                ? [this.$t('pricing_rule.text_messages.rule_type_required')]
                : []
            "
            hide-no-data
          ></v-autocomplete>
          <v-autocomplete
            flat
            :items="conditions"
            v-model="condition"
            :label="$t('pricing_rule.condition')"
            class="mr-2 to-upper"
            item-value="id"
            item-text="text"
            required
            :outlined="is_boxes"
            :dense="is_boxes"
            :error-messages="
              $store.state.validation && !$v.condition.required
                ? [this.$t('pricing_rule.text_messages.condition_required')]
                : []
            "
            hide-no-data
          ></v-autocomplete>
          <v-select
            :readonly="this.operation === 'edit'"
            class="mr-2"
            :class="[operation == 'edit' ? 'mt-1 custom-readonly-color' : '']"
            v-model="form.familyArticle"
            :items="operation == 'edit' ? globalfamilyArticles : familyArticles"
            :label="$t('articles.family')"
            item-text="name"
            item-value="id"
            @change="changeFamilyArticle(true)"
            hide-details="auto"
            return-object
            required
            :outlined="is_boxes"
            :dense="is_boxes"
          >
          </v-select>
          <v-autocomplete
            v-model="supplier"
            :items="suppliers"
            :label="$t('pricing_rule.supplier')"
            item-value="id"
            item-text="name"
            class="mr-2 to-upper"
            required
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-autocomplete>
          <v-autocomplete
            v-model="familyClient"
            :items="familyClients"
            :label="$t('pricing_rule.family_client')"
            item-value="id"
            item-text="name"
            class="mr-2 to-upper"
            required
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-autocomplete>
        </v-row>
        <v-row>
          <div v-on:keyup="handleNegativAmounteKey($event)">
            <custom-money-input
              :autocomplete="autocompleteValueLocal"
              :label="$t('pricing_rule.amount')"
              class="mr-2 mt-0 to-upper"
              hide-details="auto"
              outlined
              style="width: 350px"
              dense
              ref="tableUnitPrice"
              v-model="amount"
              v-bind:properties="{
                prefix: currencySymbol,
                readonly: false,
                disabled: false,
                outlined: is_boxes,
                placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                dense: is_boxes,
                hideDetails: 'auto',
                errorMessages: $store.state.validation
                  ? returnErrorMessage(
                      [
                        !$v.amount.required,
                        this.$t(
                          'pricing_rule.text_messages.amount_is_required_when_percent_is_not_defined'
                        ),
                      ]
                      // [!$v.amount.minValue, this.$t('pricing_rule.text_messages.amount_cannot_be_less_then_1')],
                    )
                  : [],
                maxLength: 10,
              }"
              v-bind:options="{
                length: 12,
                precision: getDecimalNumber(),
                empty: null,
              }"
            />
          </div>
          <div v-on:keyup="handleNegativePercentKey($event)">
            <custom-money-input
              :autocomplete="autocompleteValueLocal"
              v-model="percent"
              style="width: 350px"
              :label="$t('pricing_rule.percent')"
              class="mr-2 mt-0 to-upper"
              @keypress="
                handleInput($event, 'float', getDecimalNumber() == 2 ? 5 : 4)
              "
              v-bind:properties="{
                suffix: '%',
                readonly: false,
                disabled: false,
                outlined: is_boxes,
                clearable: false,
                placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                dense: is_boxes,
                hideDetails: 'auto',
                errorMessages: $store.state.validation
                  ? returnErrorMessage(
                      [
                        !$v.percent.required,
                        this.$t(
                          'pricing_rule.text_messages.percent_is_required_when_amount_is_not_defined'
                        ),
                      ],
                      [
                        !$v.percent.maxValue,
                        this.$t(
                          'pricing_rule.text_messages.percent_cannot_be_greater_then_100'
                        ),
                      ]
                      // [!$v.percent.minValue, this.$t('pricing_rule.text_messages.percent_cannot_be_less_then_1')],
                    )
                  : [],
                maxLength: 7,
              }"
              v-bind:options="{
                locale: userLanguageId,
                length: 5,
                precision: getDecimalNumber(),
                empty: null,
              }"
              hide-details="auto"
              v-bind:focus="true"
              ref="tableRedPercentage"
            />
          </div>
          <!-- Start Date -->
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                class="to-upper"
                :label="$t('pricing_rule.start_date')"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="date = parseDate(startDate)"
                v-on="on"
                required
                :outlined="is_boxes"
                :dense="is_boxes"
                :error-messages="
                  $store.state.validation && !$v.startDate.required
                    ? [$t('pricing_rule.text_messages.start_date_required')]
                    : []
                "
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              :weekday-format="getDay"
              v-model="startDate"
              no-title
              @input="menu1 = false"
              :locale="userLanguageId"
            ></v-date-picker>
          </v-menu>
          <!-- End Date -->
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="to-upper"
                v-model="endDate"
                :label="$t('pricing_rule.end_date')"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="date = parseDate(endDate)"
                v-on="on"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              :weekday-format="getDay"
              v-model="endDate"
              no-title
              @input="menu2 = false"
              :locale="userLanguageId"
            ></v-date-picker>
          </v-menu>
          <v-checkbox
            class="mt-6 ml-4"
            v-model="is_active"
            :label="$t('pricing_rule.active')"
          ></v-checkbox>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="pb-6">
              <v-tabs
                v-model="tab"
                background-color="deep-purple accent-4"
                dark
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#tab-1">
                  <span v-if="form.familyArticle">
                    {{ form.familyArticle.name }}
                  </span>
                  {{ $t("pricing_rule.filters") }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-container fluid>
                          <!-- Now Display the properties that are not in the above rendered property  -->
                          <v-row class="d-flex">
                            <v-col
                              v-for="(filter, index) in filterPropertiesExcept(
                                customFilters
                              )"
                              :key="index"
                            >
                              <template
                                v-if="filter.property_name == 'property_00'"
                              >
                                <v-autocomplete
                                  style="min-width: 200px"
                                  :autocomplete="autocompleteValueLocal"
                                  ref="brand"
                                  :items="brands"
                                  v-model="form.dynamicProperties.brand_id"
                                  :label="$t('articles.properties.brand')"
                                  class="mr-2 mt-1 to-upper"
                                  item-value="id"
                                  item-text="name"
                                  @focus="brand = null"
                                  hide-details="auto"
                                  append-icon="mdi-close"
                                  @click:append="
                                    form.dynamicProperties.brand_id = null
                                  "
                                  :outlined="is_boxes"
                                  :dense="is_boxes"
                                  hide-no-data
                                ></v-autocomplete>
                              </template>
                              <template v-else>
                                <v-autocomplete
                                  style="min-width: 200px"
                                  :autocomplete="autocompleteValueLocal"
                                  v-if="filter.mapping_key != null"
                                  v-model="
                                    form.dynamicProperties[filter.property_name]
                                  "
                                  :items="multiselectsValues[index]"
                                  :label="filter.text"
                                  item-value="id"
                                  hide-details="auto"
                                  class="mr-2 mt-1"
                                  append-icon="mdi-close"
                                  @click:append="
                                    form.dynamicProperties[
                                      filter.property_name
                                    ] = null
                                  "
                                  :outlined="is_boxes"
                                  :dense="is_boxes"
                                  hide-no-data
                                ></v-autocomplete>
                                <v-text-field
                                  style="min-width: 200px"
                                  :autocomplete="autocompleteValueLocal"
                                  v-if="filter.mapping_key == null"
                                  flat
                                  v-model="
                                    form.dynamicProperties[filter.property_name]
                                  "
                                  :label="filter.text"
                                  :ref="filter.property_name"
                                  @focus="filter.value = null"
                                  @keypress="
                                    handleInput(
                                      $event,
                                      filter.keytype,
                                      filter.search_length
                                    )
                                  "
                                  hide-details="auto"
                                  class="to-upper"
                                  :outlined="is_boxes"
                                  :dense="is_boxes"
                                ></v-text-field>
                              </template>
                            </v-col>
                          </v-row>

                          <v-row
                            class="mt-2"
                            v-for="(row, rowIndex) in articleBooleanProperties"
                            :key="'R' + rowIndex"
                          >
                            <v-col
                              class="ml-2 pa-0"
                              v-for="(property, colIndex) in row"
                              :key="'C' + colIndex"
                            >
                              <v-checkbox
                                :label="
                                  $t('articles.properties.' + property.name)
                                "
                                v-model="
                                  form.dynamicProperties[property.property_name]
                                "
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <!-- <pricing-rule-tabs :client="form.id"></pricing-rule-tabs> -->
    <v-btn @click="onSubmit()" class="d-none" id="submit-pricing-rule"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-pricing-rule"></v-btn>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
// import { EDIT_MODE } from "@/config";
import { DETAILS_MODE } from "@/config";
import { ADD_MODE } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";

export default {
  mixins: [validationMixin],
  name: "PricingRule",
  components: {
    "confirmation-model": ConfirmationModel,
    "custom-money-input": CutsomMoneyInput,
  },
  props: {
    iCompany: Boolean,
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
    ruleType: {
      required,
    },
    startDate: {
      required,
    },
    condition: {
      required,
    },
    amount: {
      required: requiredIf(function () {
        return !this.percent;
      }),
      // minValue(value) {
      //   value = parseFloat(value)
      //   if(parseFloat(value) < parseFloat(0.001) && ( !this.percent || parseFloat(this.percent) == 0) ){
      //     return false;
      //   }
      //   return true;
      // }
    },
    percent: {
      required: requiredIf(function () {
        return !this.amount;
      }),
      maxValue(value) {
        value = parseFloat(value);
        if (value > 100) {
          return false;
        }
        return true;
      },
      // minValue(value) {
      //   value = parseFloat(value)
      //   if(parseFloat(value) < parseFloat(0.001) && ( !this.amount || parseFloat(this.amount) == 0) ){
      //     return false;
      //   }
      //   return true;
      // }
    },
  },
  data() {
    const defaultForm = Object.freeze({
      entityType: { id: 1 },
      id: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: null,
      startDate: null,
      dynamicProperties: [],
      familyArticle: null,
      name: null,
    });

    return {
      autocompleteValueLocal: "off",
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dialog: false,
      amount: null,
      percent: null,
      endDate: null,
      startDate: null,
      menu1: false,
      menu2: false,
      is_active: false,
      approval: { id: 10011001 },
      DeleteConfirm: false,
      form: Object.assign({}, defaultForm),
      familyClient: null,
      operation: "edit",
      ruleTypes: [],
      ruleType: null,
      conditions: [],
      condition: null,
      brands: [],
      brand: null,
      suppliers: [],
      supplier: null,
      status: null,
      articleBooleanProperties: [],
      customFilters: [],
      customFiltersExceptions: {}, // these numbers represents property_id value example : property_id = 1, also these index represents the family articles and 0 means all articles
      tab: null,
      // rules: {
      // amount: [
      //   v => !!v || 'Name is required'
      // ],
      // },
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      globalfamilyArticles: [],
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    (this.header = { Authorization: "Bearer " + this.token }),
      this.setCurrentFormOperation();
    this.getConditions().then(() => {
      this.getRuleTypes().then(() => {
        this.getSuppliers().then(() => {
          console.log("get operation");
          if (this.operation === "edit") {
            this.getFamilyArticles().then(() => {
              this.$store.commit("setFormMode", DETAILS_MODE);
              this.fetchData();
            });
          } else {
            this.setDefaultFamilyArticle();
            this.$store.commit("setFormMode", ADD_MODE);
          }
        });
      });
    });
    this.setDefaultFamilyClient();

    let pricingRuleFilters = JSON.parse(
      localStorage.getItem("PRICING_RULE_FILTERS")
    );
    if (pricingRuleFilters != null) {
      this.ruleType = pricingRuleFilters.ruleType;
      this.familyArticle = pricingRuleFilters.familyArticle;
      this.condition = pricingRuleFilters.condition;
      this.supplier = pricingRuleFilters.supplier;
      this.familyClient = pricingRuleFilters.familyClient;
      this.startDate = pricingRuleFilters.start_date;
      this.endDate = pricingRuleFilters.end_date;
    }
  },
  methods: {
    handleNegativAmounteKey(event) {
      event = event ? event : window.event;
      let charCode = event.which ? event.which : event.keyCode;
      if (charCode == 189) {
        this.amount = parseFloat(this.amount) * -1;
      }
      return true;
    },
    handleNegativePercentKey(event) {
      event = event ? event : window.event;
      let charCode = event.which ? event.which : event.keyCode;
      if (charCode == 189) {
        this.percent = parseFloat(this.percent) * -1;
      }
      return true;
    },
    getMultiSelectValuesFromCode() {
      this.customFilters.forEach((filter, filterindex) => {
        this.multiselectsValues[filterindex] = [];
      });
      this.multiselects.forEach((code) => {
        this.getCodes(parseInt(code), "codes").then((data) => {
          this.customFilters.forEach((filter, filterindex) => {
            if (filter.mapping_key == code) {
              this.multiselectsValues[filterindex] = data;
            }
          });
          this.multiselectsValues.push({});
          this.multiselectsValues.splice(-1);
        });
      });
    },
    setDefaultFamilyClient() {
      this.familyClients.forEach((element) => {
        if (element.is_default == 1) {
          this.familyClient = element.id;
        }
      });
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "pricing_rule.delete_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("pricing_rule.cancel");
      this.confirmationDialogTrueText = this.$t("pricing_rule.delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    /**
     * This Function is responsible to autofocus on next property field, if current's max length reached
     */
    handleAutoFocus(
      event,
      search_length,
      propertyName,
      dataType,
      forceToJumpOnProperty
    ) {
      let currentFieldLength = 0;
      if (dataType == "int" || dataType == "float") {
        currentFieldLength = (event.target.value + "").replace(".", "").length;
      } else {
        currentFieldLength = (event.target.value + "").length;
      }
      if (
        forceToJumpOnProperty &&
        search_length != null &&
        currentFieldLength == search_length
      ) {
        this.$refs[forceToJumpOnProperty][0].focus();
      } else if (
        propertyName.search("property") == 0 &&
        search_length != null &&
        currentFieldLength == search_length
      ) {
        let underscorePos = propertyName.search("_") + 1;
        let propertyNo = Number(propertyName.substr(underscorePos)) + 1;
        let nextPropertyName =
          "property_" + (propertyNo < 10 ? "0" : "") + propertyNo;
        this.$refs[nextPropertyName][0].focus();
      }
    },
    /**
     * It handles the type of values that can be entered in the text field based upon its datatypes
     * This is also responsible for the behaviour like : when max length reached no more data can be entered.
     */
    handleInput: function (event, dataType, search_length) {
      event = event ? event : window.event;
      let charCode = event.which ? event.which : event.keyCode;
      if (
        search_length != null &&
        (event.target.value + "").length >= Number(search_length)
      ) {
        event.preventDefault();
      }
      if (dataType == "int" || dataType == "float") {
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          event.preventDefault();
        } else {
          return true;
        }
      }
      return true;
    },
    deleteItem() {
      axios({
        method: "delete",
        url: API_BASE_URL + "/pricing_rules/" + this.$store.state.editId,
        headers: this.header,
      })
        .then(() => {
          this.$toast.success(this.$t("pricing_rule.deleted_successfully"));
          this.$router.push("/pricing-rules").catch(() => {});
        })
        .catch((error) => {
          this.$store.state.alerts.push({
            msg: error.response.data.errors,
            show: true,
            type: "error",
          });
        });
    },
    onDelete() {
      this.deletePopUp();
    },
    fetchData() {
      axios
        .get(API_BASE_URL + "/pricing_rules/" + this.$store.state.editId, {
          headers: this.header,
        })
        .then(({ data }) => {
          let currentFamilyArticle = this.globalfamilyArticles.find(
            (element) => {
              if (element.id == data.data.family_article_id) return element;
            }
          );
          this.form.familyArticle = currentFamilyArticle;

          this.getBrands();
          console.log(
            "fetched family article",
            this.familyArticles,
            data.data.family_article_id
          );
          this.setDynamicFilterBoxes();
          this.changeArticleProperties();

          this.form.id = data.data.id;
          this.form.name = data.data.name;
          this.familyClient = data.data.family_client_id;
          this.ruleType = data.data.rule_type_id;
          this.condition = data.data.condition_id;
          this.supplier = data.data.supplier_id;
          this.amount = data.data.amount;
          this.percent = data.data.percent;
          this.startDate = data.data.start_date;
          this.endDate = data.data.end_date;
          this.is_active = data.data.is_active;

          Object.keys(data.data).forEach(function (key) {
            if (
              key.includes("property_") &&
              data.data[key] &&
              !isNaN(data.data[key])
            ) {
              data.data[key] = parseInt(data.data[key]);
            }
          });
          this.form.dynamicProperties = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    makeSumbitData() {
      return {
        rule_type_id: this.ruleType
          ? typeof this.ruleType == "object"
            ? this.ruleType.id
            : this.ruleType
          : null,
        family_article_id: this.form.familyArticle.id,
        condition_id: this.condition
          ? typeof this.condition == "object"
            ? this.condition.id
            : this.condition
          : null,
        supplier_id: this.supplier
          ? typeof this.supplier == "object"
            ? this.supplier.id
            : this.supplier
          : null,
        family_client_id: this.familyClient
          ? typeof this.familyClient == "object"
            ? this.familyClient.id
            : this.familyClient
          : null,
        amount: parseFloat(this.amount),
        percent: parseFloat(this.percent),
        start_date: this.startDate,
        end_date: this.endDate,
        name: this.form.name,
        is_active: this.is_active,
      };
    },
    submit() {
      this.$store.commit("setValidation", true);
      if (this.$v.$invalid) {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
      } else {
        let data = this.makeSumbitData();
        if (this.operation === "add") {
          data["properties"] = this.form.dynamicProperties;
          this.addPricingRule(data);
        } else {
          data["properties"] = this.form.dynamicProperties;
          this.updatePricingRule(data);
        }
      }
    },
    getRuleTypes() {
      return new Promise((resolve) => {
        this.getCodes("pricing_rule_types", "pricing_rule.rule_types").then(
          (data) => {
            this.ruleTypes = data;
            resolve();
          }
        );
      });
    },
    getConditions() {
      return new Promise((resolve) => {
        this.getCodes("article_conditions", "pricing_rule.conditions").then(
          (data) => {
            this.conditions = data;
            resolve();
          }
        );
      });
    },
    getSuppliers() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_BASE_URL +
              "/suppliers?items_per_page=-1&internal_supplier=true&parent_also=true",
            { headers: this.header }
          )
          .then(({ data }) => {
            this.suppliers = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          });
      });
    },

    filterPropertiesExcept(customFilters) {
      let properties;
      if (
        this.form.familyArticle &&
        (this.form.familyArticle.parent_family_id == 1 ||
          this.form.familyArticle.id == 1)
      ) {
        properties = [];
      } else if (
        this.form.familyArticle &&
        (this.form.familyArticle.parent_family_id == 2 ||
          this.form.familyArticle.id == 2)
      ) {
        properties = [];
      } else {
        properties = [];
      }

      let filteredProperties = [];
      filteredProperties = customFilters.filter((property) => {
        let found = false;
        properties.forEach((element) => {
          if (element == property.property_name) {
            found = true;
          }
        });

        return found ? false : property;
      });
      return filteredProperties;
    },
    checkIfBrandExistsInProperties(customFilters) {
      let found = false;
      customFilters.forEach((property) => {
        if ("property_00" == property.property_name) {
          found = true;
        }
      });
      return found;
    },
    filterProperties(customFilters, properties) {
      let filteredProperties = [];
      filteredProperties = customFilters.filter((property) => {
        let found = false;
        properties.forEach((element) => {
          if (element == property.property_name) {
            found = true;
          }
        });
        return found ? property : false;
      });
      return filteredProperties;
    },
    filterPropertiesRemoveKeyIfMappingKeyIsNull(customFilters, properties) {
      let filteredProperties = [];
      filteredProperties = customFilters.filter((property) => {
        let found = false;
        properties.forEach((element) => {
          if (element == property.property_name) {
            found = true;
          }
        });
        return found
          ? property.mapping_key != null
            ? property
            : false
          : false;
      });
      return filteredProperties;
    },
    getBrands() {
      if (
        this.form.familyArticle.parent_family_id
          ? this.form.familyArticle.parent_family_id
          : this.form.familyArticle.id
      ) {
        if (
          this.cachedData["brands"][
            this.form.familyArticle.parent_family_id
              ? this.form.familyArticle.parent_family_id
              : this.form.familyArticle.id
          ] &&
          this.cachedData["brands"][
            this.form.familyArticle.parent_family_id
              ? this.form.familyArticle.parent_family_id
              : this.form.familyArticle.id
          ].length > 0
        ) {
          this.brands =
            this.cachedData["brands"][
              this.form.familyArticle.parent_family_id
                ? this.form.familyArticle.parent_family_id
                : this.form.familyArticle.id
            ];
        } else {
          axios
            .get(
              API_BASE_URL +
                "/brands?family=" +
                (this.form.familyArticle.parent_family_id
                  ? this.form.familyArticle.parent_family_id
                  : this.form.familyArticle.id) +
                "&parent_also=true&items_per_page=-1",
              { headers: this.header }
            )
            .then(({ data }) => {
              this.brands = data.data;
              this.cachedData["brands"][
                this.form.familyArticle.parent_family_id
                  ? this.form.familyArticle.parent_family_id
                  : this.form.familyArticle.id
              ] = this.brands;
            })
            .catch(function (error) {
              console.log("an error occured " + error);
            });
        }
      }
    },
    async fetchTestData(familyId, tochangeFamilyArticle) {
      await axios
        .get(API_BASE_URL + "/pricing_rules/params", {
          params: {
            familyId: familyId ?? null,
          },
          headers: this.header,
        })
        .then(({ data }) => {
          console.log("Test fetch executed");

          this.form.dynamicProperties = data.data;
          tochangeFamilyArticle ? this.changeFamilyArticle(false) : false;
        })
        .catch(() => {});
    },
    changeArticleProperties() {
      let currentFamilyArticle = null;
      if (this.operation == "add") {
        currentFamilyArticle = this.familyArticles.find((element) => {
          if (
            this.form.familyArticle &&
            element.id == this.form.familyArticle.id
          )
            return element;
        });
      } else {
        currentFamilyArticle = this.globalfamilyArticles.find((element) => {
          if (
            this.form.familyArticle &&
            element.id == this.form.familyArticle.id
          )
            return element;
        });
      }

      if (currentFamilyArticle != undefined) {
        this.articleBooleanProperties = this.sliceIntoChunks(
          currentFamilyArticle.properties.filter((property) => {
            property.property_name =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);
            let propertyName =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);
            if (property.cast == "boolean" && property.in_edit == 1) {
              this.form.dynamicProperties[propertyName] =
                Number(this.form.dynamicProperties[propertyName]) == 1
                  ? Boolean(Number(this.form.dynamicProperties[propertyName]))
                  : null;
              return property;
            }
            return false;
          }),
          5
        );
      }
    },
    setCurrentFamilyArticle(articleId) {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (element.id == articleId) return element;
      });
      this.form.familyArticle = currentFamilyArticle;
    },
    sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    },
    getApprovals() {
      this.getCodes(
        "article_approval_statuses",
        "article_approval_statuses"
      ).then((data) => {
        this.approvals = data;
      });
    },
    async getStatuses() {
      await this.getCodes("article_statuses", "article_statuses").then(
        (data) => {
          this.statuses = data;
        }
      );
    },
    getFamilyArticles() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_BASE_URL +
              "/family_articles?items_per_page=-1" +
              (this.operation != "add" ? "&global_also=true" : ""),
            { headers: this.header }
          )
          .then(({ data }) => {
            this.globalfamilyArticles = data.data;
            this.globalfamilyArticles.forEach((elem) => {
              if (elem.parent_family_id) {
                elem.properties = elem.parent_family_properties;
              }
            });
            if (this.operation === "add") {
              this.setDefaultFamilyArticle();
              this.changeFamilyArticle();
            }
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured family article" + error);
            reject();
          });
      });
    },
    setDefaultFamilyArticle() {
      let defaultFamilyArticle = null;
      this.familyArticles.forEach((element) => {
        if (element.is_default) {
          defaultFamilyArticle = element;
        }
      });
      if (!defaultFamilyArticle) {
        this.form.familyArticle = this.familyArticles[0];
      } else {
        this.form.familyArticle = defaultFamilyArticle;
      }
      this.fetchTestData(this.form.familyArticle.id, true);
    },
    changeFamilyArticle(fetchTestDataAgain) {
      if (fetchTestDataAgain) {
        this.fetchTestData(this.form.familyArticle.id, false);
      }
      this.changeArticleProperties();
      this.brands = [];
      this.getBrands();
      this.setDynamicFilterBoxes();
    },
    setDynamicFilterBoxes() {
      let currentFamilyArticle = null;
      if (this.operation == "add") {
        currentFamilyArticle = this.familyArticles.find((element) => {
          if (element.id == this.form.familyArticle.id) return element;
        });
      } else {
        currentFamilyArticle = this.globalfamilyArticles.find((element) => {
          if (element.id == this.form.familyArticle.id) return element;
        });
      }

      this.customFilters = [];
      this.multiselects = [];
      currentFamilyArticle.properties.filter((property) => {
        let propertiesArray = [];
        for (const [key, value] of Object.entries(
          this.customFiltersExceptions
        )) {
          if (
            this.form.familyArticle.id == key ||
            this.form.familyArticle.parent_family_id == key
          ) {
            propertiesArray = value;
            break;
          } else {
            propertiesArray = value;
          }
        }
        if (
          property.in_edit == 1 &&
          property.cast != "boolean" &&
          !propertiesArray.includes(property.property_id)
        ) {
          let indexOfClass = (property.mapping ?? "").indexOf("class=");
          let mappingKey = null;
          if (indexOfClass != -1) {
            mappingKey = property.mapping.substr(indexOfClass + 6, 4) + "";
            let propertyName =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);

            if (this.form.dynamicProperties) {
              this.form.dynamicProperties[propertyName] = parseInt(
                this.form.dynamicProperties[propertyName]
              );
            }
          }
          if (mappingKey) {
            this.multiselects.push(mappingKey);
          }
          this.customFilters.push({
            text: this.$t("articles.properties." + property.name),
            align: "start",
            sortable: true,
            property_name:
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id),
            property_id: property.property_id,
            column_name: property.name,
            value: null,
            property_type: property.cast,
            mapping: property.mapping ?? null,
            mapping_key: mappingKey ?? null,
            keytype: property.cast,
            search_length: property.search_length,
            cast: property.cast,
          });
          return true;
        }
        return false;
      });

      this.getMultiSelectValuesFromCode();
    },
    getModelNameFromClassId(classId) {
      let modelName = [
        { key: "1010", name: "tyreTypes" },
        { key: "1011", name: "tyreSeasons" },
        { key: "1041", name: "wheelTypes" },
      ];
      return modelName.find((code) => {
        if (code.key == classId) return code;
      }).name;
    },
    addPricingRule(data) {
      axios
        .post(API_BASE_URL + "/pricing_rules", data, { headers: this.header })
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$t("pricing_rule.pricing_rule_added"));
            this.$router.push("/pricing-rules").catch(() => {});
          } else {
            alert("ERROR on saving. Please try again later");
          }
        })
        .catch((error) => {
          let err = error.response.data.errors;
          let errArray = [];
          for (const key in err) {
            err[key].forEach((errorMessage) => {
              errArray.push(errorMessage);
            });
          }
          errArray.forEach((error) => {
            this.$store.state.alerts.push({
              msg: error,
              show: true,
              type: "error",
            });
          });
        })
        .finally(() => {});
    },
    updatePricingRule(data) {
      axios
        .put(API_BASE_URL + "/pricing_rules/" + this.form.id, data, {
          headers: this.header,
        })
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$t("pricing_rule.pricing_rule_updated"));
            this.$router.push("/pricing-rules").catch(() => {});
          } else {
            alert("ERROR on saving. Please try again later");
          }
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {});
    },
    setCurrentFormOperation() {
      this.operation = this.$route.path.indexOf("/add") > 0 ? "add" : "edit";
    },
  },
  computed: {
    currencySymbol() {
      return this.userCurrencySymbol;
    },
    isCompanyEntitySelected() {
      return this.form.entityType.id === 1;
    },
    companyNameLabel() {
      return this.isCompanyEntitySelected ? "Company Name" : "Full Name";
    },
  },
  watch: {
    "form.familyArticle": {
      handler: function () {},
    },
    familyArticle() {},
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "delete" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.deleteItem();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
};
</script>

<style scoped></style>
