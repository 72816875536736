<template>
  <v-dialog max-width="800" :value="value" v-if="isset(client)">
    <v-card>
      <v-card-title> Overdue Bill Summary </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td>{{ $t("order_history_identifier") }}</td>
                <td>{{ $t("billings.totalThtva") }}</td>
                <td>{{ $t("billings.totalTva") }}</td>
                <td>{{ $t("billings.total") }}</td>
                <td>{{ $t("expenses.due_date") }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in pendingOrders" :key="order.id">
                <td>
                  <TextComponentWithExtraIcons
                    :value="order.identifier"
                  ></TextComponentWithExtraIcons>
                </td>
                <td>{{ parseFloat(order.total_without_vat).toFixed(2) }}</td>
                <td>{{ parseFloat(order.vat_total).toFixed(2) }}</td>
                <td>
                  {{
                    (
                      parseFloat(order.total_without_vat) +
                      parseFloat(order.vat_total)
                    ).toFixed(2)
                  }}
                </td>
                <td>{{ formatDate(order.due_date.substr(0, 10)) }}</td>
              </tr>
              <tr>
                <td></td>
                <td style="font-weight: bold">
                  {{ calculateTotal("total_without_vat") }}
                </td>
                <td style="font-weight: bold">
                  {{ calculateTotal("vat_total") }}
                </td>
                <td style="font-weight: bold">
                  {{
                    (
                      parseFloat(calculateTotal("total_without_vat")) +
                      parseFloat(calculateTotal("vat_total"))
                    ).toFixed(2)
                  }}
                </td>
                <td style="font-weight: bold"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close()">
          <CloseButton />
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";
import CloseButton from "@/components/Buttons/CloseButton.vue";

export default {
  name: "overdueOrdersList",
  components: { CloseButton },
  emits: ["input", "updateBillData"],
  props: {
    client: {
      required: false,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      axiosHeaders: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      pendingOrders: [],
    };
  },

  watch: {
    value(val) {
      if (val) {
        this.getPendingOrders(this.client.id);
      }
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    getPendingOrders(clientId) {
      return new Promise((resolve) => {
        return axios
          .get(
            API_BASE_URL +
              "/clients/" +
              clientId +
              "/pending/bill?return_orders=true",
            { headers: this.axiosHeaders }
          )
          .then((response) => {
            this.pendingOrders = response?.data?.data || response?.data;
            resolve(this.pendingOrders);
          });
      });
    },
    editItem(item) {
      this.$emit("updateBillData", item.id);
      this.close();
    },
    calculateTotal(column) {
      return parseFloat(
        this.pendingOrders.reduce((acc, order) => {
          return acc + parseFloat(order[column]);
        }, 0)
      ).toFixed(2);
    },
  },
};
</script>
