<template>
  <div class="mt-8">
    <!-- First Row (4 items) -->
    <v-row>
      <v-col
        cols="4"
        class="ma-0 pa-0 pl-4 pb-3"
        v-for="(property, index) in getPropertiesForSection(
          prop_company_properties,
          'others'
        )"
        :key="index"
      >
        <!-- If Input Fields -->
        <v-text-field
          v-if="
            !checkIfPropertyIsOtherThenText(property) &&
            !checkIfPropertyIsColor(property) &&
            !checkIfPropertyIsDefaultBillingDocument(property)
          "
          :type="isPasswordField(property) ? 'password' : 'text'"
          :label="getTranslation(property.company_setting_key)"
          v-model="property.value"
        ></v-text-field>

        <!-- If Color Properties -->
        <v-menu
          v-if="checkIfPropertyIsColor(property)"
          v-model="property.menu"
          nudge-bottom="105"
          nudge-left="16"
          :close-on-content-click="false"
          hide-details
        >
          <template v-slot:activator="{ on }">
            <v-row class="d-flex">
              <v-col cols="12" class="mb-0 pb-0 extra_label">
                {{ getTranslation(property.company_setting_key) }}
              </v-col>
              <v-col cols="12" class="mt-0 pt-0">
                <div :style="swatchStyle(property)" v-on="on" />
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker v-model="property.value" mode="hexa" flat />
            </v-card-text>
          </v-card>
        </v-menu>

        <!-- If Billing Document Properties -->
        <v-autocomplete
          v-if="
            billTypes &&
            billTypes.length > 0 &&
            checkIfPropertyIsDefaultBillingDocument(property)
          "
          :items="billTypes"
          :label="getTranslation(property.company_setting_key)"
          v-model="property.value"
          :return-object="false"
          item-value="id"
          item-text="text"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  name: "CompanyOthers",
  props: {
    prop_company_properties: [Array],
  },
  components: {},
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      autocompleteValueLocal: "off",

      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      token: this.$store.state.AccessToken,
      billTypes: [],
      billType: null,
    };
  },
  created() {
    this.header = { Authorization: "Bearer " + this.token };
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  mounted() {
    this.header = { Authorization: "Bearer " + this.token };
    this.getBillTypes();
  },
  methods: {
    async getBillTypes() {
      await this.getCodes("order_types", "billings.types").then((data) => {
        data.forEach((element, index) => {
          if (element.key == "bill") {
            data[index].icon = "mdi-script-text";
          } else if (element.key == "purchase_order") {
            data[index].icon = "mdi-tag-text";
          } else if (element.key == "estimation") {
            data[index].icon = "mdi-book-open";
          } else if (element.key == "credit_note") {
            data[index].icon = "mdi-note-multiple";
          }
        });
        this.billTypes = data;
      });
    },
    getTranslation(company_key) {
      if (company_key) {
        return this.$t("company_properties." + company_key.toLowerCase());
      }
      return company_key.toLowerCase().replaceAll("_", " ");
    },
    checkIfPropertyIsOtherThenText(property) {
      return property.company_setting_key.includes("color");
    },
    checkIfPropertyIsColor(property) {
      return property.company_setting_key.toLowerCase().includes("color");
    },
    checkIfPropertyIsDefaultBillingDocument(property) {
      return property.company_setting_key
        .toLowerCase()
        .includes("default_billing_document");
    },
    updatePropData() {
      this.$emit(
        "update:prop_company_properties",
        this.prop_company_properties
      );
    },
    checkPropertyAvailable(property_name) {
      let isAvailable = false;
      if (
        this.prop_company_properties &&
        this.prop_company_properties.length > 0
      ) {
        this.prop_company_properties.forEach((element) => {
          if (
            element.company_setting_key.toLowerCase() ==
            property_name.toLowerCase()
          ) {
            isAvailable = true;
          }
        });
      }
      return isAvailable;
    },

    swatchStyle(property) {
      let color = property.value;
      let menu = property.menu;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "4px" : "4px",
        transition: "border-radius 200ms ease-in-out",
        marginTop: "2px",
        boxShadow: "0px 1px 5px 1px #DCDCDC",
      };
    },
  },
  computed: {
    send_mail_from_address_is_available() {
      return this.checkPropertyAvailable("SEND_MAIL_FROM_ADDRESS");
    },
  },
  watch: {
    shipping_search_country() {
      this.updatePropData();
    },
  },
};
</script>
<style>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
.extra_label {
  font-size: 14px !important;
}
</style>
