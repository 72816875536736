<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1200">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <span class="hidden" id="messageModalActivator"> </span>
        </span>
      </template>
      <v-card>
        <v-card-text class="px-0 py-0">
          <v-row>
            <v-col
              class="col-md-3 col-sm-12 pr-0"
              style="border-right: 1px solid #bdbdbd"
            >
              <div class="d-flex justify-content-between nowrap">
                <v-text-field
                  v-model="searchThreads"
                  :label="$t('messaging_section.search')"
                  outlined
                  dense
                  hide-details
                  disabled
                  class="p-2 ma-2 ml-4"
                ></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="ma-2 ml-0"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="createNewChannel"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("messaging_section.create_new_channel") }}</span>
                </v-tooltip>
              </div>

              <v-list v-if="threads.length > 0">
                <v-subheader>{{
                  $t("messaging_section.channels")
                }}</v-subheader>
                <v-list-item-group
                  v-model="selectedItem"
                  color="primary"
                  style="height: 60vh; overflow-y: auto"
                  class="hide-scrollbar"
                >
                  <v-list-item
                    v-for="(item, i) in threads"
                    :key="i"
                    :dark="i === selectedItem"
                    @click="changeActiveThread(item)"
                    @mouseover="toggleDeleteButton(item, 'show')"
                    @mouseleave="toggleDeleteButton(item, 'hide')"
                  >
                    <v-list-item-icon>
                      <v-icon
                        :color="item.unread_messages_count > 0 ? 'red' : ''"
                      >
                        mdi-account-group</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        :style="{
                          color: item.unread_messages_count > 0 ? 'red' : '',
                        }"
                      >
                        {{ item.subject }}

                        <!--                        Delete Icon Color Red-->
                        <v-icon
                          v-if="userOwnThread(item)"
                          class="ml-2 float-right"
                          style="display: none"
                          :id="'delete_thread_' + item.id"
                          color="red"
                          @click.stop="deleteThreadPopup(item)"
                          >mdi-delete</v-icon
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col class="col-md-9 col-sm-12 pl-0 pb-0">
              <v-card height="100%" width="100%" class="mx-auto" :elevation="0">
                <span ref="messageCard"></span>
                <v-card-title class="mb-2 py-0">
                  <span
                    v-if="activeThread"
                    style="line-height: 1.5"
                    class="mb-1"
                    :class="{ 'cursor-pointer': userOwnThread(activeThread) }"
                    @click="editThread(activeThread)"
                  >
                    {{ activeThread.subject }}
                    <br />
                    <small
                      v-if="activeThread.participants.length >= 1"
                      class="text-caption"
                    >
                      <!--                       A small circle with Participant short name-->
                      <span
                        v-for="participant in activeThread.participants"
                        :key="participant.id"
                        style="cursor: pointer"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                              size="30"
                              class="mr-1"
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="white--text">{{
                                participant.id !== authUserId
                                  ? participant.short_name
                                  : $t("messaging_section.you")
                              }}</span>
                            </v-avatar>
                          </template>
                          <span>{{ participant.name }}</span>
                        </v-tooltip>
                      </span>
                    </small>
                  </span>
                  <span
                    v-else
                    @click="createNewChannel()"
                    class="cursor-pointer"
                  >
                    {{ $t("messaging_section.create_new_channel") }}
                  </span>
                  <v-spacer></v-spacer>
                  <!--                      Close Button-->
                  <v-btn icon @click="dialog = false" class="ml-2 float-right">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                  <!-- List name of Participants in a Thread -->
                </v-card-title>
                <!-- Send Text Message Field in a chat room fixed to the bottom of the Card -->
                <div ref="messagesList" id="messages-list">
                  <v-card-text
                    :key="messagesKey"
                    class="flex-grow-1 overflow-y-auto messages-area"
                    v-if="!threadFormVisible"
                  >
                    <v-card
                      v-for="message in messages"
                      :key="message.time"
                      flat
                    >
                      <v-list-item
                        :key="message.id"
                        v-if="message.user.id !== authUserId"
                      >
                        <v-list-item-avatar class="align-self-start mr-2">
                          <span style="cursor: pointer">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar
                                  size="30"
                                  class="mr-1"
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span class="white--text">{{
                                    message.user.id !== authUserId
                                      ? message.user.short_name
                                      : $t("messaging_section.you")
                                  }}</span>
                                </v-avatar>
                              </template>
                              <span>{{ message.user.name }}</span>
                            </v-tooltip>
                          </span>
                        </v-list-item-avatar>
                        <v-list-item-content class="received-message">
                          <v-card color="success" class="flex-none">
                            <v-card-text
                              class="white--text pa-2 d-flex flex-column"
                            >
                              <!--                              <span class="text-caption">{{message.from}} </span>-->
                              <span
                                class="align-self-start text-subtitle-1 message-text"
                                >{{ message.body }}</span
                              >
                              <span
                                class="text-caption font-italic align-self-end message-date"
                                >{{
                                  formatMessageDate(message.created_at)
                                }}</span
                              >
                            </v-card-text>
                          </v-card>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-else :key="message.id">
                        <v-list-item-content class="sent-message justify-end">
                          <v-card color="primary" class="flex-none">
                            <v-card-text
                              class="white--text pa-2 d-flex flex-column"
                            >
                              <span
                                class="text-subtitle-1 chat-message message-text"
                                >{{ message.body }}

                                <span
                                  class="text-caption font-italic align-self-end message-date float-right"
                                  >{{
                                    formatMessageDate(message.created_at)
                                  }}</span
                                >
                              </span>
                            </v-card-text>
                          </v-card>
                        </v-list-item-content>
                        <v-list-item-avatar class="align-self-start ml-2">
                          <span style="cursor: pointer">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar
                                  size="30"
                                  class="mr-1"
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span class="white--text">{{
                                    message.user.id !== authUserId
                                      ? message.user.short_name
                                      : $t("messaging_section.you")
                                  }}</span>
                                </v-avatar>
                              </template>
                              <span>{{ message.user.name }}</span>
                            </v-tooltip>
                          </span>
                        </v-list-item-avatar>
                      </v-list-item>
                    </v-card>

                    <span ref="messageEndsHere"></span>
                  </v-card-text>
                  <v-card-text
                    class="flex-grow-1 overflow-y-auto messages-area"
                    v-else
                  >
                    <v-form @submit.prevent="updateChannel()">
                      <v-text-field
                        :label="$t('messaging_section.subject')"
                        v-model="form.subject"
                        class="to-upper"
                        ref="threadSubject"
                        id="threadSubject"
                      ></v-text-field>

                      <!--                     List Company users with a Check Box exclude authUserId-->
                      <v-list-item-group multiple>
                        <v-list-item
                          v-for="user in company.users.filter(
                            (u) => u.id !== authUserId
                          )"
                          :key="user.id"
                          :value="user.id"
                          @click="toggleUserCheckBox(user)"
                        >
                          <v-list-item-avatar>
                            <v-avatar size="30" color="primary">
                              <span class="white--text">{{
                                user.short_name
                              }}</span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              user.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="form.participants[user.id]"
                              :ref="'user_checkbox_' + user.id"
                              :id="'user_checkbox_' + user.id"
                              style="pointer-events: none"
                            ></v-checkbox>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list-item-group>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="pb-0">
                    <div
                      style="width: 100%"
                      class="d-flex justify-end mt-2"
                      v-if="threadFormVisible"
                    >
                      <!--                Cancel Button-->
                      <v-spacer></v-spacer>
                      <v-btn
                        color="grey lighten-2"
                        class="mr-2"
                        @click="threadFormVisible = false"
                      >
                        <v-icon>mdi-keyboard-return</v-icon>
                        Cancel
                      </v-btn>

                      <v-btn
                        type="submit"
                        color="primary"
                        @click="updateChannel()"
                        @keyup.enter="updateChannel()"
                        >Update
                      </v-btn>
                    </div>
                    <div
                      class="d-flex justify-content-between"
                      style="width: 100%"
                      v-else
                    >
                      <v-text-field
                        :label="$t('messaging_section.send_message')"
                        ref="messageInput"
                        rows="1"
                        class="my-0 py-0"
                        auto-grow
                        clearable
                        v-model="message"
                        hide-details
                        @keyup.enter="sendMessage"
                        :disabled="!activeThread || messageSending"
                        :loading="messageSending"
                      >
                        <template v-slot:prepend>
                          <v-btn icon small class="ml-1 mb-0 pb-0">
                            <EmojiPickerModal
                              @emojiSelected="
                                (emoji) => handleEmojiSelected(emoji)
                              "
                            />
                          </v-btn>
                        </template>
                      </v-text-field>

                      <!-- Icon for Send Message -->
                      <v-btn
                        icon
                        small
                        class="ml-1 mt-2 mb-0 pb-0 mr-4"
                        @click="sendMessage"
                        :disabled="!activeThread"
                      >
                        <v-icon>mdi-send</v-icon>
                      </v-btn>
                    </div>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="(status) => (status ? deleteThread() : '')"
    ></confirmation-model>
  </v-row>
</template>
<script>
import EmojiPickerModal from "@/components/MessagesThread/EmojiPickerModal.vue";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import moment from "moment";
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
  refs: [
    "messageCard",
    "messageInput",
    "messageThreadForm",
    "messageEndsHere",
    "messagesList",
    "threadSubject",
  ],
  components: {
    ConfirmationModel,
    EmojiPickerModal,
  },
  data() {
    return {
      dialog: false,
      threadName: "",
      threadLoading: false,
      threadFormVisible: false,
      searchThreads: "",
      company: null,
      message: "",
      messageSending: false,
      navKey: 0,
      messagesKey: 0,
      form: {
        subject: "",
        participants: [],
      },
      threads: [],
      notifications: false,
      selectedThread: null,
      confirmationDialog: false,
      confirmationDialogOperation: "",
      confirmationDialogConfirmationText: "",
      confirmationDialogTrueText: "",
      confirmationDialogFalseText: "",
      threadToBeDeleted: null,
      selectedVehicleHistory: null,
      activeThread: null,
      threadMessageKey: 0,
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" },
      ],
      selectedItem: 0,
      messages: [],
      formConfig: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken,
        },
      },
    };
  },
  computed: {
    totalUnreadMessages() {
      if (!this.threads) {
        return 0;
      }

      return this.threads.reduce((acc, thread) => {
        return acc + thread.unread_messages_count;
      }, 0);
    },
    companyUsers() {
      if (!this.company) {
        return;
      }

      return this.company.users.filter(
        (user) => user.id !== parseInt(localStorage.getItem("loginUserId"))
      );
    },
    authUserId() {
      return parseInt(localStorage.getItem("loginUserId"));
    },
  },
  watch: {
    dialog() {
      this.navKey++;
      if (this.activeThread) {
        this.getThreadMessages(this.activeThread.id).then(() => {
          this.scrollToEndOfMessages();
        });
      }

      clearInterval(this.$store.state.getMessageInterval);

      // TODO: This is working code we have to enable it later
      //   if(val){
      //     //Set 5 seconds Interval
      //     this.$store.commit('setGetMessageInterval', setInterval(() => this.getUserThreadUpdates(), 5000))
      //   }else{
      //     // Set 1 Minute Interval
      //     this.$store.commit('setGetMessageInterval', setInterval(() => this.getUserThreadUpdates(), 60000))
      //   }
    },
    activeThread(val) {
      if (!val || !this.dialog) return;

      this.getThreadMessages(val.id).then(() => this.scrollToEndOfMessages());
    },
    totalUnreadMessages(val) {
      this.$store.state.totalUnreadMessagesCount = val;
    },
  },
  mounted() {
    this.getCompany();
    this.getCompanyThreads().then(() => {
      if (this.threads.length > 0) {
        this.activeThread = this.threads[0];
      }
    });

    // Set 1 Minute Interval ::: TODO: This is working code we have to enable it later
    // this.$store.commit('setGetMessageInterval', setInterval(() => this.getUserThreadUpdates(), 60000))
  },
  methods: {
    toggleDeleteButton(item, action = "hide") {
      let elementRef = "delete_thread_" + item.id;

      if (!document.getElementById(elementRef)) {
        return;
      }

      document.getElementById(elementRef).style.display =
        action === "show" ? "" : "none";
    },
    handleEmojiSelected(emoji) {
      this.message += emoji.toString();
    },
    scrollToEndOfMessages() {
      this.$nextTick(() => {
        if (!this.$refs.messageEndsHere) return;
        this.$refs.messageEndsHere.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      });
    },
    scrollToStartOfMessages() {
      this.$nextTick(() => {
        if (!this.$refs.messagesList) return;
        this.$refs.messagesList.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
    userOwnThread(thread) {
      if (!thread) return;
      return thread.owner.id === this.authUserId;
    },
    toggleUserCheckBox(user) {
      document.getElementById("user_checkbox_" + user.id).click();
    },
    getCompany() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_BASE_URL + "/companies/" + this.$store.state.companyId,
            this.formConfig
          )
          .then((response) => {
            this.company = response.data.data;
            resolve(this.company);
          })
          .catch((error) => reject(error));
      });
    },
    updateChannel() {
      let form = JSON.parse(JSON.stringify(this.form));

      form.participants = Object.keys(form.participants)
        .filter((key) => form.participants[key] === true)
        .map((key) => parseInt(key));

      if (form.subject === "") {
        return this.$toast.error(
          this.$t("messaging_section.please_enter_channel_name")
        );
      }

      if (form.participants.length === 1) {
        return this.$toast.error(
          this.$t("messaging_section.select_participant")
        );
      }

      this.threadLoading = true;

      let axiosMethod = this.selectedThread
        ? axios.put(
            API_BASE_URL + "/message_threads/" + this.selectedThread.id,
            form,
            this.formConfig
          )
        : axios.post(API_BASE_URL + "/message_threads", form, this.formConfig);

      axiosMethod
        .finally(() => {
          this.threadLoading = false;
        })
        .then((response) => {
          // add it to the beginning of threads
          this.threads.shift(response.data.data);
          this.threadFormVisible = false;
          this.form = {
            subject: "",
            participants: [],
          };
          this.getCompanyThreads().then(() => {
            this.activeThread = this.threads.find(
              (thread) => thread.id === this.activeThread.id
            );
          });
          let successMessage = this.$t("messaging_section.channel_updated");
          this.$toast.success(successMessage);
        })
        .catch((error) => {
          let errorMessage;
          if (error?.response?.data?.errors) {
            errorMessage = this.filterFirstError(error.response.data.errors);
          } else {
            errorMessage =
              error?.response?.message ?? "An unknown error Occurred";
          }
          this.$toast.error(errorMessage);
        });
    },
    createNewChannel() {
      this.threadLoading = true;

      axios
        .post(API_BASE_URL + "/message_threads", {}, this.formConfig)
        .finally(() => {
          this.threadLoading = false;
        })
        .then((response) => {
          // add it to the beginning of threads
          this.threads.shift(response.data.data);
          this.threadFormVisible = false;
          this.getCompanyThreads().then(() => {
            this.activeThread = this.threads[0];
            this.editThread(this.activeThread);
            setTimeout(() => {
              if (document.getElementById("threadSubject")) {
                document.getElementById("threadSubject").focus();
                document.getElementById("threadSubject").select();
              }
            }, 100);
          });
          let successMessage = this.$t("messaging_section.channel_created");
          this.$toast.success(successMessage);
        })
        .catch((error) => {
          console.log(error.response);
          let errorMessage;
          if (error?.response?.data?.errors) {
            errorMessage = this.filterFirstError(error.response.data.errors);
          } else {
            errorMessage =
              error?.response?.message ?? "An unknown error Occurred";
          }
          this.$toast.error(errorMessage);
        });
    },
    getCompanyThreads() {
      return new Promise((resolve, reject) => {
        return axios
          .get(API_BASE_URL + "/message_threads", this.formConfig)
          .then((response) => {
            let threadData = response.data.data;

            let sysTemThreads = threadData.filter((thread) =>
              thread.type.key.includes("system")
            );
            let userThreads = threadData.filter((thread) =>
              thread.type.key.includes("normal")
            );

            this.threads = [...sysTemThreads, ...userThreads];
            resolve(this.threads);
          })
          .catch((error) => reject(error));
      });
    },
    toggleThreadForm() {
      this.threadFormVisible = !this.threadFormVisible;
      if (!this.threadFormVisible) {
        this.selectedThread = null;
      }
    },
    editThread(item) {
      if (!this.userOwnThread(item)) {
        return false;
      }

      this.selectedThread = item;
      let participants = {};

      item.participants.forEach((participant) => {
        participants[participant.id] = true;
      });
      this.form = {
        subject: item.subject,
        participants: participants,
      };
      this.threadFormVisible = true;

      // scroll to top
      setTimeout(() => {
        this.scrollToStartOfMessages();
      }, 100);
    },
    deleteThreadPopup(item) {
      this.confirmationDialogConfirmationText = this.$t(
        "messaging_section.delete_thread_warning"
      );
      this.confirmationDialogFalseText = this.$t("navigation.no");
      this.confirmationDialogTrueText = this.$t("navigation.yes");
      this.confirmationDialogOperation = "deleteThread";
      this.threadToBeDeleted = item;
      this.confirmationDialog = true;
    },
    deleteThread() {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            API_BASE_URL + "/message_threads/" + this.threadToBeDeleted.id,
            this.formConfig
          )
          .then((response) => {
            this.threads = this.threads.filter(
              (item) => item.id !== this.threadToBeDeleted.id
            );
            if (this.activeThread.id === this.threadToBeDeleted.id) {
              this.activeThread = this.threads[0];
            }
            this.$toast.success(this.$t("messaging_section.channel_deleted"));
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    changeActiveThread(item) {
      if (this.confirmationDialog) {
        return false;
      }

      this.threadFormVisible = false;
      this.activeThread = null;
      this.$nextTick(() => {
        this.activeThread = item;
      });
      // Cancel the previous interval
      // clearInterval(this.getMessageInterval)

      // this.getMessageInterval = setInterval(() => {
      //   this.getThreadMessages(item.id)
      // }, 5000)
    },
    sendMessage() {
      if (this.message === "") {
        return this.$toast.error(this.$t("messaging_section.enter_message"));
      }

      this.messageSending = true;
      let form = {
        body: this.message,
        thread_id: this.activeThread.id,
      };

      axios
        .post(API_BASE_URL + "/messages", form, this.formConfig)
        .finally(() => {
          this.messageSending = false;
        })
        .then((response) => {
          this.messages.push(response.data.data);
          this.message = "";
          this.scrollToEndOfMessages();
        })
        .catch((error) => {
          console.log(error.response);
          let errorMessage;
          if (error?.response?.data?.errors) {
            errorMessage = this.filterFirstError(error.response.data.errors);
          } else {
            errorMessage =
              error?.response?.message ??
              this.$t("messaging_section.unknown_error");
          }
          this.$toast.error(errorMessage);
        });
    },
    getThreadMessages(threadId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_BASE_URL + "/messages?thread_id=" + threadId,
            this.formConfig
          )
          .then((response) => {
            this.messages = response.data.data;

            let threadIndex = this.threads.findIndex(
              (item) => item.id === threadId
            );
            // update unread messages count
            this.threads[threadIndex].unread_messages_count = 0;

            resolve(this.messages);
          })
          .catch((error) => reject(error));
      });
    },
    formatMessageDate(date) {
      // Format date to be like 2021-01-01 HH:MM AM/PM
      // return moment(date).format('YYYY-MM-DD hh:mm A')
      return moment(date).format("hh:mm");
    },
    getUserThreadUpdates() {
      return new Promise((resolve, reject) => {
        if (!localStorage.getItem("accessToken")) {
          clearInterval(this.$store.state.getMessageInterval);
          return resolve();
        }
        let dialogState = this.dialog ? 1 : 0;
        let url =
          API_BASE_URL +
          "/message_thread_updates?thread_is_open=" +
          dialogState;

        let sentThreadId = null;

        if (this.activeThread) {
          sentThreadId = this.activeThread.id;
          url += "&active_thread_id=" + sentThreadId;
        }

        axios
          .get(url, this.formConfig)
          .then((response) => {
            let threadData = response.data.data;

            if (this.activeThread && this.activeThread.id === sentThreadId) {
              this.messages = [
                ...this.messages,
                ...threadData.active_thread_new_messages,
              ];
              this.scrollToEndOfMessages();
            }

            // Update unread messages count for all threads or push as new thread
            threadData.threads.forEach((item) => {
              let threadIndex = this.threads.findIndex(
                (thread) => thread.id === item.id
              );
              if (threadIndex === -1) {
                this.threads.push(item);
              }

              this.threads[threadIndex].unread_messages_count =
                item.unread_messages_count;
              // Update Thread Subject
              this.threads[threadIndex].subject = item.subject;
            });

            // remove deleted threads from the list
            let deletedThreadIds = [];

            this.threads.forEach((item) => {
              let threadIndex = threadData.threads.findIndex(
                (thread) => thread.id === item.id
              );
              if (threadIndex === -1) {
                deletedThreadIds.push(item.id);
              }
            });

            this.threads = this.threads.filter(
              (item) => !deletedThreadIds.includes(item.id)
            );

            // check if active thread is deleted
            if (
              this.activeThread &&
              deletedThreadIds.includes(this.activeThread.id)
            ) {
              this.activeThread = this.threads[0];
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              localStorage.clear();
            }
            reject(error);
          });
      });
    },
  },
};
</script>

<style scoped>
.messages-area {
  overflow-y: scroll;
  height: 60vh;
  width: 100%;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.messages-area::-webkit-scrollbar {
  display: none;
}

.chat-message {
  display: unset !important;
  white-space: break-spaces;
}

.chat-screen {
  max-height: 320px;
  overflow-y: auto;
}

.flex-none {
  flex: unset;
}

.received-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #4caf50 transparent transparent transparent;
}

.sent-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #1976d2 transparent transparent transparent;
}

.message-text {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  line-height: 1 !important;
}
.v-list-item {
  padding-right: 2px !important;
}
</style>
