var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1200"},on:{"click:outside":function($event){return _vm.cancelDialog('cancel')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancelDialog('cancel')}},model:{value:(_vm.internalShowDialog),callback:function ($$v) {_vm.internalShowDialog=$$v},expression:"internalShowDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[(_vm.workpointprop && typeof _vm.workpointprop == 'object')?[_vm._v(" "+_vm._s(_vm.$t("edit"))+" "+_vm._s(_vm.$t(_vm.label))+" ")]:[_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")]],2),_c('v-card-text',[_c('v-form',{staticClass:"main_form",attrs:{"id":"client-form"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{ref:"client_name",staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('company_settings.name'),"error-messages":_vm.$store.state.validation && !_vm.$v.name
                    ? [
                        this.$t(
                          'vehicles.clients_model.text_messages.name_required'
                        ),
                      ]
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'justalphanumeric', 50)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('AddressInput',{staticClass:"mt-n4",attrs:{"singleLine":false,"showAddressType":false,"defaultAddressType":'billing_address',"label":_vm.$t('billings.address'),"searchWithoutCountry":true,"showLocationName":false,"required":true},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1),_c('v-row',{staticClass:"mt-n6"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('vehicles.clients_model.email'),"error-messages":_vm.$store.state.validation
                    ? _vm.returnErrorMessage(
                        [
                          !_vm.$v.email.required,
                          this.$t('users.text_messages.email_required'),
                        ],
                        [
                          !_vm.$v.email.email,
                          this.$t('users.text_messages.invalid_email'),
                        ]
                      )
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'email', 60)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(" "+_vm._s(_vm.$t("vehicles.clients_model.email"))+" ")]},proxy:true}]),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('vue-tel-input-vuetify',{attrs:{"disabledFetchingCountry":true,"preferredCountries":_vm.preferredCountry,"filterCountries":_vm.onlyCountries,"validCharactersOnly":"","label":_vm.$t('vehicles.clients_model.phone'),"inputOptions":{ showDialCode: true, tabindex: 0 },"mode":'international'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('vue-tel-input-vuetify',{attrs:{"disabledFetchingCountry":true,"preferredCountries":_vm.preferredCountry,"filterCountries":_vm.onlyCountries,"validCharactersOnly":"","label":_vm.$t('vehicles.clients_model.gsm'),"inputOptions":{ showDialCode: true, tabindex: 0 },"mode":'international'},model:{value:(_vm.gsm),callback:function ($$v) {_vm.gsm=$$v},expression:"gsm"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('vue-tel-input-vuetify',{attrs:{"disabledFetchingCountry":true,"preferredCountries":_vm.preferredCountry,"filterCountries":_vm.onlyCountries,"validCharactersOnly":"","label":_vm.$t('vehicles.clients_model.fax'),"inputOptions":{ showDialCode: true, tabindex: 0 },"mode":'international'},model:{value:(_vm.fax),callback:function ($$v) {_vm.fax=$$v},expression:"fax"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),(
          _vm.workpointprop &&
          typeof _vm.workpointprop == 'object' &&
          _vm.workpointprop.id
        )?_c('v-btn',{staticClass:"mr-1 text-white-color",attrs:{"id":"top-delete","color":"error"},on:{"click":function($event){return _vm.deleteMember()}}},[_vm._v(" "+_vm._s(_vm.$t("nav_buttons.delete"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-delete ")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.cancelDialog('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("vehicles.Cancel"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1),_c('v-btn',{attrs:{"color":"success","disabled":!(_vm.address && _vm.name) || _vm.$store.state.isLoading},on:{"click":function($event){return _vm.saveData()}}},[_vm._v(" "+_vm._s(_vm.$t("vehicles.Save"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }