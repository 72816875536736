<template>
  <div style="display: flex">
    <v-combobox
      class="to-upper"
      :autocomplete="autocompleteValueLocal"
      v-model="client"
      :items="clients"
      item-value="id"
      item-text="name"
      :label="$t(label)"
      @change="removeMember()"
      v-on:keydown="handleInput($event, 'alphanumeric', 50)"
      :search-input.sync="search_client"
      no-filter
      v-on:keydown.enter.prevent=""
      return-object
      :outlined="is_boxes"
      :dense="is_boxes"
      :style="showDenomination ? 'width: 80%; max-width: 80%;' : ''"
      :error-messages="errorMessages"
      :class="blocked ? 'blockedclient' : ''"
      :disabled="disabled"
    >
      <template #label>
        <span v-if="required" class="red--text"><strong>* </strong></span>
        {{ $t(label) }}
      </template>
      <template slot="append">
        <v-icon @click="editClient()" v-if="showPencilButton"
          >mdi-pencil</v-icon
        >
        <v-icon v-if="client || value" @click="removeClientCompany()"
          >mdi-close</v-icon
        >
        <!-- <v-icon v-if="!client && typeof client" @click="addClient()">mdi-plus</v-icon> -->
      </template>
      <template slot="prepend">
        <v-tooltip
          right
          v-if="
            client && typeof client == 'object' && client.status_id == 10041003
          "
        >
          <span>{{ $t("clients.client_blocked_full") }}</span>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-1" v-on="on" v-bind="attrs" color="red"
              >mdi-account-alert</v-icon
            >
          </template>
        </v-tooltip>
      </template>
      <template slot="item" slot-scope="{ item }">
        <v-list-item-content>
          <p class="client_name">
            {{ item.name }} {{ item.first_name }}
            <template v-if="item.company && item.company.tva">
              - {{ item.company.tva }}
            </template>
          </p>
          <p class="client_detail">
            <template>
              <span v-if="item.representative_company_id">{{
                $t("entity_type.company")
              }}</span>
              <span v-else>{{ $t("entity_type.private") }}</span>
            </template>
            <template v-if="item.email"> - {{ item.email }} </template>
            <template v-if="item.phone"> - {{ item.phone }} </template>
            <template
              v-if="item.company_address && item.company_address.length > 0"
            >
              <br />
              {{ computeAddressLabel(item.company_address[0].address) }}
            </template>
          </p>
        </v-list-item-content>
      </template>

      <template slot="selection" slot-scope="{ item }">
        <v-list-item-content :class="blocked ? 'blockedtext' : ''">
          <template v-if="item && typeof item == 'object'">
            <p class="client_name">
              <strong>
                {{ item.name }} {{ item.first_name }}
                <template v-if="item.company && item.company.tva">
                  - {{ item.company.tva }}
                </template>
              </strong>
            </p>
            <p class="client_detail">
              <template>
                <span v-if="item.representative_company_id">{{
                  $t("entity_type.company")
                }}</span>
                <span v-else>{{ $t("entity_type.private") }}</span>
              </template>
              <template v-if="item.email"> - {{ item.email }} </template>
              <template
                v-if="item.company_address && item.company_address.length > 0"
              >
                <br />
                <span class="address_text">{{
                  computeAddressLabel(getMainAddress(item.company_address))
                }}</span>
              </template>
            </p>
          </template>
          <template v-if="item && typeof item == 'string'">
            {{ item.toUpperCase() }}
          </template>
        </v-list-item-content>
      </template>
    </v-combobox>
    <v-select
      v-if="showDenomination"
      class="to-upper mt-1"
      v-model="denomination"
      :items="denominations"
      item-value="id"
      item-text="text"
      v-on:keydown.enter.prevent=""
      :outlined="is_boxes"
      hide-details
      return-object
      style="width: 20%; max-width: 20%"
    ></v-select>

    <b7-edit-client-model
      v-if="openEditClientModel"
      @addressUpdatedFromPopUp="emitAddresssChanged"
      @buttonClicked="updatedClient"
      :defaultClient="value"
      :showDialog.sync="openEditClientModel"
      :addMember="false"
      :expeditor_tva="tva"
      :email_required="email_required"
      :tva_required="tva_required"
      :address_required="address_required"
    ></b7-edit-client-model>
  </div>
</template>
<script>
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
import B7_EditClientModel from "@/components/B7G/EditClientModel.vue";
export default {
  model: { prop: "value", event: "input" },
  components: {
    "b7-edit-client-model": B7_EditClientModel,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    label: {
      type: String,
      default: "billings.comp_name",
    },
    tva: {
      type: String,
      default: null,
    },
    address: {
      type: Object,
      default: null,
    },
    selectedDenomination: {
      type: [Object, String, Number],
      default: null,
    },
    showDenomination: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array],
      default: Array,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    email_required: {
      type: Boolean,
      default: true,
    },
    tva_required: {
      type: Boolean,
      default: true,
    },
    address_required: {
      type: Boolean,
      default: true,
    },
    showPencilButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      client: null,
      clients: [],
      search_client: null,
      denominations: [],
      denomination: null,
      openEditClientModel: false,
    };
  },
  mounted() {
    this.getCompanyDenominations();
  },
  methods: {
    getMainAddress(company_addressess) {
      if (company_addressess && company_addressess.length > 0) {
        let main_address = null;
        company_addressess.forEach((company_address) => {
          if (
            company_address.address_type_id == 10511001 &&
            typeof company_address.address == "object"
          ) {
            // Main address
            main_address = company_address.address;
          }
        });
        return main_address ? main_address : company_addressess[0].address;
      }
    },
    emitAddresssChanged(data) {
      this.client.company_address = [];
      this.client.company_address[0] = data;
      this.$emit("update:address", data);
      this.$emit("addressUpdated", data);
    },
    addClient() {
      this.value = null;
      this.openEditClientModel = true;
    },
    editClient() {
      if (
        (typeof this.client == "string" || !this.client) &&
        this.search_client
      ) {
        this.client = this.search_client;
      }
      this.openEditClientModel = true;
    },
    updatedClient(val) {
      if (val && val.id) {
        let ifAlreadyExists = false;
        this.clients.forEach((element, index) => {
          if (element.id == val.id) {
            ifAlreadyExists = index;
          }
        });
        if (ifAlreadyExists !== false) {
          this.clients[ifAlreadyExists] = val;
        } else if (ifAlreadyExists >= 0) {
          this.clients.push(val);
        }
        // Select member Automatically
        this.client = val;
      }
      this.$emit("refreshData", val);
    },
    removeMember() {
      //
    },
    removeClientCompany() {
      this.search_client = null;
      this.client = null;
      this.value = null;
      this.clients = [];
      this.$emit("cleared", true);
    },
    fetchClientsListDebounced() {
      if (!this.search_client || typeof this.search_client == "object") {
        return;
      }
      // This means it is in edit mode
      axios
        .get(
          API_BASE_URL +
            "/clients/search?companiesOnly=true&searchRegNumber=true&searchTva=true" +
            (this.search_client
              ? "&search=" + encodeURIComponent(this.search_client)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          data.data.forEach((element) => {
            element.clientname =
              element.name + " " + (element.first_name ?? "");
          });
          this.clients = data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //
        });
    },
    async getCompanyDenominations() {
      await this.getCodes("company_denomination", "company_denomination").then(
        (data) => {
          this.denominations = data;
          this.denomination = this.denominations[0];
        }
      );
    },
    getDenominationById(denomination_id) {
      let selectDenominationId = null;
      this.denominations.forEach((element) => {
        if (element.id == denomination_id) {
          selectDenominationId = element;
        }
      });
      return selectDenominationId;
    },
  },
  watch: {
    search_client(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchClientsListDebounced();
      }, 500);
    },
    client(val) {
      if (val && val.company) {
        this.denomination = this.getDenominationById(
          val.company.denomination_id
        );
      }
      this.$emit("input", val);
    },
    value(val) {
      if (val && typeof val == "object") {
        this.clients = [val];
        this.client = val;
      } else if (val && typeof val == "string") {
        this.search_client = val;
      }
    },
    denomination(val) {
      this.$emit("update:selectedDenomination", val);
    },
  },
};
</script>
<style>
.client_name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.client_detail {
  font-size: 10px;
  margin: 0px !important;
  white-space: nowrap;
  overflow: hidden;
}
.address_text {
  font-size: 12px;
}
.client_more_detail {
  font-size: 10px;
}
.v-list-item__content {
  padding: 0px;
  margin: 0px;
}
.v-list-item__content ~ input {
  width: 2px !important;
  min-width: 2px !important;
  max-width: 2px !important;
}
.blockedclient .v-input__control .v-input__slot {
  color: #ffc4c4 !important;
}
.blockedtext {
  color: #ff5252 !important;
}
/* .blockedclient .v-input__control .v-input__slot .v-select__slot label{
    background-color: #ffc4c4 !important;
} */
</style>
