<template>
  <v-card flat>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-form
      ref="form"
      class="main_form"
      id="family-client-form"
      @submit.prevent="onSubmit()"
    >
      <v-btn type="submit" class="d-none" id="submit-family-client"></v-btn>
      <v-container fluid>
        <v-row>
          <v-col col="1" sm="2">
            <v-checkbox
              :label="$t('family_article.is_default')"
              v-model="is_default"
            ></v-checkbox>
          </v-col>
          <v-col col="1" sm="2">
            <v-checkbox
              :label="$t('family_article.is_stocks_managed')"
              v-model="is_stock_managed"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col>
            <v-autocomplete
                disabled
                :autocomplete="autocompleteValueLocal"
                flat
                :items="family_articles"
                v-model="parent"
                :label="$t('family_article.parent')"
                class="mr-2 to-upper"
                item-value="id"
                item-text="name"
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
                hide-no-data
            ></v-autocomplete>
          </v-col> -->
          <v-col>
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="name"
              name="name"
              class="to-upper"
              v-on:keydown="handleInput($event, 'alphanumeric', 50)"
              :label="$t('family_article.name')"
              required
              :error-messages="
                $store.state.validation && !$v.name.required
                  ? [this.$t('family_article.name_required')]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              :autocomplete="autocompleteValueLocal"
              flat
              :items="tvas"
              v-model="tva"
              :label="$t('family_article.tva')"
              class="mr-2 to-upper"
              item-value="id"
              item-text="customText"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :autocomplete="autocompleteValueLocal"
              flat
              :items="mus"
              v-model="mu"
              :label="$t('family_article.mu')"
              class="mr-2 to-upper"
              item-value="id"
              item-text="text"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
              :error-messages="
                $store.state.validation && !$v.mu.required
                  ? [this.$t('family_article.mu_required')]
                  : []
              "
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              flat
              v-model="default_quantity"
              :label="$t('family_article.default_quantity')"
              class="mr-2 to-upper"
              :outlined="is_boxes"
              :dense="is_boxes"
              :error-messages="
                $store.state.validation && !$v.default_quantity.required
                  ? [this.$t('family_article.default_quantity_required')]
                  : []
              "
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              flat
              v-model="quantity_decimals"
              :label="$t('family_article.quantity_decimals')"
              class="mr-2 to-upper"
              :outlined="is_boxes"
              :dense="is_boxes"
              :error-messages="
                $store.state.validation && !$v.quantity_decimals.required
                  ? [this.$t('family_article.quantity_decimals_required')]
                  : []
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn
      @click="onSubmit()"
      class="d-none"
      id="submit-family-article"
    ></v-btn>
    <v-btn
      @click="onDelete()"
      class="d-none"
      id="delete-family-article"
    ></v-btn>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DETAILS_MODE, JUST_CANCEL_MODE } from "@/config";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  mixins: [validationMixin],
  components: {
    "confirmation-model": ConfirmationModel,
  },
  validations: {
    name: {
      required: required,
    },
    default_quantity: {
      required: required,
    },
    quantity_decimals: {
      required: required,
    },
    mu: {
      required: required,
    },
  },
  name: "FamilyArticle",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      DeleteConfirm: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      family_articles: [],
      family_article: {},
      autocompleteValueLocal: "off",
      is_default: 0,
      name: "",
      parent: null,
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      mus: [],
      mu: null,
      tvas: [],
      tva: null,
      default_quantity: null,
      quantity_decimals: 1,
      is_stock_managed: null,
    };
  },
  mounted() {
    this.$store.commit("setValidation", false);
    this.autocompleteValueLocal = this.autocompleteValue();
    this.header = { Authorization: "Bearer " + this.token };
    this.getTvas();
    this.getMeasurementUnits();
    this.getAllFamilyArticles().then(() => {
      this.$store.commit("setValidation", false);
      if (this.$store.state.editId) {
        this.fetchData();
      } else {
        this.$store.commit("setFormMode", DETAILS_MODE);
      }
    });
  },
  methods: {
    getTvas() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/tvas", { headers: this.header })
          .then(({ data }) => {
            for (let index in data) {
              data[index]["customText"] =
                data[index].country_id +
                " (" +
                data[index].tax +
                "%)" +
                " - " +
                this.$t("tva" + "." + data[index].level.key);
            }
            this.tvas = data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          });
      });
    },
    async getMeasurementUnits() {
      await this.getCodes("measurement_units", "measurement_units").then(
        (data) => {
          this.mus = data;
        }
      );
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deleteItem() {
      axios({
        method: "delete",
        url: API_BASE_URL + "/family_articles/" + this.$store.state.editId,
        headers: this.header,
      })
        .then(() => {
          this.$router.push("/family-articles").catch(() => {});
        })
        .catch(() => {
          //
        })
        .finally(() => {});
    },
    onDelete() {
      console.log("delete clicked");
      this.deletePopUp();
    },
    fetchData() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/family_articles/" + this.$store.state.editId, {
            headers: this.header,
          })
          .then(({ data }) => {
            this.family_article = data.data;
            this.name = this.family_article.name;
            this.is_default = this.family_article.is_default;
            this.tva = this.family_article.tva;
            this.mu = this.family_article.mu;
            this.default_quantity = this.family_article.default_quantity;
            this.quantity_decimals = this.family_article.quantity_decimals;
            this.is_stock_managed = this.family_article.is_stock_managed;

            // this.parent = data.data.parent_family;
            if (this.family_article.company_id == null) {
              this.$store.commit("setFormMode", JUST_CANCEL_MODE);
            } else {
              this.$store.commit("setFormMode", DETAILS_MODE);
            }
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {});
      });
    },
    onSubmit() {
      this.$store.commit("setValidation", true);
      if (!this.$v.$invalid) {
        if (this.$store.state.editId) {
          this.updateFamilyArticle(this.makeData());
        } else {
          this.addFamilyArticle(this.makeData());
        }
      } else {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
      }
    },
    makeData() {
      return {
        // parent_family_id : this.parent ? this.parent.id : null,
        name: this.name,
        is_default: this.is_default ? this.is_default : false,
        tva_id: this.tva ? this.tva.id : null,
        mu_id: this.mu ? this.mu.id : null,
        default_quantity: this.default_quantity ?? null,
        quantity_decimals: this.quantity_decimals ?? null,
        is_stock_managed: this.is_stock_managed ?? null,
      };
    },
    addFamilyArticle(data) {
      axios
        .post(API_BASE_URL + "/family_articles", data, { headers: this.header })
        .then(() => {
          this.$router.push("/family-articles").catch(() => {});
        })
        .catch(function () {
          this.$toast.error(
            this.$t("family_article.text_messages.something_went_wrong")
          );
        })
        .finally(() => {});
    },
    getAllFamilyArticles() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/family_articles", { headers: this.header })
          .then((data) => {
            this.family_articles = data.data.data;
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {});
      });
    },
    updateFamilyArticle(data) {
      axios
        .put(
          API_BASE_URL + "/family_articles/" + this.family_article.id,
          data,
          { headers: this.header }
        )
        .then(() => {
          this.$router.push("/family-articles").catch(() => {});
        })
        .catch(function (error) {
          console.log("an error occured " + error);
          this.$toast.error(
            this.$t("family_article.text_messages.something_went_wrong")
          );
        })
        .finally(() => {});
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "family_article.DeleteConfirmation"
      );
      this.confirmationDialogFalseText = this.$t("family_article.Cancel");
      this.confirmationDialogTrueText = this.$t("family_article.Delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
  },
  computed: {},
  watch: {
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "delete" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.deleteItem();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
};
</script>

<style scoped></style>
