<template>
    <v-menu
      v-model="menu"
      :close-on-content-click="range ? false : true"
      :nudge-right="0"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedDate"
          :label="label || $t('billings.date')"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          :hide-details="hideDetails"
          append-icon="mdi-close"
          :class="classData ? classData : ''"
          @click:append="onDateChange(null)"
          :style="maxWidth ? { maxWidth: typeof maxWidth === 'number' ? maxWidth + 'px' : maxWidth } : {}"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :first-day-of-week="1"
        :weekday-format="getDay"
        @input="onDateChange"
        :disabled="disabled"
        :hide-details="hideDetails"
        :elevation="0"
        :range="range"
      ></v-date-picker>
    </v-menu>
  </template>
  
  <script>
  export default {
    name: "DateInput",
    emits: ["input", "change"],
    props: {
      value: {
        type: [String, Array],
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: null,
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      range: {
        type: Boolean,
        default: false,
      },
      classData: {
        type: String,
        default: ''
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      maxWidth: {
        type: [String, Number],
        default: null
      }
    },
    data() {
      return {
        date: null,
        menu: false,
      };
    },
    watch: {
      value: {
        immediate: true,
        handler(value) {
          this.date = value;
        },
      },
    },
    computed: {
      formattedDate() {
        if (!this.date) return null;
        return this.range && Array.isArray(this.date)
          ? this.date.map(this.formatDateRange).join(" - ")
          : this.formatDate(this.date);
      },
    },
    methods: {
      onDateChange(date) {
        this.date = date;
        this.$emit("input", date);
        this.$emit("change", date);
        console.log("date change", date);
        if(this.range && this.date && this.date.length == 2){
            this.menu = false;
        }
        else if(!this.range){
            this.menu = false
        }
      },
      formatDateRange(date) {
        if (!date) return "";
        return Array.isArray(date)
          ? date.map(d => this.formatDate(d)).join(" - ")
          : this.formatDate(date);
      },
    },
  };
  </script>
  