var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.searchProperties),function(filter,index){return _c('div',{key:index},[(filter.property_name == 'property_00')?[(filter.property_name == 'property_00')?_c('v-autocomplete',{ref:filter.property_name,refInFor:true,staticClass:"mr-2 to-upper",style:({ width: '120px' }),attrs:{"flat":"","items":_vm.brands,"search-input":_vm.searchBrand,"label":_vm.$t('articles.properties.brand'),"item-value":"id","item-text":"name","append-icon":"mdi-close","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"update:searchInput":function($event){_vm.searchBrand=$event},"update:search-input":function($event){_vm.searchBrand=$event},"click:append":function($event){_vm.brand = null}},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}}):_vm._e()]:[(filter.mapping_key != null)?_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.multiselectsValues[index],"label":filter.text,"item-value":"id","hide-details":"auto","append-icon":"mdi-close","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"click:append":function($event){filter.value = null;
          _vm.fetchData();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();_vm.isDebounceCancelled = true;
          _vm.fetchData();}},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.mapping_key == null)?_c('v-text-field',{ref:filter.property_name,refInFor:true,staticClass:"mr-2 to-upper",class:{
          'fixed-width': filter.property_name == 'property_60',
        },attrs:{"flat":"","label":filter.text,"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"focus":function($event){filter.value = null;
          _vm.resetFurtherBoxesValues(filter.property_name);
          _vm.updateLocalStorageValues();},"keypress":($event) => {
            // If key pressed is enter then fetch Data
            if ($event.keyCode == 13) {
              _vm.fetchData();
            }
            _vm.handleInput($event, filter.keytype, filter.search_length);
          },"keyup":function($event){_vm.handleAutoFocus(
            $event,
            filter.search_length,
            filter.property_name,
            filter.keytype,
            _vm.filterPropertiesExcept(_vm.customFilters)[index + 1]
              ? _vm.filterPropertiesExcept(_vm.customFilters)[index + 1].property_name
              : null
          )},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();_vm.isDebounceCancelled = true;
          _vm.fetchData();}},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e()]],2)}),(_vm.articleBooleanProperties.length >= 1)?_c('v-select',{attrs:{"label":_vm.$t('articles.properties.more'),"items":_vm.articleBooleanProperties,"item-value":"id","item-text":"name","multiple":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"return-object":""},on:{"change":function($event){return _vm.updateLocalStorageValues()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [_c('span',[(index === 0 && _vm.booleanSelectedValues.length == 1)?[_vm._v(" "+_vm._s(item.name.substr(0, _vm.booleanSelectedValues.length > 1 ? 3 : 5))+" ")]:_vm._e(),(index === 1)?[_vm._v(" (+"+_vm._s(_vm.booleanSelectedValues.length - 1)+") ")]:_vm._e()],2)]}}],null,false,1526793794),model:{value:(_vm.booleanSelectedValues),callback:function ($$v) {_vm.booleanSelectedValues=$$v},expression:"booleanSelectedValues"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }