<template>
  <div>
    <v-row>
      <v-col :md="2">
        <v-combobox
          :items="formattedEmployees"
          :label="translate('filter_by_employee')"
          item-text="text"
          v-model="selectedEmployee"
          clearable
        >
        </v-combobox>
      </v-col>
      <v-col :md="2">
        <v-select
          :items="applicableClockingTypes"
          :label="translate('clocking_type')"
          v-model="selectedClockingType"
          item-text="text"
          return-object
          item-value="id"
          clearable
        />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="formattedEmployeeRequests"
      class="elevation-0"
      :height="'calc(-200px + 100vh)'"
    >
      <template v-slot:item.is_approved="{ item }">
        <v-chip v-if="item.is_approved" color="success" text-color="white">
          {{ $t("approved") }}
        </v-chip>
      </template>

      <template v-slot:item.approved_by="{ item }">
        <span v-if="item.is_approved">
          {{ item.approved_by }}
        </span>
        <v-btn
          v-else
          small
          color="success"
          @click="openApproveAppointmentDialog(item.id)"
        >
          {{ translate("approve") }}

          <v-icon class="ml-1 mb-1">mdi-check</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </div>
</template>
<script>
import axios from "axios";
import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import clockingMixin from "@/mixins/clockingMixin";
import ConfirmationModel from "@/components/ConfirmationModel";
import moment from "moment";

export default {
  name: "EmployeeRequestListing",
  mixins: [clockingMixin],
  components: {
    ConfirmationModel,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("srno"),
          width: 50,
          value: "serial",
          sortable: false,
        },
        {
          text: this.translate("name"),
          value: "name",
          sortable: false,
        },
        {
          text: this.translate("type"),
          value: "type",
          sortable: false,
        },
        {
          text: this.translate("start_date"),
          value: "start_date",
          sortable: false,
        },
        {
          text: this.translate("end_date"),
          value: "end_date",
          sortable: false,
        },
        {
          text: this.translate("requested"),
          value: "requested",
          sortable: false,
        },
        {
          text: this.translate("allotted"),
          value: "allotted",
          sortable: false,
        },
        {
          text: this.translate("consumed"),
          value: "consumed",
          sortable: false,
        },
        {
          text: this.translate("available"),
          value: "available",
          sortable: false,
        },
        {
          text: this.translate("approved_by"),
          value: "approved_by",
          sortable: false,
        },
      ],
      selectedEmployee: null,
      selectedClockingType: null,
      perPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      page: 1,
      clockingTypes: [],
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogOperation: null,
      idOfRequestToApprove: null,
    };
  },
  async mounted() {
    this.employees = await this.getEmployees();
    this.employeeCounters = await this.getEmployeeCounters();
    this.employeeContracts = await this.getEmployeeContracts();
    this.employmentCounters = await this.getEmploymentCounters();
    this.clockingTypes = await this.getCodes("clocking_types");
    let employeeRequests = await this.getEmployeeRequests();
    this.employeeRequests = employeeRequests.data;
  },
  computed: {
    formattedEmployeeRequests() {
      return this.employeeRequests.map((item, index) => {
        let employeeCounter = this.employeeCounters.find(
          (counter) => counter.id === item.employee_counter_id
        );
        let employmentCounter = this.employmentCounters.find(
          (counter) => counter.id === employeeCounter.counter_id
        );
        let employeeContract = this.employeeContracts.find(
          (contract) => contract.id === employeeCounter.employee_contract_id
        );
        let employee = this.employees.find(
          (employee) => employee.id === employeeContract.employee_id
        );
        let type = this.clockingTypes.find(
          (type) => type.id === employmentCounter.counter_type_id
        );

        let requested =
          moment(item.end).diff(moment(item.start), "minutes") / (60 * 24);

        //check if requested is float
        if (requested % 1 !== 0) {
          requested =
            requested < 1 ? requested.toFixed(2) : Math.ceil(requested);
        }

        return {
          serial: (this.page - 1) * this.perPage + index + 1,
          id: item.id,
          name: employee.first_name + " " + employee.last_name,
          type: this.translate("types." + type.key),
          start_date: this.formatDateWithLocale(item.start),
          end_date: this.formatDateWithLocale(item.end),
          is_approved: item.is_approved,
          approved_by: item.approved_by?.name ?? "",
          action: item.id,
          requested: requested,
          allotted: employeeCounter.initial_value,
          consumed: employeeCounter.value,
          available: employeeCounter.available,
        };
      });
    },
    filters() {
      return {
        employee_id: this.selectedEmployee?.id ?? null,
        counter_type_id: this.selectedClockingType?.value ?? null,
      };
    },
    applicableClockingTypes() {
      let whiteList = ["vacation", "sick_leave"];
      return this.clockingTypes
        .filter((type) => whiteList.includes(type.key))
        .map((type) => {
          return {
            text: this.translate("types." + type.key),
            value: type.id,
          };
        });
    },
  },
  watch: {
    async filters(val) {
      this.employeeRequests = (await this.getEmployeeRequests(val)).data;
    },
  },
  methods: {
    approveEmployeeRequest(requestId) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${API_BASE_URL}/employees/request/${requestId}/approve`,
            {},
            { headers: this.axiosOptions }
          )
          .finally(() => {})
          .then(async (response) => {
            resolve(response.data);
            this.$toast.success("Request approved successfully");
            this.employeeRequests = (await this.getEmployeeRequests()).data;
            this.confirmationDialog = false;
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    openApproveAppointmentDialog(requestId) {
      this.confirmationDialogConfirmationText =
        this.translate("approve_request");
      this.confirmationDialogFalseText = this.$t("cancel");
      this.confirmationDialogTrueText = this.$t("approve");
      this.confirmationDialogOperation = "approve";
      this.idOfRequestToApprove = requestId;
      this.confirmationDialog = true;
    },
    async confirmationButtonClicked(action) {
      if (!action) return;

      if (this.confirmationDialogOperation === "approve") {
        await this.approveEmployeeRequest(this.idOfRequestToApprove);
      }
    },
  },
};
</script>
