<template>
  <v-text-field
    v-if="
      currentBillPeppolStatus &&
      currentBillPeppolStatus.companyIsPeppol &&
      currentBillPeppolStatus.billQualifiesForPeppol
    "
    :style="{ maxWidth: '150px' }"
    class="mx-2"
    :label="'PEPPOL ' + $t('billings.status')"
    :disabled="true"
    :value="currentBillPeppolStatus.message"
  >
    <template #prepend-inner>
      <v-icon :color="currentBillPeppolStatus.color">mdi-send-outline</v-icon>
    </template>
  </v-text-field>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["currentBillPeppolStatus"]),
  },
  name: "PeppolStatusField",
};
</script>
