<template>
  <v-dialog :value="value" :max-width="isToBeUpdated ? '900px' : '600px'">
    <v-card>
      <v-card-title class="headline">
        {{ translate("employee_counter") }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col :cols="isToBeUpdated ? 4 : 12">
              <v-select
                :key="forceReloadComponentKey"
                :label="translate('counter')"
                v-model="form.counter_id"
                :items="availableCounters"
                item-text="label"
                item-value="id"
                :disabled="isToBeUpdated"
              >
              </v-select>
            </v-col>
            <v-col :cols="isToBeUpdated ? 4 : 6">
              <v-text-field
                :label="translate('initial_value')"
                v-model="form.initial_value"
                @keydown="(event) => handleInput(event, 'int')"
              >
                <template #append>
                  <span class="mx-3">{{ selectedCounterMeasurement }}</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col :cols="isToBeUpdated ? 4 : 6">
              <v-menu
                v-model="expiryDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDay"
                    :label="translate('select_expiry_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.expire_at"
                  @input="expiryDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-if="isToBeUpdated">
            <v-card-title class="w-100">
              Details
              <v-spacer></v-spacer>
              <v-col cols="10" v-if="showDetailForm">
                <v-form @submit.prevent="saveDetail" ref="detail_form">
                  <v-row>
                    <v-col :cols="5">
                      <v-text-field
                        :label="translate('value')"
                        v-model="detail.value"
                        required="true"
                        type="number"
                        :rules="[(v) => !!v || translate('value_is_required')]"
                      >
                        <template #prepend>
                          <span
                            class="mx-3 cursor-pointer"
                            @click="showDetailForm = !showDetailForm"
                            ><v-icon>mdi-close</v-icon></span
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col :cols="5">
                      <v-text-field
                        :label="translate('message')"
                        v-model="detail.message"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col :cols="2" class="mt-4">
                      <span><SaveButton :small="true" /></span>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <!--            <span @click="showDetailForm = true" v-if="!showDetailForm"><AddButton :small="true"/></span>-->
            </v-card-title>
            <v-col cols="12">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>{{ translate("date") }}</th>
                      <th>{{ translate("value") }}</th>
                      <th>{{ translate("message") }}</th>
                      <!--                 <th></th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in details"
                      :key="item.id"
                      @mouseenter="hoveredDetail = item.id"
                      @mouseleave="hoveredDetail = null"
                    >
                      <td>
                        {{
                          formatDateWithLocale(
                            item.created_at,
                            true,
                            "YYYY-MM-DD"
                          )
                        }}
                      </td>
                      <td>{{ item.value }} {{ selectedCounterMeasurement }}</td>
                      <td>{{ item.message }}</td>
                      <!--                 <td>-->
                      <!--                   <v-icon @click="confirmDelete('deleteDetail',item.id)" color="red" :style="{-->
                      <!--                   visibility: hoveredDetail === item.id ? 'visible' : 'hidden'-->
                      <!--                 }">mdi-trash-can</v-icon>-->
                      <!--                 </td>-->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span v-if="isToBeUpdated" @click="confirmDelete"
          ><DeleteButton
        /></span>
        <span class="mx-3" @click="close"><CloseButton /></span>
        <span @click="save"><SaveButton /></span>
      </v-card-actions>
    </v-card>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      :checkboxText.sync="confirmationDialogReCheckBoxConfirmationText"
      @buttonClicked="(e) => confirmationButtonClicked(e, deleteHandler)"
    />
  </v-dialog>
</template>
<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";
import moment from "moment";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import CloseButton from "@/components/Buttons/CloseButton.vue";
import DeleteButton from "@/components/Buttons/DeleteButton.vue";
import ConfirmationModelMixin from "@/mixins/ConfirmationModelMixin";
import clockingMixin from "@/mixins/clockingMixin";
// import AddButton from "@/components/Buttons/AddButton.vue";
export default {
  name: "EmployeeCounterForm",
  components: { DeleteButton, CloseButton, SaveButton },
  emits: ["input", "updated"],
  mixins: [ConfirmationModelMixin, clockingMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    employee: {
      type: Object,
      default: () => ({}),
    },
    employeeCounter: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isToBeUpdated() {
      return this.employeeCounter !== null;
    },
    formattedDay: {
      get() {
        return this.form.expire_at
          ? moment(this.form.expire_at).format("YYYY-MM-DD")
          : "";
      },
      set(value) {
        this.form.expire_at = value;
      },
    },
    availableCounters() {
      if (this.isToBeUpdated) {
        return [this.employeeCounter.employment_counter].map((i) => {
          i.label = this.translate("types." + i.name);
          return i;
        });
      }

      let employeeEmploymentCounterIds = this.employeeCounters.map(
        (i) => i.counter_id
      );
      return this.employmentCounters
        .filter((counter) => !employeeEmploymentCounterIds.includes(counter.id))
        .map((i) => {
          i.label = this.translate("types." + i.name);
          return i;
        });
    },
    forceReloadComponentKey() {
      return this.$store.state.forceReloadComponentKey;
    },
    selectedCounter() {
      if (!this.form.counter_id) {
        return null;
      }

      return this.employmentCounters.find((i) => i.id === this.form.counter_id);
    },
    selectedCounterMeasurement() {
      if (!this.selectedCounter) {
        return null;
      }

      return this.$t(
        "measurement_units_plural." + this.selectedCounter.measurement_unit.key
      );
    },
  },
  async mounted() {
    await this.getEmploymentCounters();
  },
  data() {
    return {
      form: {
        counter_id: null,
        initial_value: null,
        expire_at: moment().endOf("year").format("YYYY-MM-DD"),
      },
      expiryDateMenu: false,
      employmentCounters: [],
      employeeCounters: [],
      detail: {
        value: null,
        message: null,
      },
      details: [],
      showDetailForm: false,
      hoveredDetail: null,
    };
  },
  watch: {
    showDetailForm(val) {
      if (!val) {
        this.detail = {
          value: null,
          message: null,
        };
      }
    },
    employee() {
      this.getEmployeeCounters();
    },
    async value(val) {
      if (!val) {
        this.showDetailForm = false;
        return;
      }

      await this.getEmploymentCounters();

      if (this.isToBeUpdated) {
        let employeeCounter = this.employeeCounter;
        this.form = {
          initial_value: employeeCounter.initial_value,
          expire_at: employeeCounter.expire_at,
          counter_id: employeeCounter.employment_counter.id,
        };

        this.getEmployeeCounterDetails();
      }
    },
    async forceReloadComponentKey() {
      await this.getEmployeeCounters();
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    async getEmploymentCounters() {
      try {
        const response = await axios.get(
          API_BASE_URL + "/employment/counters",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          }
        );

        this.employmentCounters = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getEmployeeCounters() {
      try {
        const response = await axios.get(
          API_BASE_URL +
            "/employees/counters?employee_contract_id=" +
            this.employee?.active_contract?.id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          }
        );

        this.employeeCounters = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      try {
        let payload = {
          employee_contract_id: this.employee?.active_contract?.id,
          employment_counter_id: this.form.counter_id,
          initial_value: this.form.initial_value,
          expire_at: this.form.expire_at,
        };
        let axiosHeaders = {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        };
        try {
          this.$store.state.isLoading = true;
          this.isToBeUpdated
            ? await axios.put(
                API_BASE_URL + "/employees/counters/" + this.employeeCounter.id,
                payload,
                axiosHeaders
              )
            : await axios.post(
                API_BASE_URL + "/employees/counters",
                payload,
                axiosHeaders
              );

          this.$store.state.isLoading = false;

          this.close();
          this.$store.state.forceReloadComponentKey++;
          this.form = {
            counter_id: null,
            initial_value: null,
            expire_at: moment().endOf("year").format("YYYY-MM-DD"),
          };
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveDetail() {
      // do form validation
      if (!this.$refs.detail_form.validate()) {
        return;
      }

      try {
        let payload = {
          employee_counter_id: this.employeeCounter.id,
          value: this.detail.value,
          message: this.detail.message,
        };
        let axiosHeaders = {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        };

        this.$store.state.isLoading = true;
        await axios.post(
          API_BASE_URL + "/employees/counters/details",
          payload,
          axiosHeaders
        );

        this.$store.state.isLoading = false;

        this.showDetailForm = false;
        this.$store.state.forceReloadComponentKey++;
        this.getEmployeeCounterDetails();
      } catch (error) {
        console.log(error);
      }
    },
    deleteHandler() {
      if (this.confirmationDialogOperation === "deleteHandler") {
        this.deleteCounter();
      }

      if (this.confirmationDialogOperation === "deleteDetail") {
        this.deleteEmployeeCounterDetail(this.confirmationDialogData);
      }
    },
    deleteCounter() {
      axios
        .delete(
          API_BASE_URL + "/employees/counters/" + this.employeeCounter.id,
          { headers: this.axiosOptions }
        )
        .then(async () => {
          this.$toast.success(this.$t("articles.deleted_successfully"));
          this.close();
          this.$store.state.forceReloadComponentKey++;
        })
        .catch(() => {
          this.$toast.error(this.translate("delete_error"));
        });
    },
    confirmDelete(action = "deleteHandler", data = null) {
      this.popConfirmation(
        this.$t("attachment.delete_attachment_confirmation"),
        action,
        null,
        null,
        this.$t("billings.convert_to_credit_note_confirmation_reconfirm_text"),
        data
      );
    },
    getEmployeeCounterDetails() {
      return axios
        .get(
          API_BASE_URL +
            "/employees/counters/" +
            this.employeeCounter.id +
            "/details",
          { headers: this.axiosOptions }
        )
        .then((response) => {
          this.details = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteEmployeeCounterDetail(detailId) {
      this.$store.state.isLoading = true;
      axios
        .delete(API_BASE_URL + "/employees/counters/details/" + detailId, {
          headers: this.axiosOptions,
        })
        .finally(() => (this.$store.state.isLoading = false))
        .then(async () => {
          this.$toast.success(this.$t("articles.deleted_successfully"));
          this.getEmployeeCounterDetails();
        })
        .catch(() => {
          this.$toast.error(this.translate("delete_error"));
        });
    },
  },
};
</script>
