<template>
  <div class="home">
    <Dashboard />
  </div>
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard";
import { VIEW_MODE } from "@/config";

export default {
  name: "Home",
  components: {
    Dashboard,
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.commit("setFormMode", VIEW_MODE);
  },
};
</script>
