<template>
    <div v-if="isPageLoaded">
      <v-row class="pl-2 pr-2">
        <v-col cols="12" sm="2" class="pr-5">
          <v-row>
            <v-col cols="7" class="pr-0">
              <v-select
                v-model="familyArticle"
                :items="stockManagedFamilyArticles"
                :label="$t('articles.family')"
                item-value="id"
                item-text="name"
                class="mr-2"
                return-object
                :prepend-icon="
                  normalSearch ? 'mdi-grid' : 'mdi-rectangle-outline'
                "
                @click:prepend="normalSearch = !normalSearch"
                :outlined="is_boxes"
                :dense="is_boxes"
                :disabled="isLoading"
              ></v-select>
            </v-col>
            <v-col cols="5" class="pl-0">
              <v-select
                v-model="familyClient"
                :items="familyClients"
                item-value="id"
                item-text="name"
                :label="$t('pricing_rule.family_client')"
                :outlined="is_boxes"
                :dense="is_boxes"
                :disabled="isLoading"
                menu-props="{ contentClass: 'menu-higher-z-index-then-nav' }"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
  
        <!-- Search -->
        <v-col class="d-flex pl-0" cols="12" sm="7">
          <v-row>
            <template v-for="(searchCount, searchIndex) in searchCounts">
              <v-col
                :key="searchIndex"
                class="d-flex pl-0"
                cols="12"
                sm="12"
                xl="12"
                :class="searchIndex != 0 ? 'mt-n10' : ''"
              >
                <template
                  v-if="
                    normalSearch ||
                    customFilters.length == 0 ||
                    (!normalSearch &&
                      filterPropertiesExcept(customFilters[searchIndex]).length ==
                        0)
                  "
                >
                  <v-text-field
                    class="ml-3 to-upper"
                    label="Search"
                    :disabled="isLoading"
                    prepend-inner-icon="mdi-magnify"
                    v-bind:clearable="true"
                    clear-icon="mdi-close"
                    v-model="search"
                    v-on:keydown.enter.prevent="
                      isDebounceCancelled = true;
                      fetchData();
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </template>
                <template v-if="!normalSearch">
                  <!-- Season -->
                  <v-btn-toggle
                    :disabled="isLoading"
                    id="season-button"
                    v-if="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                    "
                    v-model="season"
                    dense
                    class="mb-0 mr-2 mt-5"
                    :class="searchIndex == 0 ? '' : 'opacityZero'"
                    style="margin-top: 15px"
                    :style="{ width: '150px' }"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small value="10111002" :disabled="isLoading">
                          <!-- Summer -->
                          <v-icon
                            style="font-size: 22px"
                            v-bind="attrs"
                            v-on="on"
                            dark
                            color="amber darken-4"
                          >
                            mdi-weather-sunny
                          </v-icon>
                          <!-- <span> {{ $t("codes.shorts.summer") }} </span> -->
                        </v-btn>
                      </template>
                      <span> {{ $t("codes.summer") }} </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small value="10111001" :disabled="isLoading">
                          <!-- Winter -->
                          <v-icon
                            style="font-size: 22px"
                            v-bind="attrs"
                            v-on="on"
                            dark
                            color="blue darken-2"
                          >
                            mdi-weather-snowy-heavy
                          </v-icon>
                          <!-- <span> {{ $t("codes.shorts.winter") }} </span> -->
                        </v-btn>
                      </template>
                      <span> {{ $t("codes.winter") }} </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small value="10111003" :disabled="isLoading">
                          <!-- All Season -->
                          <v-icon
                            style="font-size: 22px"
                            v-bind="attrs"
                            v-on="on"
                            dark
                            color="green darken-1"
                          >
                            mdi-weather-partly-snowy-rainy
                          </v-icon>
                          <!-- <span> {{ $t("codes.shorts.all_season") }} </span> -->
                        </v-btn>
                      </template>
                      <span> {{ $t("codes.all_season") }} </span>
                    </v-tooltip>
                  </v-btn-toggle>
                  <!-- Custom Filters -->
                  <div
                    v-for="(filter, filterIndex) in filterPropertiesExcept(
                      customFilters[searchIndex]
                    )"
                    :key="filterIndex"
                  >
                    <template
                      v-if="
                        customFilters[searchIndex][filterIndex + 1]
                          .property_name == 'property_00'
                      "
                    >
                      <v-autocomplete
                        v-if="
                          customFilters[searchIndex][filterIndex + 1]
                            .property_name == 'property_00'
                        "
                        :disabled="searchIndex > 0 || isLoading"
                        :class="searchIndex > 0 ? 'opacityZero' : ''"
                        flat
                        :ref="
                          customFilters[searchIndex][filterIndex + 1]
                            .property_name
                        "
                        :items="brands"
                        :search-input.sync="searchBrand"
                        v-model="brand"
                        :label="$t('articles.properties.brand')"
                        class="mr-2 to-upper"
                        item-value="id"
                        item-text="name"
                        append-icon="mdi-close"
                        @click:append="brand = null"
                        return-object
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        hide-no-data
                        :style="{ width: '120px' }"
                      ></v-autocomplete>
                    </template>
                    <template>
                      <!-- Dynamic Dropdown -->
                      <template
                        v-if="
                          customFilters[searchIndex][filterIndex + 1]
                            .mapping_key != null
                        "
                      >
                        <!-- Dropdown is not season -->
                        <v-autocomplete
                          v-if="
                            customFilters[searchIndex][filterIndex + 1]
                              .property_name != 'property_02'
                          "
                          :disabled="isLoading"
                          v-model="
                            customFilters[searchIndex][filterIndex + 1].value
                          "
                          :items="multiselectsValues[searchIndex]"
                          :label="
                            customFilters[searchIndex][filterIndex + 1].text
                          "
                          item-value="id"
                          hide-details="auto"
                          class="mr-2"
                          append-icon="mdi-close"
                          @click:append="
                            customFilters[searchIndex][filterIndex + 1].value =
                              null;
                            fetchData();
                          "
                          v-on:keydown.enter.prevent="
                            isDebounceCancelled = true;
                            fetchData();
                          "
                          :outlined="is_boxes"
                          :dense="is_boxes"
                          hide-no-data
                        ></v-autocomplete>
                      </template>
                      <!-- Dynamic Text Fields -->
                      <template
                        v-if="
                          customFilters[searchIndex][filterIndex + 1]
                            .mapping_key == null &&
                          customFilters[searchIndex][filterIndex + 1]
                            .property_name != 'property_00'
                        "
                      >
                        <v-text-field
                          flat
                          :disabled="isLoading"
                          v-if="
                            customFilters[searchIndex][filterIndex + 1]
                              .mapping_key == null
                          "
                          v-model="
                            customFilters[searchIndex][filterIndex + 1].value
                          "
                          :label="
                            customFilters[searchIndex][filterIndex + 1].text
                          "
                          class="mr-2 to-upper"
                          :class="{
                            'fixed-width':
                              customFilters[searchIndex][filterIndex + 1]
                                .property_name == 'property_60',
                          }"
                          :ref="
                            customFilters[searchIndex][filterIndex + 1]
                              .property_name
                          "
                          @focus="
                            customFilters[searchIndex][filterIndex + 1].value =
                              null;
                            resetFurtherBoxesValues(
                              customFilters[searchIndex][filterIndex + 1]
                                .property_name,
                              searchIndex
                            );
                          "
                          @keypress="
                            ($event) => {
                              if ($event.keyCode == 13) {
                                fetchData();
                              }
                              handleInput(
                                $event,
                                customFilters[searchIndex][filterIndex + 1]
                                  .keytype,
                                customFilters[searchIndex][filterIndex + 1]
                                  .search_length
                              );
                            }
                          "
                          @keyup="
                            handleAutoFocus(
                              $event,
                              customFilters[searchIndex][filterIndex + 1]
                                .search_length,
                              customFilters[searchIndex][filterIndex + 1]
                                .property_name,
                              customFilters[searchIndex][filterIndex + 1].keytype,
                              filterPropertiesExcept(customFilters[searchIndex])[
                                filterIndex + 1
                              ]
                                ? filterPropertiesExcept(
                                    customFilters[searchIndex]
                                  )[filterIndex + 1].property_name
                                : null,
                              searchIndex,
                              filterIndex
                            )
                          "
                          v-on:keydown.enter.prevent="
                            isDebounceCancelled = true;
                            fetchData();
                          "
                          :outlined="is_boxes"
                          :dense="is_boxes"
                        ></v-text-field>
                      </template>
                    </template>
                  </div>
                  <v-select
                    v-if="articleBooleanProperties.length >= 1"
                    v-model="booleanSelectedValues[searchIndex]"
                    :label="$t('articles.properties.more')"
                    :items="articleBooleanProperties"
                    item-value="id"
                    item-text="name"
                    multiple
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    return-object
                    :disabled="isLoading"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span>
                        <template
                          v-if="
                            index === 0 &&
                            booleanSelectedValues[searchIndex].length == 1
                          "
                        >
                          {{
                            item.name.substr(
                              0,
                              booleanSelectedValues[searchIndex].length > 1
                                ? 3
                                : 5
                            )
                          }}
                        </template>
                        <template v-if="index === 1">
                          (+{{ booleanSelectedValues[searchIndex].length - 1 }})
                        </template>
                      </span>
                    </template>
                  </v-select>
                </template>
  
                <!-- Min. Quantity -->
                <v-text-field
                  v-if="familyArticle"
                  class="to-upper ml-2"
                  style="max-width: 50px"
                  :label="$t('stocks.min_quantity_to_search')"
                  v-model="min_quantity_to_search"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  v-on:keydown.enter.prevent="fetchData()"
                  :disabled="isLoading"
                ></v-text-field>
  
                <!-- Search Button -->
                <v-btn
                  dense
                  small
                  class="ml-2 mt-5"
                  color="success"
                  :dark="isLoading ? false : true"
                  @click="fetchDataForInternalStock()"
                  :class="searchIndex > 0 ? 'opacityZero' : ''"
                  :disabled="isLoading"
                >
                  <v-icon dark>mdi-database-search</v-icon>
                </v-btn>
                <v-btn
                  dense
                  small
                  class="ml-2 mt-5"
                  color="primary"
                  :dark="isLoading ? false : true"
                  @click="fetchDataForExternalStock()"
                  :class="searchIndex > 0 ? 'opacityZero' : ''"
                  :disabled="isLoading"
                >
                  <v-icon dark>mdi-database-search</v-icon>
                </v-btn>
                <!-- Add New Search Button -->
                <v-tooltip bottom class="search_tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2 mt-5"
                      :class="searchIndex > 0 ? 'opacityZero' : ''"
                      color="warning"
                      :dark="isLoading ? false : true"
                      :disabled="isLoading"
                      @click="
                        searchCounts.length > 1
                          ? removeAdditionalSearch(searchIndex + 1)
                          : addAdditionalSearch()
                      "
                      v-if="
                        familyArticle &&
                        (familyArticle.parent_family_id == 1 ||
                          familyArticle.id == 1)
                      "
                    >
                      <v-icon dark>{{
                        searchCounts.length > 1 ? "mdi-minus" : "mdi-plus"
                      }}</v-icon>
                      <!-- <span v-if="savedSearches.length > 0">
                                                    {{ savedSearches.length }}
                                                </span> -->
                    </v-btn>
                  </template>
                  <!-- <span v-html="formatSavedSearches(savedSearches)"> 
                                        </span> -->
                  <span v-html="formatSavedSearches(savedSearches)"> </span>
                </v-tooltip>
              </v-col>
            </template>
          </v-row>
        </v-col>
  
        <!-- Extra Buttons -->
        <v-col class="d-flex pl-0 justify-end" cols="12" sm="3">
          <NewCombinedInput
            :familyArticle="familyArticle"
            :withProposedArticles.sync="withProposedArticles"
            :stockUnits.sync="stockUnits"
            :withTvaWithoutTva.sync="withTvaWithoutTva"
            :withOnlyInternalStock.sync="withOnlyInternalStock"
            class="pl-2"
            :isLoading="isLoading"
          />
          <!-- Suppliers -->
  
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="ml-2">
                <!-- Select Headers -->
                <v-menu
                  min-width="300px"
                  bottom
                  left
                  v-model="supplierModel"
                  v-if="suppliers && suppliers.length > 0"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      dense
                      small
                      class="mt-5 pl-0 pr-0 to-upper"
                      :color="
                        unCheckedSupplierFilters > 0
                          ? 'red text-white'
                          : 'success text-white'
                      "
                      :disabled="isLoading"
                    >
                      <v-icon class="mr-1" v-if="supplierColumnVisibility == true"
                        >mdi-playlist-check</v-icon
                      >
                      <v-icon
                        class="mr-1"
                        color="white"
                        v-if="supplierColumnVisibility == false"
                        >mdi-playlist-remove</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="mb-0">
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="12"
                          sm="6"
                          class="mr-0 pr-0 d-flex justify-end"
                        >
                          <span style="font-size: 14px">
                            {{ $t("stocks.select_unselect") }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          class="ml-0 pl-0 pr-1 d-flex justify-end"
                        >
                          <v-checkbox
                            style="position: absolute; z-index: 9"
                            label=""
                            class="mt-0"
                            color="green"
                            hide-details
                            :disabled="isLoading"
                            :indeterminate="checkAllSubHeaderIntermediate"
                            v-model="checkAllSubHeader"
                            @click="checkUncheckAllSubHeader()"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-list>
                      <template v-if="subHeader.length > 0">
                        <draggable
                          v-model="tempSubHeader"
                          :options="{ group: 'people' }"
                          style="min-height: 10px"
                        >
                          <template v-for="item in tempSubHeader">
                            <v-list-item
                              v-if="item.text != 'BRAND'"
                              :key="item.order"
                              @click="
                                item.is_read_only
                                  ? ''
                                  : toggleColumnVisibility(item);
                                markIntermediateStateAfterCheck();
                              "
                              :class="item.is_read_only ? 'grey lighten-4' : ''"
                            >
                              <v-list-item-content>
                                <v-list-item-title min-width="200px">
                                  <v-row>
                                    <v-col cols="12" sm="2">
                                      <v-icon class="rowHandle">mdi-drag</v-icon>
                                    </v-col>
                                    <v-col cols="12" sm="8">
                                      <span class="text-sm-left">
                                        {{ item.text }}
                                      </span></v-col
                                    >
                                    <v-col cols="12" sm="2">
                                      <v-checkbox
                                        style="position: absolute; z-index: 9"
                                        label=""
                                        class="mt-n2"
                                        @click="
                                          item.is_read_only
                                            ? ''
                                            : toggleColumnVisibility(item);
                                          markIntermediateStateAfterCheck();
                                        "
                                        v-model="item.visible"
                                        color="green"
                                        hide-details
                                        :disabled="
                                          item.is_read_only ? true : false
                                        "
                                      ></v-checkbox>
                                      <!-- <v-icon v-if="item.visible == true"  class="rowHandle">mdi-check</v-icon>  -->
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </draggable>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row>
                                <v-col>
                                  <span class="pa-1">
                                    {{ $t("stocks.no_suppliers_found_for") }}
                                    {{ familyArticle ? familyArticle.name : "" }}
                                  </span>
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                    <v-spacer></v-spacer>
                      <v-card-actions v-if="subHeader.length > 0" class="mt-0 pt-0">
                        <v-btn
                          color="success"
                          class="ma-0 text-white-color"
                          block
                          @click="
                            supplierModel = false;
                            updateHeadersListingOrder();
                          "
                          dense
                          small
                        >
                          {{ $t("action_texts.okay") }}
                        </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </template>
            {{ $t("stocks.suppliers") }}
          </v-tooltip>
        </v-col>
      </v-row>
  
      <v-data-table
        id="suppliers_model_table"
        :footer-props="{
          'items-per-page-options': dataTableOptions,
        }"
        :no-data-text="$t('no_results_text')"
        :hide-default-footer="isLoading"
        fixed-header
        :headers="mainHeader"
        :options.sync="options"
        :items="articles"
        :items-per-page="itemsPerPage"
        :sort-by="sortBy"
        :server-items-length="totalItems"
        :loading-text="this.$t('loading')"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        class="elevation-0 mainTable"
        item-key="identifier"
        style="width: 100%"
        :height="calculateDataTableHeightDynamically"
        dense
        @update:sort-by="updateSort"
        @update:sort-desc="updateSortOrder"
        :key="forceReloadComponentKey"
      >
        <template v-slot:item="{ item, index }">
          <tr class="stockTable">
            <!-- Other Slots after first -->
            <td
              :class="
                familyArticle &&
                (familyArticle.parent_family_id == 1 || familyArticle.id == 1)
                  ? 'stock-sticky-col-tyre-pnc-1'
                  : familyArticle &&
                    (familyArticle.parent_family_id == 2 || familyArticle.id == 2)
                  ? 'stock-sticky-col-pnc-1'
                  : 'stock-sticky-col-pnc-2'
              "
              class="pl-2 pa-1 text-sm-left"
              @mouseover="
                getElementByIdForPinning(
                  generateElementIdForPinning(item, null, index),
                  ''
                );
                getElementByIdForPinning(
                  generateElementIdForMinArticleStock(item, null, index),
                  ''
                );
                getElementByIdForPinning(
                  generateElementIdForCopy(item, null, index),
                  ''
                );
              "
              @mouseleave="
                getElementByIdForPinning(
                  generateElementIdForPinning(item, null, index),
                  'none'
                );
                getElementByIdForPinning(
                  generateElementIdForMinArticleStock(item, null, index),
                  'none'
                );
                getElementByIdForPinning(
                  generateElementIdForCopy(item, null, index),
                  'none'
                );
              "
            >
              <div
                style="display: flex; justify-content: space-between; margin: 2px"
              >
                <!-- Tyre -->
                <div>
                  <template v-if="item.property_01">
                    <strong>
                      {{
                        $t(
                          "articles.types.shorts." +
                            getKeyFromCode(tyreTypes, item.property_01)
                        )
                      }}</strong
                    >
                  </template>
                  <template v-if="item.description">
                    {{ item.description }}
                  </template>
                  <template v-else>{{ item.description }} </template>
                  <br />
                  <TextComponentWithExtraIcons
                    :value="item.identifier"
                  ></TextComponentWithExtraIcons>
                </div>
                <!--, Season Icons -->
                <div
                  class="d-flex align-content-center flex-no-wrap justify-end flex-sm-grow-0"
                >
                  <!-- Pin Icon -->
                  <v-icon
                    @click="
                      pinned = !pinned;
                      hideAllOtherItems(item, index, pinned);
                    "
                    v-if="item.identifier"
                    :id="generateElementIdForPinning(item, null, index)"
                    :style="{
                      fontSize: calendarIconSize,
                      display: 'none',
                    }"
                    :color="pinned ? 'orange' : 'blue'"
                  >
                    {{ pinned ? "mdi-pin-off" : "mdi-pin" }}
                  </v-icon>
  
                  <!-- Star Icon -->
                  <v-icon
                    @click="showMinArticleStockPopUp(item)"
                    v-if="item.identifier"
                    :id="generateElementIdForMinArticleStock(item, null, index)"
                    :style="[
                      item.is_favorite
                        ? {
                            fontSize: calendarIconSize,
                            display: 'block',
                          }
                        : {
                            fontSize: calendarIconSize,
                            display: 'none',
                          },
                    ]"
                    :color="'orange'"
                  >
                    mdi-star
                  </v-icon>
  
                  <!-- Image -->
                  <v-icon
                    class="imgicon"
                    v-if="item.length != 0"
                    color="blue"
                    @click.stop.prevent="showImage(item)"
                    >mdi-image
                  </v-icon>
                  <!-- Season -->
                  <template v-if="item.property_01">
                    <div class="text-center div_icon d-flex align-center">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="
                              'summer' ==
                              getKeyFromCode(tyreSeasons, item.property_02)
                            "
                            dark
                            color="amber darken-4"
                            >mdi-weather-sunny</v-icon
                          >
  
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="
                              'all_season' ==
                              getKeyFromCode(tyreSeasons, item.property_02)
                            "
                            dark
                            color="green darken-1"
                            >mdi-weather-partly-snowy-rainy</v-icon
                          >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="
                              'winter' ==
                              getKeyFromCode(tyreSeasons, item.property_02)
                            "
                            dark
                            color="blue darken-2"
                            >mdi-weather-snowy-heavy</v-icon
                          >
                        </template>
                        <span
                          v-if="
                            'summer' ==
                            getKeyFromCode(tyreSeasons, item.property_02)
                          "
                        >
                          {{ $t("codes.summer") }}
                        </span>
                        <span
                          v-if="
                            'no_season' ==
                            getKeyFromCode(tyreSeasons, item.property_02)
                          "
                        >
                          {{ $t("codes.no_season") }}
                        </span>
                        <span
                          v-if="
                            'all_season' ==
                            getKeyFromCode(tyreSeasons, item.property_02)
                          "
                        >
                          {{ $t("codes.all_season") }}
                        </span>
                        <span
                          v-if="
                            'winter' ==
                            getKeyFromCode(tyreSeasons, item.property_02)
                          "
                        >
                          {{ $t("codes.winter") }}
                        </span>
                      </v-tooltip>
                    </div>
                  </template>
                </div>
              </div>
            </td>
  
            <!-- Tariff Price -->
            <td
              :class="
                familyArticle &&
                (familyArticle.parent_family_id == 1 || familyArticle.id == 1)
                  ? 'stock-sticky-col-tyre-pnc-2'
                  : 'stock-sticky-col-pnc-2'
              "
              class="pl-2 pa-1 text-sm-center"
            >
              <template>
                <span v-if="item.length == 0"> </span>
                <span v-else>
                  {{
                    item.tariff_price
                      ? item.tariff_price.toFixed(getDecimalNumber())
                      : (0).toFixed(getDecimalNumber())
                  }}
                </span>
              </template>
            </td>
            <!-- Wide boxes -->
            <td
              :class="[
                familyArticle &&
                (familyArticle.parent_family_id == 1 || familyArticle.id == 1)
                  ? 'stock-sticky-col-tyre-pnc-3'
                  : 'stock-sticky-col-pnc-3',
                setRowColorForWideBoxOfInternal(item),
              ]"
              class="pl-2 pr-1 pb-0 pt-0 mt-0 mb-0 text-sm-center"
            >
              <v-row
                @click="change_stock_detail_dialog(item, 1)"
                @mouseover="
                  getElementById(generateElementId(item, null, index), '');
                  getElementById(generateElementId(item, null, index), '');
                "
                @mouseleave="
                  getElementById(generateElementId(item, null, index), 'none')
                "
              >
                <!-- Current Stock -->
                <v-col
                  cols="2"
                  class="my-auto pl-0 pr-0"
                  :class="
                    item.length != 0 ? 'stock_right_border biggerTextFont' : ''
                  "
                >
                  <template v-if="item.length == 0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ $t("stocks.current_stock") }}
                        </span>
                      </template>
                      {{ $t("stocks.current_stock_hint") }}
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <span
                      :style="
                        parseFloat(item.stock || 0) -
                          parseFloat(item.reserve || 0) >
                        0
                          ? 'text-decoration:underline;'
                          : ''
                      "
                    >
                      <strong>
                        {{
                          fixDecimal(
                            parseFloat(item.stock || 0) -
                              parseFloat(item.reserve || 0),
                            familyArticle.quantity_decimals
                          )
                        }}
                      </strong>
                    </span>
                  </template>
                </v-col>
  
                <!-- Ordered Quantity -->
                <v-col
                  cols="2"
                  class="my-auto pl-0 pr-0"
                  :class="
                    item.length != 0 ? 'stock_right_border biggerTextFont' : ''
                  "
                  style="font-size: 13px"
                >
                  <template v-if="item.length == 0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ $t("stocks.ordered_quantity_label") }}
                        </span>
                      </template>
                      {{ $t("stocks.ordered_quantity_label_hint") }}
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <strong>
                            <span
                              v-if="parseInt(item.supplier_order_quantity) == 0"
                            >
                              {{ parseInt(item.supplier_order_quantity || 0) }}
                            </span>
                            <span v-else>
                              <span
                                @click.stop.prevent="
                                  change_stock_detail_dialog(item, 1)
                                "
                                :class="
                                  parseInt(
                                    item.supplier_order_partially_received_quantity
                                  )
                                    ? ''
                                    : ''
                                "
                              >
                                {{ parseInt(item.supplier_order_quantity || 0) }}
                              </span>
                            </span>
                            <span
                              v-if="
                                parseInt(
                                  item.supplier_order_partially_received_quantity
                                )
                              "
                              @click.stop.prevent="
                                change_stock_detail_dialog(item, 1)
                              "
                            >
                              ({{
                                item.supplier_order_partially_received_quantity
                              }})
                            </span>
                          </strong>
                        </span>
                      </template>
                      {{
                        $t("stocks.ordered_and_received_value_hint", {
                          ordered: parseInt(item.supplier_order_quantity || 0),
                          received:
                            item.supplier_order_partially_received_quantity,
                        })
                      }}
                    </v-tooltip>
                  </template>
                </v-col>
  
                <!-- Client Price -->
                <v-col
                  cols="2"
                  class="my-auto pl-0 pr-0"
                  :class="
                    item.length != 0 ? 'stock_right_border smallerTextFont' : ''
                  "
                >
                  <template v-if="item.length == 0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <div>
                            {{ $t("stocks.client_price_short") }} x
                            {{ stockUnits }}
                          </div>
                          <div
                            class="text-red x-small-text"
                            v-if="!withTvaWithoutTva"
                          >
                            ({{ $t("stocks.without_tva") }})
                          </div>
                        </span>
                      </template>
                      {{ $t("stocks.client_price_short_hint") }}
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <strong>
                      <span
                        :style="
                          parseInt(fixDecimal(item.family_client_price)) == 0
                            ? ''
                            : ''
                        "
                      >
                        <template v-if="withTvaWithoutTva">
                          {{
                            fixDecimal(item.family_client_price * stockUnits, 2)
                          }}
                        </template>
                        <template v-else>
                          {{
                            fixDecimal(
                              calculateWithoutTva(
                                item.family_client_price * stockUnits
                              ),
                              2
                            )
                          }}
                        </template>
                      </span>
                    </strong>
                  </template>
                </v-col>
  
                <!-- Price -->
                <v-col
                  cols="2"
                  class="my-auto pl-0 pr-0"
                  :class="
                    item.length != 0 ? 'stock_right_border biggerTextFont' : ''
                  "
                >
                  <strong>
                    <span v-if="item.length == 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ $t("stocks.price") }}
                          </span>
                        </template>
                        {{ $t("stocks.price_hint") }}
                      </v-tooltip>
                    </span>
                    <span v-else>
                      <span
                        :style="parseInt(fixDecimal(item.price)) == 0 ? '' : ''"
                      >
                        {{ fixDecimal(item.price, 2) || fixDecimal(0, 2) }}
                      </span>
                    </span>
                  </strong>
                </v-col>
  
                <!-- Stock -->
                <v-col
                  cols="2"
                  class="my-auto pl-0 pr-0"
                  :class="[
                    item.length != 0 ? 'stock_right_border biggerTextFont' : '',
                    setColumnColorInFunctionOfDot(item),
                  ]"
                >
                  <strong>
                    <span v-if="item.length == 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ $t("stocks.stock") }}
                          </span>
                        </template>
                        {{ $t("stocks.stock_hint") }}
                      </v-tooltip>
                    </span>
                    <span v-else>
                      <span
                        :style="
                          parseInt(item.stock ? item.stock : 0) == 0 ? '' : ''
                        "
                      >
                        {{ parseInt(item.stock ? item.stock : 0) }}
                      </span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="checkDotYearDifference(item) > 2"
                            color="white"
                            style="font-size: 16px; left: 4px; top: -1px"
                          >
                            mdi-bell-alert
                          </v-icon>
                        </template>
                        {{ $t("stocks.dot_very_old") }}
                      </v-tooltip>
                    </span>
                  </strong>
                </v-col>
  
                <!-- Reserve -->
                <v-col
                  cols="2"
                  class="my-auto pl-0 pr-2"
                  :class="item.length != 0 ? 'biggerTextFont' : ''"
                  align="right"
                >
                  <strong>
                    <template v-if="item.length == 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ $t("stocks.reserve_label") }}
                          </span>
                        </template>
                        {{ $t("stocks.reserve_label_hint") }}
                      </v-tooltip>
                    </template>
                    <div v-else class="d-flex justify-end align-self-center">
                      <!-- Data Stock/Reserve -->
                      <div class="align-self-right pr-1">
                        <span
                          @click.stop.prevent="change_stock_detail_dialog(item)"
                          :style="
                            item.is_verified ? 'text-decoration:underline;' : ''
                          "
                        >
                          <!-- {{ fixDecimal(item.stock, familyArticle.quantity_decimals) || fixDecimal(0, familyArticle.quantity_decimals) }} -->
                          <!-- / -->
                          <span
                            >{{
                              fixDecimal(
                                item.reserve,
                                familyArticle.quantity_decimals
                              ) || fixDecimal(0, familyArticle.quantity_decimals)
                            }}
                          </span>
                        </span>
                      </div>
  
                      <v-simple-checkbox
                        class="mr-n1"
                        v-if="
                          item.stock &&
                          item.stock > 0 &&
                          item.stock !== item.reserve
                        "
                        @click="addNewAppointment(item, null, item.is_selected)"
                        v-model="item.is_selected"
                        :ripple="false"
                      >
                      </v-simple-checkbox>
  
                      <v-simple-checkbox
                        v-else
                        class="mr-n1 noDrop"
                        color="green"
                        disabled
                      >
                      </v-simple-checkbox>
                    </div>
                  </strong>
                </v-col>
              </v-row>
            </td>
  
            <template v-for="(slot, i) in subSlots">
              <td :key="i" v-if="item.length == 0" class="pa-1">
                <v-row>
                  <!-- Client Price -->
                  <v-col cols="12" sm="3" class="text-sm-left pl-3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <div>
                            {{ $t("stocks.client_price_short") }} x
                            {{ stockUnits }}
                          </div>
                          <div
                            class="text-red x-small-text"
                            v-if="!withTvaWithoutTva"
                          >
                            ({{ $t("stocks.without_tva") }})
                          </div>
                        </span>
                      </template>
                      {{ $t("stocks.client_price_short_hint") }}
                    </v-tooltip>
                  </v-col>
                  <!-- Acquisition Price -->
                  <v-col cols="12" sm="2" class="text-sm-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ $t("stocks.price") }}
                        </span>
                      </template>
                      {{ $t("stocks.price_hint") }}
                    </v-tooltip>
                  </v-col>
                  <!-- Delivery Date -->
                  <v-col cols="12" sm="3" class="text-sm-left">
                    {{ $t("stocks.d_date") }}
                  </v-col>
                  <!-- Quantity -->
                  <v-col cols="12" sm="3" class="text-sm-right">
                    {{ $t("stocks.quantity") }}
                  </v-col>
                </v-row>
              </td>
              <td
                v-if="item.length != 0"
                :key="i"
                :class="i == 0 ? 'first-column-after-internal' : ''"
                :style="{
                  backgroundColor: computeSlotBackgroundColor(item, slot),
                }"
              >
                <v-row
                  :class="
                    item[`${slot.field_stock}`] && item[`${slot.field_stock}`] > 0
                      ? 'stock-available'
                      : ''
                  "
                  style="height: inherit"
                  @mouseover="
                    getElementById(generateElementId(item, slot, i), '');
                    getElementById(
                      generateElementIdForRefresh(item, slot, i),
                      ''
                    );
                  "
                  @mouseleave="
                    getElementById(generateElementId(item, slot, i), 'none');
                    getElementById(
                      generateElementIdForRefresh(item, slot, i),
                      'none'
                    );
                  "
                >
                  <!-- Client Price -->
                  <v-col
                    @click="showDialog(item, slot)"
                    v-if="item[`${slot.field_stock}`]"
                    cols="12"
                    sm="3"
                    class="text-sm-right biggerTextFont"
                  >
                    <strong>
                      <template v-if="withTvaWithoutTva">
                        {{
                          fixDecimal(
                            item[`${slot.field_client_price}`] * stockUnits,
                            2
                          )
                        }}
                      </template>
                      <template v-else>
                        {{
                          fixDecimal(
                            calculateWithoutTva(
                              item[`${slot.field_client_price}`] * stockUnits
                            ),
                            2
                          )
                        }}
                      </template>
                    </strong>
                  </v-col>
                  <!-- Price -->
                  <v-col
                    @click="showDialog(item, slot)"
                    v-if="item[`${slot.field_stock}`]"
                    cols="12"
                    sm="2"
                    class="text-sm-right biggerTextFont"
                  >
                    {{ fixDecimal(item[`${slot.field_price}`], 2) }}
                  </v-col>
                  <!-- Delivery Date -->
                  <v-col
                    @click="showDialog(item, slot)"
                    v-if="item[`${slot.field_stock}`]"
                    cols="12"
                    sm="4"
                    class="text-sm-left biggerTextFont"
                  >
                    {{
                      item[`${slot.field_delivery_date}`]
                        ? getDayAndMonthInText(
                            item[`${slot.field_delivery_date}`],
                            true
                          )
                        : ""
                    }}
                  </v-col>
                  <!-- Sub Slot Data Stock/Reserve -->
                  <v-col
                    cols="12"
                    sm="3"
                    class="stock-col text-sm-left align-self-center biggerTextFont"
                  >
                    <div class="d-flex justify-end align-self-center">
                      <div class="align-self-center pr-2">
                        <span
                          @click="showDialog(item, slot)"
                          :class="{
                            'mr-10': !(
                              item[`${slot.field_stock}`] &&
                              item[`${slot.field_stock}`] > 0
                            ),
                          }"
                          :style="
                            item[`${slot.field_stock}`] &&
                            item[`${slot.field_stock}`] > 0
                              ? 'text-decoration:underline;'
                              : ''
                          "
                          v-if="
                            item[`${slot.field_stock}`] &&
                            item[`${slot.field_stock}`] > 0
                          "
                        >
                          {{ item[`${slot.field_stock}`] }}
                        </span>
                      </div>
                      <v-simple-checkbox
                        color="green"
                        v-if="
                          item[`${slot.field_stock}`] &&
                          item[`${slot.field_stock}`] > 0
                        "
                        @click="
                          addNewAppointment(
                            item,
                            slot,
                            item[`${slot.field_stock}` + '_is_selected']
                          )
                        "
                        v-model="item[`${slot.field_stock}` + '_is_selected']"
                        :ripple="false"
                      >
                      </v-simple-checkbox>
                      <template v-else>
                        <v-icon
                          @click="getStockAvailability(item, slot)"
                          :id="generateElementIdForRefresh(item, slot, i)"
                          :style="{
                            fontSize: calendarIconSize,
                            display: 'none',
                          }"
                        >
                          mdi-refresh
                        </v-icon>
                        <v-icon
                          @click="showDialog(item, slot)"
                          :id="generateElementId(item, slot, i)"
                          :style="{
                            fontSize: calendarIconSize,
                            display: 'none',
                          }"
                        >
                          mdi-home-import-outline
                        </v-icon>
                      </template>
                    </div>
                  </v-col>
                </v-row>
              </td>
            </template>
          </tr>
        </template>
      </v-data-table>
      <stock-order-details
        ref="stockOrderDetails"
        @changeDialog="changeDialog()"
        :showDialog="dialog"
        :rowData="selectedRowData"
        :columnData="selectedColumnData"
        :brands="brands"
        :selectedFamily.sync="familyArticle"
      ></stock-order-details>
  
      <modify-stock
        v-if="modify_stock_dialog == true"
        @modifyClose="change_stock_detail_dialog_close"
        :rowData="selectedRowData"
        :header="header"
        :selectedFamily="familyArticle"
        :activeTab="activeTab"
      ></modify-stock>
  
      <keep-alive>
        <add-article-model
          @changeDialog="changeDialog()"
          :showFamilyArticle="true"
          :familyArticles.sync="familyArticles"
          :approvals.sync="approvals"
          :multiselectsValues.sync="multiselectsValues"
          :multiselects.sync="multiselects"
          :statuses.sync="statuses"
          :normalSearch.sync="normalSearch"
          :tyreSeasons.sync="tyreSeasons"
          :wheelTypes.sync="wheelTypes"
          :tyreTypes.sync="tyreTypes"
          :compSelectedArticleId.sync="parentSearchSelectedArticleId"
          :internalStockOnly.sync="internalStockOnly"
          :showDialog.sync="addArticleDialog"
          :showFamilyArticleOnlyWithStock="true"
        ></add-article-model>
      </keep-alive>
  
      <MinArticleStock
        v-if="showArticleStockPopUp"
        :showDialog.sync="showArticleStockPopUp"
        :item.sync="selectedItemForPopUp"
        :familyArticle="familyArticle"
      ></MinArticleStock>
      <StockCart
        v-if="isCartPopUpVisible"
        @change-popup-model-visibility="changePopUpModelVisibility"
      ></StockCart>
      <image-pop-up
        v-if="showImagePopUp"
        :show="showImagePopUp"
        @closePopUp="showImagePopUp = false"
        :imageUrl="articleImageBaseUrl + currentItem.identifier"
        :data="currentItem"
      ></image-pop-up>
      <v-btn
        @click="addArticleToStock()"
        class="d-none"
        id="add-article-to-stock"
      ></v-btn>
      <v-btn
        id="show-cart-pop-up-for-stocks"
        class="d-none"
        @click="showCartPopUpForStocks()"
      ></v-btn>
      <v-btn
        id="show-favorite-articles"
        class="d-none"
        @click="showFavoriteArticles()"
      ></v-btn>
    </div>
  </template>
  <style scoped>
  tr:hover td {
    background-color: rgb(241, 241, 241) !important;
  }
  .v-list-item__content:hover {
    cursor: move;
  }
  .stock_or_reserver_col:hover {
    cursor: move;
    font-weight: 600;
  }
  .first-column-after-internal {
    border-left: 0px solid black !important;
  }
  td {
    border: 1px solid rgba(0, 0, 0, 0.178);
  }
  
  .v-tooltip__content {
    font-size: 14px !important;
    color: black;
    background: rgb(218, 255, 220) !important;
    border: 1px solid black;
    line-height: 20px;
    opacity: 1 !important;
  }
  </style>
  <script>
  import axios from "@/plugins/axios";
  import _ from "lodash";
  import { API_BASE_URL } from "@/config";
  import { STOCK_LISTING } from "@/config";
  import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
  import StockOrderDetails from "@/components/Stock/StockOrderDetails";
  import draggable from "vuedraggable";
  import ModifyStock from "@/components/Stock/ModifyStock";
  import StockCart from "@/components/Stock/StockCart";
  import AddArticleModel from "@/components/SearchArticleModel.vue";
  import ImagePopUp from "@/components/ImagePopUp";
  import NewCombinedInput from "@/components/PNC/Stock/NewCombinedInput.vue";
  import MinArticleStock from "@/components/MinArticleStock.vue";
  export default {
    name: "Stock",
    components: {
      "stock-order-details": StockOrderDetails,
      "modify-stock": ModifyStock,
      "add-article-model": AddArticleModel,
      "image-pop-up": ImagePopUp,
      draggable,
      NewCombinedInput,
      StockCart,
      MinArticleStock,
    },
    async mounted() {
      setTimeout(() => {
        let el = document.querySelector(".v-data-footer__pagination");
        if (el) el.style.display = "none";
      }, 10_000);
  
      (this.header = { Authorization: "Bearer " + this.token }),
        this.$store.commit("setFormMode", STOCK_LISTING);
      this.$store.commit("setAddButtonRoute", "AddStock", "EditStock");
      this.setDefaultFamilyArticle();
      this.setDefaultFamilyClient();
    },
    data() {
      return {
        checkAllSubHeader: false,
        supplierModel: false,
        min_quantity_to_search: 1,
        is_favorite_searching: false,
        pinned: false,
        defaultStockUnits: 4,
        calendarIconSize: "19px",
        fetchDataAbortController: {},
        filterStocks: "internal_only",
        supplierColumnVisibility: null,
        stockUnits: this.defaultStockUnits,
        withTvaWithoutTva: true,
        withOnlyInternalStock: false,
        booleanSelectedValues: [],
        showImagePopUp: false,
        currentItem: null,
        articleImageBaseUrl: API_BASE_URL + "/article/image/",
        is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
        withProposedArticles: true,
        normalSearch: false,
        parentSearchSelectedArticleId: null,
        internalStockOnly: false,
        isFirstTimeLoaded: 0,
        dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
        isLoading: false,
        articles: [],
        lastPage: null,
        totalItems: null,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
        sortBy: "description",
        sortDesc: false,
        options: {},
        pagination: {},
        season: null,
        widths: ["205", "255", "245", "145", "225"],
        width: "",
        heights: ["45", "55", "60", "65", "75", "85", "95"],
        height: "",
        rims: ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
        rim: "",
        lis: [
          "90",
          "91",
          "100",
          "120",
          "130",
          "140",
          "150",
          "160",
          "170",
          "180",
          "190",
        ],
        li: "",
        sis: ["H", "V", "W"],
        si: "",
        brands: [],
        brand: null,
        approval: { id: 10011001 },
        status: { id: 10001001 },
        familyClient: "",
        articleBooleanProperties: [],
        familyArticle: {},
        loadingFirstTime: 0,
        ifCurrentlyPaused: false,
        changingArticlePropertiesInProgress: false,
        customHeader: [
          // { text: this.$t('stocks.id'),                   class:"sticky-header",      align: 'start', sortable: false, width: 5,    value:'article_id'},
          {
            text: this.$t("stocks.image"),
            class: "sticky-header",
            align: "start",
            sortable: false,
            width: 5,
            value: "image",
          },
          {
            text: this.$t("stocks.article"),
            class: "sticky-header",
            align: "start",
            sortable: true,
            width: 200,
            value: "description",
          },
          {
            text: this.$t("stocks.tarrif"),
            class: "sticky-header",
            align: "end",
            sortable: false,
            width: 5,
            value: "tariff_price",
          },
          {
            text: this.$t("stocks.stock"),
            class: "sticky-header",
            align: "end",
            sortable: false,
            width: 5,
            value: "brand_stock",
          },
          {
            text: this.$t("stocks.res"),
            class: "sticky-header",
            align: "end",
            sortable: false,
            width: 5,
            value: "res",
          },
          {
            text: this.$t("stocks.current_stock"),
            class: "sticky-header",
            align: "end",
            sortable: false,
            width: 5,
            value: "brand_stock",
          },
          {
            text: this.$t("stocks.price"),
            class: "sticky-header",
            align: "end",
            sortable: false,
            width: 5,
            value: "price",
          },
        ],
        subHeader: [],
        tempSubHeader: [],
        mainHeader: [],
        customFilters: [],
        customFiltersExceptions: [1, 2], // these numbers represents property_id value example : property_id = 1
        token: this.$store.state.AccessToken,
        header: {},
        parentTableHeight: "",
        suppliers: [],
        supplier: "",
        subSlots: [],
        search: [],
        addArticleDialog: false,
        dialog: false,
        selectedRowData: {},
        selectedColumnData: {},
        conditions: [],
        condition: "",
        stock: "",
        reserve: "",
        deliveryDate: "",
        tvas: "",
        tva: "",
        clients: [],
        liveData: false,
        modify_stock_dialog: false,
        isPageLoaded: false,
        isDebounceCancelled: false,
        searchBrand: null,
        ifBrandSUpplierExists: false,
        suppliers_statuses: [],
        activeTab: null,
        searchCounts: [{ search: 1 }],
        savedSearches: [],
        tempSavedSearches: [],
        searchCounter: 0,
        isCartPopUpVisible: false,
        showArticleStockPopUp: false,
        selectedItemForPopUp: null,
        randomKeyValue: null,
        checkAllSubHeaderIntermediate: false,
      };
    },
    methods: {
      getBrandById(brand_id) {
        let foundBrand = null;
        this.brands.forEach((brand) => {
          if (brand.id == brand_id) {
            foundBrand = brand;
          }
        });
        return foundBrand;
      },
      getSupplierByIdFromCache(supplier_id) {
        let foundSupplier = null;
        if (supplier_id && this.familyArticle) {
          (this.cachedData["suppliers"][this.familyArticle.id] ?? []).forEach(
            (supplier) => {
              if (supplier.id == supplier_id) {
                foundSupplier = supplier;
              }
            }
          );
        }
        return foundSupplier;
      },
      getSupplierAsBrand(brand) {
        let foundSupplier = null;
        (this.cachedData["suppliers"][this.familyArticle.id] ?? []).forEach(
          (supplier) => {
            if (supplier.name.toUpperCase() == brand.name.toUpperCase()) {
              foundSupplier = supplier;
            }
          }
        );
        return foundSupplier;
      },
      getStockAvailability(item, currentColumnData) {
        this.selectedRowData = item;
        this.selectedColumnData = currentColumnData;
  
        this.$store.state.isLoading = true;
        let supplier_id =
          this.selectedColumnData && this.selectedColumnData.supplier_detail
            ? this.selectedColumnData.supplier_detail.id == 0
              ? this.selectedRowData.brand_supplier_id
              : this.selectedColumnData.supplier_detail.id
            : 0;
  
        // Supplier Id check
        if (this.selectedRowData && (supplier_id == 0 || supplier_id == null)) {
          // means user has clicked on brand column,  then in this case we will replace supplier_id with 0 to selectedRowData.brand_id's supplier
          let brand = this.getBrandById(this.selectedRowData.brand_id);
          let brandAsSupplier = this.getSupplierAsBrand(brand);
          supplier_id = brandAsSupplier ? brandAsSupplier.id : null;
          // if even now supplier doesn't exists then put proprietary_supplier_id as supplier
          if (!supplier_id && brand && brand.proprietary_supplier_id) {
            let supplier = this.getSupplierByIdFromCache(
              brand.proprietary_supplier_id
            );
            if (supplier) {
              supplier_id = supplier.id;
            }
          }
        }
  
        axios
          .get(
            API_BASE_URL +
              "/suppliers/" +
              supplier_id + // Note: If brand column is selected then use brand supplier_detail_id
              "/availability/" +
              this.selectedRowData.article_id,
            { headers: this.header }
          )
          .then(({ data }) => {
            let response = data.data[Object.keys(data.data)[0]];
            if (response && response.error_code == 0) {
              if (response.availability > -1) {
                this.selectedRowData[`${this.selectedColumnData.field_stock}`] =
                  response.stock;
                this.selectedRowData[
                  `${this.selectedColumnData.field_delivery_date}`
                ] = response.delivery_date;
  
                this.selectedRowData[`${this.selectedColumnData.field_price}`] =
                  response.price;
                // this.form.acq_price = response.price;
                // this.form.supplier_stock = response.stock;
                if (!response.delivery_date && !response.stock) {
                  this.$toast.error(
                    this.$t(
                      "stocks.supplier_responded_with_no_stock_and_with_no_delivery_date"
                    )
                  );
                }
              } else {
                this.errorMessage = this.$t(
                  "stocks.error_while_making_connection_to_supplier"
                );
              }
            } else if (response && response.error_code > 0) {
              this.$toast.error(
                this.$t("stocks.supplier_responded_with_error_code")
              );
            } else {
              this.$toast.error(response.error_message);
            }
            console.log("response data", response);
          })
          .catch(function (error) {
            console.log("an error occured " + error);
          })
          .finally(() => {
            this.$store.state.isLoading = false;
          });
      },
      checkUncheckAllSubHeader() {
        if (this.tempSubHeader && this.tempSubHeader.length > 0) {
          this.tempSubHeader.forEach((element) => {
            if (element.is_read_only) {
              element.visible = true;
            } else {
              element.visible = this.checkAllSubHeader;
            }
          });
        }
      },
      markIntermediateStateAfterCheck() {
        setTimeout(() => {
          this.checkAllSubHeaderIntermediate = false;
          if (this.tempSubHeader && this.tempSubHeader.length > 0) {
            let firstVisible = this.tempSubHeader[0].visible;
            let ifAnyChanged = false;
            this.tempSubHeader.forEach((element) => {
              if (element.visible != firstVisible) {
                ifAnyChanged = true;
              }
            });
            this.$nextTick(() => {
              if (ifAnyChanged) {
                this.checkAllSubHeader = false;
                this.checkAllSubHeaderIntermediate = true;
              } else {
                this.checkAllSubHeaderIntermediate = false;
              }
            });
          }
        }, 200);
      },
      showFavoriteArticles() {
        if (this.customFilters.length > 0) {
          this.searchCounts.forEach((searchCount, searchIndex) => {
            this.resetFurtherBoxesValues(
              this.customFilters[searchIndex][0].property_name,
              searchIndex
            );
          });
          this.search = null;
          this.brand = null;
        }
        this.is_favorite_searching = true;
        this.$nextTick(() => {
          this.fetchData();
        });
      },
      showMinArticleStockPopUp(item) {
        this.showArticleStockPopUp = true;
        this.selectedItemForPopUp = item;
      },
      checkDotYearDifference(item) {
        if (item.dot_property) {
          let currentYear = parseInt(
            new Date().getFullYear().toString().substring(2)
          );
          let yearDiff = currentYear - parseInt(item.dot_property);
  
          return yearDiff;
        }
        return 0;
      },
      setRowColorForWideBoxOfInternal(item) {
        if (
          item.stock &&
          parseFloat(item.stock || 0) - parseFloat(item.reserve || 0) > 0
        ) {
          return "stockPresent";
        }
        if (
          item.supplier_order_quantity &&
          parseInt(item.supplier_order_quantity) > 0
        ) {
          return "supplierOrderPresent";
        }
        return "";
      },
      setColumnColorInFunctionOfDot(item) {
        if (item.dot_property) {
          let yearDiff = this.checkDotYearDifference(item);
          if (yearDiff == 0) {
            return "stockPresent";
          } else if (yearDiff == 1) {
            return "stockPresent";
            //   return "orange text-white";
          } else if (yearDiff == 2) {
            return "orange text-white";
          } else {
            return "red text-white";
          }
        }
      },
      changePopUpModelVisibility() {
        this.isCartPopUpVisible = false;
        this.recheckAndFixSelectionOfArticles();
      },
      showCartPopUpForStocks() {
        this.isCartPopUpVisible = true;
      },
      resetSearchCount() {
        this.searchCounts = [];
        this.$nextTick(() => {
          this.searchCounts = [{ search: 1 }];
        });
      },
      hideAllOtherItems(item, index, pinned) {
        if (pinned) {
          // --------------------------MAKE ALL INVISIBLE EXCEPT SELECTED INDEX-------------------------------
          // Define the index of the <tr> element you want to keep visible
          const indexToKeepVisible = index; // For example, to keep the third <tr> (index 2) visible
  
          // Get all <tr> elements within the tbody
          const trElements = document.querySelectorAll("tbody tr");
  
          // Loop through each <tr> element
          trElements.forEach((tr, index) => {
            // Get all <td> elements within the current <tr>
            if (index != indexToKeepVisible && index > 0) {
              tr.classList.add("hideRow");
            }
          });
        } else {
          // --------------------------MAKE ALL VISIBLE-------------------------------
          // Get all <tr> elements within the tbody
          const trElements = document.querySelectorAll("tbody tr");
  
          // Loop through each <tr> element
          trElements.forEach((tr, index) => {
            if (index > 0) {
              // Add a CSS class to hide the <td> element
              tr.classList.remove("hideRow");
            }
          });
        }
      },
      addAdditionalSearch() {
        this.searchCounts.push({ search: 2 });
  
        // Assuming customFilters is an array of objects
        if (this.customFilters && this.customFilters.length > 0) {
          // Clone the first object and push it onto the array
          const firstObject = this.customFilters[0];
          const clonedObject = JSON.parse(JSON.stringify(firstObject)); // Deep clone
          this.customFilters.push(clonedObject);
        }
        // Now reset the values of every new added filter
        this.customFilters[this.customFilters.length - 1].forEach((element) => {
          element.value = null;
        });
      },
      removeAdditionalSearch(searchIndex) {
        // Check if the searchIndex is within the bounds of the customFilters array
        if (searchIndex >= 0 && searchIndex < this.customFilters.length) {
          // Remove the additional search at the specified searchIndex
          this.searchCounts.splice(searchIndex, 1);
          this.customFilters.splice(searchIndex, 1);
        } else {
          console.error("Invalid searchIndex:", searchIndex, this.customFilters);
        }
      },
      getPropertyName(property_name) {
        let propertyText = property_name;
        this.filterPropertiesExcept(this.customFilters[0]).forEach((property) => {
          if (property.property_name == property_name) {
            propertyText = property.text;
          }
        });
        return propertyText;
      },
      formatSavedSearches() {
        if (this.searchCounts.length == 1) {
          return this.$t("stocks.add_new_search");
        } else {
          return this.$t("stocks.remove_extra_search");
        }
        // let newSearchFormattedString = "";
        // if(savedSearches && savedSearches.length > 0){
        //     savedSearches.forEach(searchString => {
        //         let searchValues = searchString.split("&");
        //         searchValues.forEach(searchPart => {
        //             let [searchFirstPart, searchSecondPart]  = searchPart.split("=");
        //             if(searchFirstPart.includes('property_')){
        //                 searchFirstPart = this.getPropertyName(searchFirstPart);
        //             }
        //             else if(searchFirstPart.includes('season')){
        //                 if(searchSecondPart == 0){
        //                     searchFirstPart = "";
        //                 }
        //                 else{
        //                     searchFirstPart = this.$t('tyreSeasons');
        //                     this.tyreSeasons.forEach((season) => {
        //                         if(season.id == searchSecondPart){
        //                             searchSecondPart = season.text;
        //                         }
        //                     })
        //                 }
        //             }
        //             else if(searchFirstPart.includes('brand')){
        //                 searchFirstPart = this.$t('articles.properties.brand');
        //                 if(searchSecondPart){
        //                     this.brands.forEach((brand) => {
        //                         if(brand.id == searchSecondPart){
        //                             searchSecondPart = brand.name;
        //                         }
        //                     })
        //                 }
        //             }
        //             else if(searchFirstPart.includes('family') || searchFirstPart.includes('familyClient') || searchFirstPart.includes('with_proposed_article')  ){
        //                 searchFirstPart = "";
        //             }
        //             if(searchFirstPart.trim()){
        //                 newSearchFormattedString += searchFirstPart + " : " + searchSecondPart + ", ";
  
        //             }
        //         });
  
        //         // Add New Line for new search
        //         newSearchFormattedString = newSearchFormattedString + "<br>";
        //     });
        // }
        // if(!newSearchFormattedString || newSearchFormattedString == ""){
        //     return this.$t('stocks.add_new_search');
        // }
        // return newSearchFormattedString;
      },
      getElementById(id, state) {
        let node = document.getElementById(id);
  
        if (node) {
          node.style.display = state;
        }
      },
      generateElementId(item, slot = null, i = 0) {
        return (
          "calendarIcon" +
          i +
          (item?.id || "") +
          (slot?.supplier_detail?.id || "")
        );
      },
      generateElementIdForRefresh(item, slot = null, i = 0) {
        return (
          "refreshIcon" + i + (item?.id || "") + (slot?.supplier_detail?.id || "")
        );
      },
      getElementByIdForPinning(id, state) {
        let node = document.getElementById(id);
  
        if (node) {
          node.style.display = state;
        }
      },
      generateElementIdForPinning(item, slot = null, i = 0) {
        return (
          "pinningItem" + i + (item?.id || "") + (slot?.supplier_detail?.id || "")
        );
      },
      generateElementIdForMinArticleStock(item, slot = null, i = 0) {
        if (item.is_favorite) {
          return "";
        }
        return (
          "minArticleStock" +
          i +
          (item?.id || "") +
          (slot?.supplier_detail?.id || "")
        );
      },
      generateElementIdForCopy(item, slot = null, i = 0) {
        return (
          "copyItem" + i + (item?.id || "") + (slot?.supplier_detail?.id || "")
        );
      },
      clearPendingArticles() {
        this.$store.commit("setSelectedArticlePendingAppointment", null);
      },
      async addNewAppointment(item, passedSlot = null, is_selected = false) {
        // let currentAvailable = parseFloat(item.stock || 0) - parseFloat(item.reserve || 0);
        let currentAvailable = parseFloat(item.stock || 0);
        let slot = null;
        if (passedSlot) {
          slot = Object.assign({}, passedSlot);
        }
        if (slot && [0, null, undefined].includes(slot?.supplier_detail?.id)) {
          let supplier = await this.getSupplierById(item.brand_supplier_id);
          slot.supplier_detail = supplier;
        }
        let data = {
          article: item,
          slot: slot,
          quantity:
            currentAvailable < this.stockUnits && currentAvailable > 0
              ? currentAvailable
              : this.stockUnits,
        };
        if (
          this.selectedArticlePendingAppointment &&
          Array.isArray(this.selectedArticlePendingAppointment) &&
          this.selectedArticlePendingAppointment.length > 0
        ) {
          // If selectedArticlePendingAppointment is not empty then check if it is to be removed then remove it else add it
          let articlesForAppointments = this.selectedArticlePendingAppointment;
          if (is_selected) {
            let indexToRemove =
              this.checkIfSameArticleIsPresentInPendingArticleForAppointments(
                item,
                slot,
                false,
                false,
                true
              );
            if (indexToRemove > -1) {
              articlesForAppointments.splice(indexToRemove, 1);
            }
            articlesForAppointments.push(data);
            this.$store.commit(
              "setSelectedArticlePendingAppointment",
              articlesForAppointments
            );
          } else {
            let indexToRemove =
              this.checkIfSameArticleIsPresentInPendingArticleForAppointments(
                item,
                slot,
                false,
                false,
                true
              );
            if (indexToRemove > -1) {
              articlesForAppointments.splice(indexToRemove, 1);
            }
            this.$store.commit(
              "setSelectedArticlePendingAppointment",
              articlesForAppointments
            );
          }
        } else {
          if (is_selected) {
            // If selectedArticlePendingAppointment is empty then put this data in array
            this.$store.commit("setSelectedArticlePendingAppointment", [data]);
          }
        }
        this.recheckAndFixSelectionOfArticles();
      },
      recheckAndFixSelectionOfArticles() {
        this.articles.forEach((element) => {
          element.is_selected = false;
          let objkeys = Object.entries(element)
            .filter(([key]) => {
              return key.includes("_stock");
            })
            .map(([key]) => {
              if (key.includes("_is_selected")) {
                return key;
              }
              return key + "_is_selected";
            });
          objkeys.forEach((objectKeys) => {
            element[objectKeys] = false;
          });
        });
        if (
          this.selectedArticlePendingAppointment &&
          Array.isArray(this.selectedArticlePendingAppointment) &&
          this.selectedArticlePendingAppointment.length > 0
        ) {
          this.selectedArticlePendingAppointment.forEach((element) => {
            // For Internat Slot
            if (element.article && element.slot == null) {
              this.articles.forEach((article) => {
                if (article.identifier == element.article.identifier) {
                  article.is_selected = true;
                }
              });
            } else if (element.article && element.slot) {
              this.articles.forEach((article) => {
                if (article.identifier == element.article.identifier) {
                  article[`${element.slot.field_stock}` + "_is_selected"] = true;
                }
              });
            }
          });
        }
      },
      checkIfSameArticleIsPresentInPendingArticleForAppointments(
        item,
        slot = null,
        checkWithSameSupplier = false,
        checkWithDifferentSupplier = false,
        checkWithoutSupplier = false
      ) {
        let articlesForAppointments =
          this.selectedArticlePendingAppointment ?? [];
        let indexFound = -1;
        articlesForAppointments.forEach((element, index) => {
          if (
            checkWithSameSupplier &&
            element.article.identifier == item.identifier &&
            element.slot == slot
          ) {
            indexFound = index;
          } else if (
            checkWithDifferentSupplier &&
            element.article.identifier == item.identifier &&
            element.slot != slot
          ) {
            indexFound = index;
          } else if (
            checkWithoutSupplier &&
            element.article.identifier == item.identifier
          ) {
            indexFound = index;
          }
        });
        return indexFound;
      },
      async getSupplierById(id) {
        return new Promise((resolve) => {
          axios
            .get(API_BASE_URL + `/suppliers/${id}`, {
              headers: this.header,
            })
            .finally(() => {})
            .then((response) => {
              resolve(response.data.data);
            });
        });
      },
      toggleSupplierColumnVisibility(columnVisibility) {
        this.supplierColumnVisibility = columnVisibility;
  
        this.subHeader.forEach((item) =>
          this.toggleColumnVisibility(item, columnVisibility)
        );
  
        this.fetchData();
      },
      showImage(item) {
        this.currentItem = item;
        this.showImagePopUp = true;
      },
      debounceInput: _.debounce(function () {
        if (this.isDebounceCancelled) {
          this.isDebounceCancelled = false;
          return false;
        }
        this.options.page = 1;
        this.fetchData();
      }, 2000),
      filterPropertiesExcept(customFilters) {
        let properties = [];
        if (
          this.familyArticle &&
          (this.familyArticle.parent_family_id == 1 || this.familyArticle.id == 1)
        ) {
          properties = ["property_02"];
        }
  
        let filteredProperties = [];
        filteredProperties = customFilters.filter((property) => {
          let found = false;
          properties.forEach((element) => {
            if (element == property.property_name) {
              found = true;
            }
          });
  
          return found ? false : property;
        });
        return filteredProperties;
      },
      getMultiSelectValuesFromCode() {
        this.customFilters.forEach((filter, filterindex) => {
          this.multiselectsValues[filterindex] = [];
        });
        this.multiselects.forEach((code) => {
          if (code == "1011" && this.$store.state.tyreSeasons.length > 0) {
            return this.handleCustomFilters(code, this.$store.state.tyreSeasons);
          }
  
          this.getCodes(parseInt(code), "codes").then((data) => {
            this.handleCustomFilters(code, data);
            this.multiselectsValues.push({});
            this.multiselectsValues.splice(-1);
          });
        });
      },
      handleCustomFilters(code, data = null) {
        this.customFilters.forEach((filter, filterindex) => {
          if (filter.mapping_key == code) {
            this.multiselectsValues[filterindex] =
              code == "1001" && this.$store.state.tyreSeasons.length > 0
                ? this.$store.state.tyreSeasons
                : this.translateKeys(data, "key", "codes");
          }
        });
        this.multiselectsValues.push({});
        this.multiselectsValues.splice(-1);
      },
      /**
       * This Function is responsible to autofocus on next property field, if current's max length reached
       */
      handleAutoFocus(
        event,
        search_length,
        propertyName,
        dataType,
        forceToJumpOnProperty,
        searchIndex,
        filterIndex
      ) {
        let currentFieldLength = 0;
  
        if (dataType === "int" || dataType === "float") {
          currentFieldLength = (event.target.value + "").replace(".", "").length;
        } else {
          currentFieldLength = (event.target.value + "").length;
        }
  
        if (
          forceToJumpOnProperty &&
          search_length != null &&
          currentFieldLength === search_length
        ) {
          this.$refs[forceToJumpOnProperty][searchIndex].focus();
        } else if (
          propertyName.search("property") === 0 &&
          search_length != null &&
          currentFieldLength === search_length
        ) {
          const underscorePos = propertyName.search("_") + 1;
          const propertyNo = Number(propertyName.substr(underscorePos)) + 1;
          const nextPropertyName =
            "property_" + (propertyNo < 10 ? "0" : "") + propertyNo;
  
          // Check if filterIndex + 1 is valid and within the bounds of customFilters[searchIndex]
          if (this.customFilters[searchIndex][filterIndex + 1]) {
            const nextFilterPropertyName =
              this.customFilters[searchIndex][filterIndex + 1].property_name;
            if (nextFilterPropertyName === nextPropertyName) {
              this.$refs[nextPropertyName][searchIndex].focus();
            }
          }
        }
      },
      checkIfBrandExistsInProperties(customFilters) {
        let found = false;
        customFilters.forEach((property) => {
          if ("property_00" == property.property_name) {
            found = true;
          }
        });
        return found;
      },
      resetFurtherBoxesValues(focussedPropertyName, searchIndex) {
        // Disable Favorite Search
        this.is_favorite_searching = false;
        let foundProperty = false;
  
        // Iterate over the filter group for the specified search index
        this.customFilters[searchIndex].forEach((filter) => {
          if (filter.property_name === focussedPropertyName) {
            foundProperty = true;
          }
          if (foundProperty) {
            if (filter.property_name === "property_00" && searchIndex == 0) {
              // Resetting brand
              this.brand = null;
            }
            filter.value = null;
          }
        });
      },
      addArticleToStock() {
        this.addArticleDialog = true;
        this.parentSearchSelectedArticleId = null;
      },
      changeDialog() {
        this.dialog = false;
      },
      showDialog(item, currentColumnData) {
        this.selectedRowData = item;
        this.selectedColumnData = currentColumnData;
  
        // Disabled : because now they can order from any supplier
        //   if (item[`${currentColumnData.field_price}`]) {
        //     this.dialog = true;
        //   }
        this.dialog = true;
      },
      change_stock_detail_dialog(item, tab = null) {
        this.selectedRowData = item;
        this.modify_stock_dialog = true;
        this.activeTab = tab;
      },
      change_stock_detail_dialog_close(e) {
        if (e) {
          this.randomKeyValue = this.generateRandomString(4);
          this.fetchData();
        }
        this.modify_stock_dialog = false;
      },
      refreshLiveData() {
        // this.fetchData();
      },
      updateUserSupplierStatus(supplier_id, is_active) {
        let data = {
          is_active: is_active,
          supplier_id: supplier_id,
        };
  
        axios
          .post(API_BASE_URL + "/user_supplier_status", data, {
            headers: this.header,
          })
          .then(() => {})
          .catch((err) => {
            this.$toast.error(err.error);
          })
          .finally(() => {});
      },
  
      updateBulkUserSupplierStatus(bulkData) {
        axios
          .post(
            API_BASE_URL + "/user_supplier_status",
            { bulk_update_data: bulkData },
            { headers: this.header }
          )
          .then(() => {})
          .catch((err) => {
            this.$toast.error(err.error);
          })
          .finally(() => {});
      },
      toggleColumnVisibility(item, visibilityStatus = null) {
        let selectedSupplierIdentifier = item.value.substr(
          0,
          item.value.indexOf("_")
        );
        this.tempSubHeader = this.subHeader;
        this.tempSubHeader.find((current_item, index) => {
          if (current_item.index == item.index) {
            this.tempSubHeader[index].visible =
              visibilityStatus ?? !current_item.visible;
            let storedFilteredSuppliers =
              localStorage.getItem("FILTERED_SUPPLIERS") ?? [];
            if (storedFilteredSuppliers.length != 0) {
              storedFilteredSuppliers = JSON.parse(storedFilteredSuppliers);
            }
  
            if (this.tempSubHeader[index].visible == false) {
              if (
                storedFilteredSuppliers.includes(
                  this.tempSubHeader[index].value
                ) == false
              ) {
                storedFilteredSuppliers.push(this.tempSubHeader[index].value);
                localStorage.setItem(
                  "FILTERED_SUPPLIERS",
                  JSON.stringify(storedFilteredSuppliers)
                );
              }
            } else {
              let elementIndex = storedFilteredSuppliers.indexOf(
                this.tempSubHeader[index].value
              );
              if (elementIndex != -1) {
                storedFilteredSuppliers.splice(elementIndex, 1);
                localStorage.setItem(
                  "FILTERED_SUPPLIERS",
                  JSON.stringify(storedFilteredSuppliers)
                );
              }
            }
            this.suppliers.forEach((element) => {
              let convertedBackToIdentifier =
                selectedSupplierIdentifier == "brand"
                  ? "BRAND"
                  : selectedSupplierIdentifier.toUpperCase();
              if (element.identifier == convertedBackToIdentifier) {
                //   this.updateUserSupplierStatus(
                //     element.id,
                //     this.tempSubHeader[index].visible == false ? 0 : 1
                //   );
              }
            });
          }
        });
        let tempHeader = this.tempSubHeader;
        this.tempSubHeader = [];
        this.tempSubHeader = tempHeader;
      },
      updateHeadersListingOrder() {
        let updateData = [];
        this.tempSubHeader.forEach((element, index) => {
          if (element.supplier_detail) {
            updateData.push({
              supplier_id: element.supplier_detail.id,
              order: index + 1,
              is_active: element.visible,
            });
          }
        });
        this.subHeader = [...this.tempSubHeader];
        this.updateBulkUserSupplierStatus(updateData);
        this.fetchData();
      },
      checkIfFetchingPossible() {
        let isPossible = false;
        this.ifCurrentlyPaused = false;
        let valueAssigned = 0;
        this.customFilters[0].forEach((element) => {
          if (element.value != null) {
            valueAssigned++;
          }
        });
        if (this.search != null && this.search.length != 0) {
          valueAssigned++;
        }
        if (valueAssigned != 0) {
          isPossible = true;
        } else {
          isPossible = false;
        }
        if (!isPossible) {
          this.ifCurrentlyPaused = true;
        }
        if (this.changingArticlePropertiesInProgress) {
          isPossible = false;
        }
        if (this.search != null && this.search.length > 0) {
          isPossible = true;
        }
        if (this.is_favorite_searching) {
          isPossible = true;
        }
        return isPossible;
      },
      storeFetchData() {
        this.customFilters.forEach((filter, searchIndex) => {
          let searchData = "";
          searchData =
            (this.familyArticle ? "&family=" + this.familyArticle.id : "") +
            (!this.normalSearch && this.season ? "&season=" + this.season : "") +
            (this.brand ? "&brand=" + this.brand.id : "") +
            (this.condition ? "&condition_id=" + this.condition.id : "") +
            (this.supplier ? "&supplier_id=" + this.supplier.id : "") +
            (this.search ? "&search=" + encodeURIComponent(this.search) : "") +
            (this.currency ? "&currency_id=" + this.currency.id : "") +
            (this.min_quantity_to_search
              ? "&min_quantity_to_search=" + this.min_quantity_to_search
              : "") +
            (this.tva ? "&tva_id=" + this.tva.id : "") +
            (this.familyClient ? "&familyClient=" + this.familyClient : "") +
            (this.withOnlyInternalStock
              ? "&only_internal_stock=" + this.withOnlyInternalStock
              : "") +
            (this.withProposedArticles
              ? "&with_proposed_article=" + this.withProposedArticles
              : "") +
            this.getQueryStringFromDynamicallyCreatedFilters(searchIndex)[0];
          let isAlareadyExists = false;
          this.savedSearches.forEach((searchElement) => {
            if (searchElement == searchData) {
              isAlareadyExists = true;
            }
          });
          if (!isAlareadyExists) {
            this.savedSearches.push(searchData);
          }
        });
      },
      checkCommasToAdd(counter, value) {
        if (counter == 0) {
          return "";
        } else {
          let alreadyCountOfComma = (value ?? "").split(",").length - 1;
          return ",".repeat(counter - alreadyCountOfComma);
        }
      },
      fetchDataForInternalStock(){
          this.subHeader = [];
          this.fetchData();
      },
      fetchDataForExternalStock(){
          this.updateSubHeaders();
          this.fetchData();
      },
      fetchData(currentPage = null, newSearch = false) {
        this.pinned = false; // Reset pin status
        if (!currentPage) {
          this.options.page = 1;
          this.resetDataTable(currentPage);
        }
  
        this.articles = []; // remove the data from the table
  
        // if brands is empty then wait for getBrands to call
        if (this.checkIfFetchingPossible()) {
          if (this.searchCounter == 0) {
            this.savedSearches = [];
          }
          this.storeFetchData();
  
          let families = "";
          let seasons = "";
          let brands = "";
          let familyClients = "";
          let properties = [];
          let combinedProperties = "";
          if (newSearch) {
            // Reset Values
            // let allProperties = this.filterPropertiesExcept(this.customFilters);
            // let firstProperty = allProperties.length > 0 ? allProperties[0] : null;
            // if(firstProperty){
            //     this.resetFurtherBoxesValues(firstProperty.property_name);
            //     this.season = 0;
            // }
            this.searchCounter++;
            return true;
          } else {
            this.savedSearches.forEach((searchElement, index) => {
              let allPropertyValue = searchElement.split("&");
              allPropertyValue.forEach((propertyFromAll) => {
                if (propertyFromAll) {
                  let property = propertyFromAll.split("=");
                  let key = property[0];
                  let value = property[1];
  
                  if (key == "family") {
                    families += this.checkCommasToAdd(index, families) + value;
                  }
                  if (key == "season") {
                    seasons += this.checkCommasToAdd(index, seasons) + value;
                  }
                  if (key == "brand") {
                    brands += this.checkCommasToAdd(index, brands) + value;
                  }
                  if (key == "familyClient") {
                    familyClients +=
                      this.checkCommasToAdd(index, familyClients) + value;
                  }
                  if (key.includes("property_")) {
                    if (properties[`${key}`]) {
                      properties[`${key}`] +=
                        this.checkCommasToAdd(index, properties[`${key}`]) +
                        value;
                    } else {
                      properties[`${key}`] =
                        this.checkCommasToAdd(index, properties[`${key}`]) +
                        value;
                    }
                  }
                }
              });
            });
            for (const [key, value] of Object.entries(properties)) {
              combinedProperties += "&" + `${key}=${value}`;
            }
  
            this.searchCounter = 0;
          }
          const { sortBy, sortDesc, itemsPerPage } = this.options;
          let suppliers = [];
          this.subHeader.forEach((element) => {
            if (element.visible) {
              suppliers.push(element.supplier_detail.id);
            }
          });
  
          if (JSON.stringify(this.fetchDataAbortController) != "{}") {
            // loop through an object (fetchDataAbortController)
            // and abort all the requests
            for (let key in this.fetchDataAbortController) {
              this.fetchDataAbortController[key].abort();
            }
            // clear the object
            this.fetchDataAbortController = {};
          }
  
          let controller = new AbortController();
          let randKey = Math.random().toString(36).substring(7);
          this.fetchDataAbortController[randKey] = controller;
  
          this.isLoading = true;
          let el = document.querySelector(".v-data-footer__pagination");
          if (el) el.style.display = "none";
          this.$store.state.isLoading = true;
          axios
            .get(
              API_BASE_URL +
                "/stocks" +
                "?page=" +
                this.page +
                "&items_per_page=" +
                ((itemsPerPage == -1 ? this.itemsPerPage : itemsPerPage) ||
                  this.itemsPerPage) +
                "&order_by=" +
                (sortBy[0] || this.sortBy) +
                "&order_direction=" +
                (!!sortDesc[0] || this.sortDesc ? "desc" : "asc") +
                "&parent_also=true" +
                (suppliers.length > 0
                  ? "&supplier_ids=" + suppliers.toString()
                  : "") +
                (this.familyArticle ? "&family=" + this.familyArticle.id : "") +
                (!this.normalSearch && this.season ? "&season=" + seasons : "") +
                (this.brand ? "&brand=" + brands : "") +
                (this.condition ? "&condition_id=" + this.condition.id : "") +
                (this.supplier ? "&supplier_id=" + this.supplier.id : "") +
                (this.search
                  ? "&search=" + encodeURIComponent(this.search)
                  : "") +
                (this.currency ? "&currency_id=" + this.currency.id : "") +
                (this.min_quantity_to_search
                  ? "&min_quantity_to_search=" + this.min_quantity_to_search
                  : "") +
                (this.tva ? "&tva_id=" + this.tva.id : "") +
                (this.familyClient ? "&familyClient=" + this.familyClient : "") +
                (this.is_favorite_searching ? "&is_favorite=1" : "") +
                (this.randomKeyValue
                  ? "&random_key_value" + this.randomKeyValue
                  : "") +
                (this.withOnlyInternalStock
                  ? "&only_internal_stock=" + this.withOnlyInternalStock
                  : "") +
                (this.withProposedArticles
                  ? "&with_proposed_article=" + this.withProposedArticles
                  : "") +
                combinedProperties,
              {
                signal: controller.signal,
                headers: this.header,
              }
            )
            .then(({ data }) => {
              this.articles = data.data.map((element) => {
                /*
                            if (element.property_01) {
                                element.property_01 = this.$t(element.property_01);
                            }
                            if (element.property_02) {
                                element.property_02 = this.$t(element.property_02);
                            }
                            */
                element.is_selected = false;
                if (
                  this.selectedArticlePendingAppointment &&
                  this.selectedArticlePendingAppointment.length > 0
                ) {
                  this.selectedArticlePendingAppointment.forEach(
                    (elementIntoAppointment) => {
                      if (elementIntoAppointment.article.id == element.id) {
                        element.is_selected = true;
                      }
                    }
                  );
                }
                let objkeys = Object.entries(element)
                  .filter(([key]) => {
                    return key.includes("_stock");
                  })
                  .map(([key]) => {
                    return key + "_is_selected";
                  });
                objkeys.forEach((objectKeys) => {
                  element[objectKeys] = false;
                });
                return element;
              });
              this.recheckAndFixSelectionOfArticles();
              if (data.data.length > 0) {
                this.articles.unshift([]); // add one extra row at beginning
              }
              this.lastPage = data.last_page;
              this.page = data.current_page;
              this.totalItems = data.total;
              this.itemsPerPage = parseInt(data.per_page);
              this.getHeightOfTheParentTable();
  
              this.isLoading = false;
              let el = document.querySelector(".v-data-footer__pagination");
              if (el) el.style.display = "none";
            })
            .catch((err) => {
              console.log(err);
              if (err?.message != "canceled") {
                this.isLoading = false;
              }
  
              this.parentTableHeight = this.getHeightOfTheParentTable();
            })
            .finally(() => {
              this.$store.state.isLoading = false;
              this.isLoading = false;
              this.randomKeyValue = null;
            });
        }
      },
      getHeightOfTheParentTable() {
        let height = 500;
        return height + "px";
      },
      setDefaultFamilyClient() {
        this.familyClients.forEach((element) => {
          if (element.is_default == 1) {
            this.familyClient = element.id;
          }
        });
      },
      getSupplierStatus() {
        return new Promise((resolve, reject) => {
          axios
            .get(API_BASE_URL + "/user_supplier_status", { headers: this.header })
            .then(({ data }) => {
              this.suppliers_statuses = data.data;
              resolve();
            })
            .catch(function (error) {
              console.log("an error occured " + error);
              reject();
            });
        });
      },
      getSupplier() {
        // if(this.familyArticle){
  
        axios
          .get(
            API_BASE_URL +
              "/suppliers?sortby=id&sorttype=asc" +
              (this.familyArticle ? "&family_id=" + this.familyArticle.id : "") +
              "&internal_supplier=true&parent_also=true",
            { headers: this.header }
          )
          .then(({ data }) => {
            let activeSuppliers = [];
            let storedFilteredSuppliers =
              localStorage.getItem("FILTERED_SUPPLIERS") ?? [];
            let orderedSuppliers = [];
            if (storedFilteredSuppliers.length != 0) {
              storedFilteredSuppliers = JSON.parse(storedFilteredSuppliers);
            }
            this.getSupplierStatus().then(() => {
              // Now Mark only the supplier which are in stored filtered suppliers
              this.suppliers_statuses.forEach((supplier_status) => {
                // if active then continue as by default all will be selected and we will push the supplier into localstorage that are not active
                if (supplier_status.is_active == 1) {
                  return;
                }
                data.data.forEach((supplier) => {
                  if (supplier.id == supplier_status.supplier_id) {
                    let supplierStoredFilterValue =
                      (supplier.identifier.toLowerCase() == 'int' ? 'brand' : supplier.identifier.toLowerCase()) + "_stock";
                    activeSuppliers.push(supplierStoredFilterValue);
                  }
                });
              });
              // Update Active Suppliers
              // let brandWasAlreadyUnselected =
              //   storedFilteredSuppliers.includes("brand_stock");
              // if (brandWasAlreadyUnselected) {
              //   activeSuppliers.push("brand_stock");
              // }
              console.log("all suppliers", activeSuppliers);
              localStorage.setItem(
                "FILTERED_SUPPLIERS",
                JSON.stringify(activeSuppliers)
              );
  
              this.suppliers_statuses.forEach((supplier_status) => {
                data.data.forEach((element) => {
                  if (supplier_status.supplier_id == element.id) {
                    orderedSuppliers.push(element);
                  }
                });
              });
              if (orderedSuppliers.length == 0) {
                orderedSuppliers = data.data;
              }
              data.data.forEach((element) => {
                let added = false;
                orderedSuppliers.forEach((ordered) => {
                  if (ordered.id == element.id) {
                    added = true;
                  }
                });
                if (!added) {
                  orderedSuppliers.push(element);
                }
              });
  
              // We Just need to show the Broker Suppliers, so we have to filter those suppliers
              // Supplier With Broker have type_id=10191001
              let brokerSuppliers = orderedSuppliers.filter((element) => {
                if (element.type_id == 10191001) {
                  // if Supplier is Broker then push to brokerSuppliers
                  return element;
                }
  
                return false;
              });
              let brandSuppliers = orderedSuppliers.filter((element) => {
                if (element.type_id == 10191000) {
                  // if Supplier is Brand then set ifBrandSupplierExists
                  this.ifBrandSUpplierExists = true;
                }
                if (element.type_id == 10191000) {
                  // if Supplier is Brand then push to brandSuppliers
                  return element;
                }
                return false;
              });
              // Just Add Any First Brand Supplier to the array
              if (brandSuppliers && brandSuppliers.length > 0) {
                let brandSupplier = brandSuppliers[0];
                brandSupplier.name = "BRAND";
                brandSupplier.identifier = "BRAND";
                brandSupplier.id = 0;
                brokerSuppliers.unshift(brandSupplier);
              }
              this.suppliers = brokerSuppliers;
  
              this.updateSubHeaders();
            });
          })
          .catch(function (error) {
            console.log("an error occured " + error);
          })
          .finally(() => {});
        // }
      },
      getConditions() {
        return new Promise((resolve) => {
          this.getCodes("article_conditions", "stocks.conditions").then(
            (data) => {
              this.conditions = data;
              resolve();
            }
          );
        });
      },
      getTvas() {
        return new Promise((resolve) => {
          this.tvas = this.$store.state.tvas;
          resolve();
        });
      },
      updateSubHeaders() {
        this.subHeader = [];
        this.tempSubHeader = [];
        this.subSlots = [];
        this.mainHeader = [];
        let storedFilteredSuppliers =
          localStorage.getItem("FILTERED_SUPPLIERS") ?? [];
        this.suppliers.filter((supplier, index) => {
          this.subHeader.push({
            text:
              supplier.name == "BRAND"
                ? this.$t("stocks.brand").toUpperCase()
                : supplier.name,
            value:
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_stock",
            align: "start",
            width: 300,
            sortable: false,
            field_stock:
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_stock",
            field_delivery_date:
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_delivery_date",
            field_price:
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_price",
            field_client_price:
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_client_price",
            header_text: supplier.name == "BRAND" ? "BRAND" : supplier.name,
            supplier_detail: supplier,
            order: index,
            index: index,
            visible: storedFilteredSuppliers.includes(
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_stock"
            )
              ? false
              : true,
            is_read_only: this.getIfIsReadOnly(supplier),
          });
          this.subSlots.push({
            field_stock:
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_stock",
            field_delivery_date:
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_delivery_date",
            field_price: supplier.identifier.toLowerCase() + "_price",
            field_client_price:
              supplier.identifier.toLowerCase() + "_client_price",
            header_text: supplier.name == "BRAND" ? "BRAND" : supplier.name,
            supplier_detail: supplier,
            order: index,
            index: index,
            visible: storedFilteredSuppliers.includes(
              (supplier.identifier == "BRAND"
                ? "brand"
                : supplier.identifier.toLowerCase()) + "_stock"
            )
              ? false
              : true,
            is_read_only: this.getIfIsReadOnly(supplier),
          });
          return true;
        });
  
        this.mainHeader = this.customHeader.concat(this.subHeader);
      },
      getIfIsReadOnly(item) {
        let isReadOnly = false;
        if (item.companySupplier && item.companySupplier.length > 0) {
          item.companySupplier.forEach((compSupp) => {
            isReadOnly = compSupp.is_read_only;
          });
        }
        return isReadOnly;
      },
      setDefaultFamilyArticle() {
        let defaultFamilyArticle = null;
        this.familyArticles.forEach((element) => {
          if (element.is_default) {
            defaultFamilyArticle = element;
          }
        });
        if (!defaultFamilyArticle) {
          this.familyArticle = this.familyArticles[0];
        } else {
          this.familyArticle = defaultFamilyArticle;
        }
        if (
          this.familyArticle &&
          this.familyArticle.is_default &&
          !this.familyArticle.is_stock_managed
        ) {
          this.$nextTick(() => {
            if (this.stockManagedFamilyArticles.length > 0) {
              this.familyArticle = this.stockManagedFamilyArticles[0];
            }
          });
        }
      },
      editItem() {
        // this.$store.commit('setFormMode', EDIT_MODE);
        // this.$router.push('/articles/edit/' + item.id).catch(() => {});
      },
      deleteItem() {},
      changeArticleProperties() {
        let currentFamilyArticle = this.familyArticles.find((element) => {
          if (this.familyArticle && element.id == this.familyArticle.id)
            return element;
        });
  
        if (!currentFamilyArticle) {
          currentFamilyArticle = this.getEverythingFamilyArticle();
        }
        // hard coding for family : todo later make it dynamic
        if (currentFamilyArticle.parent_family_id === 2) {
          this.season = "";
        }
  
        this.articleBooleanProperties = currentFamilyArticle.properties.filter(
          (property) => {
            if (property.cast == "boolean" && property.in_filter == 1) {
              property.name = property.translated
                ? property.name
                : this.$t("articles.properties." + property.name);
              property.property_name =
                "property_" +
                (property.property_id < 10
                  ? 0 + property.property_id.toString()
                  : property.property_id);
              property.translated = true;
              return property;
            }
            return false;
          }
        );
      },
      setCustomDataTableHeaders() {
        let currentFamilyArticle = this.familyArticles.find((element) => {
          if (this.familyArticle && element.id == this.familyArticle.id)
            return element;
        });
        if (!currentFamilyArticle) {
          currentFamilyArticle = this.getEverythingFamilyArticle();
        }
        if (this.familyArticle && this.familyArticle.parent_family_id == 1) {
          this.customHeader = [
            // { text: this.$t('stocks.id'),                   class:"sticky-header-tyres",       align: 'start', sortable: false, width: 80, value: 'article_id'},
            //   {
            //     text: this.$t("stocks.image"),
            //     class: "sticky-header-tyres",
            //     align: "start",
            //     sortable: false,
            //     width: 20,
            //     value: "image",
            //   },
            //   {
            //     text: this.$t("stocks.type_short"),
            //     class: "sticky-header-tyres",
            //     align: "start",
            //     sortable: false,
            //     width: 20,
            //     value: "type",
            //   },
            //   {
            //     text: this.$t("stocks.season_short"),
            //     //text: "Se",
            //     class: "sticky-header-tyres",
            //     align: "start",
            //     sortable: false,
            //     width: 20,
            //     value: "season",
            //   },
            {
              text: this.$t("stocks.article"),
              class: "sticky-header-tyres",
              align: "start",
              sortable: true,
              width: 600,
              value: "description",
            },
            {
              text: this.$t("stocks.tarrif"),
              class: "sticky-header-tyres",
              align: "start",
              sortable: false,
              width: 60,
              value: "tariff_price",
            },
            {
              text: this.$t("stocks.internal_stock"),
              class: "sticky-header-tyres",
              align: "start",
              sortable: false,
              width: 350,
              value: "brand_stock",
            },
          ];
        } else if (
          this.familyArticle &&
          this.familyArticle.parent_family_id == 2
        ) {
          this.customHeader = [
            // { text: this.$t('stocks.id'),                   class:"sticky-header-tyres",       align: 'start', sortable: false, width: 80, value: 'article_id'},
            {
              text: this.$t("stocks.image"),
              class: "sticky-header-tyres",
              align: "start",
              sortable: false,
              width: 80,
              value: "image",
            },
            {
              text: this.$t("stocks.article"),
              class: "sticky-header-tyres",
              align: "start",
              sortable: true,
              width: 600,
              value: "description",
            },
            {
              text: this.$t("stocks.tarrif"),
              class: "sticky-header-tyres",
              align: "start",
              sortable: false,
              width: 60,
              value: "tariff_price",
            },
            {
              text: this.$t("stocks.internal_stock"),
              class: "sticky-header-tyres",
              align: "start",
              sortable: false,
              width: 500,
              value: "brand_stock",
            },
          ];
        } else if (this.familyArticle && this.familyArticles.id == 3) {
          this.customHeader = [
            // { text: this.$t('stocks.id'),                   class:"sticky-header",       align: 'start', sortable: false, width: 80,    value: 'article_id'},
            {
              text: this.$t("stocks.image"),
              class: "sticky-header",
              align: "start",
              sortable: false,
              width: 80,
              value: "image",
            },
            {
              text: this.$t("stocks.article"),
              class: "sticky-header",
              align: "start",
              sortable: true,
              width: 600,
              value: "description",
            },
            {
              text: this.$t("stocks.tarrif"),
              class: "sticky-header",
              align: "start",
              sortable: false,
              width: 60,
              value: "tariff_price",
            },
            {
              text: this.$t("stocks.internal_stock"),
              class: "sticky-header",
              align: "start",
              sortable: false,
              width: 350,
              value: "brand_stock",
            },
          ];
        } else {
          this.customHeader = [
            // { text: this.$t('articles.id'),                 class:"sticky-header",value:'id', align:'start', sortable: false, width: 80},
            {
              text: this.$t("stocks.image"),
              class: "sticky-header",
              align: "start",
              sortable: false,
              width: 80,
              value: "image",
            },
            {
              text: this.$t("stocks.article"),
              class: "sticky-header",
              align: "start",
              sortable: true,
              width: 350,
              value: "description",
            },
            {
              text: this.$t("stocks.tarrif"),
              class: "sticky-header",
              align: "start",
              sortable: false,
              width: 60,
              value: "tariff_price",
            },
            {
              text: this.$t("stocks.internal_stock"),
              class: "sticky-header",
              align: "start",
              sortable: false,
              width: 300,
              value: "brand_stock",
            },
          ];
        }
  
        this.renderedFilters = [];
        // currentFamilyArticle.properties.filter((property) => {
        //     if( property.in_grid == 1) {
        //       this.customHeader.push({
        //         text:this.$t('articles.properties.' + property.name),
        //         value: 'property_'+ (property.property_id < 10 ? 0 + property.property_id.toString() : property.property_id) ,
        //         align:'start',
        //         sortable:true,
        //       });
        //       return true;
        //     }
        //   });
        // this.modifyOrderingOfCustomHeaders();
      },
      /**
       * This function is used to modify the ordering of Headers in Listing
       * Note : For ordering you must know the column name
       */
      // modifyOrderingOfCustomHeaders(){
      //   let sortedArray = [];
      //   let nonSortedArray = [];
      //   let sortingArray = ["ID","Img","Type","Season","Name","EAN","Width","Height","Rim","LI","SI","Tariff Price"];
      //   let processedArray = [];
      //   sortingArray.forEach((element, indexNo) => {
      //     for (const [key, value] of Object.entries(this.customHeader)) {
      //       let added = false;
  
      //       if(element == value.text  ){
      //         sortedArray.push(value);
      //         processedArray.push(value.text);
      //         added = true;
      //       }
      //       if(added == false && processedArray.find(element => element == value.text) == undefined && sortingArray.length == indexNo + 1){
      //         nonSortedArray.push(value);
      //       }
      //     }
      //   });
      //   this.customHeader = sortedArray.concat(nonSortedArray);
      // },
      setDynamicFilterBoxes() {
        let currentFamilyArticle = this.familyArticles.find((element) => {
          if (this.familyArticle && element.id == this.familyArticle.id)
            return element;
        });
        if (!currentFamilyArticle) {
          currentFamilyArticle = this.getEverythingFamilyArticle();
        }
        this.customFilters = [[]];
        this.multiselects = [];
        currentFamilyArticle.properties.filter((property) => {
          if (property.in_filter == 1) {
            let indexOfClass = (property.mapping ?? "").indexOf("class=");
            let mappingKey = null;
            if (indexOfClass != -1) {
              mappingKey = property.mapping.substr(indexOfClass + 6, 4) + "";
              let propertyName =
                "property_" +
                (property.property_id < 10
                  ? 0 + property.property_id.toString()
                  : property.property_id);
  
              if (this.familyArticle.properties) {
                this.familyArticle.properties[propertyName] = parseInt(
                  this.familyArticle.properties[propertyName]
                );
              }
            }
            if (mappingKey) {
              this.multiselects.push(mappingKey);
            }
            if (property.cast != "boolean") {
              this.customFilters[0].push({
                text: this.$t("articles.properties." + property.name),
                align: "start",
                sortable: true,
                property_name:
                  "property_" +
                  (property.property_id < 10
                    ? 0 + property.property_id.toString()
                    : property.property_id),
                property_id: property.property_id,
                column_name: property.name,
                value: null,
                property_type: property.cast,
                mapping: property.mapping ?? null,
                mapping_key: mappingKey ?? null,
                keytype: property.cast,
                search_length: property.search_length,
              });
            }
            return true;
          }
          return false;
        });
        if (this.isFirstTimeLoaded == 0) {
          this.isFirstTimeLoaded++;
        }
        this.changingArticlePropertiesInProgress = false;
        this.$nextTick(() => {
          this.isPageLoaded = true;
        });
      },
      getQueryStringFromDynamicallyCreatedFilters(searchIndex) {
        let queryString = "";
        let appliedFilters = [];
        this.customFilters[searchIndex].forEach((filter) => {
          if (filter.value) {
            queryString += "&" + filter.property_name + "=" + filter.value;
            appliedFilters.push({
              [filter.property_name]: filter.value,
            });
          }
        });
        (this.booleanSelectedValues[searchIndex] ?? []).forEach((filter) => {
          queryString += "&" + filter.property_name + "=" + 1; // 1 means true
          appliedFilters.push({
            [filter.property_name]: 1, // 1 means true
          });
        });
  
        // Return queryString and appliedFilters without visibility style
        return [queryString, appliedFilters];
      },
      updatePagination(page) {
        this.page = page;
        this.searchCounter++;
        this.fetchData(page);
      },
      updateItemsPerPage(itemsPerPage) {
        this.searchCounter++;
        this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
        this.fetchData();
        this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
      },
      getBrands() {
        if (this.familyArticle && this.familyArticle.id) {
          if (
            this.cachedData["brands"][this.familyArticle.id] &&
            this.cachedData["brands"][this.familyArticle.id].length > 0
          ) {
            this.brands = this.cachedData["brands"][this.familyArticle.id];
          } else {
            axios
              .get(
                API_BASE_URL +
                  "/brands?family=" +
                  this.familyArticle.id +
                  "&parent_also=true&items_per_page=-1",
                { headers: this.header }
              )
              .then(({ data }) => {
                this.brands = data.data;
                this.cachedData["brands"][this.familyArticle.id] = this.brands;
              })
              .catch(function (error) {
                console.log("an error occured " + error);
              })
              .finally(() => {});
          }
        }
      },
      updateSort(sortBy) {
        if (typeof sortBy == "object") {
          this.sortBy = sortBy[0];
        } else {
          this.sortBy = sortBy == undefined ? "id" : sortBy;
        }
      },
      updateSortOrder(sortDesc) {
        if (typeof sortDesc == "object") {
          !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
        } else {
          !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
        }
        this.fetchData();
      },
    },
    computed: {
      calculateDataTableHeightDynamically() {
        if (this.searchCounts.length == 0 || this.searchCounts.length == 1) {
          return "calc(-212px + 100vh)";
        } else if (this.searchCounts.length == 2) {
          return "calc(-266px + 100vh)";
        } else if (this.searchCounts.length == 3) {
          return "calc(-320px + 100vh)";
        } else if (this.searchCounts.length == 4) {
          return "calc(-374px + 100vh)";
        } else if (this.searchCounts.length == 5) {
          return "calc(-428px + 100vh)";
        }
        return "calc(-212px + 100vh)";
      },
      selectedArticlePendingAppointment() {
        return this.$store.state.selectedArticlePendingAppointment ?? [];
      },
      stockManagedFamilyArticles: function () {
        return this.familyArticles.filter((element) => {
          if (element.is_stock_managed == 1) {
            return element;
          }
        });
      },
      unCheckedSupplierFilters() {
        let unCheckedCount = 0;
        this.subHeader.forEach((element) => {
          if (element.supplier_detail && !element.visible) {
            unCheckedCount++;
          }
        });
        return unCheckedCount;
      },
    },
    watch: {
      supplierModel(val) {
        // Update order on closing the dialog
        if (val == false) {
          this.updateHeadersListingOrder();
        }
      },
      withProposedArticles: {
        handler: function () {
          this.fetchData();
        },
      },
      withOnlyInternalStock: {
        handler: function () {
          this.fetchData();
        },
      },
      familyClients: {
        handler: function () {
          if (this.familyClients.length > 0) {
            this.setDefaultFamilyClient();
          }
        },
      },
      normalSearch: {
        handler: function (val) {
          this.customFilters.forEach((element) => {
            element.value = null;
          });
          this.brand = null;
          if (val == false) {
            this.search = null;
          }
        },
      },
      subHeader: {
        handler: function () {
          this.subSlots = this.subHeader.filter((item) => {
            return item.visible;
          });
          let selected = this.subHeader.concat([]);
          // selected.sort(function(a,b){
          //   return a.order > b.order;
          // });
          selected = selected.filter((item) => {
            return item.visible;
          });
  
          this.mainHeader = this.customHeader.concat(selected);
  
          // Check if any supplier is selected
          const unselectedSupplier = this.subHeader.find(
            (item) => item.visible != true
          );
  
          this.supplierColumnVisibility =
            unselectedSupplier === undefined ? true : false;
  
          this.tempSubHeader = [...this.subHeader];
          this.markIntermediateStateAfterCheck();
        },
      },
  
      parentSearchSelectedArticleId(val) {
        if (val) {
          this.dialog = false;
  
          axios
            .post(API_BASE_URL + "/stocks/add/" + val, [], {
              headers: this.header,
            })
            .then(() => {
              this.$toast.success(this.$t("stocks.article_added_to_stock"));
              this.fetchData();
            })
            .catch((err) => {
              this.$toast.error(err.error);
            })
            .finally(() => {});
        }
      },
      familyArticles: {
        handler: function () {
          this.setDefaultFamilyArticle();
        },
      },
      familyArticle: {
        handler: function (val) {
          this.search = null;
  
          this.changingArticlePropertiesInProgress = true;
          this.brand = null;
          this.brands = [];
          this.suppliers = [];
          this.resetSearchCount();
  
          if (
            this.familyArticle &&
            this.familyArticle.parent_family_properties.length > 0
          ) {
            this.familyArticle.properties =
              this.familyArticle.parent_family_properties;
          }
          if (
            this.familyArticle &&
            (this.familyArticle.parent_family_id == 1 ||
              this.familyArticle.id == 1)
          ) {
            // this.searchCounts = [{search: 1}, {search: 1}];
          } else {
            this.searchCounts = [{ search: 1 }];
          }
          if (this.loadingFirstTime != 0) {
            this.fetchData();
          }
          this.getSupplier();
          this.changeArticleProperties();
          this.setCustomDataTableHeaders();
          this.setDynamicFilterBoxes();
  
          this.getMultiSelectValuesFromCode();
          this.getBrands();
          if (val) {
            this.stockUnits = val.default_quantity
              ? val.default_quantity > 0
                ? val.default_quantity
                : this.defaultStockUnits
              : this.defaultStockUnits;
          }
        },
      },
      familyClient: {
        handler: function () {
          this.fetchData();
        },
      },
      // brand:  {
      //   handler: function(){
      //     this.fetchData();
      //   },
      // },
      season: {
        handler: function () {
          this.fetchData();
        },
      },
      status: {
        handler: function () {
          this.fetchData();
        },
      },
      approval: {
        handler: function () {
          this.fetchData();
        },
      },
      condition: {
        handler: function () {
          this.fetchData();
        },
      },
      supplier: {
        handler: function () {
          this.fetchData();
        },
      },
      currency: {
        handler: function () {
          this.fetchData();
        },
      },
      tva: {
        handler: function () {
          this.fetchData();
        },
      },
    },
  };
  </script>
  
  <style scoped>
  div.noDrop .v-icon.v-icon--disabled {
    color: rgb(195 195 195 / 61%) !important; /* Overrides Vuetify's default */
  }
  
  .to-upper input {
    text-transform: uppercase !important;
  }
  
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 2px 6px !important;
  }
  
  .fixed-width {
    width: 90px !important;
  }
  
  .disable {
    pointer-events: none;
    opacity: 0.4;
  }
  .v-data-table >>> .v-data-footer__pagination {
    display: none !important;
  }
  .stockTable td {
    min-height: 55px !important;
    max-height: 55px !important;
    height: 55px !important;
  }
  </style>
  