<template>
  <v-row class="driver-1 ml-0 pl-0 mt-3">
    <v-col cols="12" sm="12" md="4">
      <v-combobox
        autocomplete="off"
        role="presentation"
        ref="driver_name"
        v-model="driver"
        :items="predictions"
        item-value="id"
        item-text="name"
        :label="$t('clients.last_name')"
        required
        class="to-upper"
        append-icon="mdi-close"
        :search-input.sync="search"
        no-filter
        @click:append="
          typeof driver === 'object' ? (driver.name = '') : (driver = {})
        "
        return-object
      >
        <template slot="selection" slot-scope="{ item }">
          {{ renderDriverLastName(item) }}
        </template>
        <template slot="item" slot-scope="{ item }">
          <v-list-item-content>
            <p class="client_name">{{ item.name }} {{ item.first_name }}</p>
            <p class="client_detail" v-if="item.representative_company_id">
              {{ $t("entity_type.company") }}
            </p>
            <p class="client_detail" v-else>
              {{ $t("entity_type.private") }}
            </p>
          </v-list-item-content>
        </template>
      </v-combobox>
    </v-col>
    <v-col cols="12" sm="12" md="4">
      <v-text-field
        autocomplete="off"
        role="presentation"
        v-model="driver.first_name"
        class="to-upper"
        name="driver_first_name"
        v-on:keydown="handleInput($event, 'alphanumeric', 100)"
        :label="$t('clients.first_name')"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="12" md="4">
      <v-text-field
        autocomplete="off"
        role="presentation"
        v-model="driver.license"
        class="to-upper"
        name="driver_license"
        :label="$t('vehicles.clients_model.driving_licence')"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12" md="4">
      <!-- <v-text-field
        autocomplete="off"
        role="presentation"
        v-model="driver.gsm"
        class="to-upper"
        name="driver_gsm"
        v-on:keydown="handleInput($event, 'alphanumeric', 100)"
        :label="$t('vehicles.clients_model.gsm')"
      ></v-text-field> -->
      <vue-tel-input-vuetify
        :disabledFetchingCountry="true"
        :preferredCountries="preferredCountry"
        :filterCountries="onlyCountries"
        validCharactersOnly
        :label="$t('vehicles.clients_model.gsm')"
        :inputOptions="{ showDialCode: true, tabindex: 0 }"
        :mode="'international'"
        v-model="driver.gsm"
      ></vue-tel-input-vuetify>
    </v-col>

    <v-col cols="12" sm="12" md="4">
      <!-- <v-text-field
        autocomplete="off"
        role="presentation"
        v-model="driver.phone"
        class="to-upper"
        name="driver_phone"
        v-on:keydown="handleInput($event, 'alphanumeric', 100)"
        :label="$t('vehicles.clients_model.phone')"
      ></v-text-field> -->
      <vue-tel-input-vuetify
        :disabledFetchingCountry="true"
        :preferredCountries="preferredCountry"
        :filterCountries="onlyCountries"
        validCharactersOnly
        :label="$t('vehicles.clients_model.phone')"
        :inputOptions="{ showDialCode: true, tabindex: 0 }"
        :mode="'international'"
        v-model="driver.phone"
      ></vue-tel-input-vuetify>
    </v-col>

    <v-col cols="12" sm="12" md="4">
      <v-text-field
        autocomplete="off"
        role="presentation"
        v-model="driver.email"
        class="to-upper"
        name="driver_email"
        v-on:keydown="handleInput($event, 'email', 60)"
        :label="$t('vehicles.clients_model.email')"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="12" md="12">
      <AddressInput
        v-model="driver.address"
        :label="$t('tyre_hotel.vehicle.address')"
      />
    </v-col>
  </v-row>
</template>
<script>
import AddressInput from "@/components/AddressInput";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import debounce from "lodash/debounce";
import VueTelInputVuetify from "@/components/PhoneNumberInput";
export default {
  name: "DriverForm",
  props: {
    value: {
      required: true,
    },
  },
  components: {
    AddressInput,
    "vue-tel-input-vuetify": VueTelInputVuetify,
  },
  data() {
    return {
      driver: this.value,
      search: "",
      predictions: [],
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
    };
  },
  watch: {
    value(val) {
      this.driver = val;
    },
    search() {
      this.debounceHandler();
    },
    driver: {
      handler(val) {
        if (typeof val == "string") {
          val = {
            name: val.toUpperCase(),
          };
        }

        if (val == null || val == undefined || val == "") {
          val = {};
        }
        this.updateModel(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.debounceHandler = debounce((event) => this.searchDrivers(event), 500);
  },
  beforeUnmount() {
    this.debounceHandler.cancel();
  },
  methods: {
    updateModel(val) {
      this.$emit("input", val);
    },
    renderDriverLastName(item) {
      if (typeof item == "string") {
        return item.toUpperCase();
      }
      return item.name;
    },
    searchDrivers() {
      if (!this.search) {
        return;
      }

      axios
        .get(
          API_BASE_URL +
            "/clients/search?" +
            (this.search ? "&search=" + encodeURIComponent(this.search) : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.predictions = data.data;
        })
        .finally(() => {});
    },
  },
};
</script>
