<template>
  <div></div>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import NotificationPopUpContent from "@/components/NotificationPopUpContent.vue";

export default {
  name: "PopupNotificationHandler",
  data() {
    return {
      notification_messages: [],
    };
  },
  mounted() {
    this.getNewNotifications();

    setInterval(() => {
      this.getNewNotifications();
    }, 300000); // 5 Minutes
    // this.storeInterval(notificationInterval);
  },
  methods: {
    getNewNotifications() {
      if (!localStorage.getItem("accessToken")) return null;
      axios
        .get(API_BASE_URL + "/notifications", {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(({ data }) => {
          let messages = data;
          if (messages.length > 0) {
            messages.forEach((message) => {
              this.$toast(
                {
                  component: NotificationPopUpContent,
                  props: {
                    text: message.body,
                    heading: this.$t("attention"),
                    action: message.action,
                  },
                  on: {
                    "close-toast": () => {
                      this.$toast.goAway(0); // Close the toast immediately
                    },
                  },
                },
                {
                  position: "top-center",
                  // timeout: this.computerMessageTimeoutByLength(message.body),
                  timeout: false,
                  closeOnClick: false,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 1,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: message.action ? "" : "button",
                  icon: "mdi mdi-comment-alert",
                  rtl: false,
                }
              );
            });
          }
        });
    },
    // computerMessageTimeoutByLength(message) {
    //     let timeout = 5000;
    //     if (message.length > 100) {
    //         timeout = 10000;
    //     }
    //     return timeout;
    // },
  },
};
</script>
