export default {
  computed: {
    userDateFormat() {
      let dateTimeFormat = localStorage.getItem("date_time_format");
      if (dateTimeFormat) {
        return dateTimeFormat.split(" ")[0];
      }
      return "YYYY-MM-DD";
    },
    userTimeFormat() {
      let dateTimeFormat = localStorage.getItem("date_time_format");
      if (dateTimeFormat) {
        return dateTimeFormat.split(" ")[1];
      }
      return "hh:mm";
    },
    userDateTimeFormat() {
      let dateTimeFormat = localStorage.getItem("date_time_format");
      if (dateTimeFormat) {
        return dateTimeFormat;
      }
      return "YYYY-MM-DD hh:mm";
    },
  },
  methods: {
    getCurrentDateTime() {
      return this.formatDateTime(new Date(), this.userDateTimeFormat);
    },
    getCurrentDate() {
      return this.formatDateTime(new Date(), this.userDateFormat);
    },
    getCurrentTime() {
      return this.formatDateTime(new Date(), this.userTimeFormat);
    },
    formatTime(inputTime) {
      return this.formatDateTime(inputTime, this.userTimeFormat);
    },
    formatDate(inputDate) {
      return this.formatDateTime(inputDate, this.userDateFormat);
    },
    formatDateTime(inputDate, format = null) {
      if (!inputDate) return "";

      if (!format) {
        format = this.userDateTimeFormat;
      }

      // Helper function to parse date from common formats
      const parseDate = (dateStr) => {
        // Check if input is already a Date object

        if (!isNaN(dateStr)) return dateStr;
        // Try to parse the date string based on common formats
        const isoFormat = /^\d{4}-\d{2}-\d{2}/; // YYYY-MM-DD
        const slashFormat = /^\d{2}\/\d{2}\/\d{4}/; // DD/MM/YYYY or MM/DD/YYYY
        const dashFormat = /^\d{2}-\d{2}-\d{4}/; // DD-MM-YYYY or MM-DD-YYYY

        let dateParts;
        if (isoFormat.test(dateStr)) {
          // For 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:mm:ssZ'
          return new Date(dateStr);
        } else if (slashFormat.test(dateStr)) {
          // Handle 'DD/MM/YYYY' or 'MM/DD/YYYY'
          dateParts = dateStr.split("/");
          // Check if day is more than 12 to decide format (assumes DD/MM/YYYY if day > 12)
          const isDMY = parseInt(dateParts[0], 10) > 12;
          const day = isDMY ? dateParts[0] : dateParts[1];
          const month = isDMY ? dateParts[1] : dateParts[0];
          const year = dateParts[2];
          return new Date(`${year}-${month}-${day}`);
        } else if (dashFormat.test(dateStr)) {
          // Handle 'DD-MM-YYYY' or 'MM-DD-YYYY'
          dateParts = dateStr.split("-");
          const isDMY = parseInt(dateParts[0], 10) > 12;
          const day = isDMY ? dateParts[0] : dateParts[1];
          const month = isDMY ? dateParts[1] : dateParts[0];
          const year = dateParts[2];
          return new Date(`${year}-${month}-${day}`);
        }

        // If parsing fails, return invalid date
        return new Date(dateStr);
      };

      // Parse the input date
      const d = parseDate(inputDate);
      if (isNaN(d.getTime())) return "Invalid Date";

      // Extract date components
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const year = d.getFullYear();

      // Extract time components for 24-hour format
      const hours24 = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");

      // Convert to 12-hour format
      const hours12 = String(d.getHours() % 12 || 12).padStart(2, "0");
      const ampm = d.getHours() >= 12 ? "PM" : "AM";

      // Replace format tokens
      return format
        .replace("DD", day)
        .replace("dd", day)
        .replace("MM", month)
        .replace("YYYY", year)
        .replace("yyyy", year)
        .replace("HH", hours24) // 24-hour format
        .replace("hh", hours12) // 12-hour format
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("A", ampm); // AM/PM
    },
  },
};
