<template>
  <v-card class="user-role-wrapper">
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <v-row class="pt-5">
      <v-col cols="12" md="12" class="pt-0">
        <v-col cols="12" md="12" class="pt-0">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                class="to-upper"
                label="Role Name"
                v-model="selectedRole.name"
                @blur="onRoleNameChange()"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pt-0">
              <v-textarea
                rows="4"
                @blur="onDescriptionChange()"
                v-model="selectedRole.description"
                :label="description"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <v-col col="12" sm="12" class="pt-0">
        <v-col col="12" sm="12" class="pt-0">
          <v-col col="12" sm="12" class="">
            <v-card>
              <v-card-actions class="flex-column">
                <v-col col="12" sm="12" class="text-right pl-0 pr-0">
                  <v-dialog v-model="dialog" width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        color="primary"
                        v-bind="attrs"
                        :disabled="!selectedSlug.id"
                        v-on="on"
                        >{{ $t("user_role.Add") }}</v-btn
                      >
                    </template>

                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        {{ $t("user_role.AddResources") }}
                      </v-card-title>
                      <v-card-actions>
                        <v-row>
                          <v-col cols="12" md="12" class="pb-0 pt-0">
                            <v-text-field
                              class="to-upper"
                              :label="ResourceName"
                              v-model="resource_name"
                              :error-messages="ResourceNameError"
                              required
                              @input="$v.resource_name.$touch()"
                              @blur="$v.resource_name.$touch()"
                              :outlined="is_boxes"
                              :dense="is_boxes"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" class="pb-0 pt-0">
                            <v-textarea
                              rows="4"
                              v-model="resource_description"
                              :error-messages="ResourceDescriptionError"
                              required
                              @input="$v.resource_description.$touch()"
                              @blur="$v.resource_description.$touch()"
                              :label="description"
                              :outlined="is_boxes"
                              :dense="is_boxes"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" md="12" class="pb-0 pt-0">
                            <v-select
                              v-model="selectedSlug"
                              :items="slugs"
                              item-text="slug"
                              item-value="id"
                              :label="SelectSlug"
                              :disabled="true"
                              return-object
                              :outlined="is_boxes"
                              :dense="is_boxes"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="12" class="mb-3 text-right">
                            <v-btn
                              color="primary"
                              class="mt-2 ml-2 mr-2 mb-2"
                              @click="onSaveResource()"
                              ><v-icon class="mr-1">mdi-check</v-icon>
                              {{ $t("user_role.Save") }}</v-btn
                            >
                            <v-btn
                              class="mt-2 ml-2 mr-2 mb-2"
                              @click="onCancelResource()"
                              ><v-icon class="mr-1">mdi-close</v-icon
                              >{{ $t("user_role.Cancel") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-simple-table
                  class="w-100 table-responsive"
                  height="425"
                  fixed-header="true"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">{{ $t("user_role.Slug") }}</th>
                        <th class="text-left">
                          {{ $t("user_role.Description") }}
                        </th>
                        <th class="text-left">{{ $t("user_role.Canadd") }}</th>
                        <th class="text-left">{{ $t("user_role.Canedit") }}</th>
                        <th class="text-left">
                          {{ $t("user_role.Candelete") }}
                        </th>
                        <th class="text-left">{{ $t("user_role.Canview") }}</th>
                      </tr>
                      <tr>
                        <th>
                          <v-select
                            v-model="selectedSlug"
                            :items="slugs"
                            item-text="slug"
                            item-value="id"
                            :label="SelectSlug"
                            return-object
                            @change="onSelectedSlugChange()"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                          ></v-select>
                        </th>
                        <th>{{ selectedSlug.description }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in slugsResources" :key="item.name">
                        <td>{{ item.resource_name }}</td>
                        <td>{{ item.resource_description }}</td>
                        <td>
                          <v-checkbox
                            v-model="item.role_resource.add"
                            @change="
                              onCheck(
                                item.resource_id,
                                item.role_resource.add,
                                'can_add'
                              )
                            "
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-checkbox
                            v-model="item.role_resource.edit"
                            @change="
                              onCheck(
                                item.resource_id,
                                item.role_resource.edit,
                                'can_edit'
                              )
                            "
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-checkbox
                            v-model="item.role_resource.delete"
                            @change="
                              onCheck(
                                item.resource_id,
                                item.role_resource.delete,
                                'can_delete'
                              )
                            "
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-checkbox
                            v-model="item.role_resource.view"
                            @change="
                              onCheck(
                                item.resource_id,
                                item.role_resource.view,
                                'can_view'
                              )
                            "
                          ></v-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    resource_name: { required, maxLength: maxLength(100) },
    resource_description: { required, maxLength: maxLength(170) },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      resource_description: "",
      resource_name: "",
      selectedRole: {},
      slugs: [],
      slugsResources: [],
      selectedSlug: {},
      dialog: false,
      // saveRole: true,
      // duplicateRole: JSON.parse(localStorage.getItem('DuplicateRole')),
      duplicateRole: this.$store.state.DuplicateRole,

      // i18
      selectRole: this.$t("user_role.select_role"),
      description: this.$t("user_role.role_description"),
      SelectSlug: this.$t("user_role.select_slug"),
      ResourceName: this.$t("user_role.resource_name"),

      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
    };
  },
  computed: {
    ResourceNameError() {
      const errors = [];
      if (!this.$v.resource_name.$dirty) return errors;
      !this.$v.resource_name.maxLength &&
        errors.push("Resource name is not allowd morethen 100 characters");
      !this.$v.resource_name.required &&
        errors.push("Resource Name is required");
      return errors;
    },
    ResourceDescriptionError() {
      const errors = [];
      if (!this.$v.resource_description.$dirty) return errors;
      !this.$v.resource_description.maxLength &&
        errors.push(
          "Resource Description is not allowd morethen 170 characters"
        );
      !this.$v.resource_description.required &&
        errors.push("Resource Description is required");
      return errors;
    },
  },
  created() {
    // this.getRolesList()
    (this.header = { Authorization: "Bearer " + this.token }),
      (this.selectedRole = {
        description: this.duplicateRole.description,
        id: this.duplicateRole.role_id,
      });
    this.getSlugList();
  },
  methods: {
    getSlugList() {
      axios({
        method: "get",
        url: API_BASE_URL + "/resources",
        params: { role_id: this.duplicateRole.role_id },
        headers: this.header,
      })
        .then((resSlug) => {
          this.slugs = resSlug.data.data.result;
          this.slugs.forEach((slug) => {
            if (slug.id == 1) {
              this.selectedSlug = slug;
              // this.getUpdatedResourceList(payload)
              this.getSlugResourcesList();
            }
          });
        })
        .catch((error) => {
          this.$swal.fire({
            position: "top-end",
            title: error.response.data.msg,
            toast: true,
            icon: "error",
            showConfirmButton: false,
            timer: 5000,
          });
        });
    },
    getSlugResourcesList() {
      this.slugsResources = this.duplicateRole.role_resource;
      this.slugsResources.forEach((slugResource) => {
        if (slugResource.role_resource.add == 0) {
          slugResource.role_resource.add = false;
        } else if (slugResource.role_resource.add == 1) {
          slugResource.role_resource.add = true;
        }
        if (slugResource.role_resource.delete == 0) {
          slugResource.role_resource.delete = false;
        } else if (slugResource.role_resource.delete == 1) {
          slugResource.role_resource.delete = true;
        }
        if (slugResource.role_resource.edit == 0) {
          slugResource.role_resource.edit = false;
        } else if (slugResource.role_resource.edit == 1) {
          slugResource.role_resource.edit = true;
        }
        if (slugResource.role_resource.view == 0) {
          slugResource.role_resource.view = false;
        } else if (slugResource.role_resource.view == 1) {
          slugResource.role_resource.view = true;
        }
      });
    },
    getUpdatedResourceList(payload) {
      axios({
        method: "get",
        url: API_BASE_URL + "/resources",
        params: payload,
        headers: this.header,
      })
        .then((resResources) => {
          this.slugsResources = resResources.data.data.result;
          this.slugsResources.forEach((slugResource) => {
            if (slugResource.role_resource.add == 0) {
              slugResource.role_resource.add = false;
            } else if (slugResource.role_resource.add == 1) {
              slugResource.role_resource.add = true;
            }
            if (slugResource.role_resource.delete == 0) {
              slugResource.role_resource.delete = false;
            } else if (slugResource.role_resource.delete == 1) {
              slugResource.role_resource.delete = true;
            }
            if (slugResource.role_resource.edit == 0) {
              slugResource.role_resource.edit = false;
            } else if (slugResource.role_resource.edit == 1) {
              slugResource.role_resource.edit = true;
            }
            if (slugResource.role_resource.view == 0) {
              slugResource.role_resource.view = false;
            } else if (slugResource.role_resource.view == 1) {
              slugResource.role_resource.view = true;
            }
          });
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$swal.fire({
            position: "top-end",
            title: error.response.data.msg,
            toast: true,
            icon: "error",
            showConfirmButton: false,
            timer: 5000,
          });
        });
    },
    onSelectedSlugChange() {
      const payload = {
        role_id: this.selectedRole.id,
        parent_id: this.selectedSlug.id,
      };
      this.getUpdatedResourceList(payload);
    },
    onSaveResource() {
      const payload = {
        name: this.resource_name,
        description: this.resource_description,
        parent_id: this.selectedSlug.id,
      };
      axios({
        method: "post",
        url: API_BASE_URL + "/resources",
        data: payload,
        headers: this.header,
      })
        .then((resNewResources) => {
          if (resNewResources.data.data.code == 200) {
            const payload = {
              role_id: this.selectedRole.id,
              parent_id: this.selectedSlug.id,
            };
            this.getUpdatedResourceList(payload);
            this.resource_name = "";
            this.resource_description = "";
            this.$swal.fire({
              position: "top-end",
              title: resNewResources.data.msg,
              toast: true,
              icon: "success",
              showConfirmButton: false,
              timer: 5000,
            });
            this.dialog = false;
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (typeof error?.response?.data?.errors == "object") {
            error.response.data.errors.forEach((error) => {
              this.$swal.fire({
                position: "top-end",
                title: error,
                toast: true,
                icon: "error",
                showConfirmButton: false,
                timer: 5000,
              });
            });
          } else {
            this.$swal.fire({
              position: "top-end",
              title: error.response.data.message,
              toast: true,
              icon: "error",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        });
    },
    onCancelResource() {
      this.resource_name = "";
      this.resource_description = "";
      this.dialog = false;
    },
    onCheck(id, value, type) {
      // value = !value
      if (value == true) {
        value = 1;
      } else if (value == false) {
        value = 0;
      }
      let permission = {};
      if (type == "can_add") {
        permission = { can_add: value };
      } else if (type == "can_edit") {
        permission = { can_edit: value };
      } else if (type == "can_delete") {
        permission = { can_delete: value };
      } else if (type == "can_view") {
        permission = { can_view: value };
      }
      const payload = {
        resource_id: id,
        permission,
      };
      this.onUpdateRole(payload);
    },
    onDescriptionChange() {
      const payload = {
        description: this.selectedRole.description,
      };
      this.onUpdateRole(payload);
    },
    onRoleNameChange() {
      const payload = {
        name: this.selectedRole.name,
      };
      axios({
        method: "post",
        url: API_BASE_URL + "}/user_roles/exist/" + this.selectedRole.name,
        headers: this.header,
      })
        .then((resRoleName) => {
          if (resRoleName.data.data.code == 200) {
            this.onUpdateRole(payload);
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (typeof error?.response?.data?.errors == "object") {
            error.response.data.errors.forEach((error) => {
              this.$swal.fire({
                position: "top-end",
                title: error,
                toast: true,
                icon: "error",
                showConfirmButton: false,
                timer: 5000,
              });
            });
          } else {
            this.$swal.fire({
              position: "top-end",
              title: error.response.data.errors,
              toast: true,
              icon: "error",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        });
    },
    onUpdateRole(payload) {
      axios({
        method: "put",
        url: API_BASE_URL + "/roles/" + this.selectedRole.id,
        headers: this.header,
        data: payload,
      })
        .then((res) => {
          this.$swal.fire({
            position: "top-end",
            title: res.data.msg,
            toast: true,
            icon: "success",
            showConfirmButton: false,
            timer: 5000,
          });
        })
        .catch((error) => {
          console.log(error.response.data);
          if (typeof error?.response?.data?.errors == "object") {
            error.response.data.errors.forEach((error) => {
              this.$swal.fire({
                position: "top-end",
                title: error,
                toast: true,
                icon: "error",
                showConfirmButton: false,
                timer: 5000,
              });
            });
          } else {
            this.$swal.fire({
              position: "top-end",
              title: error.response.data.errors,
              toast: true,
              icon: "error",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
@import url("../../../assets/CSS/style-main.css");
</style>
