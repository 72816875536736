<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-combobox
          presentation="off"
          :label="translate('employee')"
          :items="formattedEmployees"
          item-text="text"
          v-model="selectedEmployee"
          @update:search-input="searchEmployees"
          :loading="searching"
          class="to-upper"
          clearable
        >
          <template #prepend>
            <v-btn icon @click="gridView = false" v-if="gridView">
              <v-icon color="primary">mdi-view-list</v-icon>
            </v-btn>
            <v-btn icon @click="gridView = true" v-if="!gridView">
              <v-icon color="primary">mdi-view-grid</v-icon>
            </v-btn>
          </template>
        </v-combobox>
      </v-col>
      <v-col md="1">
        <v-select
          :items="filterOptions"
          item-text="title"
          item-value="value"
          :label="translate('filter_options.title')"
          v-model="selectedFilter"
        >
        </v-select>
      </v-col>
      <v-col v-if="selectedFilterIsDay" md="2">
        <v-menu
          v-model="dayPickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDay"
              :label="translate('filter_options.select_day')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDay"
            @input="dayPickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="selectedFilterIsWeek" md="2">
        <v-menu
          v-model="weekPickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedWeek"
              :label="translate('filter_options.select_week')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedWeek"
            @input="weekPickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="selectedFilterIsMonth" md="2">
        <v-menu
          v-model="monthPickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedSelectedMonth"
              :label="translate('filter_options.select_month')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="selectedMonth"
            @input="monthPickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="selectedFilterIsCustom" md="4">
        <v-row>
          <v-col>
            <v-menu
              v-model="fromDatePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedFromDate"
                  :label="translate('filter_options.select_from_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedFromDate"
                @input="fromDatePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="toDatePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedToDate"
                  :label="translate('filter_options.select_to_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedToDate"
                @input="toDatePickerMenu = false"
                :min="selectedFromDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="4" v-if="gridView">
        <div class="d-flex justify-start flex-nowrap">
          <div style="margin-right: 20px">
            <v-checkbox
              v-model="showBreakColumns"
              :label="translate('filter_options.show_break_columns')"
              color="success"
              hide-details
            ></v-checkbox>
          </div>
          <div>
            <v-checkbox
              v-model="showMissionColumns"
              :label="translate('filter_options.show_mission_columns')"
              color="success"
              hide-details
            ></v-checkbox>
          </div>
        </div>
      </v-col>
      <v-col cols="2" v-if="!gridView">
        <v-select
          :items="processedTypes"
          :label="translate('clocking_type')"
          v-model="selectedType"
          item-text="text"
          item-value="id"
          clearable
        />
      </v-col>
    </v-row>
    <v-row v-if="!gridView">
      <ClockingTableListing
        :selectedMonth="selectedMonth"
        :selectedWeek="selectedWeek"
        :filter-type="selectedFilter"
        :from-date="selectedFromDate"
        :to-date="selectedToDate"
        :selected-employee="selectedEmployee"
        :selected-type="selectedType"
        :day="selectedDay"
        :grid-view="gridView"
        v-if="isset(selectedClockingData) && !gridView"
        :key="$store.state.forceReloadComponentKey"
      />
    </v-row>
    <v-row
      style="overflow-x: auto; overflow-y: auto"
      class="mb-5"
      v-if="gridView"
    >
      <v-col :cols="8">
        <v-simple-table
          v-if="
            isset(selectedClockingData) && isset(selectedEmployee) && gridView
          "
          :height="$store.state.defaultTableHeight"
          fixed-header
        >
          <template #default>
            <thead>
              <tr>
                <th style="min-width: 120px; max-width: 121px">
                  {{ translate("filter_options.day") }}
                </th>
                <th style="width: 50px">{{ translate("net") }}</th>
                <th style="width: 50px">{{ translate("expected") }}</th>
                <th style="width: 50px">{{ translate("balance") }}</th>
                <th style="width: 120px">{{ translate("type") }}</th>
                <th>{{ translate("check_in") }}</th>
                <th>{{ translate("check_out") }}</th>
                <th style="width: 20px">{{ translate("total") }}</th>
                <th v-if="showBreakColumns" colspan="2">
                  {{ translate("break") }}
                </th>
                <th v-if="showBreakColumns" style="width: 20px">
                  {{ translate("total_break") }}
                </th>
                <th v-if="showMissionColumns" colspan="2">
                  {{ translate("mission") }}
                </th>
                <th v-if="showMissionColumns" style="width: 20px">
                  {{ translate("total_mission") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(superItem, day) in selectedClockingData.clocking"
              >
                <tr
                  v-for="(item, index) in extractClockingByType(
                    superItem,
                    'master'
                  )"
                  :key="item.id"
                  :style="{
                    'text-align': 'right',
                    background: computeRowBgColor(
                      selectedClockingData.summary[day]['expected']
                    ),
                  }"
                  :class="{
                    disablederrr:
                      selectedClockingData.summary[day]['expected'] === 0,
                  }"
                >
                  <td
                    :style="{
                      'border-bottom': borderBottomColor(day, item),
                    }"
                    @mouseenter="openInNewTabId = item.id"
                    @mouseleave="openInNewTabId = null"
                  >
                    <span v-if="index === 0" class="my-auto">
                      <v-icon
                        small
                        class="float-left"
                        @click="toggleEmployeeClockingForDayModal(day)"
                        :style="{
                          visibility:
                            openInNewTabId === item.id ? 'visible' : 'hidden',
                        }"
                      >
                        mdi-open-in-new</v-icon
                      >
                      {{ formatDay(day) }}
                    </span>
                  </td>
                  <td
                    :style="{
                      'border-bottom': borderBottomColor(day, item),
                      background: clockingColor['work'],
                    }"
                  >
                    <span v-if="index === 0">
                      {{
                        convertMinutesToHours(
                          selectedClockingData.summary[day].total
                        )
                      }}
                    </span>
                  </td>
                  <td
                    :style="{
                      'border-bottom': borderBottomColor(day, item),
                    }"
                  >
                    <div v-if="index === 0">
                      {{
                        convertMinutesToHours(
                          selectedClockingData.summary[day].expected
                        )
                      }}
                    </div>
                  </td>
                  <td
                    :style="{
                      'border-bottom': borderBottomColor(day, item),
                    }"
                    @click="toggleEmployeeClockingForDayModal(day)"
                  >
                    <div
                      :style="{
                        color:
                          selectedClockingData.summary[day].balance < 0
                            ? 'darkred'
                            : '',
                        cursor: 'pointer',
                      }"
                      v-if="index === 0"
                    >
                      {{
                        convertMinutesToHours(
                          selectedClockingData.summary[day].balance
                        )
                      }}
                    </div>
                  </td>
                  <td
                    :style="{
                      'border-bottom': borderBottomColor(day, item),
                      cursor: 'pointer',
                      background: computeWorkBgColor(
                        selectedClockingData.clocking[day][index],
                        selectedClockingData.summary[day]
                      ),
                      'text-align':
                        computeColSpan(item, day) === 4 ? 'center' : 'left',
                    }"
                    @mouseover="lastHoveredClockingId = item.id"
                    @mouseleave="lastHoveredClockingId = null"
                    @click="addNewClockingOthers(day)"
                    :colspan="computeColSpan(item, day)"
                  >
                    <span
                      v-if="
                        clockingIsForFirstColumn(item) &&
                        (selectedClockingData.summary[day]['expected'] > 0 ||
                          selectedClockingData.summary[day]['total'] !== 0)
                      "
                    >
                      {{
                        translate(
                          "types." +
                            (item.is_placeholder ? "absent" : item.type.key)
                        )
                      }}

                      <span
                        class="ml-auto"
                        v-if="
                          clockingIsForFirstColumn(item) && !item.is_placeholder
                        "
                      >
                        <v-btn
                          small
                          icon
                          @click="confirmDeleteAction(item, day)"
                          class="mr-0"
                          :style="{
                            cursor: 'pointer',
                            visibility:
                              lastHoveredClockingId === item.id
                                ? 'visible'
                                : 'hidden',
                          }"
                        >
                          <v-icon small color="red">mdi-close</v-icon>
                        </v-btn>
                      </span>
                    </span>
                  </td>
                  <td
                    :style="{ 'border-bottom': borderBottomColor(day, item) }"
                    v-if="computeColSpan(item, day) !== 4"
                  >
                    <ClockingInput
                      :day="day"
                      :employee="selectedEmployee"
                      target="check_in"
                      :clocking="item"
                      type="work"
                      @updated="(response) => hydrateClockingData(response)"
                      v-if="clockingIsForFirstColumn(item)"
                    />
                  </td>
                  <td
                    :style="{ 'border-bottom': borderBottomColor(day, item) }"
                    v-if="computeColSpan(item, day) !== 4"
                  >
                    <ClockingInput
                      :day="day"
                      :employee="selectedEmployee"
                      target="check_out"
                      :clocking="item"
                      :disabled="
                        !(
                          clockingIsForFirstColumn(item) &&
                          item &&
                          item.id &&
                          !item.is_placeholder
                        )
                      "
                      type="work"
                      @updated="(response) => hydrateClockingData(response)"
                    />
                  </td>
                  <td
                    :style="{
                      background: clockingColor['work'],
                      'border-bottom': borderBottomColor(day, item),
                    }"
                    v-if="computeColSpan(item, day) !== 4"
                  >
                    <span v-if="clockingIsForFirstColumn(item)">
                      {{ convertMinutesToHours(item.time_difference) }}</span
                    >
                  </td>

                  <td
                    :style="{ 'border-bottom': borderBottomColor(day, item) }"
                    v-if="showBreakColumns"
                  >
                    <div>
                      <template
                        v-for="i in extractClockingByType(
                          selectedClockingData.clocking[day],
                          'break'
                        )"
                      >
                        <ClockingInput
                          :key="i.id"
                          :day="day"
                          :employee="selectedEmployee"
                          target="check_in"
                          :clocking="i"
                          type="break"
                          :readonly="
                            !(
                              isFirstColumn(index) &&
                              workHasBeenLoggedForDay(day)
                            )
                          "
                          @updated="(response) => hydrateClockingData(response)"
                        />
                      </template>
                      <ClockingInput
                        v-if="
                          extractClockingByType(
                            selectedClockingData.clocking[day],
                            'break'
                          ).length === 0
                        "
                        :readonly="
                          !(
                            isFirstColumn(index) && workHasBeenLoggedForDay(day)
                          )
                        "
                        :day="day"
                        :employee="selectedEmployee"
                        target="check_in"
                        :clocking="null"
                        type="break"
                        :disabled="
                          !(
                            isFirstColumn(index) && workHasBeenLoggedForDay(day)
                          )
                        "
                        @updated="(response) => hydrateClockingData(response)"
                      />
                    </div>
                  </td>
                  <td
                    :style="{ 'border-bottom': borderBottomColor(day, item) }"
                    v-if="showBreakColumns"
                  >
                    <div>
                      <template
                        v-for="i in extractClockingByType(
                          selectedClockingData.clocking[day],
                          'break'
                        )"
                      >
                        <ClockingInput
                          :key="i.id"
                          :day="day"
                          :employee="selectedEmployee"
                          target="check_out"
                          :clocking="i"
                          type="break"
                          :disabled="
                            !(
                              isFirstColumn(index) &&
                              workHasBeenLoggedForDay(day)
                            )
                          "
                          @updated="(response) => hydrateClockingData(response)"
                          v-if="i && i.id"
                        />
                      </template>
                    </div>
                  </td>
                  <td
                    :style="{
                      background: clockingColor['break'],
                      'border-bottom': borderBottomColor(day, item),
                    }"
                    v-if="showBreakColumns"
                  >
                    <div v-if="isFirstColumn(index)">
                      <template
                        v-for="i in extractClockingByType(
                          selectedClockingData.clocking[day],
                          'break'
                        )"
                      >
                        <p :key="i.id">
                          {{ convertMinutesToHours(i.time_difference) }}
                        </p>
                      </template>
                    </div>
                  </td>

                  <td
                    :style="{ 'border-bottom': borderBottomColor(day, item) }"
                    v-if="showMissionColumns"
                  >
                    <div>
                      <template
                        v-for="i in extractClockingByType(
                          selectedClockingData.clocking[day],
                          'mission'
                        )"
                      >
                        <ClockingInput
                          :key="i.id"
                          :day="day"
                          :employee="selectedEmployee"
                          target="check_in"
                          :clocking="i"
                          type="mission"
                          @updated="(response) => hydrateClockingData(response)"
                        />
                      </template>
                      <ClockingInput
                        v-if="
                          extractClockingByType(
                            selectedClockingData.clocking[day],
                            'mission'
                          ).length === 0
                        "
                        :day="day"
                        :employee="selectedEmployee"
                        target="check_in"
                        :clocking="null"
                        type="mission"
                        :disabled="
                          !(
                            isFirstColumn(index) && workHasBeenLoggedForDay(day)
                          )
                        "
                        @updated="(response) => hydrateClockingData(response)"
                      />
                    </div>
                  </td>
                  <td
                    :style="{ 'border-bottom': borderBottomColor(day, item) }"
                    v-if="showMissionColumns"
                  >
                    <div>
                      <template
                        v-for="i in extractClockingByType(
                          selectedClockingData.clocking[day],
                          'mission'
                        )"
                      >
                        <ClockingInput
                          :key="i.id"
                          :day="day"
                          :employee="selectedEmployee"
                          target="check_out"
                          :clocking="i"
                          type="mission"
                          :disabled="
                            !(
                              isFirstColumn(index) &&
                              workHasBeenLoggedForDay(day)
                            )
                          "
                          @updated="(response) => hydrateClockingData(response)"
                          v-if="i && i.id"
                        />
                      </template>
                    </div>
                  </td>
                  <td
                    :style="{
                      background: clockingColor['work'],
                      'border-bottom': borderBottomColor(day, item),
                    }"
                    v-if="showMissionColumns"
                  >
                    <div v-if="isFirstColumn(index)">
                      <template
                        v-for="i in extractClockingByType(
                          selectedClockingData.clocking[day],
                          'mission'
                        )"
                      >
                        <p :key="i.id">
                          {{ convertMinutesToHours(i.time_difference) }}
                        </p>
                      </template>
                    </div>
                  </td>
                </tr>
              </template>
              <!-- Total Section Starts Here -->
              <tr style="font-weight: bolder; text-align: right">
                <td>Total</td>
                <td>{{ convertMinutesToHours(overAllTotal) }}</td>
                <td>
                  {{ convertMinutesToHours(calculateOverall("expected")) }}
                </td>
                <td>
                  {{ convertMinutesToHours(calculateOverall("balance")) }}
                  <!--            <v-icon v-if="calculateOverall('balance') > 0" color="success">mdi-arrow-up-->
                  <!--            </v-icon>-->
                  <!--            <v-icon color="success" v-else-if="calculateOverall('balance') === 0">mdi-check</v-icon>-->
                  <!--            <v-icon color="red" v-else>mdi-arrow-down</v-icon>-->
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ convertMinutesToHours(calculateOverall("work")) }}</td>
                <td v-if="showBreakColumns"></td>
                <td v-if="showBreakColumns"></td>
                <td v-if="showBreakColumns">
                  {{ convertMinutesToHours(calculateOverall("break")) }}
                </td>
                <td v-if="showMissionColumns"></td>
                <td v-if="showMissionColumns"></td>
                <td v-if="showMissionColumns">
                  {{ convertMinutesToHours(calculateOverall("mission")) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="0">
        <EmployeeCounterTable
          :employee="selectedEmployee"
          v-show="showCounterTable"
          @showCountersTable="(val) => (showCounterTable = val)"
          @editEmployeeCounter="
            (counter) => {
              showEmployeeCounterForm = false;
              selectedEmployeeCounter = counter;
              $nextTick(() => (showEmployeeCounterForm = true));
            }
          "
        />
      </v-col>
    </v-row>

    <AddClocking
      v-if="selectedClockingData && selectedEmployee"
      v-model="addClockingDialog"
      :employee-contract="selectedClockingData.contract"
      :fromDate="formattedFromDate"
      :toDate="formattedToDate"
      :selected-month="selectedMonth"
      @clockingAdded="(data) => updateClockingData(data)"
      :day="selectedModalDate"
      @input="
        (val) => {
          if (!val) selectedModalDate = null;
        }
      "
    />

    <span
      id="add-clocking"
      class="d-none"
      @click="openAddClockingDialog"
    ></span>
    <span id="print-clocking" class="d-none" @click="printReport"></span>
    <span
      id="add-new-clocking-for-day"
      @click="addNewClockingOthers(selectedDay)"
    ></span>
    <span
      id="add-employee-counter"
      @click="
        () => {
          showEmployeeCounterForm = false;
          selectedEmployeeCounter = null;
          $nextTick(() => (showEmployeeCounterForm = true));
        }
      "
    >
      <EmployeeCounterForm
        v-model="showEmployeeCounterForm"
        :employee="selectedEmployee"
        :employeeCounter="selectedEmployeeCounter"
    /></span>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      :url="computeClockingQueryParameters('employees/clocking/admin')"
      @buttonClicked="confirmationButtonClicked"
    />

    <employee-clocking-for-day
      v-if="selectedEmployee"
      v-model="showClockingForDayModal"
      :date="selectedClockingForDayDate"
      :employee_contract_id="selectedEmployee.active_contract.id"
      @deleted="
        () => getClockingData(selectedEmployee.active_contract.id, false)
      "
    />
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL, CLOCKING_COLORS } from "@/config";
import moment from "moment";
import AddClocking from "@/components/Clocking/AddClocking.vue";
import clockingMixin from "@/mixins/clockingMixin";
import ConfirmationModel from "@/components/ConfirmationModel.vue";
import EmployeeClockingForDay from "@/components/Clocking/EmployeeClockingForDay.vue";
import ClockingInput from "@/components/Clocking/ClockingInput.vue";
import EmployeeCounterForm from "@/components/Clocking/EmployeeCounterForm.vue";
import EmployeeCounterTable from "@/components/Clocking/EmployeeCounterTable.vue";
import ClockingTableListing from "@/views/Clocking/TableListing.vue";

export default {
  name: "ClockingListing",
  components: {
    ClockingTableListing,
    EmployeeCounterForm,
    ClockingInput,
    AddClocking,
    ConfirmationModel,
    EmployeeClockingForDay,
    EmployeeCounterTable,
  },
  mixins: [clockingMixin],
  data() {
    return {
      showCounterTable: true,
      editClockingModel: {
        check_in_time: {
          work: {},
          break: {},
          mission: {},
          others: {},
        },
        check_out_time: {
          work: {},
          break: {},
          mission: {},
          others: {},
        },
      },
      showEmployeeCounterForm: false,
      tableKey: 1,
      addClockingDialog: false,
      clockingData: [],
      employees: [],
      selectedFilter: null,
      selectedDay: null,
      dayPickerMenu: false,
      selectedWeek: null,
      weekPickerMenu: false,
      selectedMonth: null,
      monthPickerMenu: false,
      fromDatePickerMenu: false,
      toDatePickerMenu: false,
      selectedFromDate: null,
      selectedToDate: null,
      showBreakColumns: true,
      showMissionColumns: true,
      showOtherColumns: false,
      allowTimeEdit: true,
      automaticallyDeductBreak: false,
      selectedEmployee: null,
      idOfClockingToEdit: null,
      valueOfClockingToEdit: null,
      targetOfClockingToEdit: null,
      actionOfClockingToEdit: null,
      typeOfClockingToEdit: null,
      fromDate: null,
      toDate: null,
      selectedModalDate: null,
      searching: false,
      timerId: null,
      lastHoveredClockingId: null,
      openInNewTabId: null,
      allowUpdate: true,
      filterOptions: [
        {
          title: this.translate("filter_options.day"),
          value: "day",
        },
        {
          title: this.translate("filter_options.week"),
          value: "week",
        },
        {
          title: this.translate("filter_options.month"),
          value: "month",
        },
        {
          title: this.translate("filter_options.custom"),
          value: "custom",
        },
      ],
      axiosOptions: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogOperationData: null,
      showClockingForDayModal: false,
      selectedClockingForDayDate: null,
      selectedEmployeeCounter: null,
      gridView: true,
      clockingTypes: [],
      selectedType: null,
    };
  },
  async mounted() {
    this.configureClocking();
    this.employees = await this.getEmployees();
    this.selectedEmployee = this.formattedEmployees[0];

    this.selectedFilter = this.filterOptions.find(
      (f) => f.value === "month"
    ).value;
    // set current month as selected month
    this.selectedMonth = moment().format("YYYY-MM");
    this.clockingTypes = (await this.getCodes("clocking_types")).filter(
      (i) => i.value !== "deleted"
    );
  },
  computed: {
    selectedClockingData() {
      return this.clockingData[0] ?? null;
    },
    overAllTotal() {
      if (!this.selectedClockingData) {
        return 0;
      }

      return Object.values(this.selectedClockingData.summary).reduce(
        (total, item) => total + item.total,
        0
      );
    },
    selectedFilterIsMonth() {
      return this.selectedFilter === "month";
    },
    selectedFilterIsDay() {
      return this.selectedFilter === "day";
    },
    selectedFilterIsWeek() {
      return this.selectedFilter === "week";
    },
    selectedFilterIsCustom() {
      return this.selectedFilter === "custom";
    },
    watchFilters() {
      return [
        this.selectedDay,
        this.selectedWeek,
        this.selectedMonth,
        this.selectedFromDate,
        this.selectedToDate,
      ];
    },
    formattedSelectedMonth() {
      return moment(this.selectedMonth)
        .locale(this.$store.state.languageId)
        .format("MMMM YYYY");
    },
    formattedFromDate() {
      return moment(this.selectedMonth).startOf("month").format("YYYY-MM-DD");
    },
    formattedToDate() {
      return moment(this.selectedMonth).endOf("month").format("YYYY-MM-DD");
    },
    clockingColor() {
      return CLOCKING_COLORS;
    },
    processedTypes() {
      return this.clockingTypes
        .map((item) => {
          item.text = this.translate("types." + item.key);
          return item;
        })
        .sort((a, b) => a.key.localeCompare(b.key));
    },
  },
  watch: {
    async gridView(val) {
      if (val) {
        await this.getClockingData();
        this.$store.state.forceReloadComponentKey++;
      }
    },
    selectedEmployee(val) {
      if (!val) return;

      this.getClockingData(val.active_contract.id);
    },
    watchFilters() {
      if (!this.selectedEmployee) return;

      this.getClockingData(this.selectedEmployee.active_contract.id);
    },
    selectedFromDate(val) {
      if (!this.selectedToDate || !val) return false;

      let fromDate = moment(val);
      let toDate = moment(this.selectedToDate);

      if (fromDate.isAfter(toDate)) {
        this.selectedToDate = null;
      }
    },
  },
  methods: {
    clockingIsForFirstColumn(clocking) {
      return !["break", "mission"].includes(clocking.type.key);
    },
    isFirstColumn(index) {
      return index === 0;
    },
    isLastItem(day, item) {
      let clocking = this.selectedClockingData.clocking[day].filter(
        (i) => !["break", "mission"].includes(i.type.key)
      );
      return clocking[clocking.length - 1]?.id === item.id;
    },
    isMidItem(day, item) {
      let clocking = this.selectedClockingData.clocking[day].filter(
        (i) => !["break", "mission"].includes(i.type.key)
      );
      return (
        clocking[clocking.length - 1].id !== item.id &&
        clocking[0].id !== item.id
      );
    },
    toggleEmployeeClockingForDayModal(date) {
      this.selectedClockingForDayDate = moment(date, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );

      this.showClockingForDayModal = false;
      this.selectedDay = date;
      setTimeout(() => (this.showClockingForDayModal = true), 10);
    },
    addNewClockingOthers(day) {
      this.selectedModalDate = moment(day, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.openAddClockingDialog();
    },
    translate(key) {
      return this.$t("clocking_section." + key);
    },
    getClockingData(
      employeeContractId = null,
      reloadData = false,
      clocking = null
    ) {
      employeeContractId =
        employeeContractId || this.selectedEmployee.active_contract.id;
      let url = this.computeClockingQueryParameters(
        "employees/clocking",
        employeeContractId
      );
      if (reloadData) {
        this.clockingData = [];
      }

      axios
        .get(url, { headers: this.axiosOptions })
        .finally(() => {})
        .then((response) => {
          this.clockingData = Object.values(response.data);
          if (clocking) {
            let checkoutInputId = this.generateCheckoutId(clocking);

            setTimeout(() => {
              let checkoutInput = document.getElementById(checkoutInputId);

              if (checkoutInput) checkoutInput.focus();
            }, 500);
          }
        })
        .catch(() => {});
    },
    hydrateClockingData(payload) {
      // get last focused element
      let lastFocusedElement = document.activeElement;
      let day = payload.day;
      let clocking = payload.clocking;
      let summary = payload.summary;
      let newClocking = payload.new_clocking;
      this.clockingData[0].clocking[day] = clocking[day];
      this.clockingData[0].summary[day] = summary[day];

      if (payload.action === "update") {
        return lastFocusedElement.focus();
      }

      let newElementId = this.generateId(newClocking, "check_out");

      this.$nextTick(() => {
        let newElement = document.getElementById(newElementId);
        if (newElement) newElement.focus();
      });
    },
    generateId(clocking, target = null) {
      return "clocking_" + clocking?.id + "_" + (target ?? this.target);
    },
    computeClockingQueryParameters(
      baseUrl = "employees/clocking",
      employeeContractId
    ) {
      let url = API_BASE_URL + "/" + baseUrl + "?a=a";

      if (employeeContractId) {
        url += "&employee_contract_id=" + employeeContractId;
      }

      if (this.selectedFilter === "month") {
        if (!this.selectedMonth) {
          return;
        }

        // Using moment get start date and end date of the month
        let fromDate = moment(this.selectedMonth)
          .startOf("month")
          .format("YYYY-MM-DD");
        let toDate = moment(this.selectedMonth)
          .endOf("month")
          .format("YYYY-MM-DD");

        this.fromDate = fromDate;
        this.toDate = toDate;

        url += "&from_date=" + fromDate + "&to_date=" + toDate;
      }

      if (this.selectedFilter === "day") {
        if (!this.selectedDay) {
          return false;
        }

        url += "&day=" + this.selectedDay;

        this.fromDate = this.selectedDay;
        this.toDate = this.selectedDay;
      }

      if (this.selectedFilter === "week") {
        if (!this.selectedWeek) {
          return false;
        }

        // Using moment get start date and end date of the week
        let fromDate = moment(this.selectedWeek)
          .startOf("week")
          .format("YYYY-MM-DD");
        let toDate = moment(this.selectedWeek)
          .endOf("week")
          .format("YYYY-MM-DD");

        this.fromDate = fromDate;
        this.toDate = toDate;

        url += "&from_date=" + fromDate + "&to_date=" + toDate;
      }

      if (this.selectedFilter === "custom") {
        if (!this.selectedFromDate || !this.selectedToDate) {
          return;
        }

        // Using moment get start date and end date of the week
        let fromDate = moment(this.selectedFromDate).format("YYYY-MM-DD");
        let toDate = moment(this.selectedToDate).format("YYYY-MM-DD");

        this.fromDate = fromDate;
        this.toDate = toDate;

        url += "&from_date=" + fromDate + "&to_date=" + toDate;
      }

      return url;
    },
    extractClockingByType(clocking, type, placeHolderOnly = false) {
      if (placeHolderOnly) {
        return clocking.filter((i) => i.check_in_time === i.check_out_time);
      }

      if (Array.isArray(type)) {
        return clocking.filter(
          (i) =>
            type.includes(i.type.key) && !(i.check_in_time === i.check_out_time)
        );
      }

      return clocking.filter((i) => {
        let checkInAndOutTimeIsTheSame = i.check_in_time === i.check_out_time;

        if (checkInAndOutTimeIsTheSame && type !== "master") {
          return false;
        }

        if (type === "master") {
          if (
            clocking.every((item) =>
              ["break", "mission"].includes(item.type.key)
            ) &&
            clocking[0]?.id === i.id
          ) {
            return true;
          }

          return !["break", "mission"].includes(i.type.key);
        }

        return type === "others"
          ? ![
              "work",
              "mission",
              "break",
              "sick_leave",
              "vacation",
              "public_holiday",
            ].includes(i.type.key)
          : i.type.key === type;
      });
    },
    computeColSpan(clocking, day) {
      let whiteList = ["work", "break", "mission"];
      if (whiteList.includes(clocking?.type?.key)) return 1;

      let expected = this.selectedClockingData.summary[day].expected;

      if (expected === clocking.time_difference) {
        return 4;
      }

      return 1;
    },
    calculateOverall(target) {
      if (!this.selectedClockingData) {
        return 0;
      }

      return Object.values(this.selectedClockingData.summary).reduce(
        (total, item) => total + item[target],
        0
      );
    },
    computeWorkBgColor(clocking, summary) {
      if (summary.expected === 0) return undefined;

      if (
        summary.expected > 0 &&
        clocking.is_placeholder === true &&
        clocking.type.key === "work"
      )
        return this.clockingColor.absent;

      let type = clocking.type.key;
      if (
        ["vacation", "sick_leave", "absent", "public_holiday", "work"].includes(
          type
        )
      ) {
        return this.clockingColor[type];
      } else if (
        clocking.check_in_time === clocking.check_out_time ||
        !clocking.check_out_time
      ) {
        return this.clockingColor.default;
      } else {
        return this.clockingColor.work;
      }
    },
    computeRowBgColor(expected) {
      return expected === 0 ? this.clockingColor.no_work : undefined;
    },
    computeWorkText(clocking, column = 1) {
      let type = clocking.type.key;
      if (!["work", "break", "mission"].includes(type)) {
        if (column === 3) {
          let val = this.convertMinutesToHours(clocking.time_difference);
          return val === "00:00" ? "--:--" : val;
        }

        if (column === 2) {
          //use moment to parse time to AM/PM Format like 4PM or 4:30PM
          let start = moment(clocking.check_in_time_only, "HH:mm").format(
            "h:mmA"
          );
          let end = moment(clocking.check_out_time_only, "HH:mm").format(
            "h:mmA"
          );

          return start + " - " + end;
        }

        let text = column === 1 ? this.translate("types." + type) : "--:--";

        if (text.length > 7) {
          text = text.substring(0, 4) + "...";
        }
        return text;
      } else {
        if (column === 3) {
          let val = this.convertMinutesToHours(clocking.time_difference);
          return val === "00:00" ? "--:--" : val;
        }

        let data =
          clocking[column === 1 ? "check_in_time_only" : "check_out_time_only"];

        if (!this.isset(data)) {
          return "--:--";
        }

        if (column === 2) {
          // use moment to parse time to AM/PM Format
          return moment(data, "HH:mm").format("hh:mm A");
        }

        return data;
      }
    },
    openAddClockingDialog() {
      this.addClockingDialog = false;

      setTimeout(() => {
        if (!this.confirmationDialog) this.addClockingDialog = true;
      }, 30);
      setTimeout(() => {
        this.selectedModalDate = null;
      }, 500);
    },

    borderBottomColor(day, clocking = null) {
      if (clocking === null) {
        return "";
      }

      if (!this.isLastItem(day, clocking)) {
        return "1px solid #ffffff";
      }

      let isTodaySunday = moment(day, "DD-MM-YYYY").day() === 0;
      return isTodaySunday ? "2px solid grey" : "";
    },
    formatDay(day) {
      // output format "Monday Jan 30, 2024, take locale into Consideration
      return moment(day, "DD-MM-YYYY")
        .locale(this.$store.state.languageId)
        .format("ddd DD");
    },
    revertEditClockingDataToDefault() {
      this.idOfClockingToEdit = null;
      this.valueOfClockingToEdit = null;
      this.targetOfClockingToEdit = null;
      this.typeOfClockingToEdit = null;
      this.actionOfClockingToEdit = null;
    },
    updateClockingHandler(
      clocking,
      target,
      day,
      revertOnly = false,
      action = "update",
      clockingType = null
    ) {
      if (revertOnly) {
        return this.revertEditClockingDataToDefault();
      }

      if (!this.allowUpdate) return;

      let newTime =
        clocking?.id !== undefined
          ? (newTime = this.editClockingModel[target][clocking.id])
          : (newTime = this.editClockingModel[target][clockingType][clocking]);

      // check if new time is not same as old time
      if (clocking[target + "_only"] === newTime) {
        return this.revertEditClockingDataToDefault();
      }

      let payload = {
        new_time: newTime,
        target: target.replace("_time", ""),
        action: action,
        type: clockingType,
      };

      if (action === "create") {
        payload.employee_contract_id = this.selectedEmployee.active_contract.id;
        payload.date = day;
      }

      if (this.$store.state.progressBarLoading) {
        return;
      }

      let url = "employees/clocking/" + (clocking?.id || clocking);
      url = this.computeClockingQueryParameters(
        url,
        this.selectedEmployee.active_contract.id
      );
      this.allowUpdate = false;
      axios
        .put(url, payload, { headers: this.axiosOptions })
        .finally(() => {})
        .then((response) => {
          this.revertEditClockingDataToDefault();
          this.clockingData = Object.values(response.data);
          // if check in time is edited or added, focus corresponding check out time
          let clockingIsNew =
            typeof clocking === "string" ||
            this.isset(clocking?.is_placeholder);

          if (target === "check_in_time" && clockingIsNew) {
            setTimeout(() => {
              let clockingForDay = this.selectedClockingData.clocking[day];
              let lastClocking = clockingForDay[clockingForDay.length - 1];
              document
                .getElementById("check_out_time_edit_time_" + lastClocking.id)
                .focus();
              this.allowUpdate = true;
            }, 10);
          } else {
            setTimeout(() => {
              document
                .getElementById("check_out_time_edit_time_" + clocking.id)
                .focus();
              this.allowUpdate = true;
            }, 100);
          }
        })
        .catch(() => {
          this.allowUpdate = true;
        });
    },
    configureClocking() {
      this.showBreakColumns = !(
        parseInt(this.getCompanyPropertyValue("CLOCKING_HIDE_BREAK")) === 1
      );
      this.showMissionColumns = !(
        parseInt(this.getCompanyPropertyValue("CLOCKING_HIDE_MISSION")) === 1
      );
      // this.showOtherColumns = !(parseInt(this.getCompanyPropertyValue('CLOCKING_HIDE_OTHERS')) === 1);
      this.allowTimeEdit =
        parseInt(
          this.getCompanyPropertyValue("CLOCKING_ALLOW_TIME_MODIFICATION")
        ) === 1;
      this.automaticallyDeductBreak =
        parseInt(
          this.getCompanyPropertyValue("CLOCKING_AUTOMATICALLY_DEDUCT_BREAK")
        ) === 1;
    },
    printReport() {
      let url = `${API_BASE_URL}/employees/clocking/print?employee_contract_id=${this.selectedEmployee.active_contract.id}&lang=${this.$store.state.languageId}&from_date=${this.fromDate}&to_date=${this.toDate}`;
      this.setPreviewData(
        url,
        this.selectedEmployee.name +
          "_" +
          moment(this.selectedMonth).format("MMYYYY"),
        true,
        "application/pdf"
      );
    },
    async searchEmployees(search) {
      if (this.formattedEmployees.find((e) => e.text === search)) {
        return;
      }
      // cancel pending call
      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(async () => {
        this.searching = true;
        this.employees = await this.getEmployees(search);
        this.searching = false;
      }, 500);
    },
    confirmDeleteAction(clocking, day) {
      this.confirmationDialog = false;

      setTimeout(() => {
        this.confirmationDialog = true;
        this.confirmationDialogConfirmationText = this.translate(
          "delete_clocking_confirmation_text"
        );
        this.confirmationDialogFalseText = this.$t("no");
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogOperation = "deleteClocking";
        this.confirmationDialogOperationData = {
          clocking,
          day,
        };
      }, 20);
    },
    confirmationButtonClicked(action) {
      if (!action) return;

      if (this.confirmationDialogOperation === "deleteClocking") {
        this.deleteClocking(
          this.confirmationDialogOperationData.clocking,
          this.confirmationDialogOperationData.day
        );
      }
    },
    deleteClocking(clocking) {
      return new Promise((resolve) => {
        let url =
          API_BASE_URL +
          "/employees/clocking/" +
          clocking.id +
          "?employee_contract_id=" +
          this.selectedEmployee.active_contract.id;
        axios
          .delete(url, { headers: this.axiosOptions })
          .finally(() => {})
          .then(() => {
            this.$emit("updated");
            this.getClockingData(null, false, clocking);
            this.$store.state.forceReloadComponentKey++;
            resolve();
          });
      });
    },
    updateClockingData(payload) {
      this.clockingData = Object.values(payload);
      this.$store.state.forceReloadComponentKey++;
    },
    generateCheckoutId(clocking) {
      return "clocking_" + clocking.id + "_check_out";
    },
    workHasBeenLoggedForDay(day) {
      let clocking = this.selectedClockingData["clocking"][day] ?? null;
      if (!clocking) return false;

      return this.isset(
        clocking.filter(
          (i) => i.type.key === "work" && i.is_placeholder == false
        )
      );
    },
  },
};
</script>
<style scoped>
.edit-time {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
}

td {
  border: 1px solid lightgrey;
  font-size: 0.8rem !important;
}

input[type="time"] {
  border: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

p {
  vertical-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background: #f5f5f5;
  opacity: 0.6;
}
</style>
