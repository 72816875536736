<template>
    <div>
        <v-dialog v-model="showDialog" @click:outside="closeOrderDetails()" @keydown.esc="closeOrderDetails()"
            persistent max-width="1024px">
            <v-card v-if="rowData">
                <v-card-title>
                    <span class="text-h5">{{ $t("stocks.order") }} - {{ supplierName }}
                        <span style="color: red">{{
                            isSupplierActiveConnection
                                ? ""
                                : " - " + $t("stocks.ordering_from_outside")
                            }}</span>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mb-n8">
                            <v-col cols="12" sm="10">
                                <p class="orderTitle">
                                    {{ rowData.description }}
                                </p>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" sm="2">
                                <v-btn :loading="stockLoading" :disabled="stockLoading || !form.supplier_id"
                                    color="primary" @click="getStockAvailability()">
                                    {{ $t("stocks.refresh") }}
                                    <template v-slot:loader>
                                        <v-progress-circular indeterminate color="grey" :size="15"
                                            :width="1"></v-progress-circular>
                                        <span class="ml-2"> {{ $t("stocks.fetching") }}</span>
                                    </template>
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2">
                            <v-col>
                                <p class="orderSubTitle">
                                    <TextComponentWithExtraIcons :value="rowData.identifier">
                                    </TextComponentWithExtraIcons>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2">
                            <v-col align="right" style="color: red">
                                <template v-if="form && form.quantity_to_order">
                                    {{
                                        parseInt(form.quantity_to_order) >
                                            parseInt(this.rowData[`${this.columnData.field_stock}`])
                                            ? $t("stocks.quantity_to_order_is_greater_then_available")
                                    : errorMessage
                                    }}
                                </template>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-layout justify-center align-center>
                                    <v-flex v-if="!imageError" shrink>
                                        <v-img style="max-height: 350px; max-width: 350px"
                                            v-if="rowData && rowData.identifier"
                                            :lazy-src="articleImageBaseUrl + rowData.identifier"
                                            :src="articleImageBaseUrl + rowData.identifier" v-on:error="onImgError">
                                            <template v-slot:placeholder>
                                                <v-row class="fill-height ma-0" align="center" justify="center">
                                                    <v-progress-circular indeterminate
                                                        color="grey lighten-5"></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-flex>
                                    <v-flex v-if="imageError" shrink>
                                        <v-img style="max-height: 350px; max-width: 350px" v-if="rowData.identifier"
                                            lazy-src="@/assets/images/no-image.png"
                                            src="@/assets/images/no-image.png"></v-img>
                                    </v-flex>
                                </v-layout>
                            </v-col>
                            <!-- Second (Supplier & Ordered for) Column -->
                            <v-col>
                                <v-autocomplete v-if="
                                    selectedFamily &&
                                    typeof selectedFamily == 'object' &&
                                    cachedData['suppliers'][selectedFamily.id]
                                " v-model="form.supplier_id" :items="cachedSuppliers" item-value="id" item-text="name"
                                    class="supplierTextBox" :label="$t('scheduler.supplier')" outlined dense
                                    :return-object="false" hide-details="auto">
                                    <template slot="item" slot-scope="{ item }">
                                        <v-list-item class="pa-0 ma-0 pl-2" @click="form.supplier_id = item.id" :class="setSupplierRowColorClass(
                                            item.companySupplier.length > 0
                                                ? item.companySupplier[0]
                                                : null
                                        )
                                            ">
                                            {{ item.name }}
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                                <v-autocomplete v-model="form.client" class="mr-2 mt-4 to-upper" :items="clients"
                                    :loading="isLoading" :search-input.sync="search" item-text="name" item-value="id"
                                    :label="$t('stocks.client_or_internal')"
                                    placeholder="Search Client - Registration No - VIN" @input="debounceInput"
                                    return-object no-filter :outlined="is_boxes" :dense="is_boxes" hide-no-data>
                                </v-autocomplete>
                                <v-row class="order-detail-text">
                                    <v-col>
                                        <v-row v-if="form.client">
                                            <v-col sm="5">{{ $t("stocks.label_ordered_for") }}
                                            </v-col>
                                            <v-col class="order-detail-text-right">{{ form.client.name }}
                                                {{
                                                    form.client.first_name ? form.client.first_name : ""
                                                }}</v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="5">{{ $t("stocks.label_stocks") }} </v-col>
                                            <v-col class="order-detail-text-right">{{ rowData.stock }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="5">{{ $t("stocks.label_reserve") }} </v-col>
                                            <v-col class="order-detail-text-right">{{ rowData.reserve }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="5">{{ $t("stocks.label_dispo") }} </v-col>
                                            <v-col class="order-detail-text-right">{{ form ? form.dispo : "" }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="5">{{ $t("stocks.label_new_dispo") }} </v-col>
                                            <v-col class="order-detail-text-right">{{
                                                form
                                                    ? isNaN(form.new_dispo)
                                                        ? 0
                                                : form.new_dispo
                                                : ""
                                                }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="7">{{ $t("stocks.label_current_stock_price") }}
                                            </v-col>
                                            <v-col class="order-detail-text-right">{{
                                                form
                                                    ? fixDecimal(
                                                        isNaN(form.current_stock_price)
                                                            ? 0
                                                            : form.current_stock_price
                                                )
                                                : ""
                                                }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="7">{{ $t("stocks.label_new_stock_price") }}
                                            </v-col>
                                            <v-col class="order-detail-text-right">{{
                                                form
                                                    ? fixDecimal(
                                                        isNaN(form.new_stock_price)
                                                            ? 0
                                                            : form.new_stock_price
                                                )
                                                : ""
                                                }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Third (Quantity to Order, Acq. Price) Column -->
                            <v-col>
                                <v-row>
                                    <v-col class="order-detail-text">
                                        <!-- Delivery Date -->
                                        <v-row>
                                            <v-col sm="6">
                                                {{ $t("stocks.delivery_date") }}
                                            </v-col>
                                            <v-col class="order-detail-text-right">
                                                {{
                                                    rowData[`${columnData.field_delivery_date}`]
                                                        ? formatDate(
                                                            rowData[`${columnData.field_delivery_date}`]
                                                )
                                                : ""
                                                }}
                                            </v-col>
                                        </v-row>
                                        <!-- Supplier Disponability -->
                                        <v-row>
                                            <v-col sm="10">
                                                {{ $t("stocks.supplier_disponability") }}
                                            </v-col>
                                            <v-col sm="2" style="text-align: end">
                                                {{ form.supplier_stock }}
                                            </v-col>
                                        </v-row>
                                        <!-- Quantity to order -->
                                        <v-row>
                                            <v-col sm="10">
                                                {{ $t("stocks.quantity_to_order") }}
                                            </v-col>
                                            <v-text-field v-if="form" :autocomplete="autocompleteValueLocal"
                                                style="width: 40px" v-model="form.quantity_to_order"
                                                class="pt-0 pr-4 right-aligned-input to-upper" hide-details="auto"
                                                align="right" v-on:keydown="handleInput($event, 'int', 3)"
                                                :outlined="is_boxes" :dense="is_boxes"></v-text-field>
                                        </v-row>
                                        <!-- Confirmed Quantity -->
                                        <v-row v-if="!isSupplierActiveConnection">
                                            <v-col cols="12" sm="10">
                                                {{ $t("receptions.confirmed_quant_label") }}
                                            </v-col>
                                            <v-col class="order-detail-text-right pl-0 pr-0" cols="12" sm="2">
                                                <v-text-field v-if="form" :autocomplete="autocompleteValueLocal"
                                                    style="width: 100px" v-model="form.confirmed_quantity"
                                                    class="pt-0 pr-4 right-aligned-input to-upper" hide-details="auto"
                                                    align="right" v-on:keydown="handleInput($event, 'int', 3)"
                                                    :outlined="is_boxes" :dense="is_boxes"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!-- Acquisition Price -->
                                        <v-row>
                                            <v-col sm="7" class="mb-0">
                                                {{ $t("scheduler.acquisition_price") }}
                                            </v-col>
                                            <v-col class="order-detail-text-right mb-0">
                                                <custom-money-input :autocomplete="autocompleteValueLocal"
                                                    class="to-upper right-aligned-input shrink" v-model="form.acq_price"
                                                    :required="true" v-on:keydown="handleInput($event, 'int', 20)"
                                                    v-bind:properties="{
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        placeholder: '0.00',
                                                        dense: true,
                                                        hideDetails: false,
                                                    }" v-bind:options="{
                            locale: userLanguageId,
                            length: 10,
                            precision: 2,
                            empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                        }" />
                                            </v-col>
                                        </v-row>

                                        <!-- Client Price -->
                                        <v-row>
                                            <v-col sm="7" class="pt-0">
                                                {{ $t("receptions.price") }}
                                            </v-col>
                                            <v-col class="order-detail-text-right pt-0">
                                                <custom-money-input :autocomplete="autocompleteValueLocal"
                                                    class="to-upper right-aligned-input shrink" v-model="form.price"
                                                    :required="true" v-on:keydown="handleInput($event, 'int', 20)"
                                                    v-bind:properties="{
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        placeholder: '0.00',
                                                        dense: true,
                                                        hideDetails: false,
                                                    }" v-bind:options="{
                            locale: userLanguageId,
                            length: 10,
                            precision: 2,
                            empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                        }" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <div id="divider" style="
                    background-color: #441468;
                    height: 2px;
                    width: 100%;
                    margin: 5px 0px 5px 0px;
                  "></div>
                                <v-row>
                                    <v-col sm="8" class="order-detail-text">
                                        <strong> {{ $t("stocks.total") }} <br /> </strong>
                                        <strong>
                                            {{ $t("stocks.totalwva") }} ({{ form ? form.tva : "" }}%):
                                            <br />
                                        </strong>
                                    </v-col>
                                    <v-col class="order-detail-text-right">
                                        <strong>
                                            {{
                                                form && !checkingArticleTaxData ? form.total_price : ""
                                            }}
                                        </strong>
                                        <br />
                                        <strong>
                                            {{
                                                form && !checkingArticleTaxData
                                                    ? form.price_with_tva
                                            : ""
                                            }}
                                        </strong>
                                        <br />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-if="form" :autocomplete="autocompleteValueLocal"
                                            :outlined="is_boxes" :dense="is_boxes" class="mr-3 to-upper"
                                            :label="$t('stocks.order_reference')" v-model="form.order_reference">
                                        </v-text-field>
                                        <v-textarea v-if="form" :autocomplete="autocompleteValueLocal"
                                            :outlined="is_boxes" :dense="is_boxes" class="to-upper" rows="2"
                                            :label="$t('stocks.message_for_order')"
                                            v-model="form.message_for_order"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="px-2">
                    <v-spacer></v-spacer>
                    <v-btn :disabled="ordering" color="grey lighten-2" @click="closeOrderDetails()">
                        {{ $t("stocks.cancel") }}
                        <v-icon dark right> mdi-keyboard-return </v-icon>
                    </v-btn>
                    <v-btn v-if="form" :loading="ordering" color="success" @click="submitOrderDetails()" :disabled="ordering ||
                        !form.supplier_id ||
                        isNaN(parseInt(form.quantity_to_order)) ||
                        parseInt(form.quantity_to_order) == 0 ||
                        parseInt(form.quantity_to_order) >
                        parseInt(this.rowData[`${this.columnData.field_stock}`])
                        ">
                        {{ $t("stocks.order") }}
                        <v-icon dark right> mdi-content-save </v-icon>
                        <template v-slot:loader>
                            <v-progress-circular indeterminate color="grey" :size="15" :width="1"></v-progress-circular>
                            <!-- <template class="ma-2"> {{ $t('stocks.fetching') }}</template> -->
                        </template>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <confirmation-model v-if="confirmationDialog" :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation" :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText" :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"></confirmation-model>
    </div>
</template>

<style scoped>
.right-aligned-input>>>input {
    text-align: right;
}

.v-alert {
    z-index: 99;
}

.v-alert-positation-wrapper .v-alert-positation {
    top: 1em;
}

.supplierTextBox {
    /* background-color: rgb(242 255 242); */
}

.supplierTextBox .v-input__slot {
    /* background-color: rgb(242 255 242); */
}
</style>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";

export default {
    name: "StockOrderDetails",
    components: {
        ConfirmationModel,
        "custom-money-input": CutsomMoneyInput,
    },
    props: {
        rowData: {
            type: Object,
        },
        columnData: {
            type: Object,
        },
        brands: {
            type: Array,
        },
        showDialog: {
            type: Boolean,
        },
        selectedFamily: {
            type: Object,
        },
    },
    data() {
        return {
            imageError: false,
            articleImageBaseUrl: API_BASE_URL + "/article/image/",
            is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
            form: {
                client: null,
            },
            loader: null,
            stockLoading: false,
            ordering: false,
            search: null,
            companyArticleDetails: {},
            alerts: [],
            clients: [],
            isLoading: false,
            brand: null,
            internal_stock: {
                id: 0,
                name: "INTERNAL STOCK",
                registration_number: null,
                vin: null,
            },
            autocompleteValueLocal: "off",
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            errorMessage: null,
            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            lastSupplierOrderCreated: null,

            selectedFamilyArticleSuppliers: [],
            companyDefaultTva: null,
            newStockPrice: null,
            checkingArticleTaxData: false,
        };
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.message_for_order = "";
        this.order_reference = "";
        this.getCompanyDefaultTva();
    },
    methods: {
        getBrandById(brand_id) {
            let foundBrand = null;
            this.brands.forEach((brand) => {
                if (brand.id == brand_id) {
                    foundBrand = brand;
                }
            });
            return foundBrand;
        },
        getSupplierById(supplier_id) {
            let foundSupplier = null;
            if (supplier_id && this.selectedFamily) {
                (this.cachedData["suppliers"][this.selectedFamily.id] ?? []).forEach(
                    (supplier) => {
                        if (supplier.id == supplier_id) {
                            foundSupplier = supplier;
                        }
                    }
                );
            }
            return foundSupplier;
        },
        getSupplierAsBrand(brand) {
            let foundSupplier = null;
            (this.cachedData["suppliers"][this.selectedFamily.id] ?? []).forEach(
                (supplier) => {
                    if (supplier.name.toUpperCase() == brand.name.toUpperCase()) {
                        foundSupplier = supplier;
                    }
                }
            );
            return foundSupplier;
        },
        getCompanyDefaultTva() {
            return new Promise((resolve, reject) => {
                axios
                    .get(API_BASE_URL + "/tvas?by_auth=1", {
                        headers: this.header,
                    })
                    .then(({ data }) => {
                        this.companyDefaultTva = data;
                        resolve();
                    })
                    .catch(function () {
                        reject();
                    });
            });
        },
        getSupplierOrBrandName() {
            if (this.columnData && this.columnData.supplier_detail) {
                if (this.columnData.supplier_detail.id != 0) {
                    return this.columnData.text;
                } else {
                    let brandName = "";
                    this.brands.forEach((brand) => {
                        if (brand.id == this.rowData.brand_id) {
                            brandName = brand.name;
                        }
                    });
                    return brandName;
                }
            }
        },
        onImgError() {
            this.imageError = true;
        },
        debounceInput: _.debounce(function (val) {
            this.getClients(val);
        }, 500),
        retryPopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "stocks.retry_order_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("stocks.cancel");
            this.confirmationDialogTrueText = this.$t("stocks.keep_trying");
            this.confirmationDialogOperation = "keep_trying";
            this.confirmationDialog = true;
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        getFamilyArticleSuppliers() {
            if (this.selectedFamily && this.selectedFamily.id) {
                if (
                    this.cachedData["suppliers"] &&
                    this.cachedData["suppliers"][this.selectedFamily.id]
                ) {
                    this.selectedFamilyArticleSuppliers =
                        this.cachedData["suppliers"][this.selectedFamily.id];
                } else {
                    axios
                        .get(
                            API_BASE_URL +
                            "/suppliers?family_id=" +
                            this.selectedFamily.id +
                            "&parent_also=true&items_per_page=-1&all_suppliers=true",
                            { headers: this.header }
                        )
                        .then(({ data }) => {
                            this.selectedFamilyArticleSuppliers = data.data;
                            if (!this.cachedData["suppliers"]) {
                                this.cachedData["suppliers"] = {};
                            }
                            this.cachedData["suppliers"][this.selectedFamily.id] =
                                this.selectedFamilyArticleSuppliers;
                        })
                        .catch(function (error) {
                            console.log("an error occured " + error);
                        })
                        .finally(() => { });
                }
            }
        },
        getCompanyArticleDeatails() {
            return new Promise((resolve, reject) => {
                if (
                    this.rowData &&
                    this.rowData.article_id &&
                    this.columnData &&
                    this.columnData.supplier_detail &&
                    this.columnData.supplier_detail.id
                ) {
                    this.checkingArticleTaxData = true;
                    if (
                        this.columnData &&
                        this.columnData.supplier_detail &&
                        this.columnData.supplier_detail &&
                        this.columnData.supplier_detail.id
                    ) {
                        axios
                            .get(
                                API_BASE_URL +
                                "/company_article/" +
                                this.rowData.article_id +
                                "?supplier_id=" +
                                this.columnData.supplier_detail.id,
                                { headers: this.header }
                            )
                            .then(({ data }) => {
                                if (data && data.data) {
                                    this.companyArticleDetails = data;
                                    this.form.tva = data.tva.tax;
                                } else {
                                    this.form.tva = this.companyDefaultTva.tax;
                                }
                                resolve();
                            })
                            .catch(function (error) {
                                console.log("an error occured " + error);
                                reject();
                            })
                            .finally(() => {
                                this.checkingArticleTaxData = false;
                            });
                    }
                } else {
                    resolve();
                }
            });
        },
        closeOrderDetails: function () {
            this.showDialog = false;
            this.$emit("changeDialog", false);
        },
        getClients: function (searchString) {
            if (!searchString) return;
            if (typeof searchString == "object") return;

            this.isLoading = true;
            axios
                .get(
                    API_BASE_URL +
                    "/clients/search?searchRegNumber=true&search=" +
                    encodeURIComponent(searchString),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    let customData = [];
                    if (data.data) {
                        data.data.forEach((element) => {
                            customData.push({
                                id: element.id,
                                name:
                                    element.name +
                                    " " +
                                    (element.first_name ?? "") +
                                    (element.registration_number
                                        ? ", Reg. No : " + element.registration_number
                                        : "") +
                                    (element.vin ? ", VIN : " + element.vin : ""),
                                registration_number: element.registration_number,
                                vin: element.vin,
                            });
                        });
                    }
                    customData.push(this.internal_stock);
                    this.clients = customData;
                })
                .catch(function (error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
            this.isLoading = false;
        },
        calculateNewDispo() {
            if (this.form.client != null && this.form.client.id == 0) {
                // means if it is Internal
                this.form.new_dispo =
                    parseInt(this.rowData.stock) + parseInt(this.form.quantity_to_order);
            } else {
                this.form.new_dispo = parseInt(this.rowData.stock);
            }
        },
        makeOrderToSupplier(supplierOrderId) {
            this.errorMessage = null;
            axios
                .post(API_BASE_URL + "/suppliers/order/" + supplierOrderId, this.form, {
                    headers: this.header,
                })
                .then(({ data }) => {
                    if (typeof data.data[Object.keys(data.data)[0]] == "string") {
                        this.$toast.warning(data.data[Object.keys(data.data)[0]]);
                    } else {
                        let responseLength = Object.keys(
                            data.data[Object.keys(data.data)[0]]
                        ).length;
                        for (let index = 0; index < responseLength; index++) {
                            let arrayItemKey =
                                Object.keys(data.data[Object.keys(data.data)[0]])[index] + "";
                            let arrayItemValue =
                                data.data[Object.keys(data.data)[0]][arrayItemKey];
                            if (arrayItemValue) {
                                let response = arrayItemValue;
                                if (response && response.error_code == 0) {
                                    if (response.availability > -1) {
                                        this.$toast.success(
                                            this.$t("stocks.order_placed_successfully")
                                        );
                                    } else {
                                        this.retryPopUp();
                                    }
                                } else {
                                    this.retryPopUp();
                                    this.$toast.error(response.error_message);
                                }
                            }
                        }
                    }
                })
                .catch(() => {
                    this.retryPopUp();
                    this.$toast.error(this.$t("stocks.something_went_wrong"));
                })
                .finally(() => {
                    this.ordering = false;
                });
        },
        submitOrderDetails() {
            this.closeOrderDetails();

            this.ordering = true;
            axios
                .post(API_BASE_URL + "/orders", this.form, {
                    headers: this.header,
                })
                .then((response) => {
                    if (response.status === 201) {
                        this.lastSupplierOrderCreated = response.data.data;
                        this.makeOrderToSupplier(response.data.data.id);
                    } else {
                        // alert('ERROR on saving. Please try again later');
                        this.$toast.error(this.$t("stocks.something_went_wrong"));
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t("stocks.something_went_wrong"));
                })
                .finally(() => { });
        },
        calculateNewStockPrice() {
            // Please dont delete the below table, its for reference
            //            Stock      Reserve      Unit Price            Total       NewPrice
            // Current     4           2            30                   180          30.8
            // New         4                        32->(col. Price)     128
            let currentTotal =
                (this.rowData.stock + this.rowData.reserve) * this.rowData.price;
            let newTotal = this.form.quantity_to_order * this.form.price;

            this.form.new_stock_price = Number(
                (currentTotal + newTotal) /
                (parseInt(this.rowData.stock) +
                    parseInt(this.rowData.reserve) +
                    parseInt(this.form.quantity_to_order))
            ).toFixed(this.getDecimalNumber());
        },
        getStockAvailability() {
            this.loader = true;
            this.errorMessage = null;
            this.stockLoading = true;
            axios
                .get(
                    API_BASE_URL +
                    "/suppliers/" +
                    this.form.supplier_id + // Note: If brand column is selected then use brand supplier_detail_id
                    "/availability/" +
                    this.rowData.article_id,
                    { headers: this.header }
                )
                .then(({ data }) => {
                    let response = data.data[Object.keys(data.data)[0]];
                    if (response && response.error_code == 0) {
                        if (response.availability > -1) {
                            this.rowData[`${this.columnData.field_stock}`] = response.stock;
                            this.rowData[`${this.columnData.field_delivery_date}`] =
                                response.delivery_date;

                            this.rowData[`${this.columnData.field_price}`] = response.price;
                            this.form.acq_price = response.price;
                            this.form.supplier_stock = response.stock;
                            if (!response.delivery_date && !response.stock) {
                                this.$toast.error(
                                    this.$t(
                                        "stocks.supplier_responded_with_no_stock_and_with_no_delivery_date"
                                    )
                                );
                            }
                        } else {
                            this.errorMessage = this.$t(
                                "stocks.error_while_making_connection_to_supplier"
                            );
                        }
                    } else if (response && response.error_code > 0) {
                        this.$toast.error(
                            this.$t("stocks.supplier_responded_with_error_code")
                        );
                    } else {
                        this.$toast.error(response.error_message);
                    }
                })
                .catch(function (error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.loader = false;
                    this.stockLoading = false;
                });
        },
        deleteSupplierOrder() {
            axios
                .delete(
                    API_BASE_URL + "/suppliers/order/" + this.lastSupplierOrderCreated.id,
                    { headers: this.header }
                )
                .then(() => { })
                .catch(function (error) {
                    console.log("an error occured " + error);
                })
                .finally(() => { });
        },
        resetData() {
            this.form.quantity_to_order = 4;
            this.calculateNewDispo();
            this.getClients();
            this.rowData.stock = this.rowData.stock ?? 0;
            this.rowData.reserve = this.rowData.reserve ?? 0;
            this.rowData.message_for_order = "";
            this.rowData.order_reference = "";
            this.$emit("update:rowData", null);
            this.rowData = null;
            this.errorMessage = null;
        },
        calculateTotalOnChange() {
            this.form.total_price = Number(
                parseFloat(this.form.quantity_to_order * this.form.price)
            ).toFixed(this.getDecimalNumber());
            this.form.price_with_tva = Number(
                parseFloat(
                    parseFloat(this.form.total_price ?? 0) +
                    (parseFloat(this.form.total_price ?? 0) *
                        parseFloat(this.form.tva ?? 0)) /
                    100
                )
            ).toFixed(this.getDecimalNumber());
            this.calculateNewDispo();
            this.calculateNewStockPrice();
        },
    },
    computed: {
        cachedSuppliers() {
            return (
                this.cachedData["suppliers"][this.selectedFamily.id] ?? []
            ).filter((element) => element.id != 0);
        },
        isSupplierActiveConnection() {
            let supplier = this.getSupplierById(this.form.supplier_id);
            if (supplier && supplier.companySupplier) {
                if (supplier.companySupplier.length == 0) {
                    return false;
                }
                return (
                    supplier.companySupplier.length > 0 &&
                    supplier.companySupplier[0].is_active == 1
                );
            }
            return false;
        },
        supplierName() {
            let foundSupplier = null;
            let supplier_id = this.form.supplier_id;
            if (supplier_id) {
                (this.cachedData["suppliers"][this.selectedFamily.id] ?? []).forEach(
                    (supplier) => {
                        if (supplier.id == supplier_id) {
                            foundSupplier = supplier;
                        }
                    }
                );
            }
            return foundSupplier ? foundSupplier.name : "";
        },
    },
    watch: {
        selectedFamily: {
            handler: function () {
                this.getFamilyArticleSuppliers();
            },
            immediate: true,
        },
        showDialog(val) {
            if (val == false) {
                // reset data on closing the dialog
                this.resetData();
            }
        },
        rowData(val) {
            if (val) {
                this.newStockPrice = this.rowData[`${this.columnData.field_price}`];
                this.form = {
                    quantity_to_order: 0,
                    confirmed_quantity: 0,
                    price: this.rowData[`${this.columnData.field_price}`],
                    acq_price: this.rowData[`${this.columnData.field_price}`],
                    supplier_stock: this.rowData[`${this.columnData.field_stock}`],
                    unit_client_price:
                        this.rowData[`${this.columnData.field_client_price}`],
                    delivery_date: this.rowData[`${this.columnData.field_delivery_date}`],
                    total_price: 0,
                    tva: this.rowData.tax,
                    price_with_tva: 0,
                    client: "",
                    dispo: this.rowData.stock - this.rowData.reserve,
                    new_dispo: 0,
                    current_stock_price: this.rowData.price,
                    new_stock_price: this.rowData.price,
                    article_id: this.rowData.article_id,
                    supplier_id:
                        this.columnData && this.columnData.supplier_detail
                            ? this.columnData.supplier_detail.id == 0
                                ? this.rowData.brand_supplier_id
                                : this.columnData.supplier_detail.id
                            : 0,
                    message_for_order: "",
                    order_reference: "",
                };

                this.clients = [this.internal_stock];
                this.form.client = this.clients[0];
                if (this.form.supplier_id == 0 || this.form.supplier_id == null) {
                    // means user has clicked on brand column,  then in this case we will replace supplier_id with 0 to rowData.brand_id's supplier
                    let brand = this.getBrandById(this.rowData.brand_id);
                    let brandAsSupplier = this.getSupplierAsBrand(brand);
                    this.form.supplier_id = brandAsSupplier ? brandAsSupplier.id : null;
                    // if even now supplier doesn't exists then put proprietary_supplier_id as supplier
                    if (
                        !this.form.supplier_id &&
                        brand &&
                        brand.proprietary_supplier_id
                    ) {
                        let supplier = this.getSupplierById(brand.proprietary_supplier_id);
                        if (supplier) {
                            this.form.supplier_id = supplier.id;
                        }
                    }
                }
                this.getCompanyArticleDeatails().then(() => {
                    this.form.quantity_to_order = 4;
                    this.calculateNewDispo();
                    this.getClients();
                    this.rowData.stock = this.rowData.stock ?? 0;
                    this.rowData.reserve = this.rowData.reserve ?? 0;
                });

                // Add Brand Name in Pop Up
                if (this.rowData.brand_id) {
                    this.brands.forEach((brand) => {
                        if (brand.id == this.rowData.brand_id) {
                            this.brand = brand;
                        }
                    });
                }
            }
        },
        "form.quantity_to_order": {
            handler: function () {
                this.calculateTotalOnChange();
            },
        },
        "form.price": {
            handler: function () {
                this.calculateTotalOnChange();
            },
        },

        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == false && this.confirmationDialogOperation != null) {
                // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
                if (this.confirmationDialogOperation == "keep_trying") {
                    if (this.confirmationDialogButtonClickedIs == false) {
                        this.deleteSupplierOrder();
                    }
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        search(val) {
            if (
                val &&
                typeof this.form.client == "string" &&
                val != this.form.client.name
            ) {
                if (val == this.form.client.name) return;
                // Items have already been requested
                if (this.isLoading) return;

                this.debounceInput(val);
            } else {
                this.debounceInput(val);
            }
        },
        "form.client": {
            handler: function () {
                this.calculateNewDispo();
      },
    },
  },
};
</script>
