var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"fill-height"},[_c('v-container',{staticClass:"d-flex align-center justify-center"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"6","lg":"4"}},[_c('v-card',{ref:"form",staticClass:"elevation-3 rounded-lg pa-5"},[_c('v-card-title',{staticClass:"text-center"},[_c('v-img',{staticStyle:{"max-height":"60px","max-width":"60px","margin-right":"10px"},attrs:{"src":`/images/cxp.png`}}),_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("login.title"))+" ")])],1),_c('v-card-text',{staticClass:"pt-0",staticStyle:{"height":"200px !important"}},[(!_vm.showOtpPage)?_c('div',[_c('v-form',{staticClass:"mt-3"},[_c('v-text-field',{staticClass:"mb-3",attrs:{"label":_vm.$t('login.email'),"variant":"outlined","density":"comfortable","color":"primary","required":"","error-messages":_vm.$store.state.validation
                      ? _vm.returnErrorMessage(
                          [
                            !_vm.$v.email.required,
                            this.$t('login.email_required'),
                          ],
                          [!_vm.$v.email.email, this.$t('login.email_required')]
                        )
                      : []},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.submitLogin()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticClass:"mb-3",attrs:{"label":_vm.$t('login.password'),"variant":"outlined","density":"comfortable","color":"primary","required":"","error-messages":_vm.$store.state.validation
                      ? _vm.returnErrorMessage(
                          [
                            !_vm.$v.password.required,
                            this.$t('login.password_required'),
                          ],
                          [
                            !_vm.$v.password.minLength,
                            this.$t('login.password_minimum_length'),
                          ]
                        )
                      : [],"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.submitLogin()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","color":"primary","size":"large","loading":_vm.$store.state.isLoading},on:{"click":_vm.submitLogin}},[_vm._v(" "+_vm._s(_vm.$t("login.title"))+" "),_c('v-icon',{attrs:{"end":""}},[_vm._v("mdi-login-variant")])],1)],1)],1):_c('div',[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("verification_code_has_sent_to_email"))+" "),(_vm.attemptLeft < _vm.maxAttempt)?_c('span',{staticClass:"text-primary"},[_c('br'),_vm._v(" "+_vm._s(_vm.attemptLeft)+" "+_vm._s(_vm.$t("attempt_left"))+" ")]):_vm._e()]),_c('v-otp-input',{ref:"otpInput",attrs:{"color":"primary","length":"6","required":"","error":_vm.invalidOtpError},on:{"finish":_vm.verifyOtp,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.verifyOtp.apply(null, arguments)}},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }