<template>
  <div v-if="readyToView">
    <h2 class="card-title mb-0 pb-4">{{ card.title }}</h2>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
export default {
  props: {
    bardata: [String],
    barfields: [Array],
    card: [Object],
  },
  data() {
    return {
      readyToView: false,
      bardataProcessed: [],
      series: [
        // {
        //     name: 'Net Profit',
        //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        // },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
          categories: [],
        },
        yaxis: {
          title: {
            // text: '$ (thousands)'
          },
        },
        fill: {
          opacity: 1,
        },
        // tooltip: {
        //     y: {
        //         formatter: function (val) {
        //             return "$ " + val + " thousands"
        //         }
        //     }
        // }
      },
    };
  },
  mounted() {
    // Process Bar Data
    if (this.bardata) {
      let dataRows = this.bardata.replace(/"/g, "").split("\n");
      let finalData = [];
      let count = -1;
      dataRows.forEach((record) => {
        count++;
        if (count == 0) {
          // return because first row will contain headers
          return;
        }
        let dataInColumns = record.split(";");
        let rowObject = {};
        for (let index = 0; index < this.barfields.length; index++) {
          rowObject[index] = dataInColumns[index];
        }
        finalData.push(rowObject);
      });
      this.bardataProcessed = finalData;
    }

    // Process All the Data related to Graph
    this.barfields.forEach((element, index) => {
      if (element.key.includes("x_")) {
        // this.chartOptions.xaxis.categories.push(element.translation);
        let dataToBeFilled = [];
        this.bardataProcessed.forEach((row) => {
          if (!row[index]) {
            return;
          }
          dataToBeFilled.push(row[index]); // index is same as data to be filled
        });
        this.chartOptions.xaxis.categories = dataToBeFilled;
      }
      if (element.key.includes("y_")) {
        let dataToBeFilled = [];
        this.bardataProcessed.forEach((row) => {
          if (!row[index]) {
            return;
          }
          dataToBeFilled.push(row[index]); // index is same as data to be filled
        });
        this.series.push({
          name: element.translation,
          data: dataToBeFilled,
          // data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        });
      }
    });

    // Show Graph
    this.$nextTick(() => {
      this.readyToView = true;
    });
  },
};
</script>
