<template>
  <v-btn class="px-4" color="grey lighten-2">
    {{ $t("billings.okay") }}
    <v-icon dark right> mdi-keyboard-return </v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "OkayButton",
};
</script>
