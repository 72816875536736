<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      hide-overlay
      style="z-index: 20001"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-emoticon-happy-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Emoji Picker</span>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <emoji-picker @emoji="selectEmoji">
              <div
                class="mr-2 mt-2"
                slot="emoji-invoker"
                ref="showEmojiButton"
                slot-scope="{ events: { click: clickEvent } }"
                style="display: none"
                @click.stop="clickEvent"
              >
                <v-btn icon>
                  <v-icon>mdi-emoticon-happy-outline</v-icon>
                </v-btn>
              </div>
              <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                <div>
                  <div>
                    <div
                      v-for="(emojiGroup, category) in emojis"
                      :key="category"
                    >
                      <h5>{{ category }}</h5>
                      <div>
                        <span
                          :style="{ cursor: 'pointer' }"
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                          >{{ emoji }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import EmojiPicker from "vue-emoji-picker";

export default {
  name: "EmojiPickerModal",
  refs: ["showEmojiButton"],
  components: {
    EmojiPicker,
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.$nextTick(() => {
          this.$refs.showEmojiButton.click();
        });
      }
    },
  },
  data() {
    return {
      dialog: false,
      emoji: null,
      search: "",
    };
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit("emojiSelected", emoji);
      this.$refs.showEmojiButton.click();
      this.dialog = false;
    },
  },
};
</script>
