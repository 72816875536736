<template>
  <v-dialog
    persistent
    @click:outside="falseButtonClicked()"
    @keydown.esc="falseButtonClicked()"
    v-model="internalShowDialog"
    min-width="600"
    max-width="600"
  >
    <v-card>
      <!-- Card Title -->
      <v-card-title class="text-title">
        {{ $t("bulk_payment") }}
      </v-card-title>
      <!-- Card Content -->
      <v-card-text class="dialog-text text-content" color="black">
        <v-row>
          <!-- Payment Method -->
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="payed_by_id"
              :items="paymentMethodsBasedOnResource"
              item-value="id"
              item-text="text"
              return-object
              menu-props="auto"
              :label="$t('billings.payed_by')"
              :outlined="is_boxes"
              :dense="is_boxes"
              @change="updateLocalStorageValues()"
            ></v-autocomplete>
          </v-col>
          <!-- Payed At -->
          <v-col
            cols="12"
            sm="6"
            :style="{
              display: 'flex',
              flexDirection: 'row',
            }"
          >
            <v-text-field
              class="to-upper right-aligned-input shrink"
              :label="$t('billings.payed_at')"
              v-model="payed_at"
              type="datetime-local"
              @input="updateLocalStorageValues()"
              @change="updateLocalStorageValues()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <!-- Card Actions -->
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-2"
          class="px-4"
          @click="falseButtonClicked()"
        >
          {{ $t("nav_buttons.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn
          class="px-4"
          :color="'success'"
          :disabled="!payed_by_id || is_clicked"
          @click="trueButtonClicked()"
        >
          {{ $t("nav_buttons.save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import moment from "moment";

export default {
  name: "BulkPayment",
  components: {},
  props: {
    showDialog: [Number, Boolean],
    paymentItems: {
      type: Array,
      default: Array,
    },
    module: [String],
  },
  data() {
    return {
      is_clicked: false,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      internalShowDialog: this.showDialog,
      payments: [],
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isDataLoaded: false,
      payment_methods: [],
      isLoading: false,
      payed_by_id: null,
      payed_at: null,
    };
  },
  async beforeMount() {
    let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
    this.payed_at = moment(currentDate)
      .tz(this.currentTimeZone)
      .format("YYYY-MM-DD HH:mm:ss");
    await this.getPaymentMethods();
    this.applyPreviousFilters();
  },
  methods: {
    updateLocalStorageValues() {
      let store = {
        payed_by_id: this.payed_by_id,
        payed_at: this.payed_at,
      };
      localStorage.setItem("BULK_PAYMENT_STORE", JSON.stringify(store));
    },
    applyPreviousFilters() {
      let storeFilters = JSON.parse(localStorage.getItem("BULK_PAYMENT_STORE"));
      if (storeFilters != null) {
        this.payed_by_id = storeFilters.payed_by_id;
        this.payed_at = storeFilters.payed_at;
      }
    },
    makeSubmitData() {
      let bulkData = [];
      this.payments.forEach((payment) => {
        let payload = {
          amount: payment.amount,
          payed_by_id: this.payed_by_id
            ? typeof this.payed_by_id == "object"
              ? this.payed_by_id.id
              : this.payed_by_id
            : null,
          created_at: this.payed_at,
          executed_at: this.payed_at,
        };
        if (this.module == "billing") {
          payload["order_id"] = payment.id;
        }
        if (this.module == "expense") {
          payload["expense_id"] = payment.id;
        }
        bulkData.push(payload);
      });
      return {
        is_bulk_payment: true,
        data: bulkData,
      };
    },
    savePayment() {
      return new Promise((resolve) => {
        this.isLoading = true;
        let data = this.makeSubmitData();
        let url =
          this.module == "billing" ? "/order_payments" : "/expense_payments";
        axios
          .post(API_BASE_URL + url, data, {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(() => {
            this.$store.commit("setSelectedItemsForBulkPayment", null);
            resolve();
          })
          .catch((error) => {
            console.log("an error occured " + error.response.data.errors[0]);
          })
          .finally(() => {
            this.isLoading = false;
            resolve();
          });
      });
    },
    falseButtonClicked() {
      this.$emit("update:showDialog", false);
      this.internalShowDialog = false;
    },
    async trueButtonClicked() {
      this.is_clicked = true;
      await this.savePayment().then(() => {
        this.$emit("refreshList", true);
      });
      this.$emit("update:showDialog", false);
      this.internalShowDialog = false;
    },
    async getPaymentMethods() {
      await this.getCodes("payment_types", "billings.payment_methods").then(
        (data) => {
          this.payment_methods = data;
        }
      );
    },
  },
  computed: {
    paymentMethodsBasedOnResource() {
      return this.filterPaymentMethods(this.payment_methods);
    },
    headers() {
      let data = [
        {
          text: this.$t("expenses.reference_client"),
          value: "reference_client",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("expenses.bill_date"),
          value: "bill_date",
          width: 140,
          sortable: false,
        },
        {
          text: this.$t("billings.client"),
          value: "client",
          width: 100,
          sortable: false,
          align: "start",
        },
        {
          text: this.$t("billings.total"),
          value: "total",
          width: 100,
          sortable: false,
          align: "end",
        },
        {
          text: this.$t("billings.payed_by"),
          value: "payed_by",
          width: 150,
          sortable: false,
          align: "end",
        },
        {
          text: this.$t("expenses.to_be_payed"),
          value: "to_be_payed",
          width: 100,
          sortable: false,
          align: "end",
        },
        {
          text: this.$t("billings.payed_at"),
          value: "payed_at",
          width: 90,
          sortable: false,
          align: "center",
        },
      ];

      return data;
    },
  },
  watch: {
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
      },
    },
    paymentItems: {
      handler: function () {
        this.payments = [];
        if (this.paymentItems) {
          this.paymentItems.forEach((element) => {
            let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
            if (element) {
              this.payments.push({
                id: element.id,
                reference: element.reference,
                bill_date: element.bill_date,
                client: element.client,
                total: element.total,
                payed_by_id: element.payed_by_id,
                amount: element.total,
                payed_at: moment(currentDate)
                  .tz(this.currentTimeZone)
                  .format("YYYY-MM-DD HH:mm:ss"),
              });
            }
          });
          this.isDataLoaded = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 27px !important;
}
.text-content {
  font-size: 18px !important;
}
.text-title {
  font-size: 24px !important;
}
</style>
