<template>
  <v-dialog
    class="dialogPopUp"
    v-model="internalShowDialog"
    @click:outside="internalShowDialog = false"
    @keydown.esc="internalShowDialog = false"
    max-width="1800"
    scrollable
  >
    <v-card>
      <v-card-title class="mb-0">
        <span class="text-h5">{{ $t("billings.search_article") }}</span>
      </v-card-title>
      <v-card-text class="pb-1">
        <v-container class="pt-0 ma-0" style="max-width: 100%">
          <v-row v-if="isPageLoaded">
            <v-row class="pl-2 pr-2 pt-6">
              <v-col class="d-flex pl-2" cols="12" sm="2">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="familyArticle"
                      :items="familyArticles"
                      :label="$t('articles.family')"
                      item-value="id"
                      item-text="name"
                      class="mr-2"
                      return-object
                      @change="updateLocalStorageValues(true)"
                      :prepend-icon="
                        normalSearch ? 'mdi-grid' : 'mdi-rectangle-outline'
                      "
                      @click:prepend="normalSearch = !normalSearch"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      :disabled="isLoading"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      @change="fetchData()"
                      v-model="familyClient"
                      :items="familyClients"
                      item-value="id"
                      item-text="name"
                      :label="$t('pricing_rule.family_client')"
                      menu-props="auto"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      :disabled="isLoading"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="d-flex pl-0" cols="12" sm="7">
                <template v-if="normalSearch || customFilters.length == 0">
                  <v-text-field
                    class="ml-3 to-upper"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    v-bind:clearable="true"
                    clear-icon="mdi-close"
                    v-model="search"
                    @change="updateLocalStorageValues()"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    :disabled="isLoading"
                  ></v-text-field>
                </template>
                <template v-if="!normalSearch">
                  <div :style="isLoading ? 'pointer-events:none' : ''">
                    <v-btn-toggle
                      id="season-button"
                      v-if="
                        familyArticle &&
                        (familyArticle.parent_family_id == 1 ||
                          familyArticle.id == 1)
                      "
                      v-model="season"
                      dense
                      mandatory
                      small
                      class="mb-0 mr-2 mt-5"
                      style="margin-top: 15px"
                      :disabled="isLoading"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn  small value="0" :disabled="isLoading">
                            <!-- No Season -->
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              dark
                              color="grey darken-1"
                            >
                              mdi-check-all
                            </v-icon>
                            <span>
                              {{ $t("codes.shorts.no_season") }}
                            </span>
                          </v-btn>
                        </template>
                        <span>
                          {{ $t("codes.no_season") }}
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small value="10111002" :disabled="isLoading">
                            <!-- Summer -->
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              dark
                              color="amber darken-4"
                            >
                              mdi-weather-sunny
                            </v-icon>
                            <span>
                              {{ $t("codes.shorts.summer") }}
                            </span>
                          </v-btn>
                        </template>
                        <span>
                          {{ $t("codes.summer") }}
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small value="10111001" :disabled="isLoading">
                            <!-- Winter -->
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              dark
                              color="blue darken-2"
                            >
                              mdi-weather-snowy-heavy
                            </v-icon>
                            <span>
                              {{ $t("codes.shorts.winter") }}
                            </span>
                          </v-btn>
                        </template>
                        <span>
                          {{ $t("codes.winter") }}
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small value="10111003" :disabled="isLoading">
                            <!-- All Season -->
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              dark
                              color="green darken-1"
                            >
                              mdi-weather-partly-snowy-rainy
                            </v-icon>
                            <span>
                              {{ $t("codes.shorts.all_season") }}
                            </span>
                          </v-btn>
                        </template>
                        <span>
                          {{ $t("codes.all_season") }}
                        </span>
                      </v-tooltip>
                    </v-btn-toggle>
                  </div>

                  <div
                    v-for="(filter, index) in filterPropertiesExcept(
                      customFilters
                    )"
                    :key="index"
                  >
                    <template v-if="filter.property_name == 'property_00'">
                      <v-autocomplete
                        v-if="filter.property_name == 'property_00'"
                        flat
                        :ref="filter.property_name"
                        :items="brands"
                        :search-input.sync="searchBrand"
                        v-model="brand"
                        :label="$t('articles.properties.brand')"
                        class="mr-2 to-upper"
                        item-value="id"
                        item-text="name"
                        append-icon="mdi-close"
                        @click:append="brand = null"
                        return-object
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        hide-no-data
                        :disabled="isLoading"
                      ></v-autocomplete>
                    </template>
                    <template v-else>
                      <v-autocomplete
                        v-if="filter.mapping_key != null"
                        v-model="filter.value"
                        :items="multiselectsValues[index]"
                        :label="filter.text"
                        item-value="id"
                        hide-details="auto"
                        class="mr-2"
                        append-icon="mdi-close"
                        @click:append="
                          filter.value = null;
                          fetchData();
                        "
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        hide-no-data
                        :disabled="isLoading"
                      ></v-autocomplete>
                      <v-text-field
                        flat
                        v-if="filter.mapping_key == null"
                        v-model="filter.value"
                        :label="filter.text"
                        class="mr-2 to-upper"
                        :ref="filter.property_name"
                        :disabled="isLoading"
                        @focus="
                          filter.value = null;
                          resetFurtherBoxesValues(filter.property_name);
                          updateLocalStorageValues();
                        "
                        @keypress="
                          handleInput(
                            $event,
                            filter.keytype,
                            filter.search_length
                          )
                        "
                        @keyup="
                          handleAutoFocus(
                            $event,
                            filter.search_length,
                            filter.property_name,
                            filter.keytype,
                            filterPropertiesExcept(customFilters)[index + 1]
                              ? filterPropertiesExcept(customFilters)[index + 1]
                                  .property_name
                              : null
                          )
                        "
                        :outlined="is_boxes"
                        :dense="is_boxes"
                      ></v-text-field>
                    </template>
                  </div>
                  <v-select
                    v-if="articleBooleanProperties.length >= 1"
                    v-model="booleanSelectedValues"
                    :label="$t('articles.properties.more')"
                    :items="articleBooleanProperties"
                    item-value="id"
                    item-text="name"
                    multiple
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    return-object
                    :disabled="isLoading"
                    @change="updateLocalStorageValues()"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span>
                        <template
                          v-if="
                            index === 0 && booleanSelectedValues.length == 1
                          "
                        >
                          {{
                            item.name.substr(
                              0,
                              booleanSelectedValues.length > 1 ? 3 : 5
                            )
                          }}
                        </template>
                        <template v-if="index === 1">
                          (+{{ booleanSelectedValues.length - 1 }})
                        </template>
                      </span>
                    </template>
                  </v-select>
                </template>

                <!-- Min. Quantity -->
                <v-text-field
                  v-if="familyArticle"
                  class="to-upper ml-2"
                  style="max-width: 50px"
                  :label="$t('stocks.min_quantity_to_search')"
                  v-model="min_quantity_to_search"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  v-on:keydown.enter.prevent="fetchData()"
                  :disabled="isLoading"
                ></v-text-field>
                <v-btn
                  class="mt-5 ml-2"
                  :class="isLoading ? 'disabled' : ''"
                  color="primary"
                  :dark="isLoading ? false : true"
                  @click="fetchData()"
                  small
                  :disabled="isLoading"
                >
                  <v-icon dark>mdi-database-search</v-icon>
                </v-btn>
              </v-col>
              <v-col class="d-flex pl-0 justify-end" cols="12" sm="3">
                <NewCombinedInput
                  :familyArticle="familyArticle"
                  :withProposedArticles.sync="withProposedArticles"
                  :stockUnits.sync="stockUnits"
                  :withTvaWithoutTva.sync="withTvaWithoutTva"
                  :withOnlyInternalStock.sync="withOnlyInternalStock"
                  class="pl-2"
                  :isLoading="isLoading"
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs" class="ml-2">
                      <!-- Select Headers -->
                      <v-menu
                        min-width="300px"
                        bottom
                        left
                        v-model="supplierModel"
                        v-if="suppliers && suppliers.length > 0"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            dense
                            small
                            class="mt-5 pl-0 pr-0 to-upper"
                            :color="
                              unCheckedSupplierFilters > 0
                                ? 'red text-white'
                                : 'success text-white'
                            "
                            :disabled="isLoading"
                          >
                            <v-icon
                              class="mr-1"
                              v-if="supplierColumnVisibility == true"
                              >mdi-playlist-check</v-icon
                            >
                            <v-icon
                              class="mr-1"
                              color="white"
                              v-if="supplierColumnVisibility == false"
                              >mdi-playlist-remove</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="mb-0">
                            <v-row>
                              <v-spacer></v-spacer>
                              <v-col
                                cols="12"
                                sm="6"
                                class="mr-0 pr-0 d-flex justify-end"
                              >
                                <span style="font-size: 14px">
                                  {{ $t("stocks.select_unselect") }}
                                </span>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="2"
                                class="ml-0 pl-0 pr-1 d-flex justify-end"
                              >
                                <v-checkbox
                                  style="position: absolute; z-index: 9"
                                  label=""
                                  class="mt-0"
                                  color="green"
                                  hide-details
                                  v-model="checkAllSubHeader"
                                  :indeterminate="checkAllSubHeaderIntermediate"
                                  @click="checkUncheckAllSubHeader()"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-list>
                            <template v-if="subHeader.length > 0">
                              <draggable
                                v-model="tempSubHeader"
                                :options="{
                                  group: 'people',
                                }"
                                style="min-height: 10px"
                              >
                                <template v-for="item in tempSubHeader">
                                  <v-list-item
                                    v-if="item.text != 'BRAND'"
                                    :key="item.order"
                                    @click="
                                      item.is_read_only
                                        ? ''
                                        : toggleColumnVisibility(item);
                                      markIntermediateStateAfterCheck();
                                    "
                                    :class="
                                      item.is_read_only ? 'grey lighten-4' : ''
                                    "
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title min-width="200px">
                                        <v-row>
                                          <v-col cols="12" sm="2">
                                            <v-icon class="rowHandle"
                                              >mdi-drag</v-icon
                                            >
                                          </v-col>
                                          <v-col cols="12" sm="8">
                                            <span class="text-sm-left">
                                              {{ item.text }}
                                            </span></v-col
                                          >
                                          <v-col cols="12" sm="2">
                                            <v-checkbox
                                              style="
                                                position: absolute;
                                                z-index: 9;
                                              "
                                              label=""
                                              class="mt-n2"
                                              @click="
                                                item.is_read_only
                                                  ? ''
                                                  : toggleColumnVisibility(
                                                      item
                                                    );
                                                markIntermediateStateAfterCheck();
                                              "
                                              v-model="item.visible"
                                              color="green"
                                              hide-details
                                              :disabled="
                                                item.is_read_only ? true : false
                                              "
                                            ></v-checkbox>
                                            <!-- <v-icon v-if="item.visible == true"  class="rowHandle">mdi-check</v-icon>  -->
                                          </v-col>
                                        </v-row>
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </draggable>
                            </template>
                            <template v-else>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <v-row>
                                      <v-col>
                                        <span class="pa-1">
                                          {{
                                            $t("stocks.no_suppliers_found_for")
                                          }}
                                          {{
                                            familyArticle
                                              ? familyArticle.name
                                              : ""
                                          }}
                                        </span>
                                      </v-col>
                                    </v-row>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-list>
                          <v-spacer></v-spacer>
                          <v-card-actions
                            v-if="subHeader.length > 0"
                            class="mt-0 pt-0"
                          >
                            <v-btn
                              :disabled="isLoading"
                              color="success"
                              class="ma-0 text-white-color"
                              block
                              @click="
                                supplierModel = false;
                                updateHeadersListingOrder();
                              "
                              dense
                              small
                            >
                              {{ $t("action_texts.okay") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                  </template>
                  {{ $t("stocks.suppliers") }}
                </v-tooltip>
              </v-col>
            </v-row>

            <v-data-table
              id="stock_model"
              :footer-props="{
                'items-per-page-options': dataTableOptions,
              }"
              fixed-header
              :headers="mainHeader"
              :hide-default-footer="isLoading"
              :options.sync="options"
              :items="articles"
              :items-per-page="itemsPerPage"
              :sort-by="sortBy"
              :server-items-length="totalItems"
              :loading="isLoading || $store.state.isLoading"
              :loading-text="this.$t('loading')"
              @update:page="updatePagination"
              @update:items-per-page="updateItemsPerPage"
              class="elevation-0 mainTable"
              item-key="identifier"
              style="width: 100%"
              :height="'calc(-305px + 100vh)'"
              dense
              @update:sort-by="updateSort"
              @update:sort-desc="updateSortOrder"
              :key="forceReloadComponentKey"
            >
              <template v-slot:item="{ item }">
                <tr v-if="item">
                  <!-- <td :class="familyArticle && (familyArticle.parent_family_id == 1 || familyArticle.id == 1) ? 'stock-sticky-col-tyre-1' : 'stock-sticky-col-1'" class="stock-sticky-col-1 pa-1 text-sm-center">{{ item.article_id }}</td> -->
                  <td
                    :class="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                        ? 'stock-sticky-col-tyre-2'
                        : 'stock-sticky-col-1'
                    "
                    class="pa-1 text-sm-center"
                  >
                    <v-icon
                      v-if="item.length != 0"
                      color="blue"
                      @click.stop.prevent="showImage(item)"
                      >mdi-image</v-icon
                    >
                  </td>
                  <td
                    :class="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                        ? 'stock-sticky-col-tyre-3'
                        : ''
                    "
                    class="stock-sticky-col-var pl-2 pa-1 text-center"
                    v-if="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                    "
                  >
                    <template v-if="item.property_01">
                      {{
                        $t(
                          "articles.types.shorts." +
                            getKeyFromCode(tyreTypes, item.property_01)
                        )
                      }}</template
                    >
                    <!-- Type Column -->
                  </td>
                  <td
                    align="center"
                    :class="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                        ? 'stock-sticky-col-tyre-4'
                        : ''
                    "
                    class="stock-sticky-col-var text-sm-center"
                    v-if="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                    "
                  >
                    <template v-if="item.property_01">
                      <div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              v-if="
                                'summer' ==
                                getKeyFromCode(tyreSeasons, item.property_02)
                              "
                              dark
                              color="amber darken-4"
                              >mdi-weather-sunny</v-icon
                            >
                            <!-- <v-icon  v-if="'no_season' == getKeyFromCode(tyreSeasons,item.property_02)" dark color="grey darken-1">mdi-check-all</v-icon> -->
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              v-if="
                                'all_season' ==
                                getKeyFromCode(tyreSeasons, item.property_02)
                              "
                              dark
                              color="green darken-1"
                              >mdi-weather-partly-snowy-rainy</v-icon
                            >
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              v-if="
                                'winter' ==
                                getKeyFromCode(tyreSeasons, item.property_02)
                              "
                              dark
                              color="blue darken-2"
                              >mdi-weather-snowy-heavy</v-icon
                            >
                          </template>
                          <span
                            v-if="
                              'summer' ==
                              getKeyFromCode(tyreSeasons, item.property_02)
                            "
                          >
                            {{ $t("codes.summer") }}
                          </span>
                          <span
                            v-if="
                              'no_season' ==
                              getKeyFromCode(tyreSeasons, item.property_02)
                            "
                          >
                            {{ $t("codes.no_season") }}
                          </span>
                          <span
                            v-if="
                              'all_season' ==
                              getKeyFromCode(tyreSeasons, item.property_02)
                            "
                          >
                            {{ $t("codes.all_season") }}
                          </span>
                          <span
                            v-if="
                              'winter' ==
                              getKeyFromCode(tyreSeasons, item.property_02)
                            "
                          >
                            {{ $t("codes.winter") }}
                          </span>
                        </v-tooltip>
                      </div>
                    </template>
                  </td>
                  <td
                    :class="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                        ? 'stock-sticky-col-tyre-5'
                        : familyArticle &&
                          (familyArticle.parent_family_id == 2 ||
                            familyArticle.id == 2)
                        ? 'stock-sticky-col-1'
                        : 'stock-sticky-col-2'
                    "
                    class="pl-2 pa-1 text-sm-left"
                  >
                    <template v-if="item.description">
                      {{ item.description }}
                    </template>
                    <template v-else>{{ item.description }} </template>
                    <br />
                    <TextComponentWithExtraIcons
                      :value="item.identifier"
                    ></TextComponentWithExtraIcons>
                  </td>

                  <td
                    :class="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                        ? 'stock-sticky-col-tyre-6'
                        : 'stock-sticky-col-4'
                    "
                    class="pl-2 pa-1 text-sm-center"
                  >
                    <template>
                      <span v-if="item.length == 0"> </span>
                      <span v-else>
                        {{
                          item.tariff_price
                            ? item.tariff_price.toFixed(getDecimalNumber())
                            : (0).toFixed(getDecimalNumber())
                        }}
                      </span>
                    </template>
                  </td>

                  <td
                    @click="selectItem(item, slot)"
                    :class="
                      familyArticle &&
                      (familyArticle.parent_family_id == 1 ||
                        familyArticle.id == 1)
                        ? 'stock-sticky-col-tyre-7'
                        : 'stock-sticky-col-5'
                    "
                    class="pl-2 pa-1 text-sm-center"
                  >
                    <!-- <v-row :class="item.stock ? 'stock-available' : ''"> -->
                    <v-row :class="item.stock ? 'stock-available' : ''">
                      <!-- Stock or Reserve -->
                      <v-col cols="3" class="my-auto" align="left">
                        <span v-if="item.length == 0">
                          {{ $t("stocks.stock_or_rese") }}
                        </span>
                        <span
                          class="stock_or_reserver_col"
                          v-else
                          @click="change_stock_detail_dialog(item)"
                        >
                          {{
                            fixDecimal(
                              item.stock,
                              familyArticle.quantity_decimals
                            ) || fixDecimal(0, familyArticle.quantity_decimals)
                          }}
                          /
                          <span style="color: red"
                            >{{
                              fixDecimal(
                                item.reserve,
                                familyArticle.quantity_decimals
                              ) ||
                              fixDecimal(0, familyArticle.quantity_decimals)
                            }}
                          </span>
                        </span>
                      </v-col>
                      <!-- Current Stock -->
                      <v-col>
                        <template v-if="item.length == 0">
                          {{ $t("stocks.current_stock") }}
                        </template>
                        <template v-else>
                          <span
                            :style="
                              parseInt(item.stock || 0) -
                                parseInt(item.reserve || 0) >
                              0
                                ? 'color:green'
                                : ''
                            "
                          >
                            {{
                              fixDecimal(
                                parseFloat(item.stock || 0) -
                                  parseFloat(item.reserve || 0),
                                familyArticle.quantity_decimals
                              )
                            }}
                          </span>
                        </template>
                      </v-col>
                      <!-- Ordered Quantity -->
                      <v-col class="pl-0">
                        <template v-if="item.length == 0">
                          {{ $t("stocks.ordered_quantity") }}
                        </template>
                        <template v-else>
                          <span
                            v-if="parseInt(item.supplier_order_quantity) == 0"
                          >
                            {{ parseInt(item.supplier_order_quantity || 0) }}
                          </span>
                          <span v-else>
                            {{ parseInt(item.supplier_order_quantity || 0) }}
                          </span>
                        </template>
                      </v-col>
                      <!-- Acquisition Price -->
                      <v-col class="pl-0">
                        <span v-if="item.length == 0">
                          {{ $t("stocks.price") }}
                        </span>
                        <span v-else>
                          <span
                            :style="
                              parseInt(fixDecimal(item.price)) == 0
                                ? 'color:red'
                                : ''
                            "
                          >
                            {{ fixDecimal(item.price) || fixDecimal(0) }}
                          </span>
                        </span>
                      </v-col>
                      <!-- Client Price -->
                      <v-col class="pl-0 pr-4">
                        <template v-if="item.length == 0">
                          <div>
                            {{ $t("stocks.client_price_short") }}
                            x
                            {{ stockUnits }}
                          </div>
                          <div
                            class="text-red x-small-text"
                            v-if="!withTvaWithoutTva"
                          >
                            ({{ $t("stocks.without_tva") }})
                          </div>
                        </template>
                        <template v-else>
                          <span
                            :style="
                              parseInt(fixDecimal(item.family_client_price)) ==
                              0
                                ? 'color:red'
                                : ''
                            "
                          >
                            <template v-if="withTvaWithoutTva">
                              {{
                                fixDecimal(
                                  item.family_client_price * stockUnits,
                                  2
                                )
                              }}
                            </template>
                            <template v-else>
                              {{
                                fixDecimal(
                                  calculateWithoutTva(
                                    item.family_client_price * stockUnits
                                  ),
                                  2
                                )
                              }}
                            </template>
                          </span>
                        </template>
                      </v-col>
                    </v-row>
                  </td>

                  <template v-for="(slot, i) in subSlots">
                    <td
                      :key="i"
                      v-if="item.length == 0"
                      class="pa-1"
                      :class="i == 0 ? 'first-column-after-internal' : ''"
                    >
                      <v-row>
                        <v-col cols="12" sm="2" class="text-sm-center">
                          {{ $t("stocks.quantity") }}
                        </v-col>
                        <v-col cols="12" sm="3" class="text-sm-right">
                          {{ $t("stocks.price") }}
                        </v-col>
                        <v-col cols="12" sm="3" class="text-sm-right">
                          <div>
                            {{ $t("stocks.client_price_short") }}
                            x
                            {{ stockUnits }}
                          </div>
                          <div
                            class="text-red x-small-text"
                            v-if="!withTvaWithoutTva"
                          >
                            ({{ $t("stocks.without_tva") }})
                          </div>
                        </v-col>
                        <v-col cols="12" sm="4" class="text-sm-left">
                          {{ $t("stocks.d_date") }}
                        </v-col>
                      </v-row>
                    </td>
                    <td
                      v-if="item.length != 0"
                      @click="selectItem(item, slot)"
                      :key="i"
                      :class="i == 0 ? 'first-column-after-internal' : ''"
                      :style="{
                        backgroundColor: computeSlotBackgroundColor(item, slot),
                      }"
                    >
                      <v-row
                        :class="
                          item[`${slot.field_stock}`] ? 'stock-available' : ''
                        "
                      >
                        <v-col
                          cols="12"
                          sm="2"
                          class="stock-col text-sm-left"
                          v-if="item[`${slot.field_stock}`]"
                        >
                          <!-- <v-icon class="item-cart" color="green" v-if="item[`${slot.field_stock}`]" > mdi-cart-plus </v-icon> -->
                          {{ item[`${slot.field_stock}`] }}
                        </v-col>

                        <v-col
                          v-if="item[`${slot.field_stock}`]"
                          cols="12"
                          sm="3"
                          class="text-sm-right"
                          >{{ fixDecimal(item[`${slot.field_price}`]) }}</v-col
                        >
                        <v-col
                          v-if="item[`${slot.field_stock}`]"
                          cols="12"
                          sm="3"
                          class="text-sm-right"
                        >
                          <template v-if="withTvaWithoutTva">
                            {{
                              fixDecimal(
                                item[`${slot.field_client_price}`] * stockUnits,
                                2
                              )
                            }}
                          </template>
                          <template v-else>
                            {{
                              fixDecimal(
                                calculateWithoutTva(
                                  item[`${slot.field_client_price}`] *
                                    stockUnits
                                ),
                                2
                              )
                            }}
                          </template>
                        </v-col>
                        <v-col
                          v-if="item[`${slot.field_stock}`]"
                          cols="12"
                          sm="4"
                          class="text-sm-left"
                          >{{ item[`${slot.field_delivery_date}`] }}</v-col
                        >
                      </v-row>
                    </td>
                  </template>
                </tr>
              </template>
            </v-data-table>

            <stock-order-details
              @changeDialog="changeDialog()"
              :showDialog="dialog"
              :rowData="selectedRowData"
              :columnData="selectedColumnData"
              :brands="brands"
            ></stock-order-details>

            <modify-stock
              v-if="modify_stock_dialog == true"
              @modifyClose="change_stock_detail_dialog_close"
              :rowData="selectedRowData"
              :header="header"
              :selectedFamily="familyArticle"
            ></modify-stock>

            <!-- <keep-alive>
                <add-article-model 
                  @changeDialog="changeDialog()"
                  :showFamilyArticle="true"
                  :familyArticles.sync="familyArticles"
                  :approvals.sync="approvals"
                  :multiselectsValues.sync="multiselectsValues"
                  :multiselects.sync="multiselects"
                  :statuses.sync="statuses"
                  :normalSearch.sync="normalSearch"
                  :tyreSeasons.sync="tyreSeasons"
                  :wheelTypes.sync="wheelTypes"
                  :tyreTypes.sync="tyreTypes"
                  :compSelectedArticleId.sync="parentSearchSelectedArticleId"
                  :internalStockOnly.sync="internalStockOnly"
                  :showDialog.sync="addArticleDialog"
                ></add-article-model>
              </keep-alive> -->

            <image-pop-up
              v-if="showImagePopUp"
              :show="showImagePopUp"
              @closePopUp="showImagePopUp = false"
              :imageUrl="articleImageBaseUrl + currentItem.identifier"
              :data="currentItem"
            ></image-pop-up>
            <v-btn
              @click="addArticleToStock()"
              class="d-none"
              id="add-article-to-stock"
            ></v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
tr:hover td {
  background-color: rgb(241, 241, 241) !important;
}
.v-list-item__content:hover {
  cursor: move;
}
.stock_or_reserver_col:hover {
  cursor: move;
  font-weight: 600;
}
.first-column-after-internal {
  border-left: 0px solid black !important;
}
td {
  border: 1px solid rgba(0, 0, 0, 0.178);
}
</style>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import StockOrderDetails from "@/components/Stock/StockOrderDetails";
import draggable from "vuedraggable";
import ModifyStock from "@/components/Stock/ModifyStock";
import ImagePopUp from "@/components/ImagePopUp";
import NewCombinedInput from "@/components/PNC/Stock/NewCombinedInput.vue";

export default {
  name: "SearchArticleWithStockModel",
  components: {
    "stock-order-details": StockOrderDetails,
    "modify-stock": ModifyStock,
    // "add-article-model":AddArticleModel,
    "image-pop-up": ImagePopUp,
    NewCombinedInput,
    draggable,
  },
  props: {
    showFamilyArticle: [Boolean],
    showDialog: [Number, Boolean],
    compSelectedArticleId: [String, Number, Object],
    compSelectedArticleSupplierDetail: [String, Number, Object],
    internalStockOnly: [Number, Boolean],
    familyArticleId: [Number, Object],
    normalSearch: [Boolean],
    returnItemObjectOrId: [String],
    multiselectsValuesPassed: [Array],
    multiselectsPassed: [Array],
  },
  async mounted() {
    this.$store.state.apiAbortControllerMap = {};
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setAddButtonRoute", "AddStock", "EditStock");
    this.setDefaultFamilyArticle();
    this.setDefaultFamilyClient();
  },
  data() {
    return {
      withProposedArticles: true,
        min_quantity_to_search: 1,
        supplierModel: false,
        checkAllSubHeader: false,
        defaultStockUnits: 4,
        supplierColumnVisibility: null,
        stockUnits: this.defaultStockUnits,
        withTvaWithoutTva: true,
        withOnlyInternalStock: false,
        booleanSelectedValues: [],
        showImagePopUp: false,
        currentItem: null,
        internalShowDialog: this.showDialog,
        articleImageBaseUrl: API_BASE_URL + "/article/image/",
        is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      parentSearchSelectedArticleId: null,
      isFirstTimeLoaded: 0,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isLoading: false,
      articles: [],
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      season: 0,
      widths: ["205", "255", "245", "145", "225"],
      width: "",
      heights: ["45", "55", "60", "65", "75", "85", "95"],
      height: "",
      rims: ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
      rim: "",
      lis: [
        "90",
        "91",
        "100",
        "120",
        "130",
        "140",
        "150",
        "160",
        "170",
        "180",
        "190",
      ],
      li: "",
      sis: ["H", "V", "W"],
      si: "",
      brands: [],
      brand: null,
      approval: { id: 10011001 },
      status: { id: 10001001 },
      familyClient: "",
      articleBooleanProperties: [],
      familyArticle: {},
      loadingFirstTime: 0,
      ifCurrentlyPaused: false,
      changingArticlePropertiesInProgress: false,
      customHeader: [
        // { text: this.$t('stocks.id'),                   class:"sticky-header",      align: 'start', sortable: false, width: 5,    value:'article_id'},
        {
          text: this.$t("stocks.image"),
          class: "sticky-header",
          align: "start",
          sortable: false,
          width: 5,
          value: "image",
        },
        {
          text: this.$t("stocks.article"),
          class: "sticky-header",
          align: "start",
          sortable: true,
          width: 200,
          value: "description",
        },
        {
          text: this.$t("stocks.tarrif"),
          class: "sticky-header",
          align: "end",
          sortable: false,
          width: 5,
          value: "tariff_price",
        },
        {
          text: this.$t("stocks.stock"),
          class: "sticky-header",
          align: "end",
          sortable: false,
          width: 5,
          value: "brand_stock",
        },
        {
          text: this.$t("stocks.res"),
          class: "sticky-header",
          align: "end",
          sortable: false,
          width: 5,
          value: "res",
        },
        {
          text: this.$t("stocks.current_stock"),
          class: "sticky-header",
          align: "end",
          sortable: false,
          width: 5,
          value: "brand_stock",
        },
        {
          text: this.$t("stocks.price"),
          class: "sticky-header",
          align: "end",
          sortable: false,
          width: 5,
          value: "price",
        },
      ],
      subHeader: [],
      tempSubHeader: [],
      mainHeader: [],
      customFilters: [],
      customFiltersExceptions: [1, 2], // these numbers represents property_id value example : property_id = 1
      token: this.$store.state.AccessToken,
      header: {},
      parentTableHeight: "",
      suppliers: [],
      supplier: "",
      subSlots: [],
      search: [],
      addArticleDialog: false,
      dialog: false,
      selectedRowData: {},
      selectedColumnData: {},
      conditions: [],
      condition: "",
      stock: "",
      reserve: "",
      deliveryDate: "",
      tvas: "",
      tva: "",
      clients: [],
      liveData: false,
      modify_stock_dialog: false,
      isPageLoaded: false,
      isDebounceCancelled: false,
      searchBrand: null,
      ifBrandSUpplierExists: false,
      suppliers_statuses: [],
      activeTab: 0,
      checkAllSubHeaderIntermediate: false,
    };
  },
  methods: {
    checkUncheckAllSubHeader() {
      if (this.tempSubHeader && this.tempSubHeader.length > 0) {
        this.tempSubHeader.forEach((element) => {
          if (element.is_read_only) {
            element.visible = true;
          } else {
            element.visible = this.checkAllSubHeader;
          }
        });
      }
    },
    markIntermediateStateAfterCheck() {
      setTimeout(() => {
        this.checkAllSubHeaderIntermediate = false;
        if (this.tempSubHeader && this.tempSubHeader.length > 0) {
          let firstVisible = this.tempSubHeader[0].visible;
          let ifAnyChanged = false;
          this.tempSubHeader.forEach((element) => {
            if (element.visible != firstVisible) {
              ifAnyChanged = true;
            }
          });
          this.$nextTick(() => {
            if (ifAnyChanged) {
              this.checkAllSubHeader = false;
              this.checkAllSubHeaderIntermediate = true;
            } else {
              this.checkAllSubHeaderIntermediate = false;
            }
          });
        }
      }, 200);
    },
    async selectItem(item, slot) {
      if (slot && [0, null, undefined].includes(slot?.supplier_detail?.id)) {
        let supplier_id = item.brand_supplier_id;
        // If not brand supplier id then take proprietary_supplier_id
        if (!item.brand_supplier_id && item.brand_id) {
          this.brands.forEach((brand) => {
            if (brand.id == item.brand_id) {
              supplier_id = brand.proprietary_supplier_id;
            }
          });
        }
        let supplier = await this.getSupplierById(supplier_id);
        slot.supplier_detail = supplier;
      }

      if (this.returnItemObjectOrId && this.returnItemObjectOrId == "object") {
        this.$emit("update:compSelectedArticleId", item);
        this.$emit("update:compSelectedArticleSupplierDetail", slot);
      } else {
        this.$emit("update:compSelectedArticleId", item.id);
        this.$emit("update:compSelectedArticleSupplierDetail", slot);
      }

      this.internalShowDialog = false;
    },
    async getSupplierById(id) {
      return new Promise((resolve) => {
        axios
          .get(API_BASE_URL + `/suppliers/${id}`, {
            headers: this.header,
          })
          .finally(() => {})
          .then((response) => {
            resolve(response.data.data);
          });
      });
    },
    toggleSupplierColumnVisibility(columnVisibility) {
      this.supplierColumnVisibility = columnVisibility;

      this.subHeader.forEach((item) =>
        this.toggleColumnVisibility(item, columnVisibility)
      );

      this.fetchData();
    },
    showImage(item) {
      this.currentItem = item;
      this.showImagePopUp = true;
    },
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.options.page = 1;
      this.fetchData();
    }, 2000),
    filterPropertiesExcept(customFilters) {
      let properties = [];
      if (
        this.familyArticle &&
        (this.familyArticle.parent_family_id == 1 || this.familyArticle.id == 1)
      ) {
        properties = ["property_02"];
      }

      let filteredProperties = [];
      filteredProperties = customFilters.filter((property) => {
        let found = false;
        properties.forEach((element) => {
          if (element == property.property_name) {
            found = true;
          }
        });

        return found ? false : property;
      });
      return filteredProperties;
    },
    getMultiSelectValuesFromCode() {
      this.customFilters.forEach((filter, filterindex) => {
        this.multiselectsValues[filterindex] = [];
      });
      this.multiselects.forEach((code) => {
        this.getCodes(parseInt(code), "codes").then((data) => {
          this.customFilters.forEach((filter, filterindex) => {
            if (filter.mapping_key == code) {
              this.multiselectsValues[filterindex] = data;
            }
          });
          this.multiselectsValues.push({});
          this.multiselectsValues.splice(-1);
        });
      });
    },
    /**
     * This Function is responsible to autofocus on next property field, if current's max length reached
     */
    handleAutoFocus(
      event,
      search_length,
      propertyName,
      dataType,
      forceToJumpOnProperty
    ) {
      let currentFieldLength = 0;
      if (dataType == "int" || dataType == "float") {
        currentFieldLength = (event.target.value + "").replace(".", "").length;
      } else {
        currentFieldLength = (event.target.value + "").length;
      }
      if (
        forceToJumpOnProperty &&
        search_length != null &&
        currentFieldLength == search_length
      ) {
        this.$refs[forceToJumpOnProperty][0].focus();
      } else if (
        propertyName.search("property") == 0 &&
        search_length != null &&
        currentFieldLength == search_length
      ) {
        let underscorePos = propertyName.search("_") + 1;
        let propertyNo = Number(propertyName.substr(underscorePos)) + 1;
        let nextPropertyName =
          "property_" + (propertyNo < 10 ? "0" : "") + propertyNo;
        this.$refs[nextPropertyName][0].focus();
      }
    },
    checkIfBrandExistsInProperties(customFilters) {
      let found = false;
      customFilters.forEach((property) => {
        if ("property_00" == property.property_name) {
          found = true;
        }
      });
      return found;
    },
    resetFurtherBoxesValues(focussedPropertyName) {
      let foundProperty = false;
      this.customFilters.forEach((filter, index) => {
        if (filter.property_name == focussedPropertyName) {
          foundProperty = true;
        }
        if (foundProperty) {
          if (filter.property_name == "property_00") {
            this.brand = null;
          }
          if (index == 1) {
            // if first property is focussed then we have to reset the season also
            this.season = 0;
          }
          this.customFilters[index].value = null;
        }
      });
    },
    addArticleToStock() {
      this.addArticleDialog = true;
      this.parentSearchSelectedArticleId = null;
    },
    changeDialog() {
      this.dialog = false;
    },
    change_stock_detail_dialog(item, tab = 0) {
      this.selectedRowData = item;
      this.modify_stock_dialog = true;
      this.activeTab = tab;
    },
    change_stock_detail_dialog_close(e) {
      if (e) {
        this.fetchData();
      }
      this.modify_stock_dialog = false;
    },
    refreshLiveData() {
      // this.fetchData();
    },
    updateBulkUserSupplierStatus(bulkData) {
      axios
        .post(
          API_BASE_URL + "/user_supplier_status",
          { bulk_update_data: bulkData },
          { headers: this.header }
        )
        .then(() => {})
        .catch((err) => {
          this.$toast.error(err.error);
        })
        .finally(() => {});
    },
    toggleColumnVisibility(item, visibilityStatus = null) {
      let selectedSupplierIdentifier = item.value.substr(
        0,
        item.value.indexOf("_")
      );
      this.tempSubHeader = this.subHeader;
      this.tempSubHeader.find((current_item, index) => {
        if (current_item.index == item.index) {
          this.tempSubHeader[index].visible =
            visibilityStatus ?? !current_item.visible;
          let storedFilteredSuppliers =
            localStorage.getItem("FILTERED_SUPPLIERS") ?? [];
          if (storedFilteredSuppliers.length != 0) {
            storedFilteredSuppliers = JSON.parse(storedFilteredSuppliers);
          }

          if (this.tempSubHeader[index].visible == false) {
            if (
              storedFilteredSuppliers.includes(
                this.tempSubHeader[index].value
              ) == false
            ) {
              storedFilteredSuppliers.push(this.tempSubHeader[index].value);
              localStorage.setItem(
                "FILTERED_SUPPLIERS",
                JSON.stringify(storedFilteredSuppliers)
              );
            }
          } else {
            let elementIndex = storedFilteredSuppliers.indexOf(
              this.tempSubHeader[index].value
            );
            if (elementIndex != -1) {
              storedFilteredSuppliers.splice(elementIndex, 1);
              localStorage.setItem(
                "FILTERED_SUPPLIERS",
                JSON.stringify(storedFilteredSuppliers)
              );
            }
          }
          this.suppliers.forEach((element) => {
            let convertedBackToIdentifier =
              selectedSupplierIdentifier == "brand"
                ? "BRAND"
                : selectedSupplierIdentifier.toUpperCase();
            if (element.identifier == convertedBackToIdentifier) {
              //   this.updateUserSupplierStatus(
              //     element.id,
              //     this.tempSubHeader[index].visible == false ? 0 : 1
              //   );
            }
          });
        }
      });
      let tempHeader = this.tempSubHeader;
      this.tempSubHeader = [];
      this.tempSubHeader = tempHeader;
    },
    updateHeadersListingOrder() {
      let updateData = [];
      this.tempSubHeader.forEach((element, index) => {
        if (element.supplier_detail) {
          updateData.push({
            supplier_id: element.supplier_detail.id,
            order: index + 1,
            is_active: element.visible,
          });
        }
      });
      this.subHeader = [...this.tempSubHeader];
      this.updateBulkUserSupplierStatus(updateData);
      this.fetchData();
    },
    applyPreviousFilters() {
      if (this.customFilters.length > 0) {
        this.resetFurtherBoxesValues(this.customFilters[0].property_name);
        this.search = null;
        // this.brands = [];
        this.brand = null;
      }
      let articleFilters = JSON.parse(
        localStorage.getItem("ARTICLE_MODEL_FILTERS")
      );
      if (articleFilters != null) {
        // this.normalSearch = articleFilters.normalSearch;
        this.page = articleFilters.page;
        this.itemsPerPage = articleFilters.items_per_page;
        this.sortBy = articleFilters.order_by
          ? articleFilters.order_by
          : "description";
        // this.sortDesc = articleFilters.order_direction ? articleFilters.order_direction : 'asc' ;
        this.approval.id = articleFilters.approval
          ? articleFilters.approval
          : this.approvals[1];
        this.status.id = articleFilters.status
          ? articleFilters.status
          : this.statuses[1];
        this.familyArticle = articleFilters.family
          ? this.getFamilyArticleById(articleFilters.family)
          : 0;
        this.familyClient = articleFilters.familyClient
          ? articleFilters.familyClient
          : this.setDefaultFamilyClient();
        this.season = articleFilters.season;
        this.searchEAN = articleFilters.identifier || articleFilters.search;

        this.articleId = articleFilters.id; // to search for specific article

        this.search = articleFilters.search;
        if (articleFilters.appliedFilters) {
          this.booleanSelectedValues = [];
          articleFilters.appliedFilters.forEach((element) => {
            let keyName = Object.keys(element)[0];
            this.customFilters.find((el, index) => {
              if (el.property_name == keyName) {
                this.customFilters[index].value = element[keyName];
                el.value = element[keyName];
                return el;
              }
            });
            this.articleBooleanProperties.find((el) => {
              if (el.property_name == keyName) {
                let foundBoolean = false;
                this.booleanSelectedValues.forEach((selectedBoolean) => {
                  if (selectedBoolean.property_id == el.property_id) {
                    foundBoolean = true;
                  }
                });
                if (!foundBoolean) {
                  this.booleanSelectedValues.push(el);
                }
                return el;
              }
            });
          });
        }
        // set brand
        this.brand = articleFilters.brand;

        // if(! this.searchEAN){
        this.fetchData();
        // }
      }
    },
    checkIfFetchingPossible() {
      let isPossible = false;
      this.ifCurrentlyPaused = false;
      let valueAssigned = 0;
      this.customFilters.forEach((element) => {
        if (element.value != null) {
          valueAssigned++;
        }
      });
      if (this.search != null && this.search.length != 0) {
        valueAssigned++;
      }
      if (valueAssigned != 0) {
        isPossible = true;
      } else {
        isPossible = false;
      }
      if (!isPossible) {
        this.ifCurrentlyPaused = true;
      }
      if (this.changingArticlePropertiesInProgress) {
        isPossible = false;
      }
      if (this.search != null && this.search.length > 0) {
        isPossible = true;
      }
      return isPossible;
    },
    updateLocalStorageValues(familyUpdated) {
      let filters = {
        familyClient: this.familyClient,
        normalSearch: this.normalSearch,
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortDesc,
        approval: this.approval.id,
        status: this.status.id,
        family: this.familyArticle ? this.familyArticle.id : 0,
        season: this.season,
        brand: familyUpdated ? null : this.brand != null ? this.brand : "",
        search: this.search,
        appliedFilters: familyUpdated
          ? []
          : this.getQueryStringFromDynamicallyCreatedFilters()[1],
        id: this.article ? this.article.id : null,
      };
      localStorage.setItem("ARTICLE_MODEL_FILTERS", JSON.stringify(filters));
    },
    fetchData(currentPage = null) {
      if (!currentPage) {
        this.options.page = 1;
        this.resetDataTable(currentPage);
      }
      this.articles = []; // remove the data from the table
      // if brands is empty then wait for getBrands to call
      if (this.checkIfFetchingPossible()) {
        this.updateLocalStorageValues();
        const { sortBy, sortDesc, itemsPerPage, page } = this.options;
        let suppliers = [];
        this.subHeader.forEach((element) => {
          if (element.visible) {
            suppliers.push(element.supplier_detail.id);
          }
        });
        this.isLoading = true;
        axios
          .get(
            API_BASE_URL +
              "/stocks" +
              "?page=" +
              (page || this.page) +
              "&items_per_page=" +
              ((itemsPerPage == -1 ? this.itemsPerPage : itemsPerPage) ||
                this.itemsPerPage) +
              "&order_by=" +
              (sortBy ? sortBy[0] : this.sortBy) +
              "&order_direction=" +
              (sortDesc
                ? !!sortDesc[0] || this.sortDesc
                  ? "desc"
                  : "asc"
                : "asc") +
              "&parent_also=true" +
              (suppliers.length > 0
                ? "&supplier_ids=" + suppliers.toString()
                : "") +
              (this.familyArticle ? "&family=" + this.familyArticle.id : "") +
              (!this.normalSearch && this.season
                ? "&season=" + this.season
                : "") +
              (this.brand ? "&brand=" + this.brand.id : "") +
              (this.condition ? "&condition_id=" + this.condition.id : "") +
              (this.supplier ? "&supplier_id=" + this.supplier.id : "") +
              (this.search
                ? "&search=" + encodeURIComponent(this.search)
                : "") +
              (this.currency ? "&currency_id=" + this.currency.id : "") +
              (this.stock ? "&min_quantity_to_search=" + this.stock : "") +
              (this.min_quantity_to_search
                ? "&min_quantity_to_search=" + this.min_quantity_to_search
                : "") +
              (this.tva ? "&tva_id=" + this.tva.id : "") +
              (this.familyClient
                ? "&familyClient=" +
                  (typeof this.familyClient == "object"
                    ? this.familyClient.id
                    : this.familyClient)
                : "") +
              (this.withOnlyInternalStock
                ? "&only_internal_stock=" + this.withOnlyInternalStock
                : "") +
              (this.withProposedArticles
                ? "&with_proposed_article=" + this.withProposedArticles
                : "") +
              this.getQueryStringFromDynamicallyCreatedFilters()[0],
            { headers: this.header,
                signal: this.abortController()
            }
          )
          .then(({ data }) => {
            this.articles = [];
            this.articles = data.data.map((element) => {
              if (element.property_01) {
                element.property_01 = this.$t(element.property_01);
              }
              if (element.property_02) {
                element.property_02 = this.$t(element.property_02);
              }
              return element;
            });
            this.articles.unshift([]); // add one extra row at beginning
            this.lastPage = data.last_page;
            this.page = data.current_page;
            this.totalItems = data.total;
            this.itemsPerPage = parseInt(data.per_page);
            this.getHeightOfTheParentTable();
          })
          .catch((err) => {
            console.log(err);
            this.parentTableHeight = this.getHeightOfTheParentTable();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    getHeightOfTheParentTable() {
      let height = 500;
      return height + "px";
    },
    setDefaultFamilyClient() {
      this.familyClients.forEach((element) => {
        if (element.is_default == 1) {
          this.familyClient = element.id;
        }
      });
    },
    getSupplierStatus() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/user_supplier_status", {
            headers: this.header,
          })
          .then(({ data }) => {
            this.suppliers_statuses = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          });
      });
    },
    getSupplier() {
      if (this.familyArticle) {
        axios
          .get(
            API_BASE_URL +
              "/suppliers?sortby=id&sorttype=asc" +
              (this.familyArticle
                ? "&family_id=" + this.familyArticle.id
                : "") +
              "&internal_supplier=true&parent_also=true",
            { headers: this.header }
          )
          .then(({ data }) => {
            let activeSuppliers = [];
            let storedFilteredSuppliers =
              localStorage.getItem("FILTERED_SUPPLIERS") ?? [];
            let orderedSuppliers = [];
            if (storedFilteredSuppliers.length != 0) {
              storedFilteredSuppliers = JSON.parse(storedFilteredSuppliers);
            }
            this.getSupplierStatus().then(() => {
              // Now Mark only the supplier which are in stored filtered suppliers
              this.suppliers_statuses.forEach((supplier_status) => {
                // if active then continue as by default all will be selected and we will push the supplier into localstorage that are not active
                if (supplier_status.is_active == 1) {
                  return;
                }
                data.data.forEach((supplier) => {
                  if (supplier.id == supplier_status.supplier_id) {
                    let supplierStoredFilterValue =
                        (supplier.identifier.toLowerCase() == 'int' ? 'brand' : supplier.identifier.toLowerCase()) + "_stock";
                    activeSuppliers.push(supplierStoredFilterValue);
                  }
                });
              });
              // Update Active Suppliers
            //   let brandWasAlreadyUnselected =
            //     storedFilteredSuppliers.includes("brand_stock");
            //   if (brandWasAlreadyUnselected) {
            //     activeSuppliers.push("brand_stock");
            //   }
              localStorage.setItem(
                "FILTERED_SUPPLIERS",
                JSON.stringify(activeSuppliers)
              );

              this.suppliers_statuses.forEach((supplier_status) => {
                data.data.forEach((element) => {
                  if (supplier_status.supplier_id == element.id) {
                    orderedSuppliers.push(element);
                  }
                });
              });
              if (orderedSuppliers.length == 0) {
                orderedSuppliers = data.data;
              }
              data.data.forEach((element) => {
                let added = false;
                orderedSuppliers.forEach((ordered) => {
                  if (ordered.id == element.id) {
                    added = true;
                  }
                });
                if (!added) {
                  orderedSuppliers.push(element);
                }
              });

              // We Just need to show the Broker Suppliers, so we have to filter those suppliers
              // We Just need to show the Broker Suppliers, so we have to filter those suppliers
              // Supplier With Broker have type_id=10191001
              let brokerSuppliers = orderedSuppliers.filter((element) => {
                if (element.type_id == 10191001) {
                  // if Supplier is Broker then push to brokerSuppliers
                  return element;
                }

                return false;
              });
              let brandSuppliers = orderedSuppliers.filter((element) => {
                if (element.type_id == 10191000) {
                  // if Supplier is Brand then set ifBrandSupplierExists
                  this.ifBrandSUpplierExists = true;
                }
                if (element.type_id == 10191000) {
                  // if Supplier is Brand then push to brandSuppliers
                  return element;
                }
                return false;
              });
              // Just Add Any First Brand Supplier to the array
              if (brandSuppliers && brandSuppliers.length > 0) {
                let brandSupplier = brandSuppliers[0];
                brandSupplier.name = "BRAND";
                brandSupplier.identifier = "BRAND";
                brandSupplier.id = 0;
                brokerSuppliers.unshift(brandSupplier);
              }
              this.suppliers = brokerSuppliers;
              this.updateSubHeaders();

              this.updateSubHeaders();
            });
          })
          .catch(function () {})
          .finally(() => {});
      }
    },
    getConditions() {
      return new Promise((resolve) => {
        this.getCodes("article_conditions", "stocks.conditions").then(
          (data) => {
            this.conditions = data;
            resolve();
          }
        );
      });
    },
    getTvas() {
      return new Promise((resolve) => {
        this.tvas = this.$store.state.tvas;
        resolve();
      });
    },
    updateSubHeaders() {
      this.subHeader = [];
      this.tempSubHeader = [];
      this.subSlots = [];
      this.mainHeader = [];
      let storedFilteredSuppliers =
        localStorage.getItem("FILTERED_SUPPLIERS") ?? [];
      this.suppliers.filter((supplier, index) => {
        this.subHeader.push({
          text:
            supplier.name == "BRAND"
              ? this.$t("stocks.brand").toUpperCase()
              : supplier.name,
          value:
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_stock",
          align: "start",
          width: 300,
          sortable: false,
          field_stock:
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_stock",
          field_delivery_date:
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_delivery_date",
          field_price:
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_price",
          field_client_price:
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_client_price",
          header_text: supplier.name == "BRAND" ? "BRAND" : supplier.name,
          supplier_detail: supplier,
          order: index,
          index: index,
          visible: storedFilteredSuppliers.includes(
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_stock"
          )
            ? false
            : true,
          is_read_only: this.getIfIsReadOnly(supplier),
        });
        this.subSlots.push({
          field_stock:
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_stock",
          field_delivery_date:
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_delivery_date",
          field_price: supplier.identifier.toLowerCase() + "_price",
          field_client_price:
            supplier.identifier.toLowerCase() + "_client_price",
          header_text: supplier.name == "BRAND" ? "BRAND" : supplier.name,
          supplier_detail: supplier,
          order: index,
          index: index,
          visible: storedFilteredSuppliers.includes(
            (supplier.identifier == "BRAND"
              ? "brand"
              : supplier.identifier.toLowerCase()) + "_stock"
          )
            ? false
            : true,
          is_read_only: this.getIfIsReadOnly(supplier),
        });
        return true;
      });

      this.mainHeader = this.customHeader.concat(this.subHeader);
    },
    getIfIsReadOnly(item) {
      let isReadOnly = false;
      if (item.companySupplier && item.companySupplier.length > 0) {
        item.companySupplier.forEach((compSupp) => {
          isReadOnly = compSupp.is_read_only;
        });
      }
      return isReadOnly;
    },
    getFamilyArticleById(id) {
      let familyArticle = 0;
      this.familyArticles.forEach((element) => {
        if (element.id == id) {
          familyArticle = element;
        }
      });
      return familyArticle;
    },
    setDefaultFamilyArticle() {
      let defaultFamilyArticle = null;
      this.familyArticles.forEach((element) => {
        if (element.is_default) {
          defaultFamilyArticle = element;
        }
      });
      if (!defaultFamilyArticle) {
        this.familyArticle = this.familyArticles[0];
      } else {
        this.familyArticle = defaultFamilyArticle;
      }
      this.updateLocalStorageValues();
    },
    editItem() {
      // this.$store.commit('setFormMode', EDIT_MODE);
      // this.$router.push('/articles/edit/' + item.id).catch(() => {});
    },
    deleteItem() {},
    changeArticleProperties() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });

      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }
      // hard coding for family : todo later make it dynamic
      if (currentFamilyArticle.parent_family_id === 2) {
        this.season = "";
      }

      this.articleBooleanProperties = currentFamilyArticle.properties.filter(
        (property) => {
          if (property.cast == "boolean" && property.in_filter == 1) {
            property.name = property.translated
              ? property.name
              : this.$t("articles.properties." + property.name);
            property.property_name =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);
            property.translated = true;
            return property;
          }
          return false;
        }
      );
    },
    setCustomDataTableHeaders() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });
      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }
      if (this.familyArticle && this.familyArticle.parent_family_id == 1) {
        this.customHeader = [
          // { text: this.$t('stocks.id'),                   class:"sticky-header-tyres",       align: 'start', sortable: false, width: 80, value: 'article_id'},
          {
            text: this.$t("stocks.image"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: false,
            width: 20,
            value: "image",
          },
          {
            text: this.$t("stocks.type_short"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: false,
            width: 20,
            value: "type",
          },
          {
            text: this.$t("stocks.season_short"),
            class: "sticky-header-tyres",
            align: "center",
            sortable: false,
            width: 20,
            value: "season",
          },
          {
            text: this.$t("stocks.article"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: true,
            width: 600,
            value: "description",
          },
          {
            text: this.$t("stocks.tarrif"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: false,
            width: 60,
            value: "tariff_price",
          },
          {
            text: this.$t("stocks.internal_stock"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: false,
            width: 350,
            value: "brand_stock",
          },
        ];
      } else if (
        this.familyArticle &&
        this.familyArticle.parent_family_id == 2
      ) {
        this.customHeader = [
          // { text: this.$t('stocks.id'),                   class:"sticky-header-tyres",       align: 'start', sortable: false, width: 80, value: 'article_id'},
          {
            text: this.$t("stocks.image"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: false,
            width: 80,
            value: "image",
          },
          {
            text: this.$t("stocks.article"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: true,
            width: 600,
            value: "description",
          },
          {
            text: this.$t("stocks.tarrif"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: false,
            width: 60,
            value: "tariff_price",
          },
          {
            text: this.$t("stocks.internal_stock"),
            class: "sticky-header-tyres",
            align: "start",
            sortable: false,
            width: 300,
            value: "brand_stock",
          },
        ];
      } else if (this.familyArticle && this.familyArticles.id == 3) {
        this.customHeader = [
          // { text: this.$t('stocks.id'),                   class:"sticky-header",       align: 'start', sortable: false, width: 80,    value: 'article_id'},
          {
            text: this.$t("stocks.image"),
            class: "sticky-header",
            align: "start",
            sortable: false,
            width: 80,
            value: "image",
          },
          {
            text: this.$t("stocks.article"),
            class: "sticky-header",
            align: "start",
            sortable: true,
            width: 600,
            value: "description",
          },
          {
            text: this.$t("stocks.tarrif"),
            class: "sticky-header",
            align: "start",
            sortable: false,
            width: 60,
            value: "tariff_price",
          },
          {
            text: this.$t("stocks.internal_stock"),
            class: "sticky-header",
            align: "start",
            sortable: false,
            width: 300,
            value: "brand_stock",
          },
        ];
      } else {
        this.customHeader = [
          // { text: this.$t('articles.id'),                 class:"sticky-header",value:'id', align:'start', sortable: false, width: 80},
          {
            text: this.$t("stocks.image"),
            class: "sticky-header",
            align: "start",
            sortable: false,
            width: 80,
            value: "image",
          },
          {
            text: this.$t("stocks.article"),
            class: "sticky-header",
            align: "start",
            sortable: true,
            width: 350,
            value: "description",
          },
          {
            text: this.$t("stocks.tarrif"),
            class: "sticky-header",
            align: "start",
            sortable: false,
            width: 60,
            value: "tariff_price",
          },
          {
            text: this.$t("stocks.internal_stock"),
            class: "sticky-header",
            align: "start",
            sortable: false,
            width: 300,
            value: "brand_stock",
          },
        ];
      }

      this.renderedFilters = [];
      // currentFamilyArticle.properties.filter((property) => {
      //     if( property.in_grid == 1) {
      //       this.customHeader.push({
      //         text:this.$t('articles.properties.' + property.name),
      //         value: 'property_'+ (property.property_id < 10 ? 0 + property.property_id.toString() : property.property_id) ,
      //         align:'start',
      //         sortable:true,
      //       });
      //       return true;
      //     }
      //   });
      // this.modifyOrderingOfCustomHeaders();
    },
    /**
     * This function is used to modify the ordering of Headers in Listing
     * Note : For ordering you must know the column name
     */
    // modifyOrderingOfCustomHeaders(){
    //   let sortedArray = [];
    //   let nonSortedArray = [];
    //   let sortingArray = ["ID","Img","Type","Season","Name","EAN","Width","Height","Rim","LI","SI","Tariff Price"];
    //   let processedArray = [];
    //   sortingArray.forEach((element, indexNo) => {
    //     for (const [key, value] of Object.entries(this.customHeader)) {
    //       let added = false;

    //       if(element == value.text  ){
    //         sortedArray.push(value);
    //         processedArray.push(value.text);
    //         added = true;
    //       }
    //       if(added == false && processedArray.find(element => element == value.text) == undefined && sortingArray.length == indexNo + 1){
    //         nonSortedArray.push(value);
    //       }
    //     }
    //   });
    //   this.customHeader = sortedArray.concat(nonSortedArray);
    // },
    setDynamicFilterBoxes() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });
      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }
      this.customFilters = [];
      this.multiselects = [];

      this.$nextTick(() => {
        this.applyPreviousFilters();
      });
      currentFamilyArticle.properties.filter((property) => {
        if (property.in_filter == 1) {
          let indexOfClass = (property.mapping ?? "").indexOf("class=");
          let mappingKey = null;
          if (indexOfClass != -1) {
            mappingKey = property.mapping.substr(indexOfClass + 6, 4) + "";
            let propertyName =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);

            if (this.familyArticle.properties) {
              this.familyArticle.properties[propertyName] = parseInt(
                this.familyArticle.properties[propertyName]
              );
            }
          }
          if (mappingKey) {
            this.multiselects.push(mappingKey);
          }
          if (property.cast != "boolean") {
            this.customFilters.push({
              text: this.$t("articles.properties." + property.name),
              align: "start",
              sortable: true,
              property_name:
                "property_" +
                (property.property_id < 10
                  ? 0 + property.property_id.toString()
                  : property.property_id),
              property_id: property.property_id,
              column_name: property.name,
              value: null,
              property_type: property.cast,
              mapping: property.mapping ?? null,
              mapping_key: mappingKey ?? null,
              keytype: property.cast,
              search_length: property.search_length,
            });
          }
          return true;
        }
        return false;
      });
      if (this.isFirstTimeLoaded == 0) {
        // this.applyPreviousFilters();
        this.isFirstTimeLoaded++;
      }
      this.changingArticlePropertiesInProgress = false;
      this.$nextTick(() => {
        this.isPageLoaded = true;
      });
    },
    getQueryStringFromDynamicallyCreatedFilters() {
      let queryString = "";
      let appliedFilters = [];
      this.customFilters.forEach((filter) => {
        if (filter.value) {
          queryString += "&" + filter.property_name + "=" + filter.value;
          appliedFilters.push({
            [filter.property_name]: filter.value,
          });
        }
      });
      this.booleanSelectedValues.forEach((filter) => {
        queryString += "&" + filter.property_name + "=" + 1; // 1 means true
        appliedFilters.push({
          [filter.property_name]: 1, // 1 means true
        });
      });
      return [queryString, appliedFilters];
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    getBrands() {
      if (this.familyArticle && this.familyArticle.id) {
        if (
          this.cachedData["brands"][this.familyArticle.id] &&
          this.cachedData["brands"][this.familyArticle.id].length > 0
        ) {
          this.brands = this.cachedData["brands"][this.familyArticle.id];
        } else {
          axios
            .get(
              API_BASE_URL +
                "/brands?family=" +
                this.familyArticle.id +
                "&parent_also=true&items_per_page=-1",
              { headers: this.header }
            )
            .then(({ data }) => {
              this.brands = data.data;
              this.cachedData["brands"][this.familyArticle.id] = this.brands;
            })
            .catch(function (error) {
              console.log("an error occured " + error);
            })
            .finally(() => {});
        }
      }
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy == undefined ? "id" : sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
      this.fetchData();
    },
  },
  computed: {
    unCheckedSupplierFilters() {
      let unCheckedCount = 0;
      this.subHeader.forEach((element) => {
        if (element.supplier_detail && !element.visible) {
          unCheckedCount++;
        }
      });
      return unCheckedCount;
    },
  },
  watch: {
    supplierModel(val){
        // Update order on closing the dialog
        if(val == false){
            this.updateHeadersListingOrder();
        }
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
        if (val == true) {
          this.applyPreviousFilters();
          this.getSupplier();
        }
      },
    },
    internalShowDialog: {
      handler: function () {
        this.$emit("update:showDialog", this.internalShowDialog);
        this.updateSubHeaders();
      },
    },
    withProposedArticles: {
      handler: function () {
        this.fetchData();
      },
    },
    withOnlyInternalStock: {
      handler: function () {
        this.fetchData();
      },
    },
    familyClients: {
      handler: function () {
        if (this.familyClients.length > 0) {
          this.setDefaultFamilyClient();
        }
      },
    },
    normalSearch: {
      handler: function (val) {
        this.customFilters.forEach((element) => {
          element.value = null;
        });
        this.brand = null;
        if (val == false) {
          this.search = null;
        }
      },
    },
    subHeader: {
      handler: function () {
        this.subSlots = this.subHeader.filter((item) => {
          return item.visible;
        });
        let selected = this.subHeader.concat([]);
        // selected.sort(function(a,b){
        //   return a.order > b.order;
        // });
        selected = selected.filter((item) => {
          return item.visible;
        });

        this.mainHeader = this.customHeader.concat(selected);

        // Check if any supplier is selected
        const unselectedSupplier = this.subHeader.find(
          (item) => item.visible != true
        );

        this.supplierColumnVisibility =
          unselectedSupplier === undefined ? true : false;
        this.tempSubHeader = [...this.subHeader];
        this.markIntermediateStateAfterCheck();
      },
    },

    parentSearchSelectedArticleId(val) {
      if (val) {
        this.dialog = false;

        axios
          .post(API_BASE_URL + "/stocks/add/" + val, [], {
            headers: this.header,
          })
          .then(() => {
            this.$toast.success(this.$t("stocks.article_added_to_stock"));
            this.fetchData();
          })
          .catch((err) => {
            this.$toast.error(err.error);
          })
          .finally(() => {});
      }
    },
    familyArticles: {
      handler: function () {
        this.setDefaultFamilyArticle();
      },
    },
    familyArticle: {
      handler: function (val) {
        this.search = null;

        this.changingArticlePropertiesInProgress = true;
        this.brand = null;
        this.brands = [];
        this.suppliers = [];

        if (
          this.familyArticle &&
          this.familyArticle.parent_family_properties.length > 0
        ) {
          this.familyArticle.properties =
            this.familyArticle.parent_family_properties;
        }
        if (this.loadingFirstTime != 0) {
          this.fetchData();
        }
        this.getSupplier();
        this.changeArticleProperties();
        this.setCustomDataTableHeaders();
        this.setDynamicFilterBoxes();

        this.getMultiSelectValuesFromCode();
        this.getBrands();
        if (val) {
          this.stockUnits = val.default_quantity
            ? val.default_quantity > 0
              ? val.default_quantity
              : this.defaultStockUnits
            : this.defaultStockUnits;
        }
      },
    },
    familyClient: {
      handler: function () {
        // this.fetchData();
      },
    },
    // brand:  {
    //   handler: function(){
    //     this.fetchData();
    //   },
    // },
    season: {
      handler: function () {
        if (this.loadingFirstTime != 0) {
          this.fetchData();
        }
        this.loadingFirstTime++;
      },
    },
    status: {
      handler: function () {
        this.fetchData();
      },
    },
    approval: {
      handler: function () {
        this.fetchData();
      },
    },
    condition: {
      handler: function () {
        this.fetchData();
      },
    },
    supplier: {
      handler: function () {
        this.fetchData();
      },
    },
    currency: {
      handler: function () {
        this.fetchData();
      },
    },
    tva: {
      handler: function () {
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped>
.to-upper input {
  text-transform: uppercase !important;
}
</style>
