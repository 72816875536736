<template>
  <v-dialog
    v-model="show_dialog"
    @click:outside="closeUpdater()"
    @keydown.esc="closeUpdater()"
    persistent
    max-width="700px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t("bulk_updater.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0">
          <v-row class="mb-n8">
            <v-col>
              <p class="filter">
                <strong>
                  {{ $t("bulk_updater.filter") }}:
                  <span>{{ getAppliedFilters() }}</span>
                </strong>
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <p class="orderSubTitle" style="color: red">
                <strong>
                  <u>
                    <span style="">{{ $t("bulk_updater.warning") }}: </span>
                  </u> </strong
                ><br />
                {{ $t("bulk_updater.warning_note_1") }} <br />
                {{ $t("bulk_updater.warning_note_2") }}
              </p>
            </v-col>
          </v-row>
          <v-row class="">
            <v-col>
              <td v-on:keydown.exact="checkIfMinusPressed($event, 'amount')">
                <custom-money-input
                  :autocomplete="autocompleteValueLocal"
                  class="pt-0 to-upper right-aligned-input shrink"
                  hide-details="auto"
                  :label="$t('bulk_updater.amount')"
                  dense
                  tabindex="3"
                  ref="tableUnitPrice"
                  v-model="amount"
                  v-bind:properties="{
                    prefix: currencySymbol,
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    placeholder: getDecimalNumber() == 2 ? '0.0000' : '0.0000',
                    dense: true,
                    hideDetails: 'auto',
                  }"
                  v-bind:options="{
                    locale: userLanguageId,
                    length: 4,
                    precision: 4,
                    empty: getDecimalNumber() == 4 ? 0.0 : 0.0,
                  }"
                />
              </td>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn color="grey lighten-2" @click="closeUpdater()">
          {{ $t("bulk_updater.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn
          color="success"
          @click="saveUpdater()"
          :disabled="
            amount == 0 || updateInProgress || getAppliedFilters().length == 0
          "
        >
          <v-progress-circular
            v-if="updateInProgress"
            indeterminate
            :size="15"
            :width="1"
            color="grey"
          ></v-progress-circular>
          {{ $t("bulk_updater.save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.right-aligned-input >>> input {
  text-align: right;
}
.text-red >>> input {
  color: red !important;
}
</style>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  validations: {},
  components: {
    "custom-money-input": CutsomMoneyInput,
  },
  props: {
    search: {
      Object,
    },
    customFilters: {
      Array,
    },
    familyArticle: {
      Object,
    },
    brand: {
      Object,
    },
    status: {
      Object,
    },
    approval: {
      Object,
    },
    season: {
      Object,
    },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      show_dialog: true,
      autocompleteValueLocal: "off",
      modifyDots: false,
      amount: 0,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      token: this.$store.state.AccessToken,
      updateInProgress: false,
    };
  },
  watch: {
    currencySymbol() {
      return this.userCurrencySymbol;
    },
  },
  methods: {
    checkIfMinusPressed(event, model_name) {
      if (/^[/-]$/i.test(event.key)) {
        if (model_name == "amount") {
          this.amount = this.amount * -1;
        }
      }
    },
    getAppliedFilters() {
      let filters = "";
      if (this.search) {
        filters += this.search.toUpperCase() + " / ";
      }
      if (this.familyArticle) {
        filters += this.familyArticle
          ? this.familyArticle.name.toUpperCase() + " / "
          : "";
      }
      if (this.season) {
        let season = this.season;
        if (this.season == 0) {
          season = this.$t("codes.no_season");
        } else if (this.season == 10111002) {
          season = this.$t("codes.summer");
        } else if (this.season == 10111001) {
          season = this.$t("codes.winter");
        } else if (this.season == 10111003) {
          season = this.$t("codes.all_season");
        }

        filters += season.toUpperCase() + " / ";
      }
      if (this.customFilters && this.customFilters.length > 0) {
        this.customFilters.forEach((element) => {
          if (element && element.value) {
            filters += element.value.toUpperCase() + " / ";
          }
        });
      }
      if (this.brand) {
        filters += this.brand ? this.brand.name.toUpperCase() + " / " : "";
      }
      if (this.status) {
        let status = this.getStatus();
        filters += status ? status.text.toUpperCase() + " / " : "";
      }
      if (this.approval) {
        let approval = this.getApproval();
        filters += approval ? approval.text.toUpperCase() + " / " : "";
      }
      return filters.slice(0, -2);
    },
    mounted() {
      this.autocompleteValueLocal = this.autocompleteValue();
      this.header = { Authorization: "Bearer " + this.token };
    },
    getStatus() {
      let status;
      if (this.status) {
        if (!this.status.text) {
          this.statuses.forEach((element) => {
            if (this.status.id == element.id) {
              status = element;
            }
          });
        }
      }
      return status;
    },
    getApproval() {
      let approval;
      if (this.approval) {
        if (!this.approval.text) {
          this.approvals.forEach((element) => {
            if (this.approval.id == element.id) {
              approval = element;
            }
          });
        }
      }
      return approval;
    },
    showModifyDotDialog() {
      this.modifyDots = true;
    },
    changeModifyArticleDialog() {
      this.modifyDots = !this.modifyDots;
    },
    closeUpdater() {
      this.show_dialog = false;
      this.$emit("modifyClose", false);
    },
    makeSumbitData() {
      let data = {};
      if (this.search) {
        data.search = this.search.toUpperCase();
      }
      if (this.familyArticle) {
        data.family = this.familyArticle ? this.familyArticle.id : "";
      }
      if (
        this.season &&
        this.familyArticle != null &&
        (this.familyArticle.parent_family_id == 1 ||
          this.familyArticle.id == 1 ||
          this.familyArticle.parent_family_id == 2 ||
          this.familyArticle.id == 2)
      ) {
        data.season = this.season;
      }
      if (this.customFilters && this.customFilters.length > 0) {
        this.customFilters.forEach((element) => {
          if (element && element.value) {
            data[`${element.property_name}`] = parseInt(element.value);
          }
        });
      }
      if (this.brand) {
        data.brand = this.brand ? this.brand.id : null;
      }
      if (
        this.status &&
        this.familyArticle != null &&
        (this.familyArticle.parent_family_id == 1 ||
          this.familyArticle.parent_family_id == 2)
      ) {
        let status = this.getStatus();
        data.status = status ? status.id : null;
      }
      if (
        this.approval &&
        this.familyArticle != null &&
        (this.familyArticle.parent_family_id == 1 ||
          this.familyArticle.parent_family_id == 2)
      ) {
        let approval = this.getApproval();
        data.approval = approval ? approval.id : null;
      }
      data.price = this.amount;
      data.parent_also = true;
      data.featchCompanyArticles = 1;
      return data;
    },
    saveUpdater() {
      let data = this.makeSumbitData();
      this.updateInProgress = true;
      axios
        .put(API_BASE_URL + "/article_pricing", data, { headers: this.header })
        .then((response) => {
          if (response.status === 200) {
            // this.$router.push("/family-clients").catch(() => {});
          } else {
            alert("ERROR on saving. Please try again later");
          }
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.updateInProgress = false;
          this.show_dialog = false;
          this.$emit("modifyClose", true);
        });
    },
  },
};
</script>
<style scoped>
.filter {
  color: black;
  font-size: 20px;
}
.filter span {
  color: #1976d2;
}
</style>
