<template>
  <v-data-table
    fixed-header
    :footer-props="{
      'items-per-page-options': dataTableOptions,
    }"
    :hide-default-footer="true"
    :headers="headers"
    :items="expeditions"
    :options="options"
    :loading-text="this.$t('loading')"
    class="elevation-0"
    :items-per-page="itemsPerPage"
    item-key="identifier"
    style="width: 100%"
    mustSort
  >
    <template v-slot:item="{ item }">
      <tr class="itemrow" :class="setExpeditionRowColorClass(item.status)">
        <!-- Identifier -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row>
            <TextComponentWithExtraIcons
              :value="item.identifier"
            ></TextComponentWithExtraIcons>
          </v-row>
          <v-row> </v-row>
        </td>
        <!-- Expeditor/Transporter -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row class="expeditorline">
            <template v-if="item.expeditor">
              <template v-if="item.expeditor.name.length > 25">
                <v-tooltip bottom z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ item.expeditor.name.substr(0, 25) }}...
                    </span>
                  </template>
                  {{ item.expeditor.name }}
                </v-tooltip>
              </template>
              <template v-else>
                {{ item.expeditor.name }}
              </template>
              <span v-if="item.expeditor.tva">
                ({{ item.expeditor.tva }})
              </span>
            </template>
          </v-row>
          <v-row class="transporterline">
            <template v-if="item.transporter">
              <template v-if="item.transporter.name.length > 25">
                <v-tooltip bottom z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ item.transporter.name.substr(0, 25) }}...
                    </span>
                  </template>
                  {{ item.transporter.name }}
                </v-tooltip>
              </template>
              <template v-else>
                {{ item.transporter.name }}
              </template>
              <span v-if="item.transporter.tva">
                ({{ item.transporter.tva }})
              </span>
            </template>
          </v-row>
        </td>
        <!-- Charging/Discharging Address -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row class="expeditorline">
            <template
              v-if="
                item.chargings &&
                item.chargings.length > 0 &&
                item.chargings[0].address
              "
            >
              <!-- {{ item.chargings[0].address.human_readable_address }} -->
              <template>
                <v-tooltip bottom z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{
                        computeExpeditionListingAddressLabel(
                          item.chargings[0].address
                        )
                      }}
                    </span>
                  </template>
                  {{ item.chargings[0].address.human_readable_address }}
                </v-tooltip>
              </template>
            </template>
          </v-row>
          <v-row class="transporterline">
            <template
              v-if="
                item.dischargings &&
                item.dischargings.length > 0 &&
                item.dischargings[0].address
              "
            >
              <template>
                <v-tooltip bottom z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{
                        computeExpeditionListingAddressLabel(
                          item.dischargings[0].address
                        )
                      }}
                    </span>
                  </template>
                  {{ item.dischargings[0].address.human_readable_address }}
                </v-tooltip>
              </template>
            </template>
          </v-row>
        </td>
        <!-- Product to Charge  -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row>
            <template v-if="item.chargings && item.chargings.length > 0">
              <template
                v-if="item.chargings[0].article.original_name.length > 25"
              >
                <v-tooltip bottom z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{
                        item.chargings[0].article.original_name.substr(0, 25)
                      }}...
                    </span>
                  </template>
                  {{ item.chargings[0].article.original_name }}
                </v-tooltip>
              </template>
              <template v-else>
                {{ item.chargings[0].article.original_name }}
              </template>
              ({{ item.chargings[0].quantity }})
            </template>
          </v-row>
          <v-row> </v-row>
        </td>
        <!-- Truck/Trailer Registration Number -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row class="expeditorline">
            {{ item.truck_registration_number }}
          </v-row>
          <v-row class="transporterline">
            {{ item.trolly_registration_number }}
          </v-row>
        </td>
        <!-- Total Bill/Bill Due Date -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row class="expeditorline">
            <template v-if="item.expeditor_price">
              {{ currencySymbol }} {{ item.expeditor_price }}
            </template>
          </v-row>
          <v-row class="transporterline">
            <template v-if="item.transport_price">
              {{ currencySymbol }} {{ item.transport_price }}
            </template>
            <!-- Due Date -->
            <!-- <template v-if="item.order && item.order.due_date">
                            {{ item.order.due_date.substr(0, 10) }}
                        </template> -->
          </v-row>
        </td>
        <!-- Created By -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row>
            <template v-if="item.user">
              {{ item.user.name }}
            </template>
          </v-row>
          <v-row> </v-row>
        </td>
        <!-- Status -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row>
            <template v-if="item.status && item.status.key">
              {{ $t("expedition_statuses." + item.status.key) }}
            </template>
          </v-row>
          <v-row> </v-row>
        </td>
        <!-- Documents -->
        <td>
          <template v-if="item.attachments && item.attachments.length > 0">
            <div>
              <ul class="scroll pl-0" v-if="item.attachments.length">
                <li
                  class="list-item"
                  v-for="file in item.attachments"
                  :key="file"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon
                          class="attachment_icon_color"
                          @click="viewAttachment(file)"
                          right
                          medium
                        >
                          {{ findFileIconWithDescription(file) }}
                        </v-icon>
                      </div>
                    </template>
                    {{ file.title }}
                  </v-tooltip>
                </li>
              </ul>
            </div>
          </template>
        </td>
        <!-- Note -->
        <td @click="editExpedition(item)" class="expeditionitemtd">
          <v-row>
            <template v-if="item.note">
              <v-tooltip right z-index="9999">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="warning" v-bind="attrs" v-on="on">
                    mdi-note-alert
                  </v-icon>
                </template>
                {{ item.note }}
              </v-tooltip>
            </template>
          </v-row>
          <v-row> </v-row>
        </td>
      </tr>
    </template>
    <template slot="no-data">
      <div></div>
    </template>
  </v-data-table>
</template>
<script>
import axios from "@/plugins/axios";
import { EDIT_MODE } from "@/config";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
export default {
  props: {
    expeditionId: [Number],
  },
  data() {
    return {
      autocompleteValueLocal: "off",
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      isLoading: true,
      totalItems: 0,
      lastPage: 1,
      sortBy: "id",
      sortDesc: true,
      sortOrd: "ASC",
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      expeditions: [],
      search: "",
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },

      search_date: null,
      menu_search_date: false,
      expeditionStatuses: [],
      expeditionStatus: null,
      isMountingPage: true,
    };
  },
  mounted() {
    if (this.expeditionId) {
      this.fetchExpeditionDetail(this.expeditionId);
    }
  },
  methods: {
    fetchExpeditionDetail(id) {
      axios
        .get(API_BASE_URL + "/expeditions?id=" + id, { headers: this.header })
        .then(({ data }) => {
          this.expeditions = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkAndApplyLocalStorageValues() {
      return new Promise((resolve) => {
        let localStorageExpeditionFilterValues = JSON.parse(
          localStorage.getItem("EXPEDITION_LISTING_FILTERS") ?? "[]"
        );
        if (localStorageExpeditionFilterValues.status_id) {
          this.expeditionStatus = localStorageExpeditionFilterValues.status_id;
        }
        if (localStorageExpeditionFilterValues.search_date) {
          this.search_date = localStorageExpeditionFilterValues.search_date;
        }
        if (localStorageExpeditionFilterValues.search) {
          this.search = localStorageExpeditionFilterValues.search;
        }
        resolve();
      });
    },
    viewAttachment(file) {
      let url = file.signed_url;
      let fileName = file.title;
      this.setPreviewData(url, fileName, true, file.mime_type);
    },
    makeExpeditionBill() {
      let order_id = null;
      let selectedExpeditionsForBill = this.updateSelectedExpeditionsForBill();
      selectedExpeditionsForBill.forEach((element) => {
        if (element.order_id) {
          order_id = element.order_id;
        }
      });
      if (order_id) {
        // Already Set: setSelectedExpeditionIdsForBilling using computed property
        this.$store.commit("seteditId", order_id);
        this.$router.push("/billing/edit");
      } else {
        // Already Set: setSelectedExpeditionIdsForBilling using computed property
        this.$router.push("/billing/add");
      }
    },
    checkRelatedExpeditor(item, is_already_selected) {
      if (is_already_selected) {
        // If we are clicking on Non checked item then select releated ones
        // First De-Select All the expedition
        this.expeditions.forEach((expedition) => {
          expedition.is_selected = false;
        });
        // Select the related Expeditions
        this.expeditions.forEach((expedition) => {
          if (
            item.expeditor_id == expedition.expeditor_id &&
            expedition.status.key == "new" &&
            expedition.order_id == null
          ) {
            expedition.is_selected = is_already_selected;
          }
        });
      } else {
        // Just Unselect the clicked Item
        this.expeditions.forEach((expedition) => {
          if (item.id == expedition.id) {
            expedition.is_selected = is_already_selected;
          }
        });
      }

      this.updateSelectedExpeditionsForBill();
    },
    updateSelectedExpeditionsForBill() {
      let selExp = this.expeditions.filter((element) => {
        return element.is_selected;
      });
      this.$store.commit("setSelectedExpeditionIdsForBilling", selExp);

      return selExp;
    },
    async getExpeditionStatuses() {
      if (this.expeditionStatuses.length == 0) {
        await this.getCodes("expedition_statuses", "expedition_statuses").then(
          (data) => {
            this.expeditionStatuses = data;
          }
        );
      }
    },
    editExpedition(item) {
      this.$store.commit("seteditId", item.id);
      this.$router.push("/expedition/edit").catch(() => {});
    },
    fetchData(currentPage = null) {
      this.resetDataTable(currentPage);
      this.isLoading = true;
      axios
        .get(
          API_BASE_URL +
            "/expeditions?page=" +
            this.page +
            "&items_per_page=" +
            this.itemsPerPage +
            (this.search ? "&search=" + encodeURIComponent(this.search) : "") +
            (this.search_date ? "&date=" + this.search_date : "") +
            (this.expeditionStatus
              ? "&status_id=" + this.expeditionStatus
              : "") +
            "&sortby=" +
            this.sortBy +
            "&sorttype=" +
            this.sortOrd,
          {
            headers: this.header,
          }
        )
        .then(({ data }) => {
          data.data.forEach((element) => {
            element.is_selected = false;
          });
          this.expeditions = data.data;
          this.lastPage = data.last_page;
          this.totalItems = data.total;
          this.page = data.current_page;
          // this.itemsPerPage = parseInt(data.meta.per_page);
          // this.sortBy = null;
          // this.sortDesc = null;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    editItem(item) {
      this.$store.commit("seteditId", item.id);
      this.$store.commit("setFormMode", EDIT_MODE);
      this.$router.push("/client/edit").catch(() => {});
    },
    deleteItem() {
      console.log("Delete Item");
    },
  },
  computed: {
    currencySymbol() {
      let symbol = null;
      if (this.$store.state.topCurrencyType) {
        symbol = this.parseSymbol(
          this.$store.state.topCurrencyType.symbol ?? ""
        );
        return symbol;
      }
      return symbol ?? "";
    },
    headers() {
      return [
        {
          text: this.$t("expeditions.expedition_number"),
          value: "expedition_number",
          sortable: false,
          width: 150,
        },
        {
          text: this.$t("expeditions.expeditor/transporter"),
          value: "name",
          sortable: false,
          width: 300,
        },
        {
          text: this.$t("expeditions.address"),
          value: "address",
          sortable: false,
          width: 200,
        },
        {
          text: this.$t("expeditions.products"),
          value: "products",
          sortable: false,
        },
        {
          text: this.$t("expeditions.transport"),
          value: "transport",
          sortable: false,
        },
        {
          text: this.$t("expeditions.bill_details"),
          value: "created_by",
          sortable: false,
        },
        {
          text: this.$t("expeditions.created_by"),
          value: "created_by",
          sortable: false,
        },
        {
          text: this.$t("expeditions.status"),
          value: "status",
          sortable: false,
        },
        {
          text: this.$t("expeditions.documents"),
          value: "documents",
          sortable: false,
        },
        { text: this.$t("expeditions.note"), value: "note", sortable: false },
      ];
    },
  },
  watch: {},
};
</script>
