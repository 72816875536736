<template>
  <pickup-address-map></pickup-address-map>
</template>

<script>
import PickupAddressMap from "@/components/Calendar/PickupAddressMap.vue";
export default {
  components: { PickupAddressMap },
};
</script>
