<template>
  <v-dialog
    persistent
    @click:outside="falseButtonClicked()"
    @keydown.esc="falseButtonClicked()"
    v-model="internalShowDialog"
    min-width="500"
    max-width="1024"
  >
    <v-card>
      <div
        @drop.prevent="handleDrop"
        @dragover.prevent="() => true"
        @dragenter.prevent="() => true"
        @dragleave.prevent="() => true"
      >
        <v-card-title class="text-title">
          {{ $t("attachments") }}
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="getAttachments"
            >mdi-reload</v-icon
          >
        </v-card-title>

        <v-card-text class="dialog-text text-content" color="black">
          <v-row>
            <v-col
              class="d-flex ma-0 ml-n0 pa-2"
              cols="12"
              :sm="showAttachmentType ? 4 : 6"
            >
              <v-file-input
                show-size
                counter
                id="attachment"
                ref="attachment"
                name="attachment"
                :error-messages="attchmentErrors ? [attchmentErrors] : null"
                v-model="attachment"
                type="file"
                @change="showAttachmentTypeDialog"
                :label="$t('attachment.file')"
              ></v-file-input>
            </v-col>
            <v-col
              v-if="showAttachmentType"
              class="d-flex ma-0 ml-n0 pa-2"
              cols="12"
              sm="3"
            >
              <v-autocomplete
                autocomplete="off"
                class="to-upper mr-3"
                flat
                :items="attachmentTypes"
                item-text="text"
                item-value="id"
                v-model="attachmentType"
                :label="$t('attachment.type')"
                :hide-details="true"
              ></v-autocomplete>
            </v-col>
            <v-col
              class="d-flex ma-0 ml-n0 pa-2"
              cols="12"
              :sm="showAttachmentType ? 3 : 4"
            >
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="title"
                class="to-upper"
                :label="$t('attachment.title_optional')"
                v-on:keydown.enter.prevent=""
                v-on:keydown="handleInput($event, 'alphanumeric', 80)"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <!-- <v-col class="d-flex ma-0 ml-n0 pa-2" cols="12" sm="2">
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            v-model="group"
                            class="to-upper"
                            :label="$t('attachment.group_optional')"
                            v-on:keydown.enter.prevent=""
                            v-on:keydown="
                                handleInput($event, 'alphanumeric', 80)
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col> -->
            <v-col class="d-flex ma-0 ml-n0 pa-2" cols="12" sm="2">
              <v-btn
                :loading="isLoading"
                color="success"
                class="px-4 mt-2 text-white-color"
                :disabled="!attachment || isLoading"
                @click="addAttachments()"
              >
                {{ $t("add") }}
                <v-icon dark right v-if="!isLoading"> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex ma-0 ml-n0 pa-2" cols="12" sm="12">
              <v-textarea
                v-model="description"
                :label="$t('attachment.description')"
                rows="2"
                class="to-upper"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-data-table
              fixed-header
              :footer-props="{
                'items-per-page-options': dataTableOptions,
              }"
              :hide-default-footer="true"
              :headers="headers"
              :items="attachments"
              :options="options"
              :server-items-length="totalItems"
              :loading="isLoading"
              :loading-text="this.$t('loading')"
              class="elevation-0"
              :items-per-page="itemsPerPage"
              item-key="identifier"
              @update:page="updatePagination"
              @update:items-per-page="updateItemsPerPage"
              @click:row="editItem"
              height="400px"
              style="width: 100%"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              @update:sort-by="updateSort"
              @update:sort-desc="updateSortOrder"
              mustSort
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-icon
                      @click="openUrl(item)"
                      v-if="item"
                      :color="getItemIcon(item)"
                      class="mr-1"
                    >
                      {{ getItemIconColor(item) }}
                    </v-icon>
                  </td>
                  <td @click="editItem(item)">
                    {{ item.registered_at }}
                  </td>
                  <!-- <td @click="editItem(item)">
                                    <template
                                        v-if="
                                            item.attachment_group_id &&
                                                item.group
                                        "
                                    >
                                        {{ item.group.group }}
                                    </template>
                                </td> -->
                  <td @click="editItem(item)">
                    <template v-if="editModeItem && editModeItem.id == item.id">
                      <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        v-model="item.title"
                        class="to-upper"
                        :label="$t('attachment.title_optional')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      {{ (item.title ? item.title : "").toUpperCase() }}
                    </template>
                  </td>
                  <td @click="editItem(item)">
                    <template v-if="editModeItem && editModeItem.id == item.id">
                      <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        v-model="item.description"
                        class="to-upper"
                        :label="$t('attachment.description')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      {{
                        item.description
                          ? item.description.substr(0, 20).toUpperCase()
                          : ""
                      }}
                    </template>
                  </td>
                  <td>
                    <template v-if="editModeItem && editModeItem.id == item.id">
                      <v-icon
                        v-if="item"
                        color="blue"
                        class="mr-1"
                        @click="cancelEdit()"
                      >
                        mdi-close
                      </v-icon>
                      <v-icon
                        v-if="item"
                        color="green"
                        class="mr-1"
                        @click="saveItem(item)"
                      >
                        mdi-content-save
                      </v-icon>
                    </template>
                    <v-icon
                      v-if="item"
                      color="warning"
                      class="mr-1"
                      @click="deleteAttachmentAfterConfirmation(item.id)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-row>

          <v-dialog
            persistent
            @click:outside="showAttachmentTypeDialogVisible = false"
            @keydown.esc="showAttachmentTypeDialogVisible = false"
            v-model="showAttachmentTypeDialogVisible"
            min-width="500"
            max-width="800"
          >
            <v-card style="min-height: 710px">
              <v-card-title class="text-title">
                {{ $t("attachment.type") }}
              </v-card-title>

              <v-card-text class="dialog-text text-content" color="black">
                <v-row>
                  <v-col
                    v-for="(loopattachmenttype, index) in attachmentTypes"
                    :key="index"
                    cols="3"
                    class="d-flex"
                    style="flex-direction: column"
                  >
                    <v-card
                      @click="
                        selectAttachmentTypeFromDialog(loopattachmenttype)
                      "
                      class="mb-1 flex-grow-1"
                      height="130px"
                      style="background-color: #f4f4f4; text-align: center"
                    >
                      <v-icon
                        class="pt-3 pb-2 attachment_icon_color"
                        size="60"
                        >{{ loopattachmenttype.icon }}</v-icon
                      >
                      <p style="font-size: 16px" class="pt-1 pb-2">
                        {{ loopattachmenttype.text }}
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pr-2">
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-2" @click="falseButtonClicked()">
            {{ $t("attachment.close") }}
            <v-icon dark right> mdi-close </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { validationMixin } from "vuelidate";
import { maxLength } from "vuelidate/lib/validators";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  name: "AttachmentDialog",
  refs: ["attachment"],
  components: {
    ConfirmationModel,
  },
  mixins: [validationMixin],
  props: {
    showDialog: [Number, Boolean],
    data: [Number, String, Object],
    resource: [String],
    countKey: [Number, String, Object],
    refresh: { type: Boolean, default: false },
    showAttachmentType: { type: Boolean, default: false },
  },
  validations: {
    // Attachment Validation
    // description: {
    //     required: required,
    // },
    title: {
      maxLength: maxLength(200),
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "date",
          width: "10px",
        },
        {
          text: this.$t("attachment.date"),
          align: "start",
          sortable: true,
          value: "date",
        },
        // {
        //     text: this.$t("attachment.group"),
        //     align: "start",
        //     sortable: false,
        //     value: "group",
        // },
        {
          text: this.$t("attachment.title"),
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: this.$t("attachment.description"),
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("attachment.actions"),
          align: "start",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      sortBy: "description",
      sortDesc: false,
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isLoading: false,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      autocompleteValueLocal: "off",
      internalShowDialog: this.showDialog,
      title: null,
      description: null,
      attachment: null,
      attachments: [],
      module_id: null,
      resource_id: null,
      editModeItem: null,
      group: null,
      resourceModule: null,
      attchmentErrors: "",
      showAttachmentTypeDialogVisible: false,

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,
    };
  },
  mounted() {},
  methods: {
    selectAttachmentTypeFromDialog(item) {
      this.attachmentType = item;
      this.showAttachmentTypeDialogVisible = false;
    },
    showAttachmentTypeDialog() {
      if (this.attachment && this.showAttachmentType) {
        this.showAttachmentTypeDialogVisible = true;
      }
    },
    handleDrop(event) {
      this.attachment = event.dataTransfer.files[0];
    },
    deleteAttachmentAfterConfirmation(data) {
      this.confirmationDialogData = data;
      this.confirmationDialogConfirmationText = this.$t(
        "attachment.delete_attachment_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t("attachment.delete");
      this.confirmationDialogOperation = "delete_attachment";
      this.confirmationDialog = true;
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    updateResource() {
      let data = JSON.parse(localStorage.getItem("data"));
      let applicationResourceId = null;
      data.applicationResources.forEach((resource) => {
        if (resource.application_resource.key == this.resource) {
          applicationResourceId = resource.application_resource.id;
        }
      });
      data.attachmentModules.forEach((modules) => {
        if (modules.application_resource_id == applicationResourceId) {
          this.module_id = modules.id;
          this.resource_id = modules.application_resource_id;
          this.resourceModule = modules;
        }
      });
      this.getAttachments();
    },
    openUrl(item) {
      // window.open(
      //     API_BASE_URL +
      //         "/attachments/file/" +
      //         `${item.resource_key_id}/${item.file_name}`,
      //     "_blank"
      // );
      // window.open(item.signed_url);
      let url = item.signed_url;
      let fileName = item.title;
      this.setPreviewData(url, fileName, true, item.mime_type);
    },
    getItemIcon(item) {
      if (item.mime_type.includes("video")) {
        return "orange";
      } else if (item.mime_type.includes("image")) {
        return "blue";
      } else if (item.mime_type.includes("audio")) {
        return "blue";
      } else {
        return "black";
      }
    },
    getItemIconColor(item) {
      if (item.mime_type.includes("video")) {
        return "mdi mdi-multimedia";
      } else if (item.mime_type.includes("image")) {
        return "mdi mdi-image";
      } else if (item.mime_type.includes("audio")) {
        return "mdi mdi-volume-high";
      } else {
        return "mdi mdi-file";
      }
    },
    deleteAttachment(id) {
      this.isLoading = true;
      axios
        .delete(API_BASE_URL + "/attachments/" + id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(() => {
          this.removeFromList(id);
        })
        .catch((error) => {
          if (error.response.data.errors.includes("not found")) {
            this.removeFromList(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.confirmationDialogData = null;
    },
    removeFromList(id) {
      let tempAttachments = [];
      this.attachments.forEach((attachment) => {
        if (attachment.id != id) {
          tempAttachments.push(attachment);
        }
      });
      this.attachments = tempAttachments;
      this.totalItems = this.attachments.length;
      this.$emit("attachmentsLoaded", this.attachments);
    },
    makeTitle(item) {
      let title = item ? item.title ?? "" : this.title ?? "";
      if (this.showAttachmentType && this.attachmentType) {
        title = title + " " + this.attachmentType.text;
      }
      return title;
    },
    makeDescription(item) {
      return item ? item.description ?? "" : this.description ?? "";
    },
    saveItem(item) {
      let data = {
        title: this.makeTitle(item),
        description: this.makeDescription(item),
      };
      this.isLoading = true;
      axios
        .put(API_BASE_URL + "/attachments/" + item.id, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(() => {})
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.cancelEdit();
    },
    editItem(item) {
      this.editModeItem = item;
    },
    cancelEdit() {
      this.editModeItem = null;
    },
    falseButtonClicked() {
      this.$emit("update:showDialog", false);
      this.$emit("buttonClicked", false);
      this.internalShowDialog = false;
    },
    getAttachments() {
      this.totalItems = 0;
      if (this.module_id && this.data) {
        this.isLoading = true;
        axios
          .get(
            API_BASE_URL +
              `/attachments?module_id=${this.module_id}&resource_key_id=${this.data}`,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
              },
            }
          )
          .then(({ data }) => {
            this.attachments = data.data.result;
            this.totalItems = data.data.result.length;
            this.$emit("attachmentsLoaded", this.attachments);
          })
          .catch((error) => {
            console.log("an error occured " + error);
          })
          .finally(() => {
            this.isLoading = false;
            this.resetRefresh();
          });
      }
    },
    resetRefresh() {
      this.$emit("update:refresh", false);
    },
    resetForm() {
      this.attachment = null;
      this.title = null;
      this.group = null;
      this.description = null;
      this.attachmentType = null;
    },
    addAttachments() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
        return false;
      } else {
        let formData = new FormData();
        formData.append("attachment", this.attachment);
        formData.append("module_id", this.module_id);
        formData.append("title", this.makeTitle(null));
        formData.append("group", this.group ?? "");
        formData.append("description", this.makeDescription(null));
        formData.append("resource_key_id", this.data);
        this.isLoading = true;
        axios
          .post(API_BASE_URL + "/attachments", formData, {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(({ data }) => {
            this.attachments.push(data.data);
            this.totalItems++;
            this.resetForm();
          })
          .catch((error) => {
            if (error?.response?.status == 413) {
                this.attchmentErrors = this.$t("very_large_file");
            }
            if(error.response.data.errors){
                this.attchmentErrors = error.response.data.errors[0];
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      this.$store.commit("setValidation", false); // disable validation
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy == undefined ? "id" : sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
      this.fetchData();
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
  },
  watch: {
    draggedFile(val) {
      if (val) {
        this.attachment = val;
      }
    },
    refresh(val) {
      if (val) {
        this.totalItems = 0;
        this.getAttachments();
      }
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
        if (this.confirmationDialogOperation == "delete_attachment") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.deleteAttachment(this.confirmationDialogData);
          }
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
      },
    },
    attachment: {
      handler: function (val) {
        if (!val) {
          this.attchmentErrors = null;
          this.attachmentType = null;
          this.$emit("attachmentsLoaded", this.attachments);
        }
      },
    },
    resource: {
      handler: function (val) {
        this.totalItems = 0;
        this.attachments = [];
        if (val) {
          this.updateResource();
        }
      },
      immediate: true,
    },
    totalItems: {
      handler: function (val) {
        let data =
          typeof this.$store.state.attachmentCounts == "number"
            ? []
            : this.$store.state.attachmentCounts ?? [];
        data[this.countKey ?? "data"] = val;
        this.$store.commit("setAttachmentCounts", data);
        this.$store.state.attachmentCounts.push({});
        this.$nextTick(() => {
          this.$store.state.attachmentCounts.pop();
        });
      },
    },
    data: {
      handler: function (val) {
        this.totalItems = 0;
        this.attachments = [];
        if (val) {
          this.updateResource();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.dialog-text {
  display: block;
  height: 600px !important;
}
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 27px !important;
}
.text-content {
  font-size: 18px !important;
}
.text-title {
  font-size: 24px !important;
}
</style>
