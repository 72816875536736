<template>
    <div>
      <label for="meeting-time">Select Date/Time:</label>
      <input
        type="datetime-local"
        id="meeting-time"
        v-model="dateTime"
        @change="formatDateTime"
        min="2018-06-07T00:00"
        max="2018-06-14T00:00"
      />
  
      <p>{{ formattedDateTime }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dateTime: '',
        formattedDateTime: '',
      };
    },
    methods: {
      formatDateTime() {
        const date = new Date(this.dateTime);
        if (!isNaN(date.getTime())) {
          this.formattedDateTime = date
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })
            .replace(',', '');
        } else {
          this.formattedDateTime = 'Invalid Date/Time';
        }
      },
      getFormattedDateTime() {
        const date = new Date(this.dateTime);
        if (!isNaN(date.getTime())) {
          const yyyy = date.getFullYear();
          const mm = String(date.getMonth() + 1).padStart(2, '0');
          const dd = String(date.getDate()).padStart(2, '0');
          const hh = String(date.getHours()).padStart(2, '0');
          const ii = String(date.getMinutes()).padStart(2, '0');
          const ss = String(date.getSeconds()).padStart(2, '0');
          return `${yyyy}-${mm}-${dd} ${hh}:${ii}:${ss}`;
        }
        return '';
      },
    },
  };
  </script>
  
  <style scoped>
  input {
    margin: 10px 0;
    padding: 5px;
  }
  </style>
  