<template>
  <v-container>
    <v-row v-for="(item, index) in items" :key="index" class="mb-3">
      <v-col class="ma-0 pa-0 mr-1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="work_point_name[index]"
          name="name"
          class="to-upper"
          ref="client_name"
          @keydown="make_workpoint_data()"
          @change="make_workpoint_data()"
          v-on:keydown="handleInput($event, 'everything', 50)"
          :label="$t('company_settings.working_points.work_point_name')"
          :error-messages="
            $store.state.validation && !$v.work_point_name.required
              ? [
                  this.$t(
                    'company_settings.working_points.text_messages.name_required'
                  ),
                ]
              : []
          "
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
      <v-col class="ma-0 pa-0 mr-1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="work_point_phone[index]"
          name="name"
          class="to-upper"
          ref="client_name"
          @keydown="make_workpoint_data()"
          @change="make_workpoint_data()"
          v-on:keydown="handleInput($event, 'justalphanumeric', 50)"
          :label="$t('company_settings.working_points.work_point_phone')"
          :error-messages="
            $store.state.validation && !$v.work_point_phone.required
              ? [
                  this.$t(
                    'company_settings.working_points.text_messages.name_required'
                  ),
                ]
              : []
          "
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
      <v-col class="ma-0 pa-0 mr-1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="work_point_gsm[index]"
          name="name"
          class="to-upper"
          @keydown="make_workpoint_data()"
          @change="make_workpoint_data()"
          ref="client_name"
          v-on:keydown="handleInput($event, 'justalphanumeric', 50)"
          :label="$t('company_settings.working_points.work_point_gsm')"
          :error-messages="
            $store.state.validation && !$v.work_point_gsm.required
              ? [
                  this.$t(
                    'company_settings.working_points.text_messages.name_required'
                  ),
                ]
              : []
          "
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
      <v-col class="ma-0 pa-0 mr-1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="work_point_fax[index]"
          name="name"
          class="to-upper"
          @keydown="make_workpoint_data()"
          @change="make_workpoint_data()"
          ref="client_name"
          v-on:keydown="handleInput($event, 'justalphanumeric', 50)"
          :label="$t('company_settings.working_points.work_point_fax')"
          :error-messages="
            $store.state.validation && !$v.work_point_fax.required
              ? [
                  this.$t(
                    'company_settings.working_points.text_messages.name_required'
                  ),
                ]
              : []
          "
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
      <v-col class="ma-0 pa-0 mr-1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="work_point_email[index]"
          name="name"
          class="to-upper"
          @keydown="make_workpoint_data()"
          @change="make_workpoint_data()"
          ref="client_name"
          v-on:keydown="handleInput($event, 'email', 80)"
          :label="$t('company_settings.working_points.work_point_email')"
          :error-messages="
            $store.state.validation && !$v.work_point_email.required
              ? [
                  this.$t(
                    'company_settings.working_points.text_messages.name_required'
                  ),
                ]
              : []
          "
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
      <v-col class="ma-0 pa-0 mr-1" cols="12" sm="4">
        <AddressInput
          v-model="address[index]"
          :label="$t('address')"
          class="mt-n3 ml-n1"
        />
      </v-col>
      <v-col class="ma-0 pa-0 mr-1">
        <v-btn
          color="primary"
          class="mt-2"
          @click="removeItem(index)"
          v-if="items.length > 1 || index > 0"
        >
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="mt-2"
          @click="addItem"
          v-if="index === items.length - 1"
        >
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddressInput from "@/components/AddressInput";

export default {
  name: "WorkingPointTab",
  props: {
    prop_workpointdata: [Array],
  },
  components: {
    AddressInput,
  },
  data() {
    return {
      items: [], // Initially one row should always be there
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),

      token: this.$store.state.AccessToken,
      header: {},
      autocompleteValueLocal: "off",
      work_point_name: [],
      work_point_phone: [],
      work_point_gsm: [],
      work_point_fax: [],
      work_point_email: [],
      address: [],
      workingPoints: this.prop_workpointdata,
    };
  },
  methods: {
    addItem(
      id = null,
      name = "",
      phone = "",
      gsm = "",
      fax = "",
      email = "",
      address = {}
    ) {
      this.items.push({ id: id });
      this.work_point_name.push(name);
      this.work_point_phone.push(phone);
      this.work_point_gsm.push(gsm);
      this.work_point_fax.push(fax);
      this.work_point_email.push(email);
      this.address.push(address);
    },
    removeItem(index) {
      if (this.items.length > 1) {
        this.items.splice(index, 1);
      }
      this.make_workpoint_data();
    },
    make_workpoint_data() {
      let data = [];
      this.items.forEach((element, index) => {
        data.push({
          id: this.items[index].id ?? null,
          name: this.work_point_name[index] ?? null,
          phone: this.work_point_phone[index] ?? null,
          gsm: this.work_point_gsm[index] ?? null,
          fax: this.work_point_fax[index] ?? null,
          email: this.work_point_email[index] ?? null,
          address: this.address[index] ?? null,
        });
      });
      // this.prop_workpointdata = data;
      this.$emit("update:prop_workpointdata", data);
    },
  },
  mounted() {
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setAddButtonRoute", "AddClient");
    this.autocompleteValueLocal = this.autocompleteValue();
  },

  created() {},
  computed: {},
  watch: {
    address() {
      this.make_workpoint_data();
    },
    // prop_workpointdata: {
    //     handler: function(val) {
    //     },
    //     immediate: true,
    // },
    workingPoints: {
      handler: function (val) {
        if (val && val.length > 0) {
          val.forEach((element) => {
            this.addItem(
              element.id,
              element.name,
              element.phone,
              element.gsm,
              element.fax,
              element.email,
              element.address ?? null
            );
          });
        } else {
          this.addItem();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
BillsTab
