<template>
  <v-dialog
    class="dialogPopUp"
    v-model="internalShowDialog"
    @click:outside="internalShowDialog = false"
    @keydown.esc="internalShowDialog = false"
  >
    <v-card>
      <v-card-title class="mb-0">
        <span class="text-h5">{{ $t("billings.search_article") }}</span>
      </v-card-title>
      <v-data-table
        fixed-header
        :footer-props="{
          'items-per-page-options': dataTableOptions,
        }"
        :headers="customHeader"
        :items="articles"
        :items-per-page="itemsPerPage"
        :options.sync="options"
        :sort-by="sortBy"
        :server-items-length="totalItems"
        :loading="isLoading"
        :loading-text="this.$t('loading')"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        class="elevation-1"
        item-key="identifier"
        style="width: 100%"
        :height="articles.length <= 1 ? '64vh' : '64vh'"
        @click:row="selectItem"
      >
        <template v-slot:top>
          <v-toolbar flat class="pt-4">
            <v-col class="d-flex" cols="2" sm="2" v-if="showFamilyArticle">
              <v-select
                v-model="familyArticle"
                :items="filteredFamilyArticles"
                :label="
                  familyArticle
                    ? $t('articles.family')
                    : $t('articles.everything')
                "
                item-value="id"
                item-text="name"
                class="mr-0 to-upper"
                return-object
                @change="updateLocalStorageValues(true)"
                :prepend-icon="
                  normalSearch ? 'mdi-grid' : 'mdi-rectangle-outline'
                "
                @click:prepend="$emit('update:normalSearch', !normalSearch)"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" :sm="showFamilyArticle ? 10 : 12">
              <template v-if="normalSearch || customFilters.length == 0">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  class="mr-3 to-upper"
                  :label="$t('search')"
                  v-bind:clearable="true"
                  clear-icon="mdi-close"
                  v-model="search"
                  @keyup="updateLocalStorageValues()"
                  v-on:keydown.enter.prevent="
                    isDebounceCancelled = true;
                    fetchData();
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>

                <v-btn color="primary" dark @click="fetchData()">
                  <v-icon dark>mdi-database-search</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn-toggle
                  id="season-button"
                  v-if="
                    familyArticle &&
                    (familyArticle.parent_family_id == 1 ||
                      familyArticle.id == 1)
                  "
                  v-model="season"
                  dense
                  mandatory
                  class="mr-2"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn value="0">
                        <!-- No Season -->
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dark
                          color="grey darken-1"
                        >
                          mdi-check-all
                        </v-icon>
                        <span> {{ $t("codes.shorts.no_season") }} </span>
                      </v-btn>
                    </template>
                    <span> {{ $t("codes.no_season") }} </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn value="10111002">
                        <!-- Summer -->
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dark
                          color="amber darken-4"
                        >
                          mdi-weather-sunny
                        </v-icon>
                        <span> {{ $t("codes.shorts.summer") }} </span>
                      </v-btn>
                    </template>
                    <span> {{ $t("codes.summer") }} </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn value="10111001">
                        <!-- Winter -->
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dark
                          color="blue darken-2"
                        >
                          mdi-weather-snowy-heavy
                        </v-icon>
                        <span> {{ $t("codes.shorts.winter") }} </span>
                      </v-btn>
                    </template>
                    <span> {{ $t("codes.winter") }} </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn value="10111003">
                        <!-- All Season -->
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dark
                          color="green darken-1"
                        >
                          mdi-weather-partly-snowy-rainy
                        </v-icon>
                        <span> {{ $t("codes.shorts.all_season") }} </span>
                      </v-btn>
                    </template>
                    <span> {{ $t("codes.all_season") }} </span>
                  </v-tooltip>
                </v-btn-toggle>

                <div
                  v-for="(filter, index) in filterPropertiesExcept(
                    customFilters
                  )"
                  :key="index"
                >
                  <template v-if="filter.property_name == 'property_00'">
                    <v-autocomplete
                      v-if="filter.property_name == 'property_00'"
                      flat
                      :ref="filter.property_name"
                      :items="brands"
                      :search-input.sync="searchBrand"
                      v-model="brand"
                      :label="$t('articles.properties.brand')"
                      class="mr-2 to-upper"
                      item-value="id"
                      item-text="name"
                      @focus="brand = null"
                      @change="updateLocalStorageValues()"
                      return-object
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      auto-select-first
                      hide-no-data
                    ></v-autocomplete>
                  </template>
                  <template v-else>
                    <v-autocomplete
                      v-if="filter.mapping_key != null"
                      v-model="filter.value"
                      :items="multiselectsValues[index]"
                      :label="filter.text"
                      item-value="id"
                      hide-details="auto"
                      class="mr-2"
                      append-icon="mdi-close"
                      @click:append="
                        filter.value = null;
                        fetchData();
                      "
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      @change="
                        fetchData();
                        updateLocalStorageValues();
                      "
                      hide-no-data
                    ></v-autocomplete>
                    <v-text-field
                      flat
                      v-if="filter.mapping_key == null"
                      v-model="filter.value"
                      :label="filter.text"
                      class="mr-2 to-upper"
                      :ref="filter.property_name"
                      @focus="filter.value = null"
                      v-on:keydown.enter.prevent="
                        isDebounceCancelled = true;
                        updateLocalStorageValues();
                        fetchData();
                      "
                      @keypress="
                        handleInput(
                          $event,
                          filter.keytype,
                          filter.search_length
                        )
                      "
                      @keyup="
                        handleAutoFocus(
                          $event,
                          filter.search_length,
                          filter.property_name,
                          filter.keytype,
                          filterPropertiesExcept(customFilters)[index + 1]
                            ? filterPropertiesExcept(customFilters)[index + 1]
                                .property_name
                            : null
                        );
                        updateLocalStorageValues();
                      "
                      :outlined="is_boxes"
                      :dense="is_boxes"
                    ></v-text-field>
                  </template>
                </div>

                <v-select
                  v-if="articleBooleanProperties.length >= 1"
                  v-model="booleanSelectedValues"
                  @change="updateLocalStorageValues()"
                  :label="$t('articles.properties.more')"
                  :items="articleBooleanProperties"
                  item-value="id"
                  item-text="name"
                  multiple
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <template
                        v-if="index === 0 && booleanSelectedValues.length == 1"
                      >
                        {{
                          item.name.substr(
                            0,
                            booleanSelectedValues.length > 1 ? 3 : 5
                          )
                        }}
                      </template>
                      <template v-if="index === 1">
                        (+{{ booleanSelectedValues.length - 1 }})
                      </template>
                    </span>
                  </template>
                </v-select>
                <v-btn color="primary" dark @click="fetchData()">
                  <v-icon dark>mdi-database-search</v-icon>
                </v-btn>
              </template>
            </v-col>
            <!-- statuses -->
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <td
            :id="articleId == item.id ? (highlightRow(item.id), item.id) : ''"
          >
            {{ item.id }}
          </td>
        </template>
        <template v-slot:item.image="{ item }">
          <td>
            <v-icon color="blue" @click.stop.prevent="showImage(item)"
              >mdi-image</v-icon
            >
          </td>
        </template>
        <template v-slot:item.property_00="{ item }">
          <td>{{ item.name }}</td>
          <!-- Type Column -->
        </template>
        <template v-slot:item.name="{ item }">
          <td>{{ item.description || item.name }}</td>
        </template>
        <template v-slot:item.property_01="{ item }">
          <td
            v-if="
              item.property_01 &&
              (familyArticle.id == 1 || familyArticle.parent_family_id == 1)
            "
          >
            {{
              $t(
                "articles.types." + getKeyFromCode(tyreTypes, item.property_01)
              )
            }}
          </td>
          <!-- Type Column -->
          <td
            v-if="
              item.property_01 &&
              (familyArticle.id == 2 || familyArticle.parent_family_id == 2)
            "
          >
            {{
              $t(
                "articles.types." + getKeyFromCode(wheelTypes, item.property_01)
              )
            }}
          </td>
          <!-- Type Column -->
        </template>
        <template v-slot:item.property_02="{ item }">
          <td
            v-if="
              ['all_season', 'no_season', 'winter', 'summer'].includes(
                getKeyFromCode(tyreSeasons, item.property_02)
              )
            "
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="
                    'summer' == getKeyFromCode(tyreSeasons, item.property_02)
                  "
                  dark
                  color="amber darken-4"
                  >mdi-weather-sunny</v-icon
                >
                <!-- <v-icon  v-if="'no_season' == getKeyFromCode(tyreSeasons,item.property_02)" dark color="grey darken-1">mdi-check-all</v-icon> -->
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="
                    'all_season' ==
                    getKeyFromCode(tyreSeasons, item.property_02)
                  "
                  dark
                  color="green darken-1"
                  >mdi-weather-partly-snowy-rainy</v-icon
                >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="
                    'winter' == getKeyFromCode(tyreSeasons, item.property_02)
                  "
                  dark
                  color="blue darken-2"
                  >mdi-weather-snowy-heavy</v-icon
                >
              </template>
              <span
                v-if="'summer' == getKeyFromCode(tyreSeasons, item.property_02)"
              >
                {{ $t("codes.summer") }}
              </span>
              <span
                v-if="
                  'no_season' == getKeyFromCode(tyreSeasons, item.property_02)
                "
              >
                {{ $t("codes.no_season") }}
              </span>
              <span
                v-if="
                  'all_season' == getKeyFromCode(tyreSeasons, item.property_02)
                "
              >
                {{ $t("codes.all_season") }}
              </span>
              <span
                v-if="'winter' == getKeyFromCode(tyreSeasons, item.property_02)"
              >
                {{ $t("codes.winter") }}
              </span>
            </v-tooltip>
          </td>

          <td
            v-else-if="
              item.property_02 &&
              (familyArticle.id == 2 || familyArticle.parent_family_id == 2)
            "
          >
            {{ $t("articles.types." + item.property_02) }}
          </td>
          <td v-else>
            {{ item.property_02 }}
          </td>
          <!-- Season Column -->
        </template>
        <template v-slot:item.stock-reserve="{ item }">
          {{ parseInt(item.stock || 0) - parseInt(item.reserve || 0) }}
        </template>
      </v-data-table>
      <image-pop-up
        v-if="showImagePopUp"
        :show="showImagePopUp"
        @closePopUp="showImagePopUp = false"
        :imageUrl="articleImageBaseUrl + currentItem.identifier"
        :data="currentItem"
      ></image-pop-up>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ImagePopUp from "@/components/ImagePopUp";

export default {
  name: "SearchArticleModel",
  components: {
    "image-pop-up": ImagePopUp,
  },
  props: {
    showFamilyArticle: [Boolean],
    showDialog: [Number, Boolean],
    compSelectedArticleId: [String, Number, Object],
    internalStockOnly: [Number, Boolean],
    familyArticleId: [Number, Object],
    normalSearch: [Boolean],
    returnItemObjectOrId: [String],
    multiselectsValuesPassed: [Array],
    multiselectsPassed: [Array],
    showFamilyArticleOnlyWithStock: {
      default: false,
      type: Boolean,
    },
  },
  async mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setAddButtonRoute", "AddArticle", "EditArticle");
    if (this.familyArticles.length > 0) {
      this.filteredFamilyArticles = this.familyArticles;
      if (this.showFamilyArticleOnlyWithStock) {
        this.filteredFamilyArticles = this.familyArticles.filter((element) => {
          if (element.is_stock_managed == 1) {
            return element;
          }
        });
      }
    }
  },
  data() {
    return {
      filteredFamilyArticles: this.familyArticles,
      booleanSelectedValues: [],
      showImagePopUp: false,
      currentItem: null,
      articleImageBaseUrl: API_BASE_URL + "/article/image/",
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      internalShowDialog: this.showDialog,
      articleId: null,
      autocompleteValueLocal: "off",
      selectedArticleId: this.compSelectedArticleId,
      search: null,
      isFirstTimeLoaded: 0,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isLoading: false,
      articles: [],
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      season: 0,
      width: null,
      height: null,
      rim: null,
      li: null,
      si: null,
      brands: [],
      brand: null,
      approval: { id: 10011001 },
      status: { id: 10001001 },
      customFilters: [],
      articleBooleanProperties: [],
      familyArticle: {},
      loadingFirstTime: 0,
      ifCurrentlyPaused: false,
      changingArticlePropertiesInProgress: false,
      customHeader: [
        {
          text: this.$t("articles.id"),
          value: "id",
          align: "start",
          sortable: false,
          width: 5,
        },
        {
          text: this.$t("articles.image"),
          value: "image",
          align: "start",
          sortable: false,
          width: 30,
        },
        {
          text: this.$t("articles.ean"),
          value: "identifier",
          align: "start",
          sortable: false,
          width: 140,
        },
        {
          text: this.$t("articles.description"),
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("articles.tariff_price"),
          align: "end",
          sortable: false,
          value: "tariff_price",
        },
      ],
      // customFilters : [],
      customFiltersExceptions: [1, 2], // these numbers represents property_id value example : property_id = 1
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      searchEAN: null,
      isDebounceCancelled: false,
      searchBrand: null,
    };
  },
  methods: {
    showImage(item) {
      this.currentItem = item;
      this.showImagePopUp = true;
    },
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.fetchData();
    }, 2000),
    filterPropertiesExcept(customFilters) {
      let properties = [];
      if (
        this.familyArticle &&
        (this.familyArticle.parent_family_id == 1 || this.familyArticle.id == 1)
      ) {
        properties = ["property_02"];
      }

      let filteredProperties = [];
      filteredProperties = customFilters.filter((property) => {
        let found = false;
        properties.forEach((element) => {
          if (element == property.property_name) {
            found = true;
          }
        });

        return found ? false : property;
      });
      return filteredProperties;
    },
    async getMultiSelectValuesFromCode() {
      this.customFilters.forEach((filter, filterindex) => {
        this.multiselectsValues[filterindex] = [];
      });
      this.multiselects.forEach((code) => {
        this.getCodes(parseInt(code), "codes").then((data) => {
          this.customFilters.forEach((filter, filterindex) => {
            if (filter.mapping_key == code) {
              this.multiselectsValues[filterindex] = data;
            }
          });
          this.multiselectsValues.push({});
          this.multiselectsValues.splice(-1);
        });
      });
    },
    /**
     * This Function is responsible to autofocus on next property field, if current's max length reached
     */
    handleAutoFocus(
      event,
      search_length,
      propertyName,
      dataType,
      forceToJumpOnProperty
    ) {
      let currentFieldLength = 0;
      if (dataType == "int" || dataType == "float") {
        currentFieldLength = (event.target.value + "").replace(".", "").length;
      } else {
        currentFieldLength = (event.target.value + "").length;
      }
      if (
        forceToJumpOnProperty &&
        search_length != null &&
        currentFieldLength == search_length
      ) {
        this.$refs[forceToJumpOnProperty][0].focus();
      } else if (
        propertyName.search("property") == 0 &&
        search_length != null &&
        currentFieldLength == search_length
      ) {
        let underscorePos = propertyName.search("_") + 1;
        let propertyNo = Number(propertyName.substr(underscorePos)) + 1;
        let nextPropertyName =
          "property_" + (propertyNo < 10 ? "0" : "") + propertyNo;
        this.$refs[nextPropertyName][0].focus();
      }
    },
    checkIfBrandExistsInProperties(customFilters) {
      let found = false;
      customFilters.forEach((property) => {
        if ("property_00" == property.property_name) {
          found = true;
        }
      });
      return found;
    },
    closeArticleDetails: function () {
      // this.showDialog = false;
    },
    resetFurtherBoxesValues(focussedPropertyName) {
      let foundProperty = false;
      this.customFilters.forEach((filter, index) => {
        if (filter.property_name == focussedPropertyName) {
          foundProperty = true;
        }
        if (foundProperty) {
          this.customFilters[index].value = null;
        }
      });
    },
    removeFamilyArticle() {
      this.familyArticle = null;
    },
    getFamilyArticleById(id) {
      let familyArticle = 0;
      this.familyArticles.forEach((element) => {
        if (element.id == id) {
          familyArticle = element;
        }
      });

      return familyArticle;
    },
    updateLocalStorageValues(familyUpdated) {
      if (familyUpdated) {
        this.search = null;
        this.searchEAN = null;
      }
      let filters = {
        // "normalSearch": this.normalSearch,
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortDesc,
        approval: this.approval.id,
        status: this.status.id,
        family: this.familyArticle ? this.familyArticle.id : 0,
        season: this.season,
        brand: familyUpdated ? null : this.brand != null ? this.brand : "",
        search: this.search,
        appliedFilters: familyUpdated
          ? []
          : this.getQueryStringFromDynamicallyCreatedFilters()[1],
        identifier: this.searchEAN ? this.searchEAN : null,
      };
      localStorage.setItem("ARTICLE_MODEL_FILTERS", JSON.stringify(filters));
    },
    applyPreviousFilters() {
      if (this.customFilters.length > 0) {
        this.resetFurtherBoxesValues(this.customFilters[0].property_name);
        this.search = null;
        // this.brands = [];
        this.brand = null;
      }
      let articleFilters = JSON.parse(
        localStorage.getItem("ARTICLE_MODEL_FILTERS")
      );
      if (articleFilters != null) {
        // this.normalSearch = articleFilters.normalSearch;
        this.page = articleFilters.page ?? 1;
        this.itemsPerPage =
          articleFilters.items_per_page ?? DEFAULT_ITEMS_PER_PAGE_OPTIONS[0];
        this.sortBy = articleFilters.order_by
          ? articleFilters.order_by
          : "description";
        // this.sortDesc = articleFilters.order_direction ? articleFilters.order_direction : 'asc' ;
        this.approval.id = articleFilters.approval
          ? articleFilters.approval
          : this.approvals[1];
        this.status.id = articleFilters.status
          ? articleFilters.status
          : this.statuses[1];
        this.familyArticle = articleFilters.family
          ? this.getFamilyArticleById(articleFilters.family)
          : 0;
        this.season = articleFilters.season;
        this.searchEAN = articleFilters.identifier || articleFilters.search;

        this.articleId = articleFilters.id; // to search for specific article

        this.search = articleFilters.search;
        if (articleFilters.appliedFilters) {
          this.booleanSelectedValues = [];
          articleFilters.appliedFilters.forEach((element) => {
            let keyName = Object.keys(element)[0];
            this.customFilters.find((el, index) => {
              if (el.property_name == keyName) {
                this.customFilters[index].value = element[keyName];
                el.value = element[keyName];
                return el;
              }
            });
            this.articleBooleanProperties.find((el) => {
              if (el.property_name == keyName) {
                let foundBoolean = false;
                this.booleanSelectedValues.forEach((selectedBoolean) => {
                  if (selectedBoolean.property_id == el.property_id) {
                    foundBoolean = true;
                  }
                });
                if (!foundBoolean) {
                  this.booleanSelectedValues.push(el);
                }
                return el;
              }
            });
          });
        }
        // set brand
        this.brand = articleFilters.brand;

        if (!this.searchEAN) {
          this.fetchData();
        }
      }
    },
    highlightRow(itemId) {
      this.$nextTick(() => {
        document.getElementById(itemId).parentElement.parentElement.className =
          "matchedRow";
      });
    },
    checkIfFetchingPossible() {
      let isPossible = false;
      this.ifCurrentlyPaused = false;

      let valueAssigned = 0;
      this.customFilters.forEach((element) => {
        if (element.value != null) {
          valueAssigned++;
        }
      });
      if (valueAssigned != 0) {
        isPossible = true;
      } else {
        isPossible = false;
      }
      if (!isPossible) {
        this.ifCurrentlyPaused = true;
      }

      if (
        this.familyArticle &&
        (this.familyArticle.id == 0 ||
          this.familyArticle.id == 3 ||
          this.familyArticle.id == 4)
      ) {
        isPossible = true;
      }

      if (this.changingArticlePropertiesInProgress) {
        isPossible = false;
      }

      if (
        (this.search && this.search.length > 0) ||
        (this.searchEAN && this.searchEAN.length > 0)
      ) {
        isPossible = true;
      }

      if (this.brand) {
        isPossible = true;
      }

      return isPossible;
    },
    fetchData(forceSearchString = null) {
      this.articles = []; // remove the data from the table
      if (this.checkIfFetchingPossible() || forceSearchString) {
        // if forceSearchString then search it
        this.isLoading = true;
        const { sortBy, sortDesc, itemsPerPage, page } = this.options;

        axios
          .get(
            API_BASE_URL +
              "/articles" +
              "?page=" +
              (page || this.page) +
              "&items_per_page=" +
              ((itemsPerPage || this.itemsPerPage) == -1
                ? this.totalItems
                : itemsPerPage || this.itemsPerPage) +
              "&order_by=" +
              (sortBy ? sortBy[0] || this.sortBy : "description") +
              "&order_direction=" +
              (sortDesc
                ? !!sortDesc[0] || this.sortDesc
                  ? "desc"
                  : "asc"
                : "asc") +
              "&parent_also=true" +
              (this.internalStockOnly
                ? "&featchCompanyArticles=1&internal_only=1"
                : "") +
              (this.familyArticle && this.familyArticle.id != 0
                ? "&family=" + this.familyArticle.id
                : "") +
              (!this.normalSearch &&
              this.familyArticle &&
              (this.familyArticle.parent_family_id == 1 ||
                this.familyArticle.id == 1)
                ? this.season
                  ? "&season=" + this.season
                  : ""
                : "") +
              (this.familyArticle
                ? this.brand
                  ? "&brand=" + this.brand.id
                  : ""
                : "") +
              //   (forceSearchString ? "&search=" + encodeURIComponent(forceSearchString) : ""),
              (this.search && this.search.length > 0
                ? "&search=" + encodeURIComponent(this.search)
                : (this.searchEAN && this.searchEAN.length == 13
                    ? "&identifier=" + encodeURIComponent(this.searchEAN)
                    : "") +
                  (this.searchEAN &&
                  this.searchEAN.length > 0 &&
                  this.searchEAN.length != 13
                    ? "&search=" + encodeURIComponent(this.searchEAN)
                    : "")) +
              this.getQueryStringFromDynamicallyCreatedFilters()[0],
            { headers: this.header }
          )
          .then(({ data }) => {
            this.articles = data.data.map((element) => {
              if (element.property_01) {
                element.property_01 = this.$t(element.property_01);
              }
              if (element.property_02) {
                this.wheelMaterials.find((ele) => {
                  if (ele.id == element.property_02) {
                    element.property_02 = ele.key;
                  }
                });
              }
              return element;
            });
            this.lastPage = data.last_page;
            this.page = data.current_page;
            this.totalItems = data.total;
            this.itemsPerPage = parseInt(data.per_page);

            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          });
      }
    },
    selectItem(item) {
      if (this.returnItemObjectOrId && this.returnItemObjectOrId == "object") {
        this.$emit("update:compSelectedArticleId", item);
      } else {
        this.$emit("update:compSelectedArticleId", item.id);
      }

      this.internalShowDialog = false;
    },
    changeArticleProperties() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });

      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }

      this.search = null;
      // hard coding for family : todo later make it dynamic
      if (currentFamilyArticle.parent_family_id === 2) {
        this.season = "";
      }

      this.articleBooleanProperties = currentFamilyArticle.properties.filter(
        (property) => {
          if (property.cast == "boolean" && property.in_filter == 1) {
            property.name = property.translated
              ? property.name
              : this.$t("articles.properties." + property.name);
            property.property_name =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);
            property.translated = true;
            return property;
          }
          return false;
        }
      );
    },
    setCustomDataTableHeaders() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });

      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }

      if (
        this.familyArticle &&
        (this.familyArticle.parent_family_id == 1 ||
          this.familyArticle.parent_family_id == 2)
      ) {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.image"),
            value: "image",
            align: "start",
            sortable: false,
            width: 30,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: false,
            width: 140,
          },
          {
            text: this.$t("articles.description"),
            align: "start",
            sortable: false,
            value: "description",
          },
          {
            text: this.$t("articles.tariff_price"),
            align: "end",
            sortable: false,
            value: "tariff_price",
          },
          {
            text: this.$t("articles.stock"),
            align: "end",
            sortable: false,
            value: "stock",
          },
          {
            text: this.$t("articles.reserve"),
            align: "end",
            sortable: false,
            value: "reserve",
          },
          {
            text: this.$t("articles.stock_reserve"),
            align: "end",
            sortable: false,
            value: "stock-reserve",
          },
          {
            text: this.$t("articles.price"),
            align: "end",
            sortable: false,
            value: "price",
          },
        ];
      } else if (this.familyArticle && this.familyArticle.id == 3) {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: false,
            width: 180,
          },
          {
            text: this.$t("articles.original_name"),
            align: "start",
            sortable: false,
            value: "original_name",
          },
          {
            text: this.$t("articles.price"),
            align: "end",
            sortable: false,
            value: "price",
          },
        ];
      } else if (this.familyArticle && this.familyArticle.id == 4) {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: false,
            width: 180,
          },
          {
            text: this.$t("articles.name"),
            align: "start",
            sortable: false,
            value: "description",
          },
          {
            text: this.$t("articles.price"),
            align: "end",
            sortable: false,
            value: "price",
          },
        ];
      } else if (this.familyArticle && this.familyArticle.id == 0) {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: false,
            width: 180,
          },
          {
            text: this.$t("articles.original_name"),
            align: "start",
            sortable: false,
            value: "original_name",
          },
          {
            text: this.$t("articles.price"),
            align: "end",
            sortable: false,
            value: "price",
          },
        ];
      } else {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: false,
            width: 140,
          },
          {
            text: this.$t("articles.description"),
            align: "start",
            sortable: false,
            value: "description",
          },
        ];
      }

      this.renderedFilters = [];
      currentFamilyArticle.properties.filter((property) => {
        if (property.in_grid == 1) {
          this.customHeader.push({
            text: this.$t("articles.properties." + property.name),
            value:
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id),
            align: "start",
            sortable: true,
          });
          return true;
        }
      });
      this.modifyOrderingOfCustomHeaders();
    },
    /**
     * This function is used to modify the ordering of Headers in Listing
     * Note : For ordering you must know the column name
     */
    modifyOrderingOfCustomHeaders() {
      let sortedArray = [];
      let nonSortedArray = [];
      let sortingArray = [];
      if (
        this.familyArticle &&
        (this.familyArticle.parent_family_id == 1 || this.familyArticle.id == 1)
      ) {
        sortingArray = [
          this.$t("articles.id"),
          this.$t("articles.image"),
          this.$t("articles.properties.type"),
          this.$t("articles.properties.season"),
          this.$t("articles.properties.name"),
          this.$t("articles.ean"),
          this.$t("articles.properties.width"),
          this.$t("articles.properties.height"),
          this.$t("articles.properties.rim"),
          this.$t("articles.properties.li"),
          this.$t("articles.properties.si"),
          this.$t("articles.tariff_price"),
        ];
      }
      if (this.familyArticle && this.familyArticle.parent_family_id == 2) {
        sortingArray = [
          this.$t("articles.id"),
          this.$t("articles.image"),
          this.$t("articles.properties.type"),
          this.$t("articles.properties.season"),
          this.$t("articles.properties.name"),
          this.$t("articles.ean"),
          this.$t("articles.properties.width"),
          this.$t("articles.properties.height"),
          this.$t("articles.properties.rim"),
          this.$t("articles.properties.li"),
          this.$t("articles.properties.si"),
          this.$t("articles.tariff_price"),
          this.$t("articles.bolts"),
          this.$t("articles.pcd"),
          this.$t("articles.offset"),
          this.$t("articles.center_bore"),
        ];
      } else {
        sortingArray = [
          this.$t("articles.id"),
          this.$t("articles.image"),
          this.$t("articles.ean"),
          this.$t("articles.properties.name"),
        ];
      }

      let processedArray = [];
      sortingArray.forEach((element, indexNo) => {
        for (const [key, value] of Object.entries(this.customHeader)) {
          let added = false;
          console.log(key);
          if (element == value.text) {
            sortedArray.push(value);
            processedArray.push(value.text);
            added = true;
          }
          if (
            added == false &&
            processedArray.find((element) => element == value.text) ==
              undefined &&
            sortingArray.length == indexNo + 1
          ) {
            nonSortedArray.push(value);
          }
        }
      });

      this.customHeader = sortedArray.concat(nonSortedArray);
    },
    setDynamicFilterBoxes() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });

      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }

      this.$nextTick(() => {
        this.applyPreviousFilters();
      });
      this.applyPreviousFilters();
      this.customFilters = [];
      this.multiselects = [];
      currentFamilyArticle.properties.filter((property) => {
        if (property.in_filter == 1) {
          let indexOfClass = (property.mapping ?? "").indexOf("class=");
          let mappingKey = null;
          if (indexOfClass != -1) {
            mappingKey = property.mapping.substr(indexOfClass + 6, 4) + "";
            let propertyName =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);

            if (this.familyArticle.properties) {
              this.familyArticle.properties[propertyName] = parseInt(
                this.familyArticle.properties[propertyName]
              );
            }
          }
          if (mappingKey) {
            this.multiselects.push(mappingKey);
          }
          if (property.cast != "boolean") {
            this.customFilters.push({
              text: this.$t("articles.properties." + property.name),
              align: "start",
              sortable: true,
              property_name:
                "property_" +
                (property.property_id < 10
                  ? 0 + property.property_id.toString()
                  : property.property_id),
              property_id: property.property_id,
              column_name: property.name,
              value: null,
              property_type: property.cast,
              mapping: property.mapping ?? null,
              mapping_key: mappingKey ?? null,
              keytype: property.cast,
              search_length: property.search_length,
            });
          }
          return true;
        }
        return false;
      });
      if (this.isFirstTimeLoaded == 0) {
        this.applyPreviousFilters();
        this.isFirstTimeLoaded++;
      }
      this.changingArticlePropertiesInProgress = false;
    },
    getQueryStringFromDynamicallyCreatedFilters() {
      let queryString = "";
      let appliedFilters = [];
      this.customFilters.forEach((filter) => {
        if (filter.value) {
          queryString += "&" + filter.property_name + "=" + filter.value;
          appliedFilters.push({
            [filter.property_name]: filter.value,
          });
        }
      });
      this.booleanSelectedValues.forEach((filter) => {
        queryString += "&" + filter.property_name + "=" + 1; // 1 means true
        appliedFilters.push({
          [filter.property_name]: 1, // 1 means true
        });
      });
      return [queryString, appliedFilters];
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
    },
    getBrands() {
      if (this.familyArticle && this.familyArticle.id) {
        if (
          this.cachedData["brands"][this.familyArticle.id] &&
          this.cachedData["brands"][this.familyArticle.id].length > 0
        ) {
          this.brands = this.cachedData["brands"][this.familyArticle.id];
        } else {
          axios
            .get(
              API_BASE_URL +
                "/brands?family=" +
                this.familyArticle.id +
                "&parent_also=true&items_per_page=-1",
              { headers: this.header }
            )
            .then(({ data }) => {
              this.brands = data.data;
              this.cachedData["brands"][this.familyArticle.id] = this.brands;
            })
            .catch(function (error) {
              console.log("an error occured " + error);
            })
            .finally(() => {});
        }
      }
    },
  },
  computed: {},
  beforeDestroy: function () {
    this.closeArticleDetails();
  },
  watch: {
    internalStockOnly: {
      handler: function () {
        this.fetchData();
      },
    },
    multiselectsValuesPassed: {
      handler: function () {
        this.multiselectsValues = this.multiselectsValuesPassed;
      },
    },
    multiselectsPassed: {
      handler: function () {
        this.multiselects = this.multiselectsPassed;
      },
    },
    normalSearch: {
      handler: function (val) {
        this.customFilters.forEach((element) => {
          element.value = null;
        });
        this.brand = null;
        if (val == false) {
          this.search = null;
        }
      },
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
        if (val == true) {
          this.applyPreviousFilters();
        }
      },
    },
    internalShowDialog: {
      handler: function () {
        this.$emit("update:showDialog", this.internalShowDialog);
      },
    },
    familyArticleId: {
      handler: function (val) {
        if (val && typeof val == "object") {
          this.familyArticle = val;
        } else {
          this.familyArticle = 0;
        }
      },
    },
    familyArticle: {
      handler: function () {
        this.searchEAN = null;
        this.search = null;
        this.brand = null;
        this.brands = [];

        this.changingArticlePropertiesInProgress = true;
        if (
          this.familyArticle &&
          this.familyArticle.parent_family_properties.length > 0
        ) {
          this.familyArticle.properties =
            this.familyArticle.parent_family_properties;
        }
        if (this.loadingFirstTime != 0) {
          this.fetchData();
        }
        if (this.familyArticle) {
          this.changeArticleProperties();
          this.setCustomDataTableHeaders();
          this.setDynamicFilterBoxes();
        } else {
          this.updateLocalStorageValues();
          this.changeArticleProperties();
          this.setCustomDataTableHeaders();
          this.setDynamicFilterBoxes();
        }

        this.applyPreviousFilters();
        this.getMultiSelectValuesFromCode();

        this.getBrands();

        // show_on_load -> Load some articles on load of the page
        this.$nextTick(() => {
          if (
            this.familyArticle &&
            typeof this.familyArticle == "object" &&
            this.familyArticle.show_on_load
          ) {
            if (
              this.familyArticle.parent_family_id == 1 ||
              this.familyArticle.id == 1
            ) {
              // DO NOT IMPLEMENT DEFAULT SEARCH FOR TYRE ARTICLES
            } else {
              this.fetchData("*");
            }
          }
        });
      },
    },
    search: _.debounce(function () {
      this.fetchData();
    }, 500),
    searchEAN: {
      handler: function () {
        _.debounce(function () {
          this.fetchData();
        }, 500);
      },
    },
    brand: {
      handler: function () {
        if (this.brand) {
          this.fetchData();
        }
      },
    },
    season: {
      handler: function () {
        if (this.loadingFirstTime != 0) {
          this.fetchData();
          this.updateLocalStorageValues();
        }
        this.loadingFirstTime++;
      },
    },
    status: {
      handler: function () {
        this.fetchData();
      },
    },
    approval: {
      handler: function () {
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped>
.to-upper input {
  text-transform: uppercase !important;
}
</style>
