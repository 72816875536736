<template>
  <v-row>
    <v-overlay :value="isLoadingInProgress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-data-table
      fixed-header
      :footer-props="{
        'items-per-page-options': dataTableOptions,
      }"
      :headers="headers"
      :hide-default-header="true"
      :items="filteredItems"
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :sort-by="sortBy"
      :loading-text="this.$t('loading')"
      @update:page="updatePagination"
      @update:items-per-page="updateItemsPerPage"
      class="elevation-0"
      item-key="id"
      :height="
        receptionDataForInjecting && receptionDataForInjecting.is_injected == 0
          ? '82vh'
          : '88vh'
      "
      style="width: 100%"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          class="pt-4"
          v-if="
            receptionDataForInjecting &&
            receptionDataForInjecting.is_injected == 0
          "
        >
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                class="mr-3 to-upper"
                :label="$t('receptions.delivery_note_reference')"
                v-bind:clearable="true"
                clear-icon="mdi-close"
                v-model="delivery_note_ref"
                v-on:keydown="handleInput($event, 'alphanumeric', 12)"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                flat
                :items="suppliers"
                v-model="supplier"
                v-bind:clearable="true"
                :label="$t('receptions.supplier')"
                class="mr-2 to-upper"
                item-value="id"
                item-text="name"
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
                hide-no-data
              ></v-autocomplete>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" class="ma-0 pa-0">
              <v-text-field
                class="mr-3 to-upper"
                :label="$t('search')"
                prepend-icon="mdi-magnify"
                v-bind:clearable="true"
                clear-icon="mdi-close"
                v-model="searchInList"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #header="{ props: { headers } }">
        <thead class="v-data-table-header">
          <tr>
            <template v-for="header in headers">
              <th
                :key="header.value"
                scope="col"
                :class="header.align == 'start' ? 'text-left' : 'text-right'"
              >
                <v-row
                  :class="
                    header.align == 'start'
                      ? 'justify-left'
                      : header.align == 'center'
                      ? 'justify-center'
                      : 'justify-end'
                  "
                >
                  {{ header.text }}
                  <v-simple-checkbox
                    v-if="header.value == 'is_verified'"
                    class="mt-0 ma-0 pa-0 pl-2"
                    hide-details="auto"
                    v-model="isAllSelected"
                    :ripple="false"
                    @click="saveCurrentData(300)"
                  ></v-simple-checkbox>
                </v-row>
              </th>
            </template>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr :class="setRowColorClass(item)">
          <td>{{ item.srno }}</td>
          <td>
            <p class="article_name mb-0 pb-0">
              {{ item.article.title_description[0].description }}
              <br />
              <span class="identifier">
                <TextComponentWithExtraIcons
                  :value="item.article.identifier"
                ></TextComponentWithExtraIcons>
              </span>
            </p>
          </td>
          <td>
            <template
              v-if="getPresentSupplierOrdersCount(item.supplier_orders)"
            >
              {{ $t("receptions.orders_count_text") }}:
              {{ getPresentSupplierOrdersCount(item.supplier_orders) }}
            </template>
            <template v-else>
              {{ $t("receptions.internal_stock") }}
            </template>
            <v-icon
              color="primary"
              v-if="getPresentSupplierOrdersCount(item.supplier_orders)"
              @click="showSupplierOrdersInModel(item)"
              >mdi-open-in-new
            </v-icon>
          </td>
          <td class="text-right">
            {{ item.before_stock }}/{{ item.before_reserve }}
          </td>
          <td
            class="text-right"
            v-if="
              receptionDataForInjecting &&
              receptionDataForInjecting.is_injected == 1
            "
          >
            {{ item.after_stock }}/{{ item.after_reserve }}
          </td>
          <td class="text-right">
            {{ item.totalOrdered }}
          </td>
          <td class="text-right" align="right">
            <v-row class="justify-end">
              <v-text-field
                style="text-align: right; max-width: 60px"
                class="mr-3 reception_received right-aligned-input to-upper"
                v-bind:clearable="false"
                v-model="item.received"
                v-on:keydown="handleInput($event, 'int', 12)"
                dense
                hide-details="auto"
                @change="saveCurrentData()"
              >
              </v-text-field>
            </v-row>
          </td>
          <td class="text-right">
            <v-row class="justify-end">
              <!-- <v-text-field
                                style="text-align: right; max-width: 100px;"
                                class="mr-3 reception_received right-aligned-input to-upper"
                                v-bind:clearable="false"
                                v-model="item.price"
                                v-on:keydown="
                                    handleInput($event, 'int', 12)
                                "
                                dense
                                hide-details="auto"
                            >
                            </v-text-field> -->
              <custom-money-input
                :autocomplete="autocompleteValueLocal"
                class="pt-0 to-upper right-aligned-input shrink"
                hide-details="auto"
                outlined
                dense
                tabindex="3"
                style="width: 100px"
                v-model="item.price"
                v-bind:properties="{
                  prefix: currencySymbol,
                  readonly: false,
                  disabled: false,
                  outlined: false,
                  placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                  dense: true,
                  hideDetails: 'auto',
                }"
                v-bind:options="{
                  locale: userLanguageId,
                  length: 12,
                  precision: getDecimalNumber(),
                  empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                }"
                @change="saveCurrentData(50, true)"
              />
            </v-row>
          </td>
          <td class="text-right">
            {{ modifyWrittenStyleOfDots(getDotList(item.dot_quantities)) }}
            <v-icon color="primary" @click="showModifyDotDialog(item.id)"
              >mdi-open-in-new</v-icon
            >
          </td>
          <td>
            <v-row class="justify-end">
              <v-simple-checkbox
                v-model="item.is_verified"
                color="green"
                hide-details
                class="mt-n1"
                :ripple="false"
                @click="saveCurrentData()"
                :disabled="
                  receptionDataForInjecting &&
                  receptionDataForInjecting.is_injected == 0
                    ? false
                    : true
                "
              ></v-simple-checkbox>
            </v-row>
          </td>
          <td>
            <v-icon
              v-if="
                receptionDataForInjecting &&
                receptionDataForInjecting.is_injected == 0
              "
              color="warning"
              class="mr-1"
              @click="deleteArticleFromReceptionAfterConfirmation(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-btn @click="injectReception()" class="d-none" id="inject_button"></v-btn>
    <v-btn
      @click="saveAndVerifyLater()"
      class="d-none"
      id="save_and_verify_later"
    ></v-btn>
    <v-btn
      @click="addArticleToReception()"
      class="d-none"
      id="add_article_to_reception"
    ></v-btn>

    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>

    <modify-article-dot
      v-if="modifyDots"
      :rec_item.sync="rec_items[clickedItemNumber]"
      @change-modify-article-dialog="changeModifyArticleDialog()"
    ></modify-article-dot>

    <ReceptionVerificationInjection
      v-if="isVisibleSupplierOrdersInModel"
      :data="selectedItem"
      :selectedItemIndexData="selectedItemIndexData"
      :parentData="receptionDataForInjecting"
      @change-popup-model-visibility="changeReceptionPopUpModelVisibility"
      :rec_statuses="rec_statuses"
    ></ReceptionVerificationInjection>

    <div class="d-none">
      <reception-search-article
        :class="article ? 'articleset' : 'articlenotset'"
        @changeDialog="changeDialog()"
        :compSelectedArticleId.sync="parentSearchSelectedArticleId"
        :compSearchTextBox.sync="parentSearchTextBox"
        :internalStockOnly.sync="parentInternalStockOnly"
        ref="searchArticle"
      ></reception-search-article>
    </div>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { RECEPTION_INJECT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ReceptionSearchArticle from "@/components/Reception/SearchArticle";
import ConfirmationModel from "@/components/ConfirmationModel";
import ModifyArticleDot from "@/components/Reception/ModifyArticleDot";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import ReceptionVerificationInjection from "@/components/ReceptionVerificationInjection.vue";

export default {
  name: "Reception",
  components: {
    "reception-search-article": ReceptionSearchArticle,
    "confirmation-model": ConfirmationModel,
    "modify-article-dot": ModifyArticleDot,
    "custom-money-input": CutsomMoneyInput,
    ReceptionVerificationInjection,
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.header = { Authorization: "Bearer " + this.token };
    this.$store.commit("setFormMode", RECEPTION_INJECT_MODE);
    this.getSuppliers();
    this.getStatuses();
    if (this.$store.state.editId) {
      this.fetchData();
    } else {
      this.$router.push("/receptions/verify").catch(() => {});
    }
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      token: this.$store.state.AccessToken,
      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      rec_items: [],
      rec_number: null,
      isLoading: false,
      articles: [],
      article: null,
      isLoadingInProgress: false,
      show: false,
      modifyDots: false,
      clickedItemNumber: null,
      reception_data: null,
      parentSearchTextBox: null,
      parentInternalStockOnly: false,
      parentSearchSelectedArticleId: null,

      searchInList: null,

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,

      isAllSelected: false,
      supplier: null,
      suppliers: [],
      isVisibleSupplierOrdersInModel: false,
      selectedItem: null,
      selectedItemIndexData: null,
      rec_statuses: [],
    };
  },
  methods: {
    getStatuses() {
      return new Promise((resolve) => {
        this.getCodes("supplier_order_statuses", "receptions.statuses").then(
          (data) => {
            this.rec_statuses = data;
            resolve();
          }
        );
      });
    },
    changeReceptionPopUpModelVisibility() {
      this.isVisibleSupplierOrdersInModel =
        !this.isVisibleSupplierOrdersInModel;
      this.saveCurrentData(300);
    },
    getPresentSupplierOrders(suppliers) {
      let identifiers = "";
      suppliers.forEach((supplierData) => {
        if (supplierData.identifier) {
          identifiers += supplierData.identifier + ",";
        }
      });
      if (identifiers && identifiers.length > 0) {
        identifiers = identifiers.substring(0, identifiers.length - 1);
      }
      return identifiers;
    },
    getPresentSupplierOrdersCount(suppliers) {
      let count = 0;
      if (suppliers && suppliers.length > 0) {
        suppliers.forEach((supplierData) => {
          if (supplierData.id) {
            count++;
          }
        });
      }
      return count > 0 ? count : "";
    },
    showSupplierOrdersInModel(item) {
      this.selectedItem = item.supplier_orders;
      this.selectedItemIndexData = item;
      this.isVisibleSupplierOrdersInModel = true;
    },
    saveCurrentData(timeoutTime = 50, updateAcquisitonPrice = false) {
      setTimeout(() => {
        this.saveAndVerifyLater(false, updateAcquisitonPrice);
      }, timeoutTime);
    },
    getSuppliers() {
      this.suppliers = [];

      axios
        .get(
          API_BASE_URL +
            "/suppliers?all_suppliers=true&items_per_page=-1&parent_also=true=" +
            (this.searchSupplier
              ? "&search=" + encodeURIComponent(this.searchSupplier)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          let suppliers = [];
          data.data.forEach((element) => {
            if (element.id != 0) {
              suppliers.push(element);
            }
          });
          this.suppliers = suppliers;
        })
        .catch(function (error) {
          console.log("error for suppliers", error);
        })
        .finally(() => {});
    },
    modifyWrittenStyleOfDots(dotsList) {
      if (dotsList) {
        if (dotsList.length > 10) {
          return dotsList.substr(0, 10) + " ...";
        }
        return dotsList;
      }
    },
    showModifyDotDialog(id) {
      this.clickedItemNumber = id - 1;
      this.modifyDots = true;
    },
    changeModifyArticleDialog() {
      this.clickedItemNumber = null;
      this.modifyDots = !this.modifyDots;
      this.saveCurrentData();
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deleteArticleFromReceptionAfterConfirmation(data) {
      this.confirmationDialogData = data;
      this.confirmationDialogConfirmationText = this.$t(
        "receptions.text_messages.delete_article_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t("attachment.delete");
      this.confirmationDialogOperation = "delete_article";
      this.confirmationDialog = true;
    },
    deleteArticle(data) {
      this.rec_items.forEach((element, index) => {
        if (element.article_id == data.article_id) {
          this.rec_items.splice(index, 1);
        }
      });
      this.saveCurrentData();
    },
    updateVerified(item) {
      this.rec_items.forEach((element, index) => {
        if (element.article_id == item.article_id) {
          if (
            item.is_verified == 0 ||
            item.is_verified == false ||
            item.is_verified == "0"
          ) {
            this.rec_items[index].is_verified = true;
          } else if (
            item.is_verified == 1 ||
            item.is_verified == true ||
            item.is_verified == "1"
          ) {
            this.rec_items[index].is_verified = false;
          } else {
            //     "is verified",
            //     this.rec_items,
            //     item.is_verified,
            //     item.is_verified == 0 ||
            //         item.is_verified == false ||
            //         item.is_verified == "0"
          }
        }
      });
    },
    getDotList(dots) {
      if (typeof dots == "object" && dots) {
        let newDots = [];
        for (const [key, value] of Object.entries(dots)) {
          console.log(key);
          newDots.push(value);
        }
        return (newDots ? newDots : []).map((e) => e.dot_property).join(",");
      }
      return (dots ? dots : []).map((e) => e.dot_property).join(",");
    },
    updatePagination(page) {
      this.page = page;
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    setRowColorClass(item) {
      let className = "";
      if (
        item.is_verified == 1 ||
        item.is_verified == true ||
        item.is_verified == "1"
      ) {
        className = "greenColor";
      }

      return className;
    },
    fetchData() {
      if (this.$store.state.editId) {
        axios
          .get(API_BASE_URL + "/receptions/scans/" + this.$store.state.editId, {
            headers: this.header,
            showErrorMessage: false
          })
          .then(({ data }) => {
            this.isAllSelected = false;
            this.reception_data = data;
            this.delivery_note_ref =
              this.reception_data.delivery_note_reference;
            this.supplier = this.reception_data.supplier;
            let count = 1;
            data.scan_data.forEach((element) => {
              element.is_verified = element.is_verified ? true : false;
              element.id = count;
              element.srno = count++;
            });
            this.rec_items = data.scan_data;
            this.$store.commit(
              "setReceptionDataForInjecting",
              this.reception_data
            );
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
            this.$router.push("/receptions/verify").catch(() => {});
          });
      }
    },
    addArticleToDataTableFromSelectedArticle() {
      let count = this.rec_items.length + 1;
      let articleDetail = {
        id: count,
        article: this.article,
        // localisation: this.localisation,
        price: this.article.unit_price,
        totalOrdered: 0,
        confirmed_quantity: 0,
        received_quantity: 0,
        received: 0, // Do not increase received quantity until user makes input
        supplier: this.supplier,
      };

      let itemFound = false;
      this.rec_items.forEach((item, index) => {
        if (item.article.id == this.article.id) {
          // item.received++; Do not increase received quantity until user makes input
          // item.localisation = this.localisation;
          itemFound = true;
          this.array_move(this.rec_items, index, 0);
        }
      });

      if (!itemFound) {
        this.rec_items.push(articleDetail);
        this.array_move(this.rec_items, this.rec_items.length - 1, 0);
      }

      this.article = null;
      this.articles = [];
      this.parentSearchSelectedArticleId = null;
      this.resetAllArticlesIds();
    },
    resetAllArticlesIds() {
      let count = 1;
      this.rec_items.forEach((element) => {
        element.id = count;
        element.srno = count++;
      });
    },
    resetSelectedArticle() {
      this.article = "";
    },
    focusOnSearchEAN() {
      this.$refs["searchArticle"].$refs["searchEAN"].focus();
    },
    saveAndVerifyLater(showRedirectAndMessage = true, updateAcquisitonPrice = false) {
      if (
        this.receptionDataForInjecting &&
        this.receptionDataForInjecting.is_injected == 0
      ) {
        if (this.$store.state.editId) {
            let data = this.makeRequest();
            if(updateAcquisitonPrice){
                data.update_order_acquisition_price = true;
            }
          axios
            .put(
              API_BASE_URL + "/receptions/scans/" + this.$store.state.editId,
              data,
              {
                headers: this.header,
              }
            )
            .then((response) => {
              if (response.status === 200) {
                if (showRedirectAndMessage) {
                  this.$router.push("/receptions/verify").catch(() => {});
                  this.$toast.success(
                    this.$t(
                      "receptions.text_messages.saved_successfully_for_later_injection"
                    )
                  );
                }
              } else {
                this.$toast.error(
                  this.$t("receptions.text_messages.failed_while_saving")
                );
              }
            })
            .catch(() => {
              this.$toast.error(
                this.$t("receptions.text_messages.failed_while_saving")
              );
            })
            .finally(() => {});
        }
      }
    },
    addArticleToReception() {
      this.$refs["searchArticle"].showDialog();
    },
    injectReception() {
      if (this.$store.state.editId) {
        axios
          .post(
            API_BASE_URL +
              "/receptions/scans/" +
              this.$store.state.editId +
              "/inject",
            this.makeRequest(),
            {
              headers: this.header,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$router.push("/receptions/verify").catch(() => {});
              this.$toast.success(
                this.$t("receptions.text_messages.injected_successfully")
              );
            } else {
              this.$toast.error(
                this.$t("receptions.text_messages.failed_while_injecting")
              );
            }
          })
          .catch(() => {
            this.$toast.error(
              this.$t("receptions.text_messages.failed_while_injecting")
            );
          })
          .finally(() => {});
      }
    },
    makeRequest() {
      let reception_items = [];
      this.rec_items.forEach((item) => {
        // push only those, whose received quantity is greater then 0 else, backend code will process them also
        reception_items.push({
          srno: item.srno,
          article_id: item.article ? item.article.id : item.article_id,
          // localisation: item.localisation,
          totalOrdered: item.totalOrdered,
          price: item.price,
          received: parseInt(item.received),
          dot_quantities: item.dot_quantities ?? [],
          supplier_order_id: item.supplier_order_id ?? null,
          is_verified: item.is_verified ?? false,
          supplier_orders: item.supplier_orders,
          before_stock: item.before_stock,
          before_reserve: item.before_reserve,
          after_stock: item.after_stock,
          after_reserve: item.after_reserve,
        });
      });
      let data = {
        delivery_note_reference: this.delivery_note_ref,
        supplier_id:
          this.supplier && typeof this.supplier == "object"
            ? this.supplier.id
            : null,
        rec_items: reception_items,
      };
      return data;
    },
    getBackgroundColorForReceivedBox(ordered_quantity, receivedQuantity) {
      if (ordered_quantity < receivedQuantity) {
        return "rgb(255, 234, 234)"; // red
      } else if (ordered_quantity == receivedQuantity) {
        return "rgb(205, 255, 193)"; // green
      } else {
        return "rgb(242, 255, 153)"; // yellow
      }
    },
    showImage() {
      alert("THE IMAGE");
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
  },
  computed: {
    currencySymbol() {
      let symbol = null;
      if (this.$store.state.topCurrencyType) {
        symbol = this.parseSymbol(
          this.$store.state.topCurrencyType.symbol ?? ""
        );
        return symbol;
      }
      return symbol ?? "";
    },
    filteredItems() {
      if (this.searchInList && this.searchInList.length > 0) {
        return this.rec_items.filter((element) => {
          if (element.article) {
            if (
              element.article.title_description[0].description &&
              element.article.title_description[0].description
                .toUpperCase()
                .includes(this.searchInList.toUpperCase())
            ) {
              return element;
            }
            if (
              element.article.identifier &&
              element.article.identifier
                .toUpperCase()
                .includes(this.searchInList.toUpperCase())
            ) {
              return element;
            }
          }
        });
      }
      this.rec_items.forEach((element) => {
        if (element.supplier_orders && element.supplier_orders.length > 0) {
          let count = 1;
          element.supplier_orders.forEach((supplier_order) => {
            supplier_order.srno = count++;
          });
        }
      });
      return this.rec_items;
    },
    receptionDataForInjecting() {
      return this.$store.state.receptionDataForInjecting;
    },
    headers() {
      let data = [
        {
          text: this.$t("receptions.id"),
          align: "center",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("receptions.article"),
          align: "start",
          sortable: false,
          value: "id",
          width: "73%",
        },
        {
          text: this.$t("receptions.supplier_orders"),
          align: "start",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("receptions.before_stock_reserve"),
          align: "end",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("receptions.ordered_quantity"),
          align: "end",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("receptions.scanned_quantity"),
          align: "end",
          sortable: false,
          value: "id",
          width: "2%",
        },
        {
          text: this.$t("receptions.price"),
          align: "end",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("receptions.dot"),
          align: "end",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("receptions.is_verified"),
          align: "end",
          sortable: false,
          value: "is_verified",
          width: "5%",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "id",
          width: "5%",
        },
      ];

      if (
        this.receptionDataForInjecting &&
        this.receptionDataForInjecting.is_injected == 1
      ) {
        data.splice(4, 0, {
          text: this.$t("receptions.after_stock_reserve"),
          align: "end",
          sortable: false,
          value: "id",
          width: "5%",
        });
      }
      return data;
    },
  },
  watch: {
    isAllSelected(val) {
      if (val) {
        this.rec_items.forEach((element) => {
          element.is_verified = true;
        });
      } else {
        this.rec_items.forEach((element) => {
          element.is_verified = false;
        });
      }
    },
    parentSearchTextBox(val) {
      if (val && val.length == 13 && !isNaN(Number(val))) {
        this.dialog = false;
        this.isLoadingInProgress = true;

        let itemFound = false;
        this.rec_items.forEach((item, index) => {
          if (item.article.identifier == val) {
            // item.received++; Do not increase the input until user inputs
            itemFound = true;
            // item.localisation = this.localisation;
            this.isLoadingInProgress = false;
            this.array_move(this.rec_items, index, 0);
          }
        });

        if (!itemFound) {
          axios
            .get(
              API_BASE_URL +
                "/billings/articles/search?page=1&items_per_page=10" +
                (this.parent_familyClient
                    ? "&family_client_for_pricing=" + (typeof this.parent_familyClient == 'object' ? this.parent_familyClient.id : this.parent_familyClient)
                  : "") +
                ("&identifier=" + val),
              { headers: this.header }
            )
            .then(({ data }) => {
              if (data.data.length == 0) {
                this.$toast.error(
                  this.$t(
                    "scheduler.appointment.no_pricing_found_for_this_article"
                  )
                );
              }
              this.articles = [data.data[0]];
              this.article = data.data[0];
              this.isLoadingArticle = false;

              this.addArticleToDataTableFromSelectedArticle();
            })
            .catch(() => {
              this.isLoadingInProgress = false;
            })
            .finally(() => {
              this.isLoadingInProgress = false;
            });
        }
        this.$nextTick(() => {
          this.parentSearchTextBox = null;
        });
      }
    },
    parentSearchSelectedArticleId(val) {
      if (val && !isNaN(Number(val))) {
        this.dialog = false;
        this.isLoadingInProgress = true;
        axios
          .get(
            API_BASE_URL +
              "/billings/articles/search?page=1&items_per_page=10" +
              (this.parent_familyClient
                ? "&family_client_for_pricing=" + (typeof this.parent_familyClient == 'object' ? this.parent_familyClient.id : this.parent_familyClient)
                : "") +
              ("&article_ids=" + val),
            { headers: this.header }
          )
          .then(({ data }) => {
            if (data.data.length == 0) {
              this.$toast.error(
                this.$t(
                  "scheduler.appointment.no_pricing_found_for_this_article"
                )
              );
            }
            this.articles = [data.data[0]];
            this.article = data.data[0];
            this.isLoadingArticle = false;

            this.addArticleToDataTableFromSelectedArticle();

            let articleFilters = JSON.parse(
              localStorage.getItem("ARTICLE_MODEL_FILTERS")
            );
            articleFilters["id"] = val;
            localStorage.setItem(
              "ARTICLE_MODEL_FILTERS",
              JSON.stringify(articleFilters)
            );
          })
          .catch(() => {
            this.isLoadingInProgress = false;
          })
          .finally(() => {
            this.isLoadingInProgress = false;
          });
      } else {
        this.articles = [];
        this.article = "";
        this.unit_price = 0.0;
        this.red_percentage = 0.0;
      }
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
        if (this.confirmationDialogOperation == "delete_article") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.deleteArticle(this.confirmationDialogData);
          }
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
    rec_items: {
      handler: function () {
        this.$store.commit("setReceptionRecItemsForInjecting", this.rec_items);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.right-aligned-input >>> input {
  text-align: right;
}

.articlenotset {
  display: flex !important;
}
.articleset {
  display: none !important;
}
.selectedArticleTextBox {
  background-color: rgb(220, 255, 220);
}
.reception_received {
  width: 120px;
}
</style>
