import { render, staticRenderFns } from "./SendPdfViaEmail.vue?vue&type=template&id=6923884e&scoped=true"
import script from "./SendPdfViaEmail.vue?vue&type=script&lang=js"
export * from "./SendPdfViaEmail.vue?vue&type=script&lang=js"
import style0 from "./SendPdfViaEmail.vue?vue&type=style&index=0&id=6923884e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6923884e",
  null
  
)

export default component.exports