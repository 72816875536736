<template>
  <v-dialog
    persistent
    @click:outside="falseButtonClicked()"
    @keydown.esc="falseButtonClicked()"
    v-model="internalShowDialog"
    min-width="300"
    max-width="600"
  >
    <v-card>
      <v-card-title class="text-title">
        {{ $t("billings.order_reminders") }}
      </v-card-title>

      <v-card-text class="dialog-text text-content" color="black">
        <v-data-table
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :hide-default-footer="true"
          :headers="headers"
          :items="order_reminders"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          height="400px"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          mustSort
        >
          <template v-slot:item.srno="{ item }">
            {{ item.sr_no }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="isSendingReminder || !parent_email"
          @click="sendReminderAfterConfirmation()"
        >
          {{ $t("billings.manual_order_reminder") }}
          <v-progress-circular
            v-if="isSendingReminder"
            indeterminate
            size="15"
            width="2"
            color="grey"
            class="ma-2"
          ></v-progress-circular>
          <v-icon v-else dark right> mdi-reminder </v-icon>
        </v-btn>
        <v-btn color="grey lighten-2" @click="falseButtonClicked()">
          {{ $t("attachment.close") }}
          <v-icon dark right> mdi-close </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-dialog>
</template>

<script>
import splitPaymentMixin from "@/mixins/splitPaymentMixin";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  name: "OrderReminder",
  components: {
    ConfirmationModel,
  },
  mixins: [splitPaymentMixin],
  props: {
    showDialog: { type: Boolean, default: true },
    order_reminders: {
      type: Array,
    },
    order: {
      type: Object,
      default: null,
    },
    parent_email: {
      type: String,
      default: null,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("articles.id"),
          align: "start",
          sortable: false,
          value: "srno",
          width: "90px",
        },
        {
          text: this.$t("attachment.date"),
          align: "start",
          sortable: false,
          value: "created_at",
        },
      ];
    },
  },
  data() {
    return {
      internalShowDialog: this.showDialog,
      autocompleteValueLocal: "off",
      isSendingReminder: false,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,
    };
  },
  mounted() {},
  methods: {
    saveItem() {},
    falseButtonClicked() {
      this.$emit("update:showDialog", false);
      this.$emit("buttonClicked", false);
      this.internalShowDialog = false;
    },
    triggerManualReminder() {
      if (this.order) {
        this.isSendingReminder = true;
        axios
          .post(API_BASE_URL + "/billings/" + this.order.id + "/remind", {
            headers: this.header,
          })
          .then(() => {
            // Order Reminder
            this.$toast.success(this.$t("billings.reminder_sent_successfully"));
            this.falseButtonClicked();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
          })
          .finally(() => {
            this.isSendingReminder = false;
          });
      }
    },
    sendReminderAfterConfirmation(data) {
      this.confirmationDialogData = data;
      this.confirmationDialogConfirmationText = this.$t(
        "billings.are_you_sure_to_send_order_reminder"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "remind";
      this.confirmationDialog = true;
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
  },
  watch: {
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        // Remind Order
        if (this.confirmationDialogOperation == "remind") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.triggerManualReminder();
          }
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
      },
    },
    order_reminders: {
      handler: function () {
        this.totalItems = this.order_reminders
          ? this.order_reminders.length
          : 0;
        let sr_no = this.totalItems;
        this.order_reminders.forEach((element) => {
          return (element.sr_no = sr_no--);
        });
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$emit("update:showDialog", false);
  },
};
</script>
