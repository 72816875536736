<template>
  <div class="mt-1">
    <div class="d-flex">
      <v-row>
        <div class="ma-0 ml-3 pa-0">
          <span style="font-size: 10px; color: grey">
            {{ $t("stocks.with_tva") }}
          </span>
        </div>
        <div class="ma-0 ml-10 pa-0">
          <span style="font-size: 10px; color: grey">
            {{ $t("stocks.only_with_stock") }}
          </span>
        </div>
        <div class="ma-0 ml-3 pa-0">
          <span style="font-size: 10px; color: grey">
            {{ $t("stocks.fit") }}
          </span>
        </div>
        <div class="ma-0 ml-5 pa-0">
          <span style="font-size: 10px; color: grey">
            {{ $t("stocks.qty") }}
          </span>
        </div>
      </v-row>
    </div>
    <div class="d-flex mt-4">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            small
            dense
            class="mr-2"
            :color="withTvaWithoutTva ? 'primary' : 'grey'"
            @click="toggleWithTvaWithoutTva"
            :disabled="isLoading"
            :dark="isLoading ? false : true"
          >
            <v-icon class="icon_short">mdi-currency-eur</v-icon>
          </v-btn>
        </template>
        {{ $t("stocks.with_tva") }}
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            small
            dense
            :dark="isLoading ? false : true"
            class="mr-2"
            :color="withOnlyInternalStock ? 'warning' : 'grey'"
            @click="toggleWithOnlyInternalStock"
            :disabled="isLoading"
          >
            <v-icon class="icon_short">mdi-table-filter</v-icon>
          </v-btn>
        </template>
        {{ $t("stocks.only_with_stock") }}
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            small
            dense
            :dark="isLoading ? false : true"
            class="mr-2"
            :color="withProposedArticles ? 'success' : 'grey'"
            @click="toggleWithProposedArticles"
            :disabled="isLoading"
          >
            <v-icon class="icon_short">mdi-tools</v-icon>
          </v-btn>
        </template>
        {{ $t("stocks.with_montage") }}
      </v-tooltip>

      <div class="multi-button d-flex">
        <template v-for="(item, index) in buttonArray">
          <template v-if="index != 0">
            <button
              :key="index"
              @click="setModelValue(item)"
              :class="getClassForButtons(item, index)"
              :disabled="isLoading"
            >
              x{{ item }}
            </button>
          </template>
        </template>
        <div
          :class="checkIfExists() ? 'beforeDifferentColor' : 'differentColor'"
        >
          <v-text-field
            style="max-width: 44px"
            :class="'center-aligned-input stock_input'"
            dense
            class="mt-0"
            @focus="$event.target.select()"
            @click="$event.target.select()"
            v-on:keydown="handleInput($event, 'int', 50)"
            hide-details="true"
            v-model="stockUnits"
            :disabled="isLoading"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientPriceInput",
  data() {
    return {
      stockUnits: this.value,
      buttonArray: [0, 1, 2, 4],
    };
  },
  props: {
    familyArticle: [Object],
    isLoading: {
        default: false
    },
    value: {
      default: 4,
    },
    withTvaWithoutTva: {
      type: Boolean,
      default: true,
    },
    withProposedArticles: {
      type: Boolean,
      default: false,
    },
    withOnlyInternalStock: {
      type: Boolean,
      default: false,
    },
    marginRight: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    stockUnits() {
      this.$emit("update:stockUnits", this.stockUnits);
    },
    value(val) {
      this.stockUnits = val;
    },
  },
  methods: {
    toggleWithTvaWithoutTva() {
      this.$emit("update:withTvaWithoutTva", !this.withTvaWithoutTva);
    },
    toggleWithProposedArticles() {
      this.$emit("update:withProposedArticles", !this.withProposedArticles);
    },
    toggleWithOnlyInternalStock() {
      this.$emit("update:withOnlyInternalStock", !this.withOnlyInternalStock);
    },
    checkIfExists() {
      return this.buttonArray.includes(this.stockUnits);
    },
    setModelValue(value) {
      this.stockUnits = value;
      this.$emit("input", value);
    },
    getClassForButtons(item, index) {
      let className = "heightStyle";
      if (item === this.stockUnits) {
        className += " active-button";
      }
      if (index === this.buttonArray.length - 1) {
        className += " last-button";
      }
      return className;
    },
  },
};
</script>

<style scoped>
.text-right >>> input {
  text-align: right;
  padding-right: 2px;
  width: 100%;
}

.multi-button {
  background-color: #eee;
  border-radius: 4px;
  width: 142px !important;
  border: 1px solid #d9d4d4;
  max-height: 30px;
}
.multi-button button {
  height: 25px;
  width: 32px;
  border: none;
  background-color: inherit;
}
.multi-button button:hover {
  background-color: #aacef1;
}
.multi-button button:first-child {
  border-radius: 3px 0 0 3px;
}
.multi-button button:last-child {
  border-radius: 0 3px 3px 0;
}
.active-button {
  background-color: #1976d2 !important;
  color: white !important;
}
.last-button {
  border-right: 1px solid #d9d4d4 !important;
}

.center-aligned-input >>> input {
  text-align: center;
}

.stock_input >>> .v-input__slot::before,
.stock_input >>> .v-input__slot::after {
  border: none !important;
}
.differentColor >>> input {
  color: white !important; /* Change 'red' to any color you prefer */
}
.differentColor {
  background-color: #1976d2;
  border-radius: 0 3px 3px 0;
  color: white !important;
}
.beforeDifferentColor {
  background-color: #ffffffff;
  border-radius: 0 3px 3px 0;
  color: white !important;
}
.heightStyle {
  height: 30px !important;
}
</style>
