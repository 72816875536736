<template>
  <v-dialog
    persistent
    @click:outside="closeDialogue()"
    @keydown.esc="closeDialogue()"
    v-model="internalShowDialog"
    min-width="1348"
    max-width="1648"
  >
    <v-card>
      <v-card-title class="text-title">
        <template v-if="!selectedItem">
          {{ $t("expeditions.add_new_charging") }}
        </template>
        <template v-else>
          {{ $t("expeditions.edit_charging") }}
        </template>
      </v-card-title>

      <v-card-text class="dialog-text text-content" color="black">
        <v-row>
          <v-col cols="12" sm="8">
            <!-- First Row Location and Contact Person and Map -->
            <v-row class="mt-n7">
              <v-col cols="12" sm="12">
                <h3 class="expedition_title" style="color: #2196f3">
                  {{ $t("expeditions.location") }}
                </h3>
                <v-row class="mt-n1">
                  <!-- New Address Component -->
                  <v-col cols="12" sm="12">
                    <AddressInput
                      class="mt-n3 pb-6"
                      v-model="location_address"
                      :error-messages="
                        $store.state.validation && !$v.location_address.required
                          ? [
                              this.$t(
                                'vehicles.clients_model.text_messages.location_address_required'
                              ),
                            ]
                          : []
                      "
                      :defaultAddressType="'billing_address'"
                      :label="$t('billings.shipping_address')"
                      :hide-details="true"
                      :searchWithoutCountry="true"
                      :singleLine="false"
                      :required="true"
                      :detailedViewAllowed="true"
                      :searchWithLocationName="true"
                      :showLocationName="true"
                    />
                  </v-col>
                </v-row>
                <h3 class="expedition_title mt-n6" style="color: #2196f3">
                  {{ $t("expeditions.charging_goods") }}
                </h3>
                <v-row>
                  <!-- Merchandise/Vehicle Type -->
                  <v-col cols="12" sm="6">
                    <v-select
                      clearable
                      v-on:keydown="handleInput($event, 'alphanumeric', 80)"
                      :items="expeditionMerchandiseTypes"
                      v-model="expeditionMerchandiseType"
                      item-value="id"
                      item-text="text"
                      menu-props="auto"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>
                        {{ $t("expeditions.charging_type") }}
                      </template>
                    </v-select>
                  </v-col>
                  <!-- Equipment Type -->
                  <v-col cols="12" sm="6">
                    <v-select
                      clearable
                      v-on:keydown="handleInput($event, 'alphanumeric', 80)"
                      :items="loadingEquipmentTypes"
                      v-model="loadingEquipmentType"
                      item-value="id"
                      item-text="text"
                      menu-props="auto"
                      hide-details
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>
                        {{ $t("expeditions.loading_equipment_type") }}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <!-- Reference -->
              <v-col cols="12" sm="2" class="mb-0">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.reference')"
                  v-model="reference"
                  @change="
                    checkReferenceInExpeditions(
                      reference,
                      isEditExpeditionPage
                        ? $store.state.selectedExpedition.id
                        : null
                    )
                  "
                  dense
                  v-on:keydown.enter.prevent=""
                  v-on:keydown="handleInput($event, 'alphanumeric', 150)"
                  :error-messages="
                    reference && reference.length > 150
                      ? [
                          this.$t(
                            'expeditions.text_messages.max_150_character_allowed'
                          ),
                        ]
                      : []
                  "
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>
                    {{ $t("expeditions.reference") }}
                  </template>
                </v-text-field>
              </v-col>
              <!-- Search Article -->
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col class="d-none" :style="{ maxWidth: '100px' }">
                    <v-autocomplete
                      :autocomplete="autocompleteValueLocal"
                      v-model="familyArticle"
                      :items="familyArticles"
                      :placeholder="$t('articles.family')"
                      item-value="id"
                      item-text="name"
                      @change="fetchArticleListDebounced()"
                      class="mr-0"
                      ref="familyArticle"
                      return-object
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-combobox
                      :autocomplete="autocompleteValueLocal"
                      v-model="selectedArticle"
                      class="to-upper"
                      :items="articles"
                      :search-input.sync="search_article"
                      item-text="original_name"
                      item-value="id"
                      :loading="isLoadingSearchArticle"
                      :placeholder="$t('billings.start_typing')"
                      return-object
                      :clearable="selectedArticle ? true : false"
                      clear-icon="mdi-close"
                      v-on:keydown="handleInput($event, 'alphanumeric', 250)"
                      v-on:keydown.enter.prevent=""
                      ref="billDropdown"
                      :outlined="is_boxes"
                      dense
                      hide-details
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>
                        {{ $t("expeditions.search_article") }}
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Quantity -->
              <v-col cols="12" sm="2">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper"
                  append-icon="mdi-close"
                  v-model="quantity"
                  v-on:keydown="handleInput($event, 'phonenumber', 20)"
                  v-on:keydown.enter.prevent=""
                  v-on:keydown.tab.prevent=""
                  dense
                  hide-details
                >
                  <template #label>
                    {{ $t("expeditions.quantity") }}
                  </template>
                </v-text-field>
              </v-col>
              <!-- Price -->
              <v-col cols="12" sm="2">
                <custom-money-input
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.price')"
                  v-model="good_price"
                  v-on:keydown="handleInput($event, 'int', 20)"
                  v-bind:properties="{
                    prefix: currencySymbol,
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                    dense: true,
                    hideDetails: false,
                  }"
                  v-bind:options="{
                    locale: userLanguageId,
                    length: 10,
                    precision: getDecimalNumber(),
                    empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                  }"
                />
              </v-col>
            </v-row>
            <!-- Tons -->
            <v-row class="mt-n4">
              <v-col cols="12" sm="4">
                <custom-money-input
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.weight')"
                  :required="true"
                  v-model="tons"
                  v-on:keydown="handleInput($event, 'int', 20)"
                  :suffix="$t('expeditions.unit_tons')"
                  v-bind:properties="{
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    placeholder: '0.00',
                    dense: false,
                    hideDetails: false,
                  }"
                  v-bind:options="{
                    locale: userLanguageId,
                    length: 10,
                    precision: 2,
                    empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                  }"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <custom-money-input
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  v-model="floor_meter"
                  :required="true"
                  :label="$t('expeditions.floor_meter')"
                  v-on:keydown="handleInput($event, 'int', 20)"
                  :suffix="$t('expeditions.unit_floor_meter')"
                  v-bind:properties="{
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    placeholder: '0.00',
                    dense: false,
                    hideDetails: false,
                  }"
                  v-bind:options="{
                    locale: userLanguageId,
                    length: 10,
                    precision: 2,
                    empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                  }"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <custom-money-input
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  v-model="meter_cubs"
                  :label="$t('expeditions.meter_cubs')"
                  v-on:keydown="handleInput($event, 'int', 20)"
                  :suffix="$t('expeditions.unit_meter_cubs')"
                  v-bind:properties="{
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    placeholder: '0.00',
                    dense: false,
                    hideDetails: false,
                  }"
                  v-bind:options="{
                    locale: userLanguageId,
                    length: 10,
                    precision: 2,
                    empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                  }"
                />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <!-- Transport Types -->
            <v-row class="mb-5 mt-2"> </v-row>
            <!-- Charging Date and Time -->
            <h6 class="mt-n4 expedition_title" style="color: #2196f3">
              {{ $t("expeditions.charging_date_and_time") }}
            </h6>
            <v-row>
              <v-col cols="12" sm="2">
                <v-menu
                  v-model="menu_search_from_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="search_from_date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:append="search_from_date = null"
                      append-icon="mdi-close"
                      class="mr-2 to-upper"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      hide-no-data
                      hide-details
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>
                        {{ $t("expeditions.from") }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    v-model="search_from_date"
                    @input="menu_search_from_date = false"
                    no-title
                    scrollable
                    :locale="userLanguageId"
                    hide-no-data
                    hide-details
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="2">
                <v-menu
                  v-model="menu_search_to_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="search_to_date"
                      :label="$t('expeditions.to')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:append="search_to_date = null"
                      append-icon="mdi-close"
                      class="mr-2 to-upper"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      hide-no-data
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    v-model="search_to_date"
                    @input="menu_search_to_date = false"
                    no-title
                    scrollable
                    :locale="userLanguageId"
                    hide-no-data
                    hide-details
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="2">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.from')"
                  v-model="search_from_time"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.to')"
                  v-model="search_to_time"
                  type="time"
                ></v-text-field>
              </v-col>
              <!-- Appointment and Share Real time Location -->
              <v-col cols="12" sm="2">
                <v-checkbox
                  :label="$t('expeditions.appointment')"
                  v-model="is_appointment"
                  color="green"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" class="">
                <v-checkbox
                  :label="$t('expeditions.share_real_time_location')"
                  v-model="share_real_time_location"
                  color="green"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

            <!-- Stackable and Exchange Charging Support -->
            <v-row class="mt-n10">
              <v-col cols="12" sm="2">
                <v-select
                  clearable
                  :label="$t('expeditions.truck_types')"
                  :items="expeditionVehicleTypes"
                  v-model="expeditionVehicleType"
                  @change="
                    $emit('update:expeditionVehicleType', expeditionVehicleType)
                  "
                  item-value="id"
                  item-text="text"
                  menu-props="auto"
                  multiple
                  hide-details="auto"
                >
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <template
                        v-if="index === 0 && expeditionVehicleType.length == 1"
                      >
                        {{
                          item.text.substr(
                            0,
                            expeditionVehicleType.length > 1 ? 10 : 12
                          )
                        }}
                      </template>
                      <template v-if="index === 1">
                        {{
                          item.text.substr(
                            0,
                            expeditionVehicleType.length > 1 ? 10 : 12
                          )
                        }}
                        (+{{ expeditionVehicleType.length - 1 }})
                      </template>
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                  clearable
                  :label="$t('expeditions.body_type')"
                  :items="expeditionVehicleBodyTypes"
                  v-model="expeditionVehicleBodyType"
                  @change="
                    $emit(
                      'update:expeditionVehicleBodyType',
                      expeditionVehicleBodyType
                    )
                  "
                  multiple
                  item-value="id"
                  item-text="text"
                  menu-props="auto"
                  hide-details="auto"
                >
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <template
                        v-if="
                          index === 0 && expeditionVehicleBodyType.length == 1
                        "
                      >
                        {{
                          item.text.substr(
                            0,
                            expeditionVehicleBodyType.length > 1 ? 10 : 12
                          )
                        }}
                      </template>
                      <template v-if="index === 1">
                        {{
                          item.text.substr(
                            0,
                            expeditionVehicleBodyType.length > 1 ? 10 : 12
                          )
                        }}
                        (+{{ expeditionVehicleBodyType.length - 1 }})
                      </template>
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                  clearable
                  :label="$t('expeditions.characteristics')"
                  :items="expeditionVehicleBodyCharacteristicsTypes"
                  v-model="expeditionVehicleBodyCharacteristicsType"
                  @change="
                    $emit(
                      'update:expeditionVehicleBodyCharacteristicsType',
                      expeditionVehicleBodyCharacteristicsType
                    )
                  "
                  multiple
                  item-value="id"
                  item-text="text"
                  menu-props="auto"
                  hide-details="auto"
                >
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <template
                        v-if="
                          index === 0 &&
                          expeditionVehicleBodyCharacteristicsType.length == 1
                        "
                      >
                        {{
                          item.text.substr(
                            0,
                            expeditionVehicleBodyCharacteristicsType.length > 1
                              ? 10
                              : 12
                          )
                        }}
                      </template>
                      <template v-if="index === 1">
                        {{
                          item.text.substr(
                            0,
                            expeditionVehicleBodyCharacteristicsType.length > 1
                              ? 10
                              : 12
                          )
                        }}
                        (+{{
                          expeditionVehicleBodyCharacteristicsType.length - 1
                        }})
                      </template>
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-checkbox
                  :label="$t('expeditions.is_stackable')"
                  v-model="is_stackable"
                  color="green"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2">
                <v-checkbox
                  :label="$t('expeditions.exchanging_charging_support')"
                  v-model="exchange_charging_support"
                  color="green"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

            <!-- note -->
            <v-row class="mt-n3">
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="note"
                  :label="$t('expeditions.note')"
                  rows="3"
                  class="to-upper mt-2"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4">
            <v-row class="mt-2">
              <v-col
                sm="12"
                style="
                  min-height: 200px !important;
                  max-height: 200px !important;
                "
              >
                <div
                  id="mapexpeditioncharging"
                  class="map"
                  style="
                    width: 100%;
                    min-height: 175px !important;
                    height: 485px;
                  "
                ></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pr-2">
        <v-btn color="grey lighten-2" class="px-4" @click="closeDialogue()">
          {{ $t("expeditions.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="addingChargeAllowed() ? false : true"
          color="primary"
          class="px-4"
          v-if="!selectedItem"
          @click="addCharge()"
        >
          {{ $t("expeditions.add_charge") }}
          <v-icon dark right> mdi-package-variant-closed </v-icon>
        </v-btn>
        <v-btn
          :disabled="addingChargeAllowed() ? false : true"
          color="primary"
          class="px-4"
          v-if="selectedItem"
          @click="updateCharge()"
        >
          {{ $t("expeditions.update_charge") }}
          <v-icon dark right> mdi-package-variant-closed </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <loading-model :showLoadingModel.sync="showLoadingModel"></loading-model>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import { Map, Marker } from "maplibre-gl";
import { API_BASE_URL } from "@/config";
import AddressInput from "@/components/AddressInput.vue";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput.vue";
import { validationMixin } from "vuelidate";
import AddArticlePayload from "@/payload/ArticleCreate.json";
import LoadingModel from "@/components/LoadingModel.vue";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "ExpeditionCharging",
  validations: {
    location_address: {
      required,
    },
    expeditionMerchandiseTypes: {
      required,
    },
    loadingEquipmentTypes: {
      required,
    },
    selectedArticle: {
      required,
    },
    quantity: {
      required,
    },
    search_from_date: {
      required,
    },
    reference: {
      required,
      maxLength: maxLength(150),
    },
  },
  components: {
    AddressInput,
    "custom-money-input": CutsomMoneyInput,
    "loading-model": LoadingModel,
  },
  props: {
    showDialog: [Number, Boolean],
    passedData: [Object],
    openMode: [String],
    languages: [Array],
    loadingEquipmentTypes: [Array],
    expeditionMerchandiseTypes: [Array],
    selectedItem: [Object],
    expeditionVehicleType: [Array, Object],
    expeditionVehicleTypes: [Array, Object],
    expeditionVehicleBodyType: [Array, Object],
    expeditionVehicleBodyTypes: [Array, Object],
    expeditionVehicleBodyCharacteristicsType: [Array, Object],
    expeditionVehicleBodyCharacteristicsTypes: [Array, Object],
  },
  data() {
    return {
      showLoadingModel: false,
      mapCordinates: [6.131935, 49.611622],
      internalShowDialog: this.showDialog,
      location_address: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      note: null,
      autocompleteValueLocal: "off",
      familyArticle: null,
      menu_search_from_date: false,
      search_from_date: null,
      menu_search_to_date: false,
      search_to_date: null,
      menu_search_from_time: false,
      search_from_time: "00:00",
      menu_search_to_time: false,
      search_to_time: "23:59",
      is_appointment: null,
      share_real_time_location: null,
      expeditionMerchandiseType: null,
      loadingEquipmentType: null,
      quantity: 0,
      good_price: null,
      search_article: null,
      articles: [],
      tons: null,
      isLoadingSearchArticle: false,
      selectedArticle: null,
      floor_meter: 0,
      meter_cubs: 0,
      meter_height: 0,
      meter_length: 0,
      meter_width: 0,

      is_stackable: false,
      exchange_charging_support: false,
      reference: null,
    };
  },
  methods: {
    onResetPageAddress() {
      this.location_address = null;
    },
    addingChargeAllowed() {
      let isAllowed = false;
      if (
        this.search_from_date &&
        this.reference &&
        this.reference.length < 150 &&
        this.loadingEquipmentType &&
        this.expeditionMerchandiseType &&
        this.selectedArticle &&
        this.familyArticle &&
        this.location_address &&
        typeof this.location_address == "object" &&
        this.floor_meter &&
        this.tons
      ) {
        isAllowed = true;
      }
      return isAllowed;
      // return true;
    },
    setDefaultFamilyArticle() {
      let defaultFamilyArticle = null;
      this.familyArticles.forEach((element) => {
        if (element.is_default) {
          defaultFamilyArticle = element;
        }
      });
      if (!defaultFamilyArticle) {
        this.familyArticle = this.familyArticles[0];
      } else {
        this.familyArticle = defaultFamilyArticle;
      }
    },
    resetChargePage() {
      this.note = null;
      this.menu_search_from_date = false;
      this.search_from_date = null;
      this.menu_search_to_date = false;
      this.search_to_date = null;
      this.menu_search_from_time = false;
      this.search_from_time = "00:00";
      this.menu_search_to_time = false;
      this.search_to_time = "23:59";
      this.is_appointment = false;
      this.share_real_time_location = false;
      this.expeditionMerchandiseType = null;
      this.loadingEquipmentType = null;
      this.quantity = 0;
      this.good_price = null;
      this.search_article = null;
      this.articles = [];
      this.selectedArticle = null;
      this.tons = null;
      this.isLoadingSearchArticle = false;
      this.floor_meter = null;
      this.meter_cubs = null;
      this.meter_height = null;
      this.meter_length = null;
      this.meter_width = null;
      this.is_stackable = false;
      this.exchange_charging_support = false;
      this.reference = null;
    },
    saveNecessaryData() {
      return new Promise((resolve) => {
        this.showLoadingModel = true;
        this.saveArticle().then(() => {
          this.showLoadingModel = false;
          resolve();
        });
      });
    },
    saveArticle() {
      return new Promise((resolve, reject) => {
        if (typeof this.selectedArticle == "object") {
          return resolve();
        }
        let data = AddArticlePayload;
        data.data.familyArticle = this.familyArticle;
        data.data.price = this.good_price ?? 0;
        data.data.dynamicProperties.name = this.selectedArticle;
        data.data.dynamicProperties.original_name = this.selectedArticle;
        if (data.data.dynamicProperties.name) {
          axios
            .post(API_BASE_URL + "/articles", data, { headers: this.header })
            .then((response) => {
              response.data.data.id = response.data.data.article_id;
              this.articles = [response.data.data];
              this.selectedArticle = this.articles[0];
              return resolve();
            })
            .catch((error) => {
              if (error.response.status === 409) {
                this.$toast.error(
                  this.$t("articles.text_messages.article_already_exists")
                );
                return reject();
              }
            });
        }
      });
    },
    addChargeAndAddNew() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        return false;
      }
      this.saveNecessaryData().then(() => {
        let data = this.makeChargeData();
        this.$emit("added", data);
        this.$emit("reOpenDialog", data);
        // this.resetChargePage();
        this.$store.commit("setValidation", false); // enable validation
        // this.closeDialogue();
      });
    },
    updateChargeAndAddNew() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        return false;
      }
      this.saveNecessaryData().then(() => {
        let data = this.makeChargeData();
        this.$emit("updated", data);
        this.$emit("reOpenDialog", data);
        // this.resetChargePage();
        this.$store.commit("setValidation", false); // enable validation
        // this.closeDialogue();
      });
    },
    updateCharge() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        return false;
      }
      this.saveNecessaryData().then(() => {
        let data = this.makeChargeData();
        this.$emit("updated", data);
        // this.resetChargePage();
        this.$store.commit("setValidation", false); // enable validation
        this.closeDialogue();
      });
    },
    addCharge() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        return false;
      }
      this.saveNecessaryData().then(() => {
        let data = this.makeChargeData();
        this.$emit("added", data);
        // this.resetChargePage();
        this.$store.commit("setValidation", false); // enable validation
        this.closeDialogue();
      });
    },
    makeChargeData() {
      let data = {
        address: this.location_address,
        note: this.note,
        search_from_date: this.search_from_date,
        search_to_date: this.search_to_date,
        search_from_time: this.search_from_time,
        search_to_time: this.search_to_time,
        is_appointment: this.is_appointment,
        share_real_time_location: this.share_real_time_location,
        expeditionMerchandiseType: this.expeditionMerchandiseType,
        loadingEquipmentType: this.loadingEquipmentType,
        quantity: this.quantity,
        good_price: this.good_price,
        tons: this.tons,
        selectedArticle: this.selectedArticle,
        selectedFamily: this.familyArticle,
        floor_meter: this.floor_meter,
        meter_cubs: this.meter_cubs,
        meter_height: this.meter_height,
        meter_length: this.meter_length,
        meter_width: this.meter_width,
        is_stackable: this.is_stackable,
        exchange_charging_support: this.exchange_charging_support,
        reference: this.reference,
      };
      return data;
    },
    addDischarge() {
      this.$emit("changeDialogue", true);
    },
    closeDialogue() {
      this.$emit("update:showDialog", false);
      this.internalShowDialog = false;
    },
    fetchArticleListDebounced() {
      // if (!this.search_article || typeof this.search_article == "object") {
      //     return;
      // }
      this.isLoadingSearchArticle = true;
      // This means it is in edit mode
      axios
        .get(
          API_BASE_URL +
            "/articles?page=1&items_per_page=15" +
            (this.familyArticle ? "&family=" + this.familyArticle.id : "") +
            (this.search_article
              ? "&search=" + encodeURIComponent(this.search_article)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.articles = data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoadingSearchArticle = false;
        });
    },
    async reloadMap() {
      if (this.mapCordinates && this.mapCordinates.length > 0) {
        setTimeout(() => {
          try {
            let mapOptions = {
              container: "mapexpeditioncharging",
              style:
                "https://api.maptiler.com/maps/streets-v2/style.json?key=f4IJi1mRlspltChrcUM3",
              zoom: 15,
              center: this.mapCordinates,
            };
            let map = new Map(mapOptions);
            if (this.mapCordinates) {
              new Marker({ color: "#FF0000" })
                .setLngLat(this.mapCordinates)
                .addTo(map);
            }
          } catch (e) {
            console.log("error on loading map", e);
          }
        }, 500);
      }
    },
  },
  mounted() {
    this.onResetPageAddress();
    this.$store.commit("setValidation", false); // enable validation
    this.setDefaultFamilyArticle();
    if (this.passedData && this.openMode == "add") {
      this.expeditionMerchandiseType = this.expeditionMerchandiseTypes[0];
      let currentDate = this.getDateByXDays(1);
      this.search_from_date = this.formatDate(currentDate, "yyyy-mm-dd");
    }
    // Edit Case Handle
    if (this.selectedItem && this.openMode == "edit") {
      this.note = this.selectedItem.note;
      this.location_address = this.selectedItem.address;
      this.search_from_date = this.selectedItem.search_from_date;
      this.search_to_date = this.selectedItem.search_to_date;
      this.search_from_time = this.selectedItem.search_from_time;
      this.search_to_time = this.selectedItem.search_to_time;
      this.is_appointment = this.selectedItem.is_appointment;
      this.share_real_time_location =
        this.selectedItem.share_real_time_location;
      this.expeditionMerchandiseType =
        this.selectedItem.expeditionMerchandiseType;
      this.loadingEquipmentType = this.selectedItem.loadingEquipmentType;
      this.quantity = this.selectedItem.quantity;
      this.good_price = this.selectedItem.good_price;
      this.tons = this.selectedItem.tons;
      this.selectedArticle = this.selectedItem.selectedArticle;
      this.selectedFamily = this.selectedItem.familyArticle;
      this.floor_meter = this.selectedItem.floor_meter;
      this.meter_cubs = this.selectedItem.meter_cubs;
      this.meter_height = this.selectedItem.meter_height;
      this.meter_length = this.selectedItem.meter_length;
      this.meter_width = this.selectedItem.meter_width;
      this.is_stackable = this.selectedItem.is_stackable;
      this.exchange_charging_support =
        this.selectedItem.exchange_charging_support;
      this.reference = this.selectedItem.reference;
    }
    this.fetchArticleListDebounced();
    this.$nextTick(() => {
      this.reloadMap();
    });
  },
  computed: {
    currencySymbol() {
      return this.userCurrencySymbol;
    },
    isEditExpeditionPage() {
      return this.$route.path.indexOf("/edit") > 0;
    },
  },
  watch: {
    location_address(val) {
      if (val && val.lat && val.long) {
        this.mapCordinates = [val.long, val.lat];
        this.reloadMap();
      }
    },
    search_article(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchArticleListDebounced();
      }, 500);
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
      },
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 27px !important;
}
.text-content {
  font-size: 18px !important;
}
.text-title {
  font-size: 24px !important;
}
</style>
