<template>
  <div style="overflow-y: auto">
    <div v-if="$store.state.addRentalVehicleFormActive == false">
      <v-card class="elevation-0">
        <v-tabs
          v-if="checkTag('rentals')"
          v-model="tab"
          background-color="light-blue darken-2"
          dark
          align-with-title
          center-active
          height="50px"
        >
          <v-tab v-if="checkTag('rentals')" href="#vehicle-rental-history">
            {{ $t("router_titles.rentals_history") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="'vehicle-rental-history'"
            :value="'vehicle-rental-history'"
          >
            <rental-history :vehicle="vehicle"></rental-history>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <div v-else>
      <add-rental-history />
    </div>
  </div>
</template>

<script>
import RentalHistory from "@/components/Rentals/RentalHistoryTab";
import AddRentalsHistory from "@/views/Rentals/Booking";
// import DocumentTab from "@/components/Rentals/DocumentTab";

export default {
  components: {
    "rental-history": RentalHistory,
    "add-rental-history": AddRentalsHistory,
  },
  props: {
    vehicle: [Object],
  },
  name: "Rental-Tabs",
  data() {
    return {
      tab: null,
      showBookingDialog: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
