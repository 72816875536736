<template>
  <div class="pa-4 border-box">
    <div class="card card-stats mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2 class="card-title mb-0 pb-4">{{ card.title }}</h2>
            <NoteEditor
              :showLabel="false"
              v-model="note"
              id="dashboard_notes_editr"
            ></NoteEditor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoteEditor from "../NoteEditor.vue";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  name: "NotesCard",
  props: {
    card: [Object],
  },
  data() {
    return {
      note: null,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get(API_BASE_URL + "/user_card/" + this.card.id, {
          headers: this.header,
        })
        .then(({ data }) => {
          this.note = data.data ? data.data.notes ?? "" : "";
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
  watch: {
    note() {
      let data = {
        notes: this.note,
      };

      axios
        .put(API_BASE_URL + "/user_card/" + this.card.id, data, {
          headers: this.header,
        })
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    },
  },
  components: { NoteEditor },
};
</script>
<style scoped>
h2,
h5,
.h2,
.h5 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  color: #32325d;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

.border-box {
  border: 1px solid #eeeeee;
}
.bg-danger {
  background-color: #f5365c !important;
}

.font-weight-bold {
  font-weight: 600 !important;
  font-size: 30px;
}

a.text-success:hover,
a.text-success:focus {
  color: #24a46d !important;
}

.text-warning {
  color: #fb6340 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fa3a0e !important;
}

.text-danger {
  color: #f5365c !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ec0c38 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-muted {
  color: #8898aa !important;
}

figcaption,
main {
  display: block;
}

main {
  overflow: hidden;
}

.bg-yellow {
  background-color: #ffd600 !important;
}

.icon {
  width: 3rem;
  height: 3rem;
}

.icon i {
  font-size: 2.25rem;
}

.icon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

#dashboard_notes_editr >>> .editr {
  min-height: 240px !important;
  max-height: 240px !important;
}

#dashboard_notes_editr >>> .editr--content {
  min-height: 200px !important;
  max-height: 200px !important;
  overflow-y: scroll !important;
}
</style>
