<template>
  <v-dialog v-model="dialog" width="700">
    <v-card>
      <v-card-title>
        <span class="text-h5">Suppliers List</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="suppliers"
            :options="options"
            :loading="progressBarLoading"
            :server-items-length="totalItems"
            :items-per-page="itemsPerPage"
            @update:page="updatePagination"
            @click:row="(item) => $emit('selectSupplier', item)"
            :footer-props="{
              'items-per-page-options': dataTableOptions,
            }"
            class="elevation-0"
          >
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import axios from "@/plugins/axios";
import { mapState } from "vuex";
export default {
  name: "SuppliersList",
  emits: ["closeDialog"],
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
    selectedFamilyArticle: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState(["progressBarLoading"]),
    options() {
      return {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
      };
    },
  },
  data: () => ({
    dialog: false,
    suppliers: [],
    itemsPerPage: 10,
    totalItems: 0,
    dataTableOptions: [10, ...DEFAULT_ITEMS_PER_PAGE_OPTIONS],
    page: 1,
    headers: [
      {
        text: "Identifier",
        value: "identifier",
        align: "left",
        sortable: false,
      },
      {
        text: "name",
        value: "name",
        align: "left",
        sortable: false,
      },
    ],
  }),
  watch: {
    toggle: function (val) {
      this.dialog = val;
      if (val) {
        this.fetchData();
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    fetchData() {
      axios
        .get(
          API_BASE_URL +
            "/suppliers?page=" +
            this.page +
            "&items_per_page=" +
            this.itemsPerPage +
            "&family_id=" +
            this.selectedFamilyArticle.id,
          {
            headers: {
              Authorization: `Bearer ` + this.$store.state.AccessToken,
            },
          }
        )
        .finally(() => {})
        .then((response) => {
          let data = response.data.data;

          // check if Internal Supplier is Included
          let internalSupplier = data.find((supplier) => supplier.id === 0);

          // remove internal Supplier from List
          if (internalSupplier) {
            data = data.filter((supplier) => supplier.id !== 0);

            // Add Internal Supplier to the top of the List
            data.unshift(internalSupplier);
          }

          this.suppliers = data;

          this.totalItems = response.data.meta.total;
          this.page = response.data.meta.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
