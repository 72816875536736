import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  activated() {
    this.initializeData();
  },
  mounted() {
    this.initializeData();
  },
  data() {
    return {
      addressBooks: [],
    };
  },
  methods: {
    initializeData() {
      this.getAddressBooks();
    },
    getAddressBooks() {
      axios
        .get(API_BASE_URL + `/user_address_book`, {
          params: {
            page: this.page,
            per_page: this.perPage,
            search: this.search,
          },
        })
        .then((response) => {
          this.addressBooks = response.data.data;
          this.totalItems = response.data.total;
          this.formatItems();
        });
    },
  },
  deactivated() {
    this.addressBooks = [];
  },
};
