<template>
  <div class="text-start" v-if="value">
    <span v-if="showCharLength"> ({{ valueToCopy.length }}) </span>
    {{ value }}
    <v-icon
      :color="'green'"
      :size="14"
      @click.stop="copyText(valueToCopy ?? value)"
    >
      mdi-content-copy
    </v-icon>
  </div>
</template>
<script>
export default {
  props: {
    value: [String],
    valueToCopy: {
      type: String,
      default: null,
    },
    showCharLength: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
};
</script>
