<template>
  <div>
    <v-dialog class="dialogPopUp" :value="value" width="1500" v-if="depot">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("tyre_hotel.return_request") }}</span>
          <v-spacer />
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" :sm="autoBulk ? 6 : 5">
              <v-menu
                v-model="dayPickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedDay"
                    :label="$t('clocking_section.filter_options.select_day')"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedDay"
                  @input="dayPickerMenu = false"
                  :min="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" :sm="autoBulk ? 6 : 5">
              <v-combobox
                v-model="depotManagerName"
                :items="depotManagerNamePredictions"
                :label="$t('tyre_hotel.deposit.depot_manager_name')"
                class="to-upper"
                @update:search-input="getManagersNamePredictions"
                clearable
              />
            </v-col>
            <v-col cols="12" :sm="2" v-if="!autoBulk">
              <v-checkbox
                v-model="performBulkOperation"
                :label="$t('tyre_hotel.perform_bulk_operation')"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" v-if="performBulkOperation">
              <v-textarea v-model="bulkData" :label="$t('')" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <span @click="close()" class="mr-3"> <CloseButton /> </span>
          <span @click="launchConfirmation()"><SaveButton /></span>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <depot-bulk-operation-detail
      v-model="depotBulkOperationDetailDialog"
      :depot-bulk-operation="depotBulkOperation"
    />
  </div>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import confirmationModel from "@/components/ConfirmationModel.vue";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import DepotBulkOperationDetail from "@/components/TyreHotel/DepotBulkOperationDetail.vue";

export default {
  name: "ReturnRequestModal",
  components: {
    DepotBulkOperationDetail,
    SaveButton,
    confirmationModel,
    CloseButton,
  },
  emits: ["updated", "created"],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    depot: {
      default: null,
    },
    autoBulk: {
      default: false,
    },
  },
  data() {
    return {
      dayPickerMenu: false,
      selectedDay: null,
      depotManagerName: null,
      depotManagerNamePredictions: [],
      performBulkOperation: false,
      bulkData: null,
      processedBulkData: [],
      confirmationDialog: false,
      confirmationDialogOperation: null,
      confirmationDialogConfirmationText: null,
      confirmationDialogTrueText: null,
      confirmationDialogFalseText: null,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      depotBulkOperation: null,
      depotBulkOperationDetailDialog: false,
    };
  },
  watch: {
    value() {
      if (this.value) {
        this.selectedDay = null;
        this.depotManagerName = null;

        if (this.autoBulk) {
          this.performBulkOperation = true;
        }
      }
    },
  },
  methods: {
    close() {
      this.bulkData = null;
      this.$emit("input", false);
    },
    confirmationButtonClicked(value) {
      if (!value) {
        return false;
      }

      if (this.confirmationDialogOperation === "return_request") {
        this.returnRequest();
      }
    },
    launchConfirmation() {
      if (this.selectedDay) {
        this.confirmationDialogConfirmationText = this.$t(
          "tyre_hotel.return_request_confirmation_text"
        );
        this.confirmationDialogFalseText = this.$t(
          "tyre_hotel.return_request_false_button_text"
        );
        this.confirmationDialogTrueText = this.$t(
          "tyre_hotel.return_request_true_button_text"
        );
        this.confirmationDialogOperation = "return_request";
        this.confirmationDialog = true;
      } else {
        this.$toast.error(
          this.$t("clocking_section.filter_options.select_day")
        );
      }
    },
    returnRequest() {
      if (this.performBulkOperation && this.bulkData) {
        return this.processBulkData();
      }
      axios
        .put(
          API_BASE_URL + "/depots/" + this.depot.id + "/rr",
          {
            positions: this.getSelectedDepotPositions(),
            rr_date: this.selectedDay,
            depot_manager: this.depotManagerName,
          },
          { headers: this.header }
        )
        .then(() => {
          this.close();
          this.$emit("updated");
        })
        .catch(() => {})
        .finally(() => {});
    },

    processBulkData() {
      axios
        .post(
          API_BASE_URL + "/depots/bulk_operations",
          {
            data: this.bulkData,
            date: this.selectedDay,
            depot_manager: this.depotManagerName,
          },
          { headers: this.header }
        )
        .then((data) => {
          this.processedBulkData = data.data;
          this.$emit("updated", this.processedBulkData);
          this.close();
          this.depotBulkOperationDetailDialog = false;
          setTimeout(() => (this.depotBulkOperationDetailDialog = true), 1);
          this.depotBulkOperation =
            this.processedBulkData.data.depot_bulk_operation;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    getSelectedDepotPositions() {
      let selectedDepotIds = [];
      if (this.depot && this.depot.details) {
        this.depot.details.forEach((item) => {
          if (item.checked == true) {
            selectedDepotIds.push(item.position_id);
          }
        });
      }
      return selectedDepotIds;
    },
    getManagersNamePredictions(search) {
      axios
        .get(API_BASE_URL + "/depots/managers?search=" + search, {
          headers: this.header,
        })
        .then((data) => {
          this.depotManagerNamePredictions = data.data.data;
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>
