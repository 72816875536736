import { mapState } from "vuex";
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      articlePayments: {},
    };
  },
  computed: {
    ...mapState(["selectedExpenseData"]),
    totalPaid() {
      let total = 0;
      if (this.articlePayments && this.articlePayments.length > 0) {
        this.articlePayments.forEach((item) => {
          if (item && typeof item == "object" && item.amount) {
            total += parseFloat(item.amount || 0);
          } else if (item && typeof item == "number") {
            total += parseFloat(item.amount || 0);
          }
        });
      }

      return total.toFixed(2);
    },
    creditNoteMultiplicationValueLocal() {
      return (
        this.getCompanyPropertyFromLocalStorage(
          "CREDIT_NOTE_MULTIPLICATION_VALUE"
        ) ?? this.creditNoteMultiplicationDefaultValue
      ).value;
    },
    isSelectedBillCreditNote() {
      if (
        this.selectedBillData &&
        typeof this.selectedBillData == "object" &&
        this.selectedBillData.type &&
        typeof this.selectedBillData.type == "object" &&
        this.selectedBillData.type.key == "credit_note"
      ) {
        return true;
      }
      return false;
    },
    paymentBalance() {
      let total = parseFloat((this.articleTotal - this.totalPaid).toFixed(2));
      if (
        this.isSelectedBillCreditNote &&
        this.creditNoteMultiplicationValueLocal < 0
      ) {
        return total.toFixed(2);
      }
      return (total > 0 ? total : 0).toFixed(2);
    },
    selectedExpenseType() {
      if (!this.selectedExpenseData) {
        return null;
      }

      return this.selectedExpenseData?.type?.key ?? null;
    },
  },
  watch: {
    paymentBalance: {
      handler: function () {
        if (this.articlePayments && this.articlePayments.length > 0) {
          // Mark only Paid/Partial Paid when atleast one item is added in articlePayments
          if (parseFloat(this.paymentBalance) == 0) {
            this.setExpenseStatus("payed");
          } else if (
            parseFloat(this.selectedExpenseData.total) >
            parseFloat(this.paymentBalance)
          ) {
            this.setExpenseStatus("partial_payed");
          } else {
            this.setExpenseStatus("waiting");
          }
        } else if (
          this.articlePayments &&
          this.articlePayments.length == 0 &&
          this.isPartialOrPaid()
        ) {
          if (
            this.order_status &&
            (this.order_status.key == "payed" ||
              this.order_status.key == "partial_payed")
          ) {
            this.setExpenseStatus("waiting");
          }
        }
      },
    },
    selectedExpenseData(val) {
      this.articlePayments = [];

      let payments = val?.payments || null;
      if (
        !payments ||
        !Array.isArray(payments) ||
        (Array.isArray(payments) && payments.length === 0)
      ) {
        return;
      }

      payments.forEach((payment) => {
        this.articlePayments.push(payment);
        // this.articlePaymentsTime[payment.payed_by_id] = payment.updated_at ?? moment().format('YYYY-MM-DD');
      });
    },
  },
  methods: {
    async setExpenseStatus(status_key) {
      let allOrderStatuses = [];
      await this.getCodes("order_status", "billings.statuses").then((data) => {
        allOrderStatuses = data;
      });
      if (allOrderStatuses && allOrderStatuses.length > 0) {
        allOrderStatuses.forEach((element) => {
          if (element.key == status_key) {
            this.order_status = element;
          }
        });
      }
    },
    async isPartialOrPaid() {
      let isPartialOrPaidStatus = false;
      let allOrderStatuses = [];
      await this.getCodes("order_status", "billings.statuses").then((data) => {
        allOrderStatuses = data;
      });
      if (allOrderStatuses && allOrderStatuses.length > 0) {
        allOrderStatuses.forEach((element) => {
          if (element.key == "partial_payed" || element.key == "payed") {
            isPartialOrPaidStatus = true;
          }
        });
      }
      return isPartialOrPaidStatus;
    },
    addRemainingExpenseToField(item) {
      let balance = parseFloat(this.articleTotal).toFixed(2) - this.totalPaid;

      this.saveExpensePayment(item, balance.toFixed(2));
    },
    saveExpensePayment(item, payed_by_id, amount, executed_at) {
      return new Promise((resolve) => {
        const payload = {
          expense_id: this.selectedExpenseData.id,
          amount: amount ?? 0,
          payed_by_id: payed_by_id
            ? typeof payed_by_id == "object"
              ? payed_by_id.id
              : payed_by_id
            : null,
          currency_id: this.$store.state.topCurrencyType
            ? this.$store.state.topCurrencyType.id
            : this.defaultCurrencyId,
          is_total_paid: this.paymentBalance - amount <= 0 ? 1 : 0,
        };
        if (executed_at) {
          payload["executed_at"] =
            DateTime.fromISO(executed_at).toFormat("yyyy-MM-dd HH:mm");
        }

        const existingPayment =
          item &&
          typeof item == "object" &&
          item.id &&
          typeof item.id == "number"
            ? item
            : null;

        const requestMethod = existingPayment ? "put" : "post";

        const url =
          requestMethod === "post"
            ? API_BASE_URL + "/expense_payments"
            : API_BASE_URL + "/expense_payments/" + existingPayment.id;

        const headers = {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        };

        this.isPaymentAPIInProgress = true;
        axios[requestMethod](url, payload, headers)
          .then(({ data }) => {
            return resolve(data);
          })
          .finally(() => {
            this.isPaymentAPIInProgress = false;
          });
      });
    },
  },
  deactivated() {
    this.articlePayments = {};
  },
  destroyed() {
    this.articlePayments = {};
  },
};
