<template>
  <div style="display: inline-block">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          class="mt-n1"
          @click="showBillNote()"
          :size="18"
          dense
          :color="localNote ? 'red' : 'grey'"
          dark
        >
          {{ localNote ? "mdi-information" : "mdi-information-outline" }}
        </v-icon>
      </template>
      <span> {{ localNote }} </span>
    </v-tooltip>
    <NoteEditorModal :dialog.sync="modelVisible" v-model="localNote" />
  </div>
</template>

<script>
import NoteEditorModal from "@/components/NoteEditorModal.vue";

export default {
  components: {
    NoteEditorModal,
  },
  props: {
    note: {
      type: String,
    },
  },
  data() {
    return {
      modelVisible: false,
      localNote: this.note,
    };
  },
  methods: {
    showBillNote() {
      this.modelVisible = true;
    },
  },
  watch: {
    modelVisible(val) {
      if (!val) {
        this.$emit("update:note", this.localNote);
      }
    },
    note(newVal) {
      this.localNote = newVal;
    },
  },
};
</script>

<style scoped></style>
