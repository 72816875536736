<template>
  <v-card class="elevation-0">
    <v-tabs v-model="tab" height="50px" background-color="primary" dark>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#client-addresses">
        <v-icon class="mr-1">mdi-map-search-outline</v-icon>
        {{ $t("company_settings.tabs.addresses") }}
      </v-tab>

      <v-tab href="#client-bills">
        <v-icon class="mr-1">mdi-currency-eur</v-icon>
        {{ $t("company_settings.tabs.working_points") }}
      </v-tab>

      <v-tab href="#client-banks">
        <v-icon class="mr-1">mdi-bank</v-icon>
        {{ $t("company_settings.tabs.bank_accounts") }}
      </v-tab>

      <v-tab href="#client-email">
        <v-icon class="mr-1">mdi-email-open-outline</v-icon>
        {{ $t("company_settings.tabs.email_settings") }}
      </v-tab>

      <v-tab href="#client-others">
        <v-icon class="mr-1">mdi-window-shutter-cog</v-icon>
        {{ $t("company_settings.tabs.others") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="'client-addresses'" :value="'client-addresses'">
        <addresses-tab
          :prop_address.sync="tab_billing_address"
          :prop_shipping_address.sync="tab_shipping_address"
        ></addresses-tab>
      </v-tab-item>

      <v-tab-item :key="'client-bills'" :value="'client-bills'">
        <working-points
          :prop_workpointdata.sync="tab_working_point"
          class="pa-4"
        ></working-points>
      </v-tab-item>

      <v-tab-item key="client-banks" value="client-banks">
        <CompanyBanks :company-id="3" />
      </v-tab-item>

      <v-tab-item key="client-email" value="client-email">
        <CompanyEmails :prop_company_properties="prop_company_properties" />
      </v-tab-item>

      <v-tab-item key="client-others" value="client-others">
        <CompanyOthers :prop_company_properties="prop_company_properties" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AddressesTab from "@/components/CompanySettings/AddressesTab";
import WorkingPoints from "@/components/CompanySettings/WorkingPoints";
import CompanyBanks from "@/components/CompanySettings/CompanyBanks.vue";
import CompanyEmails from "@/components/CompanySettings/CompanyEmails.vue";
import CompanyOthers from "@/components/CompanySettings/CompanyOthers.vue";

export default {
  name: "Tabs",
  components: {
    CompanyBanks,
    CompanyEmails,
    CompanyOthers,
    "addresses-tab": AddressesTab,
    "working-points": WorkingPoints,
  },
  props: {
    prop_address: [Object],
    prop_shipping_address: [Object],
    prop_workpointdata: [Array],
    prop_company_properties: [Array],
  },
  data() {
    return {
      tab_billing_address: null,
      tab_shipping_address: null,
      tab_working_point: [],
      tab: null,
      text: "",
    };
  },
  watch: {
    tab_billing_address() {
      this.$emit("update:prop_address", this.tab_billing_address);
      // this.$emit("update:prop_shipping_address", this.tab_shipping_address);
    },
    tab_shipping_address() {
      // this.$emit("update:prop_address", this.tab_billing_address);
      this.$emit("update:prop_shipping_address", this.tab_shipping_address);
    },
    tab_working_point(val) {
      this.prop_workpointdata = val;
      this.$emit("update:prop_workpointdata", val);
    },
    prop_workpointdata(val) {
      this.tab_working_point = val;
    },
    prop_address(val) {
      this.tab_billing_address = val;
    },
    prop_shipping_address(val) {
      this.tab_shipping_address = val;
    },
  },
};
</script>

<style scoped></style>
