var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('v-data-table',{key:_vm.$store.state.forceReloadComponentKey,staticClass:"custom-table",attrs:{"headers":_vm.headers,"hide-default-header":true,"items":_vm.formattedItems,"options":{
      itemsPerPage: _vm.perPage,
      itemsPerPageOptions: _vm.perPageOptions,
      page: _vm.page,
    },"height":"calc(-130px + 100vh)","fixed-header":"","footer-props":{
      'items-per-page-options': _vm.perPageOptions,
    },"server-items-length":_vm.totalItems},on:{"update:page":(newPage) => {
        _vm.page = newPage;
        _vm.getCompanyCashRegisters();
      },"update:items-per-page":(itemsPerPage) => {
        _vm.perPage = itemsPerPage;
        _vm.getCompanyCashRegisters();
      }},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticStyle:{"text-align":"center","vertical-align":"middle"},attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("billings.date"))+" ")]),_c('th',{staticStyle:{"text-align":"center","vertical-align":"middle"},attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("cash_register_section.cash_counted"))+" ")]),_c('th',{staticClass:"text-center",staticStyle:{"font-size":"30px","border-left":"1px solid #d3d3d3","border-right":"1px solid #d3d3d3"},attrs:{"colspan":"8"}},[_vm._v(" "+_vm._s(_vm.$t("cash_register_section.sales_for_day"))+" ")]),_c('th',{staticStyle:{"text-align":"center","vertical-align":"middle"},attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("cash_register_section.total"))+" ")]),_c('th',{staticStyle:{"text-align":"center","vertical-align":"middle"},attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("billings.statuses.waiting"))+" ")]),_c('th',{staticStyle:{"text-align":"center","vertical-align":"middle"},attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("expeditions.created_by"))+" ")])]),_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.text,class:{
              'text-left': true,
              'pr-4': true,
              'pl-4': true,
              'pt-2': true,
              'pb-2': true,
              'text-capitalize': true,
              [header.align]: true,
            },style:({
              backgroundColor: 'RGB(235, 233, 223)',
            })},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)])]}},{key:"item",fn:function({ item }){return [_c('tr',{on:{"mouseenter":function($event){return _vm.setHovered('cash', item.id)},"mouseleave":function($event){return _vm.setHovered(null, null)}}},[_c('td',{staticStyle:{"text-align":"center","border":"1px solid #e3e3e3"}},[_vm._v(" "+_vm._s(_vm.formatDateTime(item.creation_date_time))+" ")]),_c('td',{style:({
            background: item.hasDifference
              ? _vm.$store.state.colorCodes.danger
              : '',
          })},[_vm._v(" "+_vm._s(parseFloat(item.cash_counted).toFixed(2))+" "),(_vm.isHovered('cash', item.id))?_c('v-icon',{staticClass:"ml-2 cursor-pointer ml-auto",on:{"click":function($event){return _vm.launchCashRegisterModal(item)}}},[_vm._v("mdi-open-in-new ")]):_vm._e()],1),_vm._l((_vm.paymentTypes),function(type){return _c('td',{key:type.id,staticStyle:{"text-align":"end"}},[_vm._v(" "+_vm._s(parseFloat(item.order_payments[type.key] || 0.0).toFixed(2))+" ")])}),_c('td',{staticStyle:{"text-align":"end"}},[_vm._v(" "+_vm._s(parseFloat(item.total).toFixed(2))+" ")]),_c('td',{staticStyle:{"text-align":"end"}},[_vm._v(" "+_vm._s(parseFloat(item.waiting).toFixed(2))+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.user_name))])],2)]}}])}),_c('span',{staticClass:"d-none",attrs:{"id":"cash-register-modal"},on:{"click":function($event){return _vm.launchCashRegisterModal(null)}}},[_vm._v("...")]),(_vm.showCashRegisterModal)?_c('CashRegisterModal',{attrs:{"cash-register":_vm.cashRegister},on:{"cash":(cr) => _vm.hydrateData(cr),"update:cashRegister":function($event){_vm.cashRegister=$event},"update:cash-register":function($event){_vm.cashRegister=$event}},model:{value:(_vm.showCashRegisterModal),callback:function ($$v) {_vm.showCashRegisterModal=$$v},expression:"showCashRegisterModal"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }