<template>
  <v-row justify="center" class="mt-10">
    <v-col cols="12" sm="10" md="6" lg="4">
      <v-card ref="form" class="box-shad-none card-authenticated-wrapper">
        <v-card-text class="pl-0 pr-0 pt-0">
          <v-card-title class="pl-3 pr-3 mb-0 card-title-heading">
            <h3 class="text-white">{{ $t("resetPassword.title") }}</h3>
          </v-card-title>
          <v-progress-linear
            :active="isLoading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <form class="pl-3 pr-3 pt-3">
            <div v-for="alert in alerts" :key="alert" :hidden="!alert.show">
              <v-alert :type="alert.type" dismissible>
                {{ alert.msg }}
              </v-alert>
            </div>
            <v-text-field
              v-model="password"
              :error-messages="passwordErrors"
              :label="Password"
              required
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-text-field
              v-model="confirmPassword"
              :error-messages="confirmPasswordErrors"
              :label="ConfirmPassword"
              required
              @blur="$v.confirmPassword.$touch()"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmPassword ? 'text' : 'password'"
              @click:append="showConfirmPassword = !showConfirmPassword"
            ></v-text-field>
            <!-- type='password' -->

            <div class="text-right mb-5">
              <span class="forgot-password-txt"
                ><a href="/login">{{
                  $t("resetPassword.backToLogin")
                }}</a></span
              >
            </div>
            <v-divider />
            <div class="mt-5">
              <v-btn class="mr-3 white--text" color="primary" @click="submit">
                {{ $t("resetPassword.submit") }}
              </v-btn>
              <v-btn @click="clear"> {{ $t("resetPassword.clear") }} </v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  mixins: [validationMixin],
  validations: {
    password: { required, minLength: minLength(8) },
    confirmPassword: { required, sameAs: sameAs("password") },
  },

  data: () => ({
    showPassword: false,
    showConfirmPassword: false,
    password: "",
    confirmPassword: "",
    token: "",
    alerts: [],
    isLoading: false,
    // i18n
    Password: "",
    ConfirmPassword: "",
  }),

  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength && errors.push("Must be Min 8 characters");
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.sameAs &&
        errors.push("Confirm password is not match with password");
      !this.$v.confirmPassword.required &&
        errors.push("Confirm Password is required");
      return errors;
    },
  },
  created() {
    (this.Password = this.$t("resetPassword.password")),
      (this.ConfirmPassword = this.$t("resetPassword.confirmpassword"));
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.token = this.$route.params.token;
      const payload = {
        token: this.token,
        password: this.password,
        confirm_password: this.confirmPassword,
      };
      axios
        .post(API_BASE_URL + "/password/reset", payload)
        .then((resetPasswordRes) => {
          this.isLoading = false;
          // this.alerts = []
          this.$store.state.alerts = [];
          if (resetPasswordRes.data.msg == "password_changed") {
            this.$store.state.alerts.push({
              msg: this.$t("resetPassword.password_changed"),
              show: true,
              type: "success",
            });
          } else {
            this.$store.state.alerts.push({
              msg: resetPasswordRes.data.msg,
              show: true,
              type: "success",
            });
          }
          this.$router.push("/login").catch(() => {});
          this.$store.state.alerts.forEach((alert) => {
            setTimeout(() => {
              if (alert.type != "error") {
                alert.show = false;
              }
            }, 5000);
          });
        })
        .catch((error) => {
          if (typeof error?.response?.data?.errors == "object") {
            this.alerts = [];
            error.response.data.errors.forEach((error) => {
              if (error == "confirm_password_mismatch") {
                this.alerts.push({
                  msg: this.$t("resetPassword.confirm_password_mismatch"),
                  show: true,
                  type: "error",
                });
              } else if (error == "password_required") {
                this.alerts.push({
                  msg: this.$t("resetPassword.password_required"),
                  show: true,
                  type: "error",
                });
              } else if (error == "confirm_password_required") {
                this.alerts.push({
                  msg: this.$t("resetPassword.confirm_password_required"),
                  show: true,
                  type: "error",
                });
              } else if (error == "invalid_link") {
                this.alerts.push({
                  msg: this.$t("resetPassword.invalid_link"),
                  show: true,
                  type: "error",
                });
              } else {
                this.alerts.push({ msg: error, show: true, type: "error" });
              }
            });
          } else {
            this.alerts.push({
              msg: error.response.data.errors,
              show: true,
              type: "error",
            });
          }
          this.alerts.forEach((alert) => {
            setTimeout(() => {
              if (alert.type != "error") {
                alert.show = false;
              }
            }, 5000);
          });
        });
    },
    clear() {
      this.$v.$reset();
      this.password = "";
      this.confirmPassword = "";
    },
  },
};
</script>
<style scoped>
@import url("../../assets/CSS/authentication.css");
</style>
