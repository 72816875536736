<template>
  <v-dialog :value="value" width="800px">
    <v-form @submit.prevent="submitHandler" ref="addressBookForm">
      <v-card>
        <v-card-title>
          {{ isCreate ? translate("create_label") : translate("edit_label") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <v-row>
            <v-col :cols="6">
              <v-text-field
                v-model="form.name"
                required
                :label="translate('name_label')"
                :rules="[(v) => !!v || translate('required_field')]"
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                v-model="form.email"
                required
                type="email"
                :label="translate('email_label')"
                :rules="[
                  (v) => !!v || translate('required_field'),
                  (v) => /.+@.+\..+/.test(v) || translate('invalid_email'),
                ]"
              />
            </v-col>
            <v-col :cols="6">
              <VueTelInputVuetify
                :key="this.$store.state.forceReloadComponentKey"
                v-model="form.phone"
                :label="translate('phone_label')"
                :disabledFetchingCountry="true"
                :preferredCountries="preferredCountry"
                :filterCountries="onlyCountries"
                :inputOptions="{ showDialCode: true, tabindex: 0 }"
                :mode="'international'"
                validCharactersOnly
                :rules="[(v) => !!v || translate('required_field')]"
              />
            </v-col>
            <v-col :cols="6">
              <VueTelInputVuetify
                :key="this.$store.state.forceReloadComponentKey"
                v-model="form.gsm"
                :label="translate('gsm_label')"
                :disabledFetchingCountry="true"
                :preferredCountries="preferredCountry"
                :filterCountries="onlyCountries"
                :inputOptions="{ showDialCode: true, tabindex: 0 }"
                :mode="'international'"
                validCharactersOnly
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span
            @click="popConfirmation(translate('delete_confirmation'), 'delete')"
            v-if="addressBook"
            ><DeleteButton
          /></span>
          <span @click="close" class="mx-2"><CloseButton /></span>
          <span><SaveButton /></span>
        </v-card-actions>
      </v-card>
    </v-form>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      :checkbox-text="confirmationDialogReCheckBoxConfirmationText"
      @buttonClicked="
        (event) =>
          confirmationButtonClicked(event, () => deleteHandler(addressBook))
      "
    ></confirmation-model>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import { API_BASE_URL } from "@/config";
import axios from "axios";
import DeleteButton from "@/components/Buttons/DeleteButton.vue";
import confirmationModelMixin from "@/mixins/ConfirmationModelMixin";
import VueTelInputVuetify from "@/components/PhoneNumberInput.vue";

export default {
  name: "AddressBookForm",
  components: { DeleteButton, SaveButton, CloseButton, VueTelInputVuetify },
  emits: ["updated", "input", "deleted"],
  mixins: [confirmationModelMixin],
  props: {
    addressBook: {
      default: null,
    },
    value: {
      default: () => false,
    },
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        gsm: null,
      },
    };
  },
  computed: {
    isCreate() {
      return !this.addressBook;
    },
  },
  watch: {
    value:{
    handler: function (val) {
      if (!val) {
        this.form = {
          name: null,
          email: null,
          phone: null,
          gsm: null,
        };
        this.$refs.addressBookForm.resetValidation();
        return;
      }

      this.$store.state.forceReloadComponentKey++;
      if (this.addressBook) {
        this.form = {
          name: this.addressBook.name,
          email: this.addressBook.email,
          phone: this.addressBook.phone,
          gsm: this.addressBook.gsm,
        };
      }
    },
    immediate: true
}
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    translate(key) {
      return this.$t("address_book_section." + key);
    },
    submitHandler() {
      if (!this.$refs.addressBookForm.validate()) return;

      return this.isCreate ? this.createHandler() : this.updateHandler();
    },
    createHandler() {
      this.$store.state.isLoading = true;
      axios
        .post(API_BASE_URL + "/user_address_book", this.form, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .finally(() => (this.$store.state.isLoading = false))
        .then(({ data }) => {
          this.$emit("updated", data.data);
          this.close();
          this.$toast.success(this.translate("added_successfully"));
        })
        .catch((error) => {
          let errorMessage = this.filterFirstError(error.response.data.errors);
          this.$toast.error(errorMessage ?? this.translate("create_error"));
        });
    },
    updateHandler() {
      this.$store.state.isLoading = true;
      axios
        .put(
          API_BASE_URL + "/user_address_book/" + this.addressBook.id,
          this.form,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          }
        )
        .finally(() => (this.$store.state.isLoading = false))
        .then(({ data }) => {
          this.$emit("updated", data.data);
          this.close();
          this.$toast.success(this.translate("updated_successfully"));
        })
        .catch((error) => {
          let errorMessage = this.filterFirstError(error.response.data.errors);
          this.$toast.error(errorMessage ?? this.translate("update_error"));
        });
    },
    deleteHandler(addressBook) {
      this.$store.state.isLoading = true;
      axios
        .delete(API_BASE_URL + "/user_address_book/" + addressBook.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .finally(() => (this.$store.state.isLoading = false))
        .then(() => {
          this.$emit("deleted", addressBook);
          this.close();
          this.$toast.success(this.translate("deleted_successfully"));
        })
        .catch(() => {
          this.$toast.error(this.translate("delete_error"));
        });
    },
  },
};
</script>
