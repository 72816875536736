<template>
  <v-dialog
    v-model="load_localisation"
    @click:outside="closeLoadLocalisation()"
    @keydown.esc="closeLoadLocalisation()"
    persistent
    max-width="1024px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Load Localisation</span>
      </v-card-title>
      <v-card-text @click="modify_data = true">
        <v-row>
          <v-col v-if="!processed" cols="12" :sm="processed ? 0 : 12">
            <v-row class="">
              <v-col cols="12" sm="8">
                <h3 class="mt-2">
                  {{ $t("tyre_hotel.load_localisation.localisation") }}
                </h3>
              </v-col>
              <v-col cols="12" sm="4">
                <v-spacer> </v-spacer>
                <v-checkbox
                  class="ma-0 to-upper"
                  v-model="full_reset"
                  :label="$t('tyre_hotel.load_localisation.make_a_full_reset')"
                  hide-details="true"
                  style="max-width: 350px"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-textarea
                @click="copyTextFromId"
                id="textToCopy"
                outlined
                :rows="18"
                v-model="localisations"
                :placeholder="
                  $t('tyre_hotel.load_localisation.scanner_localisation')
                "
              ></v-textarea>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <span>
                  <h3>
                    {{ $t("tyre_hotel.load_localisation.or_load_from_file") }}
                  </h3>
                </span>
              </v-col>
              <v-col cols="12" sm="8" class="ma-0 pa-0">
                <v-file-input
                  class="mt-0 pt-0"
                  truncate-length="50"
                  v-model="file"
                  ref="myfile"
                  hide-details="true"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="processed" cols="12" :sm="processed ? 12 : 0">
            <h3 class="mb-4">{{ $t("tyre_hotel.load_localisation.error") }}</h3>
            <v-textarea
              @click="copyTextFromId"
              id="textToCopy"
              v-model="errors"
              outlined
              :rows="18"
              hide-details="true"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <template v-if="!processed">
          <v-btn
            :disabled="$store.state.isLoading"
            class="px-4"
            color="grey lighten-2"
            @click="resetLocalisation(false)"
          >
            {{ $t("tyre_hotel.load_localisation.reset") }}
            <v-icon dark right> mdi-reload </v-icon>
          </v-btn>
          <v-btn
            :disabled="$store.state.isLoading"
            class="px-4"
            color="success"
            @click="submitLocalisation()"
          >
            {{ $t("tyre_hotel.load_localisation.load") }}
            <v-icon dark right> mdi-arrow-up-box </v-icon>
          </v-btn>
        </template>
        <template v-if="processed">
          <v-btn
            :disabled="$store.state.isLoading"
            class="px-4"
            color="primary"
            @click="closeLoadLocalisation()"
          >
            {{ $t("tyre_hotel.load_localisation.ok") }}
            <v-icon dark right> mdi-check </v-icon>
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-dialog>
</template>
<style scoped>
.right-aligned-input >>> input {
  text-align: right;
}
.text-red >>> input {
  color: red !important;
}
.border-error {
  border: 1px solid red;
}
</style>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { validationMixin } from "vuelidate";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  name: "LoadLocalisation",
  mixins: [validationMixin],
  validations: {},
  props: {},
  components: {
    "confirmation-model": ConfirmationModel,
  },
  data() {
    return {
      load_localisation: true,
      file: null,
      full_reset: false,
      localisations: null,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      errors: null,
      processed: false,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.header = { Authorization: "Bearer " + this.token };
  },
  watch: {
    full_reset: {
      handler: function (val) {
        if (val) {
          this.alertPopUp();
        }
      },
    },
    file: {
      handler: function (val) {
        if (val) {
          this.submitLocalisation();
        }
      },
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "reset" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.confirmationDialog = false;
          this.full_reset = true;
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
  computed: {},
  methods: {
    resetLocalisation() {
      this.errors = null;
      this.localisations = null;
      this.file = null;
      this.full_reset = null;
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
      if (buttonClicked == false) {
        this.full_reset = false;
      }
    },
    alertPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "tyre_hotel.load_localisation.are_you_sure_you_want_to_full_reset"
      );
      this.confirmationDialogFalseText = this.$t(
        "tyre_hotel.erase_false_button_text"
      );
      this.confirmationDialogTrueText = this.$t(
        "tyre_hotel.erase_true_button_text"
      );
      this.confirmationDialogOperation = "reset";
      this.confirmationDialog = true;
    },
    closeLoadLocalisation() {
      this.load_localisation = false;
      this.$emit("modifyLoadLocalisationClose", false);
    },
    makeSubmitData() {
      let formData = new FormData();
      if (this.file) {
        formData.append("localisation_file", this.file, this.file.name);
      }
      formData.append("localisations", this.localisations ?? "");
      formData.append("reset", parseInt(this.full_reset ? 1 : 0));

      return formData;
    },
    submitLocalisation() {
      this.errors = null;
      let data = this.makeSubmitData();

      axios
        .post(API_BASE_URL + "/depots/locate", data, { headers: this.header })
        .then(({ data }) => {
          let errors = "";
          if (data.data.errors) {
            data.data.errors.forEach((element) => {
              errors += element + "\r\n";
            });
            this.errors = errors;
          }
          this.processed = true;
        })
        .catch(() => {
          this.$toast.error(
            this.$t("tyre_hotel.load_localisation.something_went_wrong")
          );
        })
        .finally(() => {});
    },
  },
};
</script>
