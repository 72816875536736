<template>
  <v-dialog
    content-class="dialogPopUp"
    v-model="modify_stock_dialog"
    @click:outside="closeTyreDialog()"
    @keydown.esc="closeTyreDialog()"
    persistent
    max-width="1324px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ $t("billings.depot_out.title") }} </span>
      </v-card-title>
      <v-card-text @click="modify_data = true">
        <v-row class="mt-4 mb-4">
          <v-data-table
            fixed-header
            :footer-props="{
              'items-per-page-options': dataTableOptions,
            }"
            :headers="depositHeader"
            :hide-default-footer="true"
            :items="depot"
            :items-per-page="itemsPerPage"
            :options.sync="options"
            :sort-by="sortBy"
            :loading-text="this.$t('loading')"
            class="elevation-0"
            item-key="id"
            height="29vh"
            style="width: 100%"
            hide-no-data
          >
            <template v-slot:item="{ item }">
              <tr
                :class="item == selectedDepot ? 'bg-grey' : 'bg-white'"
                @click="selectDepot(item)"
                v-if="item.deleted_at ? isHistory : true"
              >
                <td></td>
                <td
                  :id="item.id"
                  :data="
                    selectedDepot && selectedDepot.id == item.id
                      ? highlightRow(item.id)
                      : ''
                  "
                >
                  <span v-if="item.status.key">
                    <DepotStatus :status-key="item.status.key" />
                  </span>
                </td>
                <td>
                  {{ item.localisation }}
                </td>
                <td>
                  {{ formatDateTime(item.created_at) }}
                </td>
                <td>
                  {{ item.requested_out_for }}
                </td>
                <td>
                  <template v-if="isHistory">
                    {{ formatDateTime(item.deleted_at) }}
                  </template>
                </td>
                <td align="right">
                  <v-icon
                    v-if="checkIfSomeErrorInDepot(item.details)"
                    color="error"
                  >
                    mdi-fridge-alert
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <span class="pr-4">
              <v-icon class="mb-2 mr-3 tyre-hotel-heading">
                mdi-account
              </v-icon>
              <span class="tyre-hotel-heading">{{
                $t("tyre_hotel.deposit_details.text")
              }}</span>
            </span>
            <v-data-table
              fixed-header
              :footer-props="{
                'items-per-page-options': dataTableOptions,
              }"
              :hide-default-footer="true"
              :headers="depositDeatilsHeader"
              :items="selectedDepotForSubTable"
              :items-per-page="itemsPerPage"
              :options.sync="options"
              :sort-by="sortBy"
              :loading-text="this.$t('loading')"
              class="elevation-0"
              item-key="id"
              height="32vh"
              style="width: 100%"
            >
              <template v-slot:item="{ item }">
                <tr
                  :class="
                    item.article_status && item.article_status.key == 'damaged'
                      ? 'damaged-row'
                      : ''
                  "
                >
                  <td>
                    <v-checkbox
                      :color="
                        item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'
                      "
                      class="ma-0 mt-n1"
                      v-model="item.checked"
                      hide-details="auto"
                    ></v-checkbox>
                  </td>
                  <td>
                    <span v-if="item.position && item.position.id == 10281000">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-cancel
                      </v-icon>
                      {{ $t("tyre_hotel.position.no_position_shorthand") }}
                    </span>
                    <span v-if="item.position && item.position.id == 10281001">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-arrow-top-right-bold-box
                      </v-icon>
                      {{ $t("tyre_hotel.position.top_right_shorthand") }}
                    </span>
                    <span v-if="item.position && item.position.id == 10281002">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-arrow-top-left-bold-box
                      </v-icon>
                      {{ $t("tyre_hotel.position.top_left_shorthand") }}
                    </span>
                    <span v-if="item.position && item.position.id == 10281003">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-arrow-bottom-right-bold-box
                      </v-icon>
                      {{ $t("tyre_hotel.position.bottom_right_shorthand") }}
                    </span>
                    <span v-if="item.position && item.position.id == 10281004">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-arrow-bottom-left-bold-box
                      </v-icon>
                      {{ $t("tyre_hotel.position.bottom_left_shorthand") }}
                    </span>
                    <!-- Second Tyres -->
                    <span v-if="item.position && item.position.id == 10281006">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-arrow-top-right-bold-box
                      </v-icon>
                      {{ $t("tyre_hotel.position.top_right_shorthand_2") }}
                    </span>
                    <span v-if="item.position && item.position.id == 10281007">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-arrow-top-left-bold-box
                      </v-icon>
                      {{ $t("tyre_hotel.position.top_left_shorthand_2") }}
                    </span>
                    <span v-if="item.position && item.position.id == 10281008">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-arrow-bottom-right-bold-box
                      </v-icon>
                      {{ $t("tyre_hotel.position.bottom_right_shorthand_2") }}
                    </span>
                    <span v-if="item.position && item.position.id == 10281009">
                      <v-icon
                        :color="
                          item.article_status &&
                          item.article_status.key == 'damaged'
                            ? '#ff5252'
                            : 'black'
                        "
                      >
                        mdi-arrow-bottom-left-bold-box
                      </v-icon>
                      {{ $t("tyre_hotel.position.bottom_left_shorthand_2") }}
                    </span>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.article.title_description[0].description }}
                    </template>
                    <template v-else>
                      {{ item.article_name }}
                    </template>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.article.property.property_03 }}
                    </template>
                    <template v-else>
                      <!--  -->
                    </template>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.article.property.property_04 }}
                    </template>
                    <template v-else>
                      <!--  -->
                    </template>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.article.property.property_05 }}
                    </template>
                    <template v-else>
                      <!--  -->
                    </template>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.article.property.property_06 }}
                    </template>
                    <template v-else>
                      <!--  -->
                    </template>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.article.property.property_07 }}
                    </template>
                    <template v-else>
                      <!--  -->
                    </template>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.mm }}
                    </template>
                    <template v-else>
                      {{ item.mm }}
                    </template>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.couple }}
                    </template>
                    <template v-else>
                      {{ item.couple }}
                    </template>
                  </td>
                  <td>
                    <template v-if="item.article_id">
                      {{ item.pressure }}
                    </template>
                    <template v-else>
                      {{ item.pressure }}
                    </template>
                  </td>
                  <td>
                    <span v-if="item.rimtype">
                      {{ item.rimtype.value }}
                    </span>
                  </td>
                  <td>
                    <v-icon v-if="item.is_with_hubcaps == 1" color="green">
                      mdi-check
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn class="px-4" color="grey lighten-2" @click="closeTyreDialog()">
          {{ $t("tyre_hotel.add_modify_tyre.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn class="px-4" color="success" @click="saveTyreDialog()">
          {{ $t("tyre_hotel.add_modify_tyre.save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-dialog__content--active {
  z-index: 9999998 !important; /* Ensure this is lower than the .dialogPopUp */
}
</style>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { validationMixin } from "vuelidate";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import DepotStatus from "@/components/TyreHotel/DepotStatus.vue";

export default {
  name: "DepotOut",
  mixins: [validationMixin],
  components: {
    DepotStatus,
  },
  validations: {},
  props: {
    depotDetails: [Object],
    vehicleDriverDetails: [Object],
    alreadySelectedDepot: [Object],
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      modify_stock_dialog: true,
      autocompleteValueLocal: "off",
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      modify_data: false,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isHistory: false,
      selected_vehicle: {},
      depot: [],
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      selectedDepot: null,
      selectedDepotForSubTable: [],
      return_request_menu: false,
      return_request_date: null,
      nowDate: new Date().toISOString().slice(0, 10),
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      lastDepotSelected: null,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.header = { Authorization: "Bearer " + this.token };
    if (this.vehicleDriverDetails.vehicle.vehicle_id) {
      this.fetchDepot(this.vehicleDriverDetails.vehicle.vehicle_id);
    }
  },
  watch: {},
  methods: {
    highlightRow(itemId) {
      this.$nextTick(() => {
        if (
          document.getElementsByClassName("tyre-depot-matched-row").length > 0
        ) {
          document
            .getElementsByClassName("tyre-depot-matched-row")[0]
            .classList.remove("tyre-depot-matched-row");
        }
        document.getElementById(itemId).parentElement.parentElement.className =
          "tyre-depot-matched-row";
      });
    },
    selectDepot(row) {
      this.selectedDepot = row;

      row.details.forEach((detail) => {
        detail.checked = true;
      });
      this.selectedDepotForSubTable = row.details;
    },
    checkIfSomeErrorInDepot(depotDetails) {
      let isError = false;
      depotDetails.forEach((rowdepot) => {
        if (
          rowdepot.article_status &&
          rowdepot.article_status.key == "damaged"
        ) {
          isError = true;
        }
      });
      return isError;
    },
    fetchDepot(vehicleID) {
      this.depot = [];
      this.selectedDepot = null;
      this.selectedDepotForSubTable = [];

      axios
        .get(
          API_BASE_URL +
            "/depots?soft_deleted_also=true&" +
            "vehicle_id=" +
            vehicleID,
          { headers: this.header }
        )
        .then(({ data }) => {
          // Assign Checked property to all the depot items
          let tempData = [];
          let alreadySelectedDepotIds = [];
          this.alreadySelectedDepot.forEach((alreadyItems) => {
            if (alreadyItems.depot_id) {
              alreadySelectedDepotIds.push(alreadyItems.depot_id);
            }
            if (alreadyItems.depot_in_id) {
              alreadySelectedDepotIds.push(alreadyItems.depot_in_id);
            }
          });
          data.data.forEach((depot) => {
            if (this.alreadySelectedDepot.length > 0) {
              if (!alreadySelectedDepotIds.includes(depot.id)) {
                tempData.push(depot);
              }
            } else {
              tempData = Object.assign([], data.data);
            }
          });

          this.depot = tempData;

          tempData.forEach((depot) => {
            depot.checked = false;
            if (!this.selectedDepot && !depot.deleted_at) {
              this.selectedDepot = depot;
            }
          });

          // Assign the first depot
          if (tempData.length > 0 && this.selectedDepot) {
            // Check all the sub positions by default
            this.selectedDepot.details.forEach((detail) => {
              detail.checked = true;
            });
            this.selectedDepotForSubTable = this.selectedDepot.details;
          }
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {});
    },
    closeTyreDialog() {
      this.modify_stock_dialog = false;
      this.$emit("modifyClose", false, false);
    },
    saveTyreDialog() {
      this.modify_stock_dialog = false;
      this.$emit("modifyClose", false, this.selectedDepot);
    },
  },
  computed: {
    depositHeader() {
      return [
        {
          text: " ",
          align: "start",
          sortable: false,
          value: "checkbox",
          width: "10",
        },
        {
          text: this.$t("tyre_hotel.deposit.status"),
          align: "start",
          sortable: false,
          value: "status",
          width: "15%",
        },
        {
          text: this.$t("tyre_hotel.deposit.localized"),
          align: "start",
          sortable: false,
          value: "localisation",
          width: "15%",
        },
        {
          text: this.$t("tyre_hotel.deposit.entry_date"),
          align: "start",
          sortable: false,
          value: "created_at",
          width: "20%",
        },
        {
          text: this.$t("tyre_hotel.deposit.return_request_date"),
          align: "start",
          sortable: false,
          value: "requested_out_for",
          width: "20%",
        },
        {
          text: this.$t("tyre_hotel.deposit.exit_date"),
          align: "start",
          sortable: false,
          value: "deleted_at",
          width: "20%",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "someerrors",
          width: "10%",
        },
      ];
    },
    depositDeatilsHeader() {
      return [
        {
          text: " ",
          align: "start",
          sortable: false,
          value: "checkbox",
          width: "10",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.position"),
          align: "start",
          sortable: false,
          value: "position",
          width: "50",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.profile"),
          align: "start",
          sortable: false,
          value: "article",
          width: "350",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.width"),
          align: "start",
          sortable: false,
          value: "article_width",
          width: "5",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.height"),
          align: "start",
          sortable: false,
          value: "article_height",
          width: "5",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.rim"),
          align: "start",
          sortable: false,
          value: "article_rim",
          width: "5",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.li"),
          align: "start",
          sortable: false,
          value: "article_li",
          width: "5",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.si"),
          align: "start",
          sortable: false,
          value: "article_si",
          width: "5",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.mm"),
          align: "start",
          sortable: false,
          value: "mm",
          width: "5",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.tightening_torque"),
          align: "start",
          sortable: false,
          value: "couple",
          width: "150",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.pressure"),
          align: "start",
          sortable: false,
          value: "pressure",
          width: "5",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.rim_type"),
          align: "start",
          sortable: false,
          value: "rim_type",
          width: "100",
        },
        {
          text: this.$t("tyre_hotel.deposit_details.with_enjo"),
          align: "start",
          sortable: false,
          value: "with_enjo",
          width: "5",
        },
        // { text: this.$t('tyre_hotel.deposit_details.alu'),              align: 'start',     sortable: false, value:'alu', width:'5' },
      ];
    },
  },
};
</script>
