<template>
  <div>
    <template v-if="attachments && attachments.length > 0">
      <ul class="scroll pl-0" v-if="attachments.length">
        <li class="list-item" v-for="file in attachments" :key="file">
          <!-- Hide Bill Attachment -->
          <template v-if="!checkIfToHide(file.description)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon
                    class="attachment_icon_color"
                    @click="viewAttachment(file, showInFullScreen)"
                    right
                    medium
                  >
                    {{ findFileIconWithDescription(file) }}
                  </v-icon>
                </div>
              </template>
              {{ tooltipTitle(file.title) }}
            </v-tooltip>
          </template>
        </li>
      </ul>
    </template>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "ExpeditionDocuments",
  components: {},
  props: {
    resource: [String],
    data: [Number, String, Object],
    hideDocumentTypes: [Array],
    showInFullScreen: { type: Boolean, default: true },
    showImmediate: { type: Boolean, default: false },
  },
  computed: {},
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      refreshAttachment: false,
      countKey: "vehicles",
      refreshDynamicContent: true,
      sortBy: "description",
      sortDesc: false,
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isLoading: false,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      autocompleteValueLocal: "off",
      module_id: null,
      resource_id: null,
      editModeItem: null,
      attachments: [],

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,
    };
  },
  mounted() {
    let data = JSON.parse(localStorage.getItem("data"));
    let applicationResourceId = null;
    data.applicationResources.forEach((resource) => {
      if (resource.application_resource.key == this.resource) {
        applicationResourceId = resource.application_resource.id;
      }
    });
    data.attachmentModules.forEach((modules) => {
      if (modules.application_resource_id == applicationResourceId) {
        this.module_id = modules.id;
        this.resource_id = modules.application_resource_id;
      }
    });
    this.getAttachments();
  },
  methods: {
    tooltipTitle(title) {
      let expedition = this.data
        ? typeof this.data == "object"
          ? this.data
          : null
        : null;
      if (expedition && expedition.identifier) {
        title = title.replace(expedition.identifier, "");
      }
      return title;
    },
    checkIfToHide(documentToHide) {
      if (this.hideDocumentTypes.includes(documentToHide.toLowerCase())) {
        return true;
      }
      return false;
    },
    viewAttachment(item, showInFullScreen) {
      let url = item.signed_url;
      let fileName = item.title;
      this.setPreviewData(
        url,
        fileName,
        true,
        item.mime_type,
        showInFullScreen
      );
    },
    getItemIcon(item) {
      if (item.mime_type.includes("video")) {
        return "orange";
      } else if (item.mime_type.includes("image")) {
        return "blue";
      } else if (item.mime_type.includes("audio")) {
        return "blue";
      } else {
        return "black";
      }
    },
    getItemIconColor(item) {
      if (item.mime_type.includes("video")) {
        return "mdi mdi-multimedia";
      } else if (item.mime_type.includes("image")) {
        return "mdi mdi-image";
      } else if (item.mime_type.includes("audio")) {
        return "mdi mdi-volume-high";
      } else {
        return "mdi mdi-file";
      }
    },
    deleteAttachment(id) {
      axios
        .delete(API_BASE_URL + "/attachments/" + id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(() => {
          this.refreshDynamicContent = false;
          let tempAttachments = [];
          this.attachments.forEach((attachment) => {
            if (attachment.id != id) {
              tempAttachments.push(attachment);
            }
          });
          this.attachments = tempAttachments;
          this.$nextTick(() => {
            this.refreshDynamicContent = true;
            let data =
              typeof this.$store.state.attachmentCounts == "number"
                ? []
                : this.$store.state.attachmentCounts ?? [];
            data[this.countKey ?? "data"] = this.attachments.length;
            this.$store.commit("setAttachmentCounts", data);
          });
        })
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.confirmationDialogData = null;
    },
    getAttachments() {
      axios
        .get(
          API_BASE_URL +
            `/attachments?module_id=${this.module_id}&resource_key_id=${this.data}`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          }
        )
        .then(({ data }) => {
          this.attachments = data.data.result;
          if (
            this.showImmediate &&
            this.attachments &&
            this.attachments.length > 0
          ) {
            this.viewAttachment(this.attachments[0], this.showInFullScreen);
          }
        })
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    attachmentDialog() {
      this.getAttachments();
    },
  },
};
</script>
