<template>
  <v-btn
    class="px-4"
    color="grey lighten-2"
    :loading="loading"
    :disabled="disabled"
  >
    {{ $t("action_texts.close") }}
    <v-icon dark right> mdi-keyboard-return </v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "CloseButton",
  props: {
    disabled: {
      default: false,
    },
    loading: {
      default: false,
    },
  },
};
</script>
