<template>
  <v-dialog
    v-model="popUpModelVisible"
    max-width="1000"
    @click:outside="closePopUpModel()"
    @keydown.esc="closePopUpModel()"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">
          <template
            v-if="selectedItemIndexData.article.original_name.length >= 35"
          >
            {{
              selectedItemIndexData.article.original_name.substring(0, 35) +
              "..."
            }}
          </template>
          <template v-else>
            {{ selectedItemIndexData.article.original_name }}
          </template>

          <span style="color: green" class="pl-10">
            {{ $t("receptions.scanned_quantity") }}:
            {{ selectedItemIndexData.received }}</span
          >
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-data-table
            v-if="showDataTable"
            fixed-header
            :footer-props="{
              'items-per-page-options': dataTableOptions,
            }"
            :headers="headers"
            :hide-default-header="true"
            :items="data"
            :items-per-page="99999"
            :options.sync="options"
            :sort-by="sortBy"
            :loading-text="this.$t('loading')"
            class="elevation-0"
            item-key="id"
            :height="'60vh'"
            style="width: 100%"
          >
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
                <tr>
                  <template v-for="header in headers">
                    <th
                      :key="header.value"
                      scope="col"
                      :class="
                        header.align == 'start' ? 'text-left' : 'text-right'
                      "
                      :style="getHeaderWidth(header, header.width)"
                    >
                      <v-row
                        :class="
                          header.align == 'start'
                            ? 'justify-left'
                            : header.align == 'center'
                            ? 'justify-center'
                            : 'justify-end'
                        "
                      >
                        {{ header.text }}
                        <v-simple-checkbox
                          v-if="header.value == 'is_verified'"
                          class="mt-0 ma-0 pa-0 pl-2"
                          hide-details="auto"
                          v-model="isAllSelected"
                          :ripple="false"
                          @click="verifyAll()"
                        ></v-simple-checkbox>
                      </v-row>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr :class="setRowColorClass(item)">
                <td>
                  <v-tooltip bottom z-index="9999">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="
                          getStatusValue(item.status_id).key ==
                          'order_registered'
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-multiple
                      </v-icon>
                      <v-icon
                        v-else-if="
                          getStatusValue(item.status_id).key ==
                          'successfully_ordered'
                        "
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                      >
                        mdi-cart-check
                      </v-icon>
                      <v-icon
                        v-else-if="
                          getStatusValue(item.status_id).key ==
                          'order_supplier_error'
                        "
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                      >
                        mdi-close-network
                      </v-icon>
                      <v-icon
                        v-else-if="
                          getStatusValue(item.status_id).key == 'order_canceled'
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-archivecancel
                      </v-icon>
                      <v-icon
                        v-else-if="
                          getStatusValue(item.status_id).key ==
                          'partially_received'
                        "
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                      >
                        mdi-archive-arrow-down</v-icon
                      >
                      <v-icon
                        v-else-if="
                          getStatusValue(item.status_id).key == 'fully_received'
                        "
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                      >
                        mdi-archive-check
                      </v-icon>
                    </template>
                    <span>
                      {{
                        translateKeys(
                          getStatusValue(item.status_id).key,
                          "key",
                          "receptions.statuses"
                        )
                      }}
                    </span>
                  </v-tooltip>
                </td>
                <td>
                  {{ item.ordered_quantity }}
                </td>
                <td class="text-left">
                  <template v-if="item.name || item.first_name">
                    {{ item.name }} {{ item.first_name }}
                  </template>
                  <template v-else>
                    {{ $t("receptions.internal_stock") }}
                  </template>
                </td>
                <td class="text-left">
                  <span
                    v-if="item.id"
                    @click="navigateToOrdersPage(item.order_id)"
                    :style="{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }"
                  >
                    <TextComponentWithExtraIcons
                      :value="item.identifier"
                    ></TextComponentWithExtraIcons>
                  </span>
                </td>
                <td class="text-left">
                  <span
                    v-if="item.original_start_time"
                    @click="navigateToAppointmentPage(item.appointment_id)"
                    :style="{
                      color: 'blue',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }"
                  >
                    {{ formatDateTime(item.original_start_time) }}
                  </span>
                </td>
                <td>
                  <v-row class="justify-end">
                    <v-checkbox
                      v-model="item.is_verified"
                      color="green"
                      hide-details
                      class="mt-n1"
                      @click="refreshAgainData"
                    ></v-checkbox>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey-lighten-2" @click="closePopUpModel()">
          {{ $t("close") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  mixins: [validationMixin],
  validations: {},
  props: {
    data: [Object, Array],
    parentData: [Object, Array],
    selectedItemIndexData: [Object, Array],
    rec_statuses: [Array],
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      popUpModelVisible: true,
      autocompleteValueLocal: "off",
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      token: this.$store.state.AccessToken,
      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      isAllSelected: false,
      showDataTable: true,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  methods: {
    refreshAgainData() {
      this.showDataTable = false;
      this.$nextTick(() => {
        this.showDataTable = true;
      });
    },
    async navigateToOrdersPage(orderId) {
      this.$store.commit("seteditId", orderId);
      this.$router.push("/billing/edit");
    },
    async navigateToAppointmentPage(appointmentId) {
      this.$store.commit("setAppointmentRedirectData", {
        id: appointmentId,
      });
      this.$router.push("/scheduler/edit");
    },
    verifyAll() {},
    previouslyCalculatedSupplierOrders(item) {
      let totalAlreadyCalculated = 0;
      let foundForPausingCalculation = false;
      this.data.forEach((element) => {
        if (element.id == item.id) {
          foundForPausingCalculation = true;
        }
        if (element.is_verified && !foundForPausingCalculation) {
          totalAlreadyCalculated +=
            element.ordered_quantity - element.received_quantity ??
            element.ordered_quantity;
        }
      });
      return totalAlreadyCalculated;
    },
    setRowColorClass(item) {
      let alreadyProcessed = this.previouslyCalculatedSupplierOrders(item);
      this.currentlyToBeProcessedQuantity;
      let className = "";
      if (
        item.is_verified == 1 ||
        item.is_verified == true ||
        item.is_verified == "1"
      ) {
        if (
          this.selectedItemIndexData.received -
            (alreadyProcessed +
              (item.ordered_quantity - item.received_quantity) ??
              item.ordered_quantity) >
          0
        ) {
          className = "greenColor";
        } else {
          className = "yellowColor";
        }
      }

      return className;
    },
    closePopUpModel() {
      this.popUpModelVisible = false;
    },
    getStatusValue(id) {
      let found = {
        id: "",
        key: "",
        text: "",
      };
      this.rec_statuses.forEach((element) => {
        if (element.id == id) {
          found = element;
          found.text = this.$t("receptions.statuses." + element.key);
        }
      });
      return found;
    },
  },
  computed: {
    allSuppliers() {
      return this.$store.state.allSuppliers ?? [];
    },
    currentlyToBeProcessedQuantity() {
      let total = 0;
      if (this.data) {
        this.data.forEach((element) => {
          if (element.is_verified) {
            total +=
              element.ordered_quantity - element.received_quantity ??
              element.ordered_quantity;
          }
        });
      }
      return total;
    },

    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("receptions.ordered_quantity"),
          align: "start",
          sortable: false,
          value: "id",
          width: "10%",
        },
        {
          text: this.$t("scheduler.client_name"),
          align: "start",
          sortable: false,
          value: "id",
          width: "20%",
        },
        {
          text: this.$t("receptions.related_order_id"),
          align: "start",
          sortable: false,
          value: "id",
          width: "25%",
        },
        {
          text: this.$t("receptions.appointment_id"),
          align: "start",
          sortable: false,
          value: "id",
          width: "25%",
        },
        {
          text: this.$t("receptions.is_verified"),
          align: "end",
          sortable: false,
          value: "is_verified",
          width: "10%",
        },
      ];
    },
  },
  watch: {
    popUpModelVisible() {
      this.$emit("change-popup-model-visibility", this.data);
    },
    isAllSelected(val) {
      if (val) {
        this.data.forEach((element) => {
          element.is_verified = true;
        });
      } else {
        this.data.forEach((element) => {
          element.is_verified = false;
        });
      }
    },
  },
};
</script>
