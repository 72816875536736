<template>
  <v-row>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <!-- <div v-for="alert in this.$store.state.alerts" :key="alert" :hidden="!alert.show">
      <v-alert class="v-alert-positation" :type="alert.type" dismissible>
        {{ alert.msg }}
      </v-alert>
    </div> -->
    <v-row class="pt-5">
      <v-col cols="12" md="12" class="pt-0">
        <v-col cols="12" md="12" class="pt-0">
          <v-row>
            <v-col cols="12" sm="8" md="10" class="pb-0">
              <!-- <v-text-field label="Role Name"></v-text-field> -->
              <v-select
                v-model="selectedRole"
                :items="roles"
                item-text="text"
                item-value="id"
                :label="selectRole"
                @change="onSelectedRoleChange()"
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-select>
            </v-col>
            <v-col cols="4" md="2" class="pb-0 pt-5">
              <div class="text-right">
                <v-btn
                  block
                  class="ma-2"
                  color="primary"
                  disabled
                  @click="onDuplicateClick()"
                >
                  {{ $t("user_role.duplicate") }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 pb-0">
              <v-textarea
                rows="1"
                @blur="onDescriptionChange()"
                v-model="selectedRole.description"
                :label="description"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <v-col col="12" sm="12" class="pt-0">
        <v-col col="12" sm="12" class="pt-0">
          <v-col col="12" sm="12" class="px-0 py-0">
            <!-- <v-card> -->
            <v-card-actions class="flex-column pt-0 pb-0 px-0">
              <v-col col="12" sm="12" class="text-right pt-1 pl-0 pr-1 pb-1">
                <v-dialog v-model="dialog" persistent width="700">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class=""
                      small
                      color="primary"
                      v-bind="attrs"
                      :disabled="!selectedSlug.resource_id"
                      v-on="on"
                    >
                      {{ $t("user_role.Add") }}
                      <v-icon dark right> mdi-plus-thick </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="headline grey lighten-2">
                      {{ $t("user_role.AddResources") }}
                    </v-card-title>
                    <v-card-actions>
                      <v-row>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <v-text-field
                            :label="ResourceName"
                            v-model="resource_name"
                            :error-messages="ResourceNameError"
                            required
                            @input="$v.resource_name.$touch()"
                            @blur="$v.resource_name.$touch()"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            class="to-upper"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <v-textarea
                            rows="2"
                            v-model="resource_description"
                            :error-messages="ResourceDescriptionError"
                            required
                            @input="$v.resource_description.$touch()"
                            @blur="$v.resource_description.$touch()"
                            :label="description"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <v-select
                            v-model="selectedAddResource_slugs"
                            :items="addResource_slugs"
                            item-text="slug"
                            item-value="id"
                            :label="SelectSlug"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="12" class="mb-3 text-right">
                          <v-btn
                            color="primary"
                            class="mt-2 ml-2 mr-2 mb-2"
                            @click="onSaveResource()"
                            ><v-icon class="mr-1">mdi-check</v-icon>
                            {{ $t("user_role.Save") }}</v-btn
                          >
                          <v-btn class="ml-2 mr-2" @click="onCancelResource()"
                            ><v-icon class="mr-1">mdi-close</v-icon
                            >{{ $t("user_role.Cancel") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-simple-table
                class="w-100 table-responsive roles-table"
                height="425"
                fixed-header
                dense
              >
                <!-- fixed-header="true" -->
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="320px">
                        {{ $t("user_role.Slug") }}
                      </th>
                      <th class="text-left" width="400px">
                        {{ $t("user_role.Description") }}
                      </th>
                      <th class="text-left">{{ $t("user_role.Canadd") }}</th>
                      <th class="text-left">{{ $t("user_role.Canedit") }}</th>
                      <th class="text-left">
                        {{ $t("user_role.Candelete") }}
                      </th>
                      <th class="text-left">{{ $t("user_role.Canview") }}</th>
                    </tr>
                    <tr>
                      <th style="background-color: #f5fff5">
                        <v-row class="nowrap">
                          <v-select
                            class="pt-4 ma-0"
                            v-model="selectedSlug"
                            :items="slugs"
                            item-text="text"
                            item-value="id"
                            return-object
                            dense
                            outlined
                            hide-details="auto"
                            @change="onSelectedSlugChange()"
                          ></v-select>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                class="mt-5 mb-5 ml-2"
                                @click="goToParentClick()"
                              >
                                <v-icon class="mr-1" small
                                  >mdi-keyboard-caps
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Go to parent</span>
                          </v-tooltip>
                        </v-row>
                      </th>
                      <th style="background-color: #f5fff5"></th>
                      <th style="background-color: #f5fff5">
                        <!-- <v-checkbox :disabled="true" indeterminate></v-checkbox> -->
                      </th>
                      <th style="background-color: #f5fff5">
                        <!-- <v-checkbox :disabled="true" indeterminate></v-checkbox> -->
                      </th>
                      <th style="background-color: #f5fff5">
                        <!-- <v-checkbox :disabled="true" indeterminate></v-checkbox> -->
                      </th>
                      <th style="background-color: #f5fff5">
                        <!-- <v-checkbox :disabled="true" indeterminate></v-checkbox> -->
                        <!-- <tr > -->
                      </th>
                    </tr>
                    <tr>
                      <th class="progress-th" colspan="6" v-if="isLoading">
                        <v-progress-linear
                          color="primary"
                          indeterminate
                          :active="isLoading"
                          reverse
                        ></v-progress-linear>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="slugsResources.length > 0">
                      <tr
                        v-for="item in slugsResources"
                        :key="item.resource_id"
                      >
                        <td @click="onChildSlugClick(item)">
                          {{ item.text }}
                        </td>
                        <td>{{ item.description }}</td>
                        <td>
                          <v-checkbox
                            v-model="item.can_add"
                            @change="
                              onCheck(item.resource_id, item.can_add, 'can_add')
                            "
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-checkbox
                            v-model="item.can_edit"
                            @change="
                              onCheck(
                                item.resource_id,
                                item.can_edit,
                                'can_edit'
                              )
                            "
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-checkbox
                            v-model="item.can_delete"
                            @change="
                              onCheck(
                                item.resource_id,
                                item.can_delete,
                                'can_delete'
                              )
                            "
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-checkbox
                            v-model="item.can_view"
                            @change="
                              onCheck(
                                item.resource_id,
                                item.can_view,
                                'can_view'
                              )
                            "
                          ></v-checkbox>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="6" class="text-center">
                          {{ $t("no_data_found") }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-actions>
            <!-- </v-card> -->
          </v-col>
        </v-col>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    resource_name: { required, maxLength: maxLength(100) },
    resource_description: { required, maxLength: maxLength(170) },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      resource_description: "",
      resource_name: "",
      roles: [],
      selectedRole: {},
      slugs: [],
      addResource_slugs: [],
      slugsResources: [],
      selectedSlug: {},
      selectedAddResource_slugs: {},
      dialog: false,
      isLoading: false,
      // saveRole: true,

      // i18
      selectRole: this.$t("user_role.select_role"),
      description: this.$t("user_role.role_description"),
      SelectSlug: this.$t("user_role.select_slug"),
      ResourceName: this.$t("user_role.resource_name"),

      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
    };
  },
  created() {
    ``;
    (this.header = { Authorization: "Bearer " + this.token }),
      this.getRolesList();
  },
  computed: {
    ResourceNameError() {
      const errors = [];
      if (!this.$v.resource_name.$dirty) return errors;
      !this.$v.resource_name.maxLength &&
        errors.push("Resource name is not allowd morethen 100 characters");
      !this.$v.resource_name.required &&
        errors.push("Resource Name is required");
      // !this.$v.resource_name.alpha && errors.push('Name Field is not allow Numbers and Special characters')
      return errors;
    },
    ResourceDescriptionError() {
      const errors = [];
      if (!this.$v.resource_description.$dirty) return errors;
      !this.$v.resource_description.maxLength &&
        errors.push(
          "Resource Description is not allowd morethen 170 characters"
        );
      !this.$v.resource_description.required &&
        errors.push("Resource Description is required");
      return errors;
    },
  },
  methods: {
    getRolesList() {
      this.isLoading = true;
      axios({
        method: "get",
        url: API_BASE_URL + "/user_roles",
        headers: this.header,
      })
        .then((resRoles) => {
          this.isLoading = false;
          this.roles = this.translateKeys(
            resRoles.data.data.result,
            "name",
            "users.roles"
          );
          this.roles.forEach((role) => {
            if (role.id == 1) {
              this.selectedRole = role;
              const payload = { role_id: this.selectedRole.id };
              this.getSlugList(payload);
              this.getSlugListForAddresources(payload);
            }
          });
        })
        .catch(() => {
          this.$store.state.alerts = [];
        });
    },
    getSlugList(payload) {
      this.isLoading = true;
      axios({
        method: "get",
        url: API_BASE_URL + "/role_resources/" + payload.role_id,
        headers: this.header,
      })
        .then((resSlug) => {
          this.isLoading = false;
          this.slugs = [];
          this.addResource_slugs = [];
          // this.slugs.resSlug.data.data
          this.addResource_slugs.push({
            resource_id: -1,
            slug: "As a Parent Slug",
            description: "New Slug",
          });
          resSlug.data.data.forEach((slug) => {
            this.addResource_slugs.push(slug);
            // this.translateKeys(slug, 'slug', 'roles.slug');
            // slug.name = this.$t('vehicles.brand_name');
            slug.text = this.$t("roles.slug." + slug.slug);
            this.slugs.push(slug);
            if (slug.resource_id == 1) {
              this.selectedSlug = slug;
              this.selectedAddResource_slugs = slug;
              const payload = {
                role_id: this.selectedRole.id,
                parent_id: this.selectedSlug.resource_id,
              };
              this.getSlugResourcesList(payload);
            }
          });
        })
        .catch((error) => {
          console.log("slug", error);
        });
    },
    getSlugListForAddresources(payload) {
      this.isLoading = true;
      axios({
        method: "get",
        url: API_BASE_URL + "/role_resources/" + payload.role_id,
        headers: this.header,
      })
        .then((resSlug) => {
          this.isLoading = false;
          this.addResource_slugs.push({
            resource_id: -1,
            slug: "As a Parent Slug",
            description: "New Slug",
          });
          resSlug.data.data.forEach((slug) => {
            this.addResource_slugs.push(slug);
            if (slug.resource_id == 1) {
              this.selectedSlug = slug;
              this.selectedAddResource_slugs = slug;
              const payload = {
                role_id: this.selectedRole.id,
                parent_id: this.selectedSlug.resource_id,
              };
              this.getSlugResourcesList(payload);
            }
          });
        })
        .catch((error) => {
          this.$store.state.alerts = [];
          if (error.response.data.message == "resources_not_found") {
            this.$store.state.alerts.push({
              msg: this.$t("user_role.resources_not_found"),
              show: true,
              type: "error",
            });
          } else {
            this.$store.state.alerts.push({
              msg: error.response.data.message,
              show: true,
              type: "error",
            });
          }
          this.$store.state.alerts.forEach((alert) => {
            setTimeout(() => {
              if (alert.type != "error") {
                alert.show = false;
              }
            }, 5000);
          });
        });
    },
    getSlugResourcesList(payload) {
      this.isLoading = true;
      axios({
        method: "get",
        url:
          API_BASE_URL +
          "/role_resources/" +
          payload.role_id +
          "/" +
          payload.parent_id,
        // params: payload,
        headers: this.header,
      })
        .then((resResources) => {
          this.isLoading = false;
          this.slugResource = [];
          this.slugsResources = resResources.data.data;
          this.slugsResources.forEach((slugResource) => {
            slugResource.text = this.$t("roles.slug." + slugResource.slug);
            // if (slugResource.role_resource.can_add == 0) {
            //   slugResource.role_resource.can_add = false
            // } else if (slugResource.role_resource.can_add == 1) {
            //   slugResource.role_resource.can_add = true
            // }
            // if (slugResource.role_resource.can_delete == 0) {
            //   slugResource.role_resource.can_delete = false
            // } else if (slugResource.role_resource.can_delete == 1) {
            //   slugResource.role_resource.can_delete = true
            // }
            // if (slugResource.role_resource.can_edit == 0) {
            //   slugResource.role_resource.can_edit = false
            // } else if (slugResource.role_resource.can_edit == 1) {
            //   slugResource.role_resource.can_edit = true
            // }
            // if (slugResource.role_resource.can_view == 0) {
            //   slugResource.role_resource.can_view = false
            // } else if (slugResource.role_resource.can_view == 1) {
            //   slugResource.role_resource.can_view = true
            // }
          });
        })
        .catch((error) => {
          this.$store.state.alerts = [];
          if (error.response.data.message == "resources_not_found") {
            this.$store.state.alerts.push({
              msg: this.$t("user_role.resources_not_found"),
              show: true,
              type: "error",
            });
          } else {
            this.$store.state.alerts.push({
              msg: error.response.data.message,
              show: true,
              type: "error",
            });
          }
          this.$store.state.alerts.forEach((alert) => {
            setTimeout(() => {
              if (alert.type != "error") {
                alert.show = false;
              }
            }, 5000);
          });
        });
    },
    onSelectedRoleChange() {
      const payload = { role_id: this.selectedRole.id };
      this.getSlugList(payload);
      this.getSlugListForAddresources(payload);
    },
    onSelectedSlugChange() {
      this.selectedAddResource_slugs = this.selectedSlug;
      const payload = {
        role_id: this.selectedRole.id,
        parent_id: this.selectedSlug.resource_id,
      };
      this.getSlugResourcesList(payload);
    },
    onSaveResource() {
      this.$v.resource_description.$touch();
      this.$v.resource_name.$touch();
      if (!this.$v.$anyError) {
        let payload = {};
        if (this.selectedAddResource_slugs.resource_id == -1) {
          payload = {
            name: this.resource_name,
            description: this.resource_description,
          };
        } else {
          payload = {
            name: this.resource_name,
            description: this.resource_description,
            parent_id: this.selectedAddResource_slugs.resource_id,
          };
        }
        axios({
          method: "post",
          url: API_BASE_URL + "/resources",
          data: payload,
          headers: this.header,
        })
          .then((resNewResources) => {
            if (resNewResources.data.data.code == 201) {
              const payload = {
                role_id: this.selectedRole.id,
                parent_id: this.selectedSlug.resource_id,
              };
              this.getSlugResourcesList(payload);
              this.resource_name = "";
              this.resource_description = "";
              this.$v.$reset();
              this.$store.state.alerts = [];
              if (resNewResources.data.msg == "resource_added") {
                this.$store.state.alerts.push({
                  msg: this.$t("user_role.resource_added"),
                  show: true,
                  type: "success",
                });
              } else {
                this.$store.state.alerts.push({
                  msg: resNewResources.data.msg,
                  show: true,
                  type: "success",
                });
              }
              this.$store.state.alerts.forEach((alert) => {
                setTimeout(() => {
                  if (alert.type != "error") {
                    alert.show = false;
                  }
                }, 5000);
              });
              this.dialog = false;
            }
          })
          .catch((error) => {
            if (typeof error?.response?.data?.errors == "object") {
              this.$store.state.alerts = [];
              error.response.data.errors.forEach((error) => {
                if (error == "name_required") {
                  this.$store.state.alerts.push({
                    msg: this.$t("user_role.name_required"),
                    show: true,
                    type: "error",
                  });
                } else if (error == "name_already_exist") {
                  this.$store.state.alerts.push({
                    msg: this.$t("user_role.name_already_exist"),
                    show: true,
                    type: "error",
                  });
                } else if (error == "description_required") {
                  this.$store.state.alerts.push({
                    msg: this.$t("user_role.description_required"),
                    show: true,
                    type: "error",
                  });
                } else {
                  this.$store.state.alerts.push({
                    msg: error,
                    show: true,
                    type: "error",
                  });
                }
              });
            } else {
              this.$store.state.alerts = [];
              if (error.response.data.message == "something_went_wrong") {
                this.$store.state.alerts.push({
                  msg: this.$t("user_role.something_went_wrong"),
                  show: true,
                  type: "error",
                });
              } else {
                this.$store.state.alerts.push({
                  msg: error.response.data.message,
                  show: true,
                  type: "error",
                });
              }
            }
            this.$store.state.alerts.forEach((alert) => {
              setTimeout(() => {
                if (alert.type != "error") {
                  alert.show = false;
                }
              }, 5000);
            });
          });
      }
    },
    onCancelResource() {
      this.$v.$reset();
      this.dialog = false;
      this.resource_name = "";
      this.resource_description = "";
    },
    onCheck(id, value, type) {
      // value = !value
      if (value == true) {
        value = 1;
      } else if (value == false) {
        value = 0;
      }
      let permission = {};
      if (type == "can_add") {
        permission = { can_add: value };
      } else if (type == "can_edit") {
        permission = { can_edit: value };
      } else if (type == "can_delete") {
        permission = { can_delete: value };
      } else if (type == "can_view") {
        permission = { can_view: value };
      }
      const payload = {
        role_resource: [
          {
            resource_id: id,
            permission,
          },
        ],
      };
      this.onUpdateRole(payload);
    },
    onDescriptionChange() {
      const payload = {
        description: this.selectedRole.description,
      };
      this.onUpdateRole(payload);
    },
    onUpdateRole(payload) {
      axios({
        method: "put",
        url: API_BASE_URL + "/role_resources/" + this.selectedRole.id,
        headers: this.header,
        data: payload,
      })
        .then((res) => {
          this.$store.state.alerts = [];
          if (res.data.msg == "role_updated") {
            this.$store.state.alerts.push({
              msg: this.$t("user_role.role_updated"),
              show: true,
              type: "success",
            });
          } else {
            this.$store.state.alerts.push({
              msg: res.data.msg,
              show: true,
              type: "success",
            });
          }
          this.$store.state.alerts.forEach((alert) => {
            setTimeout(() => {
              if (alert.type != "error") {
                alert.show = false;
              }
            }, 5000);
          });
        })
        .catch((error) => {
          this.$store.state.alerts = [];
          if (typeof error?.response?.data?.errors == "object") {
            error.response.data.errors.forEach((error) => {
              if (error == "role_already_exist") {
                this.$store.state.alerts.push({
                  msg: this.$t("user_role.role_already_exist"),
                  show: true,
                  type: "error",
                });
              } else if (error == "role_not_fouund") {
                this.$store.state.alerts.push({
                  msg: this.$t("user_role.role_not_fouund"),
                  show: true,
                  type: "error",
                });
              } else if (error == "something_went_wrong") {
                this.$store.state.alerts.push({
                  msg: this.$t("user_role.something_went_wrong"),
                  show: true,
                  type: "error",
                });
              } else {
                this.$store.state.alerts.push({
                  msg: error,
                  show: true,
                  type: "error",
                });
              }
            });
          } else {
            if (error?.response?.data?.errors == "something_went_wrong") {
              this.$store.state.alerts.push({
                msg: this.$t("user_role.something_went_wrong"),
                show: true,
                type: "error",
              });
            }
          }
          this.$store.state.alerts.forEach((alert) => {
            setTimeout(() => {
              if (alert.type != "error") {
                alert.show = false;
              }
            }, 5000);
          });
        });
    },
    onDuplicateClick() {
      let roleResource = [];
      this.slugsResources.forEach((slugsResource) => {
        roleResource.push({
          resource_id: slugsResource.resource_id,
          permission: {
            can_add: slugsResource.role_resource.add ? 1 : 0,
            can_edit: slugsResource.role_resource.edit ? 1 : 0,
            can_delete: slugsResource.role_resource.delete ? 1 : 0,
            can_view: slugsResource.role_resource.view ? 1 : 0,
          },
        });
      });
      const payload = {
        name: this.selectedRole.name,
        description: this.selectedRole.description,
        role_id: this.selectedRole.id,
        type: "duplicate",
        role_resource: roleResource,
      };
      axios({
        method: "get",
        url: API_BASE_URL + "/user_roles/duplicate",
        headers: this.header,
        data: payload,
      })
        .then((resDuplicateRole) => {
          this.$store.state.alerts = [];
          localStorage.setItem(
            "DuplicateRole",
            JSON.stringify(resDuplicateRole.data.data.result)
          );
          this.$store.state.DuplicateRole = resDuplicateRole.data.data.result;
          this.$router.push("/new-roles").catch(() => {});
          if (resDuplicateRole.data.msg == "role_copied") {
            this.$store.state.alerts.push({
              msg: this.$t("user_role.role_copied"),
              show: true,
              type: "success",
            });
          } else {
            this.$store.state.alerts.push({
              msg: resDuplicateRole.data.msg,
              show: true,
              type: "success",
            });
          }
          this.$store.state.alerts.forEach((alert) => {
            setTimeout(() => {
              if (alert.type != "error") {
                alert.show = false;
              }
            }, 5000);
          });
        })
        .catch(() => {
          //
        });
    },

    onChildSlugClick(item) {
      this.slugs = [];
      this.addResource_slugs = [];
      this.slugs = this.slugsResources;
      this.addResource_slugs.push({
        resource_id: -1,
        slug: "As a Parent Slug",
        description: "New Slug",
      });
      this.slugs.forEach((slug) => {
        this.addResource_slugs.push(slug);
        if (slug.resource_id == item.resource_id) {
          this.selectedSlug = slug;
          this.selectedAddResource_slugs = slug;
        }
      });
      const payload = {
        role_id: this.selectedRole.id,
        parent_id: this.selectedSlug.resource_id,
      };
      this.getSlugResourcesList(payload);
    },
    goToParentClick() {
      this.isLoading = true;
      axios({
        method: "get",
        url:
          API_BASE_URL + "/resources/" + this.selectedSlug.parent_resource_id,
        headers: this.header,
      })
        .then((resourceDetail) => {
          if (resourceDetail.data.data.parent_resource_id != null) {
            axios({
              method: "get",
              url:
                API_BASE_URL +
                "/role_resources/" +
                this.selectedRole.id +
                "/" +
                resourceDetail.data.data.parent_resource_id,
              headers: this.header,
            })
              .then((resSlug) => {
                this.isLoading = false;
                this.slugs = [];
                this.addResource_slugs = [];
                this.slugs = resSlug.data.data;
                // this.slugs.push({ id: -1, slug: 'As a Parent Slug', description: 'New Slug' })
                this.addResource_slugs.push({
                  resource_id: -1,
                  slug: "As a Parent Slug",
                  description: "New Slug",
                });
                resSlug.data.data.forEach((slug) => {
                  this.addResource_slugs.push(slug);
                  // this.slugs.push(slug)
                  if (
                    slug.resource_id == this.selectedSlug.parent_resource_id
                  ) {
                    this.selectedSlug = slug;
                    this.selectedAddResource_slugs = slug;
                    const payload = {
                      role_id: this.selectedRole.id,
                      parent_id: this.selectedSlug.resource_id,
                    };
                    this.getSlugResourcesList(payload);
                  }
                });
              })
              .catch((error) => {
                if (error.response.data.message == '"resourceNotExist"') {
                  this.$store.state.alerts.push({
                    msg: this.$t("user_role.resources_not_found"),
                    show: true,
                    type: "error",
                  });
                } else {
                  this.$store.state.alerts.push({
                    msg: error.response.data.message,
                    show: true,
                    type: "error",
                  });
                }
                this.$store.state.alerts.forEach((alert) => {
                  setTimeout(() => {
                    if (alert.type != "error") {
                      alert.show = false;
                    }
                  }, 5000);
                });
              });
          } else {
            this.isLoading = true;
            axios({
              method: "get",
              url: API_BASE_URL + "/role_resources/" + this.selectedRole.id,
              headers: this.header,
            })
              .then((resSlug) => {
                this.isLoading = false;
                this.slugs = [];
                this.addResource_slugs = [];
                this.slugs = resSlug.data.data;
                this.addResource_slugs.push({
                  resource_id: -1,
                  slug: "As a Parent Slug",
                  description: "New Slug",
                });
                resSlug.data.data.forEach((slug) => {
                  this.addResource_slugs.push(slug);
                  if (
                    slug.resource_id == this.selectedSlug.parent_resource_id
                  ) {
                    this.selectedSlug = slug;
                    this.selectedAddResource_slugs = slug;
                    const payload = {
                      role_id: this.selectedRole.id,
                      parent_id: this.selectedSlug.resource_id,
                    };
                    this.getSlugResourcesList(payload);
                  }
                });
              })
              .catch((error) => {
                this.$store.state.alerts = [];
                if (error.response.data.message == '"resourceNotExist"') {
                  this.$store.state.alerts.push({
                    msg: this.$t("user_role.resources_not_found"),
                    show: true,
                    type: "error",
                  });
                } else {
                  this.$store.state.alerts.push({
                    msg: error.response.data.message,
                    show: true,
                    type: "error",
                  });
                }
                this.$store.state.alerts.forEach((alert) => {
                  setTimeout(() => {
                    if (alert.type != "error") {
                      alert.show = false;
                    }
                  }, 5000);
                });
              });
          }
        })
        .catch((error) => {
          this.$store.state.alerts = [];
          error.response.data.errors.forEach((error) => {
            if (error == "something_went_wrong") {
              this.$store.state.alerts.push({
                msg: this.$t("user_role.something_went_wrong"),
                show: true,
                type: "error",
              });
            } else if (error == "resourceNotExist") {
              this.$store.state.alerts.push({
                msg: this.$t("user_role.resourceNotExist"),
                show: true,
                type: "error",
              });
            } else {
              this.$store.state.alerts.push({
                msg: error,
                show: true,
                type: "error",
              });
            }
          });
          this.$store.state.alerts.forEach((alert) => {
            setTimeout(() => {
              if (alert.type != "error") {
                alert.show = false;
              }
            }, 5000);
          });
        });
    },
  },
};
</script>

<style scoped>
@import url("../../../assets/CSS/style-main.css");
</style>
