<template>
  <div>
    <v-sheet>
      <v-tooltip v-model="dialog" location="top">
        <template v-slot:activator="{ props }">
          <v-calendar
            :key="calendarKey"
            v-bind="props"
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :locale="locale"
            category-show-all
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :interval-minutes="minimumWorkDuration"
            :first-interval="firstInterval"
            :interval-count="intervalCount"
            :interval-format="(interval) => interval.time"
            :hide-header="false"
            :weekday-format="() => ''"
            :categories="categories"
            :event-ripple="false"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
            :day-format="(day) => formatDay(day)"
            @click:event="(e) => editAppointment(e.event)"
            @click:time="(e) => addNewAppointment(e)"
            @click:date="(e) => addNewAppointment(e)"
            @click:day="(e) => addNewAppointment(e)"
            @mouseenter:event="(e) => showMore(e.date, e.event)"
            @mouseleave:event="(e) => hideMore()"
            :style="{ 'min-height': '80vh' }"
          >
            <template v-slot:event="{ event }">
              <div style="padding: 5px; cursor: pointer">
                <p
                  style="float: left; font-weight: bolder"
                  class="title-section"
                >
                  <v-icon
                    style="font-size: 20px"
                    color="white"
                    v-if="
                      appointmentByIndex[event.id].appointment_status.key ===
                      'appointment_is_executed'
                    "
                  >
                    mdi-account-check
                  </v-icon>
                  {{ event.name }}
                  <br />
                  {{ getTimeFromDate(event.start) }} -
                  {{ getTimeFromDate(event.end) }}
                </p>
                <div style="float: right" class="icons-segment">
                  <span
                    v-if="thirdIconIsVisible(appointmentByIndex[event.id])"
                    :style="{
                      backgroundColor: '#fff',
                      borderRadius: '2px',
                      float: 'right',
                      position: 'absolute',
                      right: '0',
                      top: '0',
                      marginTop: '3px',
                      marginBottom: '2px',
                      height: '18.5px',
                      width: '18.5px',
                      textAlign: 'center',
                    }"
                  >
                    <v-icon
                      :style="{
                        fontSize: '15px',
                        paddingBottom: '2px',
                      }"
                      color="red"
                      >mdi-bell-cancel</v-icon
                    >
                  </span>
                  <span
                    v-if="
                      appointmentByIndex[event.id].external_order_status &&
                      appointmentByIndex[event.id].external_order_status != null
                    "
                    :style="{
                      float: 'right',
                      position: 'absolute',
                      right: '0',
                      top: '0',
                      marginTop: '3px',
                      marginBottom: '2px',
                      backgroundColor: '#fff',
                      borderRadius: '2px',
                      height: '18.5px',
                      width: '18.5px',
                      textAlign: 'center',
                    }"
                  >
                    <v-icon
                      :color="
                        computeExternalOrderStatus(
                          appointmentByIndex[event.id].external_order_status
                        ).color
                      "
                      :style="{
                        fontSize: '15px',
                        paddingBottom: '2px',
                      }"
                    >
                      {{
                        computeExternalOrderStatus(
                          appointmentByIndex[event.id].external_order_status
                        ).icon
                      }}
                    </v-icon>
                  </span>
                  <v-icon
                    color="white"
                    v-if="firstIconIsVisible(appointmentByIndex[event.id])"
                    >mdi-code-greater-than
                  </v-icon>
                  <v-icon
                    color="white"
                    :style="{}"
                    v-if="secondIconIsVisible(appointmentByIndex[event.id])"
                    >mdi-code-less-than
                  </v-icon>
                </div>
              </div>
            </template>
          </v-calendar>
        </template>
        <span>{{ dialogContent }}</span>
      </v-tooltip>
    </v-sheet>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import { API_BASE_URL } from "@/config";

export default {
  props: {
    selectedDate: {
      default: null,
    },
    workResources: {
      type: Array,
      required: true,
    },
    calendarType: {
      type: String,
      default: "week",
    },
    workPoint: {
      type: Object,
      required: true,
    },
    resourceType: {
      type: Object,
      required: true,
    },
    timeSlots: {
      type: Array,
      required: true,
    },
    minimumWorkDuration: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    dialogContent: "",
    dialogTimer: null,
    mode: "column",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    appointments: [],
    dragEvent: null,
    dragEventStartPoint: 0,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    redirecting: false,
    appointmentByIndex: {},
    calendarKey: 0,
    slotDuration: null,
  }),
  mounted() {
    this.slotDuration = this.processSlots(this.timeSlots);

    if (this.selectedDate) {
      this.value = this.selectedDate;
    }

    let currentDate = this.selectedDate
      ? moment(this.selectedDate)
      : moment().format("YYYY-MM-DD");
    this.fetchAppointmentsByWeek(
      currentDate.clone().startOf(this.calendarType).toDate(),
      currentDate.clone().endOf(this.calendarType).toDate()
    );
  },
  activated() {
    let currentDate = this.selectedDate
      ? moment(this.selectedDate)
      : moment().format("YYYY-MM-DD");
    this.fetchAppointmentsByWeek(
      currentDate.clone().startOf(this.calendarType).toDate(),
      currentDate.clone().endOf(this.calendarType).toDate()
    );
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    viewInFocus() {
      return ["week", "month"].includes(this.calendarType);
    },
    appointmentIsFormatted() {
      return Object.keys(this.appointmentByIndex).length > 0;
    },
    categories() {
      if (this.calendarType !== "day") {
        return [];
      }

      return this.workResources.map((workResource) => workResource.name);
    },
    type() {
      if (this.calendarType === "day") {
        return "category";
      }

      return this.calendarType;
    },
    selectedMonth() {
      return moment(this.selectedDate).format("MMMM");
    },
    firstInterval() {
      if (!this.slotDuration) {
        return 0;
      }

      let minInterval = this.slotDuration.minTime;
      let minHour = minInterval.split(":")[0];
      minHour = parseInt(minHour);
      const hoursInAMinute = 60;

      return Math.floor(
        (hoursInAMinute / this.minimumWorkDuration) * minHour - 1
      );
    },
    intervalCount() {
      if (!this.slotDuration) {
        return 0;
      }

      let minInterval = this.slotDuration.minTime;
      let maxInterval = this.slotDuration.maxTime;
      let minHour = minInterval.split(":")[0];
      let maxHour = maxInterval.split(":")[0];
      let maxMinute = maxInterval.split(":")[1];
      maxMinute = parseInt(maxMinute);
      minHour = parseInt(minHour);
      maxHour = parseInt(maxHour);

      if (maxMinute > 0) {
        maxHour += 1;
      }

      const hoursInAMinute = 60;

      return Math.ceil(
        ((maxHour - minHour + 1) * hoursInAMinute) / this.minimumWorkDuration
      );
    },
  },
  watch: {
    selectedDate(val) {
      this.value = val;
      if (this.viewInFocus) {
        this.fetchAppointmentsByNewDate(val);
      }
    },
    appointments(val) {
      if (!val) {
        return null;
      }
      this.events = this.formatAppointments();
    },
    timeSlots(val) {
      this.slotDuration = this.processSlots(val);
    },
    workPoint(next, prev) {
      if (!prev || !this.viewInFocus) {
        return;
      }

      this.fetchAppointmentsByNewDate(this.selectedDate);
    },
    resourceType(next, prev) {
      if (!prev || !this.viewInFocus) {
        return;
      }

      this.fetchAppointmentsByNewDate(this.selectedDate);
    },
    calendarType() {
      if (!this.viewInFocus) {
        return;
      }
      this.fetchAppointmentsByNewDate(this.selectedDate);
    },
  },
  methods: {
    formatAppointments() {
      let reArrange = {};
      let newAppointments = this.appointments.map((appointment) => {
        reArrange[appointment.id] = appointment;
        let originalStartTime = moment(appointment.start_time);
        let originalEndTime = moment(appointment.end_time);

        let officeStartTime = moment(
          originalStartTime.format("YYYY-MM-DD") +
            " " +
            this.slotDuration.minTime
        );
        let officeEndTime = moment(
          originalEndTime.format("YYYY-MM-DD") + " " + this.slotDuration.maxTime
        );
        let startTime = originalStartTime.clone();
        let endTime = originalEndTime.clone();

        // // IF start TIme is less than office start time then set office start time as start time
        if (
          originalStartTime.isBefore(officeStartTime) ||
          originalStartTime.isAfter(officeEndTime) ||
          originalEndTime.isBefore(officeStartTime) ||
          originalEndTime.isAfter(officeEndTime) ||
          originalEndTime.isBefore(originalStartTime)
        ) {
          startTime = officeStartTime;
        }
        return {
          name: appointment.title,
          start: startTime.toDate(),
          end: endTime.toDate(),
          color: "#" + appointment.color,
          id: appointment.id,
          appointment_status: appointment.appointment_status,
          category:
            this.workResources.find(
              (workResource) => workResource.id === appointment.work_resource_id
            )?.name ?? "",
          appointment: appointment,
          timed: true,
        };
      });
      this.appointmentByIndex = reArrange;
      return newAppointments;
    },
    formatDay(day) {
      return this.$t("week_days.short." + day.weekday) + " " + day.day;
    },
    fetchAppointmentsByWeek(startDate = null, endDate = null) {
      startDate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : moment().startOf(this.calendarType).format("YYYY-MM-DD");
      endDate = endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().endOf(this.calendarType).format("YYYY-MM-DD");
      this.$store.commit("setLoading", true);
      let url = `${API_BASE_URL}/appointments?start_date=${startDate}&end_date=${endDate}&relation_data=1`;

      if (this.workPoint) {
        url += `&work_point_id=${this.workPoint.id}`;
      }

      if (this.resourceType) {
        url += `&resource_type_id=${this.resourceType.id}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        })
        .then((response) => {
          this.appointments = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeWeek(action) {
      action === "next"
        ? this.$refs.calendar.next()
        : this.$refs.calendar.prev();

      this.fetchAppointmentsByNewDate(this.value);
      this.$emit("selectedDateChanged", this.value);
    },
    fetchAppointmentsByNewDate(newDate) {
      let getDate = moment(newDate);
      let startOfWeek = getDate.clone().startOf(this.calendarType);
      let endOfWeek = getDate.clone().endOf(this.calendarType);

      this.fetchAppointmentsByWeek(startOfWeek.toDate(), endOfWeek.toDate());
    },
    addNewAppointment(event) {
      if (this.eventDragged() && this.redirecting === false) {
        return null;
      }

      if (this.redirecting) {
        return null;
      }

      setTimeout(() => {
        if (this.redirecting === true) {
          return null;
        }

        let startDateTime = event.date + " " + event.hour + ":00";
        this.$store.commit("setAppointmentRedirectData", {
          new_start_date_time: startDateTime,
          work_resource_id: this.workResources[0].id,
          resourceType: this.resourceType,
          calendarViewType: this.calendarType,
        });
        this.$router.push("/scheduler/add");
      }, 300);
    },
    editAppointment(appointment) {
      if (this.eventDragged()) {
        return null;
      }

      this.$store.commit("setAppointmentRedirectData", {
        id: appointment.id,
        appointment_status: appointment.appointment_status,
        resourceType: this.resourceType,
      });
      this.redirecting = true;
      setTimeout(() => {
        this.redirecting = false;
      }, 5000);
      this.$router.push("/scheduler/edit");
    },
    eventDragged() {
      return (
        this.dragEvent !== null &&
        this.dragEvent.start - this.dragEventStartPoint !== 0
      );
    },
    showMore(date, event) {
      this.dialogTimer = setTimeout(() => {
        this.dialogContent = event?.name || "";
        this.dialog = true;
      }, 100);
    },
    updateDurationOfDraggedEvent(event) {
      let startDate = moment
        .unix(event.start / 1000)
        .format("MM/DD/YYYY HH:mm:ss");
      let endDate = moment.unix(event.end / 1000).format("MM/DD/YYYY HH:mm:ss");

      if (event.appointment.is_full_day) {
        startDate = moment(
          moment(startDate).format("YYYY-MM-DD") +
            " " +
            this.slotDuration.minTime
        ).format("MM/DD/YYYY HH:mm:ss");
        endDate = moment(
          moment(endDate).format("YYYY-MM-DD") + " " + this.slotDuration.maxTime
        ).format("MM/DD/YYYY HH:mm:ss");
      }

      return axios
        .put(
          API_BASE_URL + "/appointments/" + event.id + "/duration",
          {
            start_time: startDate,
            end_time: endDate,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          let appointment = response.data;
          let jsStartTime = moment(appointment.start_time).toDate();
          let jsEndTime = moment(appointment.end_time).toDate();
          let eventIndex = this.events.findIndex(
            (appointment) => appointment.id === appointment.id
          );
          this.events[eventIndex].start = jsStartTime;
          this.events[eventIndex].end = jsEndTime;
        })
        .catch(({ response }) => console.log(response));
    },
    hideMore() {
      clearTimeout(this.dialogTimer);
      this.dialog = false;
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragEventStartPoint = this.dragEvent.start;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      if (this.eventDragged()) {
        this.updateDurationOfDraggedEvent(this.dragEvent);
      }

      setTimeout(() => {
        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
      }, 10);
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`).getTime();
      const max = new Date(`${end.date}T23:59:59`).getTime();
      const days = (max - min) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const timed = this.rnd(0, 3) !== 0;
        const firstTimestamp = this.rnd(min, max);
        const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
        const start = firstTimestamp - (firstTimestamp % 900000);
        const end = start + secondTimestamp;
        events.push({
          name: this.rndElement(this.names),
          color: this.rndElement(this.colors),
          category: this.rndElement(this.categories),
          start,
          end,
          timed,
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getTimeFromDate(dateString) {
      let dateTime = new Date(dateString);

      return dateTime.getHours() + ":" + dateTime.getMinutes();
    },
    firstIconIsVisible(appointment) {
      return appointment.original_start_time < appointment.start_time;
    },
    secondIconIsVisible(appointment) {
      return appointment.original_start_time > appointment.start_time;
    },
    thirdIconIsVisible(appointment) {
      return (
        appointment.appointment_status &&
        appointment.appointment_status.key === "appointment_canceled"
      );
    },
    computeExternalOrderStatus(status) {
      if (status === "success") {
        return {
          icon: "mdi-check-bold",
          color: "green",
        };
      } else if (status === "in_progress") {
        return {
          icon: "mdi-timer-sand",
          color: "orange",
        };
      } else if (status === "error") {
        return {
          icon: "mdi-information",
          color: "orange",
        };
      } else {
        return {
          icon: "mdi-timer-sand",
          color: "red",
        };
      }
    },
  },
};
</script>

<style scoped>
.v-tooltip__content {
  font-size: 14px !important;
  background: rgb(255, 255, 255) !important;
  border: 1px solid black;
  line-height: 0%;
  opacity: 1 !important;
  color: black !important;
  padding: 15px !important;
}
</style>
