<template>
  <v-row>
    <v-col>
      <!-- <v-col class="d-flex" cols="2" sm="2">
                    <v-select
                        :autocomplete="autocompleteValueLocal"
                        v-model="familyArticle"
                        :items="familyArticles"
                        @click:append="removeFamilyArticle()"
                        :append-icon="familyArticle ? 'mdi-close' : null"
                        :placeholder="familyArticle ? $t('articles.family') : $t('articles.everything')"
                        item-value="id"
                        item-text="name"
                        @change="updateLocalStorageValues(true)"
                        v-on:keydown.enter.prevent="focusOnFamilyArticle();"
                        class="mr-0"
                        ref="familyArticle"
                        return-object
                        :prepend-icon="normalSearch ? 'mdi-grid' : 'mdi-rectangle-outline'"
                        @click:prepend="normalSearch = !normalSearch"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-select>
                    </v-col> -->
      <v-row class="pa-0 mt-0 mr-2">
        <v-autocomplete
          :autocomplete="autocompleteValueLocal"
          v-on:keydown.enter.prevent=""
          v-if="parentSearchSelectedArticleId"
          :items="[parentSearchSelectedArticleId]"
          v-model="parentSearchSelectedArticleId"
          class="mr-2 to-upper"
          item-value="id"
          item-text="description"
          append-icon="mdi-close"
          @click:append="
            parentSearchSelectedArticleId = null;
            resetEverything();
          "
          return-object
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-no-data
        ></v-autocomplete>
      </v-row>
      <v-row v-if="!parentSearchSelectedArticleId" class="pa-0 mt-0">
        <v-col class="d-flex" cols="12" sm="11">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-if="normalSearch || customFilters.length == 0"
            class="mr-3 to-upper"
            label="Search"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            @keyup="updateLocalStorageValues()"
            v-on:keydown.enter.prevent="focusOnFamilyArticle()"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
          <v-select
            :autocomplete="autocompleteValueLocal"
            v-if="
              familyArticle &&
              (familyArticle.parent_family_id == 1 || familyArticle.id == 1)
            "
            :items="seasons"
            @change="updateLocalStorageValues()"
            v-model="season"
            class="d-flex ma-0 pa-0 pt-4 mr-2 to-upper shrink"
            style="width: 80px"
            item-value="value"
            item-text="value"
            hide-details="auto"
            v-on:keydown.enter.prevent="focusOnFamilyArticle()"
            :outlined="is_boxes"
            :dense="is_boxes"
          >
            <template v-slot:selection="{ item }">
              <span>
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </span>
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-list-item-content class="pa-0 ma-0">
                <span>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </span>
              </v-list-item-content>
            </template>
          </v-select>
          <!-- <v-menu
                                  :offset-y="100"
                                  close-on-content-click
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      color="grey darken-3"
                                      text
                                      v-on="on"
                                    >
                                      <v-icon left>mdi-account-cog</v-icon>
                                        <v-icon right>mdi-chevron-down</v-icon> 
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item
                                      v-for="(item, index) in seasons"
                                      :key="index"
                                    >
                                      <v-list-item-title>
                                        <v-icon>{{item.icon}}</v-icon> </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu> -->

          <div
            v-for="(filter, index) in filterPropertiesExcept(customFilters)"
            :key="index"
          >
            <template v-if="filter.property_name == 'property_00'">
              <v-autocomplete
                v-if="filter.property_name == 'property_00'"
                flat
                :ref="filter.property_name"
                :items="brands"
                :search-input.sync="searchBrand"
                v-model="brand"
                :label="$t('articles.properties.brand')"
                class="mr-2 to-upper"
                item-value="id"
                item-text="name"
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
                @change="fetchData()"
                v-on:keydown.enter.prevent="
                  fetchData();
                  focusOnFamilyArticle();
                "
                hide-no-data
                auto-select-first
              ></v-autocomplete>
            </template>
            <template v-else>
              <v-autocomplete
                v-if="filter.mapping_key != null"
                v-model="filter.value"
                :items="multiselectsValues[index]"
                :label="filter.text"
                item-value="id"
                hide-details="auto"
                class="mr-2"
                append-icon="mdi-close"
                @click:append="
                  filter.value = null;
                  fetchData();
                "
                :outlined="is_boxes"
                :dense="is_boxes"
                @change="fetchData()"
                v-on:keydown.enter.prevent="
                  fetchData();
                  focusOnFamilyArticle();
                "
                hide-no-data
              ></v-autocomplete>
              <v-text-field
                flat
                v-if="filter.mapping_key == null"
                v-model="filter.value"
                :label="filter.text"
                class="mr-2 to-upper"
                :ref="filter.property_name"
                @focus="filter.value = null"
                @input="debounceInput"
                v-on:keydown.enter.prevent="
                  fetchData();
                  focusOnFamilyArticle();
                "
                @keypress="
                  handleInput($event, filter.keytype, filter.search_length);
                  fetchData();
                "
                @keyup="
                  handleAutoFocus(
                    $event,
                    filter.search_length,
                    filter.property_name,
                    filter.keytype,
                    filterPropertiesExcept(customFilters)[index + 1]
                      ? filterPropertiesExcept(customFilters)[index + 1]
                          .property_name
                      : null
                  )
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </template>
          </div>

          <v-select
            v-if="articleBooleanProperties.length >= 1"
            v-model="booleanSelectedValues"
            :label="$t('articles.properties.more')"
            :items="articleBooleanProperties"
            v-on:keydown.enter.prevent="focusOnFamilyArticle()"
            item-value="id"
            item-text="name"
            multiple
            :outlined="is_boxes"
            :dense="is_boxes"
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <span>
                <template
                  v-if="index === 0 && booleanSelectedValues.length == 1"
                >
                  {{
                    item.name.substr(
                      0,
                      booleanSelectedValues.length > 1 ? 3 : 5
                    )
                  }}
                </template>
                <template v-if="index === 1">
                  (+{{ booleanSelectedValues.length - 1 }})
                </template>
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="1">
          <v-btn color="primary" dark class="mt-3 ml-n5" @click="dialog = true">
            <v-icon dark>mdi-database-search</v-icon>
          </v-btn>
        </v-col>

        <v-data-table
          style="display: none"
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="customHeader"
          :items="articles"
          :items-per-page="itemsPerPage"
          :options.sync="options"
          :sort-by="sortBy"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          class="elevation-1"
          item-key="identifier"
          :height="articles.length <= 1 ? '0px' : '0px'"
        >
        </v-data-table>
      </v-row>
    </v-col>
    <search-article-model
      @changeDialog="changeDialog()"
      :showFamilyArticle="false"
      :familyArticleId.sync="familyArticle"
      :multiselectsValues.sync="multiselectsValues"
      :multiselects.sync="multiselects"
      :compSelectedArticleId.sync="parentSearchSelectedArticleId"
      :internalStockOnly.sync="internalStockOnly"
      :showDialog.sync="dialog"
      :returnItemObjectOrId="'object'"
    ></search-article-model>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import SearchArticleModel from "@/components/SearchArticleModel.vue";

export default {
  name: "Articles",
  components: {
    "search-article-model": SearchArticleModel,
  },
  props: {
    compSelectedArticleId: [String, Number, Object],
    internalStockOnly: [Boolean, Number],
  },
  async mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();

    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setAddButtonRoute", "AddArticle", "EditArticle");
    this.setDefaultTyreFamilyArticle();
  },
  data() {
    return {
      booleanSelectedValues: [],
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      normalSearch: false,
      seasons: [
        {
          icon: "mdi-check-all",
          color: "grey darken-1",
          text: "no_season",
          value: "0",
        },
        {
          icon: "mdi-weather-sunny",
          color: "amber darken-4",
          text: "summer",
          value: "10111002",
        },
        {
          icon: "mdi-weather-snowy-heavy",
          color: "blue darken-2",
          text: "winter",
          value: "10111001",
        },
        {
          icon: "mdi-weather-partly-snowy-rainy",
          color: "green darken-1",
          text: "all_season",
          value: "10111003",
        },
      ],
      parentSearchSelectedArticleId: null,
      autocompleteValueLocal: "off",
      dialog: false,
      selectedArticleId: this.compSelectedArticleId,
      search: null,
      isFirstTimeLoaded: 0,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isLoading: false,
      articles: [],
      article: "",
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      season: 0,
      widths: ["205", "255", "245", "145", "225"],
      width: "",
      heights: ["45", "55", "60", "65", "75", "85", "95"],
      height: "",
      rims: ["11", "12", "13", "14", "15", "16", "17", "18", "19"],
      rim: "",
      lis: [
        "90",
        "91",
        "100",
        "120",
        "130",
        "140",
        "150",
        "160",
        "170",
        "180",
        "190",
      ],
      li: "",
      sis: ["H", "V", "W"],
      si: "",
      brands: [],
      brand: null,
      approval: { id: 10011001 },
      status: { id: 10001001 },
      articleBooleanProperties: [],
      familyArticle: {},
      loadingFirstTime: 0,
      ifCurrentlyPaused: false,
      changingArticlePropertiesInProgress: false,
      customHeader: [
        {
          text: this.$t("articles.id"),
          value: "id",
          align: "start",
          sortable: false,
          width: 5,
        },
        {
          text: this.$t("articles.image"),
          value: "image",
          align: "start",
          sortable: false,
          width: 30,
        },
        {
          text: this.$t("articles.ean"),
          value: "identifier",
          align: "start",
          sortable: true,
          width: 140,
        },
        {
          text: this.$t("articles.description"),
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: this.$t("articles.tariff_price"),
          align: "end",
          sortable: false,
          value: "tariff_price",
        },
      ],
      customFilters: [],
      customFiltersExceptions: [1, 2], // these numbers represents property_id value example : property_id = 1
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      isDebounceCancelled: false,
      searchBrand: null,
    };
  },
  methods: {
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.fetchData();
    }, 500),
    getMultiSelectValuesFromCode() {
      this.customFilters.forEach((filter, filterindex) => {
        this.multiselectsValues[filterindex] = [];
      });
      this.multiselects.forEach((code) => {
        this.getCodes(parseInt(code), "codes").then((data) => {
          this.customFilters.forEach((filter, filterindex) => {
            if (filter.mapping_key == code) {
              this.multiselectsValues[filterindex] = data;
            }
          });
          this.multiselectsValues.push({});
          this.multiselectsValues.splice(-1);
        });
      });
    },
    filterPropertiesExcept(customFilters) {
      let properties = [];
      if (
        this.familyArticle &&
        (this.familyArticle.parent_family_id == 1 || this.familyArticle.id == 1)
      ) {
        properties = ["property_02"];
      }

      let filteredProperties = [];
      filteredProperties = customFilters.filter((property) => {
        let found = false;
        properties.forEach((element) => {
          if (element == property.property_name) {
            found = true;
          }
        });

        return found ? false : property;
      });
      return filteredProperties;
    },
    changeDialog() {
      this.applyPreviousFilters();
      this.dialog = false;
    },
    showDialog() {
      this.parentSearchSelectedArticleId = null;
      this.dialog = true;
    },
    /**
     * This Function is responsible to autofocus on next property field, if current's max length reached
     */
    handleAutoFocus(
      event,
      search_length,
      propertyName,
      dataType,
      forceToJumpOnProperty
    ) {
      let currentFieldLength = 0;
      if (dataType == "int" || dataType == "float") {
        currentFieldLength = (event.target.value + "").replace(".", "").length;
      } else {
        currentFieldLength = (event.target.value + "").length;
      }
      if (
        forceToJumpOnProperty &&
        search_length != null &&
        currentFieldLength == search_length
      ) {
        this.$refs[forceToJumpOnProperty][0].focus();
      } else if (
        propertyName.search("property") == 0 &&
        search_length != null &&
        currentFieldLength == search_length
      ) {
        let underscorePos = propertyName.search("_") + 1;
        let propertyNo = Number(propertyName.substr(underscorePos)) + 1;
        let nextPropertyName =
          "property_" + (propertyNo < 10 ? "0" : "") + propertyNo;
        this.$refs[nextPropertyName][0].focus();
      }
    },
    focusOnFamilyArticle() {
      // this.$refs['familyArticle'].focus();
      this.$nextTick(() => {
        this.dialog = true;
      });
    },
    removeFamilyArticle() {
      this.familyArticle = null;
    },
    getFamilyArticleById(id) {
      let familyArticle = 0;
      this.familyArticles.forEach((element) => {
        if (element.id == id) {
          familyArticle = element;
        }
      });

      return familyArticle;
    },
    resetFurtherBoxesValues(focussedPropertyName) {
      let foundProperty = false;
      this.customFilters.forEach((filter, index) => {
        if (filter.property_name == focussedPropertyName) {
          foundProperty = true;
        }
        if (foundProperty) {
          this.customFilters[index].value = null;
        }
      });
    },
    updateLocalStorageValues(familyUpdated) {
      let filters = {
        normalSearch: this.normalSearch,
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortDesc,
        approval: this.approval.id,
        status: this.status.id,
        family: this.familyArticle ? this.familyArticle.id : 0,
        season: this.season,
        brand: familyUpdated ? null : this.brand != null ? this.brand : "",
        search: this.search,
        appliedFilters: familyUpdated
          ? []
          : this.getQueryStringFromDynamicallyCreatedFilters()[1],
        id: this.article ? this.article.id : null,
      };
      localStorage.setItem("ARTICLE_MODEL_FILTERS", JSON.stringify(filters));
    },
    resetEverything() {
      this.resetFurtherBoxesValues(this.customFilters[0].property_name);
      this.search = null;
      this.brand = null;
    },
    applyPreviousFilters() {
      if (this.customFilters.length > 0) {
        this.resetFurtherBoxesValues(this.customFilters[0].property_name);
        this.search = null;
        this.brands = [];
        this.brand = null;
      }
      let applyOnRoutes = [
        "AddBilling",
        "Billing",
        "AddScheduler",
        "EditScheduler",
        "TyreHotel",
      ];
      if (applyOnRoutes.includes(localStorage.getItem("NT_ROUTE_KEY"))) {
        let articleFilters = JSON.parse(
          localStorage.getItem("ARTICLE_MODEL_FILTERS")
        );
        if (articleFilters != null) {
          this.normalSearch = articleFilters.normalSearch;
          this.page = articleFilters.page;
          this.itemsPerPage = articleFilters.items_per_page;
          this.sortBy = articleFilters.order_by
            ? articleFilters.order_by
            : "id";
          this.sortDesc = articleFilters.order_direction;
          this.approval.id = articleFilters.approval;
          this.status.id = articleFilters.status;
          this.familyArticle = articleFilters.family
            ? this.getFamilyArticleById(articleFilters.family)
            : 0;
          this.getBrands();
          this.season = articleFilters.season;

          this.search = articleFilters.search;

          this.customFilters.forEach((element) => {
            let keyName = element.property_name;
            this.customFilters.find((el, index) => {
              if (el.property_name == keyName) {
                this.customFilters[index].value = null;
                el.value = element[keyName];
                return el;
              }
            });
          });

          this.booleanSelectedValues = [];
          articleFilters.appliedFilters.forEach((element) => {
            let keyName = Object.keys(element)[0];
            // now map from custom filters and update their value
            this.customFilters.find((el, index) => {
              if (el.property_name == keyName) {
                this.customFilters[index].value = element[keyName];
                el.value = element[keyName];
                return el;
              }
            });

            this.articleBooleanProperties.find((el) => {
              if (el.property_name == keyName) {
                let foundBoolean = false;
                this.booleanSelectedValues.forEach((selectedBoolean) => {
                  if (selectedBoolean.property_id == el.property_id) {
                    foundBoolean = true;
                  }
                });
                if (!foundBoolean) {
                  this.booleanSelectedValues.push(el);
                }
                return el;
              }
            });
          });

          // set brand
          this.brand = articleFilters.brand;

          this.fetchData();
        }
      }
    },
    checkIfFetchingPossible() {
      let isPossible = false;
      this.ifCurrentlyPaused = false;

      let valueAssigned = 0;
      this.customFilters.forEach((element) => {
        if (element.value != null) {
          valueAssigned++;
        }
      });
      if (valueAssigned != 0) {
        isPossible = true;
      } else {
        isPossible = false;
      }

      if (!isPossible) {
        this.ifCurrentlyPaused = true;
      }

      if (
        this.familyArticle == null ||
        this.familyArticle.id == 0 ||
        this.familyArticle.id == 3 ||
        this.familyArticle.id == 4
      ) {
        isPossible = true;
      }

      if (this.changingArticlePropertiesInProgress) {
        isPossible = false;
      }
      if (this.search) {
        isPossible = true;
      }

      if (this.brand) {
        isPossible = true;
      }
      return isPossible;
    },
    fetchData() {
      this.articles = []; // remove the data from the table
      if (this.checkIfFetchingPossible()) {
        this.isLoading = true;

        // this.$emit('update:compSelectedArticleId', null ); // remove selected article
        let filters = {
          page: this.page,
          items_per_page: this.itemsPerPage,
          order_by: this.sortBy,
          order_direction: this.sortDesc,
          approval: this.approval.id,
          status: this.status.id,
          family: this.familyArticle ? this.familyArticle.id : 0,
          season: this.season,
          brand: this.brand != null ? this.brand : "",
          search: this.search,
          appliedFilters: this.getQueryStringFromDynamicallyCreatedFilters()[1],
          id: this.article ? this.article.id : null,
        };

        localStorage.setItem("ARTICLE_MODEL_FILTERS", JSON.stringify(filters));
      }
    },
    setDefaultTyreFamilyArticle() {
      let tyreFamilyArticle = null;
      this.familyArticles.forEach((element) => {
        if (element.id == 1 || element.parent_family_id == 1) {
          tyreFamilyArticle = element;
        }
      });
      if (tyreFamilyArticle) {
        this.familyArticle = tyreFamilyArticle;
      }
      this.updateLocalStorageValues();
    },
    // selectItem(item) {
    //   this.$emit('update:compSelectedArticleId', item.id );
    //   this.showDialog = false;
    // },
    showImage() {
      alert("THE IMAGE");
    },
    deleteItem() {},
    changeArticleProperties() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });

      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }

      this.search = null;
      // hard coding for family : todo later make it dynamic
      if (currentFamilyArticle.parent_family_id === 2) {
        this.season = "";
      }

      this.articleBooleanProperties = currentFamilyArticle.properties.filter(
        (property) => {
          if (property.cast == "boolean" && property.in_filter == 1) {
            property.name = property.translated
              ? property.name
              : this.$t("articles.properties." + property.name);
            property.property_name =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);
            property.translated = true;
            return property;
          }
          return false;
        }
      );
    },
    setCustomDataTableHeaders() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });
      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }
      if (
        this.familyArticle &&
        (this.familyArticle.parent_family_id == 1 ||
          this.familyArticle.parent_family_id == 2)
      ) {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.image"),
            value: "image",
            align: "start",
            sortable: false,
            width: 30,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: true,
            width: 140,
          },
          {
            text: this.$t("articles.description"),
            align: "start",
            sortable: true,
            value: "description",
          },
          {
            text: this.$t("articles.tariff_price"),
            align: "end",
            sortable: false,
            value: "tariff_price",
          },
          {
            text: this.$t("articles.stock"),
            align: "end",
            sortable: false,
            value: "stock",
          },
          {
            text: this.$t("articles.reserve"),
            align: "end",
            sortable: false,
            value: "reserve",
          },
          {
            text: this.$t("articles.stock_reserve"),
            align: "end",
            sortable: false,
            value: "stock-reserve",
          },
        ];
      } else if (this.familyArticle && this.familyArticle.id == 3) {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: true,
            width: 180,
          },
        ];
      } else if (this.familyArticle && this.familyArticle.id == 4) {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: true,
            width: 180,
          },
        ];
      } else if (this.familyArticle && this.familyArticle.id == 0) {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: true,
            width: 180,
          },
          {
            text: this.$t("articles.original_name"),
            align: "start",
            sortable: true,
            value: "original_name",
          },
        ];
      } else {
        this.customHeader = [
          {
            text: this.$t("articles.id"),
            value: "id",
            align: "start",
            sortable: false,
            width: 5,
          },
          {
            text: this.$t("articles.ean"),
            value: "identifier",
            align: "start",
            sortable: true,
            width: 140,
          },
        ];
      }

      this.renderedFilters = [];
      currentFamilyArticle.properties.filter((property) => {
        if (property.in_grid == 1) {
          this.customHeader.push({
            text: this.$t("articles.properties." + property.name),
            value:
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id),
            align: "start",
            sortable: true,
          });
          return true;
        }
      });
      this.modifyOrderingOfCustomHeaders();
    },
    /**
     * This function is used to modify the ordering of Headers in Listing
     * Note : For ordering you must know the column name
     */
    modifyOrderingOfCustomHeaders() {
      let sortedArray = [];
      let nonSortedArray = [];
      let sortingArray = [];
      if (
        this.familyArticle &&
        (this.familyArticle.parent_family_id == 1 ||
          this.familyArticle.parent_family_id == 2)
      ) {
        sortingArray = [
          this.$t("articles.id"),
          this.$t("articles.image"),
          this.$t("articles.properties.type"),
          this.$t("articles.properties.season"),
          this.$t("articles.properties.name"),
          this.$t("articles.ean"),
          this.$t("articles.properties.width"),
          this.$t("articles.properties.height"),
          this.$t("articles.properties.rim"),
          this.$t("articles.properties.li"),
          this.$t("articles.properties.si"),
          this.$t("articles.tariff_price"),
        ];
      } else {
        sortingArray = [
          this.$t("articles.id"),
          this.$t("articles.image"),
          this.$t("articles.ean"),
          this.$t("articles.properties.name"),
        ];
      }

      let processedArray = [];
      sortingArray.forEach((element, indexNo) => {
        for (const [key, value] of Object.entries(this.customHeader)) {
          let added = false;
          console.log(key);
          if (element == value.text) {
            sortedArray.push(value);
            processedArray.push(value.text);
            added = true;
          }
          if (
            added == false &&
            processedArray.find((element) => element == value.text) ==
              undefined &&
            sortingArray.length == indexNo + 1
          ) {
            nonSortedArray.push(value);
          }
        }
      });

      this.customHeader = sortedArray.concat(nonSortedArray);
    },
    setDynamicFilterBoxes() {
      let currentFamilyArticle = this.familyArticles.find((element) => {
        if (this.familyArticle && element.id == this.familyArticle.id)
          return element;
      });
      if (!currentFamilyArticle) {
        currentFamilyArticle = this.getEverythingFamilyArticle();
      }

      this.customFilters = [];
      this.multiselects = [];
      currentFamilyArticle.properties.filter((property) => {
        if (property.in_filter == 1) {
          let indexOfClass = (property.mapping ?? "").indexOf("class=");
          let mappingKey = null;
          if (indexOfClass != -1) {
            mappingKey = property.mapping.substr(indexOfClass + 6, 4) + "";
            let propertyName =
              "property_" +
              (property.property_id < 10
                ? 0 + property.property_id.toString()
                : property.property_id);

            if (this.familyArticle.properties) {
              this.familyArticle.properties[propertyName] = parseInt(
                this.familyArticle.properties[propertyName]
              );
            }
          }
          if (mappingKey) {
            this.multiselects.push(mappingKey);
          }
          if (property.cast != "boolean") {
            this.customFilters.push({
              text: this.$t("articles.properties." + property.name),
              align: "start",
              sortable: true,
              property_name:
                "property_" +
                (property.property_id < 10
                  ? 0 + property.property_id.toString()
                  : property.property_id),
              property_id: property.property_id,
              column_name: property.name,
              value: null,
              property_type: property.cast,
              mapping: property.mapping ?? null,
              mapping_key: mappingKey ?? null,
              keytype: property.cast,
              search_length: property.search_length,
            });
          }
          return true;
        }
        return false;
      });
      if (this.isFirstTimeLoaded == 0) {
        // this.applyPreviousFilters();
        this.isFirstTimeLoaded++;
      }
      this.changingArticlePropertiesInProgress = false;
    },
    getQueryStringFromDynamicallyCreatedFilters() {
      let queryString = "";
      let appliedFilters = [];
      this.customFilters.forEach((filter) => {
        if (filter.value) {
          queryString += "&" + filter.property_name + "=" + filter.value;
          appliedFilters.push({
            [filter.property_name]: filter.value,
          });
        }
      });
      this.booleanSelectedValues.forEach((filter) => {
        queryString += "&" + filter.property_name + "=" + 1; // 1 means true
        appliedFilters.push({
          [filter.property_name]: 1, // 1 means true
        });
      });
      return [queryString, appliedFilters];
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    getBrands() {
      if (this.familyArticle && this.familyArticle.id) {
        if (
          this.cachedData["brands"][this.familyArticle.id] &&
          this.cachedData["brands"][this.familyArticle.id].length > 0
        ) {
          this.brands = this.cachedData["brands"][this.familyArticle.id];
        } else {
          axios
            .get(
              API_BASE_URL +
                "/brands?family=" +
                this.familyArticle.id +
                "&parent_also=true&items_per_page=-1",
              { headers: this.header }
            )
            .then(({ data }) => {
              this.brands = data.data;
              this.cachedData["brands"][this.familyArticle.id] = this.brands;
            })
            .catch(function (error) {
              console.log("an error occured " + error);
            })
            .finally(() => {});
        }
      }
    },
  },
  computed: {},
  watch: {
    dialog() {
      this.applyPreviousFilters();
    },
    parentSearchSelectedArticleId(val) {
      if (val) {
        this.$emit("update:compSelectedArticleId", val);
      }
    },
    compSelectedArticleId(val) {
      this.parentSearchSelectedArticleId = val;
      if (!val) {
        this.parentSearchSelectedArticleId = null;
      }
    },
    familyArticle: {
      handler: function () {
        this.changingArticlePropertiesInProgress = true;

        this.brand = null;
        this.brands = [];

        if (
          this.familyArticle &&
          this.familyArticle.parent_family_properties.length > 0
        ) {
          this.familyArticle.properties =
            this.familyArticle.parent_family_properties;
        }
        if (this.loadingFirstTime != 0) {
          this.fetchData();
        }
        if (this.familyArticle) {
          if (
            this.familyArticle &&
            (this.familyArticle.parent_family_id == 1 ||
              this.familyArticle.parent_family_id == 2)
          ) {
            this.season = this.seasons[0].value;
          }

          this.changeArticleProperties();
          this.setCustomDataTableHeaders();
          this.setDynamicFilterBoxes();

          if (this.familyArticle == 1) {
            this.$nextTick(() => (this.season = 0));
          }
        } else {
          this.fetchData();

          // this.updateLocalStorageValues();
        }

        let toRefetchData = true;
        this.multiselects.forEach((multiSelectElement) => {
          this.multiselectsValues.forEach((multiSelectData) => {
            if (
              multiSelectData.length > 0 &&
              multiSelectData[0].class_id == multiSelectElement
            ) {
              toRefetchData = false;
            }
          });
        });
        if (toRefetchData) {
          this.getMultiSelectValuesFromCode();
        }

        this.getBrands();
      },
    },
    search: {
      handler: function () {
        _.debounce(function () {
          this.fetchData();
        }, 500);
      },
    },
    brand: {
      handler: function () {
        this.fetchData();
      },
    },
    season: {
      handler: function () {
        if (this.loadingFirstTime != 0) {
          this.fetchData();
        }
        this.loadingFirstTime++;
      },
    },
    status: {
      handler: function () {
        this.fetchData();
      },
    },
    approval: {
      handler: function () {
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped>
.to-upper input {
  text-transform: uppercase !important;
}
.articleModelOpened {
  display: flex !important;
}
.articleModelNotOpened {
  display: none !important;
}
</style>
