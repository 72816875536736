<template>
  <v-dialog
    persistent
    v-model="internalShowDialog"
    @click:outside="cancelDialog('cancel')"
    @keydown.esc="cancelDialog('cancel')"
    max-width="900"
  >
    <v-card>
      <v-card-title class="headline">
        <template v-if="addMember">
          <template v-if="defaultClient && typeof defaultClient == 'object'">
            {{ $t("vehicles.clients_model.edit_member") }}
          </template>
          <template v-else>
            {{ $t("vehicles.clients_model.add_member") }}
          </template>
        </template>
        <template v-else>
          {{ $t("vehicles.clients_model.add_client") }}
        </template>
      </v-card-title>
      <v-card-text>
        <v-form class="main_form" id="client-form">
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                v-if="addMember && !isClientCompanySelected"
                :sm="isClientCompanySelected ? '8' : '2'"
              >
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.memberType"
                  :items="memberTypes"
                  item-value="id"
                  item-text="text"
                  return-object
                  menu-props="auto"
                  :label="$t('vehicles.clients_model.member_type')"
                  :error-messages="
                    $store.state.validation && !$v.form.memberType.required
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.member_type_required'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                :sm="isClientCompanySelected ? '3' : '2'"
                v-if="addMember"
              >
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.department"
                  name="department"
                  class="to-upper"
                  ref="department"
                  v-on:keydown="handleInput($event, 'justalphanumeric', 50)"
                  :label="$t('vehicles.clients_model.department')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" v-if="!addMember">
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.entityType"
                  :items="entityTypeSelectItems"
                  :disabled="addMember"
                  item-text="text"
                  class="to-upper"
                  item-value="id"
                  return-object
                  :label="$t('vehicles.clients_model.entity_type')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
              <v-col cols="12" :sm="isClientCompanySelected ? '8' : '4'">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.name"
                  name="name"
                  class="to-upper"
                  ref="client_name"
                  v-on:keydown="handleInput($event, 'everything', 50)"
                  :label="$t('vehicles.clients_model.client_name')"
                  :error-messages="
                    $store.state.validation && !$v.form.name.required
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.name_required'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="!isClientCompanySelected"
                :sm="isClientCompanySelected ? '8' : addMember ? '4' : '5'"
              >
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.first_name"
                  name="name"
                  class="to-upper"
                  ref="client_name"
                  v-on:keydown="handleInput($event, 'everything', 50)"
                  :label="$t('vehicles.clients_model.client_first_name')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" v-if="isClientCompanySelected">
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.denomination"
                  :items="denominations"
                  item-value="id"
                  class="to-upper"
                  item-text="text"
                  :label="$t('vehicles.clients_model.denomination')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.email"
                  class="to-upper"
                  v-on:keydown="handleInput($event, 'email', 60)"
                  :label="$t('vehicles.clients_model.email')"
                  :error-messages="
                    $store.state.validation && !$v.form.email.email
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.invalid_email'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex">
                <vue-tel-input-vuetify
                  :countryToBeSelected="countryToBeSelectedByDefault"
                  :disabledFetchingCountry="true"
                  :preferredCountries="preferredCountry"
                  :filterCountries="onlyCountries"
                  validCharactersOnly
                  :label="$t('vehicles.clients_model.gsm')"
                  :inputOptions="{ showDialCode: true, tabindex: 0 }"
                  :mode="'international'"
                  v-model="form.gsm"
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col class="d-flex">
                <vue-tel-input-vuetify
                  :countryToBeSelected="countryToBeSelectedByDefault"
                  :disabledFetchingCountry="true"
                  :preferredCountries="preferredCountry"
                  :filterCountries="onlyCountries"
                  validCharactersOnly
                  :label="$t('vehicles.clients_model.phone')"
                  :inputOptions="{ showDialCode: true, tabindex: 0 }"
                  :mode="'international'"
                  v-model="form.phone"
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col class="d-flex" style="max-width: 140px" v-if="addMember">
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.language"
                  :items="languages"
                  item-value="id"
                  item-text="text"
                  :label="$t('vehicles.clients_model.languages')"
                  :error-messages="
                    $store.state.validation && !$v.form.language.required
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.language_required'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="addMember && checkTag('webshop')">
              <v-col>
                <v-text-field
                  v-model="form.password"
                  :label="$t('login.webshop_password')"
                  :type="'password'"
                  v-on:keydown.enter.prevent=""
                ></v-text-field>
              </v-col>
              <!-- <v-col>
                            <v-autocomplete
                                role="presentation"
                                autocomplete="off"
                                class="to-upper"
                                flat
                                :items="clientStatuses"
                                item-text="key"
                                item-value="id"
                                v-model="form.status"
                                :label="$t('vehicles.clients_model.status_id')"
                                hide-details="true"
                                :append-icon="''"
                            ></v-autocomplete>
                        </v-col> -->
              <v-col>
                <v-autocomplete
                  role="presentation"
                  autocomplete="off"
                  class="to-upper"
                  flat
                  :items="activeStatuses"
                  item-text="key"
                  item-value="id"
                  v-model="form.is_active"
                  :label="$t('vehicles.clients_model.active_id')"
                  hide-details="true"
                  :append-icon="''"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                v-if="!addMember"
                :sm="isClientCompanySelected ? '3' : '4'"
              >
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.familyClient"
                  :items="familyClients"
                  item-value="id"
                  item-text="name"
                  menu-props="auto"
                  :label="$t('vehicles.clients_model.family_client')"
                  :error-messages="
                    $store.state.validation && !$v.form.familyClient.required
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.family_client_required'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                v-if="!addMember"
                :sm="isClientCompanySelected ? '3' : '4'"
              >
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.currency"
                  :items="currencies"
                  item-value="id"
                  item-text="text"
                  :label="$t('vehicles.clients_model.currency')"
                  :error-messages="
                    $store.state.validation && !$v.form.currency.required
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.currency_required'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
              <v-col
                v-if="!addMember"
                cols="12"
                :sm="isClientCompanySelected ? '3' : addMember ? '12' : '4'"
              >
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.language"
                  :items="languages"
                  item-value="id"
                  item-text="text"
                  :label="$t('vehicles.clients_model.languages')"
                  :error-messages="
                    $store.state.validation && !$v.form.language.required
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.language_required'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" v-if="isClientCompanySelected">
                <TvaInput
                  v-model="form.tva"
                  @input="setCountryToBeSelected(form.tva)"
                  :label="$t('vehicles.clients_model.tva')"
                ></TvaInput>
                <!-- <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.tva"
                                @change="resetVariables(); verifyTVA(form.tva);"
                                @input="setCountryToBeSelected(form.tva)"
                                ref="driver_tva"
                                class="to-upper"
                                required
                                v-on:keydown="handleInput($event,'justalphanumeric',45)"
                                :label="$t('vehicles.clients_model.tva')"
                                :error-messages="$store.state.validation && !$v.form.tva.required ? [this.$t('vehicles.clients_model.text_messages.tva_required')] : [] "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            >
                                <template v-slot:prepend-inner>
                                    <template v-if="isVerifiedTVALoading">
                                    <v-progress-circular
                                        indeterminate
                                        size="20"
                                        width="2"
                                        color="primary"
                                    ></v-progress-circular>
                                    </template>
                                    <template v-else>
                                    <v-icon :color="isVerifiedTVA == false ? 'red' : 'green'">
                                        {{ isVerifiedTVA ? 'mdi-checkbox-marked-circle' : (isVerifiedTVA == false ? 'mdi-close-circle' : '') }}
                                    </v-icon>
                                    </template>
                                </template>
                            </v-text-field> -->
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            addMember &&
            addMemberToCompany &&
            defaultClient &&
            typeof defaultClient == 'object' &&
            defaultClient.id
          "
          id="top-delete"
          class="mr-1 text-white-color"
          color="error"
          @click="deleteMember()"
        >
          {{ $t("nav_buttons.delete") }}
          <v-icon dark right> mdi-delete </v-icon>
        </v-btn>
        <v-btn color="grey lighten-2" @click="cancelDialog('cancel')">
          {{ $t("vehicles.Cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn
          color="success"
          @click="saveData()"
          :disabled="$store.state.isLoading"
        >
          {{ $t("vehicles.Save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { validationMixin } from "vuelidate";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import VueTelInputVuetify from "@/components/PhoneNumberInput";
import TvaInput from "@/components/TvaInput.vue";

export default {
  mixins: [validationMixin],
  name: "EditClientModel",
  components: {
    "vue-tel-input-vuetify": VueTelInputVuetify,
    TvaInput,
  },
  props: {
    saveToDB: {
      type: Boolean,
      default: true,
    },
    showDialog: [Number, Boolean],
    defaultClient: [Object],
    addMember: {
      // If Add Member is False then We will add Client if it is true we will add Member
      type: Boolean,
      default: false,
    },
    addMemberToCompany: {
      // Add Member to Company Means the Client To Which Member is to be added
      type: Object,
      default: null,
    },
    defaultDataForAdd: {
      // If New Data to be added then update the objects
      type: Object,
      default: null,
    },
    expeditor_tva: {
      type: String,
      default: null,
    },
  },
  validations: {
    form: {
      name: {
        required: requiredIf(function () {
          return !this.form.name || !this.form.name.length;
        }),
      },
      email: {
        email: (val) => email((val ?? "").toLowerCase()),
      },
      tva: {
        required: requiredIf(function () {
          return this.isClientCompanySelected;
        }),
      },
      currency: {
        required: requiredIf(function () {
          return !this.addMember;
        }),
      },
      language: {
        required,
      },
      familyClient: {
        required: requiredIf(function () {
          return this.addMember;
        }),
      },
      memberType: {
        required: requiredIf(function () {
          return this.addMember;
        }),
      },
    },
  },
  data() {
    const defaultForm = Object.freeze({
      entityType: { id: 1 },
      id: null,
      company: {},
      language: localStorage.getItem("languageId") || "en",
      department: null,
      name: null,
      first_name: null,
      phone: null,
      gsm: null,
      fax: null,
      email: null,
      web: null,
      currency: this.defaultCurrencyId,
      representative_company_id: null,
      password: null,
      user: null,
      note: null,
      status: 10041001,
      is_active: 1,
      familyClient: null,
      created_at: null,
      updated_at: null,
      denomination: 10061001,
      registration_code: null,
      tva: null,
      memberType: null,
    });
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      entityTypes: [
        { id: 0, value: "Private" },
        { id: 1, value: "Company" },
      ],
      internalShowDialog: this.showDialog,
      autocompleteValueLocal: "off",
      client: {},
      DeleteConfirm: false,
      form: Object.assign({}, defaultForm),
      denominations: [],
      clientStatuses: [],
      currencies: [],
      memberTypes: [],
      languages: [],
      operation: "edit",
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      defaultClientNewData: null,
      countryToBeSelectedByDefault: null,
    };
  },
  async beforeMount() {
    await this.getMemberTypes();
  },
  mounted() {
    this.$store.commit("setValidation", false); // enable validation
    this.autocompleteValueLocal = this.autocompleteValue();
    if (this.addMember) {
      this.form.entityType = this.entityTypeSelectItems[0]; // Select Private
      if (this.addMemberToCompany) {
        this.form.familyClient = this.addMemberToCompany.familyClient;
        this.form.currency = this.addMemberToCompany.currency;
      }
    }
    this.getCompanyDenominations();
    this.getStatuses();
    this.getCurrencies();
    this.getLanguages();
    this.setDefaultFamilyClient();
  },
  methods: {
    setCountryToBeSelected(val) {
      let country = null;
      if (val) {
        country = this.getAlphabets(val);
      }
      this.countryToBeSelectedByDefault = country
        ? (country ?? "").toUpperCase()
        : this.preferredCountry[0];
    },
    setDefaultFamilyClient() {
      this.familyClients.forEach((element) => {
        if (element.is_default == 1) {
          this.form.familyClient = element;
        }
      });
    },
    async getMemberTypes() {
      await this.getCodes("member_types", "member_types").then((data) => {
        this.memberTypes = data.filter((element) => {
          return element.key != "not_specified";
        });
        // this.form.memberType = this.memberTypes[0];
        this.$nextTick(() => {
          if (!this.form.memberType) {
            this.form.memberType = this.memberTypes[0];
          }
        });
      });
    },
    async getCompanyDenominations() {
      await this.getCodes("company_denomination", "company_denomination").then(
        (data) => {
          this.denominations = data;
        }
      );
    },
    getCurrencies() {
      this.getDataWithCache("/currencies", "currencies", "currencies", true);
    },
    async getStatuses() {
      await this.getCodes("client_statuses", "client_statuses").then((data) => {
        this.clientStatuses = data.map((item) => {
          item.key = /^[A-Z]/.test(item.key)
            ? item.key
            : this.$t("clients." + item.key);
          return item;
        });
      });
    },
    getLanguages() {
      this.getDataWithCache("/languages", "languages", "languages", true);
    },
    addClient(data) {
      axios
        .post(API_BASE_URL + "/clients", data, { headers: this.header })
        .then((data) => {
          this.$toast.success(
            this.$t(
              "vehicles.clients_model.text_messages.client_added_successfully"
            )
          );
          this.cancelDialog("added", data.data.data);
        })
        .catch(() => {})
        .finally(() => {});
    },
    editClient(data) {
      axios
        .put(API_BASE_URL + "/clients/" + this.defaultClient.id, data, {
          headers: this.header,
        })
        .then((data) => {
          this.$toast.success(
            this.$t(
              "vehicles.clients_model.text_messages.client_added_successfully"
            )
          );
          this.cancelDialog("added", data.data.data);
        })
        .catch(() => {})
        .finally(() => {});
    },
    getClient(data) {
      axios
        .get(API_BASE_URL + "/clients/" + data.id, { headers: this.header })
        .then((response) => {
          this.defaultClientNewData = response.data.data;
          this.setValues(response.data.data);
        })
        .catch(() => {})
        .finally(() => {});
    },
    deleteMember() {
      axios
        .delete(API_BASE_URL + "/clients/" + this.defaultClient.id, {
          headers: this.header,
        })
        .then((data) => {
          this.$toast.success(
            this.$t("clients.text_messages.member_deleted_successfully")
          );
          // this.$router.push("/clients").catch(() => {});
          this.cancelDialog("added", data);
        })
        .catch((error) => {
          this.$toast.error(
            this.$t("clients.text_messages." + error.response.data.errors[0])
          );
        })
        .finally(() => {});
    },
    setValues(val) {
      let isCompany = !!val.representative_company_id;
      this.form.id = val.id;
      this.form.name = val.name;
      this.form.first_name = val.first_name;
      this.form.department = val.department;
      // this.form.password = val.password; Do not set password as it will update the values
      if (this.addMember) {
        this.form.entityType = this.entityTypeSelectItems[0];
      } else {
        this.form.entityType = isCompany
          ? this.entityTypeSelectItems[1]
          : this.entityTypeSelectItems[0];
      }

      this.company_client_first_name = val.first_name;

      this.form.familyClient = val.family_client_id;
      this.form.email = val.email;
      this.form.currency = val.currency;
      this.$nextTick(() => {
        this.form.gsm = val.gsm;
        this.form.language = val.language;
        this.form.phone = val.phone;
        this.form.fax = val.fax;
        this.form.status =
          val.status_id ??
          (val.status && typeof val.status == "object" ? val.status.id : null);
        this.form.is_active = val.is_active;
      });
      this.form.representative_company_id = val.representative_company_id;
      this.form.memberType = val.type;

      if (val.company) {
        this.form.denomination = val.company.denomination_id;
        this.form.tva = val.company.tva;
        // this.setCountryToBeSelected(this.form.tva);
      }
    },
    cancelDialog(operationPerformed, data) {
      this.$emit("update:showDialog", false);
      this.$emit("update:addMemberToCompany", null);
      this.$emit("buttonClicked", operationPerformed == "added" ? data : false);
      this.internalShowDialog = false;
    },
    saveData() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
        return false;
      } else {
        if (this.saveToDB || this.form.id) {
          if (this.defaultClient && typeof this.defaultClient == "object") {
            this.editClient(this.makeSubmitData());
          } else {
            this.addClient(this.makeSubmitData());
          }
        } else {
          this.cancelDialog("added", this.makeSubmitData());
        }
      }
    },
    makeSubmitData() {
      return {
        id: this.form.id ?? null,
        name: this.form.name,
        first_name: this.form.first_name,
        department: this.form.department,
        password: this.form.password,
        family_client_id:
          typeof this.form.familyClient == "object"
            ? this.form.familyClient.id
            : this.form.familyClient,
        denomination_id:
          this.form.entityType.id > 0 ? this.form.denomination : 0,
        representative_company_id: this.addMember
          ? this.addMemberToCompany.representative_company_id
          : null,
        type_id: this.form.memberType
          ? typeof this.form.memberType == "object"
            ? this.form.memberType.id
            : this.form.memberType ?? null
          : null,
        status_id: this.form.status,
        is_active: this.form.is_active,
        currency_id:
          typeof this.form.currency == "object"
            ? this.form.currency.id
            : this.form.currency,
        email: this.form.email,
        phone: this.form.phone,
        gsm: this.form.gsm,
        fax: this.form.fax,
        tva: this.form.tva,
        language_id:
          typeof this.form.language == "object"
            ? this.form.language.id
            : this.form.language,
      };
    },
  },
  computed: {
    activeStatuses() {
      return [
        { id: 1, key: this.$t("vehicles.clients_model.active") },
        { id: 0, key: this.$t("vehicles.clients_model.in_active") },
      ];
    },
    isClientCompanySelected() {
      return this.form.entityType.id === 1;
    },
  },
  beforeDestroy() {
    this.$store.commit("setValidation", false);
  },
  watch: {
    expeditor_tva: {
      handler: function () {
        this.$nextTick(() => {
          this.setCountryToBeSelected(this.expeditor_tva);
        });
      },
      immediate: true,
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
      },
    },
    defaultClient: {
      handler: function (val) {
        if (val && typeof val == "object" && val.id) {
          this.getClient(val);
        }
        if (val && typeof val == "object" && !val.id) {
          this.form.id = val.id;
          (this.form.name = val.name),
            (this.form.first_name = val.first_name),
            (this.form.department = val.department),
            (this.form.password = val.password),
            (this.form.familyClient = val.family_client_id),
            (this.form.denomination = val.denomination_id),
            // this.form.representative_company_id = this.addMember ? this.addMemberToCompany.representative_company_id :  null,
            (this.form.status =
              val.status_id ??
              (val.status && typeof val.status == "object"
                ? val.status.id
                : null)),
            (this.form.is_active = val.is_active),
            (this.form.currency = val.currency_id);
          this.form.email = val.email;
          this.$nextTick(() => {
            this.form.memberType = this.getMemberTypeById(val.type_id);
            this.form.phone = val.phone;
            this.form.gsm = val.gsm;
            this.form.fax = val.fax;
          });
          this.form.tva = val.tva;
          this.form.language = val.language_id;
        }
        if (val && typeof val == "string") {
          this.form.name = val;
          if (this.addMember) {
            this.form.entityType = this.entityTypeSelectItems[0];
          } else {
            this.form.entityType = this.entityTypeSelectItems[1];
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
