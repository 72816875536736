<template>
  <div>
    <v-btn
      @click="showPyamentModelForSelectedExpenses"
      class="d-none"
      id="show-payment-model"
    ></v-btn>
    <v-btn
      @click="exportDataForAppliedFilters"
      class="d-none"
      id="export-data"
    ></v-btn>
    <v-btn @click="fetchData" class="d-none" id="refresh-now"></v-btn>
    <v-btn @click="fetchData(null, false, '', true)" class="d-none" id="refresh-auto"></v-btn>

    <v-btn
      @click="confirmPurchaseOrderPopUp()"
      class="d-none"
      id="make-purchase-order"
    ></v-btn>
    <v-btn
      @click="confirmMakeBillPopUp()"
      class="d-none"
      id="make-bill"
    ></v-btn>
    <v-btn
      @click="confirmCreditNotePopUp()"
      class="d-none"
      id="make-credit-note"
    ></v-btn>
    <v-btn
      @click="confirmSelectionExportPopUp()"
      class="d-none"
      id="export-selection"
    ></v-btn>

    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-row>
      <v-col class="d-flex pl-6 pa-2 ma-0" cols="12" sm="12">
        <!-- Financial Year -->
        <v-autocomplete
          v-model="financialYear"
          :items="years"
          item-value="year"
          @change="
            fetchData();
            updateLocalStorage();
          "
          item-text="year"
          menu-props="auto"
          :label="$t('financial_year')"
          :outlined="is_boxes"
          :dense="is_boxes"
          class="mr-2 to-upper"
          :style="{ maxWidth: '100px', width: '100px' }"
        ></v-autocomplete>

        <!-- Status -->
        <v-select
          flat
          :items="orderstatuses"
          v-model="status"
          :label="$t('billings.status')"
          class="mr-2 to-upper"
          item-value="id"
          item-text="text"
          @click:append="status = null"
          append-icon="mdi-close"
          return-object
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-no-data
          multiple
        ></v-select>

        <!-- Search -->
        <v-text-field
          class="mr-3 to-upper"
          :label="$t('billings.search')"
          prepend-icon="mdi-magnify"
          v-bind:clearable="true"
          v-model="search"
          @focus="search = null"
          @click:append="status = null"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>

        <!-- Identifier -->
        <v-text-field
          class="mr-3 to-upper"
          :label="$t('billings.identifiant')"
          v-bind:clearable="true"
          v-model="ftrByIdentifiant"
          :outlined="is_boxes"
          @click:append="ftrByIdentifiant = null"
          :dense="is_boxes"
        ></v-text-field>

        <!-- Client -->
        <v-autocomplete
          flat
          :items="clients"
          v-model="client"
          :label="$t('billings.client')"
          class="mr-2 to-upper"
          item-value="id"
          item-text="clientname"
          @click:append="client = null"
          append-icon="mdi-close"
          :search-input.sync="searchClient"
          return-object
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-no-data
        ></v-autocomplete>

        <!-- Payment Date -->
        <!-- <v-menu
          ref="menu3"
          v-model="menu3"
          :close-on-content-click="false"
          :nude-right="'400px'"
          transition="scale-transition"
          v-if="isPurchaseOrderOrBillOrCreditNote"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-2 to-upper"
              v-model="search_payment_date"
              :label="$t('billings.payed_at')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:append="search_payment_date = null"
              append-icon="mdi-close"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            :first-day-of-week="1"
            :weekday-format="getDay"
            v-model="search_payment_date"
            no-title
            scrollable
            :locale="userLanguageId"
            hide-no-data
            hide-details
            range
          ></v-date-picker>
        </v-menu> -->
        <DateInput
          v-model="search_payment_date"
          :label="$t('billings.payed_at')"
          :clearable="true"
          :range="true"
        />

        <!-- Created Date -->
        <!-- <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :nude-right="'400px'"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-2 to-upper"
              v-model="search_created_date"
              :label="$t('billings.createdAt')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:append="search_created_date = null"
              append-icon="mdi-close"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            :first-day-of-week="1"
            :weekday-format="getDay"
            v-model="search_created_date"
            no-title
            scrollable
            :locale="userLanguageId"
            hide-no-data
            hide-details
            range
          ></v-date-picker>
        </v-menu> -->
        <DateInput
          v-model="search_created_date"
          :label="$t('billings.createdAt')"
          :clearable="true"
          :range="true"
        />

        <!-- Due Date -->
        <!-- <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          nude-right="0px"
          transition="scale-transition"
          offset-y
          min-width="100px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-2 to-upper"
              v-model="search_due_date"
              :label="$t('expenses.due_date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:append="search_due_date = null"
              append-icon="mdi-close"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            :first-day-of-week="1"
            :weekday-format="getDay"
            v-model="search_due_date"
            no-title
            scrollable
            :locale="userLanguageId"
            hide-no-data
            hide-details
            range
          ></v-date-picker>
        </v-menu> -->
        <DateInput
          v-model="search_due_date"
          :label="$t('billings.due_date')"
          :clearable="true"
          :range="true"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-data-table
        fixed-header
        :footer-props="{
          'items-per-page-options': dataTableOptions,
        }"
        :headers="headers"
        :items="billings"
        :options="options"
        :server-items-length="totalItems"
        :hide-default-header="true"
        :loading-text="this.$t('loading')"
        class="elevation-0"
        :items-per-page="itemsPerPage"
        item-key="identifier"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        style="width: 100%"
        :height="'calc(-230px + 100vh)'"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <template v-for="header in headers">
                <th
                  :style="getHeaderStyle(header)"
                  :key="header.value"
                  scope="col"
                  class="text-start pr-0 column"
                  v-bind:class="[
                    header.sortable ? 'sortable' : '',
                    sortBy == header.value ? 'active' : '',
                    sortOrd ? 'ASC' : 'DESC',
                  ]"
                  @click="header.sortable ? changeSort(header.value) : ''"
                >
                  <template v-if="header.value == 'checkbox'">
                    <v-row justify="space-between">
                      <v-checkbox
                        class="mt-1 ma-0 pa-0 ml-n3"
                        hide-details="auto"
                        v-model="isAllSelected"
                      ></v-checkbox>
                    </v-row>
                  </template>
                  <template v-else>
                    {{ header.text }}
                    <v-icon
                      v-if="header.sortable"
                      class="v-data-table-header__icon"
                      small
                    >
                      <template v-if="sortOrd"> mdi-arrow-up </template>
                      <template v-else> mdi-arrow-down </template>
                    </v-icon>
                  </template>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, index }">
          <tr :class="setRowColorClass(item, index)" :key="item.id">
            <!-- Billing Row Number -->
            <td :class="index == 0 && showGridTotal ? 'grey-background' : ''">
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                <v-row class="ma-0" style="text-align: center">
                  {{ getRowIndex(index) }}
                </v-row>
              </template>
              <template v-else> </template>
            </td>
            <!-- Billing Select For Payment -->
            <td
              v-if="isPurchaseOrderOrBillOrCreditNote"
              class="pr-0 pl-0"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                <div>
                  <template
                    v-if="
                      item &&
                      item.status &&
                      (item.status.value == 'waiting' ||
                        item.status.value == 'to_be_billed')
                    "
                  >
                    <v-row class="">
                      <v-simple-checkbox
                        @click="makeSelection"
                        class="pl-1"
                        v-model="item.is_selected"
                        color="primary"
                        :ripple="false"
                        style="width: 10px"
                      ></v-simple-checkbox>
                    </v-row>
                  </template>
                </div>
              </template>
              <template v-else> </template>
            </td>
            <!-- PDF -->
            <td
              @click="openDocument(item)"
              class="pl-0 pr-0"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                <v-icon color="orange" right> mdi-file-document</v-icon>
              </template>
              <template v-else> </template>
            </td>
            <!-- Identifier -->
            <td
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                <v-tooltip right z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <div color="warning" v-bind="attrs" v-on="on">
                      <!-- Only show in red if bill is converted to credit note -->
                      <span
                        :class="
                          item.child_order_id && item.type_id == 10241002
                            ? 'text-red'
                            : ''
                        "
                      >
                        {{ item.bill_number }}
                      </span>
                    </div>
                  </template>
                  <v-list-item
                    class="pa-0 ma-0"
                    v-for="(articles, index) in item.details"
                    :key="index"
                  >
                    <template
                      v-if="
                        articles.article &&
                        articles.article.descriptions.length > 0
                      "
                    >
                      {{ articles.quantity }} X
                      {{ articles.article.descriptions[0].description }}
                    </template>
                    <template v-else>
                      <template v-if="articles && articles.text">
                        {{ articles.quantity }} X {{ articles.text }}
                      </template>
                    </template>
                  </v-list-item>
                </v-tooltip>
              </template>
              <template v-else> </template>
            </td>
            <!-- Reference -->
            <!-- <td @click="editItem(item, index)">
                {{ (item.reference ? item.reference : '').toUpperCase() }}
            </td> -->
            <!-- Client Name -->
            <td
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                <span v-if="item.client" class="client_text">
                  <span
                    v-if="item.client && item.client.representative_company_id"
                  >
                    <v-icon class="client_icon">mdi-domain</v-icon>
                  </span>
                  <span v-else>
                    <v-icon class="client_icon">mdi-account</v-icon>
                  </span>
                  {{ item.client.name }}
                  {{ item.client.first_name ? item.client.first_name : "" }}
                </span>
              </template>
              <template v-else> </template>
            </td>
            <!-- Total THTVAWithout VAT -->
            <td
              align="right"
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                {{ showFormattedNumber(item.total_without_vat) }}
              </template>
              <template v-else>
                <template v-if="isLoadingTotal">
                  <v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ showFormattedNumber(sum_of_total_without_vat) }}
                </template>
              </template>
            </td>
            <!-- Total TVA -->
            <td
              align="right"
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                {{ showFormattedNumber(item.vat_total) }}
              </template>
              <template v-else>
                <template v-if="isLoadingTotal">
                  <v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ showFormattedNumber(sum_of_vat_total) }}
                </template>
              </template>
            </td>
            <!-- Total -->
            <td
              align="right"
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                {{ showFormattedNumber(item.total) }}
              </template>
              <template v-else>
                <template v-if="isLoadingTotal">
                  <v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ showFormattedNumber(sum_of_total) }}
                </template>
              </template>
            </td>
            <!-- Total To Be Payed -->
            <td
              align="right"
              v-if="isPurchaseOrderOrBillOrCreditNote"
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                {{ showFormattedNumber(getTotalUnpaid(item.payments, item)) }}
              </template>
              <template v-else>
                <template v-if="isLoadingTotal">
                  <v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ showFormattedNumber(sum_of_to_be_payed) }}
                </template>
              </template>
            </td>
            <!-- Payed At -->
            <td
              align="center"
              v-if="isPurchaseOrderOrBillOrCreditNote"
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                <span v-if="item.payments && item.payments.length > 0">
                  {{ formatDate(item.payments[0].executed_at.substr(0, 10)) }}
                </span>
              </template>
              <template v-else> </template>
            </td>
            <!-- Payed by -->
            <td
              align="center"
              v-if="isPurchaseOrderOrBillOrCreditNote"
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                <span v-if="item.payments">{{
                  getPaymentPayedMethods(item.payments)
                }}</span>
              </template>
              <template v-else> </template>
            </td>
            <!-- Peppol -->
            <td
              @click="editItem(item, index)"
              v-if="isPurchaseOrderOrBillOrCreditNote && companyIsPeppol"
              :style="{ textAlign: 'center' }"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                {{ determineBillPeppolStatus(item).message }}
              </template>
              <template v-else> </template>
            </td>
            <!-- Creation date -->
            <td
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                {{
                  item.created_date
                    ? formatDateTime(item.created_date)
                    : item.created_date
                }}
              </template>
              <template v-else> </template>
            </td>
            <!-- Due Date -->
            <td
              @click="editItem(item, index)"
              v-if="isPurchaseOrderOrBillOrCreditNote"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                {{
                  item.due_date
                    ? formatDate(item.due_date.substr(0, 10))
                    : item.due_date
                }}
              </template>
              <template v-else> </template>
            </td>
            <!-- Status -->
            <td
              @click="editItem(item, index)"
              :class="index == 0 && showGridTotal ? 'grey-background' : ''"
            >
              <template
                v-if="
                  (showGridTotal && index != 0) ||
                  (!showGridTotal && index >= 0)
                "
              >
                <v-progress-linear
                  :color="
                    getColorOnTheBasisOfStatus(
                      item,
                      item.total,
                      getTotalPaid(item.payments).toFixed(getDecimalNumber()),
                      item.status.value
                    )
                  "
                  height="20"
                  :value="
                    item.status &&
                    item.status.key &&
                    item.status.key.includes('waiting')
                      ? 65
                      : 100
                  "
                  :striped="
                    !checkIfItemIsPurchaseOrderOrBillOrCreditNote(item.type.key)
                      ? checkIfItemIsClosedOrCancelled(item.status.key)
                      : false
                  "
                >
                  <v-row>
                    <v-col cols="12" sm="8" class="pr-0">
                      <span class="pl-1 text-white">
                        {{
                          getPayedStatus(
                            item.total,
                            getTotalPaid(item.payments).toFixed(
                              getDecimalNumber()
                            ),
                            item.status.value
                          )
                        }}
                      </span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      class="pl-0 pr-0"
                      v-if="
                        item.status &&
                        item.status.key &&
                        item.status.key.includes('waiting')
                      "
                    >
                      <span>
                        {{ calculateNoOfDaysLeftInDueDate(item.due_date)
                        }}{{ $t("no_of_days_unit") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-progress-linear>
              </template>
              <template v-else> </template>
            </td>
          </tr>
        </template>

        <template v-slot:footer>
          <div style="position: absolute" class="pa-0 pl-2 pt-7">
            <strong class="totalCalculation" v-if="totalSelected > 0"
              >{{
                $t("billings.total_in_listing", {
                  total_selected: totalSelectedCount,
                  currency_symbol: currencySymbol,
                  total: totalSelected,
                })
              }}
            </strong>
            <!-- <strong class="totalCalculation" v-if="totalSelected > 0"> <br>
                    <template v-if="totalSelectedItems.length > 130">
                        <v-tooltip top z-index="9999" >
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ totalSelectedItems.substring(0, 130) }} ... 
                                </span>
                            </template>
                            {{ totalSelectedItems }}
                        </v-tooltip>
                    </template>
                    <template v-else>
                        {{ totalSelectedItems }}
                    </template>
                </strong> -->
          </div>
        </template>
      </v-data-table>
    </v-row>

    <BulkPayment
      v-if="showBulKPaymentModel"
      :showDialog.sync="showBulKPaymentModel"
      :paymentItems="selectedItemsForBulkPayment"
      :module="'billing'"
      @refreshList="refetchData"
    ></BulkPayment>
    <span id="zipped-reports" @click="launchZippedReportModal">
      <zipped-reports
        target="orders"
        ref="zippedReportComp"
        v-model="showZippedReports"
        :selected-status.sync="status"
        :selected-client="client"
        :selectedArticles="getSelectedArticles()"
      ></zipped-reports>
    </span>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { BILLING_LISTING_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import peppolMixin from "@/mixins/peppolMixin";
import BulkPayment from "@/components/BulkPayment.vue";
import ConfirmationModel from "@/components/ConfirmationModel";
import moment from "moment";
import ZippedReports from "@/components/ZippedReports";
import zippedReportsMixin from "@/mixins/zippedReportsMixin";
import bulkConvertMixin from "@/mixins/bulkConvertMixin";
import DateInput from "@/components/DateInput.vue";

export default {
  name: "BillingListing",
  mixins: [peppolMixin, zippedReportsMixin, bulkConvertMixin],
  components: {
    BulkPayment,
    ConfirmationModel,
    ZippedReports,
    DateInput,
  },
  data() {
    return {
      financialYear: null,
      isLoadingTotal: false,
      showBulKPaymentModel: false,
      isAllSelected: false,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      isLoading: true,
      totalItems: 0,
      lastPage: 1,
      page: 1,
      sortBy: "id",
      sortDesc: true,
      sortOrd: false,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      billings: [],
      billType: null,
      search: null,
      ftrByIdentifiant: "",
      ftrByCompany: "",
      date: null,
      menu_date: false,
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token: this.$store.state.AccessToken,
      header: {},
      companies: [],
      company: "",
      clients: [],
      client: "",
      nextEvents: [],
      nextEvent: "",
      allStatuses: [],
      orderstatuses: [],
      status: "",
      menu1: false,
      menu2: false,
      menu3: false,
      search_created_date: null,
      search_payment_date: null,
      search_due_date: null,
      isLoadingSearchClientInput: false,
      isPageLoaded: false,
      searchClient: null,
      stopSearch: true,

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,

      sum_of_total_without_vat: 0,
      sum_of_vat_total: 0,
      sum_of_total: 0,
      sum_of_to_be_payed: 0,
      orderDetails: {},
      billTableKey: 1,
    };
  },
  mounted() {
    this.$store.state.apiAbortControllerMap = {};
    // this.search = '';
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setFormMode", BILLING_LISTING_MODE);
    this.$store.commit("setAddButtonRoute", "AddClient");
    this.financialYear = this.years[0]?.year;

    this.getBillTypes()
      .then(() => {
        this.getStatuses()
          .then(() => {
            // this.billType = this.billTypes.find(bill => bill.key == "bill");
          })
          .then(() => {
            this.applyPreviousFilters();
          })
          .then(() => {
            this.setDefaultStatusAndSearch();
          })
          .then(() => {
            this.$nextTick(() => {
              this.isPageLoaded = true;
              this.stopSearch = false;
              this.fetchData(this.page);
            });
          });
      })
      .finally(() => {});
  },
  methods: {
    changePage(newPage) {
      this.options = { ...this.options, page: newPage }; // Ensure reactivity
    },
    zipSelectedOnes() {
      this.$refs["zippedReportComp"].exportAttachmentHandler(true);
    },
    clearSelectionData() {
      this.isAllSelected = false;
      this.billings.forEach((element) => {
        element.is_selected = false;
      });
      this.clearAllSelection();
    },
    confirmMakeBillPopUp() {
      //   if (this.checkIfForCurrentOrderSupplierOrderNotReceived()) {
      //     this.$nextTick(() => {
      //       this.confirmationDialogConfirmationText = this.$t(
      //           "billings.convert_to_bill_confirmation_and_supplier_not_received"
      //       );
      //       this.confirmationDialogFalseText = this.$t("no");
      //       this.confirmationDialogTrueText = this.$t("yes");
      //       this.confirmationDialogOperation = "make_bill";
      //       this.confirmationDialog = true;
      //     });
      //   } else {
      {
        this.$nextTick(() => {
          this.confirmationDialogConfirmationText = this.$t(
            "billings.convert_to_bill_confirmation"
          );
          this.confirmationDialogFalseText = this.$t("no");
          this.confirmationDialogTrueText = this.$t("yes");
          this.confirmationDialogOperation = "make_bill";
          this.confirmationDialog = true;
        });
      }
    },
    confirmPurchaseOrderPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "billings.convert_to_purchase_order_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "make_purchase_order";
      this.confirmationDialog = true;
    },
    confirmCreditNotePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "billings.convert_to_credit_note_confirmation"
      );
      this.confirmationDialogReCheckBoxConfirmationText = this.$t(
        "billings.convert_to_credit_note_confirmation_reconfirm_text"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "make_credit_note";
      this.confirmationDialog = true;
    },
    confirmSelectionExportPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "billings.confirm_selection_export"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "make_export_selection";
      this.confirmationDialog = true;
    },
    getOrderDetails() {
      return false;

      // if (this.isset(this.orderDetails[orderId])) {
      //   return this.orderDetails[orderId];
      // }
      //
      // axios.get(API_BASE_URL + "/orders/" + orderId + "/details", {
      //   headers: this.header,
      //   // signal: this.abortController(),
      // }).then(response => {
      //   this.orderDetails[orderId] = response.data.data;
      //   this.billTableKey++;
      // }).catch(error => {
      // });
    },
    changeSort(column) {
      if (this.sortBy === column) {
        this.sortOrd = !this.sortOrd;
      } else {
        this.sortBy = column;
        this.sortOrd = false;
      }
      this.updateLocalStorage();
    },
    getSelectedArticles() {
      let selectedListing = this.$store.state.listingSelectionsFlatten;
      let articlesSelected = "";
      if (selectedListing) {
        selectedListing.forEach((element) => {
          if (element.is_selected) {
            articlesSelected += element.id + ",";
          }
        });
      }
      if (articlesSelected && articlesSelected.length > 0) {
        articlesSelected = articlesSelected.slice(0, -1);
      }
      return articlesSelected;
    },
    exportDataForAppliedFilters() {
      let articlesSelected = this.getSelectedArticles();
      this.fetchData(null, true, articlesSelected);
    },
    refetchData() {
      this.clearSelectionData();
      this.fetchData();
    },
    showPyamentModelForSelectedExpenses() {
      this.showBulKPaymentModel = true;
    },
    makeBulkPaymentAfterConfirmation() {
      this.confirmationDialogData = null;
      this.confirmationDialogConfirmationText = this.$t(
        "bulk_payments.confirmation_text"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t("navigation.yes");
      this.confirmationDialogOperation = "bulk_payment";
      this.confirmationDialog = true;
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    getRowIndex(index) {
      let addExtraNumber = 1;
      if (this.showGridTotal) {
        addExtraNumber = 0;
      }
      if (this.page != 1) {
        return (this.page - 1) * this.itemsPerPage + index + addExtraNumber;
      }
      return index + addExtraNumber;
    },
    makeSelection() {
      let selected = [];
      const currentPage = this.page;
      const selectedData = this.$store.state.listingSelections || {};

      // Get existing selections for the current page or initialize an empty array
      const currentSelections = this.billings.filter(
        (element) => element.is_selected
      );

      // Merge the current selections with existing ones without duplicates
      // const mergedSelections = [
      //     ...existingSelections,
      //     ...currentSelections.filter(
      //         newItem => !existingSelections.some(existingItem => existingItem.id === newItem.id)
      //     ),
      // ];

      // Update selections for the current page with merged selections
      selectedData[currentPage] = currentSelections;

      // Reconstruct the complete selection object
      Object.keys(selectedData).forEach((page) => {
        selected = selected.concat(selectedData[page]);
      });

      // Update the store with the latest selection
      this.$store.commit("setListingSelections", selectedData);

      let listingSelections = this.$store.state.listingSelections || {};
      if (!listingSelections) return [];

      // Use Object.values to get all values (arrays), then flatten them
      const allItems = Object.values(listingSelections)
        .filter(Array.isArray) // Ensure the value is an array
        .flat(); // Flatten the arrays into a single array

      // Update the flattened selection in the store
      this.$store.commit("setListingSelectionsFlatten", allItems);
    },
    openDocument(item) {
      let url =
        API_BASE_URL +
        "/billings/" +
        item.md5_id +
        "/print?document=order&lang=" +
        (localStorage.getItem("languageId") || "en") +
        "&user=" +
        localStorage.getItem("loginUserId");
      let fileName = item ? item?.bill_number : "";
      this.setPreviewData(url, fileName, true, "application/pdf");
    },
    getTotalUnpaid(paymentHistory, item) {
      if (paymentHistory) {
        let totalPayed = 0;
        paymentHistory.forEach((element) => {
          totalPayed += this.customParseFloat(element.amount ?? 0);
        });
        return (
          this.customParseFloat(item.total) - this.customParseFloat(totalPayed)
        );
      }
      // return parseFloat(item.total);
    },
    getTotalPaid(paymentHistory) {
      if (paymentHistory) {
        let totalPayed = 0;
        paymentHistory.forEach((element) => {
          totalPayed += this.customParseFloat(element.amount ?? 0);
        });
        return this.customParseFloat(totalPayed);
      }
      return 0;
    },
    getBillTypes() {
      return new Promise((resolve) => {
        this.getCodes("order_types", "billings.types").then((data) => {
          this.allStatuses = data;
          data.forEach((element, index) => {
            if (element.key == "bill") {
              data[index].icon = "mdi-script-text";
            } else if (element.key == "purchase_order") {
              data[index].icon = "mdi-tag-text";
            } else if (element.key == "estimation") {
              data[index].icon = "mdi-book-open";
            } else if (element.key == "credit_note") {
              data[index].icon = "mdi-note-multiple";
            }
          });
          this.$store.commit("setBillTypes", data);
          this.selectDefaultBillTypeFromNavigationData();
          resolve();
        });
      });
    },
    setRowColorClass(item, index) {
      let className = "";
      if (item.due_date_status == 0 && this.billType?.key == "bill") {
        className = "dueDate";
      }

      if (
        item.supplierorder &&
        item.supplierorder.supplier_order_details &&
        item.supplierorder.supplier_order_details.delivery_date &&
        this.billType?.key == "purchase_order" &&
        new Date() <
          new Date(
            this.formatDate(
              item.supplierorder.supplier_order_details.delivery_date,
              "yyyy-mm-dd"
            )
          )
      ) {
        className = "yellowColor";
      }

      if (
        item.supplierorder &&
        item.supplierorder.supplier_order_details &&
        item.supplierorder.supplier_order_details.delivery_date &&
        this.billType?.key == "purchase_order" &&
        new Date() >=
          new Date(
            this.formatDate(
              item.supplierorder.supplier_order_details.delivery_date,
              "yyyy-mm-dd"
            )
          )
      ) {
        className = "redColor";
      }

      if (
        item.supplierorder &&
        item.supplierorder &&
        item.supplierorder.status_id == 10091005 /** Fully Received */ &&
        this.billType?.key == "purchase_order"
      ) {
        className = "greenColor";
      }

      // if (item.payed_on != null && item.payed_by_id != null) {
      if (item.status && item.status.key == "payed") {
        className = "greenColor";
      }

      if (item.status && item.status.key === "canceled") {
        className = "greyColor";
      }
      if (this.showGridTotal && index == 0) {
        className = "grey-background";
      }

      return className;
    },
    fetchData(page = null, is_export = false, articlesSelected = "", checkPreviousData = false) {
      this.resetDataTable(page);
      let oldData = null;
        if(checkPreviousData){
            oldData = [...this.billings];
        }
      if (!this.stopSearch) {
        this.isLoading = true;
        if (!is_export) {
          this.billings = [];
        }
        if (is_export) {
          this.$store.commit("setExportStatus", true);
        }
        let statusIds = this.status ? this.status.map((a) => a.id) : [];
        let allStatus = "";
        if (statusIds && statusIds.length > 0) {
          statusIds.forEach((element) => {
            allStatus += element + ",";
          });
          if (allStatus) {
            allStatus = allStatus.replace(/,\s*$/, "");
          }
        }

        let getRequest =
          API_BASE_URL +
          "/orders?page=" +
          this.page +
          "&items_per_page=" +
          this.itemsPerPage +
          (this.sortBy ? "&sortBy=" + this.sortBy : "") +
          (this.sortOrd ? "&sortOrd=ASC" : "&sortOrd=DESC") +
          (this.search ? "&search=" + encodeURIComponent(this.search) : "") +
          (this.ftrByIdentifiant
            ? "&document_number=" + this.ftrByIdentifiant
            : "") +
          (this.company ? "&company_id=" + this.company.company_id : "") +
          (this.client ? "&client_id=" + this.client.id : "") +
          (allStatus ? "&status_id=" + allStatus : "") +
          (this.billType ? "&type_id=" + this.billType.id : "") +
          (this.search_created_date
            ? "&created_date=" + this.search_created_date
            : "") +
          (this.search_payment_date
            ? "&payment_date=" + this.search_payment_date
            : "") +
          (articlesSelected && articlesSelected.length > 0
            ? "&article_ids=" + articlesSelected
            : "") +
          (this.search_due_date ? "&due_date=" + this.search_due_date : "") +
          (this.financialYear ? "&financial_year=" + this.financialYear : "");
        axios
          .get(getRequest + (is_export ? "&is_export=1" : ""), {
            headers: this.header,
            signal: this.abortController(),
          })
          .then(({ data }) => {
            if (is_export) {
              let reportDataInRowsColumns = [];
              let dataRows = data.replace(/"/g, "").split("\n");
              let finalData = [];
              let CSVkeysOrHeaders = dataRows[0].split(";"); // Removing double Quotes and Splitting by SemiColon(;)
              CSVkeysOrHeaders.unshift(this.$t("srno")); // Add Sr No to headers
              let count = -1;
              let rownumber = 1;
              dataRows.forEach((record) => {
                count++;
                if (count == 0) {
                  // return because first row will contain headers
                  return;
                }
                let dataInColumns = record.split(";");
                let rowObject = {};
                let rowColumnsNullCount = 0;
                for (let index = 0; index < CSVkeysOrHeaders.length; index++) {
                  if (CSVkeysOrHeaders[index] == this.$t("srno")) {
                    rowObject[CSVkeysOrHeaders[index]] = rownumber; // Add Sr No to key
                  } else {
                    rowObject[CSVkeysOrHeaders[index]] =
                      dataInColumns[index - 1];
                  }
                  if (
                    rowObject[CSVkeysOrHeaders[index]] &&
                    CSVkeysOrHeaders[index] != this.$t("srno")
                  ) {
                    rowColumnsNullCount++;
                  }
                }
                if (rowColumnsNullCount == 0) {
                  return;
                }
                finalData.push(rowObject);
                rownumber++;
              });
              reportDataInRowsColumns = finalData;
              this.totalItems = count - 1;
              // if(this.totalItems > this.downloadCSVCountValue){
              //     this.saveReport();
              // }
              let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
              this.downloadCSVFromJson(
                "Billings_" + currentDateTime + ".csv",
                CSVkeysOrHeaders,
                reportDataInRowsColumns
              );
            } else {
              this.updateLocalStorage();
              data.data.forEach((element) => {
                element.is_selected = false;
              });

              // Assuming your new page data is in data.data
              const currentPage = this.page; // Or get the current page number from your component's state
              const selectedData = this.$store.state.listingSelections || {};

              // Iterate over the incoming data and update the is_selected key
              data.data.forEach((element) => {
                // Find if the current element is in the previously selected list for this page
                const selectedItems = selectedData[currentPage] || [];
                const isAlreadySelected = selectedItems.some(
                  (selectedItem) => selectedItem.id === element.id
                );

                // Update the is_selected flag accordingly
                element.is_selected = isAlreadySelected;
              });

              this.billings = data.data;
              if (this.showGridTotal) {
                this.billings.unshift([]);
              }
              this.lastPage = data.meta.last_page;
              this.totalItems = data.meta.total;
              this.page = data.meta.current_page;
              if(checkPreviousData){
                this.checkAndTriggerNotificationInTitle(oldData, this.billings);
              }
            }
            // this.itemsPerPage = parseInt(data.meta.per_page);
            // this.sortBy = null;
            // this.sortDesc = null;
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          })
          .finally(() => {
            this.stopSearch = false;
            if (this.showGridTotal) {
              this.fetchGridTotal(getRequest);
            }
            this.$store.commit("setExportStatus", false);
          });
      }
    },
    fetchGridTotal(getRequest) {
      this.isLoadingTotal = true;
      axios
        .get(getRequest + (this.showGridTotal ? "&show_grid_total=1" : ""), {
          headers: this.header,
        })
        .then(({ data }) => {
          this.sum_of_total_without_vat = data.meta.sum_of_total_without_vat;
          this.sum_of_vat_total = data.meta.sum_of_vat_total;
          this.sum_of_total = data.meta.sum_of_total;
          this.sum_of_to_be_payed = data.meta.sum_of_total_to_be_payed;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoadingTotal = false;
        });
    },
    updateLocalStorage() {
      let filters = {
        items_per_page: this.itemsPerPage,
        search: this.search,
        ftrByIdentifiant: this.ftrByIdentifiant,
        company: this.company,
        client: this.client,
        status: this.status,
        page: this.page,
        search_due_date: this.search_due_date,
        search_created_date: this.search_created_date,
        search_payment_date: this.search_payment_date,
        billType: this.billType,
        financialYear: this.financialYear,
        sortBy: this.sortBy,
        sortOrd: this.sortOrd,
      };
      localStorage.setItem("BILLING_LISTING_FILTERS", JSON.stringify(filters));
    },
    applyPreviousFilters() {
      let applyOnRoutes = ["BillingListing"];
      if (applyOnRoutes.includes(localStorage.getItem("NT_ROUTE_KEY"))) {
        let billingFilters = JSON.parse(
          localStorage.getItem("BILLING_LISTING_FILTERS")
        );
        if (billingFilters != null) {
          this.itemsPerPage = billingFilters.items_per_page;
          this.sortBy = billingFilters.sortBy;
          this.sortOrd = billingFilters.sortOrd;
          this.search = billingFilters.search;
          this.ftrByIdentifiant = billingFilters.ftrByIdentifiant;
          this.page = billingFilters.page;
          this.company = billingFilters.company;
          if (billingFilters.client) {
            (this.client = billingFilters.client),
              (this.clients = [this.client]);
          }
          this.status = billingFilters.status;
          (this.search_due_date = billingFilters.search_due_date),
            (this.search_created_date = billingFilters.search_created_date),
            (this.search_payment_date = billingFilters.search_payment_date),
            (this.financialYear = billingFilters.financialYear);
        }
      }
    },
    filterOutStatuses() {
      return new Promise((resolve) => {
        let filteredStatuses = [];

        if (this.billType) {
          switch (this.billType.key) {
            case "estimation":
              filteredStatuses = this.filterAndSortStatuses(
                this.listingStatusFilters.estimation,
                "estimation"
              );
              break;
            case "purchase_order":
              filteredStatuses = this.filterAndSortStatuses(
                this.listingStatusFilters.purchase_order,
                "purchase_order"
              );
              break;
            case "bill":
              filteredStatuses = this.filterAndSortStatuses(
                this.listingStatusFilters.bill,
                "bill"
              );
              break;
            case "credit_note":
              filteredStatuses = this.filterAndSortStatuses(
                this.listingStatusFilters.credit_note,
                "credit_note"
              );
              break;
            default:
              filteredStatuses = this.allStatuses;
          }
        } else {
          filteredStatuses = this.allStatuses;
        }

        this.orderstatuses = filteredStatuses;
        resolve();
      });
    },
    filterAndSortStatuses(statusFilter, passedBillType) {
      return this.allStatuses
        .filter((element) => statusFilter.includes(element.key))
        .filter((element) =>
          passedBillType == "bill" ? element.key != "canceled" : true
        )
        .sort(
          (a, b) => statusFilter.indexOf(a.key) - statusFilter.indexOf(b.key)
        );
    },
    getStatuses() {
      return new Promise((resolve) => {
        this.getCodes("order_status", "billings.statuses").then((data) => {
          this.allStatuses = data;
          resolve();
        });
      });
    },
    getCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/companies", { headers: this.header })
          .then(({ data }) => {
            this.companies = data.data.result.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          });
      });
    },
    getNextEvents() {
      return new Promise((resolve) => {
        this.getCodes("order_next_event", "billings.nextEvents").then(
          (data) => {
            this.nextEvents = data;
            resolve();
          }
        );
      });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    editItem(item, index) {
      this.updateLocalStorage();
      if (
        (this.showGridTotal && index != 0) ||
        (!this.showGridTotal && index >= 0)
      ) {
        this.$store.commit("seteditId", item.id);
        this.$router.push("/billing/edit").catch(() => {});
      }
    },
    deleteItem() {
      //
    },

    setDefaultStatusAndSearch() {
      this.filterOutStatuses().then(() => {
        // if(this.billType && this.billType?.key === "bill" && !this.isset(this.status)){
        //     this.status = this.orderstatuses.find( element => element.key === "waiting");
        //     return null;
        // }

        let applyOnRoutes = ["BillingListing"];

        if (
          applyOnRoutes.includes(localStorage.getItem("NT_ROUTE_KEY")) &&
          JSON.parse(localStorage.getItem("BILLING_LISTING_FILTERS"))
        ) {
          let billingFilters = JSON.parse(
            localStorage.getItem("BILLING_LISTING_FILTERS")
          );
          if (billingFilters != null) {
            this.status = billingFilters.status;
          }
          if (billingFilters?.billType?.key == this.billType?.key) {
            this.page = billingFilters.page;
            this.itemsPerPage = billingFilters.items_per_page;
            this.changePage(this.page);
          }
          localStorage.removeItem("BILLING_LISTING_FILTERS");
        }
        // this.fetchData();
        // else if (this.status == this.orderstatuses[0]) {
        //     this.fetchData();
        // } else {
        //     this.status = this.orderstatuses[0];
        // }
      });
    },
  },
  computed: {
    currencySymbol() {
      let symbol = null;
      if (this.$store.state.topCurrencyType) {
        symbol = this.parseSymbol(
          this.$store.state.topCurrencyType.symbol ?? ""
        );
        return symbol;
      }
      return symbol ?? "";
    },
    totalSelected() {
      let selectedListing = this.$store.state.listingSelectionsFlatten;
      let billingsSelectedTotal = 0;
      if (selectedListing) {
        selectedListing.forEach((element) => {
          if (element.is_selected) {
            billingsSelectedTotal =
              billingsSelectedTotal + parseFloat(element.total);
          }
        });
      }
      return billingsSelectedTotal.toFixed(this.getDecimalNumber());
    },
    totalSelectedCount() {
      if (this.listingSelectionsFlatten) {
        return this.listingSelectionsFlatten.length;
      }
      return 0;
    },
    totalSelectedItems() {
      if (
        this.listingSelectionsFlatten &&
        this.listingSelectionsFlatten.length
      ) {
        let allSelectedItems = "";
        this.listingSelectionsFlatten.forEach((element) => {
          allSelectedItems += element.bill_number + ", ";
        });
        if (allSelectedItems.length > 2) {
          allSelectedItems = allSelectedItems.substring(
            0,
            allSelectedItems.length - 2
          );
        }
        return allSelectedItems;
      }
      return "";
    },
    isPurchaseOrderOrBillOrCreditNote() {
      if (this.billType) {
        return !["estimation"].includes(this.billType.key);
      }
      return false;
    },
    isBillOrCreditNote() {
      if (this.billType) {
        return !["estimation", "purchase_order"].includes(this.billType.key);
      }
      return false;
    },
    navigationData() {
      return this.$store.state.navigationData;
    },
    headers() {
      let data = [
        { text: "", value: "srno", width: 1, sortable: false, footer_width: 2 },
        {
          text: "",
          value: "attachments",
          width: 1,
          sortable: false,
          footer_width: 2,
        },
        {
          text: this.$t("billings.identifiant"),
          value: "identifier",
          width: 9,
          sortable: true,
        },
        {
          text: this.$t("billings.client"),
          value: "client_id",
          width: 13,
          sortable: false,
        },
        {
          text: this.$t("billings.totalThtva"),
          value: "total_thtva",
          width: 5,
          sortable: false,
          align: "end",
        },
        {
          text: this.$t("billings.totalTva"),
          value: "total_tva",
          width: 5,
          sortable: false,
          align: "end",
        },
        {
          text: this.$t("billings.total"),
          value: "total",
          width: 5,
          sortable: false,
          align: "end",
        },
        {
          text: this.$t("billings.created_date"),
          value: "created_date",
          width: 10,
          sortable: true,
        },
        {
          text: this.$t("billings.status"),
          value: "status_id",
          width: "9%",
          sortable: true,
        },
      ];

      if (this.isPurchaseOrderOrBillOrCreditNote) {
        data.splice(1, 0, {
          text: "",
          value: "checkbox",
          width: 1,
          sortable: false,
          footer_width: 2,
        });
        data.splice(8, 0, {
          text: this.$t("expenses.to_be_payed"),
          value: "to_be_payed",
          width: 5,
          sortable: false,
          align: "end",
        });
        data.splice(9, 0, {
          text: this.$t("billings.payed_at"),
          value: "payed_at",
          width: 8,
          sortable: false,
          align: "end",
        });
        data.splice(10, 0, {
          text: this.$t("billings.payed_by"),
          value: "payed_by_id",
          width: 8,
          sortable: false,
          align: "center",
        });
        data.splice(12, 0, {
          text: this.$t("billings.due_date"),
          value: "due_date",
          width: 8,
          sortable: true,
        });

        if (this.companyIsPeppol) {
          data.splice(11, 0, {
            text: "PEPPOL " + this.$t("billings.status"),
            value: "client.is_peppol",
            width: 10,
            align: "center",
            sortable: false,
          });
        }
      }
      return data;
    },
    selectedItemsForBulkPayment() {
      return this.$store.state.listingSelectionsFlatten || [];
    },
  },
  watch: {
    navigationData: {
      handler: function () {
        this.selectDefaultBillTypeFromNavigationData();
        this.clearSelectionData();
      },
    },
    sortOrd: {
      handler: function () {
        if (this.isPageLoaded) {
          this.fetchData();
        }
      },
    },
    sortBy: {
      handler: function () {
        if (this.isPageLoaded) {
          this.fetchData();
        }
      },
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
        if (this.confirmationDialogOperation == "bulk_payment") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.showPyamentModelForSelectedExpenses();
          }
        }
        if (
          (this.confirmationDialogOperation == "make_bill" ||
            this.confirmationDialogOperation == "make_bill_error") &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.convertSelectedOrders();
        }
        if (
          this.confirmationDialogOperation == "make_purchase_order" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.convertSelectedOrders();
        }
        if (
          this.confirmationDialogOperation == "make_credit_note" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.convertSelectedOrders();
        }
        if (
          this.confirmationDialogOperation == "make_export_selection" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.zipSelectedOnes();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
    isAllSelected(val) {
      if (val) {
        this.billings.forEach((element) => {
          if (element && element.status && element.status.value == "waiting") {
            element.is_selected = true;
          }
        });
      } else {
        this.billings.forEach((element) => {
          element.is_selected = false;
        });
      }
      this.makeSelection();
    },
    searchClient: _.debounce(function () {
      if (!this.searchClient) {
        return;
      }

      let searchTerm = this.searchClient.toString();
      searchTerm = searchTerm.trim();
      if (this.searchClient && this.searchClient.length > 0) {
        if (this.client && this.client.clientname == this.searchClient) {
          return;
        }

        this.clients = [];
        this.isLoadingSearchClientInput = true;
        axios
          .get(API_BASE_URL + "/clients/search?search=" + searchTerm, {
            headers: this.header,
          })
          .then(({ data }) => {
            data.data.forEach((element) => {
              element.clientname =
                element.name + " " + (element.first_name ?? "");
            });
            this.clients = data.data;
          })
          .catch(function (error) {
            console.log("an error occured " + error);
          })
          .finally(() => {
            this.isLoadingSearchClientInput = true;
          });
      }
    }, 400),
    search: _.debounce(function () {
      if (this.isPageLoaded) {
        this.fetchData();
      }
    }, 1000),
    ftrByIdentifiant: _.debounce(function () {
      if (this.isPageLoaded) {
        this.fetchData();
      }
    }, 1000),
    company: {
      handler: function () {
        if (this.isPageLoaded) {
          this.fetchData();
        }
      },
    },
    client: {
      handler: function () {
        if (this.isPageLoaded) {
          this.fetchData();
        }
      },
    },
    status: {
      handler: function () {
        if (this.isPageLoaded) {
          this.fetchData();
        }
      },
    },
    billType: {
      handler: function () {
        // Reset the status value
        this.status = null;
        this.clearSelectionData();

        if (this.isPageLoaded) {
          localStorage.removeItem("BILLING_LISTING_FILTERS");
          this.setDefaultStatusAndSearch();
          this.fetchData();
          this.clearSelectionData();
        }
      },
    },
    date: {
      handler: function () {
        if (this.isPageLoaded) {
          this.fetchData();
        }
      },
    },
    search_created_date: _.debounce(function () {
      if (this.isPageLoaded) {
        this.updateLocalStorage();
        this.fetchData();
      }
    }, 100),
    search_due_date: _.debounce(function () {
      if (this.isPageLoaded) {
        this.updateLocalStorage();
        this.fetchData();
      }
    }, 100),
    search_payment_date: _.debounce(function () {
      if (this.isPageLoaded) {
        this.updateLocalStorage();
        this.fetchData();
      }
    }, 100),
  },
};
</script>

<style scoped>
tr:hover {
  border: 2px solid blue;
  color: #000;
  font-weight: 500;
}

tr.dueDate {
  background-color: rgb(255, 220, 220) !important;
}

tr.dueDate:hover {
  background: rgb(255, 153, 153) !important;
  border: 2px solid blue;
  color: #fff;
  font-weight: 500;
}

tr.greenColor {
  background-color: rgb(221, 255, 220) !important;
}

tr.greenColor:hover {
  background: rgb(153, 255, 162) !important;
  border: 2px solid blue;
  color: rgb(34, 34, 34);
  font-weight: 500;
}

tr.greyColor {
  background-color: rgb(238, 238, 238) !important;
}

tr.greyColor:hover {
  background: rgb(204, 204, 204) !important;
  border: 2px solid blue;
  color: rgb(34, 34, 34);
  font-weight: 500;
}

tr.yellowColor {
  background-color: rgb(250, 255, 220) !important;
}

tr.yellowColor:hover {
  background: rgb(255, 240, 153) !important;
  border: 2px solid blue;
  color: rgb(39, 38, 38);
  font-weight: 500;
}

tr.redColor {
  background-color: rgb(255, 221, 220) !important;
}

tr.redColor:hover {
  background: rgb(255, 153, 153) !important;
  border: 2px solid blue;
  color: rgb(39, 38, 38);
  font-weight: 500;
}

.v-tooltip__content {
  font-size: 14px !important;
  background: rgb(255, 255, 255) !important;
  border: 1px solid black;
  line-height: 0%;
  opacity: 1 !important;
}
</style>
