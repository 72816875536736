<template>
  <div
    @dragover.prevent="onDragOver"
    @dragleave="onDragLeave"
    @drop.prevent="onDrop"
  >
    <v-row class="group_label">
      <v-col class="pb-0">
        <span style="color: #2196f3">{{
          $t("vehicles.vehicle_tab.attachments")
        }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- All Attachments -->
        <v-row v-if="attachments && attachments.length > 0">
          <v-col cols="12" sm="6" class="pt-0">
            <template v-if="attachments && attachments.length > 0">
              <ul class="scroll pl-0 pt-3" v-if="attachments.length">
                <li class="list-item" v-for="(file, index) in attachments" :key="index">
                  <!-- Hide Bill Attachment -->
                  <template v-if="!checkIfToHide(file.description)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="attachment_icon_color"
                          @click="viewAttachment(file, showInFullScreen)"
                          right
                          medium
                        >
                          {{ findFileIconWithDescription(file) }}
                        </v-icon>
                      </template>
                      {{ tooltipTitle(file.title) }}
                    </v-tooltip>
                  </template>
                </li>
              </ul>
            </template>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="2" v-if="attachment" class="mb-0 pb-0 pt-0">
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="attachment.title"
              class="to-upper"
              :label="$t('attachment.title_optional')"
              v-on:keydown.enter.prevent=""
              v-on:keydown="handleInput($event, 'alphanumeric', 80)"
              :outlined="is_boxes"
              :dense="is_boxes"
              @change="saveItem(attachment)"
              @blur="saveItem(attachment)"
              :hide-details="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Drop Zone -->
        <div
          v-if="!isCurrentlyShowingFile"
          class="dropzone mt-5"
          :class="{ dragover: isDragging }"
          @click="openFilePicker"
        >
          <v-icon v-if="uploadedFiles.length === 0" class="mdi-48px"
            >mdi-upload</v-icon
          >
          <span v-if="uploadedFiles.length === 0">
            {{ $t('attachments_module.no_data') }}
          </span>
          <div v-else class="uploaded-files">
            <v-icon
              v-for="(file, index) in uploadedFiles"
              :key="index"
              class="mdi-48px"
            >
              mdi-file
            </v-icon>
            <span v-if="isUploadingStatus != 'not_selected'">
              {{ uploadedFiles.length }}
              {{ $t("attachments_module.files") }}
              {{
                isUploadingStatus == "started"
                  ? $t("attachments_module.is_uploading")
                  : isUploadingStatus == "completed"
                  ? $t("attachments_module.is_uploaded")
                  : $t("attachments_module.failed")
              }}
            </span>
          </div>
          <input type="file" ref="fileInput" hidden @change="onFileSelect" />
        </div>

        <!-- PDF Viewer -->
        <div
          v-if="
            showPdfViewer &&
            $store.state.previewUrl &&
            !$store.state.isShowFullScreen
          "
          class="mt-5"
        >
          <v-card elevation="2" height="1600px">
            <PDFViewer
              v-if="
                showPdfViewer &&
                $store.state.previewUrl &&
                !$store.state.isShowFullScreen
              "
              :notFullScreen="true"
            ></PDFViewer>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import PDFViewer from "@/components/PDFViewer";

export default {
  name: "FileDropzone",
  components: {
    PDFViewer,
  },
  props: {
    resource: [String],
    data: [Number, String, Object],
    hideDocumentTypes: [Array],
    showInFullScreen: { type: Boolean, default: true },
    showImmediate: { type: Boolean, default: false },
  },
  data() {
    return {
      showPdfViewer: true,
      isDragging: false,
      uploadedFiles: [], // To store uploaded files
      title: null,
      autocompleteValueLocal: "off",
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      refreshAttachment: false,
      countKey: "vehicles",
      refreshDynamicContent: true,
      sortBy: "description",
      sortDesc: false,
      options: {},
      isLoading: false,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      module_id: null,
      resource_id: null,
      editModeItem: null,
      attachments: [],
      attachment: null,
      isUploadingStatus: "not_selected",

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,
    };
  },
  mounted() {
    let data = JSON.parse(localStorage.getItem("data"));
    let applicationResourceId = null;
    data.applicationResources.forEach((resource) => {
      if (resource.application_resource.key == this.resource) {
        applicationResourceId = resource.application_resource.id;
      }
    });
    data.attachmentModules.forEach((modules) => {
      if (modules.application_resource_id == applicationResourceId) {
        this.module_id = modules.id;
        this.resource_id = modules.application_resource_id;
      }
    });
    this.getAttachments();
  },
  methods: {
    tooltipTitle(title) {
      let expedition = this.data
        ? typeof this.data == "object"
          ? this.data
          : null
        : null;
      if (expedition && expedition.identifier) {
        title = title.replace(expedition.identifier, "");
      }
      return title;
    },
    checkIfToHide(documentToHide) {
      if (this.hideDocumentTypes.includes(documentToHide.toLowerCase())) {
        return true;
      }
      return false;
    },
    viewAttachment(item, showInFullScreen) {
      this.attachment = item;
      let url = item.signed_url;
      let fileName = item.title;
      this.setPreviewData(
        url,
        fileName,
        true,
        item.mime_type,
        showInFullScreen
      );
    },
    getItemIcon(item) {
      if (item.mime_type.includes("video")) {
        return "orange";
      } else if (item.mime_type.includes("image")) {
        return "blue";
      } else if (item.mime_type.includes("audio")) {
        return "blue";
      } else {
        return "black";
      }
    },
    getItemIconColor(item) {
      if (item.mime_type.includes("video")) {
        return "mdi mdi-multimedia";
      } else if (item.mime_type.includes("image")) {
        return "mdi mdi-image";
      } else if (item.mime_type.includes("audio")) {
        return "mdi mdi-volume-high";
      } else {
        return "mdi mdi-file";
      }
    },
    saveItem(item) {
      let data = {
        title: (this.attachment.title ?? "").toUpperCase(),
      };
      this.$store.state.isLoading = true;
      axios
        .put(API_BASE_URL + "/attachments/" + item.id, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(() => {})
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.$store.state.isLoading = false;
        });
      this.cancelEdit();
    },
    addAttachments() {
        console.log("add attachment", this.uploadedFiles);
      if (this.uploadedFiles && this.uploadedFiles.length > 0) {
        this.$store.commit("setValidation", true); // enable validation
        let formData = new FormData();
        this.isUploadingStatus = "started";
        formData.append("attachment", this.uploadedFiles[0]);
        formData.append("module_id", this.module_id);
        formData.append("title", "");
        formData.append("group", this.group ?? "");
        formData.append("description", "");
        formData.append("resource_key_id", this.data);
        this.$store.state.isLoading = true;
        axios
          .post(API_BASE_URL + "/attachments", formData, {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(({ data }) => {
            this.isUploadingStatus = "completed";
            this.attachments.push(data.data);
            this.totalItems++;
            this.resetForm();
            this.selectLatestAttachment();
          })
          .catch((error) => {
            if (error?.response?.status == 413) {
                this.attchmentErrors = this.$t("very_large_file");
            }
            if(error.response.data.errors){
                this.attchmentErrors = error.response.data.errors[0];
                this.$toast.error(this.attchmentErrors);
            }
            this.isUploadingStatus = "failed";
            this.clearFiles();
          })
          .finally(() => {
            this.$store.state.isLoading = false;
          });
        this.$store.commit("setValidation", false); // disable validation
      }
    },
    selectLatestAttachment() {
      if (this.attachments && this.attachments.length > 0) {
        this.viewAttachment(
          this.attachments[this.attachments.length - 1],
          this.showInFullScreen
        );
      }
    },
    resetForm() {
      this.attachment = null;
      this.title = null;
      this.group = null;
      this.description = null;
    },
    deleteAttachment(id) {
      axios
        .delete(API_BASE_URL + "/attachments/" + id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(() => {
          this.refreshDynamicContent = false;
          let tempAttachments = [];
          this.attachments.forEach((attachment) => {
            if (attachment.id != id) {
              tempAttachments.push(attachment);
            }
          });
          this.attachments = tempAttachments;
          this.$nextTick(() => {
            this.refreshDynamicContent = true;
            let data =
              typeof this.$store.state.attachmentCounts == "number"
                ? []
                : this.$store.state.attachmentCounts ?? [];
            data[this.countKey ?? "data"] = this.attachments.length;
            this.$store.commit("setAttachmentCounts", data);
          });
        })
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.$store.state.isLoading = false;
        });
      this.confirmationDialogData = null;
    },
    getAttachments() {
      if (this.data) {
        axios
          .get(
            API_BASE_URL +
              `/attachments?module_id=${this.module_id}&resource_key_id=${this.data}`,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
              },
            }
          )
          .then(({ data }) => {
            this.attachments = data.data.result;
            if (
              this.showImmediate &&
              this.attachments &&
              this.attachments.length > 0
            ) {
              this.viewAttachment(this.attachments[0], this.showInFullScreen);
            }
          })
          .catch((error) => {
            console.log("an error occured " + error);
          })
          .finally(() => {
            this.$store.state.isLoading = false;
          });
      }
    },
    onDragOver() {
      this.isDragging = true;
    },
    onDragLeave() {
      this.isDragging = false;
    },
    onDrop(event) {
      this.clearFiles();
      this.isDragging = false;
      const files = Array.from(event.dataTransfer.files);
      this.addFiles(files);
    },
    onFileSelect(event) {
      this.clearFiles();
      const files = Array.from(event.target.files);
      this.addFiles(files);
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    addFiles(files) {
      this.uploadedFiles = [...this.uploadedFiles, ...files];
      this.$emit("files-selected", files);
      if (this.data) {
        this.addAttachments();
      } else {
        // Call Parent Function to complete and then call addAttachments
        this.$emit("saveParentBeforeAddingAttachment", () => {
          this.addAttachments();
        });
      }
    },
    clearFiles() {
      this.uploadedFiles = [];
    },
  },
  watch: {
    attachmentDialog() {
      // this.getAttachments();
    },
    selectedDocumentToShow() {
      this.showPdfViewer = false;
      this.$nextTick(() => {
        this.showPdfViewer = true;
      });
    },
    data() {
      if (this.data) {
        this.getAttachments();
      } else {
        this.resetForm();
        this.attachments = [];
      }
    },
  },
  computed: {
    isCurrentlyShowingFile() {
      return this.$store.state.previewUrl;
    },
  },
};
</script>

<style scoped>
.dropzone {
  width: 100%;
  height: 150px;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #aaa;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.dropzone:hover {
  background-color: #f1f1f1;
  border-color: #aaa;
}

.dropzone.dragover {
  background-color: #e3f2fd;
  border-color: #42a5f5;
  color: #42a5f5;
}

.uploaded-files {
  display: flex;
  align-items: center;
  gap: 10px;
}
ul {
  list-style-type: none;
}
</style>
