<template v-if="clientId">
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="warning"
          dark
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
        >
          {{ $t("billings.history_short") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ $t("billings.history") }} </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container style="height: 800px">
            <v-data-table
              :headers="headers"
              :items="items"
              :options="options"
              :loading="progressBarLoading"
              :server-items-length="totalItems"
              :items-per-page="itemsPerPage"
              :loading-text="this.$t('loading')"
              @update:page="updatePagination"
              @click:row="repeatArticles($event.details)"
              :footer-props="{
                'items-per-page-options': dataTableOptions,
              }"
              class="elevation-0"
              :height="'calc(-310px + 100vh)'"
            >
              <template v-slot:item.description="{ item }">
                <span v-html="item.description"></span>

                <v-tooltip
                  right
                  z-index="9999"
                  v-if="item.details.length > minimalLength"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a>
                      <v-icon color="blue" v-bind="attrs" v-on="on">
                        mdi-dots-horizontal
                      </v-icon>
                    </a>
                  </template>
                  <v-list-item
                    class="pa-0 ma-0"
                    v-for="(articles, index) in item.details"
                    :key="index"
                  >
                    <template
                      v-if="
                        articles.article &&
                        articles.article.descriptions.length > 0
                      "
                    >
                      {{ articles.quantity }} X
                      {{ articles.article.descriptions[0].description }}
                    </template>
                    <template v-else>
                      <template v-if="articles && articles.text">
                        {{ articles.quantity }} X {{ articles.text }}
                      </template>
                    </template>
                  </v-list-item>
                </v-tooltip>
              </template>
              <template v-slot:item.action>
                <v-btn color="blue darken-1" icon>
                  <v-icon>mdi-redo</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { mapState } from "vuex";

export default {
  name: "ClientBillingHistory",
  props: {
    clientId: {
      type: Number,
      default: null,
    },
    vehicleId: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["progressBarLoading"]),
    options() {
      return {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
      };
    },
  },
  data() {
    return {
      dialog: false,
      itemsPerPage: 10,
      totalItems: 0,
      dataTableOptions: [10, ...DEFAULT_ITEMS_PER_PAGE_OPTIONS],
      minimalLength: 4,
      page: 1,
      headers: [
        {
          text: this.$t("attachment.date"),
          value: "date",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("attachment.description"),
          value: "description",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("attachment.actions"),
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  watch: {
    dialog(val) {
      if (!val || !this.clientId) {
        return;
      }

      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      this.items = [];

      let url = `${API_BASE_URL}/billings?client_id=${this.clientId}&page=${this.page}&items_per_page=${this.itemsPerPage}&type_id=10241002`;

      if (this.vehicleId) {
        url += `&vehicle_id=${this.vehicleId}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .finally(() => {})
        .then(({ data }) => {
          this.totalItems = data.meta.total;
          this.page = data.meta.current_page;

          this.items = data.data
            .map((item) => {
              item.details = item.details
                .filter(
                  (currentDetail) =>
                    currentDetail.article.family.is_stock_managed === 0
                )
                .map((currentDetail) => {
                  let unitPrice = currentDetail.price;
                  let thtva =
                    currentDetail.price * currentDetail.quantity -
                    (currentDetail.price *
                      currentDetail.quantity *
                      currentDetail.reduction) /
                      100;
                  return {
                    ...currentDetail,
                    article_original_name: currentDetail.article,
                    tva: currentDetail.tax,
                    thtva: thtva,
                    unit_price: unitPrice,
                  };
                });

              return {
                ...item,
                description: this.computeDescription(item.details),
                date: item.created_at,
              };
            })
            .filter((item) => item.details.length > 0);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    computeDescription(details, type = "minimal") {
      let description = "";

      for (let i = 0; i < details.length; i++) {
        if (i > this.minimalLength && type === "minimal") {
          break;
        }
        let descriptionText = "";
        if (details[i].article?.descriptions.length == 0) {
          descriptionText = details[i].text;
        } else {
          descriptionText = details[i].article?.descriptions[0]?.description;
        }
        description +=
          parseFloat(details[i].quantity).toFixed(2) +
          " x " +
          descriptionText +
          "<br> ";
      }

      return description;
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    repeatArticles(data) {
      this.$store.state.repeatedArticles = data;
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.v-tooltip__content {
  font-size: 14px !important;
  background: rgb(255, 255, 255) !important;
  border: 1px solid black;
  line-height: 0%;
  opacity: 1 !important;
}
</style>
