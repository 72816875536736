<template>
  <div>
    <v-card
      class="floating-form"
      :style="{
        position: 'absolute',
        top: '10px',
        left: navigationIsOpen ? '270px' : '70px',
      }"
    >
      <v-card-title>
        <v-icon @click="$emit('close')">mdi-arrow-left</v-icon>
        <span class="ml-2 mr-2">{{ $t("mappings.history") }}</span>
        <v-icon v-if="!hideBody" @click="hideBody = true">mdi-eye-off</v-icon>
        <v-icon v-if="hideBody" @click="hideBody = false">mdi-eye</v-icon>
      </v-card-title>
      <v-card-text v-if="!hideBody">
        <v-form>
          <v-select
            :label="$t('select_duration')"
            :items="durations"
            item-text="text"
            item-value="value"
            v-model="selectedDuration"
            :disabled="loading"
            :loading="loading"
          >
          </v-select>
          <div v-if="selectedDuration === 'custom'">
            <v-menu
              v-model="fromDateDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  :label="$t('mappings.from_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loading"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                @input="fromDateDialog = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="toDateDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  :label="$t('mappings.to_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loading"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                @input="toDateDialog = false"
              ></v-date-picker>
            </v-menu>
            <v-btn
              :disabled="loading"
              @click="getPositions"
              color="primary"
              class="w-full"
              style="width: 100%"
            >
              {{ $t("mappings.proceed") }}
            </v-btn>
          </div>
        </v-form>
        <v-card-title v-if="isset(trips)">
          <span class="mr-2">{{ $t("mappings.trips") }}</span>
          <v-icon v-if="!hideTripsTable" @click="hideTripsTable = true"
            >mdi-eye-off</v-icon
          >
          <v-icon v-if="hideTripsTable" @click="hideTripsTable = false"
            >mdi-eye</v-icon
          >
        </v-card-title>
        <v-data-table
          v-if="!hideTripsTable && isset(trips)"
          :headers="tableHeaders"
          :items="tripItems"
          @click:row="(item) => updateRouteCoordinates(item.positions)"
        >
          <template #item="{ item, index }">
            <tr :class="{ 'bg-blue': tripRendered === 'row_' + index }">
              <td @click="renderTrip(item, index)">{{ item.s_n }}</td>
              <td @click="renderTrip(item, index)">{{ item.start_time }}</td>
              <td @click="renderTrip(item, index)">{{ item.end_time }}</td>
              <td @click="renderTrip(item, index)">{{ item.distance }}</td>
              <td @click="renderTrip(item, index)">{{ item.average_speed }}</td>
              <td v-if="tripRendered">
                <v-icon
                  v-if="tripRendered === 'row_' + index"
                  color="white"
                  @click="removeRenderedTrip"
                  >mdi-close</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-card-title v-if="isset(events)">
          <span class="mr-2">{{ $t("mappings.events") }}</span>
          <v-icon v-if="!hideEventsTable" @click="hideEventsTable = true"
            >mdi-eye-off</v-icon
          >
          <v-icon v-if="hideEventsTable" @click="hideEventsTable = false"
            >mdi-eye</v-icon
          >
        </v-card-title>
        <v-data-table
          v-if="!hideEventsTable && isset(events)"
          :headers="eventsTableHeaders"
          :items="eventItems"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
    <div
      id="main-map"
      class="map"
      style="width: 100%; height: 100vh !important"
    ></div>
  </div>
</template>
<script>
import { Map, NavigationControl, Marker, Popup } from "maplibre-gl";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import moment from "moment";

export default {
  name: "VehicleMap",
  emits: ["close"],
  props: {
    trackingDevice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      positions: [],
      trips: [],
      currentCoordinates: [],
      routeCoordinates: [],
      tripRendered: false,
      hideTripsTable: false,
      hideEventsTable: false,
      hideBody: false,
      events: [],
      selectedDuration: "today",
      fromDate: null,
      fromDateDialog: false,
      toDate: null,
      toDateDialog: false,
      durations: [
        {
          text: this.$t("mappings.today"),
          value: "today",
        },
        {
          text: this.$t("mappings.yesterday"),
          value: "yesterday",
        },
        {
          text: this.$t("mappings.this_week"),
          value: "this_week",
        },
        {
          text: this.$t("mappings.previous_week"),
          value: "previous_week",
        },
        {
          text: this.$t("mappings.this_month"),
          value: "this_month",
        },
        {
          text: this.$t("mappings.previous_month"),
          value: "previous_month",
        },
        {
          text: this.$t("mappings.custom"),
          value: "custom",
        },
      ],
      formConfig: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken,
        },
      },
      eventsTableHeaders: [
        {
          text: this.$t("srno"),
          value: "s_n",
        },
        {
          text: this.$t("mappings.event_type"),
          value: "event_type",
        },
        {
          text: this.$t("mappings.event_time"),
          value: "event_time",
        },
      ],
    };
  },
  async mounted() {
    await this.getPositions();
    await this.reloadMap();
  },
  watch: {
    trackingDevice(val, oldVal) {
      if (!val) {
        return false;
      }

      if (!oldVal) {
        return this.reloadMap();
      }

      // if (oldVal.position.longitude !== val.position.longitude && oldVal.position.latitude !== val.position.latitude) {
      //   this.reloadMap();
      // }
    },
    async selectedDuration(val) {
      this.tripRendered = false;
      if (val === "custom") {
        return false;
      }

      await this.getPositions();
      await this.reloadMap();
    },
  },
  computed: {
    navigationIsOpen() {
      return this.$store.state.navigationIsOpen;
    },
    loading() {
      return this.$store.state.progressBarLoading;
    },
    geoJsonData() {
      return {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              "marker-color": "#148B67FF",
              "marker-size": "medium",
              "marker-symbol": "car",
              mode: "car",
            },
            geometry: {
              type: "MultiLineString",
              coordinates: [this.routeCoordinates],
            },
            details: ["route_details"],
          },
        ],
      };
    },
    dateRange() {
      return this.computeDateRange(this.selectedDuration);
    },
    tripsWithPositions() {
      return this.trips.map((trip) => {
        let positions = this.positions.filter(
          (position) =>
            position.id >= trip.startPositionId &&
            position.id <= trip.endPositionId
        );
        return {
          ...trip,
          positions,
        };
      });
    },
    tripItems() {
      return this.tripsWithPositions.map((trip, index) => {
        return {
          ...trip,
          s_n: index + 1,
          start_time: moment(trip.startTime).format("DD/MM/YYYY HH:mm:ss"),
          end_time: moment(trip.endTime).format("DD/MM/YYYY HH:mm:ss"),
          distance: this.toKm(trip.distance),
          average_speed: trip.averageSpeed.toFixed(2) + " km/h",
          start_km: this.toKm(trip.startOdometer),
          end_km: this.toKm(trip.endOdometer),
        };
      });
    },
    eventItems() {
      return this.events
        .filter((event) => event.type !== "deviceUnknown")
        .map((event, index) => {
          return {
            ...event,
            s_n: index + 1,
            event_type: this.$t("mappings.event_types." + event.type),
            event_time: moment(event.eventTime).format("DD/MM/YYYY HH:mm:ss"),
          };
        });
    },
    tableHeaders() {
      let columns = [
        {
          text: this.$t("srno"),
          value: "s_n",
          sortable: false,
        },
        {
          text: this.$t("mappings.start_time"),
          value: "start_time",
          sortable: false,
        },
        {
          text: this.$t("mappings.end_time"),
          value: "end_time",
          sortable: false,
        },

        {
          text: this.$t("mappings.distance"),
          value: "distance",
          sortable: false,
        },
        {
          text: this.$t("mappings.average_speed"),
          value: "average_speed",
          sortable: false,
        },
      ];

      if (this.tripRendered) {
        columns.push({
          text: "",
          value: "cancel",
          sortable: false,
        });
      }

      return columns;
    },
  },
  methods: {
    async reloadMap(tripOnly = false) {
      if (
        !tripOnly &&
        this.oldValTrackingDevice?.position?.longitude ===
          this.trackingDevice?.position?.longitude &&
        this.oldValTrackingDevice?.position?.latitude ===
          this.trackingDevice?.position?.latitude
      ) {
        return false;
      }

      let coordinates = [
        this.trackingDevice.position.longitude,
        this.trackingDevice.position.latitude,
      ];
      this.currentCoordinates = coordinates;
      const mapOptions = {
        container: "main-map",
        style:
          "https://api.maptiler.com/maps/streets-v2/style.json?key=f4IJi1mRlspltChrcUM3",
        center: coordinates,
        zoom: 14,
      };
      let map = new Map(mapOptions);

      map.addControl(new NavigationControl());

      let mapHtmlNode = document.getElementById("main-map");

      mapHtmlNode.style.width = "100%";
      mapHtmlNode.style.height = "100vh";

      new Popup();

      setTimeout(async () => {
        if (!tripOnly) {
          new Marker({ color: "#FF0000" }).setLngLat(coordinates).addTo(map);
        }

        // StartPoint Marker
        let address = Array.isArray(this.routeCoordinates[0] ?? "")
          ? await this.reverseCoordinatesToAddress(
              this.routeCoordinates[0][0],
              this.routeCoordinates[0][1]
            )
          : "";
        let text = this.$t("starting_point");

        if (this.isset(address)) {
          text += ": " + address;
        }

        new Marker({ color: "#0040FFFF" })
          .setLngLat(this.routeCoordinates[0])
          .setPopup(new Popup().setText(text.toString()))
          .addTo(map);
        // endpoint Marker Color Green

        let endCoordinates =
          this.routeCoordinates[this.routeCoordinates.length - 1];
        let endAddress = Array.isArray(this.routeCoordinates[0] ?? "")
          ? await this.reverseCoordinatesToAddress(
              endCoordinates[0],
              endCoordinates[1]
            )
          : "";
        let endText = this.$t("end_point");

        if (this.isset(endAddress)) {
          endText += ": " + endAddress;
        }

        new Marker({ color: "#55ff00" })
          .setLngLat(this.routeCoordinates[this.routeCoordinates.length - 1])
          .setPopup(new Popup().setText(endText.toString()))
          .addTo(map);
      }, 1500);

      setTimeout(() => {
        map.addSource("route", {
          type: "geojson",
          data: this.geoJsonData,
        });

        map.addLayer({
          id: "my-route-layer",
          source: "route",
          type: "line",
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#6084eb",
            "line-width": 3,
          },
        });

        // const showPopup = (data, lngLat) => {
        //   let popupHtml = Object.keys(data).map(key => {
        //     return `<div class="popup-property-container">
        // 			<span class="popup-property-label">${key}: </span>
        //       <span class="popup-property-value">${data[key]}</span>
        //     </div>`
        //   }).join('');
        //
        //   popup.setLngLat(lngLat).setHTML(popupHtml).addTo(map);
        // }
        //
        // map.on('click', 'my-route-layer', (e) => {
        //   console.clear();
        //   e.preventDefault();
        //   showPopup({
        //     distance: `${e.features[0].properties.distance} m`,
        //     time: `${e.features[0].properties.time} s`
        //   }, e.lngLat);
        // })
      }, 2000);
    },
    getPositions() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${API_BASE_URL}/tracking-devices/${this.trackingDevice.id}/positions?device_id=${this.trackingDevice.id}&from=${this.dateRange.from}&to=${this.dateRange.to}`,
            this.formConfig
          )
          .finally(() => {})
          .then((response) => {
            this.positions = response.data?.positions ?? [];
            this.trips = response.data?.trips ?? [];
            this.events = response.data?.events ?? [];
            this.routeCoordinates = this.positions.map((p) => [
              p.longitude,
              p.latitude,
            ]);
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    reverseCoordinatesToAddress(longitude, latitude) {
      return new Promise((resolve) => {
        return axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          )
          .then(({ data }) => resolve(data.display_name))
          .catch(() => resolve(""));
      });
    },
    toKm(odometer) {
      return (odometer / 1000).toFixed(2) + " km";
    },
    updateRouteCoordinates(positions) {
      this.routeCoordinates = positions.map((p) => [p.longitude, p.latitude]);
      this.reloadMap(true);
      this.tripRendered = true;
    },
    renderSelectedDurationCoordinates() {
      this.routeCoordinates = this.positions.map((p) => [
        p.longitude,
        p.latitude,
      ]);
      this.reloadMap(true);
      this.tripRendered = false;
    },
    computeDateRange(determinant) {
      //  all date should be in Iso String
      if (determinant === "today") {
        return {
          from: moment().startOf("day").toISOString(),
          to: moment().endOf("day").toISOString(),
        };
      } else if (determinant === "yesterday") {
        return {
          from: moment().subtract(1, "days").startOf("day").toISOString(),
          to: moment().subtract(1, "days").endOf("day").toISOString(),
        };
      } else if (determinant === "this_week") {
        return {
          from: moment().startOf("week").toISOString(),
          to: moment().endOf("week").toISOString(),
        };
      } else if (determinant === "previous_week") {
        return {
          from: moment().subtract(1, "weeks").startOf("week").toISOString(),
          to: moment().subtract(1, "weeks").endOf("week").toISOString(),
        };
      } else if (determinant === "this_month") {
        return {
          from: moment().startOf("month").toISOString(),
          to: moment().endOf("month").toISOString(),
        };
      } else if (determinant === "previous_month") {
        return {
          from: moment().subtract(1, "months").startOf("month").toISOString(),
          to: moment().subtract(1, "months").endOf("month").toISOString(),
        };
      } else if (determinant === "custom") {
        return {
          from: moment(this.fromDate).startOf("day").toISOString(),
          to: moment(this.toDate).endOf("day").toISOString(),
        };
      } else {
        return {
          from: moment().startOf("day").toISOString(),
          to: moment().endOf("day").toISOString(),
        };
      }
    },
    renderTrip(item, index) {
      this.updateRouteCoordinates(item.positions);
      this.tripRendered = "row_" + index;
    },
    removeRenderedTrip() {
      this.tripRendered = null;
      this.routeCoordinates = this.positions.map((p) => [
        p.longitude,
        p.latitude,
      ]);
      this.reloadMap();
    },
  },
};
</script>
<style type="text/css">
.floating-form {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;

  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  max-height: 98vh;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.floating-form::-webkit-scrollbar {
  display: none;
}

.bg-blue {
  background-color: #cfe2ff;
}

.stay-beside-side-bar {
  position: absolute;
  top: 10px;
  left: 270px;
  z-index: 999;
}
</style>
```
