<template>
  <v-card class="elevation-0">
    <!-- Title -->
    <v-row>
      <v-col cols="12" sm="12">
        <v-icon> mdi-account </v-icon>
        <span class="tyre_hotel_vehicle_heading">{{
          $t("tyre_hotel.vehicle.vehicle_and_driver")
        }}</span>
      </v-col>
    </v-row>
    <!-- Model -->
    <v-row class="ml-2">
      <v-col class="pa-1 pb-2" cols="12" sm="6">
        <v-autocomplete
          disabled
          :autocomplete="autocompleteValueLocal"
          v-model="brand"
          :items="brand ? [brand] : []"
          item-text="name"
          item-value="id"
          class="to-upper to-upper"
          :label="$t('billings.brand')"
          v-on:keydown="handleInput($event, 'justalphanumeric', 25)"
          v-on:keydown.enter.prevent=""
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
          return-object
        ></v-autocomplete>
      </v-col>
      <v-col class="pa-1 pb-2" cols="12" sm="6">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper to-upper"
          v-model="model"
          :label="$t('tyre_hotel.vehicle.model')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Chassie Number and brand -->
    <v-row class="ml-2">
      <v-col class="pa-1 pb-2" cols="12" sm="12">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          v-model="vin"
          :label="$t('tyre_hotel.vehicle.vin')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Registration and KM -->
    <v-row class="ml-2">
      <v-col cols="12" sm="1" v-if="registration_number">
        <v-icon class="pt-2 ml-n2" @click="editVehicle()">mdi-pencil</v-icon>
      </v-col>
      <v-col class="pa-1 pb-2" cols="12" :sm="registration_number ? 5 : 6">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          v-model="registration_number"
          :label="$t('tyre_hotel.vehicle.registration_number')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
      <v-col class="pa-1 pb-2" cols="12" sm="6">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          v-model="km"
          :label="$t('tyre_hotel.vehicle.km')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Driver -->
    <v-row class="ml-2">
      <v-col cols="12" v-if="driver" sm="1">
        <v-icon class="pt-2 ml-n2" @click="editDriver()">mdi-pencil</v-icon>
      </v-col>
      <v-col class="pa-1 pb-2" cols="12" :sm="driver ? 11 : 12">
        <v-autocomplete
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          v-model="driver"
          :items="driver ? [driver] : []"
          item-text="clientname"
          item-value="id"
          :label="$t('tyre_hotel.vehicle.driver')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <!-- Postal Code and Country -->
    <v-row class="ml-2">
      <v-col class="pa-1 pb-2" cols="12" sm="6">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          v-model="postal_code"
          :label="$t('tyre_hotel.vehicle.postal_code')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
      <v-col class="pa-1 pb-2" cols="12" sm="6">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          item-value="id"
          item-text="key"
          v-model="country"
          :label="$t('tyre_hotel.vehicle.country')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- city -->
    <v-row class="ml-2">
      <v-col class="pa-1 pb-2" cols="12" sm="12">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          v-model="city"
          :label="$t('tyre_hotel.vehicle.city')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Address -->
    <v-row class="ml-2">
      <v-col class="pa-1 pb-2" cols="12" sm="12">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          v-model="address"
          :label="$t('tyre_hotel.vehicle.address')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- GSM and SMS -->
    <v-row class="ml-2">
      <v-col class="pa-1 pb-2" cols="12" sm="6">
        <!-- <v-text-field  
                    disabled
                    :autocomplete="autocompleteValueLocal"
                    class="to-upper"
                    v-model="gsm"
                    :label="$t('tyre_hotel.vehicle.gsm')"
                    hide-details="auto"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field> -->
        <vue-tel-input-vuetify
          disabled
          :disabledFetchingCountry="true"
          :preferredCountries="preferredCountry"
          :filterCountries="onlyCountries"
          validCharactersOnly
          :label="$t('tyre_hotel.vehicle.gsm')"
          :inputOptions="{ showDialCode: true, tabindex: 0 }"
          :mode="'international'"
          v-model="gsm"
        ></vue-tel-input-vuetify>
      </v-col>
      <v-col class="pa-1 pb-2" cols="12" sm="6">
        <!-- <v-text-field  
                    disabled
                    :autocomplete="autocompleteValueLocal"
                    class="to-upper"
                    v-model="sms"
                    :label="$t('tyre_hotel.vehicle.sms')"
                    hide-details="auto"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field> -->
        <vue-tel-input-vuetify
          disabled
          :disabledFetchingCountry="true"
          :preferredCountries="preferredCountry"
          :filterCountries="onlyCountries"
          validCharactersOnly
          :label="$t('tyre_hotel.vehicle.sms')"
          :inputOptions="{ showDialCode: true, tabindex: 0 }"
          :mode="'international'"
          v-model="sms"
        ></vue-tel-input-vuetify>
      </v-col>
    </v-row>
    <!-- Email -->
    <v-row class="ml-2">
      <v-col class="pa-1 pb-2" cols="12" sm="12">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          class="to-upper"
          v-model="email"
          :label="$t('tyre_hotel.vehicle.email')"
          hide-details="auto"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import VueTelInputVuetify from "@/components/PhoneNumberInput";

export default {
  name: "VehicleDetail",
  props: {
    vehicle_detail: [Object],
  },
  components: {
    "vue-tel-input-vuetify": VueTelInputVuetify,
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      model: this.vehicle_detail.model,
      vin: this.vehicle_detail.vin,
      brand: this.vehicle_detail.brand,
      registration_number: this.vehicle_detail.registration_number,
      km: this.vehicle_detail.km,
      driver: this.vehicle_detail.driver,
      postal_code: null,
      country: null,
      city: null,
      address: null,
      gsm: this.vehicle_detail.client_gsm,
      sms: this.vehicle_detail.client_phone,
      email: this.vehicle_detail.client_email,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
    };
  },
  mounted() {
    this.header = { Authorization: "Bearer " + this.token };
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  watch: {
    driver(val) {
      if (val && typeof val == "object" && this.driver.name) {
        this.driver.clientname =
          this.driver.name + " " + (this.driver.first_name ?? "");
      }
    },
    vehicle_detail() {
      this.model = null;
      this.vin = null;
      this.brand = null;
      this.registration_number = null;
      this.km = null;
      this.driver = null;
      this.email = null;
      this.gsm = null;
      this.sms = null;
      this.address = null;
      this.postal_code = null;
      this.city = null;
      this.country = null;

      if (this.vehicle_detail) {
        this.model = this.vehicle_detail.model ?? null;
        this.vin = this.vehicle_detail.vin ?? null;
        this.brand = this.vehicle_detail.brand ?? null;
        this.registration_number =
          this.vehicle_detail.registration_number ?? null;
        this.km = this.vehicle_detail.km ?? null;
        this.driver = this.vehicle_detail.driver ?? null;
        this.email = this.vehicle_detail.client_id
          ? this.vehicle_detail.client_email ?? null
          : this.vehicle_detail.driver_id
          ? this.vehicle_detail.driver.client_email ?? null
          : null;
        this.gsm = this.vehicle_detail.client_id
          ? this.vehicle_detail.client_gsm ?? null
          : this.vehicle_detail.driver_id
          ? this.vehicle_detail.driver.client_gsm ?? null
          : null;
        this.sms = this.vehicle_detail.client_id
          ? this.vehicle_detail.client_phone ?? null
          : this.vehicle_detail.driver_id
          ? this.vehicle_detail.driver.client_phone ?? null
          : null;

        axios
          .get(
            API_BASE_URL +
              "/client_addresses/" +
              this.vehicle_detail.client_id +
              "?default=true",
            { headers: this.header }
          )
          .then((data) => {
            if (data.data.data.length > 0) {
              this.address = data.data.data[0].address_id
                ? data.data.data[0].address.street
                : null;
              this.postal_code = data.data.data[0].address_id
                ? data.data.data[0].address.zip
                : null;
              this.city = data.data.data[0].city
                ? data.data.data[0].city.name
                : null;
              this.country = data.data.data[0].city
                ? data.data.data[0].city.country_id
                : null;
            }
          })
          .catch(() => {})
          .finally(() => {});
      }
    },
  },
  methods: {
    editVehicle() {
      if (this.vehicle_detail.id) {
        this.$store.commit("seteditId", this.vehicle_detail.id);
        this.$router.push("/vehicles/edit").catch(() => {});
      }
    },
    editDriver() {
      if (this.vehicle_detail.client_id) {
        this.$store.commit("seteditId", this.vehicle_detail.client_id);
        this.$router.push("/client/edit").catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.tyre_hotel_vehicle_heading {
  font-size: 18px;
}
</style>
