<template>
  <main>
    <v-data-table
      :key="$store.state.forceReloadComponentKey"
      :headers="headers"
      :hide-default-header="true"
      :items="formattedItems"
      :options="{
        itemsPerPage: perPage,
        itemsPerPageOptions: perPageOptions,
        page: page,
      }"
      height="calc(-130px + 100vh)"
      fixed-header
      :footer-props="{
        'items-per-page-options': perPageOptions,
      }"
      :server-items-length="totalItems"
      @update:page="
        (newPage) => {
          page = newPage;
          getCompanyCashRegisters();
        }
      "
      @update:items-per-page="
        (itemsPerPage) => {
          perPage = itemsPerPage;
          getCompanyCashRegisters();
        }
      "
      class="custom-table"
    >
      <template #header="{ props: { headers } }">
        <thead class="v-data-table-header">
          <tr>
            <th rowspan="2" style="text-align: center; vertical-align: middle">
              {{ $t("billings.date") }}
            </th>
            <th rowspan="2" style="text-align: center; vertical-align: middle">
              {{ $t("cash_register_section.cash_counted") }}
            </th>
            <th
              colspan="8"
              class="text-center"
              style="
                font-size: 30px;
                border-left: 1px solid #d3d3d3;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ $t("cash_register_section.sales_for_day") }}
              <!--           Add  sub rows for the 6 rows -->
            </th>
            <th rowspan="2" style="text-align: center; vertical-align: middle">
              {{ $t("cash_register_section.total") }}
            </th>
            <th rowspan="2" style="text-align: center; vertical-align: middle">
              {{ $t("billings.statuses.waiting") }}
            </th>
            <th rowspan="2" style="text-align: center; vertical-align: middle">
              {{ $t("expeditions.created_by") }}
            </th>
          </tr>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              :class="{
                'text-left': true,
                'pr-4': true,
                'pl-4': true,
                'pt-2': true,
                'pb-2': true,
                'text-capitalize': true,
                [header.align]: true,
              }"
              :style="{
                backgroundColor: 'RGB(235, 233, 223)',
              }"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template #item="{ item }">
        <tr
          @mouseenter="setHovered('cash', item.id)"
          @mouseleave="setHovered(null, null)"
        >
          <td style="text-align: center; border: 1px solid #e3e3e3">
            {{ formatDateTime(item.creation_date_time) }}
          </td>
          <td
            :style="{
              background: item.hasDifference
                ? $store.state.colorCodes.danger
                : '',
            }"
          >
            {{ parseFloat(item.cash_counted).toFixed(2) }}
            <v-icon
              v-if="isHovered('cash', item.id)"
              class="ml-2 cursor-pointer ml-auto"
              @click="launchCashRegisterModal(item)"
              >mdi-open-in-new
            </v-icon>
          </td>
          <!--          <td>{{ item.total_cash_counted }}</td>-->

          <td
            style="text-align: end"
            v-for="type in paymentTypes"
            :key="type.id"
          >
            {{ parseFloat(item.order_payments[type.key] || 0.0).toFixed(2) }}
          </td>
          <td style="text-align: end">
            {{ parseFloat(item.total).toFixed(2) }}
          </td>
          <td style="text-align: end">
            {{ parseFloat(item.waiting).toFixed(2) }}
          </td>
          <td style="text-align: center">{{ item.user_name }}</td>
        </tr>
      </template>
    </v-data-table>
    <span
      @click="launchCashRegisterModal(null)"
      id="cash-register-modal"
      class="d-none"
      >...</span
    >
    <CashRegisterModal
      v-if="showCashRegisterModal"
      v-model="showCashRegisterModal"
      @cash="(cr) => hydrateData(cr)"
      :cash-register.sync="cashRegister"
    />
  </main>
</template>
<script>
import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import _axios from "@/plugins/axios";
import CashRegisterModal from "@/components/CashRegister/CashRegisterModal.vue";
import confirmationModelMixin from "@/mixins/ConfirmationModelMixin";
import moment from "moment";
import dateHelper from "@/dateHelper";
import addressBookMixin from "@/mixins/addressBookMixin";

export default {
  name: "CashRegisterListing",
  mixins: [confirmationModelMixin, dateHelper, addressBookMixin],
  components: { CashRegisterModal },
  data() {
    return {
      cashRegisters: [],
      formattedItems: [],
      paymentTypes: [],
      cashRegister: null,
      page: 1,
      perPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      totalItems: 0,
      perPageOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      showCashRegisterModal: false,
      hoveredCol: null,
      hoveredRow: null,
    };
  },
  computed: {
    moment() {
      return moment;
    },
    headers() {
      return this.paymentTypes.map((paymentType) => {
        return {
          text: this.$t(`billings.payment_methods.${paymentType.key}`),
          value: paymentType.code,
          sortable: false,
        };
      });
    },
  },
  async mounted() {
    this.paymentTypes = await this.getCodes("payment_types");
    await this.getCompanyCashRegisters();
  },
  activated() {
    this.showCashRegisterModal = false;
  },
  methods: {
    async getCompanyCashRegisters() {
      _axios
        .get(API_BASE_URL + "/company_cash_register", {
          params: {
            page: this.page,
            per_page: this.perPage,
          },
        })
        .then((response) => {
          this.cashRegisters = response.data.data;
          this.totalItems = response.data.total;
          this.formatItems();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    launchCashRegisterModal(cashRegister = null) {
      this.cashRegister = cashRegister;
      this.showCashRegisterModal = false;

      this.$nextTick(() => {
        this.showCashRegisterModal = true;
      });
    },
    setHovered(col, row) {
      this.hoveredCol = col;
      this.hoveredRow = row;
    },
    isHovered(col, row) {
      return this.hoveredCol === col && this.hoveredRow === row;
    },
    formatItems() {
      return (this.formattedItems = this.cashRegisters.map((item) => {
        let totalCashCountedForDay = this.cashRegisters
          .filter((i) => {
            return (
              moment(i.creation_date_time).format("YYYY-MM-DD") ===
              moment(item.creation_date_time).format("YYYY-MM-DD")
            );
          })
          .reduce((acc, i) => {
            return acc + i.cash_counted;
          }, 0);

        const cash_difference = totalCashCountedForDay - item.cash;
        const differenceIsPositive = cash_difference > 0;
        return {
          ...item,
          total_cash_counted: totalCashCountedForDay,
          cash_difference: cash_difference,
          differenceIsPositive: differenceIsPositive,
          hasDifference: cash_difference !== 0,
        };
      }));
    },
    hydrateData(cashRegister) {
      let index = this.cashRegisters.findIndex(
        (item) => item.id === cashRegister.id
      );
      if (index !== -1) {
        this.cashRegisters[index] = cashRegister;
      } else {
        this.cashRegisters.unshift(cashRegister);
      }

      this.formatItems();
    },
  },
};
</script>
<style scoped>
.red-bg {
  background: #f9d7da !important;
}

.red-bg:hover {
  background: #f49b9b !important;
}

.custom-table thead th,
td {
  border: 1px solid #e3e3e3;
}
</style>
