<template>
  <v-dialog
    v-model="popUpModelVisible"
    width="65%"
    @click:outside="closePopUpModel()"
    @keydown.esc="closePopUpModel()"
  >
    <v-card>
      <v-card-title>
        <v-row class="mt-1 mb-1" justify="space-between">
          <span class="text-h5"> {{ $t("cart.heading") }}</span>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-data-table
            fixed-header
            :footer-props="{
              'items-per-page-options': dataTableOptions,
            }"
            :hide-default-footer="true"
            :headers="headers"
            :items="articles"
            :options="options"
            :server-items-length="totalItems"
            class="elevation-0"
            :items-per-page="2000"
            item-key="identifier"
            height="700px"
            style="width: 100%"
          >
            <template v-slot:item="{ item }">
              <tr>
                <!-- Supplier -->
                <td>
                  <span class="text-muted">{{ getSlotChosen(item) }}</span>
                </td>
                <!-- Article -->
                <td>
                  {{ item.article.description }}<br />
                  <span class="text-muted">
                    <TextComponentWithExtraIcons
                      :value="item.article.identifier"
                    ></TextComponentWithExtraIcons>
                  </span>
                </td>
                <!-- Client Price for 1 -->
                <td align="right">
                  {{ fixDecimal(getClientPriceForOneWithoutWorkIfAny(item)) }}
                </td>
                <!-- Work Price for 1 -->
                <td align="right">
                  {{ fixDecimal(fixDecimal(getWorkPriceForOne(item))) }}
                </td>
                <!-- Quantity -->
                <td align="right">
                  <v-text-field
                    class="right-aligned-input"
                    v-model="item.quantity"
                    v-on:keydown="handleInput($event, 'int', 6)"
                    style="max-width: 50px; min-width: 50px"
                  ></v-text-field>
                </td>
                <!-- Total -->
                <td align="right">
                  {{ fixDecimal(getClientPriceTotalAfterAllCalculation(item)) }}
                </td>
                <!-- Actions -->
                <td align="right">
                  <v-icon
                    v-if="item"
                    color="error"
                    class="mr-1"
                    @click="deleteItemConfirmation(item)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="text-white-color"
          color="error"
          @click="deleteAllSelectedArticlePendingAppointmentAfterConfirmation()"
          v-if="
            selectedArticlePendingAppointment &&
            selectedArticlePendingAppointment.length > 0
          "
        >
          {{ $t("cart.remove_button") }}
          <v-icon dark right> mdi-delete </v-icon>
        </v-btn>
        <v-btn
          id="top-pending-article"
          class="ma-2 text-white-color"
          color="warning"
          @click="moveToSchedulerListing()"
          v-if="
            selectedArticlePendingAppointment &&
            selectedArticlePendingAppointment.length > 0
          "
        >
          {{ $t("nav_buttons.add_article_to_appointments") }} ({{
            selectedArticlePendingAppointment.length
          }})
          <v-icon dark right> mdi-calendar-import</v-icon>
        </v-btn>
        <v-btn color="grey-lighten-5" @click="closePopUpModel()">
          {{ $t("close") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <!-- <v-btn color="green" dark @click="SavePopUpModel()">
                    {{ $t('receptions.modify_article.save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn> -->
      </v-card-actions>
    </v-card>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  mixins: [validationMixin],
  validations: {},
  props: {
    data: [Object],
  },
  components: {
    ConfirmationModel,
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      popUpModelVisible: true,
      autocompleteValueLocal: "off",
      savedReception: false,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      options: {},
      articles: [],

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.articles = this.$store.state.selectedArticlePendingAppointment;
  },
  methods: {
    deleteItemConfirmation(data) {
      this.confirmationDialogData = data;
      this.confirmationDialogConfirmationText = this.$t(
        "cart.delete_selected_article"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t("attachment.delete");
      this.confirmationDialogOperation = "delete_item";
      this.confirmationDialog = true;
    },
    deleteItem(item) {
      let articles = this.$store.state.selectedArticlePendingAppointment;
      if (articles && articles.length > 0) {
        let filtered = articles.filter(function (el) {
          return el?.article?.id !== item?.article?.id;
        });
        this.$store.commit("setSelectedArticlePendingAppointment", filtered);
        this.$nextTick(() => {
          this.articles = filtered;
        });
      }
    },
    moveToSchedulerListing() {
      this.$router.push("/scheduler").catch(() => {});
    },
    deleteAllSelectedArticlePendingAppointmentAfterConfirmation(data = "") {
      this.confirmationDialogData = data;
      this.confirmationDialogConfirmationText = this.$t(
        "cart.delete_all_selected_article_from_shopping_list_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t("attachment.delete");
      this.confirmationDialogOperation = "delete_all";
      this.confirmationDialog = true;
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    getSlotChosen(item) {
      // This returns the supplier
      if (
        item.slot &&
        item.slot.supplier_detail &&
        item.slot.supplier_detail.identifier
      ) {
        return item.slot.supplier_detail.identifier;
      } else if (item && !item.slot) {
        return "INT";
      }
      return;
    },
    getClientPriceForOne(item) {
      // This returns the supplier
      if (
        item.slot &&
        item.article &&
        item.slot &&
        item.slot.field_price &&
        item.slot.supplier_detail &&
        item.slot.supplier_detail.identifier &&
        item.article
      ) {
        let slot_field_price =
          (item.slot.supplier_detail.identifier == "BRAND"
            ? "brand_price"
            : item.slot.supplier_detail.identifier.toLowerCase()) +
          "_client_price";
        if (item.slot.field_price.toLowerCase() == "brand_price") {
          slot_field_price = "brand" + "_client_price";
        }
        return this.fixDecimal(item.article[`${slot_field_price}`]);
      } else if (item && item.article && !item.slot) {
        return this.fixDecimal(item.article.family_client_price);
      }
      return this.fixDecimal(0);
    },
    getClientPriceForOneWithoutWorkIfAny(item) {
      // Without Montage
      return this.fixDecimal(
        this.getClientPriceForOne(item) - this.getWorkPriceForOne(item)
      );
    },
    getWorkPriceForOne(item) {
      // This returns the supplier
      if (
        item.slot &&
        item.article &&
        item.slot &&
        item.slot.supplier_detail &&
        item.slot.supplier_detail.identifier &&
        item.article
      ) {
        let slot_field_price =
          (item.slot.supplier_detail.identifier == "BRAND"
            ? "brand_price"
            : item.slot.supplier_detail.identifier.toLowerCase()) +
          "_work_price";
        if (item.slot.field_price.toLowerCase() == "brand_price") {
          slot_field_price = "brand" + "_work_price";
        }
        return this.fixDecimal(item.article[`${slot_field_price}`]);
      } else if (item && item.article && !item.slot) {
        return this.fixDecimal(item.article.family_work_price);
      }
      return this.fixDecimal(0);
    },
    getClientPriceTotalAfterAllCalculation(item) {
      let price = this.getClientPriceForOne(item);
      return price * item.quantity;
    },
    clearPendingArticles() {
      this.$store.commit("setSelectedArticlePendingAppointment", null);
      this.articles = [];
    },
    closePopUpModel() {
      this.popUpModelVisible = false;
    },
  },
  computed: {
    ...mapState(["selectedArticlePendingAppointment"]),
    headers() {
      return [
        {
          text: "",
          value: "supplier",
          sortable: false,
          width: "1%",
          align: "end",
        },
        {
          text: this.$t("cart.article"),
          value: "article",
          sortable: false,
          width: "50%",
          align: "start",
        },
        {
          text: this.$t("cart.client_price_for_1"),
          value: "client_price",
          sortable: false,
          width: "10%",
          align: "end",
        },
        {
          text: this.$t("cart.fitting_price_for_1"),
          value: "fitting_price",
          sortable: false,
          width: "10%",
          align: "end",
        },
        {
          text: this.$t("cart.quantity"),
          value: "quantity",
          sortable: false,
          width: "10%",
          align: "end",
        },
        {
          text: this.$t("cart.total"),
          value: "total",
          sortable: false,
          width: "10%",
          align: "end",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "10%",
          align: "end",
        },
      ];
    },
    totalItems() {
      if (this.articles) {
        return this.articles.length;
      }
      return 0;
    },
  },
  watch: {
    popUpModelVisible(val) {
      this.$emit("change-popup-model-visibility", val);
    },
    data: {
      handler: function () {},
      immediate: true,
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
        if (this.confirmationDialogOperation == "delete_all") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.clearPendingArticles();
          }
        }
        if (this.confirmationDialogOperation == "delete_item") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.deleteItem(this.confirmationDialogData);
          }
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
};
</script>
<style scoped>
.right-aligned-input >>> input {
  text-align: right;
}
</style>
