<template>
  <v-row>
    <v-data-table
      fixed-header
      :footer-props="{
        'items-per-page-options': dataTableOptions,
      }"
      :headers="headers"
      :hide-default-header="true"
      :items="rec_items"
      :server-items-length="totalItems"
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :sort-by="sortBy"
      :sort-desc.sync="sortDesc"
      :expanded.sync="expanded"
      show-expand
      :loading-text="this.$t('loading')"
      @update:page="updatePagination"
      @update:items-per-page="updateItemsPerPage"
      class="elevation-0"
      item-key="id"
      :height="'calc(-212px + 100vh)'"
      style="width: 100%"
      @update:sort-by="updateSort"
      @update:sort-desc="updateSortOrder"
      :key="forceReloadComponentKey"
    >
      <template #header="{ props: { headers } }">
        <thead class="v-data-table-header">
          <tr>
            <template v-for="header in headers">
              <th
                :key="header.value"
                scope="col"
                :class="header.align == 'start' ? 'text-left' : 'text-right'"
                :style="getHeaderWidth(header, header.width)"
              >
                <template v-if="header.value == 'actions'">
                  <v-row justify="space-between">
                    <v-col class="d-flex dontbreakline" cols="12" sm="2">
                      {{ header.text }}
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  {{ header.text }}
                </template>
              </th>
            </template>
          </tr>
        </thead>
      </template>
      <!-- Filters -->
      <template v-slot:top>
        <v-toolbar flat class="pt-4">
          <v-col class="d-flex" cols="12" sm="12">
            <!-- Reception Date -->
            <!-- <v-menu
              v-model="menu_rec_date"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="rec_date"
                  :label="$t('receptions.rec_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:append="rec_date = null"
                  append-icon="mdi-close"
                  class="mr-2 to-upper"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="rec_date"
                @input="menu_rec_date = false"
                no-title
                scrollable
                :locale="userLanguageId"
              ></v-date-picker>
            </v-menu> -->
            <DateInput
                  v-model="rec_date"
                  :label="$t('receptions.rec_date')"
                  :clearable="true"
                  :classData="'mr-2 to-upper'"
            />
            <!-- Suppliers -->
            <v-autocomplete
              flat
              :items="suppliers"
              v-model="supplier"
              :label="$t('receptions.supplier')"
              class="mr-2 to-upper"
              item-value="id"
              item-text="name"
              @click:append="supplier = null"
              append-icon="mdi-close"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>

            <!-- Delivery Note Reference -->
            <v-text-field
              class="mr-3 to-upper"
              :label="$t('receptions.reference')"
              v-bind:clearable="true"
              clear-icon="mdi-close"
              v-model="search_ref"
              @focus="reference = null"
              @input="debounceInput"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <!-- Data Items -->
      <template v-slot:item="{ item }">
        <tr :class="setRowColorClass(item)">
          <!-- Supplier -->
          <td @click="editItem(item)">
            <template v-if="item.supplier">
              {{ item.supplier.identifier }}
            </template>
            <template v-else-if="item.supplier_id == 0"> INT </template>
          </td>
          <!-- Reception Date` -->
          <td @click="editItem(item)">
            {{ formatDateTime(item.reception_date) }}
          </td>
          <!-- Reference -->
          <td @click="editItem(item)">
            {{
              (item.delivery_note_reference
                ? item.delivery_note_reference
                : ""
              ).toUpperCase()
            }}
          </td>
          <!-- Total Scanned Quantity -->
          <td @click="editItem(item)">
            {{ computeScannedQuantity(item) }}
          </td>
          <!-- User -->
          <td @click="editItem(item)">
            <!-- <template v-if="item.user_id && item.user">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                        item.user.short_name
                                    }}</span>
                                </template>
                                <span>{{ item.user.name }}</span>
                            </v-tooltip>
                        </template> -->
            <template v-if="item.user_id && item.user">
              <span>{{ item.user.name }}</span>
            </template>
          </td>
          <!-- Status -->
          <td @click="editItem(item)">
            <v-icon v-if="item.is_injected == 1" color="green">
              mdi-check
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import DateInput from "@/components/DateInput.vue";

export default {
  name: "ReceptionListing",
  components: {
    DateInput
  },
  mounted() {
    //
    this.header = { Authorization: "Bearer " + this.token };
    this.fetchData(true);
    this.getSuppliers();
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "id",
      sortOrd: "DESC",
      sortDesc: true,
      options: {},
      pagination: {},
      token: this.$store.state.AccessToken,
      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      status: null,
      statuses: [],
      suppliers: [],
      supplier: null,
      searchSupplier: null,
      menu_rec_date: false,
      rec_date: null,
      ordered_date: null,
      search_ref: null,
      rec_items: [],
      isLoading: false,
      expanded: [],
      isDebounceCancelled: false,
      showPopUp: false,
      original_statuses: [],
      editData: null,
      manualSelectedItem: null,
    };
  },
  methods: {
    computeScannedQuantity(item) {
      try {
        let scannedData = JSON.parse(item.scan_data);
        if (Array.isArray(scannedData)) {
          let totalReceived = 0;
          scannedData.forEach((element) => {
            totalReceived += element.received ? parseInt(element.received) : 0;
          });
          return totalReceived;
        }
        return 0;
      } catch (ex) {
        console.error("Error while parsing JSON of scanned quantity");
        return 0;
      }
    },
    editItem(item) {
      this.$store.commit("seteditId", item.id);
      this.$router.push("/receptions/verify/edit").catch(() => {});
    },
    setRowColorClass(item) {
      let className = "";
      if (item.is_injected) {
        className = "greenColor";
      }

      return className;
    },
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      console.log("this input");
    }, 500),
    changeDialog() {
      this.dialog = false;
    },
    checkIfReceived(reception) {
      let received_quantity = 0;
      if (reception.length > 0) {
        reception.forEach((element) => {
          received_quantity += element.received_quantity;
        });
      }
      return received_quantity;
    },
    fetchData() {
      axios
        .get(
          API_BASE_URL +
            "/receptions/scans?page=" +
            this.page +
            "&items_per_page=" +
            this.itemsPerPage +
            "&sortBy=" +
            this.sortBy +
            "&sortOrd=" +
            (this.sortOrd ? this.sortOrd : "") +
            (this.supplier ? "&supplier_id=" + this.supplier.id : "") +
            (this.search_ref ? "&reference=" + this.search_ref : "") +
            (this.rec_date ? "&reception_date=" + this.rec_date : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.rec_items = data.data;
          this.lastPage = data.last_page;
          this.page = data.current_page;
          this.totalItems = data.total;
          this.itemsPerPage = parseInt(data.per_page);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    showPopUpToChangeStatus() {
      this.showPopUp = true;
    },
    hidePopUpToChangeStatus() {
      this.hidePopUp = false;
    },
    showImage() {
      alert("THE IMAGE");
    },
    deleteItem() {},
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }

      this.fetchData();
    },
    getSuppliers() {
      this.suppliers = [];
      axios
        .get(
          API_BASE_URL +
            "/suppliers?internal_supplier=true&items_per_page=-1&parent_also=true" +
            (this.searchSupplier ? "&search=" + this.searchSupplier : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.suppliers = data.data;
        })
        .catch(function () {});
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("receptions.supplier"),
          align: "start",
          sortable: false,
          value: "id",
          width: "10%",
        },
        {
          text: this.$t("receptions.reception_date"),
          align: "start",
          sortable: false,
          value: "id",
          width: "20%",
        },
        {
          text: this.$t("receptions.reference"),
          align: "start",
          sortable: false,
          value: "id",
          width: "30%",
        },
        {
          text: this.$t("receptions.total_scanned_quantity"),
          align: "start",
          sortable: false,
          value: "id",
          width: "20%",
        },
        {
          text: this.$t("receptions.user"),
          align: "start",
          sortable: false,
          value: "id",
          width: "30%",
        },
        {
          text: this.$t("receptions.status"),
          align: "start",
          sortable: false,
          value: "id",
          width: "10%",
        },
        { text: "", value: "data-table-expand", width: "2%" },
      ];
    },
  },
  watch: {
    search_ref: _.debounce(function () {
      this.fetchData();
    }, 1000),
    rec_date: {
      handler: function () {
        this.fetchData();
      },
    },
    supplier: {
      handler: function () {
        this.fetchData();
      },
    },
    status: {
      handler: function () {
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped></style>
