<template>
  <v-row class="mt-4">
    <v-spacer></v-spacer>
    <template v-if="refreshDynamicContent">
      <v-btn
        :disabled="!data"
        id="attachments"
        class="ma-2 mr-6 text-white-color"
        color="success"
        @click="showAttachmentDialog()"
      >
        {{ $t("attachments") }} ({{
          $store.state.attachmentCounts["vehicles"]
            ? $store.state.attachmentCounts["vehicles"]
            : 0
        }})
        <v-icon dark right> mdi-paperclip </v-icon>
      </v-btn>
      <attachment-dialog
        v-if="attachmentDialog"
        :showDialog.sync="attachmentDialog"
        :data="data"
        :countKey="countKey"
        :resource="'vehicles'"
        :refresh.sync="refreshAttachment"
      ></attachment-dialog>
    </template>
    <v-data-table
      fixed-header
      :footer-props="{
        'items-per-page-options': dataTableOptions,
      }"
      :hide-default-footer="true"
      :headers="headers"
      :items="attachments"
      :options="options"
      :server-items-length="totalItems"
      :loading="isLoading"
      :loading-text="this.$t('loading')"
      class="elevation-0"
      :items-per-page="itemsPerPage"
      item-key="identifier"
      @update:page="updatePagination"
      @update:items-per-page="updateItemsPerPage"
      @click:row="editItem"
      height="400px"
      style="width: 100%"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:sort-by="updateSort"
      @update:sort-desc="updateSortOrder"
      mustSort
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-icon
              @click="openUrl(item)"
              v-if="item"
              :color="getItemIcon(item)"
              class="mr-1"
            >
              {{ getItemIconColor(item) }}
            </v-icon>
          </td>
          <td @click="editItem(item)">
            {{ item.registered_at }}
          </td>
          <td @click="editItem(item)">
            <template v-if="item.attachment_group_id && item.group">
              {{ item.group.group }}
            </template>
          </td>
          <td @click="editItem(item)">
            <template v-if="editModeItem && editModeItem.id == item.id">
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="item.title"
                class="to-upper"
                :label="$t('attachment.title_optional')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </template>
            <template v-else>
              {{ item.title }}
            </template>
          </td>
          <td @click="editItem(item)">
            <template v-if="editModeItem && editModeItem.id == item.id">
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="item.description"
                class="to-upper"
                :label="$t('attachment.description')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </template>
            <template v-else>
              {{ item.description ? item.description.substr(0, 20) : "" }}
            </template>
          </td>
          <td>
            <template v-if="editModeItem && editModeItem.id == item.id">
              <v-icon
                v-if="item"
                color="blue"
                class="mr-1"
                @click="cancelEdit()"
              >
                mdi-close
              </v-icon>
              <v-icon
                v-if="item"
                color="green"
                class="mr-1"
                @click="saveItem(item)"
              >
                mdi-content-save
              </v-icon>
            </template>
            <v-icon
              v-if="item"
              color="warning"
              class="mr-1"
              @click="deleteAttachmentAfterConfirmation(item.id)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";
import AttachmentDialog from "@/components/AttachmentDialog";

export default {
  name: "AttachmentListing",
  components: {
    ConfirmationModel,
    "attachment-dialog": AttachmentDialog,
  },
  props: {
    resource: [String],
    data: [Number, String, Object],
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "date",
          width: "10px",
        },
        {
          text: this.$t("attachment.date"),
          align: "start",
          sortable: true,
          value: "date",
        },
        {
          text: this.$t("attachment.group"),
          align: "start",
          sortable: false,
          value: "group",
        },
        {
          text: this.$t("attachment.title"),
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: this.$t("attachment.description"),
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("attachment.actions"),
          align: "start",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      attachmentDialog: false,
      refreshAttachment: false,
      countKey: "vehicles",
      refreshDynamicContent: true,
      sortBy: "description",
      sortDesc: false,
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isLoading: false,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      autocompleteValueLocal: "off",
      module_id: null,
      resource_id: null,
      editModeItem: null,
      attachments: [],

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,
    };
  },
  mounted() {
    let data = JSON.parse(localStorage.getItem("data"));
    let applicationResourceId = null;
    data.applicationResources.forEach((resource) => {
      if (resource.application_resource.key == this.resource) {
        applicationResourceId = resource.application_resource.id;
      }
    });
    data.attachmentModules.forEach((modules) => {
      if (modules.application_resource_id == applicationResourceId) {
        this.module_id = modules.id;
        this.resource_id = modules.application_resource_id;
      }
    });
    this.getAttachments();
  },
  methods: {
    openUrl(item) {
      // window.open(
      //     API_BASE_URL +
      //         "/attachments/file/" +
      //         `${item.resource_key_id}/${item.file_name}`,
      //     "_blank"
      // );
      // window.open(item.signed_url);
      let url = item.signed_url;
      let fileName = item.title;
      this.setPreviewData(url, fileName, true, item.mime_type);
    },
    showAttachmentDialog() {
      this.attachmentDialog = true;
    },
    deleteAttachmentAfterConfirmation(data) {
      this.confirmationDialogData = data;
      this.confirmationDialogConfirmationText = this.$t(
        "attachment.delete_attachment_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t("attachment.delete");
      this.confirmationDialogOperation = "delete_attachment";
      this.confirmationDialog = true;
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    getItemIcon(item) {
      if (item.mime_type.includes("video")) {
        return "orange";
      } else if (item.mime_type.includes("image")) {
        return "blue";
      } else if (item.mime_type.includes("audio")) {
        return "blue";
      } else {
        return "black";
      }
    },
    getItemIconColor(item) {
      if (item.mime_type.includes("video")) {
        return "mdi mdi-multimedia";
      } else if (item.mime_type.includes("image")) {
        return "mdi mdi-image";
      } else if (item.mime_type.includes("audio")) {
        return "mdi mdi-volume-high";
      } else {
        return "mdi mdi-file";
      }
    },
    deleteAttachment(id) {
      axios
        .delete(API_BASE_URL + "/attachments/" + id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(() => {
          this.refreshDynamicContent = false;
          let tempAttachments = [];
          this.attachments.forEach((attachment) => {
            if (attachment.id != id) {
              tempAttachments.push(attachment);
            }
          });
          this.attachments = tempAttachments;
          this.$nextTick(() => {
            this.refreshDynamicContent = true;
            let data =
              typeof this.$store.state.attachmentCounts == "number"
                ? []
                : this.$store.state.attachmentCounts ?? [];
            data[this.countKey ?? "data"] = this.attachments.length;
            this.$store.commit("setAttachmentCounts", data);
          });
        })
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.confirmationDialogData = null;
    },
    saveItem(item) {
      let data = {
        title: item.title ?? "",
        description: item.description ?? "",
      };
      axios
        .put(API_BASE_URL + "/attachments/" + item.id, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(() => {
          this.refreshAttachment = true;
        })
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.cancelEdit();
    },
    editItem(item) {
      this.editModeItem = item;
    },
    cancelEdit() {
      this.editModeItem = null;
    },
    getAttachments() {
      axios
        .get(
          API_BASE_URL +
            `/attachments?module_id=${this.module_id}&resource_key_id=${this.data}`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          }
        )
        .then(({ data }) => {
          this.attachments = data.data.result;
          this.totalItems = data.data.result.length;
        })
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetForm() {
      this.attachment = null;
      this.title = null;
      this.description = null;
    },
    addAttachments() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
        return false;
      } else {
        let formData = new FormData();
        formData.append("attachment", this.attachment);
        formData.append("module_id", this.module_id);
        formData.append("title", this.title ?? "");
        formData.append("description", this.description ?? "");
        formData.append("resource_key_id", this.data);
        axios
          .post(API_BASE_URL + "/attachments", formData, {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(({ data }) => {
            this.attachments.push(data.data);
            this.rows.push({}); // just to reflect the changes in array
            this.rows.splice(-1); // just to reflect the changes in array
            this.resetForm();
          })
          .catch((error) => {
            if (error?.response?.status == 413) {
                this.attchmentErrors = this.$t("very_large_file");
            }
            console.log("an error occured " + error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      this.$store.commit("setValidation", false); // disable validation
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy == undefined ? "id" : sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
      this.fetchData();
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
  },
  watch: {
    attachmentDialog() {
      this.getAttachments();
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
        if (this.confirmationDialogOperation == "delete_attachment") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.deleteAttachment(this.confirmationDialogData);
          }
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
      },
    },
  },
};
</script>
