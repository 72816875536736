<template>
  <v-card class="elevation-0">
    <v-text-field
      autocomplete="off"
      v-model="search"
      class="mx-2 mt-n4 to-upper text-xs-body-1"
      :placeholder="$t('tyre_hotel.vehicle.registration_number')"
      append-icon="mdi-close"
      v-on:keydown="handleInput($event, 'alphanumeric', 20)"
      v-on:keydown.enter.prevent=""
      @change="getClientVehicles()"
      @input="debounceInput"
      @click:append="
        search = null;
        getClientVehicles();
      "
    >
    </v-text-field>
    <v-card class="number-plate-area elevation-0">
      <v-list two-line>
        <v-list-item-group return-object active-class="selected_customer">
          <template v-for="(item, index) in vehicles">
            <v-list-item :key="item.id" @click="rentalHistory(item)">
              <v-list-item-title>
                <p class="text-xs mb-0">{{ item.registration_number }}</p>
              </v-list-item-title>
            </v-list-item>

            <v-divider
              v-if="index < vehicles.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-card>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import moment from "moment-timezone";

export default {
  name: "FilterRegistrationNumber",
  props: {
    vehicles: [Array],
  },
  data() {
    return {
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      search: null,
      isDebounceCancelled: false,
      isLoading: true,
      page: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "start_date",
      sortOrd: "ASC",
    };
  },
  mounted() {
    this.header = { Authorization: "Bearer " + this.token };
  },
  methods: {
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.getClientVehicles();
    }, 500),
    rentalHistory(item) {
      this.$store.commit("setVehicleId", item.id);
      this.fetchData();

      this.$emit("set_is_reload_component", false);

      this.$nextTick(() => {
        this.$emit("set_is_reload_component", true);
      });
    },
    fetchData() {
      this.isLoading = true;
      const payload = {
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortOrd,
      };
      axios({
        method: "get",
        url:
          API_BASE_URL +
          "/vehicle_rentals/history/" +
          this.$store.state.vehicleId,
        headers: this.header,
        params: payload,
      })
        .then((data) => {
          this.$store.state.vehicles = data.data.data;
          this.$store.state.vehicles.forEach((vehicle) => {
            vehicle.is_leasing = vehicle.is_leasing == 1 ? true : false;

            vehicle.is_active =
              moment(this.filterDateFormat(vehicle.start_date)).isBefore(
                this.today
              ) &&
              moment(this.filterDateFormat(this.today)).isBefore(
                this.filterDateFormat(vehicle.end_date)
              ); // true
          });
          this.totalItems = data.data.total;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          // this.$toast.success( this.$t('schedulestab.something_went_wrong_while_fetching_data'));
        });
    },
    getClientVehicles() {
      axios({
        method: "get",
        url:
          API_BASE_URL +
          "/vehicle_rentals?" +
          (this.search ? "search=" + this.search : ""),
        headers: this.header,
      })
        .then((data) => {
          this.$emit("set_vehicles", data.data.data);
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.number-plate-area {
  height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.selected_customer {
  background-color: rgb(230, 230, 230) !important;
}
</style>
