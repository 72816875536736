<template>
  <v-card class="ma-4 justify-center elevation-0 smooth-bg-color">
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.3.1/css/all.min.css"
      rel="stylesheet"
    />

    <v-row>
      <v-col cols="12" sm="12" align="left">
        <div class="container pt-5 pb-5">
          <div class="row">
            <div class="col-md-6">
              <div class="card" style="border: 0px !important">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <!-- <p class="mb-0 text-muted">{{ $t('dashboard_content.welcome') }} {{ $store.state.loginUser }}</p> -->
                          <h2>{{ $store.state.loginUser }}</h2>
                        </div>
                      </div>
                      <div class="m-t-40">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-items-center">
                            <span class="badge badge-primary badge-dot"></span>
                            <span
                              class="text-gray font-weight-semibold font-size-13"
                              >{{ $t("dashboard_content.tagline") }}</span
                            >
                          </div>
                        </div>
                        <div class="progress progress-sm w-100 m-b-0 mt-2">
                          <div
                            class="progress-bar bg-primary"
                            style="width: 70%"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <v-layout justify-center align-center>
                        <!-- Image Error -->
                        <v-flex v-if="!imageError" shrink>
                          <v-img
                            style="max-height: 350px; max-width: 350px"
                            :lazy-src="companyLogo"
                            :src="companyLogo"
                            v-on:error="onImgError"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-flex>
                        <v-flex v-if="imageError" shrink>
                          <v-img
                            style="max-height: 100px; max-width: 80px"
                            lazy-src="../assets/logo.png"
                            src="../assets/logo.png"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="(card, index) in cards">
              <v-col
                cols="12"
                sm="6"
                :key="index"
                v-if="
                  card &&
                  card.card_type_id &&
                  card.type &&
                  card.type.key == 'card'
                "
              >
                <template
                  v-if="
                    allReportsFetched == allReportsToBeFetched &&
                    card.is_visible
                  "
                >
                  <Card
                    :title="'Total Sales'"
                    :value="200"
                    :change="true"
                  ></Card>
                </template>
                <template v-else>
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                :key="index"
                v-if="
                  card &&
                  card.card_type_id &&
                  card.type &&
                  card.type.key == 'html_note'
                "
              >
                <template
                  v-if="
                    allReportsFetched == allReportsToBeFetched &&
                    card.is_visible
                  "
                >
                  <BasicNotesCard :card="card"></BasicNotesCard>
                </template>
                <template v-else>
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                :key="index"
                v-if="
                  card &&
                  card.card_type_id &&
                  card.type &&
                  card.type.key == 'system_messages'
                "
              >
                <template
                  v-if="
                    allReportsFetched == allReportsToBeFetched &&
                    card.is_visible
                  "
                >
                  <BasicSystemMessageCard
                    :carddata="card['report_raw_output_data']"
                    :cardfields="card['report_fields']"
                    :card="card"
                  ></BasicSystemMessageCard>
                </template>
                <template v-else>
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                :key="index"
                v-if="
                  card &&
                  card.card_type_id &&
                  card.type &&
                  card.type.key == 'listing'
                "
              >
                <template
                  v-if="
                    allReportsFetched == allReportsToBeFetched &&
                    card.is_visible
                  "
                >
                  <ListingCard
                    :carddata="card['report_raw_output_data']"
                    :cardfields="card['report_fields']"
                    :card="card"
                  ></ListingCard>
                </template>
                <template v-else>
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                :key="index"
                v-if="
                  card &&
                  card.card_type_id &&
                  card.type &&
                  card.type.key == 'line_graph'
                "
              >
                <template
                  v-if="
                    allReportsFetched == allReportsToBeFetched &&
                    card.is_visible
                  "
                >
                  <BasicLineGraph
                    :card="card"
                    :bardata="card['report_raw_output_data']"
                    :barfields="card['report_fields']"
                  ></BasicLineGraph>
                </template>
                <template v-else>
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                :key="index"
                v-if="
                  card &&
                  card.card_type_id &&
                  card.type &&
                  card.type.key == 'bar_graph'
                "
              >
                <template
                  v-if="
                    allReportsFetched == allReportsToBeFetched &&
                    card.is_visible
                  "
                >
                  <BasicBarGraph
                    :card="card"
                    :bardata="card['report_raw_output_data']"
                    :barfields="card['report_fields']"
                  >
                  </BasicBarGraph>
                </template>
                <template v-else>
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                :key="index"
                v-if="
                  card &&
                  card.card_type_id &&
                  card.type &&
                  card.type.key == 'pie_chart'
                "
              >
                <template
                  v-if="
                    allReportsFetched == allReportsToBeFetched &&
                    card.is_visible
                  "
                >
                  <BasicPieGraph
                    :card="card"
                    :bardata="card['report_raw_output_data']"
                    :barfields="card['report_fields']"
                  >
                  </BasicPieGraph>
                </template>
                <template v-else>
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                :key="index"
                v-if="
                  card &&
                  card.card_type_id &&
                  card.type &&
                  card.type.key == 'stacked_area_chart'
                "
              >
                <template
                  v-if="
                    allReportsFetched == allReportsToBeFetched &&
                    card.is_visible
                  "
                >
                  <BasicStackedAreaGraph
                    :card="card"
                    :bardata="card['report_raw_output_data']"
                    :barfields="card['report_fields']"
                  >
                  </BasicStackedAreaGraph>
                </template>
                <template v-else>
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </template>
              </v-col>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
import Card from "@/components/Dashboard/Card.vue";
import BasicBarGraph from "@/components/Dashboard/BasicBarGraph.vue";
import BasicLineGraph from "@/components/Dashboard/BasicLineGraph.vue";
import BasicPieGraph from "@/components/Dashboard/BasicPieGraph.vue";
import BasicNotesCard from "@/components/Dashboard/BasicNotesCard.vue";
import BasicSystemMessageCard from "@/components/Dashboard/BasicSystemMessageCard.vue";
import BasicStackedAreaGraph from "@/components/Dashboard/BasicStackedAreaGraph.vue";
import ListingCard from "@/components/Dashboard/ListingCard.vue";

export default {
  name: "Dashboard",
  components: {
    Card,
    BasicBarGraph,
    BasicLineGraph,
    BasicPieGraph,
    BasicNotesCard,
    BasicSystemMessageCard,
    BasicStackedAreaGraph,
    ListingCard,
  },
  data() {
    return {
      cards: [],
      cardsData: [],
      imageError: false,
      companyLogo: null,
      allReportsToBeFetched: 0,
      allReportsFetched: 0,
    };
  },
  methods: {
    changedNoteData() {
      //
    },
    onImgError() {
      this.imageError = true;
    },
    callCardReportData(cardDetail, index, userCardDetail) {
      if (cardDetail.report_id) {
        axios
          .get(API_BASE_URL + "/reports/" + cardDetail.report_id + "/run", {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(({ data }) => {
            this.$set(this.cards[index], "notes", userCardDetail.notes); // Add Notes to Card Detail
            this.$set(this.cards[index], "report_raw_output_data", data);
            this.$set(
              this.cards[index],
              "report_fields",
              cardDetail.report.fields
            );
            this.$set(this.cards[index], "is_visible", true); // Now Show Card
          })
          .catch(() => {
            // this.$toast.error(this.$t("stocks.something_went_wrong"));
          })
          .finally(() => {
            this.allReportsFetched = this.allReportsFetched + 1;
          });
      } else {
        this.$set(this.cards[index], "notes", userCardDetail.notes); // Add Notes to Card Detail
        // this.$set(this.cards[index], 'report_raw_output_data', data);
        // this.$set(this.cards[index], 'report_fields', cardDetail.report.fields);
        this.$set(this.cards[index], "is_visible", true); // Now Show Card

        this.allReportsFetched = this.allReportsFetched + 1;
      }
    },
  },
  watch: {},
  mounted() {
    let dashboardCards =
      JSON.parse(localStorage.getItem("dashboardCards")) ?? [];
    this.allReportsToBeFetched = dashboardCards.length;
    dashboardCards.forEach((element, index) => {
      this.cards.push(element.card.card);
      this.callCardReportData(element.card.card, index, element);
    });
    this.companyLogo = this.getCompanyPropertyFromLocalStorage("DASHBOARD_LOGO")
      ? this.getCompanyPropertyFromLocalStorage("DASHBOARD_LOGO").value
      : null;
    if (!this.companyLogo) {
      this.imageError = true;
    }
  },
};
</script>
<style scoped>
.chart {
  height: 400px;
}

.c-dashboardInfo {
  margin-bottom: 15px;
}

.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 25px 20px;
  height: 100%;
}

.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 1.18em;
}

.c-dashboardInfo span {
  display: block;
}

.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 2.5em;
  line-height: 64px;
  color: #323c43;
}

.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}

.c-dashboardInfo:nth-child(1) .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}

.c-dashboardInfo:nth-child(2) .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}

.c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.c-dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}

.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}

.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}

.card {
  position: relative;
  background-color: #fff;
  margin-bottom: 1.25rem;
  border: 1px solid #edf2f9;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.card-body {
  padding: 1.5rem;
  position: relative;
}

.card-body .h2 {
  font-size: 24px;
}

.badge-cyan {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
  border-color: transparent;
  font-weight: 500;
}

.font-size-13 {
  font-size: 13px !important;
}
</style>
