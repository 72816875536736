<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="2">
          <label class="logoImage ma-4">
            <img
              :src="logoImage"
              style="
                width: 150px;
                height: 150px;
                border-radius: 50%;
                border: 3px dotted #efefef;
              "
            />
            <input
              type="file"
              class="d-none"
              id="files"
              ref="files"
              multiple
              v-on:change="handleFilesUpload()"
            />
          </label>
        </v-col>
        <v-col cols="12" sm="10">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="name"
                :label="$t('users.name')"
                :error-messages="
                  $store.state.validation && !$v.name.required
                    ? [this.$t('users.text_messages.name_required')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
                class="to-upper"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                class="to-upper"
                :disabled="true"
                :autocomplete="autocompleteValueLocal"
                v-model="email"
                :label="$t('users.email')"
                :error-messages="
                  $store.state.validation
                    ? returnErrorMessage(
                        [
                          !$v.email.required,
                          this.$t('users.text_messages.email_required'),
                        ],
                        [
                          !$v.email.email,
                          this.$t('users.text_messages.invalid_email'),
                        ]
                      )
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="password"
                :label="$t('users.password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="
                  $store.state.validation
                    ? returnErrorMessage(
                        [
                          !$v.password.required,
                          this.$t('users.text_messages.password_required'),
                        ],
                        [
                          !$v.password.minLength,
                          this.$t('users.text_messages.password_min_length'),
                        ]
                      )
                    : []
                "
                @click:append="showPassword = !showPassword"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                :autocomplete="autocompleteValueLocal"
                v-model="language"
                :items="languages"
                item-value="id"
                item-text="text"
                :label="$t('users.language')"
                :error-messages="
                  $store.state.validation && !$v.language.required
                    ? [this.$t('users.text_messages.language_required')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0 ml-2">
          <strong>{{ $t("email_signature") }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="email_signature_profile">
          <NoteEditor
            :label="$t('email_signature')"
            v-model="email_signature"
            :showLabel="false"
            :rows="5"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-btn @click="onSubmit()" class="d-none" id="submit-user-profile"></v-btn>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  minLength,
  email,
} from "vuelidate/lib/validators";
import { API_BASE_URL } from "@/config";
import { UPDATE_MODE } from "@/config";
import NoteEditor from "@/components/NoteEditor.vue";

export default {
  mixins: [validationMixin],
  components: { NoteEditor },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email: (val) => email((val ?? "").toLowerCase()),
    },
    password: {
      required: requiredIf(function () {
        return !this.currentUser.id;
      }),
      minLength: minLength(8),
    },
    // confirmPassword:{
    //   required: requiredIf(function (model) {
    //     return !this.$store.state.editId;
    //   }),
    //   sameAs: sameAs('password')
    // },
    language: {
      required,
    },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      logoImage: null,
      roles: [],
      role: {},
      languages: [],
      language: {},
      name: null,
      email: null,
      showPassword: false,
      showConfirmPassword: false,
      password: null,
      confirmPassword: null,
      active: true,
      approve: false,
      DeleteConfirm: false,
      token: this.$store.state.AccessToken,
      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      autocompleteValueLocal: "off",
      email_signature: null,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.$store.commit("setFormMode", UPDATE_MODE);
    this.fetchData();
    this.getLanguages();
  },
  methods: {
    handleFilesUpload() {
      if (!this.$refs.files.files[0]) {
        return;
      }
      let module_id = this.getModuleId("user_profile"); // Company_banks as in Application Resource
      if (module_id && this.currentUser.id) {
        let formData = new FormData();
        formData.append("attachment", this.$refs.files.files[0]);
        formData.append("module_id", module_id);
        formData.append("title", "PROFILE_PHOTO");
        formData.append("group", this.group ?? "");
        formData.append("description", "PROFILE_PHOTO");
        formData.append("resource_key_id", this.currentUser.id);
        axios
          .post(API_BASE_URL + "/attachments", formData, {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(({ data }) => {
            console.log("console___log", data.data.id);
            // Update profile image
            this.logoImage = data.data.public_url;
            this.updateProfileImageAtOtherPlaces();

            // Now update user api
            let userUpdateData = {
              profile_image_id: data.data.id,
              is_partial_update: 1,
            };
            axios
              .put(
                API_BASE_URL + "/users/" + this.currentUser.id,
                userUpdateData,
                {
                  headers: this.header,
                }
              )
              .then(() => {
                this.$toast.success(this.$t("company_settings.image_uploaded"));
              })
              .catch(() => {
                this.$toast.error(
                  this.$t("company_settings.image_format_error")
                );
              })
              .finally(() => {});
          })
          .finally(() => {});
      }
    },
    updateProfileImageAtOtherPlaces() {
      let userData = JSON.parse(localStorage.getItem("data"));
      userData.profile_image = this.logoImage;
      localStorage.setItem("data", JSON.stringify(userData));
    },
    getLanguages() {
      this.getDataWithCache("/languages", "languages", "languages", true);
    },
    fetchData() {
      axios
        .get(API_BASE_URL + "/users/" + this.currentUser.id, {
          headers: this.header,
        })
        .then((res) => {
          this.name = res.data.data.name;
          this.email = res.data.data.email;
          this.language = res.data.data.language_id;
          this.email_signature = res.data.data.email_signature;
          this.logoImage = res.data.data?.profile_image;
        })
        .catch(() => {})
        .finally(() => {});
    },
    editUser(data) {
      axios
        .put(API_BASE_URL + "/users/" + this.currentUser.id, data, {
          headers: this.header,
        })
        .then(() => {
          this.$toast.success(
            this.$t("users.text_messages.profile_updated_successfully")
          );
        })
        .catch(() => {})
        .finally(() => {});
    },
    makeSubmitData() {
      let payload = {
        name: this.name,
        password: this.password,
        // confirm_password: this.confirmPassword,
        language_id: this.language,
        email_signature: this.email_signature,
        is_partial_update: 1,
      };

      if (!this.password) {
        delete payload.password;
        // delete payload.confirm_password;
      }
      return payload;
    },
    onSubmit() {
      this.$store.commit("setValidation", true);
      if (this.$v.$invalid) {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
      } else {
        this.editUser(this.makeSubmitData()); // remove password property if random password is equal to password
      }
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
#email_signature_profile >>> .editr {
  min-height: 280px !important;
  max-height: 280px !important;
}

#email_signature_profile >>> .editr--content {
  min-height: 240px !important;
  max-height: 240px !important;
  overflow-y: scroll !important;
}
</style>
