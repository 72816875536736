<template>
  <v-dialog
    v-model="popUpModelVisible"
    max-width="1200"
    @click:outside="closePopUpModel()"
    @keydown.esc="closePopUpModel()"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ $t("receptions.modify_receptions") }} -
          {{ data.supplier_order_details.article.original_name }}</span
        >
      </v-card-title>
      <v-card-text>
        <confirmation-model
          v-if="confirmationDialog"
          :showDialog.sync="confirmationDialog"
          :openedForOperation.sync="confirmationDialogOperation"
          :text.sync="confirmationDialogConfirmationText"
          :trueText.sync="confirmationDialogTrueText"
          :falseText.sync="confirmationDialogFalseText"
          @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
        <v-row>
          <span> </span>
          <!-- Supplier -->
          <v-col cols="12" sm="2">
            <v-autocomplete
              :disabled="!allowedStatusesToBChanged.includes(data.status.key)"
              flat
              :items="allSuppliers"
              v-model="data.supplier"
              :label="$t('receptions.supplier')"
              class="mr-2 to-upper"
              item-value="id"
              item-text="name"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>
          </v-col>
          <!-- Ordered Quantity -->
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="data.supplier_order_details.ordered_quantity"
              :label="$t('receptions.ordered_quantity')"
              v-bind="attrs"
              v-on="on"
              @input="
                data.supplier_order_details.confirmed_quantity >=
                data.supplier_order_details.ordered_quantity
                  ? setReceptionStatusToStatus('successfully_ordered')
                  : ''
              "
              :disabled="ifDisableReceptionStatus()"
              class="mr-2 to-upper"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <!-- Confirmed Quantity -->
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="data.supplier_order_details.confirmed_quantity"
              :label="$t('receptions.confirmed_quant_label')"
              v-bind="attrs"
              v-on="on"
              :disabled="ifDisableReceptionStatus()"
              @input="
                data.supplier_order_details.confirmed_quantity >=
                data.supplier_order_details.ordered_quantity
                  ? setReceptionStatusToStatus('successfully_ordered')
                  : ''
              "
              class="mr-2 to-upper"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <!-- Already Received Quantity -->
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="already_received_quantity"
              :label="$t('receptions.already_received_quant_label')"
              disabled
              @input="checkQuantityToChangeStatus()"
              v-bind="attrs"
              v-on="on"
              class="mr-2 to-upper"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <!-- Received Quantity -->
          <v-col cols="12" sm="2">
            <v-text-field
              :error-messages="
                isReceivedQuantityGreaterThen
                  ? [
                      this.$t(
                        'receptions.text_messages.received_quantity_cannot_be_greater_then_confirmed_quantity'
                      ),
                    ]
                  : []
              "
              v-model="data.supplier_order_details.received_quantity"
              :label="$t('receptions.received_quant_label')"
              :disabled="
                !isAllowedToChangeReceivedQuantity.includes(rec_status.key)
              "
              @input="checkQuantityToChangeStatus()"
              v-bind="attrs"
              v-on="on"
              class="mr-2 to-upper"
              :outlined="is_boxes"
            ></v-text-field>
          </v-col>
          <!-- Status -->
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="rec_status"
              :items="rec_statuses"
              :label="$t('receptions.status')"
              item-value="id"
              item-text="text"
              class="mr-2"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              @click:append="status = null"
              hide-details
              append-icon="mdi-close"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="
            isActiveConnection &&
            rec_status &&
            (rec_status.key == 'order_registered' ||
              rec_status.key == 'order_supplier_error')
          "
          color="warning"
          dark
          @click="sendToSupplier()"
        >
          {{ $t("receptions.modify_article.send_order_to_supplier") }}
          <v-icon dark right> mdi-invoice-send </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-1 text-white-color"
          color="error"
          @click="deletePopUp()"
        >
          {{ $t("nav_buttons.delete") }}
          <v-icon dark right> mdi-delete </v-icon>
        </v-btn>
        <v-btn color="grey-lighten-2" @click="closePopUpModel()">
          {{ $t("receptions.modify_article.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn
          color="green"
          :disabled="isReceivedQuantityGreaterThen"
          :dark="isReceivedQuantityGreaterThen ? false : true"
          @click="savePopUpModel()"
        >
          {{ $t("receptions.modify_article.save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  mixins: [validationMixin],
  validations: {
    // Attachment Validation
    // description: {
    //     required: required,
    // },
  },
  props: {
    rec_statuses: [Object],
    data: [Object],
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      popUpModelVisible: true,
      isLoading: false,
      autocompleteValueLocal: "off",
      rec_status: null,
      savedReception: false,
      company_supplier_connections: [],
      already_received_quantity:
        this.data.supplier_order_details.received_quantity,

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.getSupplierConnections();
    this.$store.commit("setValidation", true); // enable validation
    this.data.supplier_order_details.received_quantity = 0;
  },
  destroyed() {
    this.$store.commit("setValidation", false); // Disable validation
  },
  components: {
    "confirmation-model": ConfirmationModel,
  },
  methods: {
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "scheduler.delete_confirmation_for_appointment"
      );
      this.confirmationDialogFalseText = this.$t("scheduler.cancel");
      this.confirmationDialogTrueText = this.$t("scheduler.delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
    setReceptionStatusToStatus(newStatus) {
      this.rec_statuses.forEach((element) => {
        if (element.key == newStatus) {
          this.rec_status = element;
        }
      });
    },
    ifDisableReceptionStatus() {
      if (this.rec_status && this.rec_status.key) {
        return (
          this.rec_status &&
          this.rec_status.key &&
          ["successfully_ordered"].includes(this.rec_status.key)
        );
      }
      return false;
    },
    checkQuantityToChangeStatus() {
      if (
        parseInt(this.data.supplier_order_details.received_quantity) +
          parseInt(this.already_received_quantity) >
        0
      ) {
        if (parseInt(this.data.supplier_order_details.confirmed_quantity) > 0) {
          if (
            parseInt(this.data.supplier_order_details.received_quantity) +
              parseInt(this.already_received_quantity) >=
            parseInt(this.data.supplier_order_details.confirmed_quantity)
          ) {
            this.setReceptionStatusToStatus("fully_received");
          }
          // if((parseInt(this.data.supplier_order_details.received_quantity) > 0 || parseInt(this.already_received_quantity) > 0) && parseInt(this.data.supplier_order_details.received_quantity) + parseInt(this.already_received_quantity) < parseInt(this.data.supplier_order_details.confirmed_quantity)){
          //     this.setReceptionStatusToStatus('partially_received');
          // }
          else {
            this.setReceptionStatusToStatus("successfully_ordered");
          }
        } else {
          if (
            parseInt(this.data.supplier_order_details.received_quantity) +
              parseInt(this.already_received_quantity) >=
            parseInt(this.data.supplier_order_details.ordered_quantity)
          ) {
            this.setReceptionStatusToStatus("fully_received");
          }
          // if((parseInt(this.data.supplier_order_details.received_quantity) > 0 || parseInt(this.already_received_quantity) > 0) && parseInt(this.data.supplier_order_details.received_quantity) + parseInt(this.already_received_quantity) < parseInt(this.data.supplier_order_details.ordered_quantity)){
          //     this.setReceptionStatusToStatus('partially_received');
          // }
          else {
            this.setReceptionStatusToStatus("successfully_ordered");
          }
        }
      } else {
        this.setReceptionStatusToStatus("successfully_ordered");
      }
    },
    closePopUpModel() {
      this.popUpModelVisible = false;
    },
    getSupplierConnections() {
      axios
        .get(API_BASE_URL + "/company_supplier_connections?per_page=100", {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then(({ data }) => {
          this.company_supplier_connections = data.data;
        })
        .catch((error) => {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    makeSupplierReceptionRequest() {
      let reception_items = [];
      // push only those, whose received quantity is greater then 0 else, backend code will process them also
      if (this.data.supplier_order_details.received_quantity > 0) {
        let reception_data = {
          srno: 1,
          article_id: this.data.supplier_order_details.article.id,
          localisation: "",
          totalOrdered: this.data.supplier_order_details.ordered_quantity,
          // price: item.price,
          received: parseInt(
            this.data.supplier_order_details.received_quantity
          ), // + parseInt(this.already_received_quantity),
          old_reception:
            this.data.supplier_order_reception.reception_number ?? null,
          dot_quantities: null,
          price: this.data.supplier_order_details.acquisition_price,
        };
        if (this.data.id) {
          // add supplier_order_id for modifying just that supplier order detail
          reception_data["supplier_order_id"] = this.data.id;
        }
        reception_items.push(reception_data);
      }
      let request = {
        delivery_note_ref:
          this.data &&
          this.data.supplier_order_details &&
          this.data.supplier_order_details.supplier_order &&
          this.data.supplier_order_details.supplier_order.reference
            ? this.data.supplier_order_details.supplier_order.reference
            : "FROM_REC_LIST_" + this.generateRandomString(10),
        rec_items: reception_items,
        supplier: this.data ? this.data.supplier_id : null,
        force_in_successfully: true,
        update_other_details_also: true,
      };
      return request;
    },
    createReception() {
      axios
        .post(
          API_BASE_URL + "/receptions/create",
          this.makeSupplierReceptionRequest(),
          { headers: this.header }
        )
        .then(async (response) => {
          if (response.status === 200) {
            this.$toast.success(
              this.$t("receptions.text_messages.reception_created_successfully")
            );
            if (response.data.id) {
              if (
                this.rec_status &&
                this.rec_status.key &&
                this.rec_status.key == "fully_received"
              ) {
                await this.injectReception(response.data.id, response.data);
              }
            }
          } else {
            this.$toast.error(
              this.$t("receptions.text_messages.failed_while_sending")
            );
          }
        })
        .catch(() => {
          this.$toast.error(
            this.$t("receptions.text_messages.failed_while_sending")
          );
        })
        .finally(() => {});
    },
    injectReception(id, requestDataForInject) {
      return new Promise((resolve) => {
        let data = {
          delivery_note_reference: requestDataForInject.delivery_note_reference,
          supplier_id: requestDataForInject.supplier_id,
          rec_items: requestDataForInject.scan_data,
        };

        axios
          .post(API_BASE_URL + "/receptions/scans/" + id + "/inject", data, {
            headers: this.header,
          })
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success(
                this.$t("receptions.text_messages.injected_successfully")
              );
            } else {
              this.$toast.error(
                this.$t("receptions.text_messages.failed_while_injecting")
              );
            }
          })
          .catch(() => {
            this.$toast.error(
              this.$t("receptions.text_messages.failed_while_injecting")
            );
          })
          .finally(() => {
            resolve();
          });
      });
    },
    makeSubmitData() {
      let data = {
        is_partial_update: 1,
        status_id: this.rec_status.id,
        ordered_quantity: this.data.supplier_order_details.ordered_quantity,
        confirmed_quantity: this.data.supplier_order_details.confirmed_quantity,
        article_id: this.data.supplier_order_details.article.id,
      };
      if (this.allowedStatusesToBChanged.includes(this.data.status.key)) {
        data["supplier_id"] =
          this.data.supplier && typeof this.data.supplier == "object"
            ? this.data.supplier.id
            : this.data.supplier;
      }
      return data;
    },
    sendToSupplier() {
      this.savePopUpModel(true).then(() => {
        axios
          .post(API_BASE_URL + "/suppliers/order/" + this.data.id, this.form, {
            headers: this.header,
          })
          .then(({ data }) => {
            if (typeof data.data[Object.keys(data.data)[0]] == "string") {
              this.$toast.warning(data.data[Object.keys(data.data)[0]]);
            } else {
              let responseLength = Object.keys(
                data.data[Object.keys(data.data)[0]]
              ).length;
              for (let index = 0; index < responseLength; index++) {
                let arrayItemKey =
                  Object.keys(data.data[Object.keys(data.data)[0]])[index] + "";
                let arrayItemValue =
                  data.data[Object.keys(data.data)[0]][arrayItemKey];
                if (arrayItemValue) {
                  let response = arrayItemValue;
                  if (response && response.error_code == 0) {
                    if (response.availability > -1) {
                      this.$toast.success(
                        this.$t("stocks.order_placed_successfully")
                      );
                    } else {
                      this.retryPopUp();
                    }
                  } else {
                    this.retryPopUp();
                    this.$toast.error(response.error_message);
                  }
                }
              }
            }
          })
          .catch(() => {
            this.$toast.error(this.$t("stocks.something_went_wrong"));
          })
          .finally(() => {
            this.savedReception = true;
            this.popUpModelVisible = false;
          });
      });
    },
    async savePopUpModel(waitForManually = false) {
      await this.saveReception(this.makeSubmitData()).then(() => {
        if (!waitForManually) {
          this.savedReception = true;
          this.popUpModelVisible = false;
        }
      });
    },
    saveReception(data) {
      return new Promise((resolve) => {
        axios
          .put(API_BASE_URL + "/suppliers/order/" + this.data.id, data, {
            headers: this.header,
          })
          .then(() => {
            this.$toast.success(
              this.$t("receptions.text_messages.reception_updated_successfully")
            );
          })
          .finally(() => {
            let isInStatus = false;
            this.rec_statuses.forEach((element) => {
              if (
                [
                  "order_registered",
                  "successfully_ordered",
                  "partially_received",
                  "fully_received",
                ].includes(element.key)
              ) {
                isInStatus = true;
              }
            });
            if (
              this.data.supplier_order_details.received_quantity > 0 &&
              isInStatus
            ) {
              this.createReception();
            }
            resolve();
          });
      });
    },
    onConfirmationDeleteReception() {
      if (this.data && this.data.id) {
        axios
          .delete(API_BASE_URL + "/suppliers/order/" + this.data.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(() => {})
          .catch((error) => {
            console.log("an error occured " + error);
          })
          .finally(() => {
            this.savedReception = true;
            this.popUpModelVisible = false;
          });
      }
    },
  },
  computed: {
    isReceivedQuantityGreaterThen() {
      // if(parseInt(this.already_received_quantity) + parseInt(this.data.supplier_order_details.received_quantity) > parseInt(this.data.supplier_order_details.confirmed_quantity)){
      //     return true;
      // }
      return false;
    },
    isActiveConnection() {
      let supplierId =
        this.data.supplier && typeof this.data.supplier == "object"
          ? this.data.supplier.id
          : this.data.supplier;
      if (
        this.company_supplier_connections &&
        this.company_supplier_connections.length > 0
      ) {
        return this.company_supplier_connections.find((element) => {
          if (element.supplier_id == supplierId) {
            return element && element.edi_allow_order;
          }
        });
      }
      return false;
    },
    isAllowedToChangeReceivedQuantity() {
      return ["successfully_ordered", "partially_received", "fully_received"];
    },
    allowedStatusesToBChanged() {
      return ["order_registered", "order_supplier_error"];
    },
    allSuppliers() {
      return this.$store.state.allSuppliers ?? [];
    },
  },
  watch: {
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "delete" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.onConfirmationDeleteReception();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
    popUpModelVisible() {
      this.$emit("change-popup-model-visibility", this.savedReception);
    },
    data: {
      handler: function () {
        this.rec_status = this.data.status;
      },
      immediate: true,
    },
  },
};
</script>
