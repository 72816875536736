<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <span v-if="statusKey === 'depot_out'">
          <v-icon :color="color ? color : 'black'" :size="computedSize">
            mdi-car-arrow-right
          </v-icon>
        </span>
        <span v-else-if="statusKey === 'depot_requested_out'">
          <v-icon :color="color ? color : 'blue'" :size="computedSize">
            mdi-car-search-outline
          </v-icon>
        </span>
        <span v-else-if="statusKey === 'depot_stored'">
          <v-icon :color="color ? color : 'black'" :size="computedSize">
            mdi-barcode
          </v-icon>
        </span>
        <span v-else-if="statusKey === 'depot_to_be_stored'">
          <v-icon :color="color ? color : 'green'" :size="computedSize">
            mdi-home
          </v-icon>
        </span>
        <span v-else-if="statusKey === 'depot_to_be_verified'">
          <v-icon :color="color ? color : 'blue'" :size="computedSize">
            mdi-progress-alert
          </v-icon>
        </span>
        <span v-else-if="statusKey === 'depot_out_preparation'">
          <v-icon :color="color ? color : 'green'" :size="computedSize">
            mdi-home
          </v-icon>
        </span>
        <span v-else>
          <v-icon :color="color ? color : 'green'" :size="computedSize">
            mdi-home
          </v-icon>
        </span>
      </span>
    </template>
    {{ $t("depot_hints." + statusKey) }}
  </v-tooltip>
</template>
<script>
export default {
  name: "DepotStatus",
  props: {
    statusKey: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedSize() {
      return this.size ?? "22px";
    },
  },
};
</script>
