<template>
  <v-row class="fill-height">
    <v-container class="d-flex align-center justify-center">
      <v-row justify="center">
        <v-col cols="12" sm="10" md="6" lg="4">
          <v-card ref="form" class="elevation-3 rounded-lg pa-5">
            <v-card-title class="text-center">
              <v-img
                style="max-height: 60px; max-width: 60px; margin-right: 10px;"
                :src="`/images/cxp.png`"
              >
              </v-img>
              <h3 class=" font-weight-bold">
                {{ $t("login.title") }}
              </h3>
            </v-card-title>

            <v-card-text class="pt-0" style="height: 200px !important;">
              <!-- Login Form -->
              <div v-if="!showOtpPage">
                <v-form class="mt-3">
                  <v-text-field
                    v-model="email"
                    :label="$t('login.email')"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    class="mb-3"
                    required
                    :error-messages="
                      $store.state.validation
                        ? returnErrorMessage(
                            [
                              !$v.email.required,
                              this.$t('login.email_required'),
                            ],
                            [!$v.email.email, this.$t('login.email_required')]
                          )
                        : []
                    "
                    v-on:keydown.enter.prevent="submitLogin()"
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    :label="$t('login.password')"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    class="mb-3"
                    required
                    :error-messages="
                      $store.state.validation
                        ? returnErrorMessage(
                            [
                              !$v.password.required,
                              this.$t('login.password_required'),
                            ],
                            [
                              !$v.password.minLength,
                              this.$t('login.password_minimum_length'),
                            ]
                          )
                        : []
                    "
                    :append-icon="
                      showPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    v-on:keydown.enter.prevent="submitLogin()"
                  ></v-text-field>

                  <v-btn
                    block
                    color="primary"
                    class="mt-2"
                    size="large"
                    @click="submitLogin"
                    :loading="$store.state.isLoading"
                  >
                    {{ $t("login.title") }}
                    <v-icon end>mdi-login-variant</v-icon>
                  </v-btn>
                </v-form>
              </div>
              <div v-else>
                <p class="text-center">
                  {{ $t("verification_code_has_sent_to_email") }}
                  <!-- {{ maskEmail(email) }} -->
                    <span v-if="attemptLeft < maxAttempt" class="text-primary">
                        <br>
                        {{attemptLeft}} {{ $t("attempt_left") }} 
                    </span>
                </p>

                <v-otp-input
                  ref="otpInput"
                  v-model="otp"
                  color="primary"
                  length="6"
                  required
                  :error="invalidOtpError"
                  @finish="verifyOtp"
                  @keyup.enter="verifyOtp"
                ></v-otp-input>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- OTP Verification Popup -->
      <!-- <OtpVerification
        v-if="showOtpPage"
        :email="email"
        @verified="handleOtpVerified"
        @close="showOtpPage = false"
        :userIpDetails="userIpDetails"
      /> -->
    </v-container>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
// import OtpVerification from "@/components/OtpVerification.vue";
import { IP_ADDRESS_API } from "@/config";

export default {
  //   components: { OtpVerification },

  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email: (val) => email((val ?? "").toLowerCase()),
    },
    password: { required, minLength: minLength(5) },
  },
  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
      alerts: [],
      showOtpPage: false,
      otpTokenData: null,
      userIpDetails: null,

      otp: "",
      attemptLeft: 0,
      maxAttempt: 3,
      invalidOtpError: false
    };
  },
  created() {
    this.getIpAddressOfUser();
    this.resetAttemptLeft();
  },
  methods: {
    async verifyOtp() {
      if (!this.otp) return;

      this.attemptLeft -= 1;

      this.$store.state.isLoading = true;
      try {
        await axios
          .post(`${API_BASE_URL}/login/verify_token`, {
            otp: this.otp,
            ip_details: this.userIpDetails,
            token_id: this.otpTokenData
          })
          .then((data) => {
            this.handleOtpVerified(data);    
            })
          .catch((error) => {
            if (error.status == 422) {
                this.manageAttemptLeft();
              this.showInvalidOtp();
              this.otp = null;
            }
          });
      } catch (error) {
        this.manageAttemptLeft();
        alert(error.response?.data?.message);
      } finally {
        this.$store.state.isLoading = false;
      }
    },
    showInvalidOtp(){
        this.$toast.error(this.$t("invalid_otp"));
        this.invalidOtpError = true;
        this.$refs["otpInput"].focus();
        setTimeout(() => {
            this.invalidOtpError = false;
        }, 1000);
    },
    manageAttemptLeft(){
        if(this.attemptLeft <= 0){
            this.showOtpPage = false;
            this.otpTokenData = null;
            this.resetAttemptLeft();
            this.email = null;
            this.password = null;
            this.$store.commit("setValidation", false);
            this.$toast.warning(this.$t("max_attempts_failed_retry_again"));
        }
    },
    resetAttemptLeft(){
        this.attemptLeft = this.maxAttempt;
    },
    async submitLogin() {
      this.$store.commit("setValidation", true);
      if (!this.email || !this.password) return;

      this.$store.state.isLoading = true;
      try {
        await axios
          .post(`${API_BASE_URL}/login`, {
            email: this.email,
            password: this.password,
            device_info: this.getDeviceInfo(true),
            ip_details: this.userIpDetails,
          })
          .then((data) => {
            localStorage.clear();
            if (data.data.msg.includes("2fa")) {
              console.log("data", data.data.data);
              this.showOtpPage = true; // Show OTP Popup
              this.otpTokenData = data.data.data;
            } else {
              this.completeLogin(data);
            }
          });
      } catch (error) {
        console.log("error data", error);
        this.$toast.error(this.$t("invalid_credentials"));
      } finally {
        this.$store.state.isLoading = false;
      }
    },
    getIpAddressOfUser() {
      axios.get(IP_ADDRESS_API).then(({ data }) => {
        this.userIpDetails = data;
      });
    },
    handleOtpVerified(data) {
      this.showOtpPage = false;
      this.otpTokenData = null;
      this.completeLogin(data);
    },
    completeLogin(loginRes) {
      console.log("login resp", loginRes);
      clearInterval(this.$store.state.getMessageInterval);
      localStorage.setItem("data", JSON.stringify(loginRes.data.data));
      localStorage.setItem("accessToken", loginRes.data.data.access_token);
      localStorage.setItem(
        "companyID",
        loginRes.data.data.userDetails.company_id
      );
      localStorage.setItem(
        "is_peppol",
        loginRes.data.data.userDetails.company.is_peppol
      );
      localStorage.setItem("loginUser", loginRes.data.data.userDetails.name);
      localStorage.setItem("loginUserId", loginRes.data.data.userDetails.id);
      if (!localStorage.getItem("languageId")) {
        localStorage.setItem(
          "languageId",
          loginRes.data.data.userDetails.language_id
        );
      }
      localStorage.setItem(
        "currencyId",
        loginRes.data.data.userDetails.company.currency_id
      );
      localStorage.setItem(
        "company_name",
        loginRes.data.data.userDetails.company.name
      );
      localStorage.setItem(
        "company_properties",
        JSON.stringify(loginRes.data.data.companyProperties)
      );
      localStorage.setItem(
        "userRole",
        loginRes.data.data.userDetails.role.name
      );

      localStorage.setItem(
        "userCountry",
        loginRes.data.data.userDetails.company.registered_country_id ?? "LU"
      );

      localStorage.setItem(
        "dashboardCards",
        JSON.stringify(loginRes.data.data.dashboards ?? [])
      );

      localStorage.setItem(
        "date_time_format",
        loginRes.data.data.date_time_format
      );

      // Parse Currency Symbol
      const myHtmlString =
        loginRes.data.data.userDetails.currency_symbol ?? "&#8364;";
      const htmlDom = new DOMParser().parseFromString(
        myHtmlString,
        "text/html"
      );
      let currencySymbol = htmlDom.documentElement.textContent;
      localStorage.setItem("currencySymbol", currencySymbol);
      let applicationResources = [];
      loginRes.data.data.applicationResources.forEach((appresource) => {
        applicationResources.push(appresource.application_resource.key);
      });
      localStorage.setItem(
        "applicationResources",
        JSON.stringify(applicationResources)
      );

      this.updateExpeditionLocalStorageValuesFilter(
        loginRes.data.data.userDetails.id
      );

      //   this.$store.state.AccessToken = loginRes.data.data.access_token;
      //   this.$store.state.companyId = loginRes.data.data.userDetails.company_id;
      //   this.$store.state.loginUser = loginRes.data.data.userDetails.name;
      //   this.$store.state.languageId = loginRes.data.data.userDetails.language_id;
      //   this.$store.state.currencyId = loginRes.data.data.userDetails.company.currency_id;
      //   this.$store.state.role = loginRes.data.data.userDetails.role.name;
      //   this.$i18n.locale = this.findCompanyLocale(loginRes.data.data.userDetails.language_id);
      // remember me
      if (this.chkRemember === true) {
        this.$cookies.set("email", this.email);
        this.$cookies.set("password", this.password);
        this.$cookies.set("remember", this.chkRemember);
      }
      //   this.$router.push({ name: 'Home' }).then(() => { this.$router.go() }).catch(() => {});
      window.location.href = "/";
    },
    updateExpeditionLocalStorageValuesFilter(user_id) {
      let filters = {
        user: user_id ? user_id : null,
      };
      localStorage.setItem(
        "EXPEDITION_LISTING_FILTERS",
        JSON.stringify(filters)
      );
    },
  },
};
</script>
