import axios from "axios";
import store from "@/store"; // Import your Vuex store

let config = {};
let requestCount = 0;

const _external_call_axios = axios.create(config);

_external_call_axios.interceptors.request.use(
  function (config) {
    // Increment the request count before sending the request
    requestCount++;
    store.commit("setProgressBarLoading", true);

    return config;
  },
  function (error) {
    // Decrement the request count in case of request error
    requestCount--;

    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_external_call_axios.interceptors.response.use(
  function (response) {
    // Decrement the request count on complete
    requestCount--;

    if (requestCount === 0) {
      store.commit("setProgressBarLoading", false);
    }
    return response;
  },
  function (error) {
    // Decrement the request count in case of request error
    requestCount--;

    // Handle response error
    if (error.response.status == 401) {
      localStorage.clear();
    }

    if (requestCount === 0) {
      store.commit("setProgressBarLoading", false);
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _external_call_axios;
  window.axios = _external_call_axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _external_call_axios;
      },
    },
    $axios: {
      get() {
        return _external_call_axios;
      },
    },
  });
};

export default _external_call_axios;
