<template>
  <div>
    <v-row v-if="translationTarget">
      <v-col cols="10">
        <v-select
          :items="messages"
          item-text="subject"
          :label="$t('message')"
          v-model="formState.content"
          @change="updateMessage"
          :show-label="false"
          return-object
        />
      </v-col>
      <v-col cols="2">
        <v-select
          :items="languages"
          item-text="text"
          item-value="value"
          @change="updateMessage"
          label="Language"
          v-model="formState.selectedLanguage"
          :show-label="false"
        />
      </v-col>
    </v-row>
    <h4
      v-if="showLabel"
      class="mb-3"
      style="color: #9b9b9b; font-weight: normal"
    >
      {{ label || $t("scheduler.add_bill_note") }}
    </h4>
    <wysiwyg v-model="note" />
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  name: "NoteEditor",
  emits: ["input"],
  props: {
    label: {
      default: null,
    },
    value: {
      required: true,
    },
    showLabel: {
      default: true,
    },
    rows: {
      default: 5,
    },
    translationTarget: {
      default: null,
    },
  },
  data() {
    return {
      translations: [],
      formState: {
        content: null,
        selectedLanguage: null,
      },
    };
  },
  async mounted() {
    if (this.translationTarget) {
      await this.getMessageTranslations();
    }
    if (this.companyLanguages.length > 0) {
      this.formState.selectedLanguage =
        this.companyLanguages.find((item) => item.is_default === 1)
          ?.language_id ?? "fr";
    }
  },
  methods: {
    updateMessage() {
      this.note = this.formState.content.item[this.formState.selectedLanguage];
    },
    getMessageTranslations() {
      return new Promise((resolve) => {
        return axios
          .get(
            API_BASE_URL +
              "/translations?application_resource_key=" +
              this.translationTarget,
            {
              headers: this.header,
            }
          )
          .then((res) => {
            this.translations = res.data.data;
            resolve(res.data.data);
          });
      });
    },
    getMessagesFromTranslation(language) {
      return this.translations
        .map((item) => {
          return {
            item: item,
            message: item[language],
            subject: item.key.replaceAll("_", " "),
          };
        })
        .filter((item) => item !== null);
    },
  },
  computed: {
    messages() {
      if (this.translations.length === 0) return;
      return this.getMessagesFromTranslation(this.formState.selectedLanguage);
    },
    companyLanguages() {
      return this.$store.state.companyLanguages;
    },
    languages() {
      return this.companyLanguages.map((item) => {
        return {
          text: this.$t("languages." + item.language_id),
          value: item.language_id,
        };
      });
    },
    note: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.editr {
  min-height: 400px !important;
  max-height: 400px !important;
}

.editr >>> .editr--content {
  min-height: 300px !important;
  max-height: 300px !important;
  overflow-y: scroll !important;
}
</style>
