<template>
  <v-dialog :value="value" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ employmentContract ? translate("edit") : translate("add") }}
          {{ translate("employment_contracts") }}</span
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="employmentContract && employmentContract.id"
          icon
          @click="toggleDeleteConfirmation"
        >
          <v-icon color="error">mdi-trash-can</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col :md="4">
              <v-text-field
                v-model="form.name"
                :label="translate('name')"
              ></v-text-field>
            </v-col>
            <v-col :md="4">
              <v-select
                v-model="form.type_id"
                :items="employmentContractTypes"
                item-text="text"
                item-value="id"
                :label="translate('type')"
              ></v-select>
            </v-col>
            <v-col :md="4">
              <v-text-field
                :label="translate('work_hours_per_day')"
                v-model="form.work_hours_per_day"
                @keydown="handleInput($event, 'int', 2)"
              />
            </v-col>
            <v-col :md="4">
              <v-select
                v-model="form.break_time_period"
                :items="measurementUnits"
                item-text="text"
                item-value="id"
                :label="translate('break_time_period')"
              ></v-select>
            </v-col>
            <v-col :md="4">
              <v-text-field
                :label="translate('break_time_per_day')"
                v-model="form.break_time_per_day"
                @keydown="handleInput($event, 'int', 4)"
              ></v-text-field>
            </v-col>
            <v-col :md="4">
              <v-text-field
                type="time"
                v-model="form.default_start_break_time"
                :label="translate('break_time_start')"
              ></v-text-field>
            </v-col>
            <v-col :md="3">
              <v-select
                v-model="form.work_days_period"
                :items="measurementUnits"
                item-text="text"
                item-value="id"
                :label="translate('work_days_period')"
              ></v-select>
            </v-col>
            <v-col :md="3">
              <v-text-field
                :label="translate('work_days')"
                v-model="form.work_days"
                @keydown="handleInput($event, 'int', 3)"
              ></v-text-field>
            </v-col>
            <v-col :md="3">
              <v-select
                v-model="form.vacation_days_period"
                :items="measurementUnits"
                item-text="text"
                item-value="id"
                :label="translate('vacation_days_period')"
              ></v-select>
            </v-col>
            <v-col :md="3">
              <v-text-field
                :label="translate('vacation_days')"
                v-model="form.vacation_days"
                @keydown="handleInput($event, 'int', 3)"
              ></v-text-field>
            </v-col>
            <v-col :md="9" class="d-flex justify-space-between">
              <v-text-field
                type="time"
                v-model="form.default_start_time"
                :label="translate('work_start_time')"
                class="mr-4"
              ></v-text-field>
              <v-text-field
                class="ml-4"
                type="time"
                v-model="form.default_end_time"
                :label="translate('work_end_time')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="form.is_working_in_shifts"
                :label="translate('working_in_shifts')"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close()" class="mr-3">
          <CloseButton :disabled="loading" />
        </span>
        <span @click="handleSubmit"><SaveButton :loading="loading" /></span>
      </v-card-actions>
    </v-card>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import clockingMixin from "@/mixins/clockingMixin";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import { API_BASE_URL } from "@/config";
import axios from "axios";
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
  name: "EmploymentContractForm",
  emits: ["input", "created", "updated", "deleted"],
  mixins: [clockingMixin],
  components: { SaveButton, CloseButton, ConfirmationModel },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    employmentContract: {
      type: Object,
      default: null,
    },
  },
  watch: {
    employmentContract(val) {
      this.form = val;
    },
    value(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
  async mounted() {
    this.employmentContractTypes = (
      await this.getEmploymentContractTypes()
    ).map((item) => {
      return {
        ...item,
        text: this.translate("types." + item.key),
      };
    });
    await this.getMeasurementUnits();
  },
  computed: {
    loading() {
      return this.$store.state.progressBarLoading;
    },
    employmentContractName() {
      return this.employmentContract?.name ?? "";
    },
  },
  data() {
    return {
      form: {
        name: "",
        type_id: "",
        work_hours_per_day: "",
        break_time_period: "",
        default_start_break_time: "",
        break_time_per_day: "",
        work_days_period: "",
        work_days: "",
        vacation_days_period: "",
        vacation_days: "",
        default_start_time: "",
        default_end_time: "",
        is_working_in_shifts: false,
      },
      axiosOptions: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      measurementUnits: [],
      confirmationDialog: false,
      confirmationDialogOperation: null,
      confirmationDialogText: "",
      confirmationDialogTrueText: "",
      confirmationDialogFalseText: "",
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    resetForm() {
      this.form = {
        name: "",
        type_id: "",
        work_hours_per_day: "",
        break_time_period: "",
        break_time_start: "",
        break_time_per_day: "",
        work_days_period: "",
        work_days: "",
        vacation_days_period: "",
        vacation_days: "",
        start_time: "",
        end_time: "",
        is_working_in_shifts: false,
      };
    },
    handleSubmit() {
      if (!this.validate()) {
        return;
      }

      if (this.employmentContract) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      axios
        .post(API_BASE_URL + "/employment/contracts", this.form, {
          headers: this.axiosOptions,
        })
        .finally(() => {})
        .then((response) => {
          this.$toast.success(this.translate("created_successfully"));
          this.close();
          this.resetForm();
          this.$emit("created", response.data.data);
        });
    },
    update() {
      axios
        .put(
          API_BASE_URL + "/employment/contracts/" + this.employmentContract.id,
          this.form,
          { headers: this.axiosOptions }
        )
        .finally(() => {})
        .then((response) => {
          this.$toast.success(this.translate("updated_successfully"));
          this.close();
          this.$emit("updated", response.data.data);
          this.resetForm();
        });
    },
    validate() {
      // loop through form and validate each field, if any is empty bail and show an error with "noty"
      for (const argumentsKey in this.form) {
        if (argumentsKey === "is_working_in_shifts") {
          continue;
        }

        if (!this.isset(this.form[argumentsKey])) {
          this.$toast.error(this.translate("fill_all_fields"));
          return false;
        }
      }

      return true;
    },
    toggleDeleteConfirmation() {
      this.confirmationDialog = false;
      setTimeout(() => {
        this.confirmationDialog = true;
        this.confirmationDialogOperation = "delete";
        this.confirmationDialogText = this.translate("delete_confirmation", {
          name: this.employmentContractName,
        });
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogFalseText = this.$t("no");
      }, 10);
    },
    confirmationButtonClicked(action) {
      if (!action) {
        return;
      }
      if (this.confirmationDialogOperation === "delete") {
        this.deleteEmploymentContract();
      }
    },
    deleteEmploymentContract() {
      axios
        .delete(
          API_BASE_URL + "/employment/contracts/" + this.employmentContract.id,
          { headers: this.axiosOptions }
        )
        .finally(() => {})
        .then(() => {
          this.$toast.success(this.translate("deleted_successfully"));
          this.close();
          this.$emit("deleted", this.employmentContract);
          this.resetForm();
        })
        .catch((err) => {
          console.error(err.response);
          this.$toast.error(
            err?.response?.data?.message || this.translate("error")
          );
        });
    },
  },
};
</script>
