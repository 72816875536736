<template>
    <v-dialog :value="value" persistent max-width="1200px" scrollable>
        <v-card v-if="activeModelId && model">
            <v-card-title>
                <span class="headline">{{
                    $t("nav_buttons.user_action_history")
                    }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :loading="loading" :headers="headers" :items="items" :footer-props="{
                    'items-per-page-options': dataTableOptions,
                }" :items-per-page="5" class="elevation-1">
                    <template v-slot:item.changes_label="{ item }">
                        <v-expansion-panels v-if="isset(item.changes)" class="ma-2">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <span v-html="item.changes_title_label"></span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-html="item.changes_label"></div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <span @click="close">
                    <CloseButton />
                </span>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import axios from "axios";
import CloseButton from "@/components/Buttons/CloseButton.vue";

export default {
    name: "HistoryTable",
    components: { CloseButton },
    emits: ["input"],
    props: {
        model_id: {
            default: null,
        },
        model: {
            default: null,
        },
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            items: [],
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            loading: false,
            headers: [
                {
                    text: this.$t("srno"),
                    sortable: false,
                    value: "serial",
                },
                { text: this.translate("user"), value: "user_label", sortable: false },
                {
                    text: this.translate("operation"),
                    value: "operation_label",
                    sortable: false,
                },
                { text: this.translate("date"), value: "date_label", sortable: false },
                {
                    text: this.translate("changes"),
                    value: "changes_label",
                    sortable: false,
                },
            ],
            filters: {
                from: null,
                to: null,
            },
            axiosOptions: {
                headers: {
                    Authorization: "Bearer " + this.$store.state.AccessToken,
                },
            },
        };
    },
    watch: {
        value(val) {
            this.items = [];

            if (val) {
                this.getHistory();
            }
        }
    },
    mounted(){
        this.getHistory();
    },
    computed: {
        activeModelId() {
            if (this.model_id === 0) return true;

            return this.model_id;
        },
    },
    methods: {
        close() {
            this.$emit("input", false);
        },
        getHistory() {
            this.loading = true;
            axios
                .get(this.url(), this.axiosOptions)
                .finally(() => {
                    this.loading = false;
                })
                .then(({ data }) => {
                    const response = data.data;
                    this.items = response.logs.map((item, index) => {
                        item.serial = index + 1;
                        item.date_label = this.formatDateWithLocale(item.created_at);
                        item.changes_title_label = this.processLogChangesForTableView(
                            item,
                            true
                        );
                        // remove first element from changes, as its already used in title
                        delete item.changes[Object.keys(item.changes)[0]];
                        item.changes_label = this.processLogChangesForTableView(item);
                        return item;
                    });
                    // this.headers = response.table_columns
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        url() {
            let url =
                API_BASE_URL +
                "/user_action_history?model_id=" +
                this.model_id +
                "&model=" +
                this.model;

            for (const filterKey in this.filters) {
                if (this.filters[filterKey]) {
                    url += "&" + filterKey + "=" + this.filters[filterKey];
                }
            }

            return url;
        },
        processLogChangesForTableView(log, title = false) {
            // concat object to string with key value pair
            let changes = log.changes;
            let stringValue = "";
            let lastChangesKey = Object.keys(changes).pop();
            for (const key in changes) {
                let current = changes[key];
                if (key === "color") {
                    current["old"] = current["old"]
                        ? `<input style="height: 20px!important;" type="color" value="#${current["old"]}" disabled>`
                        : "";
                    // use html color swatch
                    current["new"] = current["new"]
                        ? `<input style="height: 20px!important;" type="color" value="#${current["new"]}" disabled>`
                        : "";
                }

                let from = current["old"]
                    ? `<strong>From</strong> ${current["old"]}`
                    : "";
                let conjuction = current["old"] && current["new"] ? " to " : "";
                stringValue += `<strong>${current["label"]
                    }</strong>: ${from} ${conjuction} ${current["new"] || "-"}`;

                if (key !== lastChangesKey) {
                    stringValue += "<br>";
                }

                if (title) {
                    break;
                }
            }

            return stringValue;
        },
        translate(key) {
            return this.$t("user_action_history_section." + key);
        },
    },
};
</script>
