<template>
  <div class="pt-2 pb-0">
    <h3>{{ heading }}</h3>
    <span>
      {{ text }}
    </span>
    <div v-if="action" align="right" class="mt-2">
      <v-btn @click="performAction()">{{ action }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationPopUpContent",
  props: {
    heading: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: false,
    },
  },
  methods: {
    performAction() {
      switch (this.action.toLowerCase()) {
        case "okay":
        case "yes":
        case "continue":
        case "next":
        case "accept":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "cancel":
        case "no":
        case "back":
        case "reject":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "save":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "discard":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "submit":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "retry":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "edit":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "delete":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "close":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "refresh":
          window.location.href = "/";
          break;
        case "apply":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "skip":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        case "download":
          this.$emit("close-toast"); // Emit event to close toast
          break;
        default:
          console.warn(`Action '${this.action}' is not handled.`);
      }
    },
  },
};
</script>
