<template>
  <div>
    <h2>{{ heading || $t("mappings.history") }}</h2>

    <v-simple-table v-if="type === 'edi'">
      <thead>
        <tr>
          <th style="max-width: 150px">{{ translate("date") }}</th>
          <th style="text-align: left">{{ translate("result") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in history" :key="item._id">
          <td style="max-width: 150px">
            {{ formatDateWithLocale(item.created_at) }}
          </td>
          <td>
            <v-expansion-panels class="ma-2">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span></span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <pre>{{ item.updated_from_object }}</pre>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table v-else>
      <thead>
        <tr>
          <th style="max-width: 150px">{{ translate("date") }}</th>
          <th>{{ translate("family_article") }}</th>
          <th>{{ translate("articles_in_file") }}</th>
          <th>{{ translate("articles_processed") }}</th>
          <th>{{ translate("processed_file") }}</th>
          <th>{{ translate("import_type") }}</th>
          <th>{{ translate("errors") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in history" :key="item._id">
          <td>{{ formatDateWithLocale(item.created_at) }}</td>
          <td>{{ item.family_article_label }}</td>
          <td>{{ item.total_articles_in_file }}</td>
          <td>{{ item.total_articles_processed }}</td>
          <td>{{ item.archived_file }}</td>
          <td>{{ item.import_type }}</td>
          <td>
            <v-expansion-panels class="ma-2" v-if="item.errors !== '[]'">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span></span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <pre style="max-width: 1000px" class="text-wrap">{{
                      item.errors
                    }}</pre>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
import _axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  name: "SupplierConnectionTypeHistory",
  props: ["type", "supplierId", "heading"],
  data() {
    return {
      history: [],
    };
  },
  watch: {
    supplierId() {
      this.getConnectionHistory();
    },
    type() {
      this.getConnectionHistory();
    },
  },
  mounted() {
    this.getConnectionHistory();
  },
  methods: {
    getConnectionHistory() {
      _axios
        .get(
          API_BASE_URL +
            `/company_supplier_connections/history?type=${this.type}&supplier_id=${this.supplierId}&limit=20`
        )
        .then(
          (response) =>
            (this.history = response.data.data.map((item) => {
              if (this.isset(item.import_type)) {
                item.import_type = item.import_type
                  .toString()
                  .replace("Adapt App\\Libraries\\Core\\", "");
              }

              if (this.isset(item.family_article_id)) {
                const familyArticle = this.familyArticles.find(
                  (f) => f.id === item.family_article_id
                );
                item.family_article_label = familyArticle.name;
              }

              return item;
            }))
        );
    },
    translate(translationKey, args = []) {
      return this.$t("supplier_connections_section." + translationKey, args);
    },
    pretty(value) {
      return JSON.parse(value);
    },
  },
};
</script>
