var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('report-listing-filter',{attrs:{"selected":_vm.selectedReport},on:{"update:selected":function($event){_vm.selectedReport=$event}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[(_vm.selectedReport)?_c('v-row',[(!_vm.$store.state.reportViewData)?_c('v-col',{staticClass:"mt-4 ml-4",attrs:{"cols":"12","sm":"8"}},[_c('h2',[_vm._v(_vm._s(_vm.selectedReport.name))]),_c('v-row',[_c('v-col',[_vm._l((_vm.selectedReport.params),function(params,index){return [(
                          params.is_visible && params.parameter_type.key == 'date'
                      )?_c('DateInput',{key:index,attrs:{"label":params.name},model:{value:(_vm.dynamicParams[params.field]),callback:function ($$v) {_vm.$set(_vm.dynamicParams, params.field, $$v)},expression:"dynamicParams[params.field]"}}):_vm._e(),(
                    params.is_visible && params.parameter_type.key == 'number'
                  )?_c('v-text-field',{key:index,staticClass:"to-upper",attrs:{"label":params.name,"outlined":_vm.is_boxes,"dense":_vm.is_boxes,"error-messages":_vm.$store.state.validation &&
                    !_vm.$v.dynamicParams[params.field].required
                      ? [_vm.$t('required')]
                      : []},model:{value:(_vm.dynamicParams[params.field]),callback:function ($$v) {_vm.$set(_vm.dynamicParams, params.field, $$v)},expression:"dynamicParams[params.field]"}}):_vm._e(),(
                    params.is_visible && params.parameter_type.key == 'string'
                  )?_c('v-text-field',{key:index,staticClass:"to-upper",attrs:{"label":params.name,"outlined":_vm.is_boxes,"dense":_vm.is_boxes,"error-messages":_vm.$store.state.validation &&
                    !_vm.$v.dynamicParams[params.field].required
                      ? [_vm.$t('required')]
                      : []},model:{value:(_vm.dynamicParams[params.field]),callback:function ($$v) {_vm.$set(_vm.dynamicParams, params.field, $$v)},expression:"dynamicParams[params.field]"}}):_vm._e(),(
                    params.is_visible &&
                    params.parameter_type.key == 'dropdown'
                  )?_c('v-autocomplete',{key:index,staticClass:"mr-2 to-upper",attrs:{"error-messages":_vm.$store.state.validation &&
                    !_vm.$v.dynamicParams[params.field].required
                      ? [_vm.$t('required')]
                      : [],"flat":"","items":params.dropdown_values,"label":params.name,"item-value":"value","item-text":"text","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"focus":function($event){_vm.dynamicParams[params.field] = null}},model:{value:(_vm.dynamicParams[params.field]),callback:function ($$v) {_vm.$set(_vm.dynamicParams, params.field, $$v)},expression:"dynamicParams[params.field]"}}):_vm._e()]})],2)],1)],1):_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"height":'calc(-155px + 100vh)',"fixed-header":"","footer-props":{
              'items-per-page-options': _vm.dataTableOptions,
            },"headers":_vm.headers,"items":_vm.reportDataInRowsColumns,"options":_vm.options,"loading-text":this.$t('loading'),"items-per-page":_vm.itemsPerPage,"item-key":"identifier"}})],1)],1):_vm._e(),_c('v-row')],1)],1),_c('v-btn',{staticClass:"d-none",attrs:{"id":"reset-page-data"},on:{"click":function($event){return _vm.resetParams()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"run-report"},on:{"click":function($event){return _vm.runReport()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"run-print-report"},on:{"click":function($event){return _vm.runPrintReport()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"cancel-run-report"},on:{"click":function($event){return _vm.cancelRunReport()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"save-report"},on:{"click":function($event){return _vm.saveReport()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }