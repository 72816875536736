<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :persistent="loading" max-width="700">
      <template v-slot:activator="{ props }">
        <span
          style="display: none"
          v-bind="props"
          @click="dialog = !dialog"
          id="addTriggerButton"
        >
        </span>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ formAction }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="triggers"
                  :label="translateKey('triggers_section.trigger')"
                  v-model="selectedTriggerId"
                  required
                  item-text="name"
                  item-value="id"
                >
                </v-select>
              </v-col>
              <slot v-if="selectedTrigger">
                <v-col
                  cols="12"
                  v-for="item in selectedTrigger.params"
                  :key="item.id"
                >
                  <v-text-field
                    v-if="!item.type.key.toString().includes('date')"
                    :label="
                      translateKey('triggers_section.params.' + item.field)
                    "
                    v-model="conditions[item.field]"
                    @keydown="(event) => validateParamInput(event, item)"
                    required
                    class="to-upper"
                  ></v-text-field>

                  <v-menu
                    v-else
                    v-model="dateDropdown"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="conditions[item.field]"
                        :label="
                          translateKey('triggers_section.params.' + item.field)
                        "
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:append="conditions[item.field] = null"
                        append-icon="mdi-close"
                        class="mr-2 to-upper"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :weekday-format="getDay"
                      v-model="conditions[item.field]"
                      @input="dateDropdown = false"
                      no-title
                      scrollable
                      :locale="userLanguageId"
                      hide-details
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </slot>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="grey lighten-2"
            @click="dialog = false"
          >
            {{ translateKey("vehicles.Cancel") }}
            <v-icon right> mdi-keyboard-return</v-icon>
          </v-btn>
          <v-btn :loading="loading" color="success" @click="save()">
            {{ translateKey("vehicles.Save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  name: "AddNewTriggerModal",
  props: {
    trigger: {
      type: Object,
      default: null,
    },
    parentModel: {
      type: Object,
      required: true,
    },
    triggerInstance: {
      type: Object,
      default: null,
    },
    parentModelPrimaryKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      triggers: [],
      conditions: {},
      selectedTriggerId: null,
      selectedTrigger: null,
      formConfig: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken,
        },
      },
      dateDropdown: false,
    };
  },
  mounted() {
    this.getTriggers();
  },
  watch: {
    selectedTriggerId(val) {
      this.conditions = {};
      if (val === null) {
        return null;
      }

      let trigger = this.triggers.find(
        (item) => item.id === this.selectedTriggerId
      );
      trigger = JSON.parse(JSON.stringify(trigger));

      trigger.params.forEach((item) => {
        if (
          item.type &&
          item.type.key &&
          item.type.key.toString().includes("system")
        ) {
          this.conditions[item.field] =
            this.parentModel[this.parentModelPrimaryKey];
        }
      });

      trigger.params = trigger.params.filter((item) => {
        return !item.type.key.toString().includes("system");
      });

      this.selectedTrigger = trigger;
    },
    dialog(val) {
      // open/close dialog
      if (!val) {
        this.selectedTrigger = null;
        this.selectedTriggerId = null;
        this.conditions = {};
        this.$emit("close");
        return null;
      }

      if (!this.triggerInstance) {
        return null;
      }

      this.selectedTrigger = this.triggerInstance.trigger;
      this.selectedTriggerId = this.triggerInstance.trigger.id;
      setTimeout(() => {
        this.conditions = this.triggerInstance.conditions;
      }, 500);
    },
  },
  computed: {
    formAction() {
      return this.triggerInstance
        ? this.translateKey("triggers_section.edit_trigger")
        : this.translateKey("triggers_section.add_trigger");
    },
  },
  methods: {
    save() {
      let form = {
        trigger_id: this.selectedTriggerId,
        conditions: this.conditions,
      };

      // loop through conditions object
      for (let key in form.conditions) {
        let fieldParam = this.selectedTrigger.params.find(
          (item) => item.field === key
        );
        if (fieldParam && fieldParam.type.key.includes("string")) {
          // convert to upper case
          form.conditions[key] = form.conditions[key].toUpperCase();
        }
      }

      console.clear();

      let axiosMethod = this.triggerInstance
        ? axios.put(
            API_BASE_URL + "/trigger_instances/" + this.triggerInstance.id,
            form,
            this.formConfig
          )
        : axios.post(
            API_BASE_URL + "/trigger_instances",
            form,
            this.formConfig
          );

      this.loading = true;
      axiosMethod
        .finally(() => {
          this.loading = false;
          // this.dialog = false;
        })
        .then((response) => {
          this.$emit("triggerSaved", response.data);
          this.$toast.success(
            this.translateKey("triggers_section.trigger_saved")
          );
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getTriggers() {
      axios
        .get(API_BASE_URL + "/triggers", this.formConfig)
        .then((response) => {
          this.triggers = response.data.data.map((item) => {
            let nameTranslation = this.translateKey(
              "triggers_section.names." + item.name
            );
            if (nameTranslation === "triggers_section.names." + item.name) {
              item.name = item.name.replace(/_/g, " ");
            } else {
              item.name = this.translateKey(
                "triggers_section.names." + item.name
              );
            }
            return item;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validateParamInput(event, item) {
      {
        // Limit to 100 Characters
        this.handleInput(event, "string", 100);

        // if type is integer use int validation
        if (
          item.type &&
          item.type.key &&
          item.type.key.toString().includes("integer")
        ) {
          this.handleInput(event, "int");
        }
      }
    },
  },
};
</script>
