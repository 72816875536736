<template>
  <v-dialog :value="value" max-width="500px">
    <v-card>
      <v-card-title>
        <span>{{
          $t((orderReminderSchema ? "edit" : "add") + "_order_reminder_schema")
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="form.at_counter"
            ref="at_counter"
            :rules="atCounterRules"
            @keypress="handleInput($event, 'int', 2)"
            :label="$t('at_counter_label')"
            required
          ></v-text-field>
          <v-text-field
            v-model="form.next_reminder_in_days"
            ref="next_reminder_in_days"
            :rules="nextReminderInDaysRules"
            @keypress="handleInput($event, 'int', 2)"
            :label="$t('next_reminder_in_days_label')"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close" class="mr-4"
          ><CloseButton :disabled="loading"
        /></span>
        <span @click="submit"
          ><SaveButton :loading="loading"></SaveButton
        ></span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import _axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  name: "OrderReminderSchemaForm",
  components: { SaveButton, CloseButton },
  emits: ["created", "input", "form", "updated"],
  props: {
    orderReminderSchema: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        at_counter: null,
        next_reminder_in_days: null,
      },
      loading: false,
      atCounterRules: [
        (v) => !!v || this.$t("at_counter_is_required"),
        (w) => (w && w.length <= 2) || this.$t("at_counter_max_length"),
        (x) => (x && x >= 2) || this.$t("at_counter_min_value"),
        (y) => (y && y <= 30) || this.$t("at_counter_max_value"),
      ],
      nextReminderInDaysRules: [
        (v) => !!v || this.$t("next_reminder_in_days_required"),
        (w) => (w && w.length <= 2) || this.$t("at_counter_max_length"),
        (x) => (x && x >= 2) || this.$t("at_counter_min_value"),
        (y) => (y && y <= 30) || this.$t("at_counter_max_value"),
      ],
      valid: true,
    };
  },
  watch: {
    value(val) {
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });

      if (!val) {
        return (this.form = {
          at_counter: "",
          next_reminder_in_days: "",
        });
      }

      if (this.orderReminderSchema) {
        this.form = {
          at_counter: parseInt(this.orderReminderSchema.at_counter),
          next_reminder_in_days: parseInt(
            this.orderReminderSchema.next_reminder_in_days
          ),
        };
      }
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    submit() {
      if (!this.$refs.form.validate() && !this.orderReminderSchema) {
        return;
      }

      this.$refs.form.resetValidation();
      this.loading = true;

      (this.orderReminderSchema
        ? _axios.put(
            API_BASE_URL +
              "/order_reminder_schemas/" +
              this.orderReminderSchema.id,
            this.form
          )
        : _axios.post(API_BASE_URL + "/order_reminder_schemas", this.form)
      )
        .then((response) => {
          this.loading = false;
          this.$emit("created", response.data);
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },
  },
};
</script>
