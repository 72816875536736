<template>
  <v-card flat>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :checkboxText.sync="confirmationDialogReCheckBoxConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-form
      ref="form"
      class="main_form"
      id="client-form"
      @submit.prevent="submit"
    >
      <v-btn type="submit" class="d-none" id="submit-client"></v-btn>
      <input type="hidden" name="id" v-model="form.id" />
      <v-container fluid>
        <!-- Entity Type, Name, First Name, Denomination -->
        <v-row>
          <!-- Entity Type -->
          <v-col cols="12" sm="1">
            <v-select
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              v-model="form.entityType"
              :items="entityTypeSelectItems"
              class="to-upper"
              :label="$t('clients.field.entity_type')"
              item-text="text"
              item-value="id"
              return-object
              :disabled="
                this.operation === 'edit' &&
                form.defaultEntityTypeOfClient &&
                form.defaultEntityTypeOfClient.id == 1
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-select>
          </v-col>
          <!-- Name -->
          <v-col cols="12" :sm="isCompanyEntitySelected ? 10 : 6">
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              v-model="form.name"
              class="to-upper"
              name="name"
              v-on:keydown="handleInput($event, 'everything', 50)"
              :error-messages="
                $store.state.validation && !$v.form.name.required
                  ? [this.$t('clients.text_messages.name_required')]
                  : []
              "
              :label="
                this.isCompanyEntitySelected
                  ? $t('clients.field.company_name')
                  : $t('clients.field.family_name')
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <!-- First Name -->
          <v-col
            cols="12"
            v-if="!isCompanyEntitySelected"
            :sm="isCompanyEntitySelected ? 10 : 5"
          >
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              v-model="form.first_name"
              class="to-upper"
              name="first_name"
              v-on:keydown="handleInput($event, 'everything', 50)"
              :error-messages="
                $store.state.validation && !$v.form.name.required
                  ? [this.$t('clients.text_messages.name_required')]
                  : []
              "
              :label="
                this.isCompanyEntitySelected
                  ? $t('clients.field.company_name')
                  : $t('clients.field.first_name')
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <!-- Denominations -->
          <v-col cols="12" sm="1" v-if="isCompanyEntitySelected">
            <v-select
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              v-model="form.denomination"
              :items="denominations"
              class="to-upper"
              item-value="id"
              item-text="text"
              :label="$t('clients.field.denomination')"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-select>
          </v-col>
        </v-row>

        <!-- Family Client, Status, Currency, Language -->
        <v-row>
          <!-- Family Client -->
          <v-col cols="12" sm="3">
            <v-select
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              v-model="form.familyClient"
              :items="familyClients"
              item-value="id"
              item-text="name"
              class="to-upper"
              :label="$t('clients.field.family_client')"
              menu-props="auto"
              :error-messages="
                $store.state.validation && !$v.form.familyClient.required
                  ? [this.$t('clients.text_messages.family_client_required')]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-select>
          </v-col>
          <!-- Status -->
          <v-col cols="12" sm="3">
            <v-select
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              v-model="form.status"
              :items="clientStatuses"
              class="to-upper"
              item-value="id"
              item-text="text"
              :disabled="checkIfStatusToBeDisabled(form.status)"
              :background-color="statusBackColor(form.status)"
              :label="$t('clients.field.status')"
              :error-messages="
                $store.state.validation && !$v.form.status.required
                  ? [this.$t('clients.text_messages.status_required')]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-select>
          </v-col>
          <!-- Currency -->
          <v-col cols="12" sm="3">
            <v-select
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              class="to-upper"
              v-model="form.currency"
              :items="clientCurrencies"
              item-value="id"
              item-text="text"
              :label="$t('clients.field.currency')"
              :error-messages="
                $store.state.validation && !$v.form.currency.required
                  ? [this.$t('clients.text_messages.currency_required')]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-select>
          </v-col>
          <!-- Language -->
          <v-col cols="12" sm="3">
            <v-select
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              class="to-upper"
              v-model="form.language"
              :items="languages"
              item-value="id"
              item-text="text"
              :label="$t('clients.field.language')"
              :error-messages="
                $store.state.validation && !$v.form.language.required
                  ? [this.$t('clients.text_messages.language_required')]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-select>
          </v-col>
        </v-row>

        <!-- Email, Phone, GSM, Fax -->
        <v-row>
          <!-- Email -->
          <v-col cols="12" sm="3">
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              v-model="form.email"
              class="to-upper"
              v-on:keydown="handleInput($event, 'email', 60)"
              :error-messages="
                $store.state.validation && !$v.form.email.email
                  ? [this.$t('clients.text_messages.invalid_email')]
                  : []
              "
              :label="$t('clients.field.email')"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <!-- Phone -->
          <v-col cols="12" sm="3">
            <vue-tel-input-vuetify
              :disabledFetchingCountry="true"
              :preferredCountries="preferredCountry"
              :filterCountries="onlyCountries"
              validCharactersOnly
              :label="$t('clients.field.phone')"
              :inputOptions="{ showDialCode: true, tabindex: 0 }"
              :mode="'international'"
              v-model="form.phone"
            ></vue-tel-input-vuetify>
          </v-col>
          <!-- GSM -->
          <v-col cols="12" sm="2">
            <vue-tel-input-vuetify
              :disabledFetchingCountry="true"
              :preferredCountries="preferredCountry"
              :filterCountries="onlyCountries"
              validCharactersOnly
              :label="$t('clients.field.gsm')"
              :inputOptions="{ showDialCode: true, tabindex: 0 }"
              :mode="'international'"
              v-model="form.gsm"
            ></vue-tel-input-vuetify>
          </v-col>
          <!-- Fax -->
          <v-col>
            <vue-tel-input-vuetify
              :disabledFetchingCountry="true"
              :preferredCountries="preferredCountry"
              :filterCountries="onlyCountries"
              validCharactersOnly
              :label="$t('clients.field.fax')"
              :inputOptions="{ showDialCode: true, tabindex: 0 }"
              :mode="'international'"
              v-model="form.fax"
            ></vue-tel-input-vuetify>
          </v-col>
          <v-tooltip
            bottom
            v-if="
              (isAnythingFilledForFirstRow && !showSecondRow) || !showSecondRow
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="mt-8"
                v-if="
                  (isAnythingFilledForFirstRow && !showSecondRow) ||
                  !showSecondRow
                "
                v-bind="attrs"
                v-on="on"
                @click="showSecondRow = true"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("clients.add_another_contact_person") }}
            </span>
          </v-tooltip>
        </v-row>

        <!-- Second Row of E-Mail, GSM, Phone -->
        <v-row v-if="showSecondRow">
          <!-- Name -->
          <v-col class="pt-0 pb-0" cols="12" :sm="4">
            <v-text-field
              class="to-upper"
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              ref="name"
              v-model="form.contact_name_2"
              :label="$t('billings.company_contact_name_2')"
              required
              :hide-details="false"
              v-on:keydown="handleInput($event, 'everything', 50)"
              no-filter
              v-on:keydown.enter.prevent=""
            >
            </v-text-field>
          </v-col>
          <!-- Email -->
          <v-col class="pt-0 pb-0">
            <EmailInput
              v-model="form.email_2"
              :email_position="1"
              :client_id="clientId"
              :label="$t('billings.email_2')"
              :error_messages="
                $store.state.validation && !$v.form.email_2.email
                  ? [this.$t('billings.text_messages.invalid_email')]
                  : []
              "
              :show_icon_for_verification="false"
            ></EmailInput>
          </v-col>
          <!-- GSM -->
          <v-col class="pt-0 pb-0">
            <vue-tel-input-vuetify
              :disabledFetchingCountry="true"
              :preferredCountries="preferredCountry"
              :filterCountries="onlyCountries"
              validCharactersOnly
              :label="$t('billings.gsm_2')"
              :inputOptions="{ showDialCode: true, tabindex: 0 }"
              :mode="'international'"
              v-model="form.gsm_2"
            ></vue-tel-input-vuetify>
          </v-col>
          <!-- Phone -->
          <v-col class="pt-0 pb-0">
            <vue-tel-input-vuetify
              :disabledFetchingCountry="true"
              :preferredCountries="preferredCountry"
              :filterCountries="onlyCountries"
              validCharactersOnly
              :label="$t('billings.phone_2')"
              :inputOptions="{ showDialCode: true, tabindex: 0 }"
              :mode="'international'"
              v-model="form.phone_2"
            ></vue-tel-input-vuetify>
          </v-col>
          <v-tooltip
            bottom
            v-if="
              (isAnythingFilledForThirdRow && !showThirdRow) || !showThirdRow
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-4"
                v-if="
                  (isAnythingFilledForThirdRow && !showThirdRow) ||
                  !showThirdRow
                "
                icon
                v-bind="attrs"
                v-on="on"
                @click="showThirdRow = true"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("clients.add_another_contact_person") }}
            </span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              (isAnythingFilledForThirdRow && !showThirdRow) || !showThirdRow
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-4"
                v-if="
                  (isAnythingFilledForThirdRow && !showThirdRow) ||
                  !showThirdRow
                "
                icon
                v-bind="attrs"
                v-on="on"
                @click="showSecondRow = false"
              >
                <v-icon color="primary">mdi-minus</v-icon>
              </v-btn>
            </template>
            <span> {{ $t("clients.remove_contact_person") }} </span>
          </v-tooltip>
        </v-row>

        <!-- Third Row of E-Mail, GSM, Phone -->
        <v-row v-if="showThirdRow">
          <!-- Name -->
          <v-col class="pt-0 pb-0" cols="12" :sm="4">
            <v-text-field
              class="to-upper"
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              ref="name"
              v-model="form.contact_name_3"
              :label="$t('billings.company_contact_name_3')"
              required
              :hide-details="false"
              v-on:keydown="handleInput($event, 'everything', 50)"
              no-filter
              v-on:keydown.enter.prevent=""
            >
            </v-text-field>
          </v-col>
          <!-- Email -->
          <v-col class="pt-0 pb-0">
            <EmailInput
              v-model="form.email_3"
              :email_position="1"
              :client_id="clientId"
              :label="$t('billings.email_3')"
              :error_messages="
                $store.state.validation && !$v.form.email_3.email
                  ? [this.$t('billings.text_messages.invalid_email')]
                  : []
              "
              :show_icon_for_verification="false"
            ></EmailInput>
          </v-col>
          <!-- GSM -->
          <v-col class="pt-0 pb-0">
            <vue-tel-input-vuetify
              :disabledFetchingCountry="true"
              :preferredCountries="preferredCountry"
              :filterCountries="onlyCountries"
              validCharactersOnly
              :label="$t('billings.gsm_3')"
              :inputOptions="{ showDialCode: true, tabindex: 0 }"
              :mode="'international'"
              v-model="form.gsm_3"
            ></vue-tel-input-vuetify>
          </v-col>
          <!-- Phone -->
          <v-col class="pt-0 pb-0">
            <vue-tel-input-vuetify
              :disabledFetchingCountry="true"
              :preferredCountries="preferredCountry"
              :filterCountries="onlyCountries"
              validCharactersOnly
              :label="$t('billings.phone_3')"
              :inputOptions="{ showDialCode: true, tabindex: 0 }"
              :mode="'international'"
              v-model="form.phone_3"
            ></vue-tel-input-vuetify>
          </v-col>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-4"
                icon
                v-bind="attrs"
                v-on="on"
                @click="showThirdRow = false"
              >
                <v-icon color="primary">mdi-minus</v-icon>
              </v-btn>
            </template>
            <span> {{ $t("clients.remove_contact_person") }} </span>
          </v-tooltip>
        </v-row>

        <!-- Registration Code, TVA, Web -->
        <v-row>
          <!-- Registration Code -->
          <v-col cols="12" sm="4" v-if="isCompanyEntitySelected">
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              class="to-upper"
              v-model="form.registration_code"
              v-on:keydown="handleInput($event, 'justalphanumeric', 45)"
              :label="$t('clients.field.registration_number')"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <!-- TVA -->
          <v-col cols="12" sm="4" v-if="isCompanyEntitySelected">
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              class="to-upper"
              v-model="form.tva"
              @input.native="isSameCountryTva()"
              @change="
                resetVariables();
                verifyTVA(form.tva);
              "
              v-on:keydown="handleInput($event, 'justalphanumeric', 45)"
              :label="$t('clients.field.tva')"
              :error-messages="
                $store.state.validation && !$v.form.tva.required
                  ? [this.$t('clients.text_messages.tva_required')]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            >
              <template v-slot:prepend-inner>
                <template v-if="isVerifiedTVALoading">
                  <v-progress-circular
                    indeterminate
                    size="20"
                    width="2"
                    color="primary"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  <v-icon :color="isVerifiedTVA == false ? 'red' : 'green'">
                    {{
                      isVerifiedTVA
                        ? "mdi-checkbox-marked-circle"
                        : isVerifiedTVA == false
                        ? "mdi-close-circle"
                        : ""
                    }}
                  </v-icon>
                </template>
              </template>

              <template #append>
                <v-icon v-if="form.tva" @click="verifyTVA(form.tva)"
                  >mdi-refresh</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
          <!-- Web -->
          <v-col cols="12" :sm="isCompanyEntitySelected ? 4 : 12">
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              class="to-upper"
              v-model="form.web"
              v-on:keydown="handleInput($event, 'alphanumeric', 45)"
              :label="$t('clients.field.web_site')"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Client Address -->
        <v-row v-if="this.operation != 'edit' && checkTag('client_address')">
          <v-col class="pt-0 pl-0 pr-0" cols="12">
            <AddressInput
              v-model="address"
              :label="$t('clients.client_address')"
              :currentAddressType.sync="selected_address_type"
            />
          </v-col>
        </v-row>

        <!-- Reminder and Payment Terms -->
        <v-row>
          <v-col cols="12" sm="2">
            <v-checkbox
              :label="$t('clients.is_send_order_reminders')"
              class="mt-4"
              v-model="form.is_send_order_reminders"
              color="green"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="2">
            <v-checkbox
              :label="$t('clients.is_payment_credit_approved')"
              class="mt-4"
              v-model="form.is_payment_credit_approved"
              color="green"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            v-if="isCompanyEntitySelected"
            :sm="isCompanyEntitySelected ? 2 : 0"
          >
            <v-select
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              v-model="form.taxation_reason_id"
              :items="taxation_reasons"
              class="to-upper"
              item-value="id"
              item-text="text"
              :label="$t('clients.taxation_reason')"
              :outlined="is_boxes"
              :dense="is_boxes"
              :background-color="getTaxationReasonBackgroundColor(form.taxation_reason_id)"
            ></v-select>
          </v-col>
          <v-col cols="12" :sm="isCompanyEntitySelected ? 3 : 4">
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              class="to-upper"
              v-model="form.credit_limit"
              v-on:keydown="handleInput($event, 'float', 10)"
              :label="$t('clients.credit_limit')"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="isCompanyEntitySelected ? 3 : 4">
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              class="to-upper"
              v-model="form.payment_terms"
              v-on:keydown="handleInput($event, 'int', 3)"
              :label="$t('clients.payment_terms')"
              :outlined="is_boxes"
              :dense="is_boxes"
              :suffix="$t('clients.days')"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Peppol Row -->
        <v-row
          v-if="isCompanyEntitySelected && isLoggedInCompanyPeppolSupported"
        >
          <v-col cols="12" sm="2">
            <v-checkbox
              :label="$t('is_peppol')"
              class="mt-4"
              v-model="form.is_peppol"
              color="green"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :disabled="!form.is_peppol"
              :label="$t('company_settings.end_point_id')"
              :placeholder="$t('company_settings.end_point_id')"
              v-model="form.peppol_endpoint_id"
              class="to-upper mr-2"
              required
              v-on:keydown.enter.prevent=""
              v-on:keydown="$v.$touch()"
              :error-messages="
                $store.state.validation && !$v.form.peppol_endpoint_id.required
                  ? [
                      this.$t(
                        'company_settings.text_messages.peppol_endpoint_id_required'
                      ),
                    ]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :disabled="!form.is_peppol"
              :label="$t('company_settings.scheme_id')"
              :placeholder="$t('company_settings.scheme_id')"
              v-model="form.peppol_scheme_id"
              class="to-upper mr-2"
              required
              v-on:keydown.enter.prevent=""
              v-on:keydown="$v.$touch()"
              :error-messages="
                $store.state.validation && !$v.form.peppol_scheme_id.required
                  ? [
                      this.$t(
                        'company_settings.text_messages.peppol_scheme_id_required'
                      ),
                    ]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Note -->
        <v-row>
          <v-col cols="12">
            <wysiwyg v-model="form.note" />
          </v-col>
        </v-row>

        <!-- Vehicles -->
        <v-row
          class="pa-3 pt-6"
          v-if="
            this.operation != 'edit' &&
            (checkTag('vehicles') || checkTag('vehicles'))
          "
        >
          <create-edit-vehicle
            v-if="checkTag('vehicles')"
            ref="vehicleComp"
            :comp_vehicle_type.sync="parent_vehicle_type"
            :comp_registration_no.sync="parent_registration_no"
            :comp_brand.sync="parent_brand"
            :comp_model.sync="parent_model"
            :comp_km.sync="parent_km"
            :comp_reg_date.sync="parent_reg_date"
            :comp_creditor.sync="parent_creditor"
            :comp_creditors.sync="creditors"
            :comp_vin.sync="parent_vin"
            :comp_is_leasing.sync="parent_is_leasing"
          />
        </v-row>
      </v-container>
    </v-form>
    <client-tabs v-if="client.id" :client="client"></client-tabs>
    <v-btn @click="onSubmit()" class="d-none" id="submit-client"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-client"></v-btn>

    <span id="launch-interventions" @click="openHistoryTableDialog"></span>
    <history-table
      v-model="historyTableDialog"
      :model_id="historyTableModelId"
      :model="historyTableModel"
    />
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DETAILS_MODE } from "@/config";
import { ADD_MODE } from "@/config";
import Tabs from "@/components/Client/Tabs";
import ConfirmationModel from "@/components/ConfirmationModel";
import { validationMixin } from "vuelidate";
import CreateEditVehicle from "@/components/Vehicles/CreateEditVehicle";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

import VueTelInputVuetify from "@/components/PhoneNumberInput";
import AddressInput from "@/components/AddressInput";
import HistoryTable from "@/components/UserActionHistory/HistoryTable.vue";
import historyTableMixin from "@/mixins/historyTableMixin";
import EmailInput from "@/components/EmailInput.vue";

export default {
  mixins: [validationMixin, historyTableMixin],
  name: "ClientEdit",
  validations: {
    form: {
      peppol_endpoint_id: {
        required: requiredIf(function () {
          return this.isLoggedInCompanyPeppolSupported && this.form.is_peppol;
        }),
      },
      peppol_scheme_id: {
        required: requiredIf(function () {
          return this.isLoggedInCompanyPeppolSupported && this.form.is_peppol;
        }),
      },
      name: {
        required: requiredIf(function () {
          return !this.form.name.length;
        }),
      },
      email: {
        email: (val) => email((val ?? "").toLowerCase()),
      },
      email_2: {
        email: (val) => email((val ?? "").toLowerCase()),
      },
      email_3: {
        email: (val) => email((val ?? "").toLowerCase()),
      },
      tva: {
        required: requiredIf(function () {
          return this.isCompanyEntitySelected;
        }),
      },
      status: {
        required,
      },
      currency: {
        required,
      },
      language: {
        required,
      },
      familyClient: {
        required,
      },
    },
  },
  components: {
    "client-tabs": Tabs,
    "confirmation-model": ConfirmationModel,
    "create-edit-vehicle": CreateEditVehicle,
    "vue-tel-input-vuetify": VueTelInputVuetify,
    AddressInput,
    HistoryTable,
    EmailInput,
  },
  props: {
    iCompany: Boolean,
  },
  data() {
    const defaultForm = Object.freeze({
      defaultEntityTypeOfClient: null, // this will be used for edit client
      entityType: { id: 1 },
      id: "",
      company: {},
      language: this.defaultCompanyLanguage?.language_id,
      name: "",
      contact_name_2: "",
      contact_name_3: "",
      first_name: "",
      phone: "",
      phone_2: "",
      phone_3: "",
      gsm: "",
      gsm_2: "",
      gsm_3: "",
      fax: "",
      email: "",
      email_2: "",
      email_3: "",
      web: "",
      currency: localStorage.getItem("currencyId") || "USD",
      representative_company_id: "",
      user: "",
      note: "",
      status: 10041001,
      familyClient: "",
      created_at: "",
      updated_at: "",
      denomination: 10061001,
      registration_code: "",
      tva: "",
      is_send_order_reminders: true,
      is_payment_credit_approved: false,
      payment_terms: this.getCompanyPropertyFromLocalStorage(
        "DEFAULT_DOCUMENT_DUE_DAYS"
      )
        ? this.getCompanyPropertyFromLocalStorage("DEFAULT_DOCUMENT_DUE_DAYS")
            .value
        : 30,
      credit_limit: 0,
      is_peppol: false,
      peppol_endpoint_id: null,
      peppol_scheme_id: null,
      taxation_reason_id: 10771000,
    });
    return {
      showSecondRow: false,
      showThirdRow: false,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      client: {},
      DeleteConfirm: false,
      form: Object.assign({}, defaultForm),
      denominations: [],
      clientStatuses: [],
      clientCurrencies: [],
      languages: [],
      operation: "edit",
      token: this.$store.state.AccessToken,
      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      autocompleteValueLocal: "off",
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogReCheckBoxConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      isLoadingSearchAddress: false,
      isLoadingSearchCity: false,
      isLoadingSearchCounty: false,
      isLoadingSearchCountry: false,
      zipcode: null,
      addresses: [],
      address: null,
      search_address: null,
      cities: [],
      city: null,
      search_city: null,
      counties: [],
      county: null,
      search_county: null,
      country: null,
      search_country: null,

      // Vehicle Component Sync Values
      parent_vehicle_type: null,
      parent_registration_no: null,
      parent_brand: null,
      parent_model: null,
      parent_km: null,
      parent_reg_date: null,
      parent_creditor: null,
      creditors: [],
      parent_vin: null,
      parent_is_leasing: false,
      selected_address_type: null,
      taxation_reasons: [],
      company_applications: [],
      customer_balance: null,
    };
  },
    async mounted() {
        this.$store.commit("setCustomerBalance", null);
        (this.header = { Authorization: "Bearer " + this.token }),
            this.setCurrentFormOperation();
        this.getCompanyDenominations();
        this.getTaxationReasons();
        this.getStatuses();
        this.getCurrencies();
        this.getLanguages();
        this.$store.dispatch("fetchCompanyLanguages").then(() => {
            this.form.language = this.defaultCompanyLanguage?.language_id;
        });
        this.getCreditors();
        if (this.operation === "edit") {
            this.$store.commit("setFormMode", DETAILS_MODE);
            this.fetchData();
        } else {
            this.setDefaultFamilyClient();
            this.$store.commit("setFormMode", ADD_MODE);
            await this.getCompanyApplications();
        }
    },
  methods: {
    checkIfSecondRowShouldBeVisible() {
      if (this.isAnythingFilledForThirdRow) {
        this.showSecondRow = true;
      }
    },
    checkIfThirdRowShouldBeVisible() {
      if (this.isAnythingFilledInTheThirdRow) {
        this.showThirdRow = true;
      }
    },
      fetchClientBalance(client_id) {
          axios
              .get(
                  API_BASE_URL +
                  "/clients/" +
                  client_id +
                  "/pending/bill?return_total=1",
                  { headers: this.header }
              )
              .then(({ data }) => {
                  this.customer_balance = this.fixDecimal(data.data);
                  this.$store.commit("setCustomerBalance", this.customer_balance);
              })
              .catch((err) => {
                  console.log(err);
              })
              .finally(() => { });
      },
      checkIfStatusToBeDisabled(status) {
          if (this.operation == "edit" && status) {
              if (!this.isAdmin) {
                  if (
                      typeof status == "object" &&
                      status.key == "client_blocked" &&
                      this.customer_balance == null
                  ) {
                      return true;
                  }
                  if (
                      typeof status == "object" &&
                      status.key == "client_blocked" &&
                      this.customer_balance > 0
                  ) {
                      return true;
                  }
                  if (
                      typeof status == "number" &&
                      status == 10041003 &&
                      this.customer_balance == null
                  ) {
                      return true;
                  }
                  if (
                      typeof status == "number" &&
                      status == 10041003 &&
                      this.customer_balance > 0
                  ) {
                      return true;
                  }
              }
          }
          return false;
      },
    statusBackColor(status) {
      if (typeof status == "object" && status.key == "client_blocked") {
        return "#ffb9b3";
      }
      if (typeof status == "number" && status == 10041003) {
        return "#ffb9b3";
      }
      return "";
    },
    getCompanyApplications() {
      let companyID = localStorage.getItem("companyID");

      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/companies/" + companyID + "/applications", {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(({ data }) => {
            this.company_applications = data;
            resolve(this.company_applications);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    disableTaxationReason() {
      if (this.form.tva && this.userCountry) {
        return this.form.tva
          .toUpperCase()
          .startsWith(this.userCountry.toUpperCase())
          ? true
          : false;
      }
      return false;
    },
    isSameCountryTva(event) {
      let tva = event?.target?.value ?? this.form.tva;
      if (tva && tva.length > 0) {
        if (
          tva.substr(0, 2).toUpperCase() == localStorage.getItem("userCountry")
        ) {
          this.taxation_reasons.forEach((element) => {
            if (element.key == "no_reason") {
              this.form.taxation_reason_id = element.id;
            }
          });
          this.selectDefaultTaxationReasonAsNoReason();
          return true;
        } else {
          this.taxation_reasons.forEach((element) => {
            if (element.key == "intracommunity") {
              this.form.taxation_reason_id = element.id;
            }
          });
          return false;
        }
      }

      this.selectDefaultTaxationReasonAsNoReason();
      return true;
    },
    selectDefaultTaxationReasonAsNoReason() {
      this.taxation_reasons.forEach((element) => {
        if (element.key == "no_reason") {
          this.form.taxation_reason_id = element.id;
        }
      });
    },
    onInputPhoneNumber(formattedNumber, { number }) {
      this.form.phone = number.international;
    },
    getCreditors() {
      axios
        .get(API_BASE_URL + "/creditors", { headers: this.header })
        .then(({ data }) => {
          this.creditors = data.data.map((element) => {
            if (element.creditor_company) {
              element.creditor_company["is_leaser"] = element.is_leaser;
              return element.creditor_company;
            }
          });
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        });
    },
    makeNewClientAddress() {
      return {
        address: this.address,
        is_default: this.is_default ? true : false,
        address_type:
          this.selected_address_type &&
          typeof this.selected_address_type == "object"
            ? this.selected_address_type.id
            : null,
      };
    },
    makeNewVehicleData() {
      return {
        vehicle_id:
          this.parent_registration_no &&
          typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.id
            : null, // Done
        vehicle_type_id:
          this.parent_vehicle_type &&
          typeof this.parent_vehicle_type == "object"
            ? this.parent_vehicle_type.id
            : null, // Done
        registration_no:
          this.parent_registration_no &&
          typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.registration_number
            : this.parent_registration_no, // Done
        brand: this.parent_brand
          ? typeof this.parent_brand == "number"
            ? this.parent_brand
            : typeof this.parent_brand == "object"
            ? this.parent_brand.id
            : null
          : null, // Done
        model: this.parent_model ?? null, //
        km: this.parent_km ?? null, //
        reg_date: this.parent_reg_date ?? null, //
        vin: this.parent_vin ?? null, //
        is_leasing: this.parent_is_leasing ?? null, //
        creditor_id: this.parent_creditor //
          ? typeof this.parent_creditor == "object"
            ? this.parent_creditor.id
            : this.parent_creditor
          : null,
      };
    },
    setDefaultFamilyClient() {
      this.familyClients.forEach((element) => {
        if (element.is_default == 1) {
          this.form.familyClient = element;
        }
      });
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "clients.delete_confirmation"
      );
      this.confirmationDialogReCheckBoxConfirmationText = this.$t(
        "clients.text_messages.delete_client_confirmation_reconfirm_text"
      );
      this.confirmationDialogFalseText = this.$t("clients.cancel");
      this.confirmationDialogTrueText = this.$t("clients.delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
    onDelete() {
      this.deletePopUp();
    },
    fetchData() {
      axios
        .get(API_BASE_URL + "/clients/" + this.$store.state.editId, {
          headers: this.header,
        })
        .then(({ data }) => {
          this.setHistoryModalData("Client", data.data.id);
          this.client = data.data;
          this.$store.commit("setCurrentPageData", this.client);
          this.form.id = data.data.id;
          this.form.name = data.data.name;
          this.form.first_name = data.data.first_name;
          this.form.familyClient = data.data.family_client_id;
          this.form.status = data.data.status.id;
          this.form.currency = data.data.currency.id;
          this.form.language =
            typeof data.data.language == "object"
              ? data.data.language.id
              : data.data.language;
          this.form.note = data.data.note;
          this.form.company = data.data.company;
          this.form.is_send_order_reminders = data.data.is_send_order_reminders;
          this.form.is_payment_credit_approved =
            data.data.is_payment_credit_approved;
          this.form.payment_terms = data.data.payment_terms;
          this.form.credit_limit = data.data.credit_limit;

          const isCompany = !!this.form.company;
          if (isCompany) {
            // if is a company then take the details from company
            this.form.entityType = { id: 1 };
            this.form.defaultEntityTypeOfClient = { id: 1 };
            this.form.denomination = data.data.company.denomination_id;
            this.form.email = data.data.company.email;
            this.form.phone = data.data.company.phone;
            this.form.gsm = data.data.company.gsm;
            this.form.fax = data.data.company.fax;
            this.form.web = data.data.company.web;

            this.form.contact_name_2 = data.data.contact_name_2;
            this.form.contact_name_3 = data.data.contact_name_3;
            this.form.phone_2 = data.data.phone_2;
            this.form.phone_3 = data.data.phone_3;
            this.form.email_2 = data.data.email_2;
            this.form.email_3 = data.data.email_3;
            this.form.gsm_2 = data.data.gsm_2;
            this.form.gsm_3 = data.data.gsm_3;

            this.form.registration_code = data.data.company.registration_code;
            this.form.tva = data.data.company.tva;

            this.form.is_peppol = data.data.company.is_peppol;
            this.form.peppol_scheme_id = data.data.company.peppol_scheme_id;
            this.form.peppol_endpoint_id = data.data.company.peppol_endpoint_id;
            this.form.taxation_reason_id = data.data.company.taxation_reason_id;
            this.verifyTVA(this.form.tva);
          } else {
            this.form.entityType = { id: 0 };
            this.form.defaultEntityTypeOfClient = { id: 0 };
            this.form.email = data.data.email;
            this.form.phone = data.data.phone;
            this.form.gsm = data.data.gsm;
            this.form.fax = data.data.fax;
            this.form.web = data.data.web;

            this.form.contact_name_2 = data.data.contact_name_2;
            this.form.contact_name_3 = data.data.contact_name_3;
            this.form.phone_2 = data.data.phone_2;
            this.form.phone_3 = data.data.phone_3;
            this.form.email_2 = data.data.email_2;
            this.form.email_3 = data.data.email_3;
            this.form.gsm_2 = data.data.gsm_2;
            this.form.gsm_3 = data.data.gsm_3;
          }

          this.checkIfSecondRowShouldBeVisible();
          this.checkIfThirdRowShouldBeVisible();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.fetchClientBalance(this.$store.state.editId);
        });
    },
    makeSubmitData() {
      return {
        name: this.form.name, // Done
        first_name: this.form.first_name, // Done
        family_client_id:
          typeof this.form.familyClient == "object"
            ? this.form.familyClient.id
            : this.form.familyClient, // done
        denomination_id:
          this.form.entityType.id > 0 ? this.form.denomination : 0, // done
        representative_company_id: this.form.company
          ? this.form.company.id
          : null, // Not Needed
        status_id: this.form.status, // Done
        currency_id: this.form.currency, // Done
        contact_name_2: this.form.contact_name_2,
        contact_name_3: this.form.contact_name_3,
        email: this.form.email, // Done
        email_2: this.form.email_2, // Done
        email_3: this.form.email_3, // Done
        phone: this.form.phone, //
        phone_2: this.form.phone_2, //
        phone_3: this.form.phone_3, //
        gsm: this.form.gsm, //
        gsm_2: this.form.gsm_2, //
        gsm_3: this.form.gsm_3, //
        fax: this.form.fax, //
        web: this.form.web, //
        registration_code: this.form.registration_code, //
        tva: this.form.tva, // Done
        language_id: this.form.language, // Done
        note: this.form.note, // Done
        address: this.makeNewClientAddress(), // to be reviewed
        vehicle: this.makeNewVehicleData(), // to be reviewed
        is_send_order_reminders: this.form.is_send_order_reminders, //
        is_payment_credit_approved:
          this.form.is_payment_credit_approved ?? false,
        payment_terms: this.form.payment_terms, //
        credit_limit: this.form.credit_limit, //
        is_peppol: this.form.is_peppol, //
        peppol_endpoint_id: this.form.peppol_endpoint_id, //
        peppol_scheme_id: this.form.peppol_scheme_id, //
        taxation_reason_id: this.form.taxation_reason_id ?? null,
        isDirtyName: this.operation === "add" ? true : (this.form.name == this.client.name && this.form.first_name == this.client.first_name ? false : true)
      };
    },
    submit() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
        return false;
      } else {
        if (this.operation === "add") {
          this.addClient(this.makeSubmitData());
        } else {
          this.updateClient(this.makeSubmitData());
        }
      }
    },
    async getTaxationReasons() {
      await this.getCodes("taxation_reasons", "taxation_reasons").then(
        (data) => {
          this.taxation_reasons = data;
        }
      );
    },
    async getCompanyDenominations() {
      await this.getCodes("company_denomination", "company_denomination").then(
        (data) => {
          this.denominations = data;
        }
      );
    },
    getStatuses() {
      this.getCodes("client_statuses", false).then((data) => {
        this.clientStatuses = data.map((item) => {
          item.text = /^[A-Z]/.test(item.key)
            ? item.key
            : this.$t("clients." + item.key);
          return item;
        });
      });
    },
    getCurrencies() {
      this.getDataWithCache(
        "/currencies",
        "currencies",
        "clientCurrencies",
        true
      ).then((data) => {
        this.clientCurrencies = data;
      });
    },
    getLanguages() {
      return new Promise((resolve) => {
        this.getDataWithCache(
          "/languages",
          "languages",
          "languages",
          true
        ).then((data) => {
          resolve(data);
        });
      });
    },
    addAddress(data, clientResponse) {
      axios
        .post(API_BASE_URL + "/client_addresses/" + clientResponse.id, data, {
          headers: this.header,
        })
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    },
    addClient(data) {
      axios
        .post(API_BASE_URL + "/clients", data, { headers: this.header })
        .then((response) => {
          this.$toast.success(
            this.$t("clients.text_messages.client_added_successfully")
          );
          this.addAddress(this.makeNewClientAddress(), response.data.data);
          this.$router.push("/clients").catch(() => {});
        })
        .catch((error) => {
          this.$toast.error(
            this.$t("clients.text_messages." + error.response.data.errors[0])
          );
        })
        .finally(() => {});
    },
    updateClient(data) {
      axios
        .put(API_BASE_URL + "/clients/" + this.form.id, data, {
          headers: this.header,
        })
        .then(() => {
          this.$toast.success(
            this.$t("clients.text_messages.client_updated_successfully")
          );
          this.$router.push("/clients").catch(() => {});
        })
        .catch((error) => {
          this.$toast.error(
            this.$t("clients.text_messages." + error.response.data.errors[0])
          );
        })
        .finally(() => {});
    },
    deleteClient() {
      axios
        .delete(API_BASE_URL + "/clients/" + this.$store.state.editId, {
          headers: this.header,
        })
        .then(() => {
          this.$toast.success(
            this.$t("clients.text_messages.client_deleted_successfully")
          );
          this.$router.push("/clients").catch(() => {});
        })
        .catch((error) => {
          this.$toast.error(
            this.$t("clients.text_messages." + error.response.data.errors[0])
          );
        })
        .finally(() => {});
    },
    setCurrentFormOperation() {
      this.operation = this.$route.path.indexOf("/add") > 0 ? "add" : "edit";
    },
  },
  computed: {
    ...mapGetters(["defaultCompanyLanguage"]),
    clientId() {
      return this.client?.id || null;
    },
    isCompanyEntitySelected() {
      return this.form.entityType.id === 1;
    },
    isAnythingFilledForFirstRow() {
      if (
        this.language ||
        this.form.email ||
        (this.form.gsm && (this.form.gsm ?? "").trim().length >= 5) ||
        (this.form.phone && (this.form.phone ?? "").trim().length >= 5)
      ) {
        return true;
      }
      return false;
    },
    isAnythingFilledForThirdRow() {
      if (
        this.form.contact_name_2 ||
        this.form.email_2 ||
        (this.form.gsm_2 && (this.form.gsm_2 ?? "").trim().length >= 5) ||
        (this.form.phone_2 && (this.form.phone_2 ?? "").trim().length >= 5)
      ) {
        return true;
      }
      return false;
    },
    isAnythingFilledInTheThirdRow() {
      if (
        this.form.contact_name_3 ||
        this.form.email_3 ||
        (this.form.gsm_3 && (this.form.gsm_3 ?? "").trim().length >= 5) ||
        (this.form.phone_3 && (this.form.phone_3 ?? "").trim().length >= 5)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    forceReloadComponentKey() {
      this.form.language = this.defaultCompanyLanguage?.language_id;
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "delete" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.deleteClient();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
};
</script>

<style scoped>
.v-data-table__wrapper {
  height: calc(-700px + 100vh) !important;
}
</style>
