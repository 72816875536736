<template>
  <v-dialog v-model="duplicate_expedition_dialog" persistent max-width="800px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{
          $t("expeditions.text_messages.cancellation_title")
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-textarea
          :autocomplete="autocompleteValueLocal"
          v-model="cancellationnote"
          class="to-upper"
          :label="$t('expeditions.text_messages.cancellation_text_label')"
          v-on:keydown.enter.prevent=""
          v-on:keydown="handleInput($event, 'alphanumeric', 80)"
        ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn color="grey-lighten-2" @click="close()">
          {{ $t("receptions.modify_article.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn
          :disabled="!cancellationnote"
          class="text-white-color"
          color="green"
          @click="save()"
        >
          {{ $t("receptions.modify_article.save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "DuplicateExpedition",
  emits: ["input", "close", "save"],
  validations: {
    cancellationnote: {
      required,
    },
  },
  components: {},
  props: {
    label: {
      default: null,
    },
    value: {
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      duplicate_expedition_dialog: this.dialog,
      cancellationnote: "",
      autocompleteValueLocal: "off",
    };
  },
  methods: {
    close() {
      this.duplicate_expedition_dialog = false;
      this.$emit("cancellationNote", null);
      this.$emit("update:dialog", false);
      this.$emit("close");
    },
    save() {
      this.duplicate_expedition_dialog = false;
      this.$emit("cancellationNote", this.cancellationnote);
      this.$emit("update:dialog", false);
      this.$emit("save");
    },
  },
};
</script>
