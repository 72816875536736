<template>
  <div>
    <v-dialog :value="value" max-width="1200" scrollable>
      <v-form @submit.prevent="submitHandler()">
        <v-card>
          <v-card-title>
            {{ employeeContract ? $t("edit") : $t("add") }}
            {{ translate("employee_contract") }}
          </v-card-title>
          <v-card-text>
            <v-row v-if="employee">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="employee.first_name"
                  :label="translate('first_name')"
                  class="to-upper"
                  required
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="employee.last_name"
                  :label="translate('last_name')"
                  class="to-upper"
                  required
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="employee.internal_name"
                  :label="translate('internal_name')"
                  class="to-upper"
                  required
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="employee.national_id"
                  :label="translate('national_id')"
                  class="to-upper"
                  required
                />
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field
                  v-model="employee.badge"
                  :label="translate('badge')"
                  class="to-upper"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col :sm="4">
                <v-select
                  v-model="form.contract_id"
                  :items="employmentContracts"
                  item-text="name"
                  item-value="id"
                  :label="translate('employment_contract')"
                />
              </v-col>

              <v-col :sm="3">
                <v-menu
                  v-model="startDtePickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formattedStartDate"
                      :label="translate('start_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="userLanguageId"
                    v-model="form.start_date"
                    @input="startDtePickerMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col :sm="3">
                <v-menu
                  v-model="endDatePickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formattedEndDate"
                      :label="translate('end_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :disabled="form.start_date === null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.end_date"
                    @input="endDatePickerMenu = false"
                    :min="form.start_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col :sm="2">
                <v-checkbox
                  v-model="form.is_active"
                  :label="translate('is_active')"
                ></v-checkbox>
              </v-col>

              <v-col cols="12">
                <v-card-title
                  style="font-size: 15px; margin-bottom: 5px; padding-bottom: 0"
                >
                  <h3>
                    {{ translate("add_custom_detail") }}
                  </h3>
                </v-card-title>

                <div class="d-flex justify-content-between">
                  <v-checkbox
                    v-for="day in [
                      'monday',
                      'tuesday',
                      'wednesday',
                      'thursday',
                      'friday',
                      'saturday',
                      'sunday',
                    ]"
                    v-model="dayOfWeek[day]"
                    :label="$t('days_long.' + day)"
                    :key="day"
                    @click="duplicateMondayDetailsForDay(day)"
                  ></v-checkbox>
                </div>
                <v-simple-table>
                  <tbody>
                    <tr
                      v-for="visibleDay in visibleDaysOfWeek"
                      :key="visibleDay"
                    >
                      <td>{{ $t("days_long." + visibleDay) }}</td>
                      <td style="width: 150px">
                        <v-text-field
                          :label="translate('working_hours')"
                          v-model="form.details[visibleDay].working_hours"
                          @keydown="handleInput($event, 'int', 2)"
                        />
                      </td>
                      <td style="width: 150px">
                        <v-text-field
                          :label="translate('break_minutes_per_day')"
                          v-model="
                            form.details[visibleDay].break_minutes_per_day
                          "
                          @keydown="handleInput($event, 'int', 4)"
                        />
                      </td>
                      <td>
                        <v-text-field
                          type="time"
                          :label="translate('start_time')"
                          v-model="form.details[visibleDay].start_time"
                        />
                      </td>
                      <td>
                        <v-text-field
                          :label="translate('end_time')"
                          type="time"
                          v-model="form.details[visibleDay].end_time"
                        />
                      </td>
                      <td>
                        <v-text-field
                          :label="translate('start_break_time')"
                          type="time"
                          v-model="form.details[visibleDay].start_break_time"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <span>
              <v-btn
                v-if="employeeContract"
                @click="confirmDelete"
                color="red"
                style="color: #ffffff"
              >
                {{ translate("delete_employee") }}
                <v-icon class="ml-2">mdi-trash-can</v-icon>
              </v-btn>
            </span>
            <span @click="close()" class="mx-2"><CloseButton /></span>
            <span><SaveButton /></span>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      :checkboxText.sync="confirmationDialogReCheckBoxConfirmationText"
      @buttonClicked="(e) => confirmationButtonClicked(e, deleteHandler)"
    />
  </div>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import clockingMixin from "@/mixins/clockingMixin";
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
import ConfirmationModelMixin from "@/mixins/ConfirmationModelMixin";
export default {
  name: "EmployeeContractForm",
  components: { SaveButton, CloseButton },
  emits: ["input", "created", "updated", "deleted"],
  mixins: [clockingMixin, ConfirmationModelMixin],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    employeeContract: {
      default: null,
    },
  },
  async mounted() {
    this.employmentContracts = await this.getEmploymentContracts();
    this.employees = await this.getEmployees(null, false, true);
  },
  data() {
    return {
      startDtePickerMenu: false,
      endDatePickerMenu: false,
      employee: {
        first_name: "",
        last_name: "",
        national_id: "",
        badge: "",
      },
      form: {
        start_date: null,
        end_date: null,
        contract_id: null,
        employee_id: null,
        is_active: true,
        details: {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {},
        },
      },
      dayOfWeek: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      daysOfTheWeek: [
        "",
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
      init: false,
      initialised: false,
    };
  },
  computed: {
    visibleDaysOfWeek() {
      return Object.keys(this.dayOfWeek).filter((day) => this.dayOfWeek[day]);
    },
    formDetails() {
      return this.form.details;
    },
    formattedStartDate() {
      return this.form.start_date
        ? this.formatDateWithLocale(this.form.start_date, true)
        : "";
    },
    formattedEndDate() {
      return this.form.end_date
        ? this.formatDateWithLocale(this.form.end_date, true)
        : "";
    },
  },

  watch: {
    dayOfWeek: {
      handler() {
        // loop through the days of the week and set the details to empty object if the day is not selected
        for (const day in this.form.details) {
          if (!this.dayOfWeek[day]) {
            this.form.details[day] = {};
          }
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        this.init = false;
        this.initialised = false;
        if (!val) {
          this.resetForm();
          this.resetVisibleDays();
        }

        if (val && this.employeeContract) {
          this.setFormData();
        }
      },
      immediate: true,
    },
    "form.contract_id": {
      handler(val) {
        if (!val) return false;

        if (this.employeeContract && !this.init) {
          return (this.init = true);
        }

        let contract = this.employmentContracts.find(
          (contract) => contract.id === val
        );

        let workDays = contract.work_days;
        let w = [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ];

        for (let i = 0; i < workDays; i++) {
          this.dayOfWeek[w[i]] = true;
        }

        this.form.details.monday.working_hours = contract.work_hours_per_day;
        this.form.details.monday.break_minutes_per_day =
          contract.break_time_per_day;
        this.form.details.monday.start_time = contract.default_start_time;
        this.form.details.monday.end_time = contract.default_end_time;
        this.form.details.monday.start_break_time =
          contract.default_start_break_time;

        this.duplicateMondayDetails();
        this.init = true;
      },
      immediate: true,
    },
    "formDetails.monday": {
      handler() {
        if (this.employeeContract && !this.initialised) {
          return (this.initialised = true);
        }
        this.duplicateMondayDetails();
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    confirmDelete() {
      this.popConfirmation(
        this.translate("delete_employee_confirmation"),
        "deleteHandler",
        null,
        null,
        this.$t("billings.convert_to_credit_note_confirmation_reconfirm_text")
      );
    },
    validateForm() {
      // All Fields are required including all visible days of the week details

      if (
        !this.isset(this.form.contract_id) ||
        !this.isset(this.employee.first_name) ||
        !this.isset(this.employee.last_name) ||
        !this.isset(this.form.start_date)
      ) {
        let errorMessage = !this.isset(this.form.contract_id)
          ? this.translate("employment_contract_error")
          : !this.isset(this.form.start_date)
          ? this.translate("start_date_error")
          : !this.isset(this.employee.first_name)
          ? this.translate("first_name_error")
          : this.translate("last_name_error");

        this.$toast.error(errorMessage);
        return false;
      }

      // Loop through the visible days of the week and validate the details
      for (const day of this.visibleDaysOfWeek) {
        if (!this.isset(this.form.details[day])) continue;
        if (
          !this.isset(this.form.details[day].working_hours) ||
          !this.isset(this.form.details[day].start_time) ||
          !this.isset(this.form.details[day].start_break_time)
        ) {
          let errorMessage = !this.isset(this.form.details[day].working_hours)
            ? "Working Hours is required"
            : !this.isset(this.form.details[day].start_time)
            ? "Start Time is required"
            : !this.isset(this.form.details[day].end_time)
            ? "End Time is required"
            : "Start Break Time is required";

          this.$toast.error(errorMessage);
          return false;
        }
      }

      return true;
    },
    normalizeFormData() {
      let data = JSON.parse(JSON.stringify(this.form));
      data.employee = this.employee;
      data.first_name = this.employee.first_name;
      data.internal_name = this.employee.internal_name;
      data.last_name = this.employee.last_name;
      data.national_id = this.employee.national_id;
      data.badge = this.employee.badge;
      let details = data.details;
      data.details = this.visibleDaysOfWeek.map((day) => {
        let dayOfWeekMap = [
          "",
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];
        let dayIndex = dayOfWeekMap.indexOf(day);
        return {
          dow: dayIndex,
          working_hours: details[day].working_hours,
          break_minutes_per_day: details[day].break_minutes_per_day,
          start_time: details[day].start_time,
          end_time: details[day].end_time,
          start_break_time: details[day].start_break_time,
        };
      });

      return data;
    },
    extractTimeHourAsNumber(time) {
      return parseInt(time.split(":")[0]);
    },
    resetVisibleDays() {
      for (const day in this.dayOfWeek) {
        this.dayOfWeek[day] = false;
      }
    },
    resetForm() {
      this.employee = {
        first_name: "",
        last_name: "",
        national_id: "",
        badge: "",
      };
      return (this.form = {
        start_date: null,
        end_date: null,
        contract_id: null,
        employee_id: null,
        is_active: true,
        details: {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {},
        },
      });
    },
    setFormData() {
      if (!this.employeeContract) return false;
      let val = this.clone(this.employeeContract);

      this.employee = this.employees.find(
        (employee) => employee.id === val.employee_id
      );
      this.form = {
        start_date: val.start_date,
        end_date: val.end_date,
        contract_id: val.contract_id,
        employee_id: val.employee_id,
        is_active: val.is_active,
        details: {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {},
        },
      };

      // set visible week days
      this.resetVisibleDays();
      let details = val.details;

      for (const index in details) {
        let detail = details[index];
        let day = this.daysOfTheWeek[detail.dow];
        this.dayOfWeek[day] = true;
        this.form.details[day] = {
          working_hours: detail.working_hours,
          break_minutes_per_day: detail.break_minutes_per_day,
          start_time: this.convertIntToTime(detail.start_time),
          end_time: this.convertIntToTime(detail.end_time),
          start_break_time: detail.start_break_time,
        };
      }
    },
    convertIntToTime(hour) {
      if (!hour) return "";
      if (hour.split(":").length > 1) return hour;
      return (hour.toString().length === 1 ? `0${hour}` : hour) + ":00";
    },
    submitHandler() {
      if (!this.validateForm()) {
        return false;
      }

      if (this.employeeContract) {
        return this.updateHandler();
      }

      axios
        .post(API_BASE_URL + "/employees/contracts", this.normalizeFormData(), {
          headers: this.axiosOptions,
        })
        .then(async (response) => {
          this.$toast.success(
            this.translate("employee_contract_created_successfully")
          );
          this.close();
          this.$emit("created", response.data.data);
          this.employees = await this.getEmployees(null, false, true);
        })
        .catch((error) => {
          console.log(error.response);
          let errorMessage = this.filterFirstError(error.response.data.errors);
          this.$toast.error(
            errorMessage || "An error occurred while adding employee contract"
          );
        });
    },
    updateHandler() {
      if (!this.validateForm()) {
        return false;
      }

      axios
        .put(
          API_BASE_URL + "/employees/contracts/" + this.employeeContract.id,
          this.normalizeFormData(),
          { headers: this.axiosOptions }
        )
        .then(async (response) => {
          this.$toast.success(
            this.translate("employee_contract_updated_successfully")
          );
          this.close();
          this.$emit("updated", response.data.data);
          this.employees = await this.getEmployees(null, false, true);
        })
        .catch((error) => {
          console.log(error.response);
          this.$toast.error(
            "An error occurred while updating employee contract"
          );
        });
    },
    deleteHandler() {
      axios
        .delete(
          API_BASE_URL + "/employees/" + this.employeeContract.employee.id,
          { headers: this.axiosOptions }
        )
        .then(async () => {
          this.$toast.success(this.translate("employee_deleted_successfully"));
          this.close();
          this.$emit("deleted", this.employeeContract);
          this.employees = await this.getEmployees(null, false, true);
        })
        .catch((error) => {
          console.log(error.response);
          this.$toast.error(
            "An error occurred while deleting employee contract"
          );
        });
    },
    duplicateMondayDetails() {
      let mondayDetails = this.form.details.monday;
      for (const day in this.form.details) {
        if (day === "monday") continue;
        this.form.details[day] = JSON.parse(JSON.stringify(mondayDetails));
      }
    },
    duplicateMondayDetailsForDay(day) {
      if (day === "monday" || !this.dayOfWeek[day]) return false;
      let mondayDetails = this.form.details.monday;
      this.form.details[day] = JSON.parse(JSON.stringify(mondayDetails));
    },
  },
};
</script>
