<template>
  <div class="d-flex">
    <div class="multi-button d-flex">
      <button
        v-for="(item, index) in buttonArray"
        :key="index"
        @click="setModelValue(item)"
        :class="getClassForButtons(item, index)"
      >
        x{{ item }}
      </button>
      <div :class="checkIfExists() ? 'beforeDifferentColor' : 'differentColor'">
        <v-text-field
          style="max-width: 44px"
          :class="
            item == stockUnits
              ? 'active-button center-aligned-input stock_input'
              : 'center-aligned-input stock_input'
          "
          dense
          class="mt-0"
          @focus="$event.target.select()"
          @click="$event.target.select()"
          v-on:keydown="handleInput($event, 'int', 50)"
          hide-details="true"
          v-model="stockUnits"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClientPriceInput",
  data() {
    return {
      stockUnits: this.value,
      buttonArray: [1, 2, 4],
    };
  },
  props: {
    value: {
      default: 4,
    },
    marginRight: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    stockUnits(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.stockUnits = val;
    },
  },
  methods: {
    checkIfExists() {
      let exists = false;
      this.buttonArray.forEach((element) => {
        if (element == this.stockUnits) {
          exists = true;
        }
      });
      return exists;
    },
    setModelValue(value) {
      this.stockUnits = value;
    },
    getClassForButtons(item, index) {
      let className = "heightStyle";
      if (item == this.stockUnits) {
        className += " active-button";
      }
      if (index == this.buttonArray.length - 1) {
        className += " last-button";
      }
      return className;
    },
  },
};
</script>
<style scoped>
.text-right >>> input {
  text-align: right;
  padding-right: 2px;
  width: 100%;
}

.multi-button {
  background-color: #eee;
  border-radius: 4px;
  width: 142px !important;
  border: 1px solid #d9d4d4;
}
.multi-button button {
  height: 25px;
  width: 32px;
  border: none;
  background-color: inherit;
}
.multi-button button:hover {
  background-color: #aacef1;
}
.multi-button button:first-child {
  border-radius: 3px 0 0 3px;
}
.multi-button button:last-child {
  border-radius: 0 3px 3px 0;
}
.active-button {
  background-color: #1976d2 !important;
  color: white !important;
}
.last-button {
  border-right: 1px solid #d9d4d4 !important;
}

.center-aligned-input >>> input {
  text-align: center;
}

.stock_input >>> .v-input__slot::before,
.stock_input >>> .v-input__slot::after {
  border: none !important;
}
.differentColor >>> input {
  color: white !important; /* Change 'red' to any color you prefer */
}
.differentColor {
  background-color: #1976d2;
  border-radius: 0 3px 3px 0;
  color: white !important;
}
.beforeDifferentColor {
  background-color: #ffffffff;
  border-radius: 0 3px 3px 0;
  color: white !important;
}
.heightStyle {
  height: 30px !important;
}
</style>
