import { render, staticRenderFns } from "./RentalHistoryTab.vue?vue&type=template&id=24f7ec7e&scoped=true"
import script from "./RentalHistoryTab.vue?vue&type=script&lang=js"
export * from "./RentalHistoryTab.vue?vue&type=script&lang=js"
import style0 from "./RentalHistoryTab.vue?vue&type=style&index=0&id=24f7ec7e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f7ec7e",
  null
  
)

export default component.exports