<template>
  <v-dialog :value="value" max-width="800px">
    <v-card>
      <v-card-title>Clocking for {{ date }}</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ translate("type") }}</th>
                <th class="text-left">{{ translate("check_in") }}</th>
                <th class="text-left">{{ translate("check_out") }}</th>
                <th class="text-left">{{ translate("difference") }}</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="clock in clocking" :key="clock.id">
                <td>{{ translateClockingCode(clock.clocking_type_id) }}</td>
                <td>{{ clock.check_in_time_only }}</td>
                <td>{{ clock.check_out_time_only }}</td>
                <td>{{ convertMinutesToHours(clock.time_difference) }}</td>
                <td>
                  <v-btn icon @click="launchConfirmationDialog(clock.id)">
                    <v-icon color="red">mdi-trash-can</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span
          class="mr-2"
          @click="
            () => {
              close();
              getElementById('add-new-clocking-for-day').click();
            }
          "
          ><AddButton
        /></span>
        <span class="mr-2" @click="close()"><CloseButton /></span>
      </v-card-actions>
    </v-card>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    >
    </confirmation-model>
  </v-dialog>
</template>
<script>
import _axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import CloseButton from "@/components/Buttons/CloseButton.vue";
import ClockingMixin from "@/mixins/clockingMixin";
import confirmationModel from "@/components/ConfirmationModel.vue";
import AddButton from "@/components/Buttons/AddButton.vue";

export default {
  name: "EmployeeClockingForDay",
  components: { AddButton, CloseButton, confirmationModel },
  mixins: [ClockingMixin],
  emits: ["input", "deleted"],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: null,
    },
    employee_contract_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      clocking: [],
      clockingTypes: [],
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: this.$t("no"),
      confirmationDialogTrueText: this.$t("yes"),
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
    };
  },
  async mounted() {
    this.clockingTypes = (await this.getCodes("clocking_types", false)).filter(
      (i) => i.value !== "deleted"
    );
  },
  watch: {
    value: function (val) {
      if (val) {
        this.getClockingForDay();
      }
    },
  },
  methods: {
    getClockingForDay() {
      _axios
        .get(
          API_BASE_URL +
            `/employees/clocking/date?date=${this.date}&employee_contract_id=${this.employee_contract_id}`
        )
        .then(({ data }) => {
          this.clocking = data.data;
        });
    },
    close() {
      this.$emit("input", false);
    },
    translateClockingCode(id) {
      const code = this.clockingTypes.find((c) => c.id === id);
      return this.translate("types." + code.key);
    },
    launchConfirmationDialog(toBeDeleted) {
      this.confirmationDialog = true;
      this.confirmationDialogConfirmationText = this.translate(
        "delete_clocking_confirmation_text"
      );
      this.confirmationDialogOperation = toBeDeleted;
    },
    confirmationButtonClicked(value) {
      if (value) {
        this.deleteClockingRecord();
      }
    },
    deleteClockingRecord() {
      _axios
        .delete(
          API_BASE_URL +
            `/employees/clocking/${this.confirmationDialogOperation}`
        )
        .then(() => {
          this.getClockingForDay();
          this.$emit("deleted");
        });
    },
  },
};
</script>
