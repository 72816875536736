<template>
  <v-dialog v-model="dialog" max-width="450px">
    <v-card class="pa-5">
      <v-card-title class="text-center">
        <h3 class="text-primary font-weight-bold">
          {{ $t("please_enter_otp") }}
        </h3>
      </v-card-title>
      <v-card-text>
        <p class="text-center">
          {{ $t("verification_code_has_sent_to_email") }} {{ maskEmail(email) }}
        </p>

        <v-otp-input
          v-model="otp"
          color="primary"
          length="6"
          required
          @keyup.enter="verifyOtp"
        ></v-otp-input>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="grey lighten-2" @click="closeOtp"
          >{{ $t("cancel") }}
          <v-icon dark right> mdi-keyboard-return</v-icon>
        </v-btn>
        <v-btn color="primary" :loading="isLoading" @click="verifyOtp">
          {{ $t("verify") }}
          <v-icon class="ml-2" style="color: white">mdi-check-all</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  props: ["email", "userIpDetails"],
  data() {
    return {
      otp: "",
      isLoading: false,
      dialog: true,
    };
  },
  methods: {
    closeOtp() {
      localStorage.clear();
      this.$emit("close");
    },
    async verifyOtp() {
      if (!this.otp) return;

      this.isLoading = true;
      try {
        await axios
          .post(`${API_BASE_URL}/login/verify_token`, {
            otp: this.otp,
            ip_details: this.userIpDetails,
          })
          .then((data) => {
            this.$emit("verified", data);
          })
          .catch((error) => {
            if (error.status == 422) {
              this.$toast.error(this.$t("invalid_otp"));
              this.otp = null;
            }
          });
      } catch (error) {
        alert(error.response?.data?.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
