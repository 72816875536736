import { render, staticRenderFns } from "./BasicNotesCard.vue?vue&type=template&id=505df478&scoped=true"
import script from "./BasicNotesCard.vue?vue&type=script&lang=js"
export * from "./BasicNotesCard.vue?vue&type=script&lang=js"
import style0 from "./BasicNotesCard.vue?vue&type=style&index=0&id=505df478&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505df478",
  null
  
)

export default component.exports