<template>
  <v-btn @ color="primary" class="ma-2" dark>
    <v-icon>mdi-cash-register</v-icon>
    {{ $t("cash_register_section.add_cash_register") }}
  </v-btn>
</template>
<script>
export default {
  name: "CashRegisterModalButton",
};
</script>
