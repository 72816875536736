<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-combobox
          presentation="off"
          :items="formattedEmployees"
          item-text="text"
          v-model="selectedEmployee"
          @update:search-input="searchEmployees"
          :loading="searching"
          class="to-upper"
          clearable
        >
        </v-combobox>
      </v-col>
      <v-col md="1">
        <v-select
          :items="filterOptions"
          item-text="title"
          item-value="value"
          :label="translate('filter_options.title')"
          v-model="selectedFilter"
        >
        </v-select>
      </v-col>
      <v-col v-if="selectedFilterIsDay" md="2">
        <v-menu
          v-model="dayPickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDay"
              :label="translate('filter_options.select_day')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDay"
            @input="dayPickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="selectedFilterIsWeek" md="2">
        <v-menu
          v-model="weekPickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedWeek"
              :label="translate('filter_options.select_week')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedWeek"
            @input="weekPickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="selectedFilterIsMonth" md="2">
        <v-menu
          v-model="monthPickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedSelectedMonth"
              :label="translate('filter_options.select_month')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="selectedMonth"
            @input="monthPickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="selectedFilterIsCustom" md="4">
        <v-row>
          <v-col>
            <v-menu
              v-model="fromDatePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedFromDate"
                  :label="translate('filter_options.select_from_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedFromDate"
                @input="fromDatePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="toDatePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedToDate"
                  :label="translate('filter_options.select_to_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedToDate"
                @input="toDatePickerMenu = false"
                :min="selectedFromDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="4">
        <div class="d-flex justify-start flex-nowrap">
          <div style="margin-right: 20px">
            <v-checkbox
              v-model="showBreakColumns"
              :label="translate('filter_options.show_break_columns')"
              color="success"
              hide-details
            ></v-checkbox>
          </div>
          <div>
            <v-checkbox
              v-model="showMissionColumns"
              :label="translate('filter_options.show_mission_columns')"
              color="success"
              hide-details
            ></v-checkbox>
          </div>
          <!--          <div>-->
          <!--            <v-checkbox-->
          <!--                v-model="showOtherColumns"-->
          <!--                :label="translate('filter_options.show_other_columns')"-->
          <!--                color="success"-->
          <!--                hide-details-->
          <!--            ></v-checkbox>-->
          <!--          </div>-->
        </div>
      </v-col>
    </v-row>
    <v-row style="overflow-x: auto; overflow-y: auto">
      <v-simple-table
        style="background-color: #ffffff"
        fixed-header
        :height="'calc(-230px + 100vh)'"
        :key="tableKey"
      >
        <template v-slot:default>
          <thead style="font-weight: bolder">
            <tr>
              <th style="min-width: 200px; max-width: 201px">
                {{ translate("filter_options.day") }}
              </th>
              <th style="width: 100px">{{ translate("total") }}</th>
              <th style="width: 100px">{{ translate("expected") }}</th>
              <th style="width: 120px">{{ translate("balance") }}</th>
              <th colspan="2">{{ translate("work") }}</th>
              <th style="width: 20px">{{ translate("total_work") }}</th>
              <th v-if="showBreakColumns" colspan="2">
                {{ translate("break") }}
              </th>
              <th v-if="showBreakColumns" style="width: 20px">
                {{ translate("total_break") }}
              </th>
              <th v-if="showMissionColumns" colspan="2">
                {{ translate("mission") }}
              </th>
              <th v-if="showMissionColumns" style="width: 20px">
                {{ translate("total_mission") }}
              </th>
              <th v-if="showOtherColumns" colspan="3">
                {{ translate("others") }}
              </th>
              <th v-if="showOtherColumns" style="width: 20px">
                {{ translate("total_others") }}
              </th>
            </tr>
          </thead>
          <tbody v-if="isset(selectedClockingData)" style="font-size: 1.2em">
            <tr
              v-for="(item, day) in selectedClockingData.clocking"
              :key="day"
              :style="{
                background: computeRowBgColor(
                  selectedClockingData.summary[day]['expected']
                ),
                textAlign: 'right',
              }"
              @mouseleave="() => (lastHoveredClockingId = null)"
            >
              <td
                style="font-weight: bolder"
                :style="{
                  'border-bottom': borderBottomColor(day),
                }"
              >
                <div style="cursor: pointer" @click="addNewClockingOthers(day)">
                  {{ formatDay(day) }}
                </div>
              </td>
              <td
                :style="{
                  'border-bottom': borderBottomColor(day),
                  background: computeWorkBgColor(
                    selectedClockingData.clocking[day][0],
                    selectedClockingData.summary[day]
                  ),
                }"
              >
                <div>
                  {{
                    convertMinutesToHours(
                      selectedClockingData.summary[day].total
                    )
                  }}
                </div>
              </td>
              <td
                :style="{
                  'border-bottom': borderBottomColor(day),
                  background: computeWorkBgColor(
                    selectedClockingData.clocking[day][0],
                    selectedClockingData.summary[day]
                  ),
                }"
              >
                <div>
                  {{
                    convertMinutesToHours(
                      selectedClockingData.summary[day].expected
                    )
                  }}
                </div>
              </td>
              <td
                :style="{
                  'border-bottom': borderBottomColor(day),
                  background: computeWorkBgColor(
                    selectedClockingData.clocking[day][0],
                    selectedClockingData.summary[day]
                  ),
                }"
                @click="toggleEmployeeClockingForDayModal(day)"
              >
                <div
                  :style="{
                    color:
                      selectedClockingData.summary[day].balance < 0
                        ? 'darkred'
                        : '',
                    cursor: 'pointer',
                  }"
                >
                  {{
                    convertMinutesToHours(
                      selectedClockingData.summary[day].balance
                    )
                  }}
                </div>
              </td>
              <!-- Work Column Starts Here -->
              <td
                class="work-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  'text-align': 'center',
                  'min-width': '120px',
                  'max-width': '121px',
                }"
                :colspan="computeColSpan(selectedClockingData.clocking[day][0])"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    ['work']
                  )"
                  :key="work.id"
                  @mouseenter="() => (lastHoveredClockingId = work.id)"
                >
                  <input
                    v-model="editClockingModel['check_in_time'][work.id]"
                    style="max-width: 50px"
                    :id="'check_in_time_edit_time_' + work.id"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        false,
                        'update',
                        'work'
                      )
                    "
                    @keydown.enter="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        false,
                        'update',
                        'work'
                      )
                    "
                    @keydown.tab="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        true,
                        'update',
                        'work'
                      )
                    "
                  />
                  <v-btn
                    small
                    icon
                    @click="confirmDeleteAction(work, day)"
                    :style="{
                      visibility:
                        lastHoveredClockingId === work.id
                          ? 'visible'
                          : 'hidden',
                    }"
                  >
                    <v-icon small color="red">mdi-close</v-icon>
                  </v-btn>

                  <input
                    v-if="
                      computeColSpan(selectedClockingData.clocking[day][0]) ===
                      2
                    "
                    v-model="editClockingModel['check_out_time'][work.id]"
                    style="max-width: 80px; margin-left: 40px"
                    :id="'check_in_time_edit_time_' + work.id"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(
                        work,
                        'check_out_time',
                        day,
                        false,
                        'update',
                        'work'
                      )
                    "
                    @keydown.enter="
                      updateClockingHandler(
                        work,
                        'check_out_time',
                        day,
                        false,
                        'update',
                        'work'
                      )
                    "
                    @keydown.tab="
                      updateClockingHandler(
                        work,
                        'check_out_time',
                        day,
                        true,
                        'update',
                        'work'
                      )
                    "
                  />
                </div>
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'master'
                  )"
                  :key="work.id"
                  @mouseenter="() => (lastHoveredClockingId = work.id)"
                >
                  <span
                    :style="{
                      display:
                        idOfClockingToEdit === work.id &&
                        targetOfClockingToEdit === 'check_in_time'
                          ? 'none'
                          : '',
                    }"
                    @click="handleEditTime(work, 'check_in_time')"
                  >
                    {{ computeWorkText(work) }}
                  </span>
                  <v-btn
                    small
                    icon
                    style="float: right"
                    @click="confirmDeleteAction(work, day)"
                    :style="{
                      visibility:
                        lastHoveredClockingId === work.id
                          ? 'visible'
                          : 'hidden',
                    }"
                  >
                    <v-icon small color="red">mdi-close</v-icon>
                  </v-btn>
                </div>

                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'work',
                    true
                  )"
                  :key="work.id"
                >
                  <input
                    v-model="editClockingModel['check_in_time'][work.id]"
                    style="max-width: 70px"
                    :id="'check_in_time_edit_time_' + work.id"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        false,
                        'update',
                        'work'
                      )
                    "
                    @keydown.enter="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        false,
                        'update',
                        'work'
                      )
                    "
                    @keydown.tab="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        true,
                        'update',
                        'work'
                      )
                    "
                  />
                </div>
              </td>
              <td
                class="work-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  'text-align': 'center',
                  'min-width': '180px',
                }"
                v-if="
                  computeColSpan(selectedClockingData.clocking[day][0]) !== 2
                "
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    ['work']
                  )"
                  :key="work.id"
                >
                  <div
                    v-if="
                      computeWorkText(work, 2) !== '--:--' &&
                      idOfClockingToEdit !== work.id
                    "
                    :style="{
                      display:
                        idOfClockingToEdit === work.id &&
                        targetOfClockingToEdit === 'check_out_time'
                          ? 'none'
                          : '',
                    }"
                    @click="handleEditTime(work, 'check_out_time')"
                  >
                    {{ computeWorkText(work, 2) }}
                  </div>
                  <div v-else>
                    <input
                      v-model="editClockingModel['check_out_time'][work.id]"
                      :id="'check_out_time_edit_time_' + work.id"
                      type="time"
                      style="text-align: center"
                      class="edit-time"
                      @blur="
                        updateClockingHandler(
                          work,
                          'check_out_time',
                          day,
                          false,
                          'update',
                          'work'
                        )
                      "
                      @keydown.enter="
                        updateClockingHandler(
                          work,
                          'check_out_time',
                          day,
                          false,
                          'update',
                          'work'
                        )
                      "
                      @keydown.tab="
                        updateClockingHandler(
                          work,
                          'check_out_time',
                          day,
                          true,
                          'update',
                          'work'
                        )
                      "
                    />
                  </div>
                </div>

                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'master'
                  )"
                  :key="work.id"
                >
                  <div
                    v-if="
                      computeWorkText(work, 2) !== '--:--' &&
                      idOfClockingToEdit !== work.id
                    "
                    :style="{
                      display:
                        idOfClockingToEdit === work.id &&
                        targetOfClockingToEdit === 'check_out_time'
                          ? 'none'
                          : '',
                    }"
                    @click="handleEditTime(work, 'check_out_time')"
                  >
                    {{ computeWorkText(work, 2) }}
                  </div>
                  <div v-else>
                    <input
                      v-model="editClockingModel['check_out_time'][work.id]"
                      :id="'check_out_time_edit_time_' + work.id"
                      type="time"
                      style="text-align: center"
                      class="edit-time"
                      @blur="
                        updateClockingHandler(
                          work,
                          'check_out_time',
                          day,
                          false,
                          'update',
                          'work'
                        )
                      "
                      @keydown.enter="
                        updateClockingHandler(
                          work,
                          'check_out_time',
                          day,
                          false,
                          'update',
                          'work'
                        )
                      "
                      @keydown.tab="
                        updateClockingHandler(
                          work,
                          'check_out_time',
                          day,
                          true,
                          'update',
                          'work'
                        )
                      "
                    />
                  </div>
                </div>
              </td>
              <td
                :style="{
                  'border-bottom': borderBottomColor(day),
                  background:
                    selectedClockingData.summary[day]['expected'] === 0
                      ? undefined
                      : clockingColor['work'],
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                }"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    ['work']
                  )"
                  :key="work.id"
                >
                  {{ computeWorkText(work, 3) }}
                </div>

                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'master'
                  )"
                  :key="work.id"
                >
                  {{ computeWorkText(work, 3) }}
                </div>
              </td>
              <!-- Work Column ends here -->
              <!-- Break Column starts here -->
              <td
                class="work-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                  'min-width': '120px',
                }"
                v-if="showBreakColumns"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'break'
                  )"
                  :key="work.id"
                  @mouseenter="lastHoveredClockingId = work.id"
                >
                  <input
                    v-model="editClockingModel['check_in_time'][work.id]"
                    :id="'check_in_time_edit_time_' + work.id"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        false,
                        'update',
                        'break'
                      )
                    "
                    @keydown.enter="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        false,
                        'update',
                        'break'
                      )
                    "
                    @keydown.tab="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        true,
                        'update',
                        'break'
                      )
                    "
                  />

                  <v-btn
                    small
                    icon
                    style="float: right"
                    @click="confirmDeleteAction(work, day)"
                    :style="{
                      visibility:
                        lastHoveredClockingId === work.id
                          ? 'visible'
                          : 'hidden',
                    }"
                  >
                    <v-icon small color="red">mdi-close</v-icon>
                  </v-btn>
                </div>

                <div
                  v-if="
                    extractClockingByType(
                      selectedClockingData.clocking[day],
                      'break'
                    ).length === 0
                  "
                >
                  <input
                    v-model="editClockingModel['check_in_time']['break'][day]"
                    :id="'check_in_time_edit_time_' + day"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(
                        day,
                        'check_in_time',
                        day,
                        false,
                        'create',
                        'break'
                      )
                    "
                    @keydown.enter="
                      updateClockingHandler(
                        day,
                        'check_in_time',
                        day,
                        false,
                        'create',
                        'break'
                      )
                    "
                    @keydown.tab="
                      updateClockingHandler(
                        day,
                        'check_in_time',
                        day,
                        true,
                        'create',
                        'break'
                      )
                    "
                  />
                  <v-btn
                    small
                    icon
                    style="float: right"
                    :style="{ visibility: 'hidden' }"
                  >
                    <v-icon small color="red">mdi-close</v-icon>
                  </v-btn>
                </div>
              </td>
              <td
                class="work-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                  width: '100px',
                }"
                v-if="showBreakColumns"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'break'
                  )"
                  :key="work.id"
                >
                  <input
                    v-model="editClockingModel['check_out_time'][work.id]"
                    :id="'check_out_time_edit_time_' + work.id"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(work, 'check_out_time', day, false)
                    "
                    @keydown.enter="
                      updateClockingHandler(work, 'check_out_time', day)
                    "
                    @keydown.tab="
                      updateClockingHandler(work, 'check_out_time', day, true)
                    "
                  />
                </div>
              </td>
              <td
                v-if="showBreakColumns"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  background:
                    selectedClockingData.summary[day]['expected'] === 0
                      ? undefined
                      : clockingColor['break'],
                }"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'break'
                  )"
                  :key="work.id"
                >
                  {{ computeWorkText(work, 3) }}
                </div>
              </td>
              <!-- Break Column Ends Here -->

              <!-- Mission Column starts here -->
              <td
                class="work-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  'min-width': '120px',
                  'text-align': 'center',
                }"
                v-if="showMissionColumns"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'mission'
                  )"
                  :key="work.id"
                  @mouseenter="lastHoveredClockingId = work.id"
                >
                  <input
                    v-model="editClockingModel['check_in_time'][work.id]"
                    style="max-width: 70px"
                    :id="'check_in_time_edit_time_' + work.id"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        false,
                        'update',
                        'mission'
                      )
                    "
                    @keydown.enter="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        false,
                        'update',
                        'mission'
                      )
                    "
                    @keydown.tab="
                      updateClockingHandler(
                        work,
                        'check_in_time',
                        day,
                        true,
                        'update',
                        'mission'
                      )
                    "
                  />

                  <v-btn
                    small
                    icon
                    style="float: right"
                    @click="confirmDeleteAction(work, day)"
                    :style="{
                      visibility:
                        lastHoveredClockingId === work.id
                          ? 'visible'
                          : 'hidden',
                    }"
                  >
                    <v-icon small color="red">mdi-close</v-icon>
                  </v-btn>
                </div>
                <div
                  v-if="
                    extractClockingByType(
                      selectedClockingData.clocking[day],
                      'mission'
                    ).length === 0
                  "
                >
                  <input
                    v-model="editClockingModel['check_in_time']['mission'][day]"
                    style="max-width: 70px"
                    :id="'check_in_time_edit_time_' + day"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(
                        day,
                        'check_in_time',
                        day,
                        false,
                        'create',
                        'mission'
                      )
                    "
                    @keydown.enter="
                      updateClockingHandler(
                        day,
                        'check_in_time',
                        day,
                        false,
                        'create',
                        'mission'
                      )
                    "
                    @keydown.tab="
                      updateClockingHandler(
                        day,
                        'check_in_time',
                        day,
                        true,
                        'create',
                        'mission'
                      )
                    "
                  />
                </div>
              </td>
              <td
                class="work-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  width: '100px',
                }"
                v-if="showMissionColumns"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'mission'
                  )"
                  :key="work.id"
                  style="width: 100%"
                >
                  <input
                    v-model="editClockingModel['check_out_time'][work.id]"
                    :id="'check_out_time_edit_time_' + work.id"
                    type="time"
                    class="edit-time"
                    @blur="
                      updateClockingHandler(
                        work,
                        'check_out_time',
                        day,
                        false,
                        'update',
                        'mission'
                      )
                    "
                    @keydown.enter="
                      updateClockingHandler(
                        work,
                        'check_out_time',
                        day,
                        false,
                        'update',
                        'mission'
                      )
                    "
                    @keydown.tab="
                      updateClockingHandler(
                        work,
                        'check_out_time',
                        day,
                        true,
                        'update',
                        'mission'
                      )
                    "
                  />
                </div>
              </td>
              <td
                v-if="showMissionColumns"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  background:
                    selectedClockingData.summary[day]['expected'] === 0
                      ? undefined
                      : clockingColor['mission'],
                }"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'mission'
                  )"
                  :key="work.id"
                  style="width: 100%"
                >
                  {{ convertMinutesToHours(work.time_difference) }}
                </div>
              </td>
              <!-- Mission Column Ends Here -->

              <!-- Others Column starts here -->
              <td
                class="others-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                }"
                v-if="showOtherColumns"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'others'
                  )"
                  :key="work.id"
                  @mouseenter="lastHoveredClockingId = work.id"
                >
                  {{ translate("types." + work.type.key) }}

                  <v-btn
                    small
                    icon
                    style="float: right"
                    @click="confirmDeleteAction(work, day)"
                    :style="{
                      visibility:
                        lastHoveredClockingId === work.id
                          ? 'visible'
                          : 'hidden',
                    }"
                  >
                    <v-icon small color="red">mdi-close</v-icon>
                  </v-btn>
                </div>
                <div
                  v-if="
                    extractClockingByType(
                      selectedClockingData.clocking[day],
                      'others'
                    ).length === 0
                  "
                  @click="addNewClockingOthers(day)"
                  style="width: 100%; height: 100%"
                ></div>
              </td>

              <td
                class="others-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  width: '100px',
                }"
                v-if="showOtherColumns"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'others'
                  )"
                  :key="work.id"
                >
                  <span
                    :style="{
                      display:
                        idOfClockingToEdit === work.id &&
                        targetOfClockingToEdit === 'check_in_time'
                          ? 'none'
                          : '',
                    }"
                    @click="handleEditTime(work, 'check_in_time')"
                  >
                    {{ computeWorkText(work) }}
                  </span>

                  <span
                    v-if="
                      idOfClockingToEdit === work.id &&
                      targetOfClockingToEdit === 'check_in_time'
                    "
                  >
                    <input
                      v-model="valueOfClockingToEdit"
                      style="max-width: 70px"
                      :id="'check_in_time_edit_time_' + work.id"
                      type="time"
                      class="edit-time"
                      @blur="
                        updateClockingHandler(work, 'check_in_time', day, false)
                      "
                      @keydown.enter="
                        updateClockingHandler(work, 'check_in_time', day)
                      "
                      @keydown.tab="
                        updateClockingHandler(work, 'check_in_time', day, true)
                      "
                    />
                  </span>
                </div>

                <div
                  v-if="
                    extractClockingByType(
                      selectedClockingData.clocking[day],
                      'others'
                    ).length === 0
                  "
                  @click="addNewClockingOthers(day)"
                  style="width: 100%; height: 30px"
                ></div>
              </td>
              <td
                class="others-column"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  width: '100px',
                }"
                v-if="showOtherColumns"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'others'
                  )"
                  :key="work.id"
                >
                  <span
                    :style="{
                      display:
                        idOfClockingToEdit === work.id &&
                        targetOfClockingToEdit === 'check_out_time'
                          ? 'none'
                          : '',
                    }"
                    @click="handleEditTime(work, 'check_out_time')"
                    >{{ computeWorkText(work, 2) }}</span
                  >
                  <span
                    v-if="
                      idOfClockingToEdit === work.id &&
                      targetOfClockingToEdit === 'check_out_time'
                    "
                    style="max-width: 40px"
                  >
                    <input
                      v-model="valueOfClockingToEdit"
                      :id="'check_out_time_edit_time_' + work.id"
                      type="time"
                      class="edit-time"
                      @blur="
                        updateClockingHandler(
                          work,
                          'check_out_time',
                          day,
                          false
                        )
                      "
                      @keydown.enter="
                        updateClockingHandler(work, 'check_out_time', day)
                      "
                      @keydown.tab="
                        updateClockingHandler(work, 'check_out_time', day, true)
                      "
                    />
                  </span>
                </div>
                <div
                  v-if="
                    extractClockingByType(
                      selectedClockingData.clocking[day],
                      'others'
                    ).length === 0
                  "
                  @click="addNewClockingOthers(day)"
                  style="width: 100%; height: 100%"
                ></div>
              </td>
              <td
                v-if="showOtherColumns"
                :style="{
                  'border-bottom': borderBottomColor(day),
                  background:
                    selectedClockingData.summary[day]['expected'] === 0
                      ? undefined
                      : clockingColor['unspecified'],
                }"
              >
                <div
                  v-for="work in extractClockingByType(
                    selectedClockingData.clocking[day],
                    'others'
                  )"
                  :key="work.id"
                >
                  {{ convertMinutesToHours(work.time_difference) }}
                </div>
              </td>
              <!-- Others Column Ends Here -->
            </tr>
            <!-- Total Section Starts Here -->
            <tr style="font-weight: bolder; text-align: right">
              <td>Total</td>
              <td>{{ convertMinutesToHours(overAllTotal) }}</td>
              <td>{{ convertMinutesToHours(calculateOverall("expected")) }}</td>
              <td>
                {{ convertMinutesToHours(calculateOverall("balance")) }}
                <!--            <v-icon v-if="calculateOverall('balance') > 0" color="success">mdi-arrow-up-->
                <!--            </v-icon>-->
                <!--            <v-icon color="success" v-else-if="calculateOverall('balance') === 0">mdi-check</v-icon>-->
                <!--            <v-icon color="red" v-else>mdi-arrow-down</v-icon>-->
              </td>
              <td></td>
              <td></td>
              <td>{{ convertMinutesToHours(calculateOverall("work")) }}</td>
              <td v-if="showBreakColumns"></td>
              <td v-if="showBreakColumns"></td>
              <td v-if="showBreakColumns">
                {{ convertMinutesToHours(calculateOverall("break")) }}
              </td>
              <td v-if="showMissionColumns"></td>
              <td v-if="showMissionColumns"></td>
              <td v-if="showMissionColumns">
                {{ convertMinutesToHours(calculateOverall("mission")) }}
              </td>
              <td v-if="showOtherColumns"></td>
              <td v-if="showOtherColumns"></td>
              <td v-if="showOtherColumns"></td>
              <td v-if="showOtherColumns">
                {{ convertMinutesToHours(calculateOverall("others")) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <AddClocking
      v-if="selectedClockingData && selectedEmployee"
      v-model="addClockingDialog"
      :employee-contract="selectedClockingData.contract"
      :fromDate="formattedFromDate"
      :toDate="formattedToDate"
      @clockingAdded="(data) => updateClockingData(data)"
      :day="selectedModalDate"
      @input="
        (val) => {
          if (!val) selectedModalDate = null;
        }
      "
    />

    <span
      id="add-clocking"
      class="d-none"
      @click="openAddClockingDialog"
    ></span>
    <span id="print-clocking" class="d-none" @click="printReport"></span>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      :url="computeClockingQueryParameters('employees/clocking/admin')"
      @buttonClicked="confirmationButtonClicked"
    />

    <employee-clocking-for-day
      v-model="showClockingForDayModal"
      :date="selectedClockingForDayDate"
      :employee_contract_id="selectedEmployee.active_contract.id"
      @deleted="
        () => getClockingData(selectedEmployee.active_contract.id, false)
      "
    />
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import moment from "moment";
import AddClocking from "@/components/Clocking/AddClocking.vue";
import clockingMixin from "@/mixins/clockingMixin";
import ConfirmationModel from "@/components/ConfirmationModel.vue";
import EmployeeClockingForDay from "@/components/Clocking/EmployeeClockingForDay.vue";

export default {
  name: "EmployeeClockingListing",
  components: {
    AddClocking,
    ConfirmationModel,
    EmployeeClockingForDay,
  },
  mixins: [clockingMixin],
  data() {
    return {
      editClockingModel: {
        check_in_time: {
          work: {},
          break: {},
          mission: {},
          others: {},
        },
        check_out_time: {
          work: {},
          break: {},
          mission: {},
          others: {},
        },
      },
      tableKey: 1,
      addClockingDialog: false,
      clockingData: [],
      employees: [],
      selectedFilter: null,
      selectedDay: null,
      dayPickerMenu: false,
      selectedWeek: null,
      weekPickerMenu: false,
      selectedMonth: null,
      monthPickerMenu: false,
      fromDatePickerMenu: false,
      toDatePickerMenu: false,
      selectedFromDate: null,
      selectedToDate: null,
      showBreakColumns: true,
      showMissionColumns: true,
      showOtherColumns: false,
      allowTimeEdit: true,
      automaticallyDeductBreak: false,
      selectedEmployee: null,
      idOfClockingToEdit: null,
      valueOfClockingToEdit: null,
      targetOfClockingToEdit: null,
      actionOfClockingToEdit: null,
      typeOfClockingToEdit: null,
      fromDate: null,
      toDate: null,
      selectedModalDate: null,
      searching: false,
      timerId: null,
      lastHoveredClockingId: null,
      allowUpdate: true,
      filterOptions: [
        {
          title: this.translate("filter_options.day"),
          value: "day",
        },
        {
          title: this.translate("filter_options.week"),
          value: "week",
        },
        {
          title: this.translate("filter_options.month"),
          value: "month",
        },
        {
          title: this.translate("filter_options.custom"),
          value: "custom",
        },
      ],
      clockingColor: {
        default: "#ffffff",
        uncompleted_work: "#ffffff",
        unspecified: "#816f45",
        work: "#7DD356D8",
        break: "#FFFE01",
        mission: "#4795d2",
        sick_leave: "#fd5c60",
        vacation: "#78D9D9FF",
        absent: "#f39999",
        no_work: "lightgrey",
        public_holiday: "#7babfb",
      },
      axiosOptions: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogOperationData: null,
      showClockingForDayModal: false,
      selectedClockingForDayDate: null,
    };
  },
  async mounted() {
    this.configureClocking();
    this.employees = await this.getEmployees();
    this.selectedEmployee = this.formattedEmployees[0];

    this.selectedFilter = this.filterOptions.find(
      (f) => f.value === "month"
    ).value;
    // set current month as selected month
    this.selectedMonth = moment().format("YYYY-MM");
  },
  computed: {
    selectedClockingData() {
      return this.clockingData[0] ?? null;
    },
    overAllTotal() {
      if (!this.selectedClockingData) {
        return 0;
      }

      return Object.values(this.selectedClockingData.summary).reduce(
        (total, item) => total + item.total,
        0
      );
    },
    selectedFilterIsMonth() {
      return this.selectedFilter === "month";
    },
    selectedFilterIsDay() {
      return this.selectedFilter === "day";
    },
    selectedFilterIsWeek() {
      return this.selectedFilter === "week";
    },
    selectedFilterIsCustom() {
      return this.selectedFilter === "custom";
    },
    watchFilters() {
      return [
        this.selectedFilter,
        this.selectedDay,
        this.selectedWeek,
        this.selectedMonth,
        this.selectedFromDate,
        this.selectedToDate,
      ];
    },
    formattedSelectedMonth() {
      return moment(this.selectedMonth)
        .locale(this.$store.state.languageId)
        .format("MMMM YYYY");
    },
    formattedFromDate() {
      return moment(this.selectedMonth).startOf("month").format("YYYY-MM-DD");
    },
    formattedToDate() {
      return moment(this.selectedMonth).endOf("month").format("YYYY-MM-DD");
    },
  },
  watch: {
    selectedEmployee(val) {
      if (!val) return;

      this.getClockingData(val.active_contract.id);
    },
    watchFilters() {
      if (!this.selectedEmployee) return;

      this.getClockingData(this.selectedEmployee.active_contract.id);
    },
    selectedFromDate(val) {
      if (!this.selectedToDate || !val) return false;

      let fromDate = moment(val);
      let toDate = moment(this.selectedToDate);

      if (fromDate.isAfter(toDate)) {
        this.selectedToDate = null;
      }
    },
    clockingData(val) {
      if (!this.isset(val)) return false;

      //loop through clocking days and set editClockingModel for both Check in and Check out
      let clocking = Object.values(val)[0];
      clocking = Object.values(Object.values(clocking)[1]);
      for (const day in clocking) {
        let clockingForTheDay = clocking[day];
        clockingForTheDay.forEach((work) => {
          if (work.is_placeholder) {
            this.editClockingModel["check_in_time"][work.id] = null;
            this.editClockingModel["check_out_time"][work.id] = null;
          } else {
            this.editClockingModel["check_in_time"][work.id] =
              work.check_in_time ? work.check_in_time_only : null;
            this.editClockingModel["check_out_time"][work.id] =
              work.check_in_time ? work.check_out_time_only : null;
          }
        });
      }
    },
  },
  methods: {
    toggleEmployeeClockingForDayModal(date) {
      this.selectedClockingForDayDate = date;

      this.showClockingForDayModal = false;
      setTimeout(() => (this.showClockingForDayModal = true), 10);
    },
    addNewClockingOthers(day) {
      this.selectedModalDate = day;
      this.openAddClockingDialog();
    },
    handleEditTime(work, target, action = "update", clockingType = null) {
      let tryingToUpdatePlaceHolder =
        action === "update" &&
        typeof work === "object" &&
        work.check_in_time === work.check_out_time;
      if (action !== "create" || tryingToUpdatePlaceHolder) {
        if (
          (action === "update" &&
            ["vacation", "sick_leave", "absent"].includes(work.type.key)) ||
          !this.allowTimeEdit
        ) {
          return;
        }
      }
      this.editClockingModel[target][work.id] = work[target + "_only"];
      this.idOfClockingToEdit = work?.id || work;
      this.valueOfClockingToEdit = work[target + "_only"];
      this.targetOfClockingToEdit = target;
      this.actionOfClockingToEdit = action;
      this.typeOfClockingToEdit = clockingType;
      setTimeout(() => {
        document
          .getElementById(target + "_edit_time_" + (work.id ?? work))
          .focus();
      }, 100);
    },
    translate(key) {
      return this.$t("clocking_section." + key);
    },
    getClockingData(employeeContractId = null, reloadData = true) {
      let url = this.computeClockingQueryParameters(
        "employees/clocking",
        employeeContractId
      );
      if (reloadData) {
        this.clockingData = [];
      }

      axios
        .get(url, { headers: this.axiosOptions })
        .finally(() => {})
        .then((response) => {
          this.clockingData = Object.values(response.data);
        })
        .catch(() => {});
    },
    computeClockingQueryParameters(
      baseUrl = "employees/clocking",
      employeeContractId
    ) {
      let url = API_BASE_URL + "/" + baseUrl + "?a=a";

      if (employeeContractId) {
        url += "&employee_contract_id=" + employeeContractId;
      }

      if (this.selectedFilter === "month") {
        if (!this.selectedMonth) {
          return;
        }

        // Using moment get start date and end date of the month
        let fromDate = moment(this.selectedMonth)
          .startOf("month")
          .format("YYYY-MM-DD");
        let toDate = moment(this.selectedMonth)
          .endOf("month")
          .format("YYYY-MM-DD");

        this.fromDate = fromDate;
        this.toDate = toDate;

        url += "&from_date=" + fromDate + "&to_date=" + toDate;
      }

      if (this.selectedFilter === "day") {
        if (!this.selectedDay) {
          return false;
        }

        url += "&day=" + this.selectedDay;

        this.fromDate = this.selectedDay;
        this.toDate = this.selectedDay;
      }

      if (this.selectedFilter === "week") {
        if (!this.selectedWeek) {
          return false;
        }

        // Using moment get start date and end date of the week
        let fromDate = moment(this.selectedWeek)
          .startOf("week")
          .format("YYYY-MM-DD");
        let toDate = moment(this.selectedWeek)
          .endOf("week")
          .format("YYYY-MM-DD");

        this.fromDate = fromDate;
        this.toDate = toDate;

        url += "&from_date=" + fromDate + "&to_date=" + toDate;
      }

      if (this.selectedFilter === "custom") {
        if (!this.selectedFromDate || !this.selectedToDate) {
          return;
        }

        // Using moment get start date and end date of the week
        let fromDate = moment(this.selectedFromDate).format("YYYY-MM-DD");
        let toDate = moment(this.selectedToDate).format("YYYY-MM-DD");

        this.fromDate = fromDate;
        this.toDate = toDate;

        url += "&from_date=" + fromDate + "&to_date=" + toDate;
      }

      return url;
    },
    extractClockingByType(clocking, type, placeHolderOnly = false) {
      if (placeHolderOnly) {
        return clocking.filter((i) => i.check_in_time === i.check_out_time);
      }

      if (Array.isArray(type)) {
        return clocking.filter(
          (i) =>
            type.includes(i.type.key) && !(i.check_in_time === i.check_out_time)
        );
      }

      return clocking.filter((i) => {
        let checkInAndOutTimeIsTheSame = i.check_in_time === i.check_out_time;

        if (checkInAndOutTimeIsTheSame) {
          return false;
        }

        if (type === "master") {
          return !["break", "mission", "work"].includes(i.type.key);
        }

        return type === "others"
          ? ![
              "work",
              "mission",
              "break",
              "sick_leave",
              "vacation",
              "public_holiday",
            ].includes(i.type.key)
          : i.type.key === type;
      });
    },
    computeColSpan(
      clocking,
      targets = ["vacation", "sick_leave", "public_holiday"]
    ) {
      return targets.includes(clocking?.type?.key) ? 2 : 1;
    },
    convertMinutesToHours(minutes) {
      minutes = parseInt(minutes);

      let hours =
        minutes > 0 ? Math.floor(minutes / 60) : Math.ceil(minutes / 60);
      hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(hours))
        ? `0${hours}`
        : hours;

      minutes = (minutes % 60).toString().replace("-", "");
      minutes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(minutes))
        ? `0${minutes}`
        : minutes;

      return hours + ":" + minutes;
    },
    calculateOverall(target) {
      if (!this.selectedClockingData) {
        return 0;
      }

      return Object.values(this.selectedClockingData.summary).reduce(
        (total, item) => total + item[target],
        0
      );
    },
    computeWorkBgColor(clocking, summary) {
      if (summary.expected === 0) return undefined;

      if (
        summary.expected > 0 &&
        summary.total === 0 &&
        clocking.type.key === "work"
      )
        return this.clockingColor.absent;

      let type = clocking.type.key;
      if (
        ["vacation", "sick_leave", "absent", "public_holiday"].includes(type)
      ) {
        return this.clockingColor[type];
      } else if (
        clocking.check_in_time === clocking.check_out_time ||
        !clocking.check_out_time
      ) {
        return this.clockingColor.default;
      } else {
        return this.clockingColor.work;
      }
    },
    computeRowBgColor(expected) {
      return expected === 0 ? this.clockingColor.no_work : undefined;
    },
    computeWorkText(clocking, column = 1) {
      let type = clocking.type.key;
      if (!["work", "break", "mission"].includes(type)) {
        if (column === 3) {
          let val = this.convertMinutesToHours(clocking.time_difference);
          return val === "00:00" ? "--:--" : val;
        }

        if (column === 2) {
          //use moment to parse time to AM/PM Format like 4PM or 4:30PM
          let start = moment(clocking.check_in_time_only, "HH:mm").format(
            "h:mmA"
          );
          let end = moment(clocking.check_out_time_only, "HH:mm").format(
            "h:mmA"
          );

          return start + " - " + end;
        }

        let text = column === 1 ? this.translate("types." + type) : "--:--";

        if (text.length > 7) {
          text = text.substring(0, 4) + "...";
        }
        return text;
      } else {
        if (column === 3) {
          let val = this.convertMinutesToHours(clocking.time_difference);
          return val === "00:00" ? "--:--" : val;
        }

        let data =
          clocking[column === 1 ? "check_in_time_only" : "check_out_time_only"];

        if (!this.isset(data)) {
          return "--:--";
        }

        if (column === 2) {
          // use moment to parse time to AM/PM Format
          return moment(data, "HH:mm").format("hh:mm A");
        }

        return data;
      }
    },
    openAddClockingDialog() {
      this.addClockingDialog = false;

      setTimeout(() => (this.addClockingDialog = true), 30);
      setTimeout(() => {
        this.selectedModalDate = null;
      }, 500);
    },

    borderBottomColor(day) {
      let isTodaySunday = moment(day, "DD-MM-YYYY").day() === 0;
      return isTodaySunday ? "2px solid grey" : "";
    },
    formatDay(day) {
      // output format "Monday Jan 30, 2024, take locale into Consideration
      return moment(day, "DD-MM-YYYY")
        .locale(this.$store.state.languageId)
        .format("dddd MMM DD");
    },
    revertEditClockingDataToDefault() {
      this.idOfClockingToEdit = null;
      this.valueOfClockingToEdit = null;
      this.targetOfClockingToEdit = null;
      this.typeOfClockingToEdit = null;
      this.actionOfClockingToEdit = null;
    },
    updateClockingHandler(
      clocking,
      target,
      day,
      revertOnly = false,
      action = "update",
      clockingType = null
    ) {
      if (revertOnly) {
        return this.revertEditClockingDataToDefault();
      }

      if (!this.allowUpdate) return;

      let newTime =
        clocking?.id !== undefined
          ? (newTime = this.editClockingModel[target][clocking.id])
          : (newTime = this.editClockingModel[target][clockingType][clocking]);

      // check if new time is not same as old time
      if (clocking[target + "_only"] === newTime) {
        return this.revertEditClockingDataToDefault();
      }

      let payload = {
        new_time: newTime,
        target: target.replace("_time", ""),
        action: action,
        type: clockingType,
      };

      if (action === "create") {
        payload.employee_contract_id = this.selectedEmployee.active_contract.id;
        payload.date = day;
      }

      if (this.$store.state.progressBarLoading) {
        return;
      }

      let url = "employees/clocking/" + (clocking?.id || clocking);
      url = this.computeClockingQueryParameters(
        url,
        this.selectedEmployee.active_contract.id
      );
      this.allowUpdate = false;
      axios
        .put(url, payload, { headers: this.axiosOptions })
        .finally(() => {})
        .then((response) => {
          this.revertEditClockingDataToDefault();
          this.clockingData = Object.values(response.data);
          // if check in time is edited or added, focus corresponding check out time
          let clockingIsNew =
            typeof clocking === "string" ||
            this.isset(clocking?.is_placeholder);

          if (target === "check_in_time" && clockingIsNew) {
            setTimeout(() => {
              let clockingForDay = this.selectedClockingData.clocking[day];
              let lastClocking = clockingForDay[clockingForDay.length - 1];
              document
                .getElementById("check_out_time_edit_time_" + lastClocking.id)
                .focus();
              this.allowUpdate = true;
            }, 10);
          } else {
            setTimeout(() => {
              document
                .getElementById("check_out_time_edit_time_" + clocking.id)
                .focus();
              this.allowUpdate = true;
            }, 100);
          }
        })
        .catch(() => {
          this.allowUpdate = true;
        });
    },
    configureClocking() {
      this.showBreakColumns = !(
        parseInt(this.getCompanyPropertyValue("CLOCKING_HIDE_BREAK")) === 1
      );
      this.showMissionColumns = !(
        parseInt(this.getCompanyPropertyValue("CLOCKING_HIDE_MISSION")) === 1
      );
      // this.showOtherColumns = !(parseInt(this.getCompanyPropertyValue('CLOCKING_HIDE_OTHERS')) === 1);
      this.allowTimeEdit =
        parseInt(
          this.getCompanyPropertyValue("CLOCKING_ALLOW_TIME_MODIFICATION")
        ) === 1;
      this.automaticallyDeductBreak =
        parseInt(
          this.getCompanyPropertyValue("CLOCKING_AUTOMATICALLY_DEDUCT_BREAK")
        ) === 1;
    },
    printReport() {
      let url = `${API_BASE_URL}/employees/clocking/print?employee_contract_id=${this.selectedEmployee.active_contract.id}&lang=${this.$store.state.languageId}&from_date=${this.fromDate}&to_date=${this.toDate}`;
      this.setPreviewData(
        url,
        "clocking-" + this.selectedEmployee.badge,
        true,
        "application/pdf"
      );
    },
    async searchEmployees(search) {
      if (this.formattedEmployees.find((e) => e.text === search)) {
        return;
      }
      // cancel pending call
      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(async () => {
        this.searching = true;
        this.employees = await this.getEmployees(search);
        this.searching = false;
      }, 500);
    },
    confirmDeleteAction(clocking, day) {
      this.confirmationDialog = false;

      setTimeout(() => {
        this.confirmationDialog = true;
        this.confirmationDialogConfirmationText = this.translate(
          "delete_clocking_confirmation_text"
        );
        this.confirmationDialogFalseText = this.$t("no");
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogOperation = "deleteClocking";
        this.confirmationDialogOperationData = {
          clocking,
          day,
        };
      }, 50);
    },
    confirmationButtonClicked(action) {
      if (!action) return;

      if (this.confirmationDialogOperation === "deleteClocking") {
        this.deleteClocking(
          this.confirmationDialogOperationData.clocking,
          this.confirmationDialogOperationData.day
        );
      }
    },
    deleteClocking(clocking) {
      return new Promise((resolve) => {
        axios
          .delete(
            this.computeClockingQueryParameters(
              "employees/clocking/" + clocking.id,
              this.selectedEmployee.active_contract.id
            ),
            { headers: this.axiosOptions }
          )
          .finally(() => {})
          .then((response) => {
            this.clockingData = Object.values(response.data);
            resolve();
          });
      });
    },
    updateClockingData(payload) {
      this.clockingData = Object.values(payload);
    },
  },
};
</script>
<style scoped>
.edit-time {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
}

td {
  border: 1px solid lightgrey;
  font-size: 1rem !important;
}

input[type="time"] {
  border: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
