var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[(_vm.confirmationDialog)?_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}}):_vm._e(),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('users.name'),"error-messages":_vm.$store.state.validation && !_vm.$v.name.required
              ? [this.$t('users.text_messages.name_required')]
              : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"to-upper",attrs:{"disabled":!!this.$store.state.editId,"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('users.email'),"error-messages":_vm.$store.state.validation
              ? _vm.returnErrorMessage(
                  [
                    !_vm.$v.email.required,
                    this.$t('users.text_messages.email_required'),
                  ],
                  [
                    !_vm.$v.email.email,
                    this.$t('users.text_messages.invalid_email'),
                  ]
                )
              : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('users.password'),"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"error-messages":_vm.$store.state.validation
              ? _vm.returnErrorMessage(
                  [
                    !_vm.$v.password.required,
                    this.$t('users.text_messages.password_required'),
                  ],
                  [
                    !_vm.$v.password.minLength,
                    this.$t('users.text_messages.password_min_length'),
                  ]
                )
              : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.roles,"item-text":"text","item-value":"id","label":_vm.$t('users.role'),"error-messages":_vm.$store.state.validation && !_vm.$v.role.required
              ? [this.$t('users.text_messages.role_required')]
              : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.languages,"item-value":"id","item-text":"text","label":_vm.$t('users.language'),"error-messages":_vm.$store.state.validation && !_vm.$v.language.required
              ? [this.$t('users.text_messages.language_required')]
              : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-checkbox',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"color":"success","label":_vm.$t('users.active')},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-checkbox',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"color":"success","label":_vm.$t('users.approve')},model:{value:(_vm.approve),callback:function ($$v) {_vm.approve=$$v},expression:"approve"}})],1)],1)],1),_c('v-btn',{staticClass:"d-none",attrs:{"id":"submit-user"},on:{"click":function($event){return _vm.onSubmit()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"delete-user"},on:{"click":function($event){return _vm.onDelete()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }