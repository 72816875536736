<template>
  <v-dialog
    v-model="show"
    @click:outside="show = false"
    @keydown.esc="show = false"
    max-width="700px"
  >
    <v-card style="min-height: 500px">
      <v-card-title class="mb-0">
        {{ data.description }}<br />
        <TextComponentWithExtraIcons
          :value="data.identifier"
        ></TextComponentWithExtraIcons>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-layout justify-center align-center>
            <v-flex v-if="!imageError" shrink>
              <v-img
                style="max-height: 350px; max-width: 350px"
                v-if="imageUrl"
                lazy-src="@/assets/images/no-image.png"
                :src="imageUrl"
                v-on:error="onImgError"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-flex>
            <v-flex v-if="imageError" shrink>
              <v-img
                style="max-height: 350px; max-width: 350px"
                v-if="imageUrl"
                lazy-src="@/assets/images/no-image.png"
                src="@/assets/images/no-image.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: [Boolean],
    data: [Object],
    imageUrl: [String],
  },
  data() {
    return {
      imageError: false,
    };
  },
  mounted() {},
  methods: {
    onImgError() {
      this.imageError = true;
    },
  },
  watch: {
    show(val) {
      if (val == false) {
        this.$emit("closePopUp", false);
      }
    },
  },
};
</script>
