<template>
  <v-card flat>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <v-form
      ref="form"
      class="main_form"
      id="billing-form"
      @submit.prevent="submit"
    >
      <v-btn type="submit" class="d-none" id="submit-billing"></v-btn>
      <input type="hidden" name="id" />
      <v-container fluid>
        <v-overlay :value="!isPageLoaded">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row justify="space-between">
          <v-col class="d-flex pb-0" cols="12" sm="2">
            <v-autocomplete
              :autocomplete="autocompleteValueLocal"
              v-model="selectedBill"
              class="mr-2 to-upper"
              :items="bills"
              :search-input.sync="search_bill"
              item-text="bill_number"
              item-value="id"
              :loading="isLoadingSearchRegiClient"
              :label="$t('billings.search_bill')"
              :placeholder="$t('billings.start_typing')"
              return-object
              append-icon="mdi-close"
              v-on:keydown="handleInput($event, 'alphanumeric', 20)"
              v-on:keydown.enter.prevent=""
              ref="billDropdown"
              @click:append="
                onResetPageData();
                search_bill = null;
                looseFocusFromBillBox();
              "
              :outlined="is_boxes"
              :dense="is_boxes"
              :hide-no-data="true"
              hide-selected
            >
              <template slot="item" slot-scope="{ item }">
                <v-list-item-content>
                  <span class="pl-7 bill_type" v-if="item.type.key == 'bill'">
                    Bill
                  </span>
                  <span
                    class="pl-7 bill_type"
                    v-if="item.type.key == 'purchase_order'"
                  >
                    Purchase Order
                  </span>
                  <span
                    class="pl-7 bill_type"
                    v-if="item.type.key == 'estimation'"
                  >
                    Estimation
                  </span>
                  <span
                    class="pl-7 bill_type"
                    v-if="item.type.key == 'credit_note'"
                  >
                    Credit Note
                  </span>
                  <span>
                    <v-icon
                      class="ma-0"
                      v-if="item.type.key == 'bill'"
                      color="red"
                      right
                    >
                      mdi-script-text
                    </v-icon>
                    <v-icon
                      class="ma-0"
                      v-if="item.type.key == 'purchase_order'"
                      color="green"
                      right
                    >
                      mdi-tag-text
                    </v-icon>
                    <v-icon
                      class="ma-0"
                      v-if="item.type.key == 'estimation'"
                      color="black"
                      right
                    >
                      mdi-book-open
                    </v-icon>
                    <v-icon
                      class="ma-0"
                      v-if="item.type.key == 'credit_note'"
                      color="black"
                      right
                    >
                      mdi-note-multiple
                    </v-icon>
                    {{ item.bill_number }}
                  </span>
                  <br />
                  <span class="pl-7 client_name" v-if="item.client">
                    <template v-if="item.client.company"> Company - </template>
                    <template v-else> Private - </template>
                    {{ item.client.name }}
                  </span>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- <v-col class="d-flex pb-0" cols="12" sm="2">
            
          </v-col> -->
          <v-col class="d-flex pb-0 justify-end" cols="12" sm="5">
            <v-col class="d-flex pa-0 ma-0" cols="12" sm="3">
              <v-text-field
                v-if="
                  parent_company_client_name &&
                  parent_company_client_name &&
                  parent_company_client_name.credit_limit >= 0
                "
                v-model="customer_balance"
                :label="$t('billings.customer_balance')"
                readonly
                class="mr-2 to-upper"
                :class="customer_balance > 0 ? 'customerbalance' : ''"
                v-on:keydown.enter.prevent=""
                append-icon="mdi-information"
                disabled
                :outlined="is_boxes"
                :dense="is_boxes"
              >
              </v-text-field>
            </v-col>
            <v-select
              v-if="ispayment_method"
              v-model="payment_method"
              :items="payment_methods"
              class="pr-2 to-upper"
              :label="$t('billings.payment_methods.title')"
              item-text="text"
              item-value="id"
              return-object
              v-on:keydown.enter.prevent=""
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-select>
            <v-select
              class="to-upper"
              @click="filterOutStatuses"
              v-if="isVisibleOrderStatus"
              :disabled="disableOrderOrExpenseStatus(selectedBill)"
              v-model="order_status"
              :items="billstatuses"
              :label="$t('billings.status')"
              item-text="text"
              item-value="id"
              return-object
              v-on:keydown.enter.prevent=""
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-select>
            <v-col class="d-flex pa-0 ma-0" cols="12" sm="4">
              <v-menu
                v-model="menu_due_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="due_date"
                    :label="$t('billings.due_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="due_date = null"
                    append-icon="mdi-close"
                    class="mr-2 to-upper"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  v-model="due_date"
                  @input="menu_due_date = false"
                  no-title
                  scrollable
                  :locale="userLanguageId"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-col>
        </v-row>

        <v-row class="group_label" v-if="checkTag('clients')">
          <v-col class="pb-0">
            <span style="color: #2196f3"> {{ $t("billings.client") }} </span>
          </v-col>
        </v-row>

        <v-row class="pa-3 pt-6">
          <create-edit-client
            v-if="checkTag('clients')"
            ref="clientComp"
            :comp_entityType.sync="parent_entityType"
            :comp_client_id.sync="parent_client_id"
            :comp_company.sync="parent_company"
            :comp_company_client_name.sync="parent_company_client_name"
            :comp_company_client_names.sync="parent_company_client_names"
            :comp_search_client.sync="parent_search_client"
            :comp_company_client_phone.sync="parent_company_client_phone"
            :comp_company_client_gsm.sync="parent_company_client_gsm"
            :comp_company_client_fax.sync="parent_company_client_fax"
            :comp_company_client_email.sync="parent_company_client_email"
            :comp_web.sync="parent_web"
            :comp_currency.sync="parent_currency"
            :comp_representative_company_id.sync="
              parent_representative_company_id
            "
            :comp_user.sync="parent_user"
            :comp_note.sync="parent_note"
            :comp_familyClient.sync="parent_familyClient"
            :comp_denomination.sync="parent_denomination"
            :comp_tva.sync="parent_tva"
            :comp_address.sync="parent_address"
            :comp_country.sync="parent_country"
            :comp_city.sync="parent_city"
            :comp_county.sync="parent_county"
            :comp_zipcode.sync="parent_zipcode"
            :comp_shipping_address.sync="parent_shipping_address"
            :comp_shipping_country.sync="parent_shipping_country"
            :comp_shipping_city.sync="parent_shipping_city"
            :comp_shipping_county.sync="parent_shipping_county"
            :comp_shipping_zipcode.sync="parent_shipping_zipcode"
            :comp_same_as.sync="parent_address_same_as"
            :comp_is_shipping_visible="true"
            :comp_is_billing_visible="true"
            :hideDenomination="true"
          />
        </v-row>

        <v-row class="group_label pt-4" v-if="checkTag('projects')">
          <v-col cols="12" sm="12" class="mt-0 pt-0">
            <create-edit-project
              v-if="checkTag('projects')"
              ref="clientComp"
              :comp_entityType.sync="parent_entityType"
              :comp_client_id.sync="parent_client_id"
              :comp_company.sync="parent_company"
              :comp_company_client_name.sync="parent_company_client_name"
              :comp_company_client_first_name.sync="
                parent_company_client_first_name
              "
              :comp_company_client_names.sync="parent_company_client_names"
              :comp_search_client.sync="parent_search_client"
              :comp_company_client_phone.sync="parent_company_client_phone"
              :comp_company_client_gsm.sync="parent_company_client_gsm"
              :comp_company_client_fax.sync="parent_company_client_fax"
              :comp_company_client_email.sync="parent_company_client_email"
              :comp_web.sync="parent_web"
              :comp_currency.sync="parent_currency"
              :comp_representative_company_id.sync="
                parent_representative_company_id
              "
              :comp_user.sync="parent_user"
              :comp_note.sync="parent_note"
              :comp_familyClient.sync="parent_familyClient"
              :comp_denomination.sync="parent_denomination"
              :comp_tva.sync="parent_tva"
              :comp_address.sync="parent_address"
              :comp_country.sync="parent_country"
              :comp_city.sync="parent_city"
              :comp_county.sync="parent_county"
              :comp_zipcode.sync="parent_zipcode"
              :comp_shipping_address.sync="parent_shipping_address"
              :comp_shipping_country.sync="parent_shipping_country"
              :comp_shipping_city.sync="parent_shipping_city"
              :comp_shipping_county.sync="parent_shipping_county"
              :comp_shipping_zipcode.sync="parent_shipping_zipcode"
              :comp_same_as.sync="parent_address_same_as"
              :comp_is_shipping_visible="true"
            />
          </v-col>
        </v-row>
        <v-row class="group_label" v-if="checkTag('vehicles')">
          <v-col class="pb-0">
            <span style="color: #2196f3"> Vehicle </span>
          </v-col>
        </v-row>

        <v-row class="pa-3 pt-6">
          <create-edit-vehicle
            v-if="checkTag('vehicles')"
            ref="vehicleComp"
            :comp_vehicle_type.sync="parent_vehicle_type"
            :comp_registration_no.sync="parent_registration_no"
            :comp_brand.sync="parent_brand"
            :comp_model.sync="parent_model"
            :comp_km.sync="parent_km"
            :comp_reg_date.sync="parent_reg_date"
            :comp_creditor.sync="parent_creditor"
            :comp_creditors.sync="creditors"
            :comp_vin.sync="parent_vin"
            :comp_is_leasing.sync="parent_is_leasing"
            :comp_client_detail.sync="parent_company_client_name"
          />
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0">
            <v-text-field
              class="to-upper"
              :label="$t('billings.reference_client')"
              v-model="reference_client"
              :error-messages="
                $store.state.validation && !$v.reference_client.maxLength
                  ? [
                      this.$t(
                        'billings.text_messages.reference_client_max_length'
                      ),
                    ]
                  : []
              "
              v-on:keydown.enter.prevent=""
              v-on:keydown="handleInput($event, 'alphanumeric', 50)"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <v-col class="pt-0 pb-0">
            <v-text-field
              class="to-upper"
              :label="$t('billings.note')"
              v-model="note"
              :error-messages="
                $store.state.validation && !$v.note.maxLength
                  ? [this.$t('billings.text_messages.note_max_length')]
                  : []
              "
              v-on:keydown.enter.prevent=""
              v-on:keydown="handleInput($event, 'alphanumeric', 200)"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>

          <v-col class="pt-0 pb-0">
            <t-date-time-field
              :label="$t('billings.rdv_date')"
              :dateFormat="yyyy - MM - dd"
              v-model="rdv_date"
              append-icon="mdi-calendar"
              :disabled="true"
            ></t-date-time-field>
          </v-col>
        </v-row>

        <v-row class="pt-4" v-if="checkTag('billing')">
          <v-data-table
            :footer-props="{
              'items-per-page-options': dataTableOptions,
            }"
            :headers="dataHeader"
            :hide-default-footer="true"
            :hide-default-header="true"
            :items="selectedArticles"
            :items-per-page="50"
            class="elevation-0"
            style="width: 100%"
            disable
          >
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
                <tr>
                  <template v-for="header in headers">
                    <th :key="header.value" scope="col" class="text-right">
                      <template v-if="header.value == 'article_original_name'">
                        <v-row justify="space-between">
                          <v-col class="d-flex" cols="12" sm="2">
                            {{ header.text }}
                          </v-col>
                          <v-col
                            v-if="
                              selectedFamilyArticle &&
                              selectedFamilyArticle.is_stock_managed == 1
                            "
                            cols="12"
                            sm="3"
                            class="ma-1 pt-0 pb-0"
                            v-on:keydown.enter.prevent="
                              parentInternalStockOnly = !parentInternalStockOnly
                            "
                          >
                            <v-checkbox
                              class="mt-1"
                              hide-details="auto"
                              v-model="parentInternalStockOnly"
                              :label="$t('billings.is_only_internal')"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else-if="header.value == 'actions'">
                        <v-row justify="space-between">
                          <v-col class="d-flex dontbreakline" cols="12" sm="2">
                            {{ header.text }}
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else>
                        {{ header.text }}
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:no-data>
              <span></span>
            </template>
            <template v-slot:body.prepend>
              <tr
                class="first_row"
                v-if="!(selectedBill && selectedBill.status_id == 10251003)"
              >
                <td></td>
                <td class="pl-4 pr-2" style="width: 1000px !important">
                  <v-row>
                    <!-- <v-col class="d-flex" cols="12" sm="3">
                          <v-select
                              v-model="familyArticle"
                              :items="familyArticles"
                              item-value="id"
                              item-text="name"
                              class="mr-0"
                              outlined
                              dense
                              return-object
                              hide-details="auto"
                              :outlined="is_boxes"
                              :dense="is_boxes"
                          ></v-select>
                      </v-col> -->

                    <search-article
                      :class="article ? 'articleset' : 'articlenotset'"
                      @changeDialog="changeDialog()"
                      :compSelectedArticleId.sync="
                        parentSearchSelectedArticleId
                      "
                      :internalStockOnly.sync="parentInternalStockOnly"
                      :selectedFamilyArticle.sync="selectedFamilyArticle"
                    ></search-article>

                    <v-text-field
                      v-if="typeof article == 'object'"
                      :autocomplete="autocompleteValueLocal"
                      class="ma-0 pa-0 to-upper selectedArticleTextBox"
                      append-icon="mdi-close"
                      @click:append="resetSelectedArticle()"
                      v-model="article.title_description[0].description"
                      hide-details="auto"
                      v-on:keydown.enter.prevent="addArticleToDataTable()"
                      v-on:keydown.prevent="
                        jumpToField($event, 'tableQuantity')
                      "
                      v-on:keydown.tab.prevent="
                        jumpToField($event, 'tableQuantity')
                      "
                      outlined
                      dense
                    ></v-text-field>

                    <!-- <v-col>
                          <v-autocomplete
                              :autocomplete="autocompleteValueLocal"
                              :disabled="isRecalculatingTableData"
                              v-model="article"
                              class="pa-0 article_search to-upper"
                              :items="articles_list"
                              :loading="isLoadingArticle"
                              hide-no-data
                              hide-details="auto"
                              hide-selected
                              item-text="title_description[0].description"
                              item-value="id"
                              placeholder="Start typing to Search"
                              outlined
                              dense
                              return-object 
                              no-filter 
                              tabindex="1"
                              ref="tableArticleSearch"
                              readonly
                              @focus="showDialog()"
                              v-on:keydown.enter.prevent=""
                              v-on:keypress="checkIfFamilyClientEmpty($event)"
                              v-on:keydown.tab.prevent="jumpToField($event, 'tableQuantity')"
                              @change="jumpToField($event, 'tableQuantity')"
                              :outlined="is_boxes"
                              :dense="is_boxes"
                            >
                              <template slot="item" slot-scope="{ item }">
                                  <v-list-item-content>
                                    <v-row>
                                      <v-col class="d-flex" cols="12" sm="10">
                                        <p class='article_name' style="padding-left:5px">{{item.title_description[0].description}}
                                          <br/>
                                          <span class="identifier">{{item.identifier}}</span>
                                        </p>
                                      </v-col>
                                      <template v-if="item.internal_company_article && item.internal_company_article.length > 0">
                                        <v-col class="d-flex justify-end" style="margin-left:-10px" cols="12" sm="2">
                                          <span style="color:red" v-if="item.internal_company_article[0].stock  <= 0">{{ item.internal_company_article[0].stock   }}</span>
                                          <span style="color:#dede1d" v-if="item.internal_company_article[0].stock   >= 1 && item.stock <= 5 ">{{ item.internal_company_article[0].stock   }}</span>
                                          <span style="color:green" v-if="item.internal_company_article[0].stock   > 5 ">{{ item.internal_company_article[0].stock   }}</span>
                                        </v-col>
                                      </template>
                                      <template v-else>
                                        <v-col class="d-flex justify-end" style="margin-left:-10px" cols="12" sm="2">
                                          <span style="color:red"> 0 </span>
                                        </v-col>
                                      </template>                              
                                    </v-row>
                                    
                                      
                                  </v-list-item-content>
                              </template>
                            </v-autocomplete>
                      </v-col> -->
                  </v-row>
                </td>
                <td
                  align="right"
                  class="pl-2 pr-0"
                  v-on:keydown.tab.prevent="jumpToField('', 'tableUnitPrice')"
                  v-on:keydown.enter.prevent=""
                  v-on:keydown.enter="addArticleToDataTable()"
                  v-on:keydown="
                    handleInput(
                      $event,
                      selectedFamilyArticle
                        ? selectedFamilyArticle.quantity_decimals > 0
                          ? 'float'
                          : 'int'
                        : 'int',
                      10
                    )
                  "
                >
                  <custom-money-input
                    :autocomplete="autocompleteValueLocal"
                    :disabled="isRecalculatingTableData"
                    class="pt-0 to-upper right-aligned-input shrink"
                    hide-details="auto"
                    outlined
                    dense
                    tabindex="2"
                    style="width: 95px"
                    ref="tableQuantity"
                    v-model="quantity"
                    v-bind:properties="{
                      prefix: selectedFamilyArticle
                        ? selectedFamilyArticle.mu.value
                        : '',
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      placeholder: selectedFamilyArticle
                        ? selectedFamilyArticle.quantity_decimals > 0
                          ? makeZeros(selectedFamilyArticle.quantity_decimals)
                          : '0'
                        : '0',
                      dense: true,
                      hideDetails: 'auto',
                    }"
                    v-bind:options="{
                      locale: userLanguageId,
                      length: 12,
                      precision: selectedFamilyArticle
                        ? selectedFamilyArticle.quantity_decimals > 0
                          ? selectedFamilyArticle.quantity_decimals
                          : 0
                        : 0,
                      empty: selectedFamilyArticle
                        ? selectedFamilyArticle.quantity_decimals > 0
                          ? makeZeros(selectedFamilyArticle.quantity_decimals)
                          : '0'
                        : '0',
                    }"
                  />
                </td>
                <td
                  align="right"
                  class="pl-2 pr-0"
                  v-on:keydown.enter="addArticleToDataTable()"
                  v-on:keydown.exact="checkIfMinusPressed($event)"
                  v-on:keydown.tab.prevent="
                    jumpToField($event, 'tableRedPercentage')
                  "
                >
                  <custom-money-input
                    :autocomplete="autocompleteValueLocal"
                    :disabled="isRecalculatingTableData"
                    class="pt-0 to-upper right-aligned-input shrink"
                    hide-details="auto"
                    outlined
                    dense
                    tabindex="3"
                    style="width: 100px"
                    ref="tableUnitPrice"
                    v-model="unit_price"
                    v-bind:properties="{
                      prefix: currencySymbol,
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                      dense: true,
                      hideDetails: 'auto',
                    }"
                    v-bind:options="{
                      locale: userLanguageId,
                      length: 12,
                      precision: getDecimalNumber(),
                      empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                    }"
                  />
                </td>
                <td
                  align="right"
                  class="pl-2 pr-0"
                  v-on:keydown.enter="addArticleToDataTable()"
                  v-on:keydown.tab.prevent="addArticleToDataTable()"
                >
                  <custom-money-input
                    :autocomplete="autocompleteValueLocal"
                    :disabled="isRecalculatingTableData"
                    v-model="red_percentage"
                    :background-color="
                      !$v.red_percentage.maxValue
                        ? '#ffc2b7'
                        : !$v.red_percentage.minValue
                        ? '#ffc2b7'
                        : 'white'
                    "
                    v-bind:properties="{
                      suffix: '%',
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      clearable: false,
                      placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                      dense: true,
                      hideDetails: 'auto',
                    }"
                    v-bind:options="{
                      locale: userLanguageId,
                      length: 3,
                      precision: getDecimalNumber(),
                      empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                    }"
                    class="pt-0 to-upper right-aligned-input shrink"
                    hide-details="auto"
                    outlined
                    dense
                    tabindex="4"
                    style="width: 80px"
                    v-bind:focus="true"
                    ref="tableRedPercentage"
                  >
                  </custom-money-input>
                </td>
                <!-- <td style="text-align:right">{{ first_row_tariff }}</td> -->
                <!-- <td style="text-align:right">{{ first_row_tva }}</td> -->
                <td align="right" class="pl-2 pr-0">
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="first_row_tva"
                    class="pt-0 to-upper right-aligned-input shrink"
                    style="width: 70px"
                    hide-details="auto"
                    dense
                    outlined
                    required
                    v-on:keydown.enter.prevent=""
                  ></v-text-field>
                </td>
                <td
                  class="pl-2 pr-0"
                  style="text-align: right"
                  v-on:keyup="reverseCalculate(null, first_row_thtva)"
                  v-on:keydown.enter="addArticleToDataTable()"
                  v-on:keydown.tab.prevent="addArticleToDataTable()"
                >
                  <custom-money-input
                    :autocomplete="autocompleteValueLocal"
                    :disabled="isRecalculatingTableData"
                    class="pt-0 to-upper right-aligned-input shrink"
                    hide-details="auto"
                    outlined
                    dense
                    tabindex="3"
                    style="width: 100px"
                    ref="tableFirstRowThtva"
                    v-model="first_row_thtva"
                    v-bind:properties="{
                      prefix: currencySymbol,
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                      dense: true,
                      hideDetails: 'auto',
                    }"
                    v-bind:options="{
                      locale: userLanguageId,
                      length: 12,
                      precision: getDecimalNumber(),
                      empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                    }"
                  />
                </td>
                <td
                  class="pl-2 pr-0"
                  style="text-align: right"
                  v-on:keyup="reverseCalculate(first_row_total, null)"
                  v-on:keydown.enter="addArticleToDataTable()"
                  v-on:keydown.tab.prevent="addArticleToDataTable()"
                >
                  <custom-money-input
                    :autocomplete="autocompleteValueLocal"
                    :disabled="isRecalculatingTableData"
                    class="pt-0 to-upper right-aligned-input shrink"
                    hide-details="auto"
                    outlined
                    dense
                    tabindex="3"
                    style="width: 100px"
                    ref="tableFirstRowTotal"
                    v-model="first_row_total"
                    v-bind:properties="{
                      prefix: currencySymbol,
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                      dense: true,
                      hideDetails: 'auto',
                    }"
                    v-bind:options="{
                      locale: userLanguageId,
                      length: 12,
                      precision: getDecimalNumber(),
                      empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                    }"
                  />
                </td>
                <td>
                  <v-btn
                    color="primary"
                    dark
                    class="outlinedbuttonheight"
                    @click="addArticleToDataTable()"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:item.information="{ item }">
              <v-tooltip
                bottom
                z-index="9999"
                v-if="
                  item.article.family &&
                  item.article.family.is_stock_managed == 1
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="
                      item.article.internal_company_article &&
                      item.article.internal_company_article.length > 0 &&
                      item.article.internal_company_article[0].stock <
                        item.quantity
                    "
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{
                  $t("billings.text_messages.article_does_not_have_stock")
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.article_original_name="{ item }">
              <v-row>
                <v-col cols="12" sm="12">
                  <template v-if="!item.text">
                    {{
                      item.article_original_name.title_description[0]
                        .description
                    }}<br />
                    <span>
                      <TextComponentWithExtraIcons
                        :value="item.article_original_name.identifier"
                      ></TextComponentWithExtraIcons>
                    </span>
                  </template>
                  <template v-if="item.text">
                    <div v-html="item.text"></div>
                  </template>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.quantity="{ item }">
              <v-row>
                <v-col cols="12" sm="12">
                  <template v-if="item.quantity">
                    <!-- {{ item.article_original_name }} -->
                    <span>
                      {{
                        fixDecimal(
                          item.quantity,
                          item
                            ? item.article_original_name
                              ? item.article_original_name.family
                                  .quantity_decimals
                              : 0
                            : 0
                        )
                      }}
                      {{
                        item.article_original_name
                          ? item.article_original_name.family.mu.value
                          : "pc."
                      }}
                      <!-- Here we have hardcoded if nothing is their then family mu -->
                    </span>
                    <!-- <span> {{ item.quantity }} </span> -->
                  </template>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.rounded_unit_price="{ item }">
              <v-row>
                <v-col cols="12" sm="12">
                  <span v-if="billType.key == 'credit_note'">
                    <!-- {{ item.rounded_unit_price ? (parseFloat(item.rounded_unit_price) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) : (0).toFixed(getDecimalNumber()) }} -->
                    {{
                      parseFloat(item.rounded_unit_price).toFixed(
                        getDecimalNumber()
                      )
                    }}
                  </span>
                  <span v-else>
                    {{
                      item.rounded_unit_price
                        ? parseFloat(item.rounded_unit_price).toFixed(
                            getDecimalNumber()
                          )
                        : (0).toFixed(getDecimalNumber())
                    }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.red_percentage="{ item }">
              <v-row>
                <v-col cols="12" sm="12">
                  <span>
                    {{
                      (item.red_percentage ? item.red_percentage : 0).toFixed(
                        getDecimalNumber()
                      )
                    }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.tva="{ item }">
              <v-row>
                <v-col cols="12" sm="12">
                  <span>
                    {{ (item.tva ? item.tva : 0).toFixed(getDecimalNumber()) }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.thtva="{ item }">
              <v-row>
                <v-col cols="12" sm="12">
                  <span v-if="billType.key == 'credit_note'">
                    <!-- {{ ((item.thtva ? item.thtva : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                    {{ item.thtva.toFixed(getDecimalNumber()) }}
                  </span>
                  <span v-else>
                    {{
                      (item.thtva ? item.thtva : 0).toFixed(getDecimalNumber())
                    }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.total="{ item }">
              <v-row>
                <v-col cols="12" sm="12">
                  <span v-if="billType.key == 'credit_note'">
                    <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                    {{ item.total.toFixed(getDecimalNumber()) }}
                  </span>
                  <span v-else>
                    {{
                      (item.total ? item.total : 0).toFixed(getDecimalNumber())
                    }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-row>
                <v-col cols="9" sm="9">
                  <!-- {{ selectedArticles[item.srno ? item.srno  : 0].dots }} -->
                  <template
                    v-if="
                      selectedArticles[item.srno ? item.srno : 0] &&
                      Array.isArray(
                        selectedArticles[item.srno ? item.srno : 0].dots
                      ) &&
                      selectedArticles[item.srno ? item.srno : 0].dots.length >=
                        0
                    "
                  >
                    <v-autocomplete
                      v-if="
                        item.article_original_name &&
                        item.article_original_name.family &&
                        (item.article_original_name.family.id == 1 ||
                          item.article_original_name.family.parent_family_id ==
                            1) &&
                        selectedArticles[item.srno ? item.srno : 0]
                          .depot_out_id == null &&
                        selectedArticles[item.srno ? item.srno : 0]
                          .depot_in_id == null
                      "
                      v-model="
                        selectedArticles[item.srno ? item.srno : 0]
                          .stock_property
                      "
                      :items="
                        selectedArticles[item.srno ? item.srno : 0].dots
                          ? selectedArticles[item.srno ? item.srno : 0].dots
                          : []
                      "
                      item-text="property"
                      item-value="property"
                      hide-details="auto"
                      single-line
                      :placeholder="$t('billings.dot')"
                      class="pt-0 to-upper"
                      dense
                      tabindex="2"
                      style="width: 90px"
                      @focus="$event.target.select()"
                      ref="dot"
                      append-icon=""
                      v-on:keydown.enter.prevent=""
                      v-on:keydown="
                        handleInput($event, 'int', 4);
                        fineDataForSending();
                      "
                      :outlined="is_boxes"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <template>
                          {{ item.property }} X {{ item.counter }}
                        </template>
                      </template>
                    </v-autocomplete>
                  </template>
                  <template v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-progress-circular
                          v-bind="attrs"
                          v-on="on"
                          indeterminate
                          color="primary"
                          :size="20"
                          :width="3"
                        ></v-progress-circular>
                      </template>
                      <span>
                        {{ $t("please_wait_while_we_are_loading_dots") }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-col>
                <v-col
                  v-if="!(selectedBill && selectedBill.status_id == 10251003)"
                  cols="3"
                  sm="3"
                  class="pa-0 pt-4"
                >
                  <v-icon small color="red" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </template>

            <template v-slot:body.append>
              <tr
                v-if="selectedArticles.length > 0"
                style="background-color: rgba(142, 142, 142, 0.07)"
              >
                <th class="text-sm-right" v-text="''" />
                <th class="text-sm-right" v-text="''" />
                <th class="text-sm-right" v-text="''" />
                <th class="text-sm-right" v-text="''" />
                <th class="text-sm-right" v-text="''" />
                <!-- <th class="text-sm-right" v-text="''" /> -->
                <th class="text-sm-right" v-text="''" />
                <th class="text-sm-right">
                  <span v-if="billType.key == 'credit_note'">
                    <!-- {{(articleTHTVATotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                    {{ articleTHTVATotal.toFixed(getDecimalNumber()) }}
                  </span>
                  <span v-else>
                    {{
                      (articleTHTVATotal ? articleTHTVATotal : 0).toFixed(
                        getDecimalNumber()
                      )
                    }}
                  </span>
                </th>
                <th class="text-sm-right">
                  <span v-if="billType.key == 'credit_note'">
                    <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                    {{ articleTotal.toFixed(getDecimalNumber()) }}
                  </span>
                  <span v-else>
                    {{
                      (articleTotal ? articleTotal : 0).toFixed(
                        getDecimalNumber()
                      )
                    }}
                  </span>
                </th>
                <th class="text-sm-right" v-text="''" />
              </tr>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-form>

    <v-btn @click="onSubmit()" class="d-none" id="submit-billing"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-billing"></v-btn>
    <v-btn
      @click="
        onResetPageData();
        goBackToListing();
      "
      class="d-none"
      id="reset-page-data"
    ></v-btn>
    <v-btn
      @click="confirmPurchaseOrderPopUp()"
      class="d-none"
      id="make-purchase-order"
    ></v-btn>
    <v-btn
      @click="confirmMakeBillPopUp()"
      class="d-none"
      id="make-bill"
    ></v-btn>
    <v-btn
      @click="confirmCreditNotePopUp()"
      class="d-none"
      id="make-credit-note"
    ></v-btn>
    <v-btn @click="printBill()" class="d-none" id="print-bill"></v-btn>
    <v-btn @click="emailBill()" class="d-none" id="email-bill"></v-btn>
    <v-btn @click="depotIn()" class="d-none" id="depot-in"></v-btn>
    <v-btn @click="depotOut()" class="d-none" id="depot-out"></v-btn>
    <v-btn @click="worksheetBill()" class="d-none" id="worksheet-bill"></v-btn>
    <v-btn
      @click="printReportFromTopButton()"
      class="d-none"
      id="print-report"
    ></v-btn>

    <depot-add-modify-tyre
      v-if="depotInMod == true"
      :depotDetails="null"
      :vehicleDriverDetails.sync="vehicleDriverDetails"
      @modifyClose="modifyDepotInClose"
    ></depot-add-modify-tyre>
    <depot-out
      v-if="depotOutMod == true"
      :vehicleDriverDetails.sync="vehicleDriverDetails"
      :alreadySelectedDepot="selectedArticles"
      @modifyClose="modifyDepotOutClose"
    ></depot-out>
    <loading-model :showLoadingModel.sync="showLoadingModel"></loading-model>

    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-card>
</template>
<style scoped>
.first_row:hover:not(.v-table__expanded__content) {
  background: white !important;
}
.supplier_name {
  font-size: 12px;
  margin: 0px;
}
.article_name {
  font-size: 14px;
  margin-bottom: 2px;
}
.bill_number {
  font-size: 14px;
  margin: 0px;
}
.identifier {
  font-size: 12px;
  margin-bottom: 2px;
}
.client_name {
  font-size: 11px;
  margin-bottom: 2px;
}
.bill_type {
  font-size: 11px;
  margin-bottom: 2px;
}
</style>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
// import { EDIT_MODE } from "@/config";;
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import { BILLING_MODE } from "@/config";
import CreateEditClient from "@/components/Client/CreateEditClient";
import CreateEditProject from "@/components/Projects/CreateEditProject";
import CreateEditVehicle from "@/components/Vehicles/CreateEditVehicle";

import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import SearchArticle from "@/components/Billing/SearchArticle";
import DepotAddModifyTyre from "@/components/TyreHotel/DepotAddModifyTyre";
import DepotOut from "@/components/TyreHotel/DepotOut";
import LoadingModel from "@/components/LoadingModel";
import ConfirmationModel from "@/components/ConfirmationModel";
import DateTimePicker from "@/components/DateTimePicker";

export default {
  mixins: [validationMixin],
  validations: {
    // Client Validation
    // Vehicle Validation
    parent_familyClient: {
      required,
    },
    red_percentage: {
      maxValue: maxValue(100),
      minValue: minValue(0),
    },
    reference_client: {
      maxLength: maxLength(200),
    },
    note: {
      maxLength: maxLength(200),
    },
  },
  name: "Billing",
  props: {
    iCompany: Boolean,
  },
  components: {
    "create-edit-client": CreateEditClient,
    "create-edit-vehicle": CreateEditVehicle,
    "search-article": SearchArticle,
    "depot-add-modify-tyre": DepotAddModifyTyre,
    "depot-out": DepotOut,
    "loading-model": LoadingModel,
    "confirmation-model": ConfirmationModel,
    "t-date-time-field": DateTimePicker,
    "custom-money-input": CutsomMoneyInput,
    "create-edit-project": CreateEditProject,
  },
  data() {
    return {
      isVehicleModuleEnabled: this.getCompanyPropertyFromLocalStorage(
        "LINK_VEHICLE_TO_BILL"
      )
        ? this.getCompanyPropertyFromLocalStorage("LINK_VEHICLE_TO_BILL", 1)
        : true,
      selectedFamilyArticle: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      creditNoteMultiplicationValue: null,
      storedDepotIn: [],
      depotInMod: false,
      depotOutMod: false,
      parentSearchSelectedArticleId: null,
      parentInternalStockOnly: true,
      newValidation: false,
      billstatuses: [],
      notificationData: [],
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      DeleteConfirm: false,
      denominations: [],
      allStatuses: [],
      order_status: "",
      isVisibleOrderStatus: false,
      isRecalculatingTableData: false,
      payment_methods: [],
      payment_method: null,
      ispayment_method: false,
      operation: "edit",
      bill_amount: "",
      creditors: [],
      showLoadingModel: false,

      rdv_date: null,
      due_date: this.getDueDate,
      menu_rdv_date: false,
      menu_due_date: false,

      note: "",
      tvas: [],
      reference_client: "",
      isPageLoaded: false,
      unit_price: 0.0,
      quantity: 4,
      first_row_tariff: 0,
      first_row_tva: "",
      cached_first_row_tva: "",
      first_row_thtva: "",
      first_row_total: "",
      red_percentage: 0.0,
      next_event_codes: [],
      next_event_code: "",
      search: "",
      isLoadingArticle: false,
      isLoading: false,
      rowNumber: 0,
      articleTHTVATotal: 0.0,
      articleTotal: 0.0,
      selectedArticles: [],
      finedSelectedArticles: [],
      articles: [],
      article: "",
      search_article: "",
      search_bill: "",
      allowContinueSearch: true,
      bills: [],
      selectedBill: "",
      isLoadingSearchRegiClient: false,
      familyArticle: "",
      customer_balance: 0,

      // Vehicle Component Sync Values
      parent_vehicle_type: null,
      parent_registration_no: null,
      parent_brand: null,
      parent_model: null,
      parent_km: null,
      parent_reg_date: null,
      parent_creditor: null,
      parent_vin: null,
      parent_is_leasing: false,

      // Client Component Sync Values
      parent_entityType: null,
      parent_client_id: null,
      parent_company: null,
      parent_company_client_name: null,
      parent_company_client_names: [],
      parent_search_client: null,
      parent_company_client_phone: null,
      parent_company_client_gsm: null,
      parent_company_client_fax: null,
      parent_company_client_email: null,
      parent_web: null,
      parent_currency: null,
      parent_representative_company_id: null,
      parent_user: null,
      parent_note: null,
      parent_familyClient: null,
      parent_denomination: null,
      parent_registration_code: null,
      parent_tva: null,

      parent_address: null,
      parent_city: null,
      parent_county: null,
      parent_country: null,
      parent_zipcode: null,
      parent_address_same_as: false,

      parent_shipping_address: null,
      parent_shipping_city: null,
      parent_shipping_county: null,
      parent_shipping_country: null,
      parent_shipping_zipcode: null,

      dialog: false,

      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      dataHeader: [
        {
          text: "",
          align: "right",
          width: 5,
          sortable: false,
          value: "information",
        },
        {
          text: this.$t("billings.article"),
          align: "start",
          width: 450,
          sortable: true,
          value: "article_original_name",
        },
        {
          text: this.$t("billings.quantity"),
          align: "end",
          width: 80,
          sortable: true,
          value: "quantity",
        },
        // { text: this.$t('billings.unit_price'),           align: 'end',    width:80,  sortable: false,         value: 'unit_price' },
        {
          text: this.$t("billings.unit_price"),
          align: "end",
          width: 80,
          sortable: false,
          value: "rounded_unit_price",
        },
        {
          text: this.$t("billings.red_percentage"),
          align: "end",
          width: 80,
          sortable: false,
          value: "red_percentage",
        },
        // { text: this.$t('billings.tarrif'),               align: 'end',    width:100,  sortable: false,         value: 'tarrif' },
        {
          text: this.$t("billings.tva"),
          align: "end",
          width: 100,
          sortable: false,
          value: "tva",
        },
        {
          text: this.$t("billings.thtva"),
          align: "end",
          width: 125,
          sortable: false,
          value: "thtva",
        },
        {
          text: this.$t("billings.total"),
          align: "end",
          width: 125,
          sortable: false,
          value: "total",
        },
        {
          text: this.$t("billings.actions") + "      .",
          align: "center",
          width: 200,
          sortable: false,
          value: "actions",
        },
      ],

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      selectedDepot: null,
      pauseWatchForUnitPrice: false,
    };
  },
  mounted() {
    this.$store.commit("setValidation", false); // enable validation
    this.autocompleteValueLocal = this.autocompleteValue();
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setFormMode", BILLING_MODE);
    this.$store.commit("setShowPrintReportInPrintDropDown", false);
    this.creditNoteMultiplicationValue =
      this.getCompanyPropertyFromLocalStorage(
        "CREDIT_NOTE_MULTIPLICATION_VALUE"
      ) ?? this.creditNoteMultiplicationDefaultValue;
    this.setCurrentFormOperation();
    this.getPaymentMethods();
    this.getCreditors();
    this.getTvas();
    this.getOrderStatuses();

    if (this.$route.name == "EditBilling") {
      let billId = this.$store.state.editId;
      if (billId) {
        this.fetchBillByID(billId);
      } else {
        this.$router.push("/billing/add").catch(() => {});
        this.isPageLoaded = true;
        this.onResetPageData();
      }
      this.$refs["billDropdown"]?.focus();
      // this.$refs['clientComp'].$refs['clientName'].focus(); // remove focus from client name
    } else {
      this.isPageLoaded = true;
      this.onResetPageData();
    }
  },
  beforeDestroy() {
    this.$store.commit("setBillCancelVisible", false);
    this.$store.commit("setEditingAllowed", false);
    this.$store.commit("setMakeBillVisible", false);
    this.$store.commit("setMakePurchaseOrderVisible", false);
    this.$store.commit("setMakeCreditNoteVisible", false);
  },
  methods: {
    reverseCalculate(total, thtva) {
      this.pauseWatchForUnitPrice = true;
      if (total) {
        // x = total/quantity(1+ tva/100)
        // (unit price * quantity) * ( 1 -  red_percentage /100)( 1 + tva /100)
        this.unit_price = parseFloat(
          total /
            (this.quantity *
              (1 - this.red_percentage / 100) *
              (1 + this.first_row_tva / 100))
        );
        this.rounded_unit_price = parseFloat(
          total / (this.quantity * (1 + this.first_row_tva / 100))
        ).toFixed(this.getDecimalNumber());
        this.calculateFields(true, true, true, false);
      }
      if (thtva) {
        //  thtva = unit_price * quantity (1 - r/100 )
        this.unit_price = parseFloat(
          thtva / (this.quantity * (1 - this.red_percentage / 100))
        );
        this.rounded_unit_price = parseFloat(thtva / this.quantity).toFixed(
          this.getDecimalNumber()
        );
        this.calculateFields(true, true, false, true);
      }
    },
    getProposedArticles(
      article_id,
      family_article_id,
      family_client_id,
      quantity
    ) {
      axios
        .get(
          API_BASE_URL +
            "/articles/proposed/" +
            article_id +
            "?family_article_id=" +
            family_article_id +
            (family_client_id ? "&family_client_id=" + family_client_id : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          data.data.forEach((proposedArticle) => {
            let unit_price = proposedArticle.price ?? 0.0;
            let rounded_unit_price = proposedArticle.price ?? 0.0;
            let red_percentage = 0;
            let thtva = parseFloat(
              unit_price * quantity - (unit_price * quantity * 0.0) / 100
            );

            // check if article already exists in the datatable
            let alreadyExistsProposedArticle = this.selectedArticles.find(
              (element, index) => {
                if (
                  typeof element.article == "object" &&
                  element.article.id == proposedArticle.article.id
                ) {
                  this.selectedArticles.splice(index, 1);
                  return element;
                }
                if (
                  typeof element.article == "number" &&
                  element.article == proposedArticle.article.id
                ) {
                  this.selectedArticles.splice(index, 1);
                  return element;
                }
              }
            );
            if (alreadyExistsProposedArticle) {
              // recalculate the row total
              quantity =
                parseFloat(quantity) +
                parseFloat(alreadyExistsProposedArticle.quantity);
              unit_price = parseFloat(unit_price);
              // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
            }

            let isToMultiplyWithCredNoteMulValue = false;
            if (this.billType && this.billType.key == "credit_note") {
              isToMultiplyWithCredNoteMulValue = true;
            }

            this.selectedArticles.push({
              srno: this.rowNumber++,
              article: proposedArticle.article,
              article_original_name: proposedArticle.article,
              quantity: quantity,
              unit_price: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (unit_price ?? 0)
                  : unit_price ?? 0
              ),
              rounded_unit_price: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (rounded_unit_price ?? 0)
                  : rounded_unit_price ?? 0
              ).toFixed(this.getDecimalNumber()),
              red_percentage: red_percentage,
              tarrif: proposedArticle.article.tariff_price ?? 0,
              tva: parseFloat(this.first_row_tva),
              thtva: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (thtva ?? 0)
                  : thtva ?? 0
              ),
              stock_property: null,
              total: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (parseFloat(
                        parseFloat(thtva) +
                          parseFloat((this.first_row_tva * thtva) / 100)
                      ) ?? 0)
                  : parseFloat(
                      parseFloat(thtva) +
                        parseFloat((this.first_row_tva * thtva) / 100)
                    ) ?? 0
              ),
              text: null,
              row: 0,
              text_position: 0,
              depot_out_id: null,
              depot_in_id: null,
              dots: null,
            });
            this.resetSrNo();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showOrHidePrintReport() {
      if (this.selectedBill) {
        let found = false;
        this.selectedBill.details.forEach((order_detail) => {
          if (order_detail.depot_out_id || order_detail.depot_in_id) {
            found = true;
          }
        });
        if (found) {
          this.$store.commit("setShowPrintReportInPrintDropDown", true);
        } else {
          this.$store.commit("setShowPrintReportInPrintDropDown", false);
        }
      } else {
        this.$store.commit("setShowPrintReportInPrintDropDown", false);
      }
    },
    confirmPrintReportPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "billings.print_report_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "print_report";
      this.confirmationDialog = true;
    },
    confirmPrintLabelPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "billings.print_label_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "print_label";
      this.confirmationDialog = true;
    },
    confirmMakeBillPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "billings.convert_to_bill_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "make_bill";
      this.confirmationDialog = true;
    },
    confirmPurchaseOrderPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "billings.convert_to_purchase_order_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "make_purchase_order";
      this.confirmationDialog = true;
    },
    confirmCreditNotePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "billings.convert_to_credit_note_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "make_credit_note";
      this.confirmationDialog = true;
    },
    printReport() {
      window.open(
        API_BASE_URL +
          "/depot/" +
          this.selectedDepot.md5_id +
          "/print?document=report&lang=" +
          (localStorage.getItem("languageId") || "en") +
          "&user=" +
          localStorage.getItem("loginUserId"),
        "_blank"
      );
    },
    printLabel() {
      window.open(
        API_BASE_URL +
          "/depot/" +
          this.selectedDepot.md5_id +
          "/print?document=labels&lang=" +
          (localStorage.getItem("languageId") || "en") +
          "&user=" +
          localStorage.getItem("loginUserId"),
        "_blank"
      );
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    modifyDepotOutClose(modifyDialog, data) {
      if (data) {
        this.addDepotArticleToDataTable(data, "out");
      }

      this.depotOutMod = false;
    },
    modifyDepotInClose(modifyDialog, data) {
      this.depotInMod = false;
      if (data) {
        this.selectedDepot = data;
        this.storedDepotIn.push(this.selectedDepot.id);
        this.parent_registration_no.depot.push(data);
        this.confirmPrintLabelPopUp();
        this.addDepotArticleToDataTable(data, "in");
      }
    },
    depotIn() {
      this.selectedDepot = null;
      const clientData = this.makeNewClientData();
      const vehicleData = this.makeNewVehicleData();
      this.addClient(clientData).then(() => {
        this.createVehicle(vehicleData).then(() => {
          this.depotInMod = true;
        });
      });
    },
    depotOut() {
      const clientData = this.makeNewClientData();
      const vehicleData = this.makeNewVehicleData();
      this.addClient(clientData).then(() => {
        this.createVehicle(vehicleData).then(() => {
          this.depotOutMod = true;
        });
      });
    },
    makeNewClientData() {
      return {
        name: this.parent_company_client_name
          ? typeof this.parent_company_client_name == "object"
            ? this.parent_company_client_name.name
            : this.parent_company_client_name
          : null,
        family_client_id: this.parent_familyClient
          ? typeof this.parent_familyClient == "object"
            ? parseInt(this.parent_familyClient.id)
            : this.parent_familyClient
          : null,
        denomination_id: this.parent_denomination
          ? typeof this.parent_denomination == "object"
            ? this.parent_denomination.id
            : this.parent_denomination
          : null,
        email: this.parent_company_client_email ?? null,
        phone: this.parent_company_client_phone ?? null,
        gsm: this.parent_company_client_gsm ?? null,
        fax: this.parent_company_client_fax ?? null,
        web: this.parent_web ?? null,
        tva: this.parent_tva ?? null,
        note: this.parent_note ?? null,
      };
    },
    addClient(data) {
      return new Promise((resolve, reject) => {
        if (
          !(this.parent_company_client_name
            ? typeof this.parent_company_client_name == "object"
              ? this.parent_company_client_name.id
              : null
            : null)
        ) {
          this.showLoadingModel = true;
          axios
            .post(API_BASE_URL + "/clients", data, { headers: this.header })
            .then((response) => {
              this.parent_company_client_name = response.data.data;
              resolve();
            })
            .catch(() => {
              reject();
            })
            .finally(() => {
              this.showLoadingModel = false;
            });
        } else {
          resolve();
        }
      });
    },
    makeNewVehicleData() {
      return {
        client_id: this.parent_company_client_name
          ? typeof this.parent_company_client_name == "object"
            ? this.parent_company_client_name.id
            : null
          : null,
        vehicle_type_id: this.parent_vehicle_type
          ? typeof this.parent_vehicle_type == "object"
            ? this.parent_vehicle_type.id
            : null
          : null,
        registration_number:
          this.parent_registration_no &&
          typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.registration_number
            : this.parent_registration_no,
        brand_id: this.parent_brand ? this.parent_brand.id : null,
        model: this.parent_model ?? null,
        km: this.parent_km ?? null,
        registration_date: this.parent_reg_date ?? null,
        vin: this.parent_vin ?? null,
        is_leasing: this.parent_is_leasing
          ? this.parent_is_leasing == false
            ? 0
            : 1
          : null,
        creditor_id: this.parent_creditor
          ? typeof this.parent_creditor == "object"
            ? this.parent_creditor.id
            : this.parent_creditor
          : null,
      };
    },
    createVehicle(data) {
      data.client_id = this.parent_company_client_name
        ? typeof this.parent_company_client_name == "object"
          ? this.parent_company_client_name.id
          : null
        : null;
      return new Promise((resolve, reject) => {
        if (
          !(this.parent_registration_no &&
          typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.id
            : null)
        ) {
          this.showLoadingModel = true;
          axios
            .post(API_BASE_URL + "/client_vehicles", data, {
              headers: this.header,
            })
            .then((response) => {
              this.parent_registration_no = response.data;
              resolve();
            })
            .catch(() => {
              reject();
            })
            .finally(() => {
              this.showLoadingModel = false;
            });
        } else {
          resolve();
        }
      });
    },
    debounceInput: _.debounce(function () {}, 500),
    looseFocusFromBillBox() {
      document.getElementById("billing-form").click();
    },
    setPageTitle() {
      if (this.billType && this.billType.key == "estimation") {
        this.$route.meta.title = this.selectedBill
          ? "edit_estimation"
          : "add_estimation";
      } else if (this.billType && this.billType.key == "purchase_order") {
        this.$route.meta.title = this.selectedBill
          ? "edit_purchase_order"
          : "add_purchase_order";
      } else if (this.billType && this.billType.key == "bill") {
        this.$route.meta.title = this.selectedBill ? "edit_bill" : "add_bill";
      } else if (this.billType && this.billType.key == "credit_note") {
        this.$route.meta.title = this.selectedBill
          ? "edit_credit_note"
          : "add_credit_note";
      }
    },
    goBackToListing() {
      if (this.$store.state.editId) {
        // if the user has came from the listing page and clicks on close button then redirect him to the last page visited
        this.$router.go(-1);
      }
    },
    changeDialog() {
      this.dialog = false;
      this.$nextTick(() => this.$refs["tableQuantity"].focus());
    },
    resetSelectedArticle() {
      this.article = "";
      this.unit_price = 0.0;
      this.red_percentage = 0.0;
    },
    showDialog() {
      this.parentSearchSelectedArticleId = null;
      this.dialog = true;
    },
    getTvas() {
      return new Promise((resolve) => {
        return axios
          .get(API_BASE_URL + "/tvas?by_auth=1", { headers: this.header })
          .then(({ data }) => {
            // this.tvas.push(parseFloat(0));
            // data.forEach((item, index)  => {
            //   this.tvas.push(parseFloat(item.tax));
            // });

            // this.tvas = this.tvas.sort(function(a, b) {
            //   return b - a;
            // });
            this.first_row_tva = this.fixDecimal(data.tax);
            this.cached_first_row_tva = this.fixDecimal(data.tax);
            resolve();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    fetchBillByID(id) {
      if (id) {
        axios
          .get(API_BASE_URL + "/billings/search?id=" + id, {
            headers: this.header,
          })
          .then(({ data }) => {
            this.bills = data.data;
            this.selectedBill = this.bills[0];
            this.isPageLoaded = true;
          })
          .catch((err) => {
            console.log(err);
            this.isLoadingArticle = false;
            this.isPageLoaded = true;
            this.$router.push("/billing/add").catch(() => {});
          })
          .finally(
            () => ((this.isLoadingArticle = false), (this.isPageLoaded = true))
          );
      } else {
        this.$router.push("/billing/add").catch(() => {});
      }
    },
    checkIfFamilyClientEmpty(event) {
      if (!this.parent_familyClient) {
        this.$refs["clientComp"].$refs["family_client"].focus();
        this.$refs["clientComp"].$refs["family_client"].$touch;
        event.preventDefault();
      }
    },
    reCalculateSelectedArticlesPricing() {
      if (this.selectedArticles.length > 0) {
        let selectedIds = this.selectedArticles.map((element) => {
          if (typeof element.article == "number") {
            // saved bill case, on load
            return element.article;
          }
          else if (typeof element.article == "object" && element.article.id) {
            // saved bill case, on load
            return element.article.id;
          }
          return element.article.article_id;
        });

        this.isRecalculatingTableData = true;

        axios
          .get(
            API_BASE_URL +
              "/billings/articles/search?page=1&items_per_page=10" +
              (this.parent_familyClient
                ? "&family_client_for_pricing=" + (typeof this.parent_familyClient == 'object' ? this.parent_familyClient.id : this.parent_familyClient)
                : "") +
              (selectedIds.length > 0 ? "&article_ids=" + selectedIds : ""),
            { headers: this.header }
          )
          .then(({ data }) => {
            this.rowNumber = 0;
            let tempSelectedArticles = [];

            // mapping current selected articles with new articles and then updating the temporaray selected articles with new ... and rest of the things will be done by the watchers
            this.selectedArticles.map((articleOldData) => {
              let newMappedArticle = data.data.find((articleNewData) => {
                if (typeof articleOldData.article == "number") {
                  // saved bill case, on load
                  return articleNewData.id == articleOldData.article;
                } else {
                  return articleNewData.id == articleOldData.article.article_id;
                }
              });

              if (newMappedArticle) {
                let tariff = parseFloat(newMappedArticle.tariff_price ?? 0);
                // let unit_price = parseFloat(newMappedArticle.internal_company_article ? newMappedArticle.internal_company_article[0].price : 0); // test it again
                let unit_price = parseFloat(
                  articleOldData.internal_company_article
                    ? articleOldData.unit_price
                    : 0
                ); // test it again
                let rounded_unit_price = parseFloat(
                  articleOldData.internal_company_article
                    ? articleOldData.rounded_unit_price
                    : 0
                ); // test it again
                let tva = parseFloat(
                  newMappedArticle.internal_company_article.length > 0
                    ? newMappedArticle.internal_company_article[0].tva.tax
                    : 17
                );
                let thtva = parseFloat(
                  unit_price * articleOldData.quantity -
                    (unit_price *
                      articleOldData.quantity *
                      articleOldData.red_percentage) /
                      100
                );
                let total = parseFloat(
                  parseFloat(thtva) + parseFloat((tva * thtva) / 100)
                );
                tempSelectedArticles.push({
                  srno: this.rowNumber++,
                  article: newMappedArticle,
                  article_original_name: newMappedArticle,
                  quantity: articleOldData.quantity,
                  unit_price: unit_price ?? 0,
                  rounded_unit_price: rounded_unit_price ?? 0,
                  red_percentage: articleOldData.red_percentage ?? 0,
                  tarrif: tariff,
                  tva: tva,
                  stock_property: newMappedArticle.stock_property ?? null,
                  thtva: thtva,
                  total: total,
                  family_article: newMappedArticle.family,
                  text: newMappedArticle.text,
                  text_position: newMappedArticle.text_position ?? 0,
                  depot_out_id: newMappedArticle.depot_out_id,
                  depot_in_id: newMappedArticle.depot_in_id,
                });
              }
            });
            // this.selectedArticles = tempSelectedArticles;
          })
          .catch(() => {
            this.isRecalculatingTableData = false;
          })
          .finally((this.isRecalculatingTableData = false));
      }
    },
    checkEmptinessOfForm() {
      let isBillFieldsEmpty =
        !this.order_status &&
        !this.payment_method &&
        !this.note &&
        !this.reference_client &&
        !this.red_percentage &&
        !this.next_event_code &&
        this.selectedArticles.length == 0;

      let isClientFieldsEmpty =
        !this.parent_company_client_name &&
        !this.parent_search_client &&
        !this.parent_company_client_phone &&
        !this.parent_company_client_gsm &&
        !this.parent_company_client_email &&
        !this.parent_zipcode &&
        !this.parent_note &&
        !this.parent_tva &&
        !this.parent_address &&
        !this.parent_city &&
        !this.parent_county;

      let isVehicleFieldsEmpty =
        !this.parent_vehicle_type &&
        !this.parent_registration_no &&
        !this.parent_brand &&
        !this.parent_model &&
        !this.parent_km &&
        !this.parent_reg_date &&
        !this.parent_creditor &&
        !this.parent_vin;

      if (isBillFieldsEmpty && isClientFieldsEmpty && isVehicleFieldsEmpty) {
        this.$store.commit("setShowNewButton", true);
      } else {
        if (this.selectedBill) {
          this.$store.commit("setShowNewButton", true);
        } else {
          this.$store.commit("setShowNewButton", false);
        }
      }

      if (this.selectedBill) {
        this.$store.commit("setIsBillSelected", true);
        this.$store.commit("setSelectedBillData", this.selectedBill);
      } else {
        this.$store.commit("setIsBillSelected", false);
        this.$store.commit("setSelectedBillData", null);
      }

      // show save button if client is selected and at least one article is selected
      if (this.parent_company_client_name) {
        this.$store.commit("setShowSaveButton", true);
      } else {
        this.$store.commit("setShowSaveButton", false);
      }
    },
    jumpToField: function (event, ref) {
      this.$refs[ref].focus();
    },
    checkIfMinusPressed(event) {
      if (/^[/-]$/i.test(event.key)) {
        this.unit_price =
          this.unit_price * parseInt(this.creditNoteMultiplicationValue.value);
      }
    },
    makePurchaseOrder() {
      if (this.selectedBill.bill_number) {
        this.$store.commit("setMakePurchaseOrderVisible", false);
        axios
          .post(
            API_BASE_URL + "/billings/convert/" + this.selectedBill.id,
            { id: this.selectedBill.id },
            { headers: this.header }
          )
          .then((response) => {
            if (response.status === 200) {
              // this.onResetPageData();
              this.selectedBill = response.data.data;
              this.$toast.success(
                this.$t(
                  "billings.text_messages.converted_to_purchase_order_successfully"
                )
              );
              this.$store.commit("setMakeBillVisible", true);
              this.$store.commit("setMakePurchaseOrderVisible", false);
              this.$store.commit("setMakeCreditNoteVisible", false);
            } else {
              this.$store.commit("setMakeBillVisible", false);
              this.$store.commit("setMakePurchaseOrderVisible", true);
              this.$store.commit("setMakeCreditNoteVisible", false);
              this.$toast.error(
                this.$t(
                  "billings.text_messages.converted_to_purchase_order_failed"
                )
              );
            }
          })
          .catch(() => {
            this.$store.commit("setMakeBillVisible", false);
            this.$store.commit("setMakePurchaseOrderVisible", true);
            this.$store.commit("setMakeCreditNoteVisible", false);
            this.$toast.error(
              this.$t(
                "billings.text_messages.converted_to_purchase_order_failed"
              )
            );
          })
          .finally(() => {});
      }
    },
    makeBill() {
      if (this.selectedBill.bill_number) {
        this.$store.commit("setMakeBillVisible", false);
        axios
          .post(
            API_BASE_URL + "/billings/convert/" + this.selectedBill.id,
            { id: this.selectedBill.id },
            { headers: this.header }
          )
          .then((response) => {
            if (response.status === 200) {
              // this.onResetPageData();
              this.selectedBill = response.data.data;
              this.$toast.success(
                this.$t("billings.text_messages.converted_to_bill_successfully")
              );
              this.$store.commit("setMakeBillVisible", false);
              this.$store.commit("setMakePurchaseOrderVisible", false);
              this.$store.commit("setMakeCreditNoteVisible", true);
            } else {
              this.$store.commit("setMakeBillVisible", true);
              this.$store.commit("setMakePurchaseOrderVisible", false);
              this.$store.commit("setMakeCreditNoteVisible", false);
              this.$toast.error(
                this.$t("billings.text_messages.converted_to_bill_failed")
              );
            }
          })
          .catch(() => {
            this.$store.commit("setMakeBillVisible", true);
            this.$store.commit("setMakePurchaseOrderVisible", false);
            this.$store.commit("setMakeCreditNoteVisible", false);
            this.$toast.error(
              this.$t("billings.text_messages.converted_to_bill_failed")
            );
          })
          .finally(() => {});
      }
    },
    makeCreditNote() {
      if (this.selectedBill.bill_number) {
        this.$store.commit("setMakeCreditNoteVisible", false);
        axios
          .post(
            API_BASE_URL + "/billings/convert/" + this.selectedBill.id,
            { id: this.selectedBill.id },
            { headers: this.header }
          )
          .then((response) => {
            if (response.status === 200) {
              // this.onResetPageData();
              this.selectedBill = response.data.data;
              this.$toast.success(
                this.$t(
                  "billings.text_messages.converted_to_credit_note_successfully"
                )
              );
              this.$store.commit("setMakeBillVisible", false);
              this.$store.commit("setMakePurchaseOrderVisible", false);
              this.$store.commit("setMakeCreditNoteVisible", false);
            } else {
              this.$store.commit("setMakeBillVisible", false);
              this.$store.commit("setMakePurchaseOrderVisible", false);
              this.$store.commit("setMakeCreditNoteVisible", true);
              this.$toast.error(
                this.$t(
                  "billings.text_messages.converted_to_credit_note_failed"
                )
              );
            }
          })
          .catch(() => {
            this.$store.commit("setMakeBillVisible", false);
            this.$store.commit("setMakePurchaseOrderVisible", false);
            this.$store.commit("setMakeCreditNoteVisible", true);
            this.$toast.error(
              this.$t("billings.text_messages.converted_to_credit_note_failed")
            );
          })
          .finally(() => {});
      }
    },
    printBill() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        const data = this.makeSubmitData();
        if (!data.bill.order_id) {
          this.addBilling(data, false).then(() => {
            window.open(
              API_BASE_URL +
                "/billings/" +
                this.selectedBill.md5_id +
                "/print?document=order&lang=" +
                (localStorage.getItem("languageId") || "en") +
                "&user=" +
                localStorage.getItem("loginUserId"),
              "_blank"
            );
          });
        } else {
          this.updateBilling(data, false).then(() => {
            window.open(
              API_BASE_URL +
                "/billings/" +
                this.selectedBill.md5_id +
                "/print?document=order&lang=" +
                (localStorage.getItem("languageId") || "en") +
                "&user=" +
                localStorage.getItem("loginUserId"),
              "_blank"
            );
          });
        }
        // this.$toast.success( this.$t('billings.text_messages.credit_note_updated_successfully'));
      }
    },
    emailBill() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        const data = this.makeSubmitData();
        if (!data.bill.order_id) {
          this.addBilling(data, false).then(() => {
            this.sendEmail();
          });
        } else {
          this.updateBilling(data, false).then(() => {
            this.sendEmail();
          });
        }
      }
    },
    worksheetBill() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        const data = this.makeSubmitData();
        if (!data.bill.order_id) {
          this.addBilling(data, false).then(() => {
            window.open(
              API_BASE_URL +
                "/billings/" +
                this.selectedBill.md5_id +
                "/print?document=worksheet&lang=" +
                (localStorage.getItem("languageId") || "en") +
                "&user=" +
                localStorage.getItem("loginUserId"),
              "_blank"
            );
          });
        } else {
          this.updateBilling(data, false).then(() => {
            window.open(
              API_BASE_URL +
                "/billings/" +
                this.selectedBill.md5_id +
                "/print?document=worksheet&lang=" +
                (localStorage.getItem("languageId") || "en") +
                "&user=" +
                localStorage.getItem("loginUserId"),
              "_blank"
            );
          });
        }
      }
    },
    printReportFromTopButton() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        const data = this.makeSubmitData();
        if (!data.bill.order_id) {
          this.addBilling(data, false).then(() => {
            let depot_ids = new Set();
            this.selectedArticles.forEach((article) => {
              if (article.depot_in_id) {
                depot_ids.add(article.depot_in_id);
              } else if (article.depot_out_id) {
                depot_ids.add(article.depot_out_id);
              }
            });
            Array.from(depot_ids).forEach((element) => {
              if (element) {
                window.open(
                  API_BASE_URL +
                    "/depot/" +
                    this.encryptToMD5(element.toString()) +
                    "/print?document=report&lang=" +
                    (localStorage.getItem("languageId") || "en") +
                    "&user=" +
                    localStorage.getItem("loginUserId"),
                  "_blank"
                );
              }
            });
          });
        } else {
          this.updateBilling(data, false).then(() => {
            let depot_ids = new Set();
            this.selectedArticles.forEach((article) => {
              if (article.depot_in_id) {
                depot_ids.add(article.depot_in_id);
              } else if (article.depot_out_id) {
                depot_ids.add(article.depot_out_id);
              }
            });
            Array.from(depot_ids).forEach((element) => {
              if (element) {
                window.open(
                  API_BASE_URL +
                    "/depot/" +
                    this.encryptToMD5(element.toString()) +
                    "/print?document=report&lang=" +
                    (localStorage.getItem("languageId") || "en") +
                    "&user=" +
                    localStorage.getItem("loginUserId"),
                  "_blank"
                );
              }
            });
          });
        }
      }
    },
    sendEmail() {
      axios
        .get(API_BASE_URL + "/billings/" + this.selectedBill.id + "/email", {
          headers: this.header,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(
              this.$t("billings.text_messages.email_sent_successfully")
            );
          } else {
            this.$toast.error(
              this.$t("billings.text_messages.failed_while_sending")
            );
          }
        })
        .catch(() => {
          this.$toast.error(
            this.$t("billings.text_messages.failed_while_sending")
          );
        })
        .finally(() => {});
    },
    onResetPageData() {
      this.resetBillType();
      this.$store.commit("setValidation", false);
      this.$refs["clientComp"].$refs["clientName"].focus();
      this.order_status = null;
      this.payment_method = null;
      this.selectedBill = null;
      this.$store.commit("setBillCancelVisible", false);
      this.$store.commit("setEditingAllowed", false);
      this.selectedArticles = [];
      this.bill_amount = "";
      this.bills = [];
      this.rdv_date = null;
      this.due_date = this.getDueDate;
      this.parent_reg_date = null;
      this.note = null;
      this.reference_client = null;
      this.unit_price = null;
      this.quantity = this.selectedFamilyArticle
        ? this.selectedFamilyArticle.default_quantity
        : 4;
      this.first_row_tariff = null;
      this.first_row_thtva = null;
      this.first_row_tva = this.cached_first_row_tva; // Reset TVA to company's Default TVA
      this.first_row_total = null;
      this.red_percentage = 0.0;
      this.unit_price = 0.0;
      this.next_event_code = null;
      this.finedSelectedArticles = [];
      this.articles = [];
      this.search_article = null;

      // Vehicle Component Sync Values
      this.parent_vehicle_type = null;
      this.parent_registration_no = null;
      this.parent_brand = null;
      this.parent_model = null;
      (this.parent_km = null), (this.parent_reg_date = null);
      this.parent_creditor = null;
      this.parent_vin = null;
      this.parent_is_leasing = null;

      // Client Component Sync Values
      this.parent_client_id = null;
      this.parent_company = null;
      this.parent_client_id = null;
      this.parent_company_client_name = null;
      this.parent_company_client_names = [];
      this.parent_search_client = null;
      this.parent_company_client_phone = null;
      this.parent_company_client_gsm = null;
      this.parent_company_client_fax = null;
      this.parent_company_client_email = null;
      this.parent_zipcode = null;
      this.parent_web = null;
      this.parent_currency = null;
      this.parent_representative_company_id = null;
      this.parent_user = null;
      this.parent_note = null;
      this.parent_familyClient = null;
      this.parent_denomination = null;
      this.parent_registration_code = null;
      this.parent_tva = null;
      this.parent_address = null;
      this.parent_city = null;
      this.parent_county = null;
      this.parent_country = null;
      this.parent_shipping_address = null;
      this.parent_shipping_city = null;
      this.parent_shipping_county = null;
      this.parent_shipping_country = null;
      this.parent_shipping_zipcode = null;
      this.parent_address_same_as = false;

      this.storedDepotIn = [];

      this.$store.commit("setShowNewButton", true);
      this.setPageTitle();
    },
    convertCommaToDecimalSeparator(str) {
      return parseFloat((str + "").replace(",", ".").replace(" ", ""));
    },
    addDepotArticleToDataTable(depot, depotType) {
      depot.details.forEach((depotDetail) => {
        let text = "";
        text +=
          (depotType == "out"
            ? this.$t("billings.text.depot_out")
            : this.$t("billings.text.depot_in")) +
          ": " +
          depotDetail.article.title_description[0].description +
          (depotDetail.position
            ? " - " +
              this.$t("billings.position.sh_" + depotDetail.position.key)
            : "") +
          (depotDetail.rimtype
            ? " - " +
              this.$t("billings.wheel_materials.sh_" + depotDetail.rimtype.key)
            : "") +
          (depotDetail.is_with_hubcaps == 1
            ? " - " + this.$t("billings.text.with_hub_cups")
            : "") +
          (depotDetail.mm
            ? " - " + this.$t("billings.text.mm") + ": " + depotDetail.mm
            : "") +
          (depotDetail.couple
            ? " - " +
              this.$t("billings.text.couple") +
              ": " +
              depotDetail.couple
            : "") +
          (depotDetail.pressure
            ? " - " +
              this.$t("billings.text.pressure") +
              ": " +
              depotDetail.pressure
            : "") +
          (depotDetail.article_status
            ? " - " +
              this.$t("billings.text.status") +
              ": " +
              this.$t(
                "billings.article_status.sh_" + depotDetail.article_status.key
              )
            : "");

        if (depotType == "out" && depotDetail.checked) {
          this.selectedArticles.push({
            srno: this.rowNumber++,
            article: 0,
            article_original_name: null,
            quantity: 1,
            unit_price: 0.0,
            rounded_unit_price: (0).toFixed(this.getDecimalNumber()),
            red_percentage: 0.0,
            tarrif: 0.0,
            tva: 0.0,
            thtva: 0.0,
            stock_property: null,
            total: 0.0,
            text: text,
            row: 0,
            text_position: 0,
            depot_out_id: depot.id,
            depot_in_id: null,
            dots: null,
          });
        } else if (depotType == "in") {
          this.selectedArticles.push({
            srno: this.rowNumber++,
            article: 0,
            article_original_name: null,
            quantity: 1,
            unit_price: 0.0,
            rounded_unit_price: (0).toFixed(this.getDecimalNumber()),
            red_percentage: 0.0,
            tarrif: 0.0,
            tva: 0.0,
            thtva: 0.0,
            stock_property: null,
            total: 0.0,
            text: text,
            row: 0,
            text_position: 0,
            depot_out_id: null,
            depot_in_id: depot.id,
            dots: null,
          });
        }
      });
    },
    addArticleToDataTable: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (!(this.article && this.quantity && this.red_percentage <= 100)) {
        return false;
      }
      // resetting the article id in local storage
      let articleFilters = JSON.parse(
        localStorage.getItem("ARTICLE_MODEL_FILTERS")
      );
      if (articleFilters) {
        articleFilters["id"] = null;
      }
      localStorage.setItem(
        "ARTICLE_MODEL_FILTERS",
        JSON.stringify(articleFilters)
      );

      this.unit_price = parseFloat(
        this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
      );
      this.rounded_unit_price = parseFloat(
        this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
      ).toFixed(this.getDecimalNumber());
      this.red_percentage = parseFloat(this.red_percentage);
      // Add Related Articles
      this.getProposedArticles(
        this.article.id,
        this.article.family_id,
        this.parent_familyClient,
        parseFloat(this.quantity)
      );

      // check if article already exists in the datatable
      let alreadyExistsArticle = this.selectedArticles.find(
        (element, index) => {
          if (
            typeof element.article == "object" &&
            element.article.id == this.article.id
          ) {
            this.selectedArticles.splice(index, 1);
            return element;
          }
          if (
            typeof element.article == "number" &&
            element.article == this.article.id
          ) {
            this.selectedArticles.splice(index, 1);
            return element;
          }
        }
      );
      if (alreadyExistsArticle) {
        // recalculate the row total
        this.quantity =
          parseFloat(this.quantity) + parseFloat(alreadyExistsArticle.quantity);
        this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
        this.unit_price = parseFloat(this.unit_price);
        // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
        this.first_row_thtva = parseFloat(
          this.unit_price * this.quantity -
            (this.unit_price * this.quantity * this.red_percentage) / 100
        );
        this.first_row_total = parseFloat(
          parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva * this.first_row_thtva) / 100)
        );
      }
      let isToMultiplyWithCredNoteMulValue = false;
      if (this.billType && this.billType.key == "credit_note") {
        isToMultiplyWithCredNoteMulValue = true;
      }

      this.selectedArticles.push({
        srno: this.rowNumber++,
        article: this.article,
        article_original_name: this.article,
        quantity: this.quantity,
        unit_price: parseFloat(
          isToMultiplyWithCredNoteMulValue
            ? parseInt(this.creditNoteMultiplicationValue.value) *
                (this.unit_price ?? 0)
            : this.unit_price ?? 0
        ),
        rounded_unit_price: parseFloat(
          isToMultiplyWithCredNoteMulValue
            ? parseInt(this.creditNoteMultiplicationValue.value) *
                (this.unit_price ?? 0)
            : this.unit_price ?? 0
        ).toFixed(this.getDecimalNumber()),
        red_percentage: parseFloat(this.red_percentage ?? 0.0),
        tarrif: this.first_row_tariff,
        tva: parseFloat(this.first_row_tva),
        thtva: parseFloat(
          isToMultiplyWithCredNoteMulValue
            ? parseInt(this.creditNoteMultiplicationValue.value) *
                (this.first_row_thtva ?? 0)
            : this.first_row_thtva ?? 0
        ),
        stock_property: null,
        total: parseFloat(
          isToMultiplyWithCredNoteMulValue
            ? parseInt(this.creditNoteMultiplicationValue.value) *
                (this.first_row_total ?? 0)
            : this.first_row_total ?? 0
        ),
        text: null,
        row: 0,
        text_position: 0,
        depot_out_id: null,
        depot_in_id: null,
        dots: null,
      });
      this.resetFirstRowValues();
      this.resetSrNo();
    },
    resetSrNo() {
      let sno = 0;
      this.selectedArticles.forEach((element) => {
        element.srno = sno;
        sno++;
      });
      this.rowNumber = sno;
    },
    resetFirstRowValues() {
      this.article = "";
      this.quantity = this.selectedFamilyArticle
        ? this.selectedFamilyArticle.default_quantity
        : 4;
      this.red_percentage = 0.0;
      this.unit_price = 0.0;
      this.first_row_tariff = "";
      this.first_row_thtva = "";
      this.first_row_total = "";
    },
    deleteItem(item) {
      this.selectedArticles.find((element, index) => {
        if (element.srno == item.srno) {
          this.selectedArticles.splice(index, 1);
          this.resetSrNo();
          return true;
        }
      });
    },
    onDelete() {
      this.DeleteConfirm = true;
    },
    makeClientData() {
      return {
        client_id: this.parent_company_client_name
          ? typeof this.parent_company_client_name == "object"
            ? this.parent_company_client_name.id
            : null
          : null,
        entityType: this.parent_entityType ? this.parent_entityType.id : 0, // By Default Private Client
        denomination: this.parent_denomination ?? null,
        familyClient: this.parent_familyClient
          ? typeof this.parent_familyClient == "object"
            ? parseInt(this.parent_familyClient.id)
            : this.parent_familyClient
          : null,

        name: this.parent_company_client_name
          ? typeof this.parent_company_client_name == "object"
            ? this.parent_company_client_name.name
            : this.parent_company_client_name
          : null,
        email: this.parent_company_client_email ?? null,
        fax: this.parent_company_client_fax ?? null,
        gsm: this.parent_company_client_gsm ?? null,
        phone: this.parent_company_client_phone ?? null,
        note: this.parent_note ?? null,
        tva: this.parent_tva ?? null,
        web: this.parent_web ?? null,
      };
    },
    makeVehicleData() {
      return {
        vehicle_id:
          this.parent_registration_no &&
          typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.id
            : null,
        vehicle_type_id:
          this.parent_vehicle_type &&
          typeof this.parent_vehicle_type == "object"
            ? this.parent_vehicle_type.id
            : null,
        registration_no:
          this.parent_registration_no &&
          typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.registration_number
            : this.parent_registration_no,
        brand: this.parent_brand
          ? typeof this.parent_brand == "number"
            ? this.parent_brand
            : typeof this.parent_brand == "object"
            ? this.parent_brand.id
            : null
          : null,
        model: this.parent_model ?? null,
        km: this.parent_km ?? null,
        reg_date: this.parent_reg_date ?? null,
        vin: this.parent_vin ?? null,
        is_leasing: this.parent_is_leasing ?? null,
        creditor_id: this.parent_creditor
          ? typeof this.parent_creditor == "object"
            ? this.parent_creditor.id
            : this.parent_creditor
          : null,
      };
    },
    convertDateTimeToString(datetime) {
      if (datetime) {
        datetime = new Date(datetime);
        return (
          datetime.toISOString().substring(0, 10) +
          " " +
          ("0" + datetime.getHours()).slice(-2) +
          ":" +
          ("0" + datetime.getMinutes()).slice(-2)
        );
      } else {
        return null;
      }
    },
    makeSubmitData() {
      this.fineDataForSending();
      return {
        type: this.billType ? this.billType.id : null,
        bill: !this.selectedBill
          ? {}
          : {
              order_id: this.selectedBill.bill_number
                ? this.selectedBill.id
                : null,
              client_id: this.selectedBill ? this.selectedBill.client_id : null,
              company_id: this.selectedBill
                ? this.selectedBill.company_id
                : null,
              vehicle_id: this.selectedBill
                ? this.selectedBill.vehicle_id
                : null,
            },
        next_event_code: this.next_event_code ? this.next_event_code.id : null,
        status: this.order_status ? this.order_status.id : null,
        payment_method: this.payment_method ? this.payment_method.id : null,
        rdv_date: this.convertDateTimeToString(this.rdv_date),
        due_date: this.due_date,
        reference_client: this.reference_client,
        note: this.note,
        client: this.makeClientData(),
        billing_address: {
          zipcode: this.parent_zipcode ?? null,
          city: this.parent_city
            ? typeof this.parent_city == "object"
              ? parseInt(this.parent_city.id)
              : this.parent_city
            : null,
          county: this.parent_county
            ? typeof this.parent_county == "object"
              ? parseInt(this.parent_county.id)
              : this.parent_county
            : null,
          country: this.parent_country
            ? typeof this.parent_country == "object"
              ? this.parent_country.id
              : this.parent_country
            : null,
          address: this.parent_address
            ? typeof this.parent_address == "object"
              ? parseInt(this.parent_address.id)
              : this.parent_address
            : null,
        },
        shipping_address: {
          zipcode: this.parent_shipping_zipcode ?? null,
          city: this.parent_shipping_city
            ? typeof this.parent_shipping_city == "object"
              ? parseInt(this.parent_shipping_city.id)
              : this.parent_shipping_city
            : null,
          county: this.parent_shipping_county
            ? typeof this.parent_shipping_county == "object"
              ? parseInt(this.parent_shipping_county.id)
              : this.parent_shipping_county
            : null,
          country: this.parent_shipping_country
            ? typeof this.parent_shipping_country == "object"
              ? this.parent_shipping_country.id
              : this.parent_shipping_country
            : null,
          address: this.parent_shipping_address
            ? typeof this.parent_shipping_address == "object"
              ? parseInt(this.parent_shipping_address.id)
              : this.parent_shipping_address
            : null,
        },
        vehicle: this.makeVehicleData(),
        article: this.finedSelectedArticles,
        depot_ins: this.storedDepotIn,
      };
    },
    validateSubmit() {
      // let quantityValidation = false;
      // this.selectedArticles.forEach((element) => {
      //   if(element.article.stock < element.quantity){
      //       quantityValidation = true;
      //   }
      // })

      if (
        !this.billType ||
        !this.parent_company_client_name ||
        this.selectedArticles.length <= 0
      ) {
        this.selectedArticles.length <= 0
          ? this.$toast.error(
              this.$t(
                "billings.text_messages.please_select_at_least_one_article"
              )
            )
          : "";
        !this.billType
          ? this.$toast.error(
              this.$t("billings.text_messages.please_select_bill_type")
            )
          : "";
        !this.parent_company_client_name
          ? this.$toast.error(
              this.$t("billings.text_messages.please_select_client_name")
            )
          : "";
        return false;
      }

      // if (quantityValidation || this.$v.invalid ||  this.$refs['clientComp'].$v.invalid) {
      //   quantityValidation ? this.$toast.warning("Please verify the stock of the articles selected!")  : '';
      //   this.$v.invalid || this.$refs['clientComp'].$v.invalid ? this.$toast.warning(this.$t('please_fill_form_correctly'))  : '';

      // }
      if (
        this.$v.$invalid ||
        (this.checkTag("clients")
          ? this.$refs["clientComp"].$v.$invalid
          : false) ||
        (this.checkTag("vehicles") && this.isVehicleModuleEnabled
          ? this.$refs["vehicleComp"].$v.$invalid
          : false)
      ) {
        this.$v.$invalid ||
        (this.checkTag("clients")
          ? this.$refs["clientComp"].$v.$invalid
          : false) ||
        (this.checkTag("vehicles") && this.isVehicleModuleEnabled
          ? this.$refs["vehicleComp"].$v.$invalid
          : false)
          ? this.$toast.warning(this.$t("please_fill_form_correctly"))
          : "";
        return false;
      }

      return true;
    },
    submit() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        const data = this.makeSubmitData();
        if (!data.bill.order_id) {
          this.addBilling(data, true);
        } else {
          this.updateBilling(data, true);
        }
      }
    },
    // getNextEventsCodes () {
    //   axios.get(API_BASE_URL + '/codes?class_key=order_next_event', {headers: this.header})
    //     .then(({ data }) => {
    //       this.next_event_codes = this.translateKeys(data.data, 'key', 'billings.next_events');
    //       this.next_event_code = this.next_event_codes[0];
    //     })
    //     .catch(function (error) {
    //       console.log('an error occured ' + error);
    //     });
    // },
    async getPaymentMethods() {
      await this.getCodes("payment_types", "billings.payment_methods").then(
        (data) => {
          this.payment_methods = data;
        }
      );
    },
    getCreditors() {
      axios
        .get(API_BASE_URL + "/creditors", { headers: this.header })
        .then(({ data }) => {
          this.creditors = data.data.map((element) => {
            if (element.creditor_company) {
              element.creditor_company["is_leaser"] = element.is_leaser;
              return element.creditor_company;
            }
          });
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        });
    },
    async getOrderStatuses() {
      await this.getCodes("order_status", "billings.statuses").then((data) => {
        this.allStatuses = data;
      });
    },
    filterOutStatuses() {
      if (this.billType && this.billType.key == "estimation") {
        this.billstatuses = this.allStatuses.filter((element) => {
          if (
            this.statusFilters.estimation.find((el) => {
              return element.key == el;
            })
          ) {
            return element;
          }
        });
      } else if (this.billType && this.billType.key == "purchase_order") {
        this.billstatuses = this.allStatuses.filter((element) => {
          if (
            this.statusFilters.purchase_order.find((el) => {
              return element.key == el;
            })
          ) {
            return element;
          }
        });
      } else if (this.billType && this.billType.key == "bill") {
        this.billstatuses = this.allStatuses.filter((element) => {
          if (
            this.statusFilters.bill.find((el) => {
              return element.key == el;
            })
          ) {
            return element;
          }
        });
      } else if (this.billType && this.billType.key == "credit_note") {
        this.billstatuses = this.allStatuses.filter((element) => {
          if (
            this.statusFilters.credit_note.find((el) => {
              return element.key == el;
            })
          ) {
            return element;
          }
        });
      } else {
        return this.allStatuses;
      }
    },
    setCurrentFormOperation() {
      this.operation = this.$route.path.indexOf("/add") > 0 ? "add" : "edit";
    },
    addBilling(data, showMessage = true) {
      return new Promise((resolve, reject) => {
        axios
          .post(API_BASE_URL + "/billings", data, { headers: this.header })
          .then((response) => {
            this.selectedBill = response.data.data;
            if (response.status === 200) {
              if (showMessage) {
                if (data.type == 10241000) {
                  this.$toast.success(
                    this.$t("billings.text_messages.estimation_created_success")
                  );
                } else if (data.type == 10241001) {
                  this.$toast.success(
                    this.$t(
                      "billings.text_messages.purchase_order_created_successfully"
                    )
                  );
                } else if (data.type == 10241002) {
                  this.$toast.success(
                    this.$t("billings.text_messages.bill_created_successfully")
                  );
                } else if (data.type == 10241003) {
                  this.$toast.success(
                    this.$t(
                      "billings.text_messages.credit_note_created_successfully"
                    )
                  );
                }
              }
              resolve();
              // this.onResetPageData();
            }
          })
          .catch(() => {
            reject();
          })
          .finally(() => {});
      });
    },
    updateBilling(data, showMessage = true) {
      if (data.bill.order_id) {
        return new Promise((resolve, reject) => {
          axios
            .put(API_BASE_URL + "/billings/" + data.bill.order_id, data, {
              headers: this.header,
            })
            .then((response) => {
              this.selectedBill = response.data.data;
              if (response.status === 200) {
                if (showMessage) {
                  if (data.type == 10241000) {
                    this.$toast.success(
                      this.$t(
                        "billings.text_messages.estimation_updated_success"
                      )
                    );
                  } else if (data.type == 10241001) {
                    this.$toast.success(
                      this.$t(
                        "billings.text_messages.purchase_order_updated_successfully"
                      )
                    );
                  } else if (data.type == 10241002) {
                    this.$toast.success(
                      this.$t(
                        "billings.text_messages.bill_updated_successfully"
                      )
                    );
                  } else if (data.type == 10241003) {
                    this.$toast.success(
                      this.$t(
                        "billings.text_messages.credit_note_updated_successfully"
                      )
                    );
                  }
                }
                resolve();
                // this.onResetPageData();
              } else {
                this.$toast.error(response);
              }
            })
            .catch(() => {
              reject();
            })
            .finally(() => {});
        });
      }
    },
    updatePagination() {
      // this.page = page;
      // this.fetchData();
    },
    updateItemsPerPage() {
      // this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
      // this.fetchData();
      // this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
    },
    clearArticleList() {
      this.articles = [];
    },
    fetchArticleListDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchArticlesList();
      }, 500); /* 500ms throttle */
    },
    fetchArticlesList() {
      if (!this.search || typeof this.search == "object") {
        return;
      }
      this.clearArticleList();
      axios
        .get(
          API_BASE_URL +
            "/billings/articles/search?page=1&items_per_page=10" +
            (this.parent_familyClient
                ? "&family_client_for_pricing=" + (typeof this.parent_familyClient == 'object' ? this.parent_familyClient.id : this.parent_familyClient)
              : "") +
            (this.search ? "&search=" + encodeURIComponent(this.search) : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          if (data.data.length == 0) {
            this.$toast.error(
              this.$t("scheduler.appointment.no_pricing_found_for_this_article")
            );
          }
          this.articles = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingArticle = false;
        })
        .finally(() => (this.isLoadingArticle = false));
    },
    fetchBillSuggesListDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchBillSuggesList();
      }, 500); /* 500ms throttle */
    },
    fetchBillSuggesList() {
      if (!this.search_bill || typeof this.search_bill == "object") {
        return;
      }
      // this.clearArticleList()
      axios
        .get(
          API_BASE_URL +
            "/billings/search?page=1&items_per_page=20" +
            (this.search_bill
              ? "&search=" + encodeURIComponent(this.search_bill)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.bills = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchRegiClient = false;
        })
        .finally(() => (this.isLoadingSearchRegiClient = false));
    },
    calculateFields(UpdateQuantity, updateUnitPrice, updateTHTVA, updateTotal) {
      // this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
      // this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
      // this.unit_price = "";
      if (UpdateQuantity) {
        // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
      }
      if (updateUnitPrice) {
        // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
      }
      if (updateTHTVA) {
        this.first_row_thtva = parseFloat(
          this.unit_price * this.quantity -
            (this.unit_price * this.quantity * this.red_percentage) / 100
        );
      }
      if (updateTotal) {
        this.first_row_total = parseFloat(
          parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva * this.first_row_thtva) / 100)
        );
      }
    },
    calculateColumns(changeUnitPrice) {
      if (this.article) {
        this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
        this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
        this.unit_price = parseFloat(
          changeUnitPrice
            ? this.unit_price
            : this.article.internal_company_article
            ? this.article.internal_company_article.length > 0
              ? this.article.internal_company_article[0].price
              : 0
            : 0.0
        );
        (this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
          this.getDecimalNumber()
        )),
          // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
          (this.first_row_thtva = parseFloat(
            this.unit_price * this.quantity -
              (this.unit_price * this.quantity * this.red_percentage) / 100
          ));
        this.first_row_total = parseFloat(
          parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva * this.first_row_thtva) / 100)
        );
      }
    },
    calculateTotals() {
      let thtva_total = 0;
      let total_total = 0;
      this.selectedArticles.forEach((element) => {
        thtva_total += parseFloat(element.thtva);
        total_total += parseFloat(element.total);
      });
      this.articleTHTVATotal = parseFloat(thtva_total);
      this.articleTotal = parseFloat(total_total);
    },
    fineDataForSending() {
      this.finedSelectedArticles = [];
      this.selectedArticles.forEach((element) => {
        this.finedSelectedArticles.push({
          article_id: element.text
            ? 0
            : typeof element.article == "object"
            ? element.article.id
            : element.article, // If element has text property then we will send the article id as 0
          // company_id: element.article.company_id,
          // supplier_id: element.article.supplier_id,
          quantity: element.quantity,
          red_percentage: parseFloat(element.red_percentage ?? 0),
          tarrif: parseFloat(element.tarrif),
          thtva: parseFloat(element.thtva),
          total: parseFloat(element.total),
          tva: parseFloat(element.tva),
          stock_property: element.stock_property
            ? typeof element.stock_property == "object"
              ? element.stock_property.property
              : element.stock_property
            : null,
          unit_price: parseFloat(element.unit_price),
          rounded_unit_price: parseFloat(element.rounded_unit_price),
          text: element.text,
          row: element.row,
          text_position: element.text_position ?? 0,
          depot_out_id: element.depot_out_id,
          depot_in_id: element.depot_in_id,
        });
      });
    },
    fetchClientBalance(client_id) {
      axios
        .get(
          API_BASE_URL +
            "/clients/" +
            client_id +
            "/pending/bill?return_total=1",
          { headers: this.header }
        )
        .then(({ data }) => {
          this.customer_balance = this.fixDecimal(data.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    getArticleDots(selectedArticleIndex, article_id, supplier_id) {
      if (typeof article_id == "object") {
        article_id = article_id.id;
      }
      axios
        .get(
          API_BASE_URL +
            "/stock_property/" +
            article_id +
            "?property_class_id=dot_property" +
            "&supplier_id=" +
            supplier_id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          }
        )
        .then((response) => {
          this.selectedArticles[selectedArticleIndex].dots =
            response.data.data ?? [];
          if (!this.selectedArticles[selectedArticleIndex].stock_property) {
            this.selectedArticles[selectedArticleIndex].stock_property =
              response.data.data ? response.data.data[0] : null;
          }
        })
        .catch(function (error) {
          console.log("an error occured " + error);
          this.selectedArticles[selectedArticleIndex].dots = [];
        })
        .finally(() => {});
    },
  },
  computed: {
    vehicleDriverDetails() {
      let data = {
        client: this.makeClientData(),
        vehicle: this.makeVehicleData(),
        depot: this.parent_registration_no
          ? typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.depot
            : null
          : null,
      };

      return data;
    },
    companyNameLabel() {
      return this.isCompanyEntitySelected ? "Company Name" : "Full Name";
    },
    articles_list() {
      return this.articles;
    },
    billType() {
      return this.$store.state.billType;
    },
    isEditingAllowed() {
      return this.$store.state.isEditingAllowed;
    },
  },
  watch: {
    currencySymbol() {
      return this.userCurrencySymbol;
    },
    selectedFamilyArticle() {
      this.quantity = this.selectedFamilyArticle
        ? this.selectedFamilyArticle.default_quantity
        : 4;
      this.red_percentage = 0.0;
      this.unit_price = 0.0;
      this.first_row_tariff = "";
      this.first_row_thtva = "";
      this.first_row_total = "";
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "print_report" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.printReport();
        }
        if (
          this.confirmationDialogOperation == "print_label" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.printLabel();
        }
        if (
          this.confirmationDialogOperation == "make_bill" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.makeBill();
        }
        if (
          this.confirmationDialogOperation == "make_purchase_order" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.makePurchaseOrder();
        }
        if (
          this.confirmationDialogOperation == "make_credit_note" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.makeCreditNote();
        }

        this.confirmationDialogButtonClickedIs = null;
      }
    },
    vehicleDriverDetails(val) {
      if (val.client.client_id && val.vehicle.vehicle_id) {
        if (
          typeof this.parent_registration_no == "object" &&
          this.parent_registration_no.depot &&
          this.parent_registration_no.depot.length > 0
        ) {
          let depotOutAlreadyExists = false;
          this.selectedArticles.forEach((article) => {
            if (article.depot_out_id) {
              depotOutAlreadyExists = true;
            }
          });
          if (depotOutAlreadyExists) {
            this.$store.commit("setShowDepotButtons", 4); // Enable Depot In But Disable Depot Out, as their is already a depot out for the bill
          } else {
            this.$store.commit("setShowDepotButtons", 3); // Enable Both Depot In Depot Out
          }
        } else {
          this.$store.commit("setShowDepotButtons", 1); // Enable Just Depot In
        }
      } else if (
        (val.client.name || val.client.client_id) &&
        val.vehicle.registration_no &&
        !val.vehicle.vehicle_id
      ) {
        this.$store.commit("setShowDepotButtons", 1); // Enable Just Depot In
      } else {
        this.$store.commit("setShowDepotButtons", 0); // Dont show any button
      }
    },
    parentSearchSelectedArticleId(val) {
      if (val) {
        this.dialog = false;

        axios
          .get(
            API_BASE_URL +
              "/billings/articles/search?page=1&items_per_page=10" +
              (this.parent_familyClient
                ? "&family_client_for_pricing=" + (typeof this.parent_familyClient == 'object' ? this.parent_familyClient.id : this.parent_familyClient)
                : "") +
              ("&article_ids=" + val),
            { headers: this.header }
          )
          .then(({ data }) => {
            if (data.data.length == 0) {
              this.$toast.error(
                this.$t(
                  "scheduler.appointment.no_pricing_found_for_this_article"
                )
              );
            }
            this.articles = [data.data[0]];
            this.article = data.data[0];
            this.isLoadingArticle = false;

            let articleFilters = JSON.parse(
              localStorage.getItem("ARTICLE_MODEL_FILTERS")
            );
            articleFilters["id"] = val;
            localStorage.setItem(
              "ARTICLE_MODEL_FILTERS",
              JSON.stringify(articleFilters)
            );
          })
          .catch(() => {})
          .finally(() => {
            this.$refs["tableQuantity"].focus();
            // this.parentSearchSelectedArticleId = null;
          });
      } else {
        this.articles = [];
        this.article = "";
        this.unit_price = 0.0;
        this.red_percentage = 0.0;
      }
    },
    payment_method() {
      this.checkEmptinessOfForm();
    },
    note() {
      this.checkEmptinessOfForm();
    },
    reference_client() {
      this.checkEmptinessOfForm();
    },
    first_row_tariff() {
      this.checkEmptinessOfForm();
    },
    first_row_tva() {
      this.calculateColumns(true);
      this.checkEmptinessOfForm();
    },
    first_row_thtva() {
      this.checkEmptinessOfForm();
    },
    first_row_total() {
      this.checkEmptinessOfForm();
    },
    next_event_code() {
      this.checkEmptinessOfForm();
    },
    parent_search_client() {
      this.checkEmptinessOfForm();
    },
    parent_company_client_phone() {
      this.checkEmptinessOfForm();
    },
    parent_company_client_gsm() {
      this.checkEmptinessOfForm();
    },
    parent_company_client_email() {
      this.checkEmptinessOfForm();
    },
    parent_zipcode() {
      this.checkEmptinessOfForm();
    },
    parent_note() {
      this.checkEmptinessOfForm();
    },
    parent_denomination() {
      this.checkEmptinessOfForm();
    },
    parent_tva() {
      this.checkEmptinessOfForm();
    },
    parent_address() {
      this.checkEmptinessOfForm();
    },
    parent_city() {
      this.checkEmptinessOfForm();
    },
    parent_county() {
      this.checkEmptinessOfForm();
    },
    parent_country() {
      this.checkEmptinessOfForm();
    },
    parent_creditor() {
      this.checkEmptinessOfForm();
    },
    parent_reg_date() {
      this.checkEmptinessOfForm();
    },
    parent_model() {
      this.checkEmptinessOfForm();
    },
    parent_km() {
      this.checkEmptinessOfForm();
    },
    parent_brand() {
      this.checkEmptinessOfForm();
    },
    parent_registration_no() {
      this.checkEmptinessOfForm();
    },
    parent_vehicle_type() {
      this.checkEmptinessOfForm();
    },
    parent_vin() {
      this.checkEmptinessOfForm();
    },

    due_date(val) {
      if (val && val.length > 10) {
        this.due_date = val.substring(0, 10);
      }
    },

    parent_familyClient(val) {
      if (val) {
        this.reCalculateSelectedArticlesPricing();
      }
      this.checkEmptinessOfForm();
    },
    parent_company_client_name(val) {
      this.customer_balance = this.fixDecimal(0);
      this.checkEmptinessOfForm();
      if (this.selectedBill && this.selectedBill.vehicle) {
        let billVehicle = val.vehicles.find((vehicle) => {
          return vehicle.id == this.selectedBill.vehicle.id;
        });
        if (billVehicle) {
          val.vehicles.unshift(billVehicle);
        }
        this.parent_company_client_name = val;
      }
      if (val && val.id) {
        this.fetchClientBalance(val.id);
      }
    },
    billType() {
      this.checkEmptinessOfForm();
      // this.statuses = [];
      // this.order_status = null;
      this.filterOutStatuses();
      //   if(val && val.key && val.key == 'purchase_order'){
      //     this.isVisibleOrderStatus = false;
      //   }
      //   else{
      this.isVisibleOrderStatus = true;
      //   }
      this.setPageTitle();
    },
    ispayment_method() {
      // this.payment_method = null;
    },
    order_status(val) {
      this.checkEmptinessOfForm();
      this.ispayment_method = false;
      if (val && val.key == "payed") {
        this.ispayment_method = true;
      }
    },
    selectedArticles() {
      this.checkEmptinessOfForm();
      this.calculateTotals();
      this.fineDataForSending();

      // Enable or disable depot buttons based upon depot out
      let depotOutAlreadyExists = false;
      this.selectedArticles.forEach((article) => {
        if (article.depot_out_id) {
          depotOutAlreadyExists = true;
        }
      });
      if (
        this.$store.state.showDepotButtons == 3 ||
        this.$store.state.showDepotButtons == 4
      ) {
        if (depotOutAlreadyExists) {
          this.$store.commit("setShowDepotButtons", 4); // Enable Depot In But Disable Depot Out, as their is already a depot out for the bill
        } else {
          this.$store.commit("setShowDepotButtons", 3); // Enable Both Depot In Depot Out
        }
      }
      this.selectedArticles.forEach((article, index) => {
        // Fetch Dots only for tyre article
        if (
          article.article_original_name &&
          article.article_original_name.family &&
          (article.article_original_name.family.id == 1 ||
            article.article_original_name.family.parent_family_id == 1) &&
          article.depot_out_id == null &&
          article.depot_in_id == null
        ) {
          this.getArticleDots(index, article.article, 0);
        } else {
          this.selectedArticles[index].dots = [];
        }
      });
    },
    article() {
      this.checkEmptinessOfForm();
      this.calculateColumns();
    },
    quantity() {
      this.checkEmptinessOfForm();
      this.calculateColumns(true);
    },
    unit_price(val) {
      if (!val) {
        val = 0;
      }

      this.checkEmptinessOfForm();
      this.unit_price = val ?? 0;
      if (this.pauseWatchForUnitPrice) {
        this.pauseWatchForUnitPrice = false;
      } else {
        this.calculateColumns(true);
      }
    },
    red_percentage() {
      this.checkEmptinessOfForm();
      this.calculateColumns(true);
    },
    search(val) {
      this.checkEmptinessOfForm();
      if (
        !val ||
        (this.article && this.article.title_description[0].description == val)
      ) {
        return;
      }
      this.isLoadingArticle = true;
      this.fetchArticleListDebounced();
    },
    search_bill(val) {
      this.checkEmptinessOfForm();
      if (val && typeof val == "object") {
        this.search_bill = val.bill_number
          ? val.bill_number
          : val.category == "vehicles"
          ? val.registration_number
          : val.name;
        this.allowContinueSearch = false;
        return;
      }

      if (val && this.allowContinueSearch) {
        if (
          !val ||
          (this.selectedBill && this.selectedBill.bill_number == val)
        ) {
          return;
        }
        this.isLoadingSearchRegiClient = true;
        this.fetchBillSuggesListDebounced();
      }
      this.allowContinueSearch = true;
    },
    selectedBill() {
      this.checkEmptinessOfForm();
      if (this.selectedBill) {
        this.$store.commit("setIsBillSelected", true);
        this.$store.commit("setSelectedBillData", this.selectedBill);
        // Set RDV Date if their is an appointment created for the selected bill or with its parent
        if (this.selectedBill.appointment && this.selectedBill.appointment.id) {
          this.rdv_date = new Date(
            this.selectedBill.appointment.start_time
          ).toISOString();
        }
      } else {
        this.$store.commit("setIsBillSelected", false);
        this.$store.commit("setSelectedBillData", null);
      }
      if (!this.selectedBill) {
        this.$route.meta.title = "add_billing";
        this.onResetPageData();
      }
      if (this.selectedBill) {
        this.bills = [this.selectedBill];
        this.$store.commit("setBillType", this.selectedBill.type);
        this.$route.meta.title = "edit_billing";
        this.selectedArticles = [];
        this.articles = [];
        this.article = "";

        if (this.selectedBill.bill_number) {
          this.$store.commit("setBillCancelVisible", true);
          this.$store.commit("setEditingAllowed", true);
        } else {
          this.$store.commit("setBillCancelVisible", false);
          this.$store.commit("setEditingAllowed", false);
        }

        if (
          this.selectedBill.bill_number &&
          this.billType.key == "estimation"
        ) {
          this.$store.commit("setMakeBillVisible", false);
          this.$store.commit("setMakePurchaseOrderVisible", true);
          this.$store.commit("setMakeCreditNoteVisible", false);
        } else if (
          this.selectedBill.bill_number &&
          this.billType.key == "purchase_order" &&
          this.selectedBill.status_id == 10251000 /* (10251000 = Open) */
        ) {
          this.$store.commit("setMakeBillVisible", true);
          this.$store.commit("setMakePurchaseOrderVisible", false);
          this.$store.commit("setMakeCreditNoteVisible", false);
        } else if (
          this.selectedBill.bill_number &&
          this.billType.key == "bill"
        ) {
          this.$store.commit("setMakeBillVisible", false);
          this.$store.commit("setMakePurchaseOrderVisible", false);
          this.$store.commit("setMakeCreditNoteVisible", true);
        }
        let isCompany = !!this.selectedBill.client.company_id;
        this.parent_entityType = isCompany
          ? this.entityTypeSelectItems[1]
          : this.entityTypeSelectItems[0];
        this.parent_company_client_name = this.selectedBill.client;
        this.parent_company_client_names = [this.selectedBill.client];
        this.parent_familyClient = this.selectedBill.client.family_client_id;

        this.parent_company_client_email = this.selectedBill.client.email;
        this.parent_company_client_gsm = this.selectedBill.client.gsm;
        this.parent_company_client_phone = this.selectedBill.client.phone;
        this.parent_representative_company_id =
          this.selectedBill.client.representative_company_id;
        // this.parent_zipcode = this.selectedBill.client.zip;
        this.reference_client = this.selectedBill.reference;
        this.note = this.selectedBill.message;
        this.next_event_code = this.selectedBill.next_event_id;
        this.due_date = this.selectedBill.due_date;
        // if(this.selectedBill.billing_address){
        //   this.parent_zipcode = this.selectedBill.billing_address.zip;
        //   this.parent_address = this.selectedBill.billing_address.street;
        // }

        // if(this.selectedBill.client.client_address.length > 0){
        //   this.parent_zipcode = this.selectedBill.client.client_address[0].address ? this.selectedBill.client.client_address[0].address.zip : null;
        //   this.parent_address = this.selectedBill.client.client_address[0].address ? this.selectedBill.client.client_address[0].address.street : null;
        //   this.parent_city = this.selectedBill.client.client_address[0].address ? this.selectedBill.client.client_address[0].address.city.name : null;
        //   this.parent_country = this.selectedBill.client.client_address[0].address ? this.selectedBill.client.client_address[0].address.city.country_id : null;
        // }

        if (
          this.selectedBill.billing_address_id &&
          this.selectedBill.billing_address?.id
        ) {
          this.parent_zipcode = this.selectedBill.billing_address
            ? this.selectedBill.billing_address.zip
            : null;
          this.parent_address = this.selectedBill.billing_address
            ? this.selectedBill.billing_address.street
            : null;
          this.parent_city = this.selectedBill.billing_address
            ? this.selectedBill.billing_address.city.name
            : null;
          this.parent_county = this.selectedBill.billing_address
            ? this.selectedBill.billing_address.county
            : null;
          this.parent_country = this.selectedBill.billing_address
            ? this.selectedBill.billing_address.city.country_id
            : null;
        }
        if (
          this.selectedBill.shipping_address_id &&
          this.selectedBill.shipping_address?.id
        ) {
          this.parent_shipping_zipcode = this.selectedBill.shipping_address
            ? this.selectedBill.shipping_address.zip
            : null;
          this.parent_shipping_address = this.selectedBill.shipping_address
            ? this.selectedBill.shipping_address.street
            : null;
          this.parent_shipping_city = this.selectedBill.shipping_address
            ? this.selectedBill.shipping_address.city.name
            : null;
          this.parent_shipping_county = this.selectedBill.shipping_address
            ? this.selectedBill.shipping_address.county
            : null;
          this.parent_shipping_country = this.selectedBill.shipping_address
            ? this.selectedBill.shipping_address.city.country_id
            : null;
        }

        if (this.selectedBill.client.company) {
          this.parent_denomination =
            this.selectedBill.client.company.denomination_id;
          this.parent_tva = this.selectedBill.client.company.tva;
        }
        if (this.selectedBill.details) {
          this.rowNumber = 0;
          this.selectedBill.details.forEach((element) => {
            let isToMultiplyWithCredNoteMulValue = false;
            if (this.billType && this.billType.key == "credit_note") {
              isToMultiplyWithCredNoteMulValue = true;
            }
            let thtva =
              element.price * element.quantity -
              (element.price * element.quantity * element.reduction) / 100;
            this.selectedArticles.push({
              srno: this.rowNumber++,
              article: element.article_id,
              article_original_name: element.article,
              quantity: element.quantity,
              unit_price: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.price ?? 0)
                  : element.price ?? 0
              ),
              rounded_unit_price: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.rounded_unit_price ?? 0)
                  : element.rounded_unit_price ?? 0
              ),
              red_percentage: parseFloat(element.reduction ?? 0),
              tarrif: parseFloat(element.article.tariff_price),
              tva: parseFloat(element.tax),
              thtva: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (thtva ?? 0)
                  : thtva ?? 0
              ),
              stock_property: element.stock_property ?? null,
              total: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.total ?? 0)
                  : element.total ?? 0
              ),
              text: element.text,
              row: element.row,
              text_position: element.text_position ?? 0,
              depot_out_id: element.depot_out_id,
              depot_in_id: element.depot_in_id,
              dots: null,
            });
          });
        }
        this.order_status = this.selectedBill.status;
        this.payment_method = this.selectedBill.payed_by_id
          ? this.selectedBill.payedBy
          : null;

        this.showOrHidePrintReport();
      } else {
        this.$store.commit("setMakeBillVisible", false);
        this.$store.commit("setMakePurchaseOrderVisible", false);
        this.$store.commit("setMakeCreditNoteVisible", false);
      }
    },
  },
};
</script>

<style scoped>
.right-aligned-input >>> input {
  text-align: right;
}
.customerbalance >>> .theme--light.v-label--is-disabled {
  color: rgb(255, 10, 10) !important;
}
.customerbalance >>> input {
  text-align: right;
  color: rgb(255, 0, 0) !important;
}
.customerbalance >>> .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgb(255, 0, 0) !important;
}
.articlenotset {
  display: flex !important;
}
.articleset {
  display: none !important;
}
.selectedArticleTextBox {
  background-color: rgb(220, 255, 220);
}
.dontbreakline {
  white-space: pre !important;
}
</style>
