import { API_BASE_URL, PEPPOL_BILL_TYPES } from "@/config";
import axios from "@/plugins/axios";

export default {
  data() {
    return {
      currentBillPeppolStatus: null,
      billReportedToPeppol: false,
      billQualifiesForPeppol: false,
    };
  },
  computed: {
    companyIsPeppol() {
      try {
        let userData = localStorage.getItem("data");
        userData = JSON.parse(userData);
        let userCompany = userData.userDetails.company;

        return userCompany.is_peppol == 1;
      } catch (error) {
        return false;
      }
    },
  },
  methods: {
    determineBillPeppolStatus(bill) {
      // Condition 2, Bill type must be bill or Credit Note
      let billType = this.billType;
      let isBillTypeWhiteListed =
        billType && PEPPOL_BILL_TYPES.includes(billType.key);

      let selectedBill = bill;

      // Condition 4 Client Must Have A representative Company
      let clientIsCompany =
        selectedBill?.client &&
        selectedBill?.client.company &&
        selectedBill?.client.company.is_peppol == 1;

      let billIsQualifiedForPeppol =
        this.companyIsPeppol && isBillTypeWhiteListed && clientIsCompany;

      this.billQualifiesForPeppol = billIsQualifiedForPeppol;

      if (!billIsQualifiedForPeppol) {
        return {
          color: null,
          message: "",
          companyIsPeppol: this.companyIsPeppol,
          billQualifiesForPeppol: billIsQualifiedForPeppol,
        };
      }

      let transactionStatus = selectedBill.transaction_status;

      if (!transactionStatus) {
        return {
          color: "",
          message: this.$t("peppol.status.not_reported"),
          companyIsPeppol: this.companyIsPeppol,
          billQualifiesForPeppol: billIsQualifiedForPeppol,
        };
      }

      this.billReportedToPeppol = true;

      let color = null;

      switch (transactionStatus.key) {
        case "registered_in_peppol":
          color = "green";
          break;
        case "registration_error_from_peppol":
          color = "red";
          break;
        case "confirmed_by_peppol":
          color = "green";
          break;
        default:
          color = null;
      }

      return {
        color: color,
        message: this.$t("peppol.status." + transactionStatus.key),
        companyIsPeppol: this.companyIsPeppol,
        billQualifiesForPeppol: billIsQualifiedForPeppol,
      };
    },
    showPeppol() {
      // Condition 2, Bill type must be billed or Credit Note
      let billType = this.billType;
      let isBillTypeWhiteListed =
        billType && PEPPOL_BILL_TYPES.includes(billType.key);

      // Condition 3, Client Must have is_peppol property set to true
      let selectedBill = this.selectedBillData;
      // Condition 4 Client Must Have A representative Company
      let clientIsCompany =
        selectedBill?.client &&
        selectedBill?.client.company &&
        selectedBill?.client.company.is_peppol == 1;
      // Condition 5, Selected Bill Transaction Status Must Be null
      let notSentToPeppol =
        selectedBill.transaction_status_id == null ||
        selectedBill.transaction_status?.key ===
          "registration_error_from_peppol";

      return (
        this.companyIsPeppol &&
        isBillTypeWhiteListed &&
        clientIsCompany &&
        notSentToPeppol
      );
    },
    sendPeppol() {
      if (!this.showPeppol()) {
        return this.$toast.error(this.$t("peppol.error"));
      }

      return new Promise((resolve, reject) => {
        return axios
          .put(
            API_BASE_URL + "/peppol/" + this.selectedBillData.id,
            {},
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
              },
            }
          )
          .finally(() => {
            this.peppolDialog = false;
          })
          .then((response) => {
            // Get Response Status Code
            let statusCode = response.status;

            if (statusCode === 208) {
              return this.$toast.error(this.$t("peppol.already_sent"));
            }

            let transactionStatus = response.data.data;
            this.selectedBillData.transaction_status_id = transactionStatus.id;
            this.selectedBillData.transaction_status = transactionStatus;
            this.$store.state.currentBillPeppolStatus =
              this.determineBillPeppolStatus(this.selectedBillData);

            this.$toast.success(this.$t("peppol.success"));
            resolve(response);
          })
          .catch((error) => {
            this.$toast.error(this.$t("peppol.error"));
            reject(error);
          });
      });
    },
  },
  deactivated() {
    this.currentBillPeppolStatus = null;
    this.billReportedToPeppol = false;
    this.billQualifiesForPeppol = false;
  },
  destroyed() {
    this.currentBillPeppolStatus = null;
    this.billReportedToPeppol = false;
    this.billQualifiesForPeppol = false;
  },
};
