<template>
  <v-dialog max-width="800" :value="value">
    <loading-model :showLoadingModel.sync="showLoadingModel"></loading-model>
    <v-card>
      <v-card-title>
        {{
          $t(
            isset(bank)
              ? "company_settings.edit_bank_account"
              : "company_settings.add_new_bank_account"
          )
        }}
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col md="6">
              <v-text-field
                class="to-upper"
                required
                v-model="bank_name"
                :label="$t('company_settings.bank_name')"
              >
                <!-- <template #label>
                        <span class="red--text"><strong>* </strong></span> {{ $t('company_settings.bank_name') }}
                    </template> -->
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                class="to-upper"
                v-model="branch_name"
                :label="$t('company_settings.branch_name')"
              >
                <!-- <template #label>
                        <span class="red--text"><strong>* </strong></span> {{ $t('company_settings.branch_name') }}
                    </template> -->
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                class="to-upper"
                required
                v-model="bank_account"
                :label="$t('company_settings.account_number')"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>
                  {{ $t("company_settings.account_number") }}
                </template>
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                class="to-upper"
                required
                v-model="swift_code"
                :label="$t('company_settings.swift_code')"
              >
                <!-- <template #label>
                    <span class="red--text"><strong>* </strong></span> {{ $t('company_settings.swift_code') }}
                </template> -->
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-select
                v-model="currency_id"
                :items="allCurrencies"
                item-text="text"
                item-value="id"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>
                  {{ $t("company_settings.currency") }}
                </template>
              </v-select>
            </v-col>

            <v-col md="3" class="mt-2">
              <v-checkbox
                v-model="is_default"
                :label="$t('company_settings.set_as_default')"
              ></v-checkbox>
            </v-col>

            <v-col md="3" class="mt-2">
              <v-checkbox
                v-model="is_show_on_bill"
                :label="$t('company_settings.show_on_bill')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex ma-0 ml-n0 pa-2" cols="12">
              <v-file-input
                show-size
                counter
                id="rib"
                ref="rib"
                name="rib"
                :error-messages="ribErrors ? [ribErrors] : null"
                v-model="rib"
                type="file"
                :label="$t('company_settings.rib')"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close()">
          <CloseButton />
        </span>
        <span class="ml-4" @click="handleSubmit">
          <SaveButton />
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import LoadingModel from "@/components/LoadingModel.vue";

export default {
  name: "CompanyBankForm",
  components: {
    CloseButton,
    SaveButton,
    "loading-model": LoadingModel,
  },
  props: {
    bank: {
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    company_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showLoadingModel: false,
      rib: null,
      ribErrors: null,
      axiosHeaders: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      bank_name: "",
      branch_name: "",
      bank_account: "",
      swift_code: "",
      currency_id: this.defaultCurrencyId,
      is_default: false,
      is_show_on_bill: false,
      allCurrencies: [],
    };
  },
  watch: {
    value(val) {
      this.resetForm();

      if (!val) return null;

      if (!this.isset(this.allCurrencies)) this.getCurrencies();

      if (this.bank) this.setFormValues();
    },
    bank(val) {
      if (!val) return false;

      this.setFormValues();
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
    },
    getCurrencies() {
      return new Promise((resolve) => {
        this.getDataWithCache(
          "/currencies",
          "currencies",
          "currencies",
          true
        ).then((data) => {
          this.allCurrencies = data;
          this.allCurrencies = this.allCurrencies.map((currency) => {
            return {
              id: currency.id,
              text: this.translationHelper("currencies." + currency.key),
            };
          });
          resolve(data);
        });
      });
    },
    async handleSubmit() {
      await this.createBank();
    },
    async createBank() {
      if (this.validateForm() !== true) {
        return false;
      }

      let payload = {
        bank_name: this.bank_name,
        branch_name: this.branch_name,
        bank_account: this.bank_account,
        swift_code: this.swift_code,
        currency_id: this.currency_id,
        is_default: this.is_default,
        is_show_on_bill: this.is_show_on_bill,
      };

      if (this.company_id) {
        payload["company_id"] = this.company_id;
      }

      this.showLoadingModel = true;

      await axios[this.bank ? "put" : "post"](
        API_BASE_URL + "/banks" + (this.bank ? `/${this.bank.id}` : ""),
        payload,
        { headers: this.axiosHeaders }
      )
        .finally(() => {})
        .then(async (response) => {
          for (let key in payload) {
            this[key] = ["is_default", "is_show_on_bill"].includes(key)
              ? false
              : "";
          }

          let latestBankId = null;
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((bank) => {
              if (!latestBankId || latestBankId < bank.id) {
                latestBankId = bank.id;
              }
            });
          }
          if (this.rib) {
            // Only perform operation when new rib is added
            if (this.bank) {
              await this.deleteOtherRIBForThisBank(this.bank);
            }
            await this.updateRIB(this.bank ? this.bank.id : latestBankId);
          }
          this.$toast.success(
            this.$t(
              this.bank
                ? "company_settings.bank_account_updated"
                : "company_settings.bank_account_created"
            )
          );
          this.$emit("updated", response.data.data);
          this.close();
        })
        .catch(() => {
          this.$toast.error(
            this.$t("company_settings.bank_account_creation_failed")
          );
        })
        .finally(() => {
          this.showLoadingModel = false;
        });
    },
    deleteOtherRIBForThisBank(bank) {
      return new Promise((resolve) => {
        if (bank.ribs && bank.ribs.length > 0) {
          bank.ribs.forEach((rib) => {
            axios
              .delete(API_BASE_URL + "/attachments/" + rib.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.AccessToken,
                },
              })
              .then(() => {})
              .catch((error) => {
                this.ribErrors = error.response.data.errors[0];
                console.log("an error occured " + error.response.data.errors);
              })
              .finally(() => {
                resolve();
              });
          });
        } else {
          resolve();
        }
      });
    },
    updateRIB(bankId) {
      return new Promise((resolve) => {
        let module_id = this.getModuleId("company_banks");
        let title = "RIB"; // Hardcoded for now for RIB string
        let formData = new FormData();
        formData.append("attachment", this.rib);
        formData.append("module_id", module_id);
        formData.append("title", title);
        formData.append("group", "");
        formData.append("description", title);
        formData.append("resource_key_id", bankId);
        axios
          .post(API_BASE_URL + "/attachments", formData, {
            headers: {
              Authorization: "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(() => {})
          .catch((error) => {
            if (error?.response?.status == 413) {
                this.attchmentErrors = this.$t("very_large_file");
            }
            this.ribErrors = error.response.data.errors[0];
            console.log("an error occured " + error.response.data.errors);
            this.$toast.error(error.response.data.errors[0]);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    validateForm() {
      //   if (!this.isset(this.bank_name)) {
      //     return this.$toast.error(this.$t('company_settings.bank_name_required'));
      //   }

      if (!this.isset(this.bank_account)) {
        return this.$toast.error(
          this.$t("company_settings.account_number_required")
        );
      }

      //   if (!this.isset(this.swift_code)) {
      //     return this.$toast.error(this.$t('company_settings.swift_code_required'));
      //   }

      if (!this.isset(this.currency_id)) {
        return this.$toast.error(this.$t("company_settings.currency_required"));
      }

      return true;
    },

    resetForm() {
      this.bank_name = "";
      this.branch_name = "";
      this.bank_account = "";
      this.swift_code = "";
      this.currency_id = this.defaultCurrencyId;
      this.is_default = false;
      this.is_show_on_bill = false;
      this.rib = null;
      this.ribErrors = null;
    },

    setFormValues() {
      this.bank_name = this.bank.bank_name;
      this.branch_name = this.bank.branch_name;
      this.bank_account = this.bank.bank_account;
      this.swift_code = this.bank.swift_code;
      this.currency_id = this.bank.currency_id;
      this.is_default = this.bank.is_default;
      this.is_show_on_bill = this.bank.is_show_on_bill;
    },
  },
};
</script>
