<template>
  <v-row>
    <v-col>
      <v-row>
        <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
        <v-col class="pt-0" cols="12" sm="2">
          <v-select
            v-model="familyArticle"
            :items="familyArticles"
            :label="$t('brand.family_article')"
            item-value="id"
            item-text="name"
            return-object
            hide-details="auto"
            @click:append="familyArticle = null"
            append-icon="mdi-close"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ma-0 pa-0" cols="12" sm="4">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            class="mr-3 to-upper"
            label="Search"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>

      <div>
        <v-data-table
          :height="'calc(-208px + 100vh)'"
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :items="brands"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          @click:row="editItem"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          mustSort
        >
          <template v-slot:item="{ item }">
            <tr>
              <td @click="editItem(item)">{{ item.id || "-" }}</td>
              <td @click="editItem(item)">{{ item.name || "-" }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "FamilyClient",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      isLoading: true,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      sortBy: "id",
      sortDesc: true,
      sortOrd: "DESC",
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      brands: [],
      search: "",
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      familyArticle: null,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setFormMode", VIEW_MODE);
    this.$store.commit("setAddButtonRoute", "AddClient");
    this.fetchData();
  },
  created() {
    this.$watch(
      "search",
      _.debounce(() => {
        this.isLoading = true;
        this.fetchData();
      }, 500)
    );
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get(
          API_BASE_URL +
            "/brands?page=" +
            this.page +
            ("&items_per_page=" + this.itemsPerPage) +
            (this.search
              ? "&search=" + encodeURIComponent(this.search)
              : "" +
                ("&sortby=" + this.sortBy) +
                ("&sorttype=" + this.sortOrd) +
                (this.familyArticle
                  ? "&family=" + this.familyArticle.id + "&parent_also=true"
                  : "")),
          // (this.familyArticle ? ('&family=' + this.familyArticle.id ) : '')
          {
            headers: this.header,
          }
        )
        .then(({ data }) => {
          this.brands = data.data;
          this.lastPage = data.meta.last_page;
          this.totalItems = data.meta.total;
          this.page = data.meta.current_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    editItem(item) {
      this.$store.commit("seteditId", item.id);
      this.$store.commit("setFormMode", EDIT_MODE);
      this.$router.push("/brands/edit").catch(() => {});
    },
    deleteItem() {
      //
    },

    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }

      this.fetchData();
    },
    onSearch() {
      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("brand.id"),
          align: "start",
          sortable: false,
          value: "id",
          width: "100",
        },
        {
          text: this.$t("brand.name"),
          align: "start",
          sortable: false,
          value: "name",
          width: "100",
        },
      ];
    },
  },
  watch: {
    familyArticle: {
      handler: function () {
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped></style>
