<template>
  <v-menu open-on-hover bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="top-report-save"
        class="text-white -color ma-2"
        color="success"
        v-bind="attrs"
        v-on="on"
        :disabled="exportStatus"
      >
        {{ $t("nav_buttons.export") }}
        <v-icon dark right> mdi-table-arrow-right </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="getElementById('export-data').click()">
        <v-icon color="green darken-2" left>
          mdi-file-delimited-outline
        </v-icon>
        {{ $t("nav_buttons.csv") }}
      </v-list-item>
      <v-list-item
        @click="getElementById('zipped-reports').click()"
        v-if="checkTag('zipped_reports')"
      >
        <v-icon color="green darken-2" left> mdi-folder-zip-outline</v-icon>
        {{ $t("nav_buttons.zip") }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "ExportDropDownButtons",
  computed: {
    exportStatus() {
      return this.$store.state.exportStatus;
    },
  },
};
</script>
