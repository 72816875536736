var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"900"},on:{"click:outside":function($event){return _vm.cancelDialog('cancel')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancelDialog('cancel')}},model:{value:(_vm.internalShowDialog),callback:function ($$v) {_vm.internalShowDialog=$$v},expression:"internalShowDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[(_vm.addMember)?[(_vm.defaultClient && typeof _vm.defaultClient == 'object')?[_vm._v(" "+_vm._s(_vm.$t("vehicles.clients_model.edit_member"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("vehicles.clients_model.add_member"))+" ")]]:[_vm._v(" "+_vm._s(_vm.$t("vehicles.clients_model.add_client"))+" ")]],2),_c('v-card-text',[_c('v-form',{staticClass:"main_form",attrs:{"id":"client-form"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.addMember && !_vm.isClientCompanySelected)?_c('v-col',{attrs:{"cols":"12","sm":_vm.isClientCompanySelected ? '8' : '2'}},[_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.memberTypes,"item-value":"id","item-text":"text","return-object":"","menu-props":"auto","label":_vm.$t('vehicles.clients_model.member_type'),"error-messages":_vm.$store.state.validation && !_vm.$v.form.memberType.required
                    ? [
                        this.$t(
                          'vehicles.clients_model.text_messages.member_type_required'
                        ),
                      ]
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.memberType),callback:function ($$v) {_vm.$set(_vm.form, "memberType", $$v)},expression:"form.memberType"}})],1):_vm._e(),(_vm.addMember)?_c('v-col',{attrs:{"cols":"12","sm":_vm.isClientCompanySelected ? '3' : '2'}},[_c('v-text-field',{ref:"department",staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"department","label":_vm.$t('vehicles.clients_model.department'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'justalphanumeric', 50)}},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}})],1):_vm._e(),(!_vm.addMember)?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.entityTypeSelectItems,"disabled":_vm.addMember,"item-text":"text","item-value":"id","return-object":"","label":_vm.$t('vehicles.clients_model.entity_type'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.entityType),callback:function ($$v) {_vm.$set(_vm.form, "entityType", $$v)},expression:"form.entityType"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":_vm.isClientCompanySelected ? '8' : '4'}},[_c('v-text-field',{ref:"client_name",staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('vehicles.clients_model.client_name'),"error-messages":_vm.$store.state.validation && !_vm.$v.form.name.required
                    ? [
                        this.$t(
                          'vehicles.clients_model.text_messages.name_required'
                        ),
                      ]
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'everything', 50)}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),(!_vm.isClientCompanySelected)?_c('v-col',{attrs:{"cols":"12","sm":_vm.isClientCompanySelected ? '8' : _vm.addMember ? '4' : '5'}},[_c('v-text-field',{ref:"client_name",staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('vehicles.clients_model.client_first_name'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'everything', 50)}},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})],1):_vm._e(),(_vm.isClientCompanySelected)?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.denominations,"item-value":"id","item-text":"text","label":_vm.$t('vehicles.clients_model.denomination'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.denomination),callback:function ($$v) {_vm.$set(_vm.form, "denomination", $$v)},expression:"form.denomination"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('vehicles.clients_model.email'),"error-messages":_vm.$store.state.validation && !_vm.$v.form.email.email
                    ? [
                        this.$t(
                          'vehicles.clients_model.text_messages.invalid_email'
                        ),
                      ]
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'email', 60)}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('v-col',{staticClass:"d-flex"},[_c('vue-tel-input-vuetify',{attrs:{"countryToBeSelected":_vm.countryToBeSelectedByDefault,"disabledFetchingCountry":true,"preferredCountries":_vm.preferredCountry,"filterCountries":_vm.onlyCountries,"validCharactersOnly":"","label":_vm.$t('vehicles.clients_model.gsm'),"inputOptions":{ showDialCode: true, tabindex: 0 },"mode":'international'},model:{value:(_vm.form.gsm),callback:function ($$v) {_vm.$set(_vm.form, "gsm", $$v)},expression:"form.gsm"}})],1),_c('v-col',{staticClass:"d-flex"},[_c('vue-tel-input-vuetify',{attrs:{"countryToBeSelected":_vm.countryToBeSelectedByDefault,"disabledFetchingCountry":true,"preferredCountries":_vm.preferredCountry,"filterCountries":_vm.onlyCountries,"validCharactersOnly":"","label":_vm.$t('vehicles.clients_model.phone'),"inputOptions":{ showDialCode: true, tabindex: 0 },"mode":'international'},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),(_vm.addMember)?_c('v-col',{staticClass:"d-flex",staticStyle:{"max-width":"140px"}},[_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.languages,"item-value":"id","item-text":"text","label":_vm.$t('vehicles.clients_model.languages'),"error-messages":_vm.$store.state.validation && !_vm.$v.form.language.required
                    ? [
                        this.$t(
                          'vehicles.clients_model.text_messages.language_required'
                        ),
                      ]
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}})],1):_vm._e()],1),(_vm.addMember && _vm.checkTag('webshop'))?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('login.webshop_password'),"type":'password'},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('v-col',[_c('v-autocomplete',{staticClass:"to-upper",attrs:{"role":"presentation","autocomplete":"off","flat":"","items":_vm.activeStatuses,"item-text":"key","item-value":"id","label":_vm.$t('vehicles.clients_model.active_id'),"hide-details":"true","append-icon":''},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}})],1)],1):_vm._e(),_c('v-row',[(!_vm.addMember)?_c('v-col',{attrs:{"cols":"12","sm":_vm.isClientCompanySelected ? '3' : '4'}},[_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.familyClients,"item-value":"id","item-text":"name","menu-props":"auto","label":_vm.$t('vehicles.clients_model.family_client'),"error-messages":_vm.$store.state.validation && !_vm.$v.form.familyClient.required
                    ? [
                        this.$t(
                          'vehicles.clients_model.text_messages.family_client_required'
                        ),
                      ]
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.familyClient),callback:function ($$v) {_vm.$set(_vm.form, "familyClient", $$v)},expression:"form.familyClient"}})],1):_vm._e(),(!_vm.addMember)?_c('v-col',{attrs:{"cols":"12","sm":_vm.isClientCompanySelected ? '3' : '4'}},[_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.currencies,"item-value":"id","item-text":"text","label":_vm.$t('vehicles.clients_model.currency'),"error-messages":_vm.$store.state.validation && !_vm.$v.form.currency.required
                    ? [
                        this.$t(
                          'vehicles.clients_model.text_messages.currency_required'
                        ),
                      ]
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}})],1):_vm._e(),(!_vm.addMember)?_c('v-col',{attrs:{"cols":"12","sm":_vm.isClientCompanySelected ? '3' : _vm.addMember ? '12' : '4'}},[_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.languages,"item-value":"id","item-text":"text","label":_vm.$t('vehicles.clients_model.languages'),"error-messages":_vm.$store.state.validation && !_vm.$v.form.language.required
                    ? [
                        this.$t(
                          'vehicles.clients_model.text_messages.language_required'
                        ),
                      ]
                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}})],1):_vm._e(),(_vm.isClientCompanySelected)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('TvaInput',{attrs:{"label":_vm.$t('vehicles.clients_model.tva')},on:{"input":function($event){return _vm.setCountryToBeSelected(_vm.form.tva)}},model:{value:(_vm.form.tva),callback:function ($$v) {_vm.$set(_vm.form, "tva", $$v)},expression:"form.tva"}})],1):_vm._e()],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),(
          _vm.addMember &&
          _vm.addMemberToCompany &&
          _vm.defaultClient &&
          typeof _vm.defaultClient == 'object' &&
          _vm.defaultClient.id
        )?_c('v-btn',{staticClass:"mr-1 text-white-color",attrs:{"id":"top-delete","color":"error"},on:{"click":function($event){return _vm.deleteMember()}}},[_vm._v(" "+_vm._s(_vm.$t("nav_buttons.delete"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-delete ")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.cancelDialog('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("vehicles.Cancel"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1),_c('v-btn',{attrs:{"color":"success","disabled":_vm.$store.state.isLoading},on:{"click":function($event){return _vm.saveData()}}},[_vm._v(" "+_vm._s(_vm.$t("vehicles.Save"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }