import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  refs: ["titleSearchInput"],
  data() {
    return {
      allTitlePredictions: [],
      titlePredictionLoading: false,
      vehicleHistorySearch: "",
      formConfig: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken,
        },
      },
    };
  },
  mounted() {
    this.getTitlePredictions();
  },
  methods: {
    getTitlePredictions(search = "") {
      let formConfig = this.formConfig;
      // formConfig.signal = this.abortController();
      this.predictionLoading = true;
      return axios
        .get(
          `${API_BASE_URL}/vehicle_history/title/predictions?search=${search}`,
          formConfig
        )
        .finally(() => {
          this.predictionLoading = false;
        })
        .then((response) => {
          this.allTitlePredictions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  activated() {
    this.getTitlePredictions();
  },
  deactivated() {
    this.allTitlePredictions = [];
    this.titlePredictionLoading = false;
    this.vehicleHistorySearch = "";
  },
  destroyed() {
    this.allTitlePredictions = [];
    this.titlePredictionLoading = false;
    this.vehicleHistorySearch = "";
  },
};
