var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"content-class":"dialogPopUp","persistent":"","max-width":"1324px"},on:{"click:outside":function($event){return _vm.closeTyreDialog()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeTyreDialog()}},model:{value:(_vm.modify_stock_dialog),callback:function ($$v) {_vm.modify_stock_dialog=$$v},expression:"modify_stock_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("billings.depot_out.title"))+" ")])]),_c('v-card-text',{on:{"click":function($event){_vm.modify_data = true}}},[_c('v-row',{staticClass:"mt-4 mb-4"},[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
            'items-per-page-options': _vm.dataTableOptions,
          },"headers":_vm.depositHeader,"hide-default-footer":true,"items":_vm.depot,"items-per-page":_vm.itemsPerPage,"options":_vm.options,"sort-by":_vm.sortBy,"loading-text":this.$t('loading'),"item-key":"id","height":"29vh","hide-no-data":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(item.deleted_at ? _vm.isHistory : true)?_c('tr',{class:item == _vm.selectedDepot ? 'bg-grey' : 'bg-white',on:{"click":function($event){return _vm.selectDepot(item)}}},[_c('td'),_c('td',{attrs:{"id":item.id,"data":_vm.selectedDepot && _vm.selectedDepot.id == item.id
                    ? _vm.highlightRow(item.id)
                    : ''}},[(item.status.key)?_c('span',[_c('DepotStatus',{attrs:{"status-key":item.status.key}})],1):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(item.localisation)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDateTime(item.created_at))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.requested_out_for)+" ")]),_c('td',[(_vm.isHistory)?[_vm._v(" "+_vm._s(_vm.formatDateTime(item.deleted_at))+" ")]:_vm._e()],2),_c('td',{attrs:{"align":"right"}},[(_vm.checkIfSomeErrorInDepot(item.details))?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-fridge-alert ")]):_vm._e()],1)]):_vm._e()]}}])})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"pr-4"},[_c('v-icon',{staticClass:"mb-2 mr-3 tyre-hotel-heading"},[_vm._v(" mdi-account ")]),_c('span',{staticClass:"tyre-hotel-heading"},[_vm._v(_vm._s(_vm.$t("tyre_hotel.deposit_details.text")))])],1),_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
              'items-per-page-options': _vm.dataTableOptions,
            },"hide-default-footer":true,"headers":_vm.depositDeatilsHeader,"items":_vm.selectedDepotForSubTable,"items-per-page":_vm.itemsPerPage,"options":_vm.options,"sort-by":_vm.sortBy,"loading-text":this.$t('loading'),"item-key":"id","height":"32vh"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{class:item.article_status && item.article_status.key == 'damaged'
                    ? 'damaged-row'
                    : ''},[_c('td',[_c('v-checkbox',{staticClass:"ma-0 mt-n1",attrs:{"color":item.article_status &&
                      item.article_status.key == 'damaged'
                        ? '#ff5252'
                        : 'black',"hide-details":"auto"},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})],1),_c('td',[(item.position && item.position.id == 10281000)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-cancel ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.no_position_shorthand"))+" ")],1):_vm._e(),(item.position && item.position.id == 10281001)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-arrow-top-right-bold-box ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.top_right_shorthand"))+" ")],1):_vm._e(),(item.position && item.position.id == 10281002)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-arrow-top-left-bold-box ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.top_left_shorthand"))+" ")],1):_vm._e(),(item.position && item.position.id == 10281003)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-arrow-bottom-right-bold-box ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.bottom_right_shorthand"))+" ")],1):_vm._e(),(item.position && item.position.id == 10281004)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-arrow-bottom-left-bold-box ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.bottom_left_shorthand"))+" ")],1):_vm._e(),(item.position && item.position.id == 10281006)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-arrow-top-right-bold-box ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.top_right_shorthand_2"))+" ")],1):_vm._e(),(item.position && item.position.id == 10281007)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-arrow-top-left-bold-box ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.top_left_shorthand_2"))+" ")],1):_vm._e(),(item.position && item.position.id == 10281008)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-arrow-bottom-right-bold-box ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.bottom_right_shorthand_2"))+" ")],1):_vm._e(),(item.position && item.position.id == 10281009)?_c('span',[_c('v-icon',{attrs:{"color":item.article_status &&
                        item.article_status.key == 'damaged'
                          ? '#ff5252'
                          : 'black'}},[_vm._v(" mdi-arrow-bottom-left-bold-box ")]),_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.position.bottom_left_shorthand_2"))+" ")],1):_vm._e()]),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.article.title_description[0].description)+" ")]:[_vm._v(" "+_vm._s(item.article_name)+" ")]],2),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.article.property.property_03)+" ")]:void 0],2),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.article.property.property_04)+" ")]:void 0],2),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.article.property.property_05)+" ")]:void 0],2),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.article.property.property_06)+" ")]:void 0],2),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.article.property.property_07)+" ")]:void 0],2),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.mm)+" ")]:[_vm._v(" "+_vm._s(item.mm)+" ")]],2),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.couple)+" ")]:[_vm._v(" "+_vm._s(item.couple)+" ")]],2),_c('td',[(item.article_id)?[_vm._v(" "+_vm._s(item.pressure)+" ")]:[_vm._v(" "+_vm._s(item.pressure)+" ")]],2),_c('td',[(item.rimtype)?_c('span',[_vm._v(" "+_vm._s(item.rimtype.value)+" ")]):_vm._e()]),_c('td',[(item.is_with_hubcaps == 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1)])]}}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-4",attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.closeTyreDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.add_modify_tyre.cancel"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1),_c('v-btn',{staticClass:"px-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.saveTyreDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("tyre_hotel.add_modify_tyre.save"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }