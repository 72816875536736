<template>
  <v-row>
    <v-col class="pb-0">
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="8"> </v-col>
        <v-col class="ma-0 pa-0" cols="12" sm="2">
          <v-select
            :items="taskStatuses"
            v-model="selectedStatus"
            item-text="text"
            item-value="id"
            :label="$t('billings.status')"
            @change="fetchData()"
            clearable
          ></v-select>
        </v-col>
        <v-col class="ma-0 pa-0" cols="12" sm="2">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            class="mr-3 to-upper"
            :label="$t('search')"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>

      <div>
        <v-data-table
          :height="'calc(-210px + 100vh)'"
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :items="tasks"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          @click:row="editItem"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          mustSort
        >
          <template v-slot:body>
            <tbody>
              <template v-for="(item, index) in tasks">
                <!-- Section Header Row -->
                <tr
                  v-if="
                    index === 0 ||
                    item.section_title !== tasks[index - 1].section_title
                  "
                  :key="'section-' + item.section_title"
                  class="section-header bg-gray-200 cursor-pointer"
                  @click="toggleSection(item.section_title)"
                >
                  <td colspan="7" class="font-bold py-2 px-4">
                    <span>{{ item.section_title }}</span>
                    <span class="float-right">
                      <v-icon>
                        {{
                          collapsedSections[item.section_title]
                            ? "mdi-arrow-up"
                            : "mdi-arrow-down"
                        }}
                      </v-icon>
                    </span>
                  </td>
                </tr>

                <!-- Task Row (Hidden if Section is Collapsed) -->
                <tr
                  v-if="!collapsedSections[item.section_title]"
                  :key="item.identifier"
                  :class="getPriorityColorBasedOnValue(item.priority)"
                >
                  <td @click="editItem(item)">{{ item.srno }}</td>
                  <td @click="editItem(item)">{{ item.title }}</td>
                  <td @click="editItem(item)">{{ item.description }}</td>
                  <td @click="editItem(item)">
                    {{ getPriorityTextBasedOnValue(item.priority) }}
                  </td>
                  <td @click="editItem(item)" class="capitalize">
                    {{ $t(item.status?.key) }}
                  </td>
                  <td @click="editItem(item)" class="capitalize">
                    {{ formatDateTime(item.due_date) }}
                  </td>
                  <td @click="editItem(item)" class="capitalize">
                    {{ formatDateTime(item.created_at) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";
// import { VIEW_MODE } from "@/config";
//   import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "SupplierListing",
  data() {
    return {
      collapsedSections: {},
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      isLoading: true,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      sortBy: "id",
      sortDesc: true,
      sortOrd: "DESC",
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      search: null,
      options: {},
      tasks: [],
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      taskStatuses: [],
      selectedStatus: null,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    (this.header = { Authorization: "Bearer " + this.token }),
      //   this.$store.commit("setFormMode", VIEW_MODE);
      this.$store.commit("setAddButtonRoute", "AddClient");
    this.getTasksStatuses();
    this.fetchData();
  },
  created() {},
  methods: {
    async getTasksStatuses() {
      await this.getCodes("task_statuses", "codes").then((data) => {
        this.taskStatuses = data;
      });
    },
    toggleSection(sectionTitle) {
      this.$set(
        this.collapsedSections,
        sectionTitle,
        !this.collapsedSections[sectionTitle]
      );
    },
    fetchData() {
      this.isLoading = true;
      axios
        .get(
          API_BASE_URL +
            "/tasks?page=" +
            this.page +
            "&items_per_page=" +
            this.itemsPerPage +
            (this.selectedStatus ? "&status_id=" + this.selectedStatus : "") +
            (this.search
              ? "&search=" + encodeURIComponent(this.search)
              : "" + "&sortby=" + this.sortBy + "&sorttype=" + this.sortOrd),
          { headers: this.header }
        )
        .then(({ data }) => {
          let count = 1;
          this.tasks = data.data
            .sort((a, b) =>
              (a.section_title ?? "").localeCompare(b.section_title)
            )
            .map((element) => {
              element.srno = count++;
              return element;
            });
          this.lastPage = data.meta.last_page;
          this.totalItems = data.meta.total;
          this.page = data.meta.current_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    editItem(item) {
      // this.$store.commit("seteditId", item.id);
      // this.$store.commit("setFormMode", EDIT_MODE);
      // this.$router.push("/tasks/edit").catch(() => {});
      return item;
    },
    deleteItem() {
      //
    },

    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }

      this.fetchData();
    },
    onSearch() {
      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("tasks.srno"),
          align: "start",
          sortable: false,
          value: "id",
          width: "50",
        },
        //   {
        //     text: this.$t("tasks.user"),
        //     align: "start",
        //     sortable: false,
        //     value: "name",
        //   },
        {
          text: this.$t("tasks.title"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tasks.description"),
          align: "start",
          sortable: false,
          value: "name",
        },
        // {
        //   text: this.$t("tasks.assigned_by"),
        //   align: "start",
        //   sortable: false,
        //   value: "name",
        // },
        // {
        //   text: this.$t("tasks.tags"),
        //   align: "start",
        //   sortable: false,
        //   value: "name",
        // },
        {
          text: this.$t("tasks.priority"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tasks.status"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tasks.due_date"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tasks.created_at"),
          align: "start",
          sortable: false,
          value: "name",
        },
      ];
    },
  },
};
</script>

<style scoped></style>
