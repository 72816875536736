<template>
  <div class="pa-4 border-box">
    <div class="card card-stats mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">
              {{ title }}
            </h5>
            <span class="h2 font-weight-bold mb-0">{{ value }}</span>
          </div>
          <div class="col-auto">
            <div
              class="icon icon-shape bg-danger text-white rounded-circle shadow"
            >
              <i class="fas fa-chart-bar"></i>
            </div>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm" v-if="change">
          <span class="text-success mr-2"
            ><i class="changeIcon"></i> {{ changeValue }}</span
          >
          <span class="text-nowrap">{{ changeText }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: {
    value: {
      type: String,
      default: "0",
    },
    title: {
      type: String,
      default: "Title",
    },
    icon: {
      type: String,
      default: "fas fa-chart-bar",
    },
    change: {
      type: Boolean,
      default: false,
    },
    changeValue: {
      type: String,
      default: "4.66%",
    },
    changeText: {
      type: String,
      default: "Since Last Month",
    },
    changeIcon: {
      type: String,
      default: "fa fa-arrow-up",
    },
  },
};
</script>
<style>
h2,
h5,
.h2,
.h5 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  color: #32325d;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

.border-box {
  border: 1px solid #eeeeee;
}
.bg-danger {
  background-color: #f5365c !important;
}

.font-weight-bold {
  font-weight: 600 !important;
  font-size: 30px;
}

a.text-success:hover,
a.text-success:focus {
  color: #24a46d !important;
}

.text-warning {
  color: #fb6340 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fa3a0e !important;
}

.text-danger {
  color: #f5365c !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ec0c38 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-muted {
  color: #8898aa !important;
}

figcaption,
main {
  display: block;
}

main {
  overflow: hidden;
}

.bg-yellow {
  background-color: #ffd600 !important;
}

.icon {
  width: 3rem;
  height: 3rem;
}

.icon i {
  font-size: 2.25rem;
}

.icon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
</style>
