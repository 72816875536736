var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',_vm._l((_vm.items),function(item,index){return _c('v-row',{key:index,staticClass:"mb-3"},[_c('v-col',{staticClass:"ma-0 pa-0 mr-1"},[_c('v-text-field',{ref:"client_name",refInFor:true,staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('company_settings.working_points.work_point_name'),"error-messages":_vm.$store.state.validation && !_vm.$v.work_point_name.required
            ? [
                this.$t(
                  'company_settings.working_points.text_messages.name_required'
                ),
              ]
            : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){return _vm.make_workpoint_data()},function($event){return _vm.handleInput($event, 'everything', 50)}],"change":function($event){return _vm.make_workpoint_data()}},model:{value:(_vm.work_point_name[index]),callback:function ($$v) {_vm.$set(_vm.work_point_name, index, $$v)},expression:"work_point_name[index]"}})],1),_c('v-col',{staticClass:"ma-0 pa-0 mr-1"},[_c('v-text-field',{ref:"client_name",refInFor:true,staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('company_settings.working_points.work_point_phone'),"error-messages":_vm.$store.state.validation && !_vm.$v.work_point_phone.required
            ? [
                this.$t(
                  'company_settings.working_points.text_messages.name_required'
                ),
              ]
            : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){return _vm.make_workpoint_data()},function($event){return _vm.handleInput($event, 'justalphanumeric', 50)}],"change":function($event){return _vm.make_workpoint_data()}},model:{value:(_vm.work_point_phone[index]),callback:function ($$v) {_vm.$set(_vm.work_point_phone, index, $$v)},expression:"work_point_phone[index]"}})],1),_c('v-col',{staticClass:"ma-0 pa-0 mr-1"},[_c('v-text-field',{ref:"client_name",refInFor:true,staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('company_settings.working_points.work_point_gsm'),"error-messages":_vm.$store.state.validation && !_vm.$v.work_point_gsm.required
            ? [
                this.$t(
                  'company_settings.working_points.text_messages.name_required'
                ),
              ]
            : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){return _vm.make_workpoint_data()},function($event){return _vm.handleInput($event, 'justalphanumeric', 50)}],"change":function($event){return _vm.make_workpoint_data()}},model:{value:(_vm.work_point_gsm[index]),callback:function ($$v) {_vm.$set(_vm.work_point_gsm, index, $$v)},expression:"work_point_gsm[index]"}})],1),_c('v-col',{staticClass:"ma-0 pa-0 mr-1"},[_c('v-text-field',{ref:"client_name",refInFor:true,staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('company_settings.working_points.work_point_fax'),"error-messages":_vm.$store.state.validation && !_vm.$v.work_point_fax.required
            ? [
                this.$t(
                  'company_settings.working_points.text_messages.name_required'
                ),
              ]
            : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){return _vm.make_workpoint_data()},function($event){return _vm.handleInput($event, 'justalphanumeric', 50)}],"change":function($event){return _vm.make_workpoint_data()}},model:{value:(_vm.work_point_fax[index]),callback:function ($$v) {_vm.$set(_vm.work_point_fax, index, $$v)},expression:"work_point_fax[index]"}})],1),_c('v-col',{staticClass:"ma-0 pa-0 mr-1"},[_c('v-text-field',{ref:"client_name",refInFor:true,staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('company_settings.working_points.work_point_email'),"error-messages":_vm.$store.state.validation && !_vm.$v.work_point_email.required
            ? [
                this.$t(
                  'company_settings.working_points.text_messages.name_required'
                ),
              ]
            : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){return _vm.make_workpoint_data()},function($event){return _vm.handleInput($event, 'email', 80)}],"change":function($event){return _vm.make_workpoint_data()}},model:{value:(_vm.work_point_email[index]),callback:function ($$v) {_vm.$set(_vm.work_point_email, index, $$v)},expression:"work_point_email[index]"}})],1),_c('v-col',{staticClass:"ma-0 pa-0 mr-1",attrs:{"cols":"12","sm":"4"}},[_c('AddressInput',{staticClass:"mt-n3 ml-n1",attrs:{"label":_vm.$t('address')},model:{value:(_vm.address[index]),callback:function ($$v) {_vm.$set(_vm.address, index, $$v)},expression:"address[index]"}})],1),_c('v-col',{staticClass:"ma-0 pa-0 mr-1"},[(_vm.items.length > 1 || index > 0)?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-delete")])],1):_vm._e(),(index === _vm.items.length - 1)?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":_vm.addItem}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }