<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <!-- Sent Date -->
      <v-col class="pa-0 ma-0" cols="12" sm="2">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :nude-right="'400px'"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-2 to-upper"
              v-model="email_date"
              :label="$t('billings.sent_at')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:append="
                email_date = null;
                getEmails(1);
              "
              append-icon="mdi-close"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            :first-day-of-week="1"
            :weekday-format="getDay"
            v-model="email_date"
            no-title
            @change="getEmails(1)"
            scrollable
            :locale="userLanguageId"
            hide-no-data
            hide-details
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- Status -->
      <v-col class="pa-0 ma-0" cols="12" sm="2">
        <v-autocomplete
          v-model="status"
          :items="emailStatuses"
          :label="$t('articles.status')"
          item-value="id"
          item-text="text"
          class="mr-2"
          return-object
          :outlined="is_boxes"
          :dense="is_boxes"
          @change="getEmails(1)"
          clearable
        ></v-autocomplete>
      </v-col>
      <!-- Search -->
      <v-col class="pa-0 ma-0" cols="12" sm="3">
        <v-text-field
          class="mr-3 to-upper"
          :label="$t('vehicles.search')"
          autofocus
          prepend-icon="mdi-magnify"
          v-bind:clearable="true"
          clear-icon="mdi-close"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
        :height="'calc(-200px + 100vh)'"
        :headers="tableHeaders"
      :items="formattedEMails"
      :items-per-page="perPage"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': perPageOptions,
      }"
      @update:page="
        (newPage) => {
          page = newPage;
          getEmails(newPage);
        }
      "
      @update:items-per-page="
        (newPerPage) => {
          perPage = newPerPage;
          getEmails(page);
        }
      "
    >
      <!--   Item Slot-->
      <template v-slot:item="props">
        <tr
          :class="{
            'bg-red': ['permanent_failure', 'temporary_failure'].includes(
              props.item.status.key
            ),
            'bg-green': ['sent', 'delivered'].includes(props.item.status.key),
          }"
          style="cursor: pointer"
          @click="handleRowClick(props.item)"
        >
          <td>{{ props.item.serial }}</td>
          <td>{{ props.item.title }}</td>
          <td>{{ props.item.from }}</td>
          <td>{{ props.item.to }}</td>
          <td>{{ props.item.subject }}</td>
          <td>
            <v-tooltip v-if="isset(props.item.attachments)" top>
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <v-icon>mdi-attachment</v-icon>
                </span>
              </template>
              <span>{{ props.item.attachments }}</span>
            </v-tooltip>
          </td>
          <td>{{ formatDateTime(props.item.date) }}</td>
          <td>{{ props.item.status_text }}</td>
        </tr>
      </template>
    </v-data-table>
    <email-detail
      v-model="emailDialog"
      :email-id="selectedEmailId"
    ></email-detail>
  </div>
</template>
<script>
import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import axios from "@/plugins/axios";
import debounce from "lodash/debounce";
import EmailDetail from "@/components/Emails/EmailDetail";

export default {
  name: "EmailsListing",
  components: {
    EmailDetail,
  },
  data() {
    return {
      menu1: false,
      email_date: null,
      emails: [],
      email: null,
      emailDialog: false,
      selectedEmailId: null,
      search: "",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      tableHeaders: [
        {
          text: this.$t("srno"),
          value: "serial",
          width: 50,
          sortable: false,
        },
        {
          text: this.translate("reference"),
          value: "title",
          sortable: false,
        },
        {
          text: this.translate("from"),
          value: "from",
          sortable: false,
        },
        {
          text: this.translate("to"),
          value: "to",
          sortable: false,
        },
        {
          text: this.translate("subject"),
          value: "subject",
          sortable: false,
        },
        {
          text: this.translate("attachments_short"),
          value: "attachments",
          sortable: false,
        },
        {
          text: this.translate("date"),
          value: "date",
          sortable: false,
        },
        {
          text: this.translate("status"),
          value: "status_text",
          sortable: false,
        },
      ],
      page: 1,
      perPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      total: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      perPageOptions: [...DEFAULT_ITEMS_PER_PAGE_OPTIONS],
      emailStatuses: [],
      status: null,
    };
  },
  async mounted() {
    this.getEmailStatus();
    await this.getEmails();
  },
  watch: {
    search: debounce(function () {
      this.getEmails(this.page);
    }, 500),
  },
  computed: {
    formattedEMails() {
      return this.emails.map((email, index) => {
        return {
          ...email,
          serial: index + 1,
          date: this.formatDate(email.created_at),
          status_text: this.translate("statuses." + email.status.key),
          from: email.user.name,
          attachments: this.translatedAttachments(email),
        };
      });
    },
  },
  methods: {
    async getEmailStatus() {
      await this.getCodes("email_statuses", "emails_section.statuses").then(
        (data) => {
          this.emailStatuses = data;
        }
      );
    },
    translatedAttachments(email) {
      if (!this.isset(email?.attachments)) {
        return "";
      }
      return email?.attachments
        .split(",")
        .map((item) => {
          return this.translationHelper("send_pdf_via_email.documents." + item);
        })
        .join(", ");
    },
    getEmails(page = 1) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_BASE_URL +
              "/emails?page=" +
              page +
              "&per_page=" +
              this.perPage +
              (this.search
                ? "&search=" + encodeURIComponent(this.search)
                : "") +
              (this.status ? "&status=" + this.status.id : "") +
              (this.email_date ? "&email_date=" + this.email_date : ""),
            { headers: this.headers }
          )
          .finally(() => {})
          .then((response) => {
            this.emails = response.data.data.data;
            this.total = response.data.data.total;
            resolve(response.data.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    translate(key) {
      return this.$t("emails_section." + key);
    },
    handleRowClick(row) {
      this.selectedEmailId = row.id;
      this.emailDialog = false;

      setTimeout(() => {
        this.emailDialog = true;
      }, 20);
    },
  },
};
</script>
<style>
.bg-red {
  background-color: #fbdcdc !important;
}
.bg-green {
  background-color: #dbf8dc !important;
}
</style>
