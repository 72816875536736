<template>
  <div>
    <v-text-field
      @focus="$event.target.select()"
      v-model="cmpValue"
      v-bind:label="label"
      v-bind="properties"
      v-bind:maxlength="options.length + options.precision"
      v-on:keypress="keyPress"
      v-on:blur="$emit('blur')"
      v-on:change="$emit('change')"
      v-on:click="$emit('click')"
      v-on:focus="$emit('focus')"
      v-on:keydown="$emit('keydown')"
      v-on:mousedown="$emit('mousedown')"
      v-on:mouseup="$emit('mouseup')"
      :style="customStyle"
      ref="ref"
      class="right-aligned-input"
      :suffix="suffix"
    >
      <template #label>
        <span v-if="required" class="red--text"><strong>* </strong></span>
        {{ $t(label) }}
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  model: { prop: "value", event: "input" },
  props: {
    customStyle: {
      type: [String],
      default: "0",
    },
    value: {
      type: [String, Number],
      default: "0",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: "",
    },
    properties: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {
          locale: "pt-BR",
          length: 11,
          precision: 2,
          empty: null,
        };
      },
    },
  },
  data: () => ({}),
  /*
    v-model="cmpValue": Dessa forma, ao digitar, o valor é atualizado automaticamente no componente pai.
    O valor digitado entra pelo newValue do Set é emitido para o componente pai, retorna pelo get e pára.
    */
  computed: {
    cmpValue: {
      get: function () {
        return this.humanFormat(this.value);
      },
      set: function (newValue) {
        this.$emit("input", this.machineFormat(newValue));
      },
    },
  },
  watch: {},
  methods: {
    humanFormat: function (value) {
      if (value || value === 0) {
        if (this.options.locale) {
          value = Number(value).toLocaleString(this.options.locale, {
            maximumFractionDigits: this.options.precision,
            minimumFractionDigits: this.options.precision,
          });
        }
      } else {
        value = this.options.empty;
      }
      return value;
    },

    machineFormat(value) {
      let oldValue = value;
      if (value) {
        value = this.clearNumber(value);
        // Ajustar quantidade de zeros à esquerda
        value = value.padStart(parseInt(this.options.precision) + 1, "0");
        // Incluir ponto na casa correta, conforme a precisão configurada
        value =
          value.substring(0, value.length - parseInt(this.options.precision)) +
          "." +
          value.substring(
            value.length - parseInt(this.options.precision),
            value.length
          );
        if (value === "") {
          value = this.options.empty;
        }
      } else {
        value = this.options.empty;
      }
      if (oldValue.includes("-", 0)) {
        value = value * -1;
      }
      return parseFloat(value);
    },

    // Retira todos os caracteres não numéricos e zeros à esquerda
    clearNumber: function (value) {
      let result = "";
      if (value) {
        let flag = false;
        let arrayValue = value.toString().split("");
        for (let i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              // Retirar zeros à esquerda
              if (arrayValue[i] !== "0") {
                result = result + arrayValue[i];
                flag = true;
              } else {
                // Permitir zero quando valor igual a zero - Tipo 3 (Money or Percent)
                if (Number(value) === 0) {
                  result = result + arrayValue[i];
                }
              }
            } else {
              result = result + arrayValue[i];
            }
          }
        }
      }
      return result;
    },

    keyPress($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    isInteger(value) {
      let result = false;
      if (Number.isInteger(parseInt(value))) {
        result = true;
      }
      return result;
    },

    focus() {
      this.$refs.ref.focus();
    },
  },
};
</script>
<style>
.right-aligned-input >>> input {
  text-align: right;
}
</style>
