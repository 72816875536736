<template>
  <v-btn
    :disabled="disabled"
    id="top-expedition-make-bill"
    @click="getElementById('launch-interventions').click()"
    class="text-white-color mr-4 mt-0"
    style="order: 15"
    color="primary"
  >
    {{ $t("tyre_hotel.interventions") }}
    <v-icon class="ml-2" style="color: white">mdi-clipboard-text-clock</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "interventionsButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
