<template>
  <v-combobox
    presentation="off"
    :items="formattedEmployees"
    :label="formattedLabel"
    item-text="text"
    :multiple="multiple"
    v-model="selectedEmployee"
    @update:search-input="searchEmployees"
    :loading="searching"
    class="to-upper"
    clearable
  >
  </v-combobox>
</template>
<script>
import clockingMixin from "@/mixins/clockingMixin";
export default {
  name: "CompanyEmployeesInput",
  emits: ["input"],
  mixins: [clockingMixin],
  props: {
    value: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      default: null,
    },
    shifts_only: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      employees: [],
      selectedEmployee: this.value,
      searching: false,
    };
  },
  computed: {
    formattedEmployees() {
      return this.employees
        .filter((i) => {
          if (!this.shifts_only) return true;

          return parseInt(i.active_contract?.contract?.is_working_in_shifts);
        })
        .map((employee) => {
          return {
            text: employee.name,
            value: employee.id,
          };
        });
    },
    formattedLabel() {
      return this.multiple
        ? this.translate("select_employees")
        : this.translate("select_employee");
    },
  },
  watch: {
    selectedEmployee: {
      handler() {
        this.$emit("input", this.selectedEmployee);
      },
      deep: true,
    },
    value: {
      handler() {
        this.selectedEmployee = this.value;
      },
      deep: true,
    },
  },
  methods: {
    async searchEmployees(search) {
      if (this.formattedEmployees.find((e) => e.text === search)) {
        return;
      }
      // cancel pending call
      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(async () => {
        this.searching = true;
        this.employees = await this.getEmployees(search, this.shifts_only);
        this.searching = false;
      }, 1500);
    },
  },
};
</script>
