var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"600"},on:{"click:outside":function($event){return _vm.falseButtonClicked()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.falseButtonClicked()}},model:{value:(_vm.internalShowDialog),callback:function ($$v) {_vm.internalShowDialog=$$v},expression:"internalShowDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-title"},[_vm._v(" "+_vm._s(_vm.$t("min_article_stock_title"))+" ")]),_c('v-card-text',{staticClass:"dialog-text text-content",attrs:{"color":"black"}},[_c('v-row',{staticClass:"mb-0"},[_c('v-col',[_c('custom-money-input',{ref:"min_alert_stock",staticClass:"mt-4",attrs:{"label":_vm.$t('min_stock_for_alert_keys.text_field_title'),"properties":{
              prefix: _vm.currencySymbol,
              readonly: false,
              disabled: false,
              outlined: false,
              placeholder: _vm.getDecimalNumber() == 2 ? '0.00' : '0.000',
              dense: true,
              hideDetails: 'auto',
            },"options":{
              locale: _vm.userLanguageId,
              length: 12,
              precision: _vm.defaultQuantityDecimals,
              empty: _vm.getDecimalNumber() == 2 ? 0.0 : 0.0,
            }},model:{value:(_vm.min_alert_stock),callback:function ($$v) {_vm.min_alert_stock=$$v},expression:"min_alert_stock"}})],1),_c('v-col',[_c('v-checkbox',_vm._g({staticClass:"shrink pa-0 mt-5",attrs:{"color":"primary","label":_vm.$t('min_stock_for_alert_keys.is_favorite')},model:{value:(_vm.is_favorite),callback:function ($$v) {_vm.is_favorite=$$v},expression:"is_favorite"}},_vm.on))],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-4",attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.falseButtonClicked()}}},[_vm._v(" "+_vm._s(_vm.$t("nav_buttons.cancel"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1),_c('v-btn',{staticClass:"px-4",attrs:{"color":'success'},on:{"click":function($event){return _vm.trueButtonClicked()}}},[_vm._v(" "+_vm._s(_vm.$t("nav_buttons.save"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }