<template>
  <v-dialog :value="value" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t("send_pdf_via_email.title") }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <span class="mx-auto" v-show="loadingClients">
              {{ $t("send_pdf_via_email.loading_clients") }}...
            </span>
            <v-progress-linear
              v-show="loadingClients"
              indeterminate
              color="blue"
            ></v-progress-linear>
          </v-col>
          <v-col :md="6">
            <v-select
              v-model="formState.sender"
              v-if="!loadingClients"
              :items="senderEMails"
              :label="$t('send_pdf_via_email.from')"
              clearable
              @click:clear="formState.sender = null"
            ></v-select>
          </v-col>
          <v-col :md="6">
            <v-combobox
              ref="clientBox"
              v-model="selectedClients"
              v-if="!loadingClients"
              :items="resolvedClientsForDropdown"
              @change="fixListOfResolvedClientsForDropdown()"
              :label="$t('send_pdf_via_email.to')"
              clearable
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <span>
                  <template v-if="index === 0 && selectedClients.length == 1">
                    <span class="mr-2">{{ item.email.toUpperCase() }}</span>
                  </template>
                  <template v-if="index === 0 && selectedClients.length > 1">
                    <span class="mr-2"
                      >{{ item.email.toUpperCase() }} (+{{
                        selectedClients.length - 1
                      }})</span
                    >
                  </template>
                </span>
              </template>

              <template #append-outer v-if="checkTag('address_book')">
                <AddressBookSelector
                  v-model="addressBookSelectorDialog"
                  @selected="(addressBook) => addEmailToClientList(addressBook)"
                />
                <v-btn
                  icon
                  @click="
                    () => {
                      addressBookSelectorDialog = false;
                      $nextTick(() => (addressBookSelectorDialog = true));
                    }
                  "
                >
                  <v-icon color="primary" class="mb-2"
                    >mdi-book-open-page-variant</v-icon
                  >
                </v-btn>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row v-if="playersWithoutEmail.length > 0" class="mx-1">
          <div
            :style="{
              border: '1px solid orange',
              color: 'orange',
              padding: '5px',
              fontWeight: 'bolder',
              borderRadius: '5px',
              width: '100%',
              marginBottom: '5px',
            }"
          >
            <v-row>
              <v-col cols="1">
                <v-icon color="orange" class="mr-1">
                  mdi-alert-circle-outline
                </v-icon>
              </v-col>
              <v-col cols="11">
                <span
                  v-for="(item, index) in playersWithoutEmail"
                  :key="item.id"
                >
                  {{ extractName(item) }}
                  {{ $t("send_pdf_via_email.name_of_players_without_email") }}
                  <span v-if="index !== playersWithoutEmail.length - 1">
                    , &nbsp;
                  </span>
                </span>
              </v-col>
            </v-row>
          </div>
        </v-row>

        <v-row class="mx-1">
          <v-select
            :items="translatedDocuments"
            item-text="text"
            item-value="value"
            v-model="formState.documents"
            :label="$t('send_pdf_via_email.select_document')"
            multiple
            chips
          >
          </v-select>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-select
              :items="messages"
              item-text="subject"
              :label="$t('message')"
              v-model="formState.content"
              @change="updateEmailSignature"
              :show-label="false"
              return-object
            />
          </v-col>
          <v-col cols="2">
            <v-select
              :items="languages"
              item-text="text"
              item-value="value"
              label="Language"
              v-model="formState.selectedLanguage"
              :show-label="false"
            />
          </v-col>
        </v-row>
        <!-- Message -->
        <v-row>
          <v-col id="email_pdf" cols="12" sm="12">
            <NoteEditor
              :label="$t('scheduler.note')"
              v-model="formState.content.message"
              :showLabel="false"
            />
          </v-col>
          <!-- Email Signature -->
          <v-col id="email_pdf_signature" class="mt-n6" cols="12" sm="12">
            <NoteEditor
              v-if="resetNoteEditorToLoadSignatureDataAgain"
              :label="$t('email_signature')"
              v-model="formState.content.email_signature"
              :showLabel="false"
              :rows="2"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn color="grey-lighten-2" @click="close()" :disabled="sending">
          {{ $t("cancel") }}
          <v-icon dark right> mdi-keyboard-return</v-icon>
        </v-btn>
        <v-btn
          color="green"
          :dark="!sending"
          @click="sendEmailHandler"
          :disabled="sending"
          :loading="sending"
        >
          {{ $t("send") }}
          <v-icon dark right> mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import NoteEditor from "@/components/NoteEditor.vue";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import AddressBookSelector from "@/components/AddressBook/AddressBookSelector.vue";

export default {
  name: "SendPdfViaEmail",
  emits: ["close", "emailSent"],
  refs: ["clientBox"],
  components: { AddressBookSelector, NoteEditor },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
    },
    parentModel: {
      type: Object,
      default: null,
    },
    target: {
      type: String,
      required: true,
    },
    depot: {
      type: Array,
    },
    moduleId: {
      type: Number,
      default: null,
    },
  },
  deactivated() {
    this.$store.commit("setPdfMailConfig", this.defaultPdfEmailConfig);
    this.close();
  },
  data() {
    return {
      resetNoteEditorToLoadSignatureDataAgain: true,
      selectedClients: [],
      sending: false,
      loadingClients: true,
      customEmail: "",
      senderEMails: [],
      formState: {
        email: "",
        subject: "",
        sender: "",
        selectedLanguage: null,
        documents: [],
        clients: [],
        members: [],
        member: null,
        client: null,
        content: {
          subject: null,
          message: "",
          email_signature: "",
        },
      },
      resolvedClients: [],
      clientMembers: {},
      playersWithoutEmail: [],
      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      applicableDocuments: [],
      translations: [],
      attachments: [],
      addressBookSelectorDialog: false,
    };
  },
  computed: {
    resolvedClientsForDropdown() {
      let seenEmails = new Set();

      return this.resolvedClients
        .map((item) => {
          if (typeof item == "object") {
            item.text =
              (item.name ? this.extractName(item) + " - " : "") +
              item.email.toUpperCase() +
              (item.player_title ? "(" + item.player_title + ")" : "");
          }
          return item;
        })
        .filter((item) => {
          if (seenEmails.has(item.email.toLowerCase())) {
            return false;
          } else {
            seenEmails.add(item.email.toLowerCase());
            return true;
          }
        });
    },
    mailConfig() {
      return this.$store.state.pdfMailConfig[this.target];
    },
    players() {
      return this.mailConfig?.players ?? [];
    },
    documents() {
      return this.mailConfig?.documents ?? [];
    },
    translatedDocuments() {
      return this.translateDocuments();
    },
    messages() {
      if (this.translations.length === 0) return;
      return this.getMessagesFromTranslation(this.formState.selectedLanguage);
    },
    companyLanguages() {
      return this.$store.state.companyLanguages;
    },
    languages() {
      return this.companyLanguages.map((item) => {
        return {
          text: this.$t("languages." + item.language_id),
          value: item.language_id,
        };
      });
    },
    computedAttachmentModule() {
      let dataFromLocalStorage = JSON.parse(localStorage.getItem("data"));

      let applicationResource = dataFromLocalStorage.applicationResources.find(
        (i) => i.application_resource.key === this.target
      );

      return dataFromLocalStorage.attachmentModules.find(
        (i) =>
          i.application_resource_id ===
          applicationResource.application_resource_id
      );
    },
  },
  async mounted() {
    this.$store.commit("setPdfMailConfig", this.defaultPdfEmailConfig);
    this.computeSenderEmails();
    await this.resolvePlayers();
    this.setApplicableDocuments();
    await this.getMessageTranslations();

    if (this.companyLanguages.length > 0) {
      this.formState.selectedLanguage =
        this.companyLanguages.find((item) => item.is_default === 1)
          ?.language_id ?? "fr";
    }
  },
  watch: {
    "formState.sender": {
      handler: function () {
        this.updateEmailSignature();
      },
      immediate: true,
    },
    "formState.content.email_signature"() {},
    model(val) {
      if (!val) return;
      // Set Applicable Documents
      this.setApplicableDocuments();
      // reset resolved players list
      this.resolvePlayers();
    },
    selectedClients(items) {
      this.formState.clients = [];
      this.formState.members = [];
      if (items && items.length > 0) {
        let seenEmails = new Set();
        let ifAnyDuplicateFound = false;
        let newClientList = this.selectedClients.filter((item) => {
          if (seenEmails.has(item.email.toLowerCase())) {
            ifAnyDuplicateFound = true;
            return false;
          } else {
            seenEmails.add(item.email.toLowerCase());
            return true;
          }
        });
        if (ifAnyDuplicateFound) {
          this.selectedClients = newClientList;
          return;
        }

        items.forEach((item) => {
          if (
            item &&
            typeof item === "object" &&
            item.id &&
            !("representative_company_id" in item) &&
            !("is_work_point" in item)
          ) {
            this.formState.clients.push(item.id);
          } else if (
            item &&
            typeof item === "object" &&
            item.id &&
            "representative_company_id" in item
          ) {
            this.formState.clients.push(item.id);
          } else if (
            item &&
            typeof item === "object" &&
            item.id &&
            "is_work_point" in item &&
            item.is_work_point == 1
          ) {
            this.formState.clients.push(item.email.toUpperCase());
          } else if (item) {
            this.formState.clients.push(item.email.toUpperCase());
          } else if (!item) {
            //
          }
        });
      }
    },
    companyLanguages(val) {
      this.formState.selectedLanguage =
        val.find((item) => item.is_default === 1)?.language_id ?? "fr";
    },
    value: {
      handler: function (val) {
        if (!val) {
          this.resetFormState();
        }

        this.computeSenderEmails();
        // Get Related Bank RIB Attachment
        this.getBankRIBAttachment(this.model);
        // Get Related Model Attachments
        this.getAttachments(this.model.id);

        // In Expedition Module : Do Not Fetch Expedition Attachments
        //   if (this.target === 'expedition') {
        //     this.getAttachments(this.model.id);
        //   }

        // if (this.target === 'expenses') {
        //   this.getAttachments(this.model.id);
        // }

        // if (this.target === 'billing') {
        //   // Load Attachment of Billing
        //   this.getAttachments(this.model.id);

        // Load Attachments for Expeditions only if billing page is opened
        // if(this.model.details && this.model.details.length > 0){
        //     this.model.details.forEach((detail) => {
        //         if(detail && detail.expedition_id){
        //             this.getAttachments(detail.expedition_id);
        //         }
        //     })
        // }
        // }

        if (this.isset(this.parentModel?.expedition_id)) {
          this.getAttachments(this.parentModel.expedition_id);
        }

        this.resolvePlayers();
      },
      immediate: true,
    },
    "formState.selectedLanguage"(val, oldVal) {
      this.updateEmailSignature();
      let selectedMessageIndex = this.getMessagesFromTranslation(
        oldVal
      ).findIndex((m) => m.subject === this.formState.content.subject);
      if (
        selectedMessageIndex === -1 ||
        (!selectedMessageIndex && selectedMessageIndex != 0)
      ) {
        return false;
      }
      this.formState.content.message =
        this.messages[selectedMessageIndex]?.message ?? "";
    },
  },
  methods: {
    selectOwnerClientByDefault(forceAtLeastOne = false) {
      this.resolvedClients.forEach((element) => {
        if (
          element.player_title &&
          element.player_title.includes(
            this.$t("send_pdf_via_email.players.owner")
          )
        ) {
          this.selectedClients = [element];
        }
      });
      if (forceAtLeastOne && this.selectedClients.length == 0) {
        this.selectedClients = [this.resolvedClients[0]];
      }
    },
    fixListOfResolvedClientsForDropdown() {
      const newClients = [];
      new Set();
      this.selectedClients.forEach((client) => {
        if (
          !this.resolvedClientsForDropdown.includes(client) &&
          typeof client == "string"
        ) {
          const clientObject = { email: client, text: client };
          newClients.push(clientObject);
          this.resolvedClients.push(clientObject);
        }
      });
      this.selectedClients = this.selectedClients.map((client) =>
        typeof client === "string"
          ? newClients.find((nc) => nc.email === client)
          : client
      );
    },
    resetMailSignatureShowStatus() {
      this.resetNoteEditorToLoadSignatureDataAgain = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.resetNoteEditorToLoadSignatureDataAgain = true;
        }, 10);
      });
    },
    async updateEmailSignature() {
      let loginData = JSON.parse(localStorage.getItem("data"));
      let userCompanyEmail = (
        loginData.companyProperties.find(
          (i) => i.company_setting_key === "SEND_MAIL_FROM_ADDRESS"
        )?.value ?? ""
      ).toUpperCase();
      let userCompanyEmailSignature = loginData.companyProperties.find(
        (i) => i.company_setting_key === "COMPANY_EMAIL_SIGNATURE"
      )?.value;

      if (this.formState.sender == userCompanyEmail) {
        // Make Signature from Company Properties
        // let companyEmailSignatureObject = await this.getEmailSignatureOfCompany(userCompanyEmailSignature);
        // if(companyEmailSignatureObject && companyEmailSignatureObject.length > 0 && this.formState?.selectedLanguage){
        //     let signature = companyEmailSignatureObject[0][this.formState.selectedLanguage];
        //     this.formState.content.email_signature = signature;
        // }
        this.formState.content.email_signature =
          userCompanyEmailSignature ?? "";
      } else {
        // Make Signature from Current User's Signature
        this.formState.content.email_signature =
          this.currentUser.email_signature ?? "";

        // If user does not have its signature then we have to put the company's default signature
        if (!this.formState.content.email_signature) {
          this.formState.content.email_signature =
            userCompanyEmailSignature ?? "";
        }
      }
      this.resetMailSignatureShowStatus();
    },
    async getEmailSignatureOfCompany(userCompanyEmailSignature) {
      let translationKey = userCompanyEmailSignature;
      if (!localStorage.getItem("COMPANY_MAIL_SIGNATURE")) {
        await axios
          .get(API_BASE_URL + "/translations?key=" + translationKey)
          .then((res) => {
            let data = res.data.data;
            localStorage.setItem(
              "COMPANY_MAIL_SIGNATURE",
              JSON.stringify(data)
            );
          });
      }
      return JSON.parse(localStorage.getItem("COMPANY_MAIL_SIGNATURE"));
    },
    getBankRIBAttachment(model) {
      let defaultSelectedBankAccountId = this.$store.state.topBankAccount
        ? typeof this.$store.state.topBankAccount == "object"
          ? this.$store.state.topBankAccount.id
          : this.$store.state.topBankAccount
        : null;
      let bank_id =
        model && typeof model == "object"
          ? model.bank_id
          : defaultSelectedBankAccountId;

      let companyBankAttachments = JSON.parse(
        localStorage.getItem("bankAttachments")
      );
      if (companyBankAttachments && companyBankAttachments.length > 0) {
        companyBankAttachments.forEach((bankAttachment) => {
          if (bankAttachment.bank_id == bank_id) {
            bankAttachment.attachment_id = bankAttachment.id;
            this.attachments.push(bankAttachment);
          }
        });
      }
    },
    handleClientInput(e) {
      console.log(e);
    },
    setApplicableDocuments() {
      // Get Applicable Documents for Billing
      if (this.target === "billing") {
        this.documents.forEach((doc) => {
          if (doc === "depot_report") {
            if (this.depot && this.depot.length > 0) {
              this.applicableDocuments.push(doc);
            }
          } else if (doc === "worksheet" || doc === "work_report") {
            let workSheetActivated = this.isset(
              JSON.parse(localStorage.getItem("applicationResources")).find(
                (i) => i === "worksheet_report"
              )
            );

            let vehicleIsAttachedToModel = this.isset(this.model?.vehicle_id);

            if (workSheetActivated && vehicleIsAttachedToModel)
              this.applicableDocuments.push(doc);
          } else if (
            doc === "expedition_report" &&
            this.isset(this.parentModel)
          ) {
            if (this.isset(this.parentModel?.expedition_id))
              this.applicableDocuments.push(doc);
          } else {
            if (Array.isArray(doc)) {
              this.applicableDocuments = [...this.applicableDocuments, ...doc];
            } else {
              if (this.model && this.model.type && this.model.type.key === doc)
                this.applicableDocuments.push(doc);
            }
          }
        });
      }

      // Get Applicable Documents for Expenses
      if (this.target === "expenses") {
        //
      }
      // Get Applicable Documents for Expeditions
      if (this.target === "expedition") {
        this.applicableDocuments = this.documents;
        // In Expedition Do not show Bill/PurchaseOrder/Estimation/CreditNote because those documents are of Client
        // if(this.isset(this.model?.order?.type?.key)) this.applicableDocuments.push(this.model.order.type.key)
      }
    },
    extractName(item) {
      if (this.target === "billing") {
        return item.full_name ?? item.name;
      } else if (this.target === "expenses") {
        return item.full_name ?? item.name;
      } else if (this.target === "expedition") {
        return item.name;
      } else {
        return "";
      }
    },
    close() {
      this.$emit("input", false);
    },
    getMessagesFromTranslation(language) {
      return this.translations
        .map((item) => {
          return {
            message: item[language],
            subject: item.key.replaceAll("_", " "),
          };
        })
        .filter((item) => item !== null);
    },
    async resolveLeasingCompany() {
      if (!this.model) return;

      let client = this.model.client;
      client.player_title = this.$t("send_pdf_via_email.players.owner");
      await this.handleClient(client);
    },
    async handleClient(client) {
      if (this.isset(client.representative_company_id)) {
        let clientMembers = await this.getClientMembers(
          client.representative_company_id
        );
        if (clientMembers && clientMembers.length > 0) {
          clientMembers.forEach((member) => {
            this.resolvedClients.push(member);
          });
        }
      }
      if (this.isset(client.email)) {
        if (!this.resolvedClients.find((item) => item.id === client.id)) {
          if (client.email_2) {
            const clientObject = {
              email: client.email_2,
              text: client.contact_name_2,
              name: client.contact_name_2,
              player_title: this.$t(
                "send_pdf_via_email.players.contact_person_2"
              ),
            };
            this.resolvedClients.push(clientObject);
          }
          if (client.email_3) {
            const clientObject = {
              email: client.email_3,
              text: client.contact_name_3,
              name: client.contact_name_3,
              player_title: this.$t(
                "send_pdf_via_email.players.contact_person_3"
              ),
            };
            this.resolvedClients.push(clientObject);
          }
          this.resolvedClients.push(client);
        }
      } else {
        if (!this.playersWithoutEmail.find((item) => item.id === client.id))
          this.playersWithoutEmail.push(client);
      }

      if (this.resolvedClients && this.resolvedClients.length > 0) {
        this.formState.clients = [this.resolvedClients[0]?.id];
      } else {
        if (this.target === "expedition") {
          this.formState.clients = 0;
        }
      }
    },
    async handleWorkPoints(workpoints) {
      if (workpoints && typeof workpoints == "object") {
        workpoints.forEach((element) => {
          if (element.email) {
            element.is_work_point = 1;
            element.player_title = this.$t(
              "send_pdf_via_email.players.working_points"
            );
            this.resolvedClients.push(element);
          }
        });
      }
    },
    async resolveCompanyThatRentsCar() {
      if (!this.model) {
        return;
      }
      let clientId = this.model?.vehicle?.client_id ?? null;
      if (!clientId) {
        return;
      }

      let client = await this.getClient(clientId);
      client.player_title = this.$t("send_pdf_via_email.players.owner");
      await this.handleClient(client);
    },
    async resolveCarDriver() {
      let clientId = this.model?.vehicle?.driver_id ?? null;
      if (!clientId) {
        return;
      }

      let client = await this.getClient(clientId);
      client.player_title = this.$t("send_pdf_via_email.players.first_driver");
      await this.handleClient(client);
    },
    async resolveCarSecondDriver() {
      let clientId = this.model?.vehicle?.second_driver_id ?? null;
      if (!clientId) {
        return;
      }

      let client = await this.getClient(clientId);
      client.player_title = this.$t("send_pdf_via_email.players.second_driver");
      await this.handleClient(client);
    },
    async resolveExpeditionCompany(target, isCompany = true) {
      if (!this.model) {
        return null;
      }
      let expeditorCompanyId = this.model[target] ?? null;
      if (!expeditorCompanyId) {
        return null;
      }

      let company = null;
      let workpoints = null;
      if (isCompany) {
        company = await this.getCompany(expeditorCompanyId);
        workpoints = await this.getCompanyWorkPoints(expeditorCompanyId);
      } else {
        company = await this.getClient(expeditorCompanyId);
      }
      let translationRoot = "";
      if (target === "expeditor_id") {
        translationRoot = "expeditor";
      } else if (target === "expeditor_contact_id") {
        translationRoot = "expeditor_contact";
      } else if (target === "transporter") {
        translationRoot = "transporter";
      } else if (target === "transporter_contact_id") {
        translationRoot = "transporter_contact";
      } else {
        translationRoot = "transporter";
      }
      company.player_title = this.$t(
        "send_pdf_via_email.players." + translationRoot
      );
      await this.handleClient(company);
      if (workpoints) {
        await this.handleWorkPoints(workpoints);
      }
    },
    getClientMembers(clientId) {
      return new Promise((resolve) => {
        this.getClientMembersCache(clientId).then((members) => {
          resolve(members);
        });
      });
    },
    getClient(clientId) {
      return new Promise((resolve) => {
        return axios
          .get(API_BASE_URL + "/clients/" + clientId, {
            headers: this.header,
          })
          .then((res) => {
            resolve(res.data.data);
          });
      });
    },
    getCompanyWorkPoints(companyId) {
      return new Promise((resolve) => {
        return axios
          .get(API_BASE_URL + "/work_points?id=" + companyId, {
            headers: this.header,
          })
          .then((res) => {
            resolve(res.data.data);
          });
      });
    },
    getCompany(companyId) {
      return new Promise((resolve) => {
        return axios
          .get(API_BASE_URL + "/companies/" + companyId, {
            headers: this.header,
          })
          .then((res) => {
            resolve(res.data.data);
          });
      });
    },
    getMessageTranslations() {
      return new Promise((resolve) => {
        return axios
          .get(
            API_BASE_URL +
              "/translations?application_resource_key=" +
              this.target,
            {
              headers: this.header,
            }
          )
          .then((res) => {
            this.translations = res.data.data;
            resolve(res.data.data);
          });
      });
    },
    sendEmailHandler() {
      // Let the dropdown input set the typed values
      this.$nextTick(() => {
        setTimeout(() => {
          if (!this.isset(this.formState.sender)) {
            return this.$toast.error(
              this.$t("send_pdf_via_email.error_messages.select_sender")
            );
          }

          if (!this.formState.clients && !this.formState.members) {
            return this.$toast.error(
              this.$t("send_pdf_via_email.error_messages.select_client")
            );
          }

          if (this.formState.documents.length === 0) {
            return this.$toast.error(
              this.$t("send_pdf_via_email.error_messages.select_document")
            );
          }

          if (!this.formState.content.subject) {
            this.formState.content.subject = this.$t(
              "send_pdf_via_email.default_subject"
            );
          }

          if (!this.formState.content.message) {
            return this.$toast.error(
              this.$t("send_pdf_via_email.error_messages.enter_message")
            );
          }

          let data = {
            model_id: this.model.id,
            secondary_model_id:
              this.target === "expedition"
                ? this.model.order_id
                : this.parentModel?.expedition_id,
            model_type: this.target,
            clients: this.formState.clients ?? null,
            members: this.formState.members ?? null,
            documents: Array.isArray(this.formState.documents)
              ? this.formState.documents
              : [this.formState.documents],
            subject:
              this.formState.content.subject +
              (this.target == "expedition"
                ? " : " + this.model.identifier
                : ""),
            message:
              this.formState.content.message +
              (this.formState.content.email_signature ?? ""),
            depot: this.depot && this.depot.length > 0 ? this.depot[0] : null,
            sender_email: this.formState.sender,
          };

          this.$store.commit("setLoading", true);
          this.sending = true;
          axios
            .post(
              API_BASE_URL + "/billings/" + this.model.id + "/email_documents",
              data,
              {
                headers: this.header,
              }
            )
            .finally(() => {
              this.$store.commit("setLoading", false);
              this.sending = false;
            })
            .then(() => {
              this.$toast.success(this.$t("send_pdf_via_email.success"));
              this.$emit("emailSent");
              this.close();
            })
            .catch(() => {
              this.$toast.error(
                this.$t("send_pdf_via_email.error_messages.general_error")
              );
            });
        }, 50);
      });
    },
    getAttachments(resourceKeyId) {
      return new Promise((resolve) => {
        console.error("attachment_module", this.computedAttachmentModule);
        axios
          .get(
            API_BASE_URL +
              `/attachments?module_id=${
                this.moduleId ?? this.computedAttachmentModule.id
              }&resource_key_id=${resourceKeyId}`,
            {
              headers: this.header,
            }
          )
          .then(({ data }) => {
            this.attachments = this.attachments.concat(data.data.result);
            resolve(data.data.result);
          })
          .finally(() => {});
      });
    },
    resetFormState() {
      this.formState = {
        email: "",
        subject: "",
        selectedLanguage: "en",
        attachments: [],
        documents: [],
        clients: [],
        members: [],
        content: {
          subject: null,
          message: "",
          email_signature: "",
        },
      };

      this.selectedClients = [];
      this.resolvedClients = [];
      this.clientMembers = {};
      this.playersWithoutEmail = [];
    },
    resolvePlayers() {
      this.resolvedClients = [];
      this.loadingClients = true;

      if (this.target === "expedition") {
        return Promise.all([
          this.resolveExpeditionCompany("transporter_id"),
          this.resolveExpeditionCompany("transporter_contact_id", false),
        ]).then(() => {
          this.loadingClients = false;
          // this.selectedClients = this.resolvedClients ?? [];
          this.selectOwnerClientByDefault(true);
        });
      }

      this.players.forEach(async (player) => {
        if (player === "client" && !this.model.vehicle) {
          await this.resolveLeasingCompany();
        } else if (player === "driver") {
          let resolveCompany = await this.resolveCompanyThatRentsCar();
          let resolveDriver_1 = await this.resolveCarDriver();
          let resolveDriver_2 = await this.resolveCarSecondDriver();
          Promise.all([resolveCompany, resolveDriver_1, resolveDriver_2]).then(
            () => {
              this.loadingClients = false;
              // this.selectedClients = this.resolvedClients ?? [];
            }
          );
        }

        if (
          player === "client" &&
          this.model.client.representative_company_id
        ) {
          let workpoints = await this.getCompanyWorkPoints(
            this.model.client.representative_company_id
          );
          if (workpoints) {
            await this.handleWorkPoints(workpoints);
          }
        }
        this.selectOwnerClientByDefault();
      });
    },
    computeSenderEmails() {
      try {
        let loginData = JSON.parse(localStorage.getItem("data"));
        this.senderEMails = [
          loginData.userDetails.email,
          loginData.companyProperties.find(
            (i) => i.company_setting_key === "SEND_MAIL_FROM_ADDRESS"
          ).value,
        ].map((i) => i.toUpperCase());

        if (this.isset(this.senderEMails)) {
          this.formState.sender = this.senderEMails[0];
        }
      } catch (e) {
        //
      }
    },
    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    translateDocuments() {
      let docs = this.applicableDocuments.map((item) => {
        return {
          text: this.$t("send_pdf_via_email.documents." + item),
          value: item,
        };
      });

      this.attachments.forEach((attachment) => {
        if (attachment.description.toLowerCase() === "bill") {
          // do not show the attachemnt for expeditions
          return;
        }
        // In Expedition Module : We do not show ORDER_OF_TRANSPORT(It is a document of Client) to Transporterer
        if (this.target === "expedition") {
          if (
            attachment.description.toLowerCase() ===
            "ORDER_OF_TRANSPORT".toLowerCase()
          ) {
            // do not show the attachemnt for expeditions
            return;
          }
        }
        docs.push({
          text: attachment.title,
          value: attachment.attachment_id
            ? "attachment_" + attachment.id
            : attachment.id, // If the attachment is from Attachment Module then modify value as => 'attachment'+_id else just id
          original_name: attachment?.original_name,
          type: "attachment",
          resource_key_id: attachment.resource_key_id,
          reference_resource_key_id: attachment.reference_resource_key_id,
        });
      });
      docs = this.removeDuplicates(docs);
      return docs;
    },
    removeDuplicates(docs) {
      const seen = new Set();
      const uniqueDocs = [];

      docs.forEach((doc) => {
        const pair = `${doc.resource_key_id}-${doc.reference_resource_key_id}-${doc.text}-${doc?.original_name}`;
        if (!seen.has(pair)) {
          seen.add(pair);
          uniqueDocs.push(doc);
        }
      });

      return uniqueDocs;
    },
    addEmailToClientList(addressBook) {
      if (
        addressBook &&
        !this.selectedClients.find((item) => item.email === addressBook.email)
      ) {
        this.resolvedClients.push({
          email: addressBook.email,
          text: addressBook.email,
        });

        this.selectedClients.push(addressBook.email.toUpperCase());

        this.fixListOfResolvedClientsForDropdown();
      }
    },
  },
};
</script>
<style scoped>
.v-select__selections >>> input {
  text-transform: uppercase;
}

#email_pdf >>> .editr {
  min-height: 250px !important;
  max-height: 250px !important;
}

#email_pdf >>> .editr--content {
  min-height: 240px !important;
  max-height: 240px !important;
  overflow-y: scroll !important;
}

#email_pdf_signature >>> .editr {
  min-height: 180px !important;
  max-height: 180px !important;
  border-top: 0px !important;
}

#email_pdf_signature >>> .editr--content {
  min-height: 140px !important;
  max-height: 140px !important;
  overflow-y: scroll !important;
}

#email_pdf_signature >>> .editr--toolbar {
  display: none;
}
</style>
