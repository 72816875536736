<template>
  <div>
    <v-dialog
      :value="value"
      max-width="1500px"
      scrollable
      :persistent="loading"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ translate("bulk_operation_detail") }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :sm="5">
              <v-col cols="12">
                <v-text-field
                  v-model="depotManager"
                  :label="translate('depot_manager')"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="entries"
                  label="Requested"
                  rows="10"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-col>
            <v-col :sm="7">
              <v-col
                cols="12"
                v-if="
                  depotBulkOperation && depotBulkOperation.errors.length > 0
                "
              >
                <v-card-title style="text-decoration: underline">
                  <!--                    <span class="mr-2"> <v-icon @click="toggleErrorTableView">mdi-eye</v-icon></span>-->
                  Errors
                </v-card-title>
                <v-list v-if="showErrorList">
                  <v-list-item-group>
                    <v-list-item
                      v-for="(error, index) in depotBulkOperation.errors"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ error }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span @click="close" class="mr-3"
            ><CloseButton :disabled="loading"
          /></span>
          <span @click="processBulkData"
            ><SaveButton :loading="loading"
          /></span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  name: "DepotBulkOperationDetail",
  components: { SaveButton, CloseButton },
  emits: ["input", "updated"],
  props: {
    depotBulkOperation: {
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      axiosOptions: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      entries: "",
      depotManager: null,
      showErrorList: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.progressBarLoading;
    },
  },
  watch: {
    value() {
      if (!this.value || !this.depotBulkOperation) {
        this.entries = "";
        this.depotManager = "";

        if (this.$router.currentRoute.name !== "TyreHotelBulkOperation") {
          return this.$router.push({ name: "TyreHotelBulkOperation" });
        }
      }

      // convert requested multiple array to multiple line string
      this.entries = this.depotBulkOperation.requested
        .map((entry) => entry.join(", "))
        .join("\n");
      this.depotManager = this.depotBulkOperation.depot_manager;
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    translate(key) {
      return this.$t("tyre_hotel.bulk." + key);
    },
    toggleErrorTableView() {
      this.showErrorList = !this.showErrorList;
    },
    processBulkData() {
      axios
        .post(
          API_BASE_URL + "/depots/bulk_operations",
          {
            data: this.entries,
            date: this.depotBulkOperation.requested_for,
            depot_manager: this.depotManager,
          },
          { headers: this.axiosOptions }
        )
        .finally(() => {})
        .then((data) => {
          this.processedBulkData = data.data;
          this.close();
          this.$emit(
            "updated",
            this.processedBulkData.data.depot_bulk_operation
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>
