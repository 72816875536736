<template>
  <v-row>
    <v-col>
      <v-container fluid class="mt-4">
        <v-row>
          <v-spacer></v-spacer>
        </v-row>
        <!-- Address Row -->
        <v-row>
          <AddressInput
            v-model="address"
            :label="$t('address')"
            :disabled="!isAdmin"
          />
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import AddressInput from "@/components/AddressInput";
export default {
  mixins: [validationMixin],
  name: "AddressTab",
  props: {
    prop_address: [Object],
    prop_shipping_address: [Object],
  },
  components: {
    AddressInput,
  },
  validations: {
    zipcode: {
      required: requiredIf(function () {
        return this.address;
      }),
    },
    city: {
      required: requiredIf(function () {
        return this.address;
      }),
    },
    country: {
      required: requiredIf(function () {
        return this.address;
      }),
    },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      autocompleteValueLocal: "off",

      isDebounceCancelled: false,
      addEditAddressModel: false,

      fetchedAddresses: [],
      fetchedShippingAddresses: [],

      isLoadingSearchAddress: false,
      isLoadingSearchCity: false,
      isLoadingSearchCounty: false,
      isLoadingSearchCountry: false,

      isLoadingSearchShippingAddress: false,
      isLoadingSearchShippingCity: false,
      isLoadingSearchShippingCounty: false,
      isLoadingSearchShippingCountry: false,

      zipcode: null,
      address: null,
      search_address: null,
      cities: [],
      city: null,
      search_city: null,
      counties: [],
      county: null,
      search_county: null,
      countries: [],
      country: null,
      search_country: null,

      shipping_zipcode: null,
      shipping_address: null,
      search_shipping_address: null,
      shipping_cities: [],
      shipping_city: null,
      search_shipping_city: null,
      shipping_counties: [],
      shipping_county: null,
      search_shipping_county: null,
      shipping_countries: [],
      shipping_country: null,
      shipping_search_country: null,

      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      token: this.$store.state.AccessToken,
    };
  },

  created() {
    this.header = { Authorization: "Bearer " + this.token };
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  mounted() {
    this.header = { Authorization: "Bearer " + this.token };
    this.getCountries();
  },
  methods: {
    getCountries() {
      axios
        .get(API_BASE_URL + "/countries?items_per_page=-1", {
          headers: this.header,
        })
        .then(({ data }) => {
          this.countries = data.data;
          this.shipping_countries = data.data;
          this.countries.forEach((element) => {
            if (element.is_default == 1) {
              this.defaultCountry = element;
            }
          });
          if (this.defaultCountry) {
            this.country = this.defaultCountry.id;
            this.shipping_country = this.defaultCountry.id;
          }
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        });
    },
    fetchCountySuggListDebounced() {
      if (!this.search_county || typeof this.search_county == "object") {
        return;
      }

      this.isLoadingSearchCounty = true;
      axios
        .get(
          API_BASE_URL +
            "/counties?" +
            (this.search_county
              ? "&search=" + encodeURIComponent(this.search_county)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.counties = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchCounty = false;
        })
        .finally(() => (this.isLoadingSearchCounty = false));
    },
    fetchAddressSuggListDebounced() {
      if (!this.search_address || typeof this.search_address == "object") {
        return;
      }
      this.isLoadingSearchAddress = true;
      axios
        .get(
          API_BASE_URL +
            "/addresses?" +
            (this.search_address
              ? "&search=" + encodeURIComponent(this.search_address)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.fetchedAddresses = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchAddress = false;
        })
        .finally(() => (this.isLoadingSearchAddress = false));
    },
    fetchCitySuggListDebounced() {
      if (!this.search_city || typeof this.search_city == "object") {
        return;
      }

      this.isLoadingSearchCity = true;
      axios
        .get(
          API_BASE_URL +
            "/cities?" +
            (this.search_city
              ? "&search=" + encodeURIComponent(this.search_city)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.cities = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchCity = false;
        })
        .finally(() => (this.isLoadingSearchCity = false));
    },
    fetchCountrySuggListDebounced() {
      if (!this.search_country || typeof this.search_country == "object") {
        return;
      }
      axios
        .get(
          API_BASE_URL +
            "/countries?" +
            (this.search_country
              ? "&search=" + encodeURIComponent(this.search_country)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.countries = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchCountry = false;
        })
        .finally(() => (this.isLoadingSearchCountry = false));
    },

    fetchShippingCountySuggListDebounced() {
      if (
        !this.search_shipping_county ||
        typeof this.search_shipping_county == "object"
      ) {
        return;
      }

      this.isLoadingSearchShippingCounty = true;
      axios
        .get(
          API_BASE_URL +
            "/counties?" +
            (this.search_shipping_county
              ? "&search=" + encodeURIComponent(this.search_shipping_county)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.shipping_counties = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchShippingCounty = false;
        })
        .finally(() => (this.isLoadingSearchShippingCounty = false));
    },
    fetchShippingAddressSuggListDebounced() {
      if (
        !this.search_shipping_address ||
        typeof this.search_shipping_address == "object"
      ) {
        return;
      }
      this.isLoadingSearchShippingAddress = true;
      axios
        .get(
          API_BASE_URL +
            "/addresses?" +
            (this.search_shipping_address
              ? "&search=" + encodeURIComponent(this.search_shipping_address)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.fetchedShippingAddresses = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchShippingAddress = false;
        })
        .finally(() => (this.isLoadingSearchShippingAddress = false));
    },
    fetchShippingCitySuggListDebounced() {
      if (
        !this.search_shipping_city ||
        typeof this.search_shipping_city == "object"
      ) {
        return;
      }

      this.isLoadingSearchShippingCity = true;
      axios
        .get(
          API_BASE_URL +
            "/cities?" +
            (this.search_city
              ? "&search=" + encodeURIComponent(this.search_city)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.shipping_cities = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchShippingCity = false;
        })
        .finally(() => (this.isLoadingSearchShippingCity = false));
    },
    fetchShippingCountrySuggListDebounced() {
      if (
        !this.search_shipping_country ||
        typeof this.search_shipping_country == "object"
      ) {
        return;
      }
      axios
        .get(
          API_BASE_URL +
            "/countries?" +
            (this.search_shipping_country
              ? "&search=" + encodeURIComponent(this.search_shipping_country)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.shipping_countries = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchShippingCountry = false;
        })
        .finally(() => (this.isLoadingSearchShippingCountry = false));
    },
    updatePropData() {
      let temp_prop_address =
        typeof this.address == "object"
          ? this.address
          : {
              city_id:
                this.city && typeof this.city == "object"
                  ? this.city.id
                  : this.city,
              county:
                this.county && typeof this.county == "object"
                  ? this.county.county
                  : this.county,
              id: null,
              street: this.address,
              zip: this.zipcode,
              country: this.country,
            };
      let temp_prop_shipping_address =
        typeof this.shipping_address == "object"
          ? this.shipping_address
          : {
              city_id:
                this.shipping_city && typeof this.shipping_city == "object"
                  ? this.shipping_city.id
                  : this.shipping_city,
              county:
                this.shipping_county && typeof this.shipping_county == "object"
                  ? this.shipping_county.county
                  : this.shipping_county,
              id: null,
              street: this.shipping_address,
              zip: this.shipping_zipcode,
              country: this.shipping_country,
            };
      this.$emit(
        "update:prop_address",
        temp_prop_address
          ? temp_prop_address.street
            ? temp_prop_address
            : null
          : null
      );
      this.$emit(
        "update:prop_shipping_address",
        temp_prop_shipping_address
          ? temp_prop_shipping_address.street
            ? temp_prop_shipping_address
            : null
          : null
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("addresses.street"),
          value: "address",
          sortable: false,
        },
        {
          text: this.$t("addresses.zipcode"),
          value: "address",
          sortable: false,
        },
        {
          text: this.$t("addresses.city"),
          value: "address",
          sortable: false,
        },
        {
          text: this.$t("addresses.county"),
          value: "address",
          sortable: false,
        },
        {
          text: this.$t("addresses.country"),
          value: "address",
          sortable: false,
        },
        {
          text: this.$t("addresses.is_default"),
          value: "address",
          sortable: false,
        },
        {
          text: this.$t("addresses.action"),
          value: "address",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    prop_address(val) {
      this.address = val;
    },
    prop_shipping_address(val) {
      this.shipping_address = val;
    },
    address(val) {
      if (val && typeof val == "object") {
        this.city = val.city;
        this.county = val.county;
        this.country = val.city.country_id;
        this.zipcode = val.zip;
        this.is_default = val.is_default;
      } else if (!val) {
        this.city = null;
        this.county = null;
        this.country = null;
        this.zipcode = null;
        this.is_default = false;
      }
      this.updatePropData();
    },
    shipping_address(val) {
      if (val && typeof val == "object") {
        this.shipping_city = val.city;
        this.shipping_county = val.county;
        this.shipping_country = val.city.country_id;
        this.shipping_zipcode = val.zip;
      } else if (!val) {
        this.shipping_city = null;
        this.shipping_county = null;
        this.shipping_country = null;
        this.shipping_zipcode = null;
      }
      this.updatePropData();
    },
    city() {
      this.updatePropData();
    },
    search_city() {
      this.updatePropData();
    },
    county() {
      this.updatePropData();
    },
    search_county() {
      this.updatePropData();
    },
    country() {
      this.updatePropData();
    },
    zipcode() {
      this.updatePropData();
    },
    shipping_zipcode() {
      this.updatePropData();
    },
    search_country() {
      this.updatePropData();
    },
    shipping_city() {
      this.updatePropData();
    },
    search_shipping_city() {
      this.updatePropData();
    },
    shipping_county() {
      this.updatePropData();
    },
    search_shipping_county() {
      this.updatePropData();
    },
    shipping_country() {
      this.updatePropData();
    },
    shipping_search_country() {
      this.updatePropData();
    },
  },
};
</script>
<style scoped></style>
