var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.formattedItems,"options":{
      itemsPerPage: _vm.perPage,
      itemsPerPageOptions: _vm.perPageOptions,
      page: _vm.page,
    },"height":_vm.$store.state.defaultTableHeight,"fixed-header":"","footer-props":{
      'items-per-page-options': _vm.perPageOptions,
    },"server-items-length":_vm.totalItems},on:{"update:page":_vm.getClocking,"update:items-per-page":(itemsPerPage) => {
        _vm.perPage = itemsPerPage;
        _vm.getClocking();
      }},scopedSlots:_vm._u([{key:"header.checkbox",fn:function({}){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-checkbox',{attrs:{"hide-details":"","input-attrs":attrs,"input-on":on},on:{"click":function($event){return _vm.toggleCheckAll()}}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.translate("select_all")))])])]}},{key:"body",fn:function(){return [_c('tbody',_vm._l((_vm.formattedItems),function(item){return _c('tr',{key:item.id,style:({
            background: _vm.computeRowBgColor(item),
          })},[_c('td',[_c('v-checkbox',{staticClass:"my-auto",attrs:{"hide-details":""},model:{value:(_vm.selected[item.id]),callback:function ($$v) {_vm.$set(_vm.selected, item.id, $$v)},expression:"selected[item.id]"}})],1),_c('td',[_vm._v(_vm._s(item.date))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{style:({
              background: _vm.computeWorkBgColor(item),
              textAlign: _vm.computeColSpan(item) === 4 ? 'center' : 'left',
            }),attrs:{"colspan":_vm.computeColSpan(item)}},[_vm._v(" "+_vm._s(item.clocking_type)+" ")]),(_vm.computeColSpan(item) === 1)?_c('td',{style:({ background: _vm.computeWorkBgColor(item) })},[_vm._v(" "+_vm._s(item.check_in)+" ")]):_vm._e(),(_vm.computeColSpan(item) === 1)?_c('td',{style:({ background: _vm.computeWorkBgColor(item) })},[_vm._v(" "+_vm._s(item.check_out)+" ")]):_vm._e(),(_vm.computeColSpan(item) === 1)?_c('td',{style:({ background: _vm.computeWorkBgColor(item) })},[_vm._v(" "+_vm._s(item.difference)+" ")]):_vm._e()])}),0)]},proxy:true}])}),_c('span',{attrs:{"id":"delete-selected"},on:{"click":_vm.confirmDelete}},[(_vm.confirmationDialog)?_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText,"checkboxText":_vm.confirmationDialogReCheckBoxConfirmationText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"update:checkboxText":function($event){_vm.confirmationDialogReCheckBoxConfirmationText=$event},"update:checkbox-text":function($event){_vm.confirmationDialogReCheckBoxConfirmationText=$event},"buttonClicked":(e) => _vm.confirmationButtonClicked(e, _vm.deleteHandler)}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }