<template>
    <div class="d-flex justify-center align-center fill-height">
        <div class="text-center">
            <v-progress-circular indeterminate color="primary" size="20" width="3"></v-progress-circular>
            <h2 class="pl-4 mt-2">{{ $t("please_wait") }}...</h2>
        </div>
    </div>
</template>

<style>
.fill-height {
    height: 100vh;
}
</style>
<script>
export default {};
</script>
