<template>
    <v-row>
        <v-data-table fixed-header :footer-props="{
            'items-per-page-options': dataTableOptions,
        }" :headers="customHeader" :items="articles" :items-per-page="itemsPerPage" :options.sync="options"
            :sort-by="sortBy" :server-items-length="totalItems" :loading-text="this.$t('loading')"
            @update:page="updatePagination" @update:items-per-page="updateItemsPerPage" class="elevation-0"
            item-key="identifier" :height="'calc(-211px + 100vh)'" style="width: 100%" @click:row="editItem"
            @update:sort-by="updateSort" @update:sort-desc="updateSortOrder" :key="forceReloadComponentKey">
            <template v-slot:top>
                <v-toolbar flat class="pt-4">
                    <v-col class="d-flex" cols="12" sm="2">
                        <v-select v-model="familyArticle" :items="familyArticles" :label="familyArticle
                                ? $t('articles.family')
                                : $t('articles.everything')
                            " item-value="id" item-text="name" class="mr-0 pr-2" :prepend-icon="normalSearch ? 'mdi-grid' : 'mdi-rectangle-outline'
                " @click:prepend="normalSearch = !normalSearch" return-object :outlined="is_boxes"
                            :dense="is_boxes"></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" :sm="customFilters.length > 0 ? 7 : 8">
                        <v-text-field v-if="normalSearch || customFilters.length == 0" class="to-upper"
                            :label="$t('search')" prepend-inner-icon="mdi-magnify" v-bind:clearable="true"
                            clear-icon="mdi-close" v-model="search" v-on:keydown.enter.prevent="
                                isDebounceCancelled = true;
                            fetchData();
                            " :outlined="is_boxes" :dense="is_boxes"></v-text-field>
                        <template v-if="!normalSearch">
                            <v-btn-toggle id="season-button" v-if="
                                familyArticle &&
                                (familyArticle.parent_family_id == 1 || familyArticle.id == 1)
                            " v-model="season" dense mandatory class="mr-2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn value="0">
                                            <!-- No Season -->
                                            <v-icon v-bind="attrs" v-on="on" dark
                                                color="grey darken-1">mdi-check-all</v-icon>
                                            <span>
                                                {{ $t("codes.shorts.no_season") }}
                                            </span>
                                        </v-btn>
                                    </template>
                                    <span> {{ $t("codes.no_season") }} </span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn value="10111002">
                                            <!-- Summer -->
                                            <v-icon v-bind="attrs" v-on="on" dark
                                                color="amber darken-4">mdi-weather-sunny</v-icon>
                                            <span>
                                                {{ $t("codes.shorts.summer") }}
                                            </span>
                                        </v-btn>
                                    </template>
                                    <span> {{ $t("codes.summer") }} </span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn value="10111001">
                                            <!-- Winter -->
                                            <v-icon v-bind="attrs" v-on="on" dark
                                                color="blue darken-2">mdi-weather-snowy-heavy</v-icon>
                                            <span>
                                                {{ $t("codes.shorts.winter") }}
                                            </span>
                                        </v-btn>
                                    </template>
                                    <span> {{ $t("codes.winter") }} </span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn value="10111003">
                                            <!-- All Season -->
                                            <v-icon v-bind="attrs" v-on="on" dark
                                                color="green darken-1">mdi-weather-partly-snowy-rainy</v-icon>
                                            <span>
                                                {{ $t("codes.shorts.all_season") }}
                                            </span>
                                        </v-btn>
                                    </template>
                                    <span> {{ $t("codes.all_season") }} </span>
                                </v-tooltip>
                            </v-btn-toggle>

                            <div v-for="(filter, index) in filterPropertiesExcept(customFilters)" :key="index">
                                <template v-if="filter.property_name == 'property_00'">
                                    <v-autocomplete v-if="filter.property_name == 'property_00'" flat
                                        :ref="filter.property_name" :items="brands" :search-input.sync="searchBrand"
                                        v-model="brand" :label="$t('articles.properties.brand')" class="mr-2 to-upper"
                                        item-value="id" item-text="name" @focus="brand = null" return-object
                                        :outlined="is_boxes" :dense="is_boxes" hide-no-data></v-autocomplete>
                                </template>
                                <template v-else>
                                    <v-autocomplete v-if="filter.mapping_key != null" v-model="filter.value"
                                        :items="multiselectsValues[index]" :label="filter.text" item-value="id"
                                        hide-details="auto" class="mr-2" append-icon="mdi-close" @click:append="
                                            filter.value = null;
                                        resetPage();
                                        fetchData();
                                        " :outlined="is_boxes" :dense="is_boxes" @change="
                        resetPage();
                    fetchData();
                    " hide-no-data></v-autocomplete>
                                    <v-text-field flat v-if="filter.mapping_key == null" v-model="filter.value"
                                        :label="filter.text" class="mr-2 to-upper" :ref="filter.property_name"
                                        @focus="filter.value = null" v-on:keydown.enter.prevent="
                                            isDebounceCancelled = true;
                                        fetchData();
                                        " @keypress="
                        handleInput($event, filter.keytype, filter.search_length)
                        " @keyup="
                        handleAutoFocus(
                            $event,
                            filter.search_length,
                            filter.property_name,
                            filter.keytype,
                            filterPropertiesExcept(customFilters)[index + 1]
                                ? filterPropertiesExcept(customFilters)[index + 1]
                                    .property_name
                                : null
                        )
                        " :outlined="is_boxes" :dense="is_boxes"></v-text-field>
                                </template>
                            </div>

                            <v-select v-if="articleBooleanProperties.length >= 1" v-model="booleanSelectedValues"
                                :label="$t('articles.properties.more')" :items="articleBooleanProperties"
                                item-value="id" item-text="name" multiple :outlined="is_boxes" :dense="is_boxes"
                                return-object>
                                <template v-slot:selection="{ item, index }">
                                    <span>
                                        <template v-if="index === 0 && booleanSelectedValues.length == 1">
                                            {{
                                                item.name.substr(
                                                    0,
                                                    booleanSelectedValues.length > 1 ? 3 : 5
                                            )
                                            }}
                                        </template>
                                        <template v-if="index === 1">
                                            (+{{ booleanSelectedValues.length - 1 }})
                                        </template>
                                    </span>
                                </template>
                            </v-select>
                        </template>
                    </v-col>
                    <!-- statuses -->
                    <v-col class="d-flex" cols="2" sm="3">
                        <template v-if="
                            familyArticle &&
                            (familyArticle.parent_family_id == 1 ||
                                familyArticle.parent_family_id == 2)
                        ">
                            <v-select v-model="status" :items="statuses" :label="$t('articles.status')" item-value="id"
                                item-text="text" class="mr-2" return-object :outlined="is_boxes"
                                :dense="is_boxes"></v-select>
                            <v-select v-model="approval" :items="approvals" :label="$t('articles.approval')"
                                item-value="id" item-text="text" class="mr-2" return-object :outlined="is_boxes"
                                :dense="is_boxes"></v-select>
                            <v-btn color="primary" dark @click="fetchData()">
                                <v-icon dark>mdi-database-search</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn class="mt-n6" color="primary" dark @click="fetchData()">
                                <v-icon dark>mdi-database-search</v-icon>
                            </v-btn>
                        </template>
                    </v-col>
                </v-toolbar>
            </template>
            <template v-slot:item.image="{ item }">
                <td>
                    <v-icon color="blue" @click.stop.prevent="showImage(item)">mdi-image</v-icon>
                </td>
            </template>
            <template v-slot:item.property_00="{ item }">
                <td>{{ item.name }}</td>
                <!-- Type Column -->
            </template>
            <template v-slot:item.name="{ item }">
                <td>{{ item.description || item.name }}</td>
            </template>
            <template v-slot:item.property_01="{ item }">
                <td v-if="
                    item.property_01 &&
                    (familyArticle.id == 1 || familyArticle.parent_family_id == 1)
                ">
                    {{ $t("articles.types." + item.property_01) }}
                </td>
                <!-- Type Column -->
                <td v-if="
                    item.property_01 &&
                    (familyArticle.id == 2 || familyArticle.parent_family_id == 2)
                ">
                    {{ $t("articles.types." + item.property_01) }}
                </td>
                <!-- Type Column -->
            </template>
            <template v-slot:item.property_02="{ item }">
                <td v-if="
                    ['all_season', 'no_season', 'winter', 'summer'].includes(
                        item.property_02
                    )
                ">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" v-if="'summer' == item.property_02" dark
                                color="amber darken-4">mdi-weather-sunny</v-icon>
                            <!-- <v-icon  v-if="'no_season' == item.property_02" dark color="grey darken-1">mdi-check-all</v-icon> -->
                            <v-icon v-bind="attrs" v-on="on" v-if="'all_season' == item.property_02" dark
                                color="green darken-1">mdi-weather-partly-snowy-rainy</v-icon>
                            <v-icon v-bind="attrs" v-on="on" v-if="'winter' == item.property_02" dark
                                color="blue darken-2">mdi-weather-snowy-heavy</v-icon>
                        </template>
                        <span v-if="'summer' == item.property_02">
                            {{ $t("codes.summer") }}
                        </span>
                        <span v-if="'no_season' == item.property_02">
                            {{ $t("codes.no_season") }}
                        </span>
                        <span v-if="'all_season' == item.property_02">
                            {{ $t("codes.all_season") }}
                        </span>
                        <span v-if="'winter' == item.property_02">
                            {{ $t("codes.winter") }}
                        </span>
                    </v-tooltip>
                </td>

                <td v-else-if="
                    item.property_02 &&
                    (familyArticle.id == 2 || familyArticle.parent_family_id == 2)
                ">
                    {{ $t("articles.types." + item.property_02) }}
                </td>
                <td v-else>
                    {{ item.property_02 }}
                </td>
                <!-- Season Column -->
            </template>
            <template v-slot:item.tariff_price="{ item }">
                <td>{{ fixDecimal(item.tariff_price) }}</td>
            </template>
        </v-data-table>
        <image-pop-up v-if="showImagePopUp" :show="showImagePopUp" @closePopUp="showImagePopUp = false"
            :imageUrl="articleImageBaseUrl + currentItem.identifier" :data="currentItem"></image-pop-up>
    </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ImagePopUp from "@/components/ImagePopUp";

export default {
    name: "ArticlesListing",
    components: {
        "image-pop-up": ImagePopUp,
    },
    async mounted() {
        this.$store.commit("setFormMode", VIEW_MODE);
        this.$store.commit("setAddButtonRoute", "AddArticle", "EditArticle");
    },
    activated() {
        this.$store.commit("setFormMode", VIEW_MODE);
        this.$store.commit("setAddButtonRoute", "AddArticle", "EditArticle");
        this.setDefaultFamilyArticle();

        if (this.$store.state.refreshList) {
            this.$store.commit("setRefreshList", false);
            this.$nextTick(() => {
                if (
                    this.familyArticle &&
                    typeof this.familyArticle == "object" &&
                    this.familyArticle.show_on_load
                ) {
                    if (
                        this.familyArticle.parent_family_id == 1 ||
                        this.familyArticle.id == 1
                    ) {
                        this.fetchData();
                    } else {
                        this.fetchData(null, "*");
                    }
                }
            });
        }
    },
    data() {
        return {
            booleanSelectedValues: [],
            showImagePopUp: false,
            currentItem: null,
            articleImageBaseUrl: API_BASE_URL + "/article/image/",
            is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
            normalSearch: false,
            search: "",
            isFirstTimeLoaded: 0,
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            isLoading: false,
            articles: [],
            lastPage: null,
            totalItems: null,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            sortBy: "description",
            sortDesc: false,
            options: {},
            pagination: {},
            season: 0,
            width: null,
            height: null,
            rim: null,
            li: null,
            si: null,
            brands: [],
            brand: null,
            approval: { id: 10011001 },
            status: { id: 10001001 },
            articleBooleanProperties: [],
            familyArticle: {},
            loadingFirstTime: 0,
            ifCurrentlyPaused: false,
            changingArticlePropertiesInProgress: false,
            customHeader: [
                {
                    text: this.$t("articles.id"),
                    value: "id",
                    align: "start",
                    sortable: false,
                    width: 5,
                },
                {
                    text: this.$t("articles.image"),
                    value: "image",
                    align: "start",
                    sortable: false,
                    width: 30,
                },
                {
                    text: this.$t("articles.ean"),
                    value: "identifier",
                    align: "start",
                    sortable: false,
                    width: 140,
                },
                {
                    text: this.$t("articles.description"),
                    align: "start",
                    sortable: true,
                    value: "description",
                },
                {
                    text: this.$t("articles.tariff_price"),
                    align: "end",
                    sortable: false,
                    value: "tariff_price",
                },
            ],
            customFilters: [],
            customFiltersExceptions: [1, 2], // these numbers represents property_id value example : property_id = 1
            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            isDebounceCancelled: false,
            searchBrand: null,
        };
    },
    methods: {
        resetPage() {
            this.page = 1;
            this.options["page"] = 1;
        },
        debounceInput: _.debounce(function () {
            if (this.isDebounceCancelled) {
                this.isDebounceCancelled = false;
                return false;
            }
            this.fetchData();
        }, 2000),
        /**
         * This Function is responsible to autofocus on next property field, if current's max length reached
         */
        handleAutoFocus(
            event,
            search_length,
            propertyName,
            dataType,
            forceToJumpOnProperty
        ) {
            let currentFieldLength = 0;
            if (dataType == "int" || dataType == "float") {
                currentFieldLength = (event.target.value + "").replace(".", "").length;
            } else {
                currentFieldLength = (event.target.value + "").length;
            }
            if (
                forceToJumpOnProperty &&
                search_length != null &&
                currentFieldLength == search_length
            ) {
                this.$refs[forceToJumpOnProperty][0].focus();
            } else if (
                propertyName.search("property") == 0 &&
                search_length != null &&
                currentFieldLength == search_length
            ) {
                let underscorePos = propertyName.search("_") + 1;
                let propertyNo = Number(propertyName.substr(underscorePos)) + 1;
                let nextPropertyName =
                    "property_" + (propertyNo < 10 ? "0" : "") + propertyNo;
                this.$refs[nextPropertyName][0].focus();
            }
        },
        filterPropertiesExcept(customFilters) {
            let properties = [];
            if (
                this.familyArticle &&
                (this.familyArticle.parent_family_id == 1 || this.familyArticle.id == 1)
            ) {
                properties = ["property_02"];
            }

            let filteredProperties = [];
            filteredProperties = customFilters.filter((property) => {
                let found = false;
                properties.forEach((element) => {
                    if (element == property.property_name) {
                        found = true;
                    }
                });

                return found ? false : property;
            });
            return filteredProperties;
        },
        checkIfBrandExistsInProperties(customFilters) {
            let found = false;
            customFilters.forEach((property) => {
                if ("property_00" == property.property_name) {
                    found = true;
                }
            });
            return found;
        },
        removeFamilyArticle() {
            this.familyArticle = null;
        },
        getFamilyArticleById(id) {
            let familyArticle = 0;
            this.familyArticles.forEach((element) => {
                if (element.id == id) {
                    familyArticle = element;
                }
            });
            return familyArticle;
        },
        resetFurtherBoxesValues(focussedPropertyName) {
            let foundProperty = false;
            this.customFilters.forEach((filter, index) => {
                if (filter.property_name == focussedPropertyName) {
                    foundProperty = true;
                }
                if (foundProperty) {
                    this.customFilters[index].value = null;
                }
            });
        },
        applyPreviousFilters() {
            if (this.customFilters.length > 0) {
                this.resetFurtherBoxesValues(this.customFilters[0].property_name);
                this.search = null;
                this.brands = [];
                this.brand = null;
            }
            let applyOnRoutes = ["Articles", "AddArticle", "EditArticle"];
            if (applyOnRoutes.includes(localStorage.getItem("LS_ROUTE_KEY"))) {
                let articleFilters = JSON.parse(
                    localStorage.getItem("ARTICLE_FILTERS")
                );
                if (articleFilters != null) {
                    this.normalSearch = articleFilters.normalSearch;
                    this.page = articleFilters.page;
                    this.itemsPerPage = articleFilters.items_per_page;
                    this.sortBy = articleFilters.order_by;
                    this.sortDesc = articleFilters.order_direction;
                    this.approval.id = articleFilters.approval;
                    this.status.id = articleFilters.status;
                    this.familyArticle = this.getFamilyArticleById(articleFilters.family);
                    this.season = articleFilters.season;
                    // this.brand
                    // this.brand.id = articleFilters.brand;
                    // this.search = articleFilters.search;

                    this.booleanSelectedValues = [];
                    articleFilters.appliedFilters.forEach((element) => {
                        let keyName = Object.keys(element)[0];
                        // now map from custom filters and update their value
                        this.customFilters.find((el, index) => {
                            if (el.property_name == keyName) {
                                this.customFilters[index].value = element[keyName];
                                el.value = element[keyName];
                                return el;
                            }
                        });

                        this.articleBooleanProperties.find((el) => {
                            if (el.property_name == keyName) {
                                let foundBoolean = false;
                                this.booleanSelectedValues.forEach((selectedBoolean) => {
                                    if (selectedBoolean.property_id == el.property_id) {
                                        foundBoolean = true;
                                    }
                                });
                                if (!foundBoolean) {
                                    this.booleanSelectedValues.push(el);
                                }
                                return el;
                            }
                        });
                    });
                    this.fetchData();
                }
            }
        },
        checkIfFetchingPossible() {
            let isPossible = false;
            this.ifCurrentlyPaused = false;

            let valueAssigned = 0;
            this.customFilters.forEach((element) => {
                if (element.value != null) {
                    valueAssigned++;
                }
            });
            if (valueAssigned != 0) {
                isPossible = true;
            } else {
                isPossible = false;
            }

            if (!isPossible) {
                this.ifCurrentlyPaused = true;
            }
            // if(this.familyArticle == null || this.familyArticle.parent_family_id == 0 || this.familyArticle.parent_family_id == 3 ||  this.familyArticle.parent_family_id == 4){
            //   isPossible = true;
            // }
            if (this.changingArticlePropertiesInProgress) {
                isPossible = false;
            }
            if (this.search) {
                isPossible = true;
            }
            if (this.brand) {
                isPossible = true;
            }
            return isPossible;
        },
        updateLocalStorage() {
            let filters = {
                page: this.page,
                items_per_page: this.itemsPerPage,
                order_by: this.sortBy,
                order_direction: this.sortDesc,
                approval: this.approval.id,
                status: this.status.id,
                family: this.familyArticle ? this.familyArticle.id : 0,
                season: this.season,
                brand: this.brand != null ? this.brand.id : "",
                search: this.search,
                appliedFilters: this.getQueryStringFromDynamicallyCreatedFilters()[1],
            };
            localStorage.setItem("ARTICLE_FILTERS", JSON.stringify(filters));
        },
        fetchData(currentPage = null, forceSearchString = null) {
            if (!currentPage) {
                this.resetDataTable();
                this.options.page = 1;
            }
            this.articles = []; // remove the data from the table
            if (this.checkIfFetchingPossible() || forceSearchString) {
                // if forceSearchString then search it
                this.isLoading = true;
                const { sortBy, sortDesc, itemsPerPage, page } = this.options;
                console.log(sortBy);
                this.updateLocalStorage();

                axios
                    .get(
                        API_BASE_URL +
                        "/articles" +
                        "?page=" +
                        (page || this.page) +
                        "&items_per_page=" +
                        (itemsPerPage || this.itemsPerPage) +
                        "&order_by=" +
                        (this.sortBy == "name" ? "articles.name" : this.sortBy) +
                        "&order_direction=" +
                        (!!sortDesc[0] || this.sortDesc ? "desc" : "asc") +
                        "&parent_also=true" +
                        (this.familyArticle != null &&
                            (this.familyArticle.parent_family_id == 1 ||
                                this.familyArticle.parent_family_id == 2)
                            ? this.approval
                                ? "&approval=" + this.approval.id
                                : ""
                            : "") +
                        (this.familyArticle != null &&
                            (this.familyArticle.parent_family_id == 1 ||
                                this.familyArticle.parent_family_id == 2)
                            ? this.status
                                ? "&status=" + this.status.id
                                : ""
                            : "") +
                        (this.familyArticle && this.familyArticle.id != 0
                            ? "&family=" + this.familyArticle.id
                            : "") +
                        (this.familyArticle != null &&
                            !this.normalSearch &&
                            (this.familyArticle.parent_family_id == 1 ||
                                this.familyArticle.id == 1 ||
                                this.familyArticle.parent_family_id == 2 ||
                                this.familyArticle.id == 2)
                            ? this.season
                                ? "&season=" + this.season
                                : ""
                            : "") +
                        (this.familyArticle != null
                            ? this.brand
                                ? "&brand=" + this.brand.id
                                : ""
                            : "") +
                        (this.search
                            ? "&search=" + encodeURIComponent(this.search)
                            : "") +
                        (forceSearchString
                            ? "&search=" + encodeURIComponent(forceSearchString)
                            : "") +
                        this.getQueryStringFromDynamicallyCreatedFilters()[0],
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        this.articles = data.data.map((element) => {
                            if (
                                this.familyArticle.id == 1 ||
                                this.familyArticle.parent_family_id == 1
                            ) {
                                if (element.property_01) {
                                    this.tyreTypes.find((ele) => {
                                        if (ele.id == element.property_01) {
                                            element.property_01 = ele.key;
                                        }
                                    });
                                }
                                if (element.property_02) {
                                    this.tyreSeasons.find((ele) => {
                                        if (ele.id == element.property_02) {
                                            element.property_02 = ele.key;
                                        }
                                    });
                                }
                            } else if (
                                this.familyArticle.id == 2 ||
                                this.familyArticle.parent_family_id == 2
                            ) {
                                if (element.property_01) {
                                    this.wheelTypes.find((ele) => {
                                        if (ele.id == element.property_01) {
                                            element.property_01 = ele.key;
                                        }
                                    });
                                }
                                if (element.property_02) {
                                    this.wheelMaterials.find((ele) => {
                                        if (ele.id == element.property_02) {
                                            element.property_02 = ele.key;
                                        }
                                    });
                                }
                            }

                            return element;
                        });
                        this.lastPage = data.last_page;
                        this.page = data.current_page;
                        this.totalItems = data.total;
                        this.itemsPerPage = parseInt(data.per_page);

                        this.isLoading = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.isLoading = false;
                    });
            }
        },
        setDefaultFamilyArticle() {
            let defaultFamilyArticle = null;

            this.familyArticles.forEach((element) => {
                if (element.is_default) {
                    defaultFamilyArticle = element;
                }
            });
            // update immediate family article so upcoming functions that have dependencies on family article works fine : if route has relative history
            let articleFilters = JSON.parse(localStorage.getItem("ARTICLE_FILTERS"));
            if (articleFilters != null) {
                this.familyArticle = this.getFamilyArticleById(
                    articleFilters.family ?? defaultFamilyArticle.id
                );
            } else {
                let defaultSearchType = this.getCompanyPropertyFromLocalStorage(
                    "default_article_search_type"
                );
                if (defaultSearchType && defaultSearchType.value == "0") {
                    // So, here default search type will be everything
                    this.familyArticle = defaultFamilyArticle;
                    this.normalSearch = false;
                } else if (defaultSearchType && defaultSearchType.value == "1") {
                    // So, here default search type will be everything
                    this.familyArticle = defaultFamilyArticle;
                    this.normalSearch = true;
                } else if (!defaultFamilyArticle) {
                    this.normalSearch = true;
                    this.familyArticle = this.familyArticles[0];
                } else {
                    this.familyArticle = defaultFamilyArticle;
                }
            }
        },
        editItem(item) {
            this.$store.commit("seteditId", item.id);
            // let isEditable = false;
            // this.familyArticles.forEach((element) => {
            //   if(element.id == item.family_id){
            //     isEditable = true ;
            //   }
            // });
            // if(isEditable){
            this.$store.commit("setFormMode", EDIT_MODE);
            this.$router.push("/articles/edit").catch(() => { });
            // }
        },
        showImage(item) {
            this.currentItem = item;
            this.showImagePopUp = true;
        },
        deleteItem() { },
        changeArticleProperties() {
            let currentFamilyArticle = this.familyArticles.find((element) => {
                if (this.familyArticle && element.id == this.familyArticle.id)
                    return element;
            });
            if (!currentFamilyArticle) {
                currentFamilyArticle = this.getEverythingFamilyArticle();
            }
            // hard coding for family : todo later make it dynamic
            if (currentFamilyArticle.parent_family_id === 2) {
                this.season = "";
            }

            this.articleBooleanProperties = currentFamilyArticle.properties.filter(
                (property) => {
                    if (property.cast == "boolean" && property.in_filter == 1) {
                        property.name = property.translated
                            ? property.name
                            : this.$t("articles.properties." + property.name);
                        property.property_name =
                            "property_" +
                            (property.property_id < 10
                                ? 0 + property.property_id.toString()
                                : property.property_id);
                        property.translated = true;
                        return property;
                    }
                    return false;
                }
            );
        },
        setCustomDataTableHeaders() {
            let currentFamilyArticle = this.familyArticles.find((element) => {
                if (this.familyArticle && element.id == this.familyArticle.id)
                    return element;
            });
            if (!currentFamilyArticle) {
                currentFamilyArticle = this.getEverythingFamilyArticle();
            }

            if (
                this.familyArticle &&
                (this.familyArticle.parent_family_id == 1 ||
                    this.familyArticle.parent_family_id == 2)
            ) {
                this.customHeader = [
                    {
                        text: this.$t("articles.id"),
                        value: "id",
                        align: "start",
                        sortable: false,
                        width: 5,
                    },
                    {
                        text: this.$t("articles.image"),
                        value: "image",
                        align: "start",
                        sortable: false,
                        width: 30,
                    },
                    {
                        text: this.$t("articles.ean"),
                        value: "identifier",
                        align: "start",
                        sortable: false,
                        width: 140,
                    },
                    {
                        text: this.$t("articles.description"),
                        align: "start",
                        sortable: true,
                        value: "description",
                    },
                    {
                        text: this.$t("articles.tariff_price"),
                        align: "end",
                        sortable: false,
                        value: "tariff_price",
                    },
                ];
                this.setDefaultSorting("description", true);
            } else if (this.familyArticle && this.familyArticle.id == 3) {
                this.customHeader = [
                    {
                        text: this.$t("articles.id"),
                        value: "id",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.image"),
                        value: "image",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.ean"),
                        value: "identifier",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.name"),
                        value: "name",
                        align: "start",
                        sortable: true,
                        width: 140,
                    },
                ];
                this.setDefaultSorting("name", true);
            } else if (this.familyArticle && this.familyArticle.id == 4) {
                this.customHeader = [
                    {
                        text: this.$t("articles.id"),
                        value: "id",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.image"),
                        value: "image",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.ean"),
                        value: "identifier",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.name"),
                        value: "name",
                        align: "start",
                        sortable: true,
                        width: 140,
                    },
                ];
                this.setDefaultSorting("name", true);
            } else if (!this.familyArticle || this.familyArticle.id == 0) {
                this.customHeader = [
                    {
                        text: this.$t("articles.id"),
                        value: "id",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.image"),
                        value: "image",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.ean"),
                        value: "identifier",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.original_name"),
                        align: "start",
                        sortable: true,
                        value: "original_name",
                    },
                ];
                this.setDefaultSorting("original_name", true);
            } else {
                this.customHeader = [
                    {
                        text: this.$t("articles.id"),
                        value: "id",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.image"),
                        value: "image",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.ean"),
                        value: "identifier",
                        align: "start",
                        sortable: false,
                        width: "1%",
                    },
                    {
                        text: this.$t("articles.name"),
                        value: "name",
                        align: "start",
                        sortable: true,
                        width: 140,
                    },
                ];
                this.setDefaultSorting("name", true);
            }

            this.renderedFilters = [];
            currentFamilyArticle.properties.filter((property) => {
                if (property.in_grid == 1) {
                    this.customHeader.push({
                        text: this.$t("articles.properties." + property.name),
                        value:
                            "property_" +
                            (property.property_id < 10
                                ? 0 + property.property_id.toString()
                                : property.property_id),
                        align: "start",
                        sortable: false,
                    });
                    return true;
                }
            });
            this.modifyOrderingOfCustomHeaders();
        },
        setDefaultSorting(sortingColumn, booleanValue) {
            this.options["sortBy"] = [sortingColumn];
            this.options["sortDesc"] = [booleanValue];
        },
        /**
         * This function is used to modify the ordering of Headers in Listing
         * Note : For ordering you must know the column name
         */
        modifyOrderingOfCustomHeaders() {
            let sortedArray = [];
            let nonSortedArray = [];
            let sortingArray = [];
            if (
                this.familyArticle &&
                (this.familyArticle.parent_family_id == 1 ||
                    this.familyArticle.parent_family_id == 2)
            ) {
                sortingArray = [
                    this.$t("articles.id"),
                    this.$t("articles.image"),
                    this.$t("articles.properties.type"),
                    this.$t("articles.properties.season"),
                    this.$t("articles.properties.name"),
                    this.$t("articles.ean"),
                    this.$t("articles.properties.width"),
                    this.$t("articles.properties.height"),
                    this.$t("articles.properties.rim"),
                    this.$t("articles.properties.li"),
                    this.$t("articles.properties.si"),
                    this.$t("articles.tariff_price"),
                ];
            } else {
                sortingArray = [
                    this.$t("articles.id"),
                    this.$t("articles.image"),
                    this.$t("articles.ean"),
                    this.$t("articles.properties.name"),
                ];
            }

            let processedArray = [];
            sortingArray.forEach((element, indexNo) => {
                for (const [key, value] of Object.entries(this.customHeader)) {
                    console.log("key", key);
                    let added = false;

                    if (element == value.text) {
                        sortedArray.push(value);
                        processedArray.push(value.text);
                        added = true;
                    }
                    if (
                        added == false &&
                        processedArray.find((element) => element == value.text) ==
                        undefined &&
                        sortingArray.length == indexNo + 1
                    ) {
                        nonSortedArray.push(value);
                    }
                }
            });

            this.customHeader = sortedArray.concat(nonSortedArray);
        },
        setDynamicFilterBoxes() {
            let currentFamilyArticle = this.familyArticles.find((element) => {
                if (this.familyArticle && element.id == this.familyArticle.id)
                    return element;
            });
            if (!currentFamilyArticle) {
                currentFamilyArticle = this.getEverythingFamilyArticle();
            }
            this.customFilters = [];
            this.multiselects = [];
            currentFamilyArticle.properties.filter((property) => {
                if (property.in_filter == 1) {
                    let indexOfClass = (property.mapping ?? "").indexOf("class=");
                    let mappingKey = null;
                    if (indexOfClass != -1) {
                        mappingKey = property.mapping.substr(indexOfClass + 6, 4) + "";
                        let propertyName =
                            "property_" +
                            (property.property_id < 10
                                ? 0 + property.property_id.toString()
                                : property.property_id);

                        if (this.familyArticle.properties) {
                            this.familyArticle.properties[propertyName] = parseInt(
                                this.familyArticle.properties[propertyName]
                            );
                        }
                    }
                    if (mappingKey) {
                        this.multiselects.push(mappingKey);
                    }

                    if (property.cast != "boolean") {
                        this.customFilters.push({
                            text: this.$t("articles.properties." + property.name),
                            align: "start",
                            sortable: true,
                            property_name:
                                "property_" +
                                (property.property_id < 10
                                    ? 0 + property.property_id.toString()
                                    : property.property_id),
                            property_id: property.property_id,
                            column_name: property.name,
                            value: null,
                            property_type: property.cast,
                            mapping: property.mapping ?? null,
                            mapping_key: mappingKey ?? null,
                            keytype: property.cast,
                            search_length: property.search_length,
                        });
                    }
                    return true;
                }
                return false;
            });
            if (this.isFirstTimeLoaded == 0) {
                this.applyPreviousFilters();
                this.isFirstTimeLoaded++;
            }
            this.changingArticlePropertiesInProgress = false;
        },
        getQueryStringFromDynamicallyCreatedFilters() {
            let queryString = "";
            let appliedFilters = [];
            this.customFilters.forEach((filter) => {
                if (filter.value) {
                    queryString += "&" + filter.property_name + "=" + filter.value;
                    appliedFilters.push({
                        [filter.property_name]: filter.value,
                    });
                }
            });
            this.booleanSelectedValues.forEach((filter) => {
                queryString += "&" + filter.property_name + "=" + 1; // 1 means true
                appliedFilters.push({
                    [filter.property_name]: 1, // 1 means true
                });
            });
            return [queryString, appliedFilters];
        },
        getMultiSelectValuesFromCode() {
            this.customFilters.forEach((filter, filterindex) => {
                this.multiselectsValues[filterindex] = [];
            });
            this.multiselects.forEach((code, index) => {
                console.log("index", index);
                this.getCodes(parseInt(code), "codes").then((data) => {
                    this.customFilters.forEach((filter, filterindex) => {
                        if (filter.mapping_key == code) {
                            this.multiselectsValues[filterindex] = data;
                        }
                    });
                    this.multiselectsValues.push({});
                    this.multiselectsValues.splice(-1);
                });
            });
        },
        updatePagination(page) {
            this.page = page;
            this.fetchData(page);
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
            this.fetchData();
            this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
        },
        updateSort(sortBy) {
            if (typeof sortBy == "object") {
                this.sortBy = sortBy[0];
            } else {
                this.sortBy = sortBy == undefined ? "description" : sortBy;
            }
        },
        updateSortOrder(sortDesc) {
            if (typeof sortDesc == "object") {
                !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            } else {
                !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            }
            this.fetchData();
        },
        getBrands() {
            if (this.familyArticle && this.familyArticle.id) {
                if (
                    this.cachedData["brands"][this.familyArticle.id] &&
                    this.cachedData["brands"][this.familyArticle.id].length > 0
                ) {
                    this.brands = this.cachedData["brands"][this.familyArticle.id];
                } else {
                    axios
                        .get(
                            API_BASE_URL +
                            "/brands?family=" +
                            this.familyArticle.id +
                            "&parent_also=true&items_per_page=-1",
                            { headers: this.header }
                        )
                        .then(({ data }) => {
                            this.brands = data.data;
                            this.cachedData["brands"][this.familyArticle.id] = this.brands;
                        })
                        .catch(function (error) {
                            console.log("an error occured " + error);
                        })
                        .finally(() => { });
                }
            }
        },
    },
    watch: {
        familyArticles: {
            handler: function () {
                this.setDefaultFamilyArticle();
                this.resetPage();
            },
        },
        normalSearch: {
            handler: function (val) {
                this.customFilters.forEach((element) => {
                    element.value = null;
                });
                this.brand = null;
                if (val == false) {
                    this.search = null;
                }
                this.resetPage();
            },
        },
        familyArticle: {
            handler: function () {
                this.articles = [];

                this.changingArticlePropertiesInProgress = true;
                this.search = null;
                this.brand = null;
                this.brands = [];
                // this.updateLocalStorage();
                if (
                    this.familyArticle &&
                    this.familyArticle.parent_family_properties.length > 0
                ) {
                    this.familyArticle.properties =
                        this.familyArticle.parent_family_properties;
                }
                // if(this.loadingFirstTime != 0 ){
                //   this.fetchData();
                // }

                this.changeArticleProperties();
                this.setCustomDataTableHeaders();
                this.setDynamicFilterBoxes();

                this.getMultiSelectValuesFromCode();
                this.getBrands();
                this.resetPage();

                // show_on_load -> Load some articles on load of the page
                this.$nextTick(() => {
                    if (
                        this.familyArticle &&
                        typeof this.familyArticle == "object" &&
                        this.familyArticle.show_on_load
                    ) {
                        if (
                            this.familyArticle.parent_family_id == 1 ||
                            this.familyArticle.id == 1
                        ) {
                            // DO NOT IMPLEMENT DEFAULT SEARCH FOR TYRE ARTICLES
                        } else {
                            this.fetchData(null, "*");
                        }
                    }
                });
      },
    },
    search: {
      handler: function () {
        _.debounce(function () {
          this.resetPage();
          this.fetchData();
        }, 500);
      },
    },
    brand: {
      handler: function () {
        this.resetPage();
        this.fetchData();
      },
    },
    season: {
      handler: function () {
        if (this.loadingFirstTime != 0) {
          this.resetPage();
          this.fetchData();
        }
        this.loadingFirstTime++;
      },
    },
    status: {
      handler: function () {
        this.resetPage();
        this.fetchData();
      },
    },
    approval: {
      handler: function () {
        this.resetPage();
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped>
.to-upper input {
    text-transform: uppercase !important;
}
</style>
