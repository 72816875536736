<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="8">
          <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
        </v-col>
        <v-col class="ma-0 pa-0" cols="12" sm="4">
          <v-text-field
            class="mr-3 to-upper"
            :label="$t('search')"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>

      <div>
        <v-data-table
          :height="'calc(-212px + 100vh)'"
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :items="family_clients"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          @click:row="editItem"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          mustSort
        >
          <template v-slot:item="{ item }">
            <tr>
              <td @click="editItem(item)">{{ item.id || "-" }}</td>
              <td @click="editItem(item)">{{ item.name || "-" }}</td>
              <td @click="editItem(item)">
                <span v-if="item.is_default == 1">
                  <v-icon color="success" class="mr-1"> mdi-check </v-icon>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "FamilyClient",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      isLoading: true,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      sortBy: ["id"],
      sortDesc: true,
      sortOrd: "DESC",
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      family_clients: [],
      search: "",
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
    };
  },
  mounted() {
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setFormMode", VIEW_MODE);
    this.$store.commit("setAddButtonRoute", "AddClient");
    this.fetchData();
    this.getFamilyClients();
  },
  created() {
    this.$watch(
      "search",
      _.debounce(() => {
        this.isLoading = true;
        this.fetchData();
      }, 500)
    );
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get(
          API_BASE_URL +
            "/family_clients?page=" +
            this.page +
            "&items_per_page=" +
            this.itemsPerPage +
            (this.search
              ? "&search=" + this.search
              : "" + "&sortby=" + this.sortBy + "&sorttype=" + this.sortOrd),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.family_clients = data.data;
          this.lastPage = data.meta.last_page;
          this.totalItems = data.meta.total;
          this.page = data.meta.current_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    getFamilyClients() {
      axios
        .get(API_BASE_URL + "/family_clients", {
          headers: { Authorization: "Bearer " + this.$store.state.AccessToken },
        })
        .then(({ data }) => {
          this.$store.commit("setFamilyClients", data.data);
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    editItem(item) {
      this.$store.commit("seteditId", item.id);

      this.$store.commit("setFormMode", EDIT_MODE);
      this.$router.push("/family-clients/edit").catch(() => {});
    },
    deleteItem() {
      console.log("Delete Item");
    },

    updateSort(sortBy) {
      console.log("data =>", sortBy);
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
      console.log("sort by => ", this.sortBy);
    },
    updateSortOrder(sortDesc) {
      console.log("data => ", sortDesc);
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }

      this.fetchData();
      console.log("sort order => ", this.sortOrd);
    },
    onSearch() {
      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        { text: "ID", value: "id", sortable: false },
        {
          text: this.$t("family_client.name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: this.$t("family_client.is_default"), value: "is_default" },
      ];
    },
  },
};
</script>

<style scoped></style>
