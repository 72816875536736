<template>
  <div
    :row="row.index"
    :appointmentid="appointment.data.id"
    :column="colindex - 1"
    class="draggableObject"
    :class="addClass(row, colindex)"
    :style="addTaskStyle()"
    v-if="!hideForSomeTime && localAppointment"
  >
    <div class="draggableObjectData">
      <div @click="editAppointment()">
        <span class="text-sm-left">
          <template v-if="localAppointment.data">
            <template
              v-if="
                localAppointment.data.client &&
                localAppointment.data.client.name
              "
            >
              <v-icon
                style="font-size: 20px"
                color="white"
                v-if="
                  localAppointment &&
                  localAppointment.data &&
                  localAppointment.data.appointment_status &&
                  localAppointment.data.appointment_status.key ==
                    'appointment_is_executed'
                "
                >mdi-account-check</v-icon
              >

              <template v-if="localAppointment.data">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ localAppointment.data.client.name }}
                      {{
                        localAppointment.data.client.first_name
                          ? localAppointment.data.client.first_name
                          : ""
                      }}
                      <template v-if="localAppointment.data.vehicle">
                        <template
                          v-if="
                            localAppointment.data.client.name &&
                            localAppointment.data.client.name.length <= 22
                          "
                        >
                          <br />
                        </template>
                        <template v-else> - </template>

                        <template
                          v-if="
                            localAppointment.data.vehicle.brand_id &&
                            localAppointment.data.vehicle.brand
                          "
                        >
                          {{ localAppointment.data.vehicle.brand.name }}
                        </template>
                        <template v-if="localAppointment.data.vehicle.model">
                          {{ localAppointment.data.vehicle.model }}
                          /
                        </template>
                        {{
                          localAppointment.data.vehicle.registration_number.includes(
                            "#UNKNOWN"
                          )
                            ? $t("unknown_reg_no")
                            : localAppointment.data.vehicle.registration_number
                        }}
                      </template>
                    </span>
                  </template>
                  {{ localAppointment.data.client.name }}
                  {{
                    localAppointment.data.client.first_name
                      ? localAppointment.data.client.first_name
                      : ""
                  }}
                  -

                  <template
                    v-if="
                      localAppointment.data.vehicle &&
                      localAppointment.data.vehicle.brand_id &&
                      localAppointment.data.vehicle.brand
                    "
                  >
                    {{ localAppointment.data.vehicle.brand.name }}
                  </template>
                  <template
                    v-if="
                      localAppointment.data.vehicle &&
                      localAppointment.data.vehicle.model
                    "
                  >
                    {{ localAppointment.data.vehicle.model }}
                    /
                  </template>
                  <template v-if="localAppointment.data.vehicle">
                    {{
                      localAppointment.data.vehicle.registration_number.includes(
                        "#UNKNOWN"
                      )
                        ? $t("unknown_reg_no")
                        : localAppointment.data.vehicle.registration_number
                    }}
                  </template>
                </v-tooltip>
              </template>
              <!-- Bell Icon -->
              <span
                v-if="thirdIconIsVisible(localAppointment)"
                :style="{
                  backgroundColor: '#fff',
                  borderRadius: '2px',
                  float: 'right',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  marginTop: '3px',
                  marginBottom: '2px',
                  marginRight: calculateIconRightMargin('third', appointment),
                  height: '18.5px',
                  width: '18.5px',
                  textAlign: 'center',
                }"
              >
                <v-icon
                  :style="{
                    fontSize: '15px',
                    paddingBottom: '2px',
                  }"
                  color="red"
                  >mdi-bell-cancel</v-icon
                >
              </span>
              <!-- Icon for External Order Status -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    v-if="
                      localAppointment &&
                      localAppointment.data &&
                      localAppointment.data.order_id
                    "
                    :style="{
                      float: 'right',
                      position: 'absolute',
                      right: '0',
                      top: '0',
                      marginRight: calculateIconRightMargin(
                        'fourth',
                        localAppointment
                      ),
                      marginTop: '3px',
                      marginBottom: '2px',
                      backgroundColor: '#fff',
                      borderRadius: '2px',
                      height: '18.5px',
                      width: '18.5px',
                      textAlign: 'center',
                    }"
                  >
                    <template
                      v-if="
                        localAppointment &&
                        localAppointment.data &&
                        localAppointment.data.external_order_status &&
                        localAppointment.data.external_order_status !== null
                      "
                    >
                      <v-icon
                        :color="
                          computeExternalOrderStatus(
                            localAppointment.data.external_order_status
                          ).color
                        "
                        :style="{
                          fontSize: '15px',
                          paddingBottom: '2px',
                        }"
                      >
                        {{
                          computeExternalOrderStatus(
                            localAppointment.data.external_order_status
                          ).icon
                        }}
                      </v-icon>
                    </template>
                    <!-- Show icon for all other if order is their -->
                    <template v-else>
                      <v-icon
                        :color="computeExternalOrderStatus('success').color"
                        :style="{
                          fontSize: '15px',
                          paddingBottom: '2px',
                        }"
                      >
                        {{ computeExternalOrderStatus("success").icon }}
                      </v-icon>
                    </template>
                  </span>
                </template>
                <span
                  v-if="
                    localAppointment.data.external_order_status ==
                    'error_delivery_time'
                  "
                >
                  {{
                    $t(
                      "scheduler.text_messages.delivery_time_is_less_than_appointment_date"
                    )
                  }},
                </span>
                <span v-if="suppliersPresent">
                  {{ suppliersPresent }}
                </span>
                <span v-else>
                  {{
                    $t(
                      "scheduler.text_messages.all_articles_from_internal_or_received"
                    )
                  }}
                </span>
              </v-tooltip>
              <!-- Icon for External Order Status Ends Here -->

              <!-- Icon for Client Tyre Hotel Ended -->
              <span
                v-if="
                  localAppointment &&
                  localAppointment.data &&
                  localAppointment.data.is_end_of_client_tyre_hotel_article
                "
                :style="{
                  float: 'right',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  marginRight: calculateIconRightMargin(
                    'sixth',
                    localAppointment
                  ),
                  marginTop: '3px',
                  marginBottom: '2px',
                  backgroundColor: '#fff',
                  borderRadius: '2px',
                  height: '18.5px',
                  width: '18.5px',
                  textAlign: 'center',
                }"
              >
                <v-icon
                  color="red"
                  :style="{
                    fontSize: '15px',
                    paddingBottom: '2px',
                  }"
                >
                  mdi-clock-end
                </v-icon>
              </span>

              <!-- Icon for More Details of Appointment -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    @click.stop="showAppointmentDetail(localAppointment)"
                    v-if="
                      localAppointment &&
                      localAppointment.data &&
                      localAppointment.data.order_id
                    "
                    :style="{
                      float: 'right',
                      position: 'absolute',
                      right: '0',
                      bottom: '0',

                      marginRight: '2px',
                      marginBottom: '2px',
                      backgroundColor: 'transparent',
                      color: '#fff',
                      borderRadius: '2px',
                      textAlign: 'center',
                    }"
                  >
                    <appointment-details
                      v-if="showAppointmentDetails"
                      :showDialog.sync="showAppointmentDetails"
                      :data="localAppointment"
                    ></appointment-details>
                    <v-icon
                      color="white"
                      :style="{
                        fontSize: '18.5px',
                        paddingBottom: '2px',
                      }"
                    >
                      mdi-information
                    </v-icon>
                  </span>
                </template>
                <template
                  v-if="
                    localAppointment &&
                    localAppointment.data &&
                    localAppointment.data.order_id
                  "
                >
                  <template
                    v-if="localAppointment.data.order_type_id == 10241000"
                  >
                    {{ $t("billings.types.estimation") }}
                  </template>
                  <template
                    v-if="localAppointment.data.order_type_id == 10241001"
                  >
                    {{ $t("billings.types.purchase_order") }}
                  </template>
                  <template
                    v-if="localAppointment.data.order_type_id == 10241002"
                  >
                    {{ $t("billings.types.bill") }}
                  </template>
                  <template
                    v-if="localAppointment.data.order_type_id == 10241003"
                  >
                    {{ $t("billings.types.credit_note") }}
                  </template>
                </template>
                <template v-else>
                  {{ $t("scheduler.text_messages.appointment_detail") }}
                </template>
              </v-tooltip>

              <!-- Icon for Copy Appointment -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    class="appointmentCut"
                    @click.stop="
                      appointmentToPaste &&
                      appointmentToPaste == localAppointment
                        ? cancelAppointment()
                        : copyAppointment(appointment)
                    "
                    v-if="localAppointment && localAppointment.data"
                    :style="{
                      float: 'right',
                      position: 'absolute',
                      right: '0',
                      bottom: '0',

                      marginRight: '24px',
                      marginBottom: '2px',
                      backgroundColor: 'transparent',
                      color: '#fff',
                      borderRadius: '2px',
                      textAlign: 'center',
                    }"
                  >
                    <v-icon
                      color="white"
                      :style="{
                        fontSize: '18.5px',
                        paddingBottom: '2px',
                      }"
                    >
                      {{
                        appointmentToPaste &&
                        appointmentToPaste == localAppointment
                          ? "mdi-arrow-all"
                          : "mdi-content-cut"
                      }}
                    </v-icon>
                  </span>
                </template>
                {{
                  appointmentToPaste && appointmentToPaste == localAppointment
                    ? $t("scheduler.text_messages.cancel_appointment_move")
                    : $t("scheduler.text_messages.move_appointment")
                }}
              </v-tooltip>

              <!-- Icon for Order Payed -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    v-if="
                      localAppointment &&
                      localAppointment.data &&
                      localAppointment.data.order_status_id == 10251003 // Payed
                    "
                    :style="{
                      float: 'right',
                      position: 'absolute',
                      right: '0',
                      top: '0',
                      marginRight: calculateIconRightMargin(
                        'seventh',
                        localAppointment
                      ),
                      marginTop: '3px',
                      marginBottom: '2px',
                      backgroundColor: '#fff',
                      borderRadius: '2px',
                      height: '18.5px',
                      width: '18.5px',
                      textAlign: 'center',
                    }"
                  >
                    <v-icon
                      color="green"
                      :style="{
                        fontSize: '15px',
                        paddingBottom: '2px',
                      }"
                    >
                      mdi-cash-check
                    </v-icon>
                  </span>
                </template>
                {{ $t("scheduler.text_messages.order_payed") }}
                <template
                  v-if="
                    localAppointment &&
                    localAppointment.data &&
                    localAppointment.data.payments
                  "
                >
                  {{ generatePaymentText(localAppointment.data.payments) }}
                </template>
              </v-tooltip>

              <!-- Icon for Email Sent -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    @click.stop="openEmailHistoryDialog(localAppointment)"
                    v-on="on"
                    v-bind="attrs"
                    v-if="
                      localAppointment &&
                      localAppointment.data &&
                      localAppointment.data.email_to
                    "
                    :style="{
                      float: 'right',
                      position: 'absolute',
                      right: '0',
                      top: '0',
                      marginRight: calculateIconRightMargin(
                        'eighth',
                        localAppointment
                      ),
                      marginTop: '3px',
                      marginBottom: '2px',
                      backgroundColor: '#fff',
                      borderRadius: '2px',
                      height: '18.5px',
                      width: '18.5px',
                      textAlign: 'center',
                    }"
                  >
                    <v-icon
                      color="green"
                      :style="{
                        fontSize: '15px',
                        paddingBottom: '2px',
                      }"
                    >
                      mdi-email-arrow-right-outline
                    </v-icon>
                  </span>
                </template>
                {{ $t("scheduler.text_messages.email_sent") }}
              </v-tooltip>
            </template>
          </template>
        </span>
        <span class="text-sm-left" v-if="localAppointment.data.note">
          <br />
          <span>
            <strong>{{ $t("scheduler.appointment.note") }}</strong>
            {{ localAppointment.data.note }}
          </span>
        </span>
        <span
          class="original_st"
          v-if="
            localAppointment.data &&
            localAppointment.data.original_start_time &&
            localAppointment.data.original_start_time !=
              localAppointment.data.start_time
          "
        >
          <v-icon color="white" v-if="firstIconIsVisible(localAppointment)"
            >mdi-code-greater-than</v-icon
          >
          <v-icon
            color="white"
            :style="{
              marginRight: calculateIconRightMargin('second', localAppointment),
            }"
            v-if="secondIconIsVisible(localAppointment)"
            >mdi-code-less-than</v-icon
          >
        </span>
        <span
          :style="{
            marginRight: calculateIconRightMargin('fifth', localAppointment),
            backgroundColor: '#fff',
            borderRadius: '2px',
            height: '18.5px',
            width: '18.5px',
            textAlign: 'center',
          }"
          style="margin-top: 2px"
          class="original_st"
          v-if="
            localAppointment.data && localAppointment.data.depot_out_status_key
          "
        >
          <DepotStatus
            size="15px"
            :status-key="localAppointment.data.depot_out_status_key"
          />
        </span>
      </div>
      <!-- <div class="bottom-box">
                <template v-if="isBill(row, colindex)">
                    <v-btn icon color="rgb(18, 170, 31)" @click="editAppointment(row.index, colindex-1)">
                        <v-icon>mdi-check-bold</v-icon>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn v-if="! isPastDate()" icon color="white" @click="deleteAppointment(row.index, colindex-1, row)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon color="white" @click="editAppointment(row.index, colindex-1)">
                        <v-icon>mdi-calendar-edit</v-icon>
                    </v-btn>
                </template>
                
            </div> -->
    </div>
    <span id="cancel-move" class="d-none" @click="cancelAppointment"></span>

    <OrderEmailHistory
      v-if="emailHistoryDialog"
      :order="selectedAppointmentForDialog || {}"
      v-model="emailHistoryDialog"
    />
  </div>
</template>
<script>
import DepotStatus from "@/components/TyreHotel/DepotStatus.vue";
import AppointmentDetails from "./AppointmentDetails.vue";
import ToastSchedulerMoveConfirmation from "@/components/ToastSchedulerMoveConfirmation.vue";
import OrderEmailHistory from "@/components/Emails/OrderEmailHistory.vue";

export default {
  components: { DepotStatus, AppointmentDetails, OrderEmailHistory },
  props: {
    rows: [Array],
    otherAppointmentCounts: [Number],
    appointmentIndexInSameSlot: [Number],
    appointment: [Object],
    row: [Object],
    colindex: [Number],
    noofcolumns: [Number],
  },
  data() {
    return {
      maxWidth: 176,
      showAppointmentDetails: false,
      emailHistoryDialog: false,
      hideForSomeTime: false,
      selectedAppointmentForDialog: null,
      localAppointment: this.appointment,
    };
  },
  mounted() {
    // this.maxWidth = this.getDayViewWidth() / this.noofcolumns;
  },
  computed: {
    allSuppliers() {
      return this.$store.state.allSuppliers ?? [];
    },
    appointmentToPaste() {
      return this.$store.state.copiedAppointment;
    },
    suppliersPresent() {
      const supplierNames = this.localAppointment.data.supplier_orders
        .map((element) => {
          const supplier = this.allSuppliers.find(
            (supplier) => supplier.id === element.supplier_id
          );
          return supplier ? supplier.name : "";
        })
        .filter((name) => name !== "");

      return supplierNames.join(", ");
    },
  },
  methods: {
    openEmailHistoryDialog(appointment) {
      this.selectedAppointmentForDialog = appointment.data.order_id;
      this.emailHistoryDialog = false;
      this.timerId = setTimeout(() => {
        this.emailHistoryDialog = true;
        clearTimeout(this.timerId);
      }, 20);
    },
    cancelAppointment() {
      this.$toast.clear();
      // Set Appointment to null
      this.$store.commit("setCopiedAppointment", null);
    },
    copyAppointment(appointment) {
      this.$toast.clear();
      this.$store.commit("setCopiedAppointment", appointment);
      this.$toast(
        {
          component: ToastSchedulerMoveConfirmation,
          props: {
            message: this.$t(
              "scheduler.text_messages.appointment_ready_to_move"
            ),
            buttonText: this.$t("scheduler.text_messages.cancel_move"),
          },
          on: {
            "close-toast": () => {
              this.$toast.goAway(0); // Close the toast immediately
            },
          },
        },
        {
          timeout: 500000,
        }
      );
    },
    showAppointmentDetail() {
      this.showAppointmentDetails = true;
    },
    addClass(row, colindex) {
      let classToBeAdded = this.isBill(row, colindex) ? "bill" : "";
      if (
        this.localAppointment &&
        this.localAppointment.data &&
        this.localAppointment.data.appointment_status &&
        this.localAppointment.data.appointment_status.key ==
          "appointment_not_executed"
      ) {
        classToBeAdded += " not-executed";
      }
      return classToBeAdded;
    },
    isBill(row, colindex) {
      return row.items[colindex - 1].data && row.items[colindex - 1].data
        ? row.items[colindex - 1].data.order_type_id == 10241002
          ? true
          : false
        : false;
    },
    addTaskStyle() {
      let unitAppointmentMaxWidth = this.maxWidth;
      if (this.otherAppointmentCounts > 0) {
        unitAppointmentMaxWidth = this.maxWidth / this.otherAppointmentCounts;
      }

      let style = {
        height: `${this.localAppointment.height}px`,
        width: `${unitAppointmentMaxWidth}px`,
        "margin-left": `${
          unitAppointmentMaxWidth * this.appointmentIndexInSameSlot
        }px`,
        "background-color": `#${this.localAppointment.data.color}`,
      };

      return style;
    },
    editAppointment() {
      this.$store.commit("setAppointmentRedirectData", {
        id: this.localAppointment.data.id,
        status: this.localAppointment.data.appointment_status,
      });
      this.$router.push("/scheduler/edit").catch(() => {});
    },
  },
  watch: {
    localAppointment: {
      handler: function () {
        this.hideForSomeTime = true;
        this.$nextTick(() => {
          this.hideForSomeTime = false;
        });
      },
    },
    appointment: {
      handler: function () {
        this.localAppointment = this.appointment;
      },
      immediate: true,
      deep: true,
    },
  },
  activated() {
    this.localAppointment = this.appointment;
  },
  destroyed() {
    this.localAppointment = null;
  },
  deactivated() {
    this.localAppointment = null;
  },
};
</script>
<style scoped>
.draggableObject {
  position: absolute;
  /* background-color: rgba(67, 180, 52, 0.733); */
  z-index: 1;
  min-height: 20px;
  margin-top: -25px;
  border: 2px solid white;
  /* width: 200px !important; */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: move !important;
  overflow: hidden;
}

.draggableObject:hover {
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.41);
  transform: scale(1.01, 1.01);
  z-index: 9;
  /* overflow: auto !important; */
}
.appointmentCut {
  display: none;
}
.draggableObject:hover .appointmentCut {
  display: block;
}

.draggableObject:hover > .draggableObjectData > .bottom-box {
  z-index: 9;
  transition: all 0.7s ease;
  background-color: rgba(12, 0, 0, 0.021);
  opacity: 1;
  bottom: 0px;
  right: 0px;
}

.draggableObjectData {
  color: white;
  margin: 10px;
  font-weight: 400;
  line-height: 18px;
  font-size: 14px;
  margin: 5px;
}

.draggableObjectData:hover {
  cursor: pointer;
}

.bill {
  border: 4px solid rgb(18, 170, 31);
}

.bottom-box {
  opacity: 0;
  position: absolute;
  bottom: -10px;
  right: -10px;
}

.not-executed {
  background-color: #ea9191 !important;
  border: 3px solid red;
}

.original_st {
  position: absolute;
  top: 0px;
  float: right;
  right: 0px;
  color: white;
}
</style>
