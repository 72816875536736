<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <span class="mt-6 mb-4 mr-6" @click="launchCompanyBankFormDialog">
        <AddButton />
      </span>
      <CompanyBankForm
        :bank="selectedBank"
        @updated="(newBanks) => (banks = newBanks)"
        v-model="companyBankFormDialog"
      />
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="formattedBanks"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item="props">
        <tr
          @mouseover="toggleDeleteButtonVisibility(props.item.id, true)"
          @mouseout="toggleDeleteButtonVisibility(props.item.id, false)"
          style="cursor: pointer"
        >
          <!-- Bank Name -->
          <td @click="editBank(props.item)">
            {{ props.item.bank_name }}
          </td>
          <!-- Branch Name -->
          <td @click="editBank(props.item)">
            {{ props.item.branch_name }}
          </td>
          <!-- Bank Account -->
          <td @click="editBank(props.item)">
            {{ props.item.bank_account }}
          </td>
          <!-- Swift Code -->
          <td @click="editBank(props.item)">
            {{ props.item.swift_code }}
          </td>
          <!-- Currency -->
          <td
            @click="editBank(props.item)"
            style="text-align: center"
            v-html="props.item.currency.symbol"
          ></td>
          <!-- Is Default -->
          <td @click="editBank(props.item)" style="text-align: center">
            <v-icon v-if="props.item.is_default" color="success"
              >mdi-check-bold</v-icon
            >
          </td>
          <!-- Is Show On Bill -->
          <td @click="editBank(props.item)" style="text-align: center">
            <v-icon v-if="props.item.is_show_on_bill" color="success"
              >mdi-check-bold</v-icon
            >
          </td>
          <!-- Ribs -->
          <td style="text-align: center">
            <template v-if="props.item.ribs && props.item.ribs.length > 0">
              <v-icon
                @click="showFile(rib)"
                color="success"
                v-for="(rib, index) in props.item.ribs"
                :key="index"
              >
                mdi-file-document
              </v-icon>
            </template>
          </td>
          <!-- Action -->
          <td>
            <v-btn
              icon
              :hidden="true"
              :id="'delete_button_' + props.item.id"
              @click="deleteBankPopup(props.item.id)"
            >
              <v-icon color="red"> mdi-delete </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:item.currency="{ item }">
        <span v-html="item.currency.symbol"></span>
      </template>
      <template v-slot:item.is_default="{ item }">
        <v-icon v-if="item.is_default" color="success">mdi-check-bold</v-icon>
      </template>
      <template v-slot:item.is_show_on_bill="{ item }">
        <v-icon v-if="item.is_show_on_bill" color="success"
          >mdi-check-bold</v-icon
        >
      </template>
      <template v-slot:item.rib="">
        <v-icon dark color="red"> mdi-file-document </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon :hidden="false" @click="deleteBankPopup(item.id)">
          <v-icon color="red"> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      openedForOperation="delete"
      :text="$t('company_settings.delete_bank_confirmation')"
      :trueText="$t('yes')"
      :falseText="$t('no')"
      @buttonClicked="(status) => (status ? deleteBank() : '')"
    ></confirmation-model>
  </div>
</template>
<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";
import AddButton from "@/components/Buttons/AddButton.vue";
import CompanyBankForm from "@/components/CompanySettings/CompanyBankForm.vue";
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
  name: "CompanyBanks",
  emits: ["input", "created", "updated", "deleted"],
  components: { ConfirmationModel, CompanyBankForm, AddButton },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formattedBanks() {
      return this.banks.map(function (bank) {
        return {
          ...bank,
          date: bank.created_at,
        };
      });
    },
  },
  data() {
    return {
      banks: [],
      selectedBank: null,
      selectedBankId: null,
      companyBankFormDialog: false,
      axiosHeaders: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      tableHeaders: [
        {
          text: this.$t("company_settings.bank_name"),
          value: "bank_name",
          sortable: false,
        },
        {
          text: this.$t("company_settings.branch_name"),
          value: "branch_name",
          sortable: false,
        },
        {
          text: this.$t("company_settings.account_number"),
          value: "bank_account",
          sortable: false,
        },
        {
          text: this.$t("company_settings.swift_code"),
          value: "swift_code",
          sortable: false,
        },
        {
          text: this.$t("company_settings.currency"),
          value: "currency",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("company_settings.default"),
          value: "is_default",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("company_settings.show_on_bill"),
          value: "is_show_on_bill",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("company_settings.rib"),
          value: "rib",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          width: 70,
          sortable: false,
        },
      ],
      confirmationDialog: false,
    };
  },
  watch: {
    companyId(val) {
      if (!val || !this.companyId) return null;

      this.getClientBanks();
    },
    companyBankFormDialog(val) {
      if (val == false) {
        // On Close refetch client banks
        this.banks = [];
        this.getClientBanks();
      }
    },
  },
  mounted() {
    this.getClientBanks();
  },
  methods: {
    launchCompanyBankFormDialog() {
      this.companyBankFormDialog = false;
      this.selectedBank = null;
      setTimeout(() => (this.companyBankFormDialog = true), 10);
    },
    resetCompanyBankAttachments() {
      this.company_bank_attachments = [];
      localStorage.setItem(
        "bankAttachments",
        JSON.stringify(this.company_bank_attachments)
      );
    },
    getCompanyBanksRIB(bank_id) {
      let module_id = this.getModuleId("company_banks"); // Company_banks as in Application Resource
      if (module_id && bank_id) {
        axios
          .get(
            API_BASE_URL +
              `/attachments?module_id=${module_id}&resource_key_id=${bank_id}`,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
              },
            }
          )
          .then(({ data }) => {
            // Reset Company Bank attachments, to update its data again
            this.resetCompanyBankAttachments();

            data.data.result.forEach((bankAttachment) => {
              bankAttachment.bank_id = bank_id;
            });
            let attachments = data.data.result;

            this.updateBanksRIB(bank_id, attachments);

            // Update Company Bank Attachments
            this.company_bank_attachments =
              this.company_bank_attachments.concat(attachments);
            localStorage.setItem(
              "bankAttachments",
              JSON.stringify(this.company_bank_attachments)
            );
          })
          .catch((error) => {
            console.log("an error occured " + error);
          })
          .finally(() => {});
      }
    },
    updateBanksRIB(bank_id, ribs) {
      let banks = this.banks;
      this.banks = []; // Making it empty to hard refresh table already mounted
      banks.forEach((bank) => {
        if (bank.id == bank_id) {
          bank.ribs = ribs;
        }
      });
      this.banks = banks;
    },
    getClientBanks() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_BASE_URL}/banks`, { headers: this.axiosHeaders })
          .finally(() => {})
          .then((response) => {
            this.banks = response.data.data;
            this.banks.forEach((element) => {
              this.getCompanyBanksRIB(element.id);
            });
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteBank(bankId = null) {
      return new Promise((resolve, reject) => {
        bankId = bankId ?? this.selectedBankId;
        axios
          .delete(`${API_BASE_URL}/banks/${bankId}`, {
            headers: this.axiosHeaders,
          })
          .finally(() => {})
          .then(() => {
            let bankIndex = this.banks.findIndex((b) => b.id === bankId);
            this.banks.splice(bankIndex, 1);
            this.$toast.success(this.$t("company_settings.bank_deleted"));
          })
          .catch((error) => {
            console.log(error.response.data.msg);
            this.$toast.error(error.response.data.msg);
            reject(error);
          });
      });
    },
    deleteBankPopup(bankId) {
      this.selectedBankId = bankId;
      this.confirmationDialog = true;
    },
    toggleDeleteButtonVisibility(bankId, show = true) {
      document.getElementById("delete_button_" + bankId).style.display = show
        ? "block"
        : "none";
    },
    editBank(bank) {
      if (this.confirmationDialog) return null;
      this.selectedBank = bank;
      this.companyBankFormDialog = true;
    },
  },
};
</script>
