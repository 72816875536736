import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
  data() {
    return {};
  },
  methods: {
    convertSelectedOrders() {
      if (
        this.listingSelectionsFlatten &&
        this.listingSelectionsFlatten.length > 0
      ) {
        let selectedOrderIds = this.listingSelectionsFlatten
          .map((element) => {
            return element.id;
          })
          .join(",");
        axios
          .post(
            API_BASE_URL + "/billings/convert/" + selectedOrderIds,
            { id: selectedOrderIds },
            { headers: this.header }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("setListingSelectionsFlatten", null);
              this.$toast.success(
                this.$t(
                  "billings.text_messages.converted_to_purchase_order_successfully"
                )
              );
            } else {
              this.$toast.error(
                this.$t(
                  "billings.text_messages.converted_to_purchase_order_failed"
                )
              );
            }
          })
          .catch(() => {
            this.$store.commit("setListingSelectionsFlatten", null);
            this.$toast.error(
              this.$t(
                "billings.text_messages.converted_to_purchase_order_failed"
              )
            );
          })
          .finally(() => {
            this.refetchData();
          });
      }
    },
  },
  computed: {
    listingSelectionsFlatten() {
      return this.$store.state.listingSelectionsFlatten;
    },
  },
};
