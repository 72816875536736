<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="intTime"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="intTime"
        class="pt-0"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        hide-details
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu2"
      v-model="intTime"
      full-width
      @click:minute="$refs.menu.save(intTime)"
    ></v-time-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    time: null,
    label: {
      type: String,
      default: "Time",
    },
  },
  data() {
    return {
      intTime: this.time ?? null,
      menu2: false,
      modal2: false,
    };
  },
  watch: {
    intTime(val) {
      this.$emit("update:time", val);
    },
    time(val) {
      this.intTime = val;
    },
  },
};
</script>
