<template>
  <v-dialog
    persistent
    @click:outside="closeDialogue()"
    @keydown.esc="closeDialogue()"
    v-model="internalShowDialog"
    min-width="1348"
    max-width="1648"
  >
    <v-card>
      <v-card-title class="text-title">
        <template v-if="!selectedItem">
          {{ $t("expeditions.add_new_discharging") }}
        </template>
        <template v-else>
          {{ $t("expeditions.edit_discharging") }}
        </template>
      </v-card-title>

      <v-card-text class="dialog-text text-content" color="black">
        <v-row>
          <v-col cols="12" sm="7">
            <!-- First Row Location and Contact Person and Map -->
            <v-row>
              <v-col cols="12" sm="12">
                <h3 class="expedition_title" style="color: #2196f3">
                  {{ $t("expeditions.location") }}
                </h3>
                <v-row class="mt-1">
                  <!-- New Address Component -->
                  <v-col cols="12" sm="10">
                    <AddressInput
                      class="mt-n3 pb-6"
                      v-model="location_address"
                      :error-messages="
                        $store.state.validation && !$v.location_address.required
                          ? [
                              this.$t(
                                'vehicles.clients_model.text_messages.location_address_required'
                              ),
                            ]
                          : []
                      "
                      :defaultAddressType="'billing_address'"
                      :label="$t('billings.shipping_address')"
                      :hide-details="true"
                      :searchWithoutCountry="true"
                      :singleLine="false"
                      :required="true"
                      :detailedViewAllowed="true"
                      :searchWithLocationName="true"
                      :showLocationName="true"
                    />
                  </v-col>
                  <!-- Appointment and Share Real time Location -->
                  <v-col cols="12" sm="2">
                    <v-checkbox
                      :label="$t('expeditions.appointment')"
                      v-model="is_appointment"
                      color="green"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Discharging Goods -->
            <div class="mt-1">
              <h3 class="expedition_title" style="color: #2196f3">
                {{ $t("expeditions.discharging_goods") }}
              </h3>
            </div>
            <!-- Goods -->
            <v-row>
              <v-col cols="12" sm="2">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.reference')"
                  v-model="reference"
                  v-on:keydown.enter.prevent=""
                  v-on:keydown="handleInput($event, 'alphanumeric', 150)"
                  :error-messages="
                    reference && reference.length > 150
                      ? [
                          this.$t(
                            'expeditions.text_messages.max_150_character_allowed'
                          ),
                        ]
                      : []
                  "
                  @change="
                    checkReferenceInExpeditions(
                      reference,
                      isEditExpeditionPage
                        ? $store.state.selectedExpedition.id
                        : null
                    )
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="selectedArticle"
                  class="to-upper"
                  :items="chargingArticles"
                  item-text="original_name"
                  item-value="id"
                  :label="$t('expeditions.search_article')"
                  return-object
                  :clearable="selectedArticle ? true : false"
                  clear-icon="mdi-close"
                  v-on:keydown="handleInput($event, 'alphanumeric', 20)"
                  v-on:keydown.enter.prevent=""
                  ref="billDropdown"
                  :outlined="is_boxes"
                  :hide-no-data="true"
                  hide-selected
                  :dense="is_boxes"
                  hide-details
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>
                    {{ $t("expeditions.search_article") }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  v-model="palets"
                  class="to-upper"
                  v-on:keydown.enter.prevent=""
                  v-on:keydown="handleInput($event, 'float', 80)"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  :suffix="$t('expeditions.unit_palets')"
                  hide-details
                >
                  <template #label>
                    {{ $t("expeditions.unit_palets") }}
                  </template>
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="2">
                                <div class="mt-6">
                                    <span :style="calculatePendingQuantitiesOfArticle() < 0 ? {color:'red'} : {}">{{ this.$t('expeditions.remaining_palets') + ": " }} {{ calculatePendingQuantitiesOfArticle() }}</span>
                                </div>
                            </v-col> -->
            </v-row>

            <!-- Tons -->
            <v-row class="mt-n4">
              <v-col cols="12" sm="4">
                <custom-money-input
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.weight')"
                  :required="true"
                  v-model="tons"
                  v-on:keydown="handleInput($event, 'int', 20)"
                  :suffix="$t('expeditions.unit_tons')"
                  v-bind:properties="{
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    placeholder: '0.00',
                    dense: false,
                    hideDetails: false,
                  }"
                  v-bind:options="{
                    locale: userLanguageId,
                    length: 10,
                    precision: 2,
                    empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                  }"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <custom-money-input
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  v-model="floor_meter"
                  :required="true"
                  :label="$t('expeditions.floor_meter')"
                  v-on:keydown="handleInput($event, 'int', 20)"
                  :suffix="$t('expeditions.unit_floor_meter')"
                  v-bind:properties="{
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    placeholder: '0.00',
                    dense: false,
                    hideDetails: false,
                  }"
                  v-bind:options="{
                    locale: userLanguageId,
                    length: 10,
                    precision: 2,
                    empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                  }"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <custom-money-input
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  v-model="meter_cubs"
                  :label="$t('expeditions.meter_cubs')"
                  v-on:keydown="handleInput($event, 'int', 20)"
                  :suffix="$t('expeditions.unit_meter_cubs')"
                  v-bind:properties="{
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    placeholder: '0.00',
                    dense: false,
                    hideDetails: false,
                  }"
                  v-bind:options="{
                    locale: userLanguageId,
                    length: 10,
                    precision: 2,
                    empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                  }"
                />
              </v-col>
              <v-spacer></v-spacer>
              <!-- <v-col cols="12" sm="2">
                                <v-text-field
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="meter_width"
                                    class="to-upper"
                                    v-on:keydown.enter.prevent=""
                                    v-on:keydown="handleInput($event, 'float', 20)"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                    :suffix="$t('expeditions.unit_width')"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="meter_height"
                                    class="to-upper"
                                    v-on:keydown.enter.prevent=""
                                    v-on:keydown="handleInput($event, 'float', 20)"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                    :suffix="$t('expeditions.unit_height')"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="meter_length"
                                    class="to-upper"
                                    v-on:keydown.enter.prevent=""
                                    v-on:keydown="handleInput($event, 'float', 20)"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                    :suffix="$t('expeditions.unit_length')"
                                    hide-details
                                ></v-text-field>
                            </v-col> -->
            </v-row>

            <!-- Charging Date and Time -->
            <h6 class="mt-4 expedition_title" style="color: #2196f3">
              {{ $t("expeditions.discharging_date_and_time") }}
            </h6>
            <v-row>
              <v-col cols="12" sm="3">
                <v-menu
                  v-model="menu_search_from_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="search_from_date"
                      :label="$t('expeditions.from')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:append="search_from_date = null"
                      append-icon="mdi-close"
                      class="mr-2 to-upper"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      hide-no-data
                      hide-details
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>
                        {{ $t("expeditions.from") }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    v-model="search_from_date"
                    @input="menu_search_from_date = false"
                    no-title
                    scrollable
                    :locale="userLanguageId"
                    hide-no-data
                    hide-details
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  v-model="menu_search_to_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="search_to_date"
                      :label="$t('expeditions.to')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:append="search_to_date = null"
                      append-icon="mdi-close"
                      class="mr-2 to-upper"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      hide-no-data
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    v-model="search_to_date"
                    @input="menu_search_to_date = false"
                    no-title
                    scrollable
                    :locale="userLanguageId"
                    hide-no-data
                    hide-details
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.from')"
                  v-model="search_from_time"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  class="to-upper right-aligned-input shrink"
                  :label="$t('expeditions.to')"
                  v-model="search_to_time"
                  type="time"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Transport Types -->
            <v-row class="mb-5 mt-2">
              <v-col cols="12" sm="12">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select
                      clearable
                      :label="$t('expeditions.truck_types')"
                      :items="expeditionVehicleTypes"
                      v-model="expeditionVehicleType"
                      @change="
                        $emit(
                          'update:expeditionVehicleType',
                          expeditionVehicleType
                        )
                      "
                      item-value="id"
                      item-text="text"
                      menu-props="auto"
                      multiple
                      hide-details="auto"
                      dense
                    >
                      <template v-slot:selection="{ item, index }">
                        <span>
                          <template
                            v-if="
                              index === 0 && expeditionVehicleType.length == 1
                            "
                          >
                            {{
                              item.text.substr(
                                0,
                                expeditionVehicleType.length > 1 ? 10 : 12
                              )
                            }}
                          </template>
                          <template v-if="index === 1">
                            {{
                              item.text.substr(
                                0,
                                expeditionVehicleType.length > 1 ? 10 : 12
                              )
                            }}
                            (+{{ expeditionVehicleType.length - 1 }})
                          </template>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      clearable
                      :label="$t('expeditions.body_type')"
                      :items="expeditionVehicleBodyTypes"
                      v-model="expeditionVehicleBodyType"
                      @change="
                        $emit(
                          'update:expeditionVehicleBodyType',
                          expeditionVehicleBodyType
                        )
                      "
                      multiple
                      item-value="id"
                      item-text="text"
                      dense
                    >
                      <template v-slot:selection="{ item, index }">
                        <span>
                          <template
                            v-if="
                              index === 0 &&
                              expeditionVehicleBodyType.length == 1
                            "
                          >
                            {{
                              item.text.substr(
                                0,
                                expeditionVehicleBodyType.length > 1 ? 10 : 12
                              )
                            }}
                          </template>
                          <template v-if="index === 1">
                            {{
                              item.text.substr(
                                0,
                                expeditionVehicleBodyType.length > 1 ? 10 : 12
                              )
                            }}
                            (+{{ expeditionVehicleBodyType.length - 1 }})
                          </template>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      clearable
                      :label="$t('expeditions.characteristics')"
                      :items="expeditionVehicleBodyCharacteristicsTypes"
                      v-model="expeditionVehicleBodyCharacteristicsType"
                      @change="
                        $emit(
                          'update:expeditionVehicleBodyCharacteristicsType',
                          expeditionVehicleBodyCharacteristicsType
                        )
                      "
                      multiple
                      item-value="id"
                      item-text="text"
                      menu-props="auto"
                      hide-details="auto"
                      dense
                    >
                      <template v-slot:selection="{ item, index }">
                        <span>
                          <template
                            v-if="
                              index === 0 &&
                              expeditionVehicleBodyCharacteristicsType.length ==
                                1
                            "
                          >
                            {{
                              item.text.substr(
                                0,
                                expeditionVehicleBodyCharacteristicsType.length >
                                  1
                                  ? 10
                                  : 12
                              )
                            }}
                          </template>
                          <template v-if="index === 1">
                            {{
                              item.text.substr(
                                0,
                                expeditionVehicleBodyCharacteristicsType.length >
                                  1
                                  ? 10
                                  : 12
                              )
                            }}
                            (+{{
                              expeditionVehicleBodyCharacteristicsType.length -
                              1
                            }})
                          </template>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- note -->
            <v-row class="mt-n8">
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="note"
                  :label="$t('expeditions.note')"
                  rows="3"
                  class="to-upper mt-2"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="5">
            <v-row class="mt-2">
              <v-col
                sm="12"
                style="
                  min-height: 200px !important;
                  max-height: 200px !important;
                "
              >
                <div
                  id="mapdischarging"
                  class="map"
                  style="
                    width: 100%;
                    min-height: 175px !important;
                    height: 455px;
                  "
                ></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pr-2">
        <v-btn color="grey lighten-2" class="px-4" @click="closeDialogue()">
          {{ $t("expeditions.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" class="px-4" @click="addCharge()">
                    {{ $t('expeditions.add_charge') }}
                    <v-icon dark right> mdi-package-variant </v-icon>
                </v-btn> -->
        <!-- <v-btn :disabled="addingDischargeAllowed() ? false : true" color="success" class="px-4" v-if="!selectedItem" @click="addDischargeAndAddNew()">
                    {{ $t('expeditions.add_and_new_discharge') }}
                    <v-icon dark right> mdi-package-variant-closed-plus </v-icon>
                </v-btn> -->
        <v-btn
          :disabled="addingDischargeAllowed() ? false : true"
          color="success"
          class="px-4"
          v-if="!selectedItem"
          @click="addDischarge()"
        >
          {{ $t("expeditions.add_discharge") }}
          <v-icon dark right> mdi-package-variant-closed </v-icon>
        </v-btn>
        <!-- <v-btn :disabled="addingDischargeAllowed() ? false : true" color="success" class="px-4" v-if="selectedItem" @click="updateDischargeAndAddNew()">
                    {{ $t('expeditions.update_and_new_discharge') }}
                    <v-icon dark right> mdi-package-variant-closed-plus </v-icon>
                </v-btn> -->
        <v-btn
          :disabled="addingDischargeAllowed() ? false : true"
          color="success"
          class="px-4"
          v-if="selectedItem"
          @click="updateDischarge()"
        >
          {{ $t("expeditions.update_discharge") }}
          <v-icon dark right> mdi-package-variant-closed </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <loading-model :showLoadingModel.sync="showLoadingModel"></loading-model>
  </v-dialog>
</template>

<script>
import { Map, Marker } from "maplibre-gl";
import AddressInput from "@/components/AddressInput.vue";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput.vue";
import { validationMixin } from "vuelidate";
import LoadingModel from "@/components/LoadingModel.vue";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "ExpeditionDischarging",
  validations: {
    location_address: {
      required,
    },
    reference: {
      maxLength: maxLength(150),
    },
  },
  components: {
    "custom-money-input": CutsomMoneyInput,
    AddressInput,
    "loading-model": LoadingModel,
  },
  props: {
    showDialog: [Number, Boolean],
    openMode: [String],
    languages: [Array],
    chargings: [Array],
    dischargings: [Array],
    selectedItem: [Object],
    denominations: [Array],
    expeditionVehicleType: [Array, Object],
    expeditionVehicleTypes: [Array, Object],
    expeditionVehicleBodyType: [Array, Object],
    expeditionVehicleBodyTypes: [Array, Object],
    expeditionVehicleBodyCharacteristicsType: [Array, Object],
    expeditionVehicleBodyCharacteristicsTypes: [Array, Object],
  },
  data() {
    return {
      showLoadingModel: false,
      internalShowDialog: this.showDialog,
      mapCordinates: [6.131935, 49.611622],
      location_address: null,
      is_appointment: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      note: null,
      autocompleteValueLocal: "off",
      palets: 0,
      language: null,
      menu_search_from_date: false,
      search_from_date: null,
      menu_search_to_date: false,
      search_to_date: null,
      menu_search_from_time: false,
      search_from_time: "00:00",
      menu_search_to_time: false,
      search_to_time: "23:59",

      chargingArticles: [],
      selectedArticle: null,

      reference: null,
      tons: null,
      floor_meter: 0,
      meter_cubs: 0,
      meter_height: 0,
      meter_length: 0,
      meter_width: 0,
    };
  },
  mounted() {
    this.onResetPageAddress();
    this.$store.commit("setValidation", false); // enable validation

    // Edit Case Handle
    if (this.selectedItem && this.openMode == "edit") {
      (this.location_address = this.selectedItem.address),
        (this.is_appointment = this.selectedItem.is_appointment),
        (this.note = this.selectedItem.note),
        (this.palets = this.selectedItem.palets),
        (this.language = this.selectedItem.language),
        (this.search_from_date = this.selectedItem.search_from_date),
        (this.search_to_date = this.selectedItem.search_to_date),
        (this.search_from_time = this.selectedItem.search_from_time),
        (this.search_to_time = this.selectedItem.search_to_time),
        (this.selectedArticle = this.selectedItem.selectedArticle);
      this.selectedArticle.quantity = this.selectedItem.palets;
      this.reference = this.selectedItem.reference;

      this.floor_meter = this.selectedItem.floor_meter;
      this.meter_cubs = this.selectedItem.meter_cubs;
      this.meter_height = this.selectedItem.meter_height;
      this.meter_length = this.selectedItem.meter_length;
      this.meter_width = this.selectedItem.meter_width;
      this.tons = this.selectedItem.tons;
    }
    this.$nextTick(() => {
      this.reloadMap();
    });
    this.chargings.forEach((element) => {
      let alreadyAdded = false;
      if (this.chargingArticles && this.chargingArticles.length > 0) {
        this.chargingArticles.forEach((addedArticle) => {
          if (addedArticle.id == element.selectedArticle.id) {
            alreadyAdded = addedArticle;
          }
        });
      }
      if (alreadyAdded) {
        alreadyAdded["quantity"] =
          parseInt(element["quantity"] ?? 0) +
          parseInt(alreadyAdded.quantity ?? 0);
      } else {
        element.selectedArticle["quantity"] = parseInt(
          element["quantity"] ?? 0
        );
        this.chargingArticles.push(element.selectedArticle);
      }
    });

    if (this.openMode == "add") {
      this.selectedArticle = this.chargingArticles[0];
      // this.palets = this.calculatePendingQuantitiesOfArticle();
      this.updateValuesOfTonsLikeVariables();
    }
  },
  activated() {},
  methods: {
    updateLocationAddress(data) {
      this.location_address = data;
    },
    checkArticleChargingDate(article) {
      if (!this.search_from_date) {
        this.chargings.forEach((element) => {
          if (
            typeof element == "object" &&
            element.article_id &&
            typeof article == "object"
          ) {
            if (element.article_id == article.id) {
              this.search_from_date = element.search_from_date;
            }
          } else if (
            typeof element == "object" &&
            element.selectedArticle.id &&
            typeof article == "object"
          ) {
            if (element.selectedArticle.id == article.id) {
              this.search_from_date = element.search_from_date;
            }
          }
        });
      }
    },
    updateValuesOfTonsLikeVariables() {
      if (this.openMode == "add") {
        if (this.selectedArticle) {
          if (this.chargings.length > 0 && this.selectedArticle) {
            this.chargings.forEach((charging) => {
              if (this.selectedArticle.id == charging.selectedArticle.id) {
                this.tons = charging.tons;
                this.floor_meter = charging.floor_meter;
                this.meter_cubs = charging.meter_cubs;
              }
            });
          }
        } else {
          this.tons = 0;
          this.floor_meter = 0;
          this.meter_cubs = 0;
        }
      }
      if (this.openMode == "edit") {
        if (this.selectedArticle) {
          if (this.chargings.length > 0 && this.selectedArticle) {
            this.chargings.forEach((charging) => {
              if (
                this.selectedArticle.id == charging.selectedArticle.id &&
                this.tons == 0 &&
                this.floor_meter == 0 &&
                this.meter_cubs == 0
              ) {
                this.tons = charging.tons;
                this.floor_meter = charging.floor_meter;
                this.meter_cubs = charging.meter_cubs;
              }
            });
          }
        } else {
          this.tons = 0;
          this.floor_meter = 0;
          this.meter_cubs = 0;
        }
      }
    },
    calculatePendingQuantitiesOfArticle() {
      if (this.selectedArticle) {
        let totalAlreadyAddedPalet = 0;
        if (this.openMode == "edit") {
          this.dischargings.forEach((addeddischargings) => {
            if (
              addeddischargings.selectedArticle.id == this.selectedArticle.id &&
              this.selectedItem.srno != addeddischargings.srno
            ) {
              totalAlreadyAddedPalet =
                parseInt(totalAlreadyAddedPalet) +
                parseInt(addeddischargings.palets);
            }
          });
        } else {
          this.dischargings.forEach((addeddischargings) => {
            if (
              addeddischargings.selectedArticle.id == this.selectedArticle.id
            ) {
              totalAlreadyAddedPalet =
                parseInt(totalAlreadyAddedPalet) +
                parseInt(addeddischargings.palets);
            }
          });
        }
        // Some Dischargins are already added
        return (
          parseInt(
            this.totalChargingQuantity ? this.totalChargingQuantity : 0
          ) -
          parseInt(totalAlreadyAddedPalet ? totalAlreadyAddedPalet : 0) -
          parseInt(this.palets ? this.palets : 0)
        );
      }
      return 0;
    },
    onResetPageAddress() {
      this.location_address = null;
      this.is_appointment = null;
    },
    addingDischargeAllowed() {
      let isAllowed = false;
      if (
        this.selectedArticle &&
        this.search_from_date &&
        this.location_address &&
        typeof this.location_address == "object" &&
        this.floor_meter &&
        this.tons
      ) {
        isAllowed = true;
      }

      return isAllowed;
      // return true;
    },
    resetDischargePage() {
      this.onResetPageAddress();
      this.location_address = null;
      this.is_appointment = null;
      this.note = null;
      this.palets = 0;
      this.language = null;
      this.search_from_date = null;
      this.search_to_date = null;
      this.search_from_time = "00:00";
      this.search_to_time = "23:59";
      this.selectedArticle = null;
      this.reference = null;

      this.floor_meter = null;
      this.meter_cubs = null;
      this.meter_height = null;
      this.meter_length = null;
      this.meter_width = null;
      this.tons = null;
    },
    addNecessaryData() {
      return new Promise((resolve) => {
        resolve();
      });
    },
    updateDischargeAndAddNew() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        return false;
      }
      this.addNecessaryData().then(() => {
        let data = this.makeDischargeData();
        this.$emit("updated", data);
        this.$emit("reOpenDialog", data);
        // this.resetDischargePage();
        this.$store.commit("setValidation", false); // enable validation
      });
    },
    updateDischarge() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        return false;
      }
      this.addNecessaryData().then(() => {
        let data = this.makeDischargeData();
        this.$emit("updated", data);
        // this.resetDischargePage();
        this.$store.commit("setValidation", false); // enable validation
        this.closeDialogue();
      });
    },
    addDischargeAndAddNew() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        return false;
      }
      this.addNecessaryData().then(() => {
        let data = this.makeDischargeData();
        this.$emit("added", data);
        this.$emit("reOpenDialog", data);
        // this.resetDischargePage();
      });
    },
    addDischarge() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        return false;
      }
      this.addNecessaryData().then(() => {
        let data = this.makeDischargeData();
        this.$emit("added", data);
        // this.resetDischargePage();
        this.closeDialogue();
      });
    },
    makeDischargeData() {
      return {
        address: this.location_address,
        is_appointment: this.is_appointment,
        note: this.note,
        palets: this.palets,
        language: this.language,
        search_from_date: this.search_from_date,
        search_to_date: this.search_to_date,
        search_from_time: this.search_from_time,
        search_to_time: this.search_to_time,

        selectedArticle: this.selectedArticle,
        reference: this.reference,

        floor_meter: this.floor_meter,
        meter_cubs: this.meter_cubs,
        meter_height: this.meter_height,
        meter_length: this.meter_length,
        meter_width: this.meter_width,
        tons: this.tons,
      };
    },
    addCharge() {
      this.$emit("changeDialogue", true);
    },
    async reloadMap() {
      if (this.mapCordinates && this.mapCordinates.length > 0) {
        setTimeout(() => {
          try {
            let mapOptions = {
              container: "mapdischarging",
              style:
                "https://api.maptiler.com/maps/streets-v2/style.json?key=f4IJi1mRlspltChrcUM3",
              zoom: 15,
              center: this.mapCordinates,
            };
            let map = new Map(mapOptions);
            if (this.mapCordinates) {
              new Marker({ color: "#FF0000" })
                .setLngLat(this.mapCordinates)
                .addTo(map);
            }
          } catch (e) {
            console.log("error on loading map", e);
          }
        }, 500);
      }
    },
    closeDialogue() {
      this.$emit("update:showDialog", false);
      this.internalShowDialog = false;
    },
    trueButtonClicked() {
      this.resetCheckBoxText();
      this.$emit("update:showDialog", false);
      this.$emit("added", true);
      this.internalShowDialog = false;
    },
  },
  computed: {
    isEditExpeditionPage() {
      return this.$route.path.indexOf("/edit") > 0;
    },
    totalChargingQuantity() {
      let totalChargingQuantitytemp = 0;
      if (this.chargings.length > 0 && this.selectedArticle) {
        this.chargings.forEach((charging) => {
          if (
            this.selectedArticle.id == charging.selectedArticle.id &&
            charging.selectedArticle.quantity
          ) {
            totalChargingQuantitytemp =
              totalChargingQuantitytemp + charging.selectedArticle.quantity;
          }
        });
      }
      return totalChargingQuantitytemp;
    },
  },
  watch: {
    selectedArticle(val) {
      this.checkArticleChargingDate(val);
      this.updateValuesOfTonsLikeVariables();
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
      },
    },
    location_address: {
      handler: function (val) {
        if (val && val.lat && val.long) {
          this.mapCordinates = [val.long, val.lat];
          this.reloadMap();
        }
      },
    },
  },
};
</script>

<style scoped>
.red {
  color: red;
}
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 27px !important;
}
.text-content {
  font-size: 18px !important;
}
.text-title {
  font-size: 24px !important;
}
</style>
