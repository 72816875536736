<template>
  <v-dialog
    v-model="modifyDots"
    max-width="800"
    @click:outside="closeArticleDotModel()"
    @keydown.esc="closeArticleDotModel()"
  >
    <v-card :loading="cardLoading">
      <v-card-title>
        <span class="text-h5">{{ article.description }}</span>
      </v-card-title>
      <v-card-text>
        <span v-if="iserror" class="text-error">{{
          $t("stocks.dot_greater_then_stock_error")
        }}</span>
        <v-data-table
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :items="dot_items"
          :items-per-page="itemsPerPage"
          :options.sync="options"
          :sort-by="sortBy"
          dense
          :loading="isLoading"
          :loading-text="this.$t('loading')"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          class="elevation-0"
          item-key="property"
          height="30vh"
          style="width: 100%"
        >
          <template v-slot:top>
            <v-toolbar flat class="pt-4">
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    class="mr-3 pb-5 to-upper"
                    :label="$t('receptions.modify_article.dot')"
                    v-bind:clearable="true"
                    clear-icon="mdi-close"
                    tabindex="100"
                    v-model="property"
                    v-on:keydown.enter.prevent="addDotToDataTable()"
                    v-on:keydown="handleInput($event, 'int', 4)"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    class="mr-3 to-upper"
                    :label="$t('receptions.modify_article.quantity')"
                    v-bind:clearable="true"
                    tabindex="101"
                    clear-icon="mdi-close"
                    v-model="counter"
                    v-on:keydown.enter.prevent="addDotToDataTable()"
                    v-on:keydown="handleInput($event, 'int', 6)"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    @input="checkError"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-btn
                    :disabled="
                      !(!$v.property.minLength && !$v.counter.minValue)
                    "
                    color="primary"
                    @click="addDotToDataTable()"
                  >
                    {{ $t("receptions.modify_article.add") }}
                    <v-icon dark right> mdi-plus-thick </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.counter="{ item }">
            <td>
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                style="width: 40px"
                class="mr-3 pb-4 to-upper"
                v-model="item.counter"
                v-on:keydown="handleInput($event, 'int', 6)"
                hide-details="auto"
                :outlined="is_boxes"
                :dense="is_boxes"
                @input="checkError"
              ></v-text-field>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <td align="right">
              <v-icon color="red" right @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey-lighten-2" @click="closeArticleDotModel()">
          {{ $t("receptions.modify_article.cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn
          :disabled="iserror"
          color="green"
          :dark="!iserror"
          @click="submitArticleDotModel()"
        >
          {{ $t("receptions.modify_article.save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";

export default {
  name: "StockArticleDot",
  mixins: [validationMixin],
  validations: {
    counter: {
      minValue(value) {
        value = parseInt(value);
        if (value >= 0) {
          return false;
        }
        return true;
      },
    },
    property: {
      minLength(value) {
        if ((value ?? "").length >= 4) {
          return false;
        }
        return true;
      },
    },
  },
  props: {
    article: [Object],
    passed_dot_items: [Array],
    total_stocks: [Number],
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      modifyDots: true,
      isLoading: false,
      counter: null,
      property: null,
      dot_items: this.passed_dot_items ?? [],
      autocompleteValueLocal: "off",
      cardLoading: false,
      iserror: false,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.dot_items = this.passed_dot_items ?? [];
    this.checkError();
  },
  methods: {
    checkError() {
      if (this.dot_items.length > 0) {
        let totalCounter = 0;
        this.dot_items.forEach((element) => {
          totalCounter = parseInt(totalCounter) + parseInt(element.counter);
        });
        totalCounter =
          parseInt(totalCounter) + parseInt(this.counter ? this.counter : 0);
        if (parseInt(this.total_stocks) < parseInt(totalCounter)) {
          this.iserror = true;
        } else {
          this.iserror = false;
        }
      }
    },
    closeArticleDotModel() {
      this.modifyDots = false;
    },
    submitArticleDotModel() {
      let data = {};
      let items = Object.assign({}, this.dot_items);
      data.dot_items = items;
      data.property_class_id = "dot_property";
      this.cardLoading = true;
      axios
        .put(API_BASE_URL + "/stock_property/" + this.article.id, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then((response) => {
          this.dot_items = response.data.data;
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {
          this.modifyDots = false;
          this.cardLoading = false;
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    addDotToDataTable() {
      if (!this.$v.property.minLength && !this.$v.counter.minValue) {
        let found = false;
        this.dot_items.forEach((item) => {
          if (parseInt(item.property) == parseInt(this.property)) {
            item.counter = parseInt(item.counter) + parseInt(this.counter);
            found = true;
          }
        });

        if (!found) {
          this.dot_items.push({
            id: this.dot_items.length + 1,
            property: this.property,
            counter: this.counter,
          });
        }
        this.property = null;
        this.counter = null;
      }
    },
    deleteItem(id) {
      this.dot_items.splice(id - 1, 1);
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("receptions.modify_article.dot"),
          align: "start",
          sortable: false,
          value: "property",
          width: "10",
        },
        {
          text: this.$t("receptions.modify_article.quantity"),
          align: "start",
          sortable: false,
          value: "counter",
          width: "530",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "actions",
          width: "50",
        },
      ];
    },
  },
  watch: {
    modifyDots() {
      this.$emit("change-modify-article-dialog", true);
    },
    dot_items() {
      this.checkError();
    },
  },
};
</script>
<style scoped>
.text-error {
  color: red;
}
</style>
