<template>
  <v-dialog
    persistent
    v-model="internalShowDialog"
    @click:outside="cancelDialog('cancel')"
    @keydown.esc="cancelDialog('cancel')"
    max-width="900"
  >
    <v-card>
      <v-card-title class="headline">
        {{
          type == "client"
            ? defaultClient && typeof defaultClient == "object"
              ? $t("vehicles.clients_model.edit_owner")
              : $t("vehicles.clients_model.add_owner")
            : defaultClient && typeof defaultClient == "object"
            ? $t("vehicles.clients_model.edit_driver")
            : $t("vehicles.clients_model.add_driver")
        }}
      </v-card-title>
      <v-card-text>
        <v-form class="main_form" id="client-form">
          <v-container fluid>
            <h2 v-if="type == 'driver'">{{ $t("clients.drivers_company") }}</h2>
            <!-- Client FamilyClient/Type/Name -->
            <v-row>
              <!-- Family Client -->
              <v-col cols="12" :sm="isClientCompanySelected ? '2' : '2'">
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.familyClient"
                  :items="familyClients"
                  item-value="id"
                  item-text="name"
                  menu-props="auto"
                  :label="$t('vehicles.clients_model.family_client')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  :error-messages="
                    $store.state.validation && !$v.form.familyClient.required
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.family_client_required'
                          ),
                        ]
                      : []
                  "
                ></v-select>
              </v-col>
              <!-- Entity Type -->
              <v-col cols="12" sm="2">
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.entityType"
                  :items="entityTypeSelectItems"
                  item-text="text"
                  class="to-upper"
                  item-value="id"
                  return-object
                  :label="$t('vehicles.clients_model.entity_type')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
              <!-- Company/Private Name -->
              <v-col cols="12" :sm="isClientCompanySelected ? '4' : '4'">
                <v-text-field
                  v-if="false"
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.name"
                  name="name"
                  class="to-upper"
                  ref="client_name"
                  v-on:keydown="handleInput($event, 'everything', 50)"
                  :label="
                    type == 'client'
                      ? $t('vehicles.clients_model.client_name')
                      : $t('vehicles.clients_model.driver_name')
                  "
                  :error-messages="companyNameErrorMessages"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
                <v-combobox
                  :error-messages="companyNameErrorMessages"
                  class="to-upper"
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.name"
                  :items="company_client_names"
                  item-value="id"
                  item-text="name"
                  :label="
                    isClientCompanySelected
                      ? $t('billings.comp_name')
                      : $t('billings.family_name')
                  "
                  required
                  append-icon="mdi-close"
                  @click:append="removeClientCompany()"
                  v-on:keydown="handleInput($event, 'everything', 50)"
                  :search-input.sync="search_client"
                  no-filter
                  v-on:keydown.enter.prevent=""
                  return-object
                  :outlined="is_boxes"
                  :dense="is_boxes"
                >
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item-content>
                      <p class="client_name">
                        {{ item.name }} {{ item.first_name }}
                      </p>
                      <p
                        class="client_detail"
                        v-if="item.representative_company_id"
                      >
                        Company
                      </p>
                      <p class="client_detail" v-else>Private</p>
                      <p class="client_detail">{{ item.email }}</p>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-col>
              <!-- First Name -->
              <v-col
                cols="12"
                v-if="!isClientCompanySelected"
                :sm="isClientCompanySelected ? '8' : '4'"
              >
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.first_name"
                  class="to-upper"
                  ref="client_name"
                  v-on:keydown="handleInput($event, 'justalphanumeric', 50)"
                  :label="
                    type == 'client'
                      ? $t('vehicles.clients_model.client_first_name')
                      : $t('vehicles.clients_model.driver_first_name')
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" v-if="isClientCompanySelected">
                <v-select
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.denomination"
                  :items="denominations"
                  item-value="id"
                  class="to-upper"
                  item-text="text"
                  :label="$t('vehicles.clients_model.denomination')"
                  :error-messages="
                    $store.state.validation && !$v.form.denomination.required
                      ? [
                          this.$t(
                            'billings.text_messages.denomination_required'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" v-if="isClientCompanySelected">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.tva"
                  @change="
                    resetVariables();
                    verifyTVA(form.tva);
                  "
                  ref="driver_tva"
                  class="to-upper"
                  required
                  v-on:keydown="handleInput($event, 'justalphanumeric', 45)"
                  :label="$t('vehicles.clients_model.tva')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  :error-messages="
                    $store.state.validation && !$v.form.tva.required
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.tva_required'
                          ),
                        ]
                      : []
                  "
                >
                  <template v-slot:prepend-inner>
                    <template v-if="isVerifiedTVALoading">
                      <v-progress-circular
                        indeterminate
                        size="20"
                        width="2"
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      <v-icon :color="isVerifiedTVA == false ? 'red' : 'green'">
                        {{
                          isVerifiedTVA
                            ? "mdi-checkbox-marked-circle"
                            : isVerifiedTVA == false
                            ? "mdi-close-circle"
                            : ""
                        }}
                      </v-icon>
                    </template>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <!-- Client/Driver Email/Phone/GSM/Language -->
            <v-row class="mt-n6">
              <v-col cols="12" sm="3">
                <v-text-field
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.email"
                  class="to-upper"
                  v-on:keydown="handleInput($event, 'email', 60)"
                  :label="$t('vehicles.clients_model.email')"
                  :error-messages="
                    $store.state.validation && !$v.form.email.email
                      ? [
                          this.$t(
                            'vehicles.clients_model.text_messages.invalid_email'
                          ),
                        ]
                      : []
                  "
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <!-- <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.phone"
                                class="to-upper"
                                :label="$t('vehicles.clients_model.phone')"
                                v-on:keydown="handleInput($event,'phonenumber',45);"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field> -->
                <vue-tel-input-vuetify
                  :disabledFetchingCountry="true"
                  :preferredCountries="preferredCountry"
                  :filterCountries="onlyCountries"
                  validCharactersOnly
                  :label="$t('vehicles.clients_model.phone')"
                  :inputOptions="{ showDialCode: true, tabindex: 0 }"
                  :mode="'international'"
                  v-model="form.phone"
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="12" sm="3">
                <!-- <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="form.gsm"
                                class="to-upper"
                                v-on:keydown="handleInput($event,'phonenumber',45);" 
                                :label="$t('vehicles.clients_model.gsm')"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field> -->
                <vue-tel-input-vuetify
                  :disabledFetchingCountry="true"
                  :preferredCountries="preferredCountry"
                  :filterCountries="onlyCountries"
                  validCharactersOnly
                  :label="$t('vehicles.clients_model.gsm')"
                  :inputOptions="{ showDialCode: true, tabindex: 0 }"
                  :mode="'international'"
                  v-model="form.gsm"
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="12" :sm="isClientCompanySelected ? '3' : '3'">
                <v-autocomplete
                  class="to-upper"
                  :autocomplete="autocompleteValueLocal"
                  v-model="form.language"
                  :items="languages"
                  item-value="id"
                  item-text="text"
                  :label="$t('vehicles.clients_model.languages')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Member -->
            <div v-if="showMembers && membersEnabled">
              <h2>{{ $t("clients.company_member") }}</h2>
              <v-row class="mb-0 pb-0">
                <!-- Member Types -->
                <v-col cols="12" sm="3">
                  <v-select
                    :autocomplete="autocompleteValueLocal"
                    v-model="memberType"
                    :items="memberTypes"
                    item-value="id"
                    item-text="text"
                    menu-props="auto"
                    :label="$t('vehicles.clients_model.member_type')"
                    :outlined="is_boxes"
                    :error-messages="
                      $store.state.validation && !$v.memberType.required
                        ? [
                            this.$t(
                              'vehicles.clients_model.text_messages.member_type_required'
                            ),
                          ]
                        : []
                    "
                    :dense="is_boxes"
                    hide-details="true"
                  ></v-select>
                </v-col>
                <v-col cols="12" :sm="'2'">
                  <!-- <v-text-field
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="member_department"
                                    name="member_department"
                                    class="to-upper"
                                    ref="member_department"
                                    v-on:keydown="handleInput($event,'justalphanumeric',50);"
                                    :label="$t('vehicles.clients_model.department')"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                ></v-text-field> -->
                  <v-combobox
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    v-model="member_department"
                    :items="member_departments"
                    item-value="id"
                    item-text="name"
                    :label="$t('vehicles.clients_model.department')"
                    required
                    v-on:keydown="handleInput($event, 'alphanumeric', 50)"
                    :search-input.sync="search_department"
                    no-filter
                    v-on:keydown.enter.prevent=""
                    return-object
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  >
                    <template slot="item" slot-scope="{ item }">
                      <v-list-item-content>
                        <p class="client_name">{{ item }}</p>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </v-col>
                <!-- Member Name -->
                <v-col cols="12" sm="3">
                  <v-combobox
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    v-model="member_name"
                    :items="member_names"
                    item-value="id"
                    item-text="name"
                    :label="$t('clients.member_name')"
                    required
                    append-icon="mdi-close"
                    @click:append="removeMember"
                    v-on:keydown="handleInput($event, 'alphanumeric', 50)"
                    :search-input.sync="search_member"
                    v-on:keydown.enter.prevent=""
                    return-object
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  >
                    <template slot="item" slot-scope="{ item }">
                      <v-list-item-content>
                        <p class="client_name">
                          {{ item.name }} {{ item.first_name }}
                        </p>
                        <!-- <p class="client_detail" v-if="item.representative_company_id">
                                            {{ $t('company') }}
                                        </p>
                                        <p class="client_detail" v-else>
                                            {{ $t('private') }}
                                        </p> -->
                        <p class="client_detail">{{ item.email }}</p>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" :sm="2">
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="member_first_name"
                    name="name"
                    class="to-upper"
                    ref="client_name"
                    v-on:keydown="handleInput($event, 'justalphanumeric', 50)"
                    :label="$t('vehicles.clients_model.client_first_name')"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </v-col>
                <!-- Language -->
                <v-col cols="12" sm="2">
                  <v-autocomplete
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    role="presentation"
                    flat
                    :items="languages"
                    v-model="memberLanguage"
                    :label="$t('billings.languages')"
                    @change="
                      $emit('update:comp_company_client_language', language)
                    "
                    :error-messages="
                      $store.state.validation && !$v.memberLanguage.required
                        ? [
                            this.$t(
                              'vehicles.clients_model.text_messages.language_required'
                            ),
                          ]
                        : []
                    "
                    item-value="id"
                    item-text="text"
                    v-on:keydown.enter.prevent=""
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-n10">
                <v-col cols="12" sm="4">
                  <vue-tel-input-vuetify
                    :disabledFetchingCountry="true"
                    :preferredCountries="preferredCountry"
                    :filterCountries="onlyCountries"
                    validCharactersOnly
                    :label="$t('billings.phone')"
                    :inputOptions="{ showDialCode: true, tabindex: 0 }"
                    :mode="'international'"
                    v-model="memberPhone"
                    @onInput="
                      $emit('update:comp_company_client_phone', memberPhone)
                    "
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col cols="12" sm="4">
                  <vue-tel-input-vuetify
                    :disabledFetchingCountry="true"
                    :preferredCountries="preferredCountry"
                    :filterCountries="onlyCountries"
                    validCharactersOnly
                    :label="$t('billings.gsm')"
                    :inputOptions="{ showDialCode: true, tabindex: 0 }"
                    :mode="'international'"
                    v-model="memberGsm"
                    @onInput="
                      $emit('update:comp_company_client_phone', memberPhone)
                    "
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="memberEmail"
                    :error-messages="
                      $store.state.validation && !$v.memberEmail.email
                        ? [
                            this.$t(
                              'vehicles.clients_model.text_messages.invalid_email'
                            ),
                          ]
                        : []
                    "
                    class="to-upper"
                    v-on:keydown="handleInput($event, 'email', 60)"
                    :label="$t('vehicles.clients_model.email')"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn color="grey lighten-2" @click="cancelDialog('cancel')">
          {{ $t("vehicles.Cancel") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn color="success" @click="submit()">
          {{ $t("vehicles.Save") }}
          <v-icon dark right> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { validationMixin } from "vuelidate";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import VueTelInputVuetify from "@/components/PhoneNumberInput";
export default {
  mixins: [validationMixin],
  name: "AddEditClientDriverModel",
  components: {
    "vue-tel-input-vuetify": VueTelInputVuetify,
  },
  props: {
    showDialog: [Number, Boolean],
    openedForOperation: [String],
    type: { type: String, default: "client" },
    defaultClient: [String, Object],
    membersEnabled: { type: Boolean, default: false },
    defaultMember: [String, Object],
  },
  validations: {
    form: {
      name: {
        required: requiredIf(function () {
          return !this.form.name || !this.form.name.length;
        }),
      },
      email: {
        email: (val) => email((val ?? "").toLowerCase()),
      },
      tva: {
        required: requiredIf(function () {
          return this.isClientCompanySelected;
        }),
      },
      denomination: {
        required,
      },
      language: {
        required,
      },
      familyClient: {
        required,
      },
    },
    memberEmail: {
      email: (val) => email((val ?? "").toLowerCase()),
    },
    memberLanguage: {
      required: requiredIf(function () {
        return this.member_name;
      }),
    },
    memberType: {
      required: requiredIf(function () {
        return this.member_name;
      }),
    },
  },
  data() {
    const defaultForm = Object.freeze({
      entityType:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.representative_company_id
            ? { id: 1 }
            : { id: 0 }
          : { id: 1 },
      id:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.id
          : null,
      company: {},
      language: this.defaultClient
        ? this.defaultClient.language_id
          ? typeof this.defaultClient.language_id == "object"
            ? this.defaultClient.language_id.id
            : this.defaultClient.language_id
          : localStorage.getItem("languageId") || "en"
        : localStorage.getItem("languageId") || "en",
      name:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.name
          : null,
      first_name:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.first_name
          : null,
      contact_name_2: null,
      contact_name_3: null,
      phone: null,
      phone_2: null,
      phone_3: null,
      gsm: null,
      gsm_2: null,
      gsm_3: null,
      fax: null,
      email:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.email
          : null,
      email_2:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.email_2
          : null,
      email_3:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.email_3
          : null,
      web:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.web
          : null,
      currency: this.defaultClient
        ? this.defaultClient.currency_id
          ? typeof this.defaultClient.currency_id == "object"
            ? this.defaultClient.currency_id.id
            : this.defaultClient.currency_id
          : this.defaultCurrencyId
        : this.defaultCurrencyId,
      representative_company_id:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.representative_company_id
          : null,
      user: null,
      note:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.note
          : null,
      status:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.status_id
          : 10041001,
      familyClient:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.family_client_id
          : null,
      created_at: null,
      updated_at: null,
      denomination: 10061001,
      tva:
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.company
            ? this.defaultClient.company.tva
            : null
          : null,
    });
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      entityTypes: [
        { id: 0, value: "Private" },
        { id: 1, value: "Company" },
      ],
      internalShowDialog: this.showDialog,
      autocompleteValueLocal: "off",
      client: {},
      DeleteConfirm: false,
      form: Object.assign({}, defaultForm),
      denominations: [],
      clientStatuses: [],
      currencies: [],
      languages: [],
      operation: "edit",
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },

      search_member: null,
      memberTypes: [],
      memberType: null,
      memberLanguage: localStorage.getItem("languageId") || "en",
      member_names: [],
      member_name: null,
      member_first_name: null,
      member_department: null,
      member_departments: [],
      memberPhone: null,
      memberGsm: null,
      memberEmail: null,

      company_client_names: [],
      search_client: null,
      search_department: null,
      companyNameServerErrors: [],
    };
  },
  activated() {
    this.setDefaultFamilyClient();
  },
  mounted() {
    this.$store.commit("setValidation", false); // enable validation
    this.autocompleteValueLocal = this.autocompleteValue();
    this.getCompanyDenominations();
    this.getStatuses();
    this.getMemberTypes().then(() => {
      if (this.defaultMember && typeof this.defaultMember == "object") {
        this.member_name = this.defaultMember;
      }
    });
    this.getCurrencies().then(() => {
      if (this.defaultClient) {
        this.form.currency = this.defaultClient
          ? this.defaultClient.currency_id
            ? typeof this.defaultClient.currency_id == "object"
              ? this.defaultClient.currency_id.id
              : this.defaultClient.currency_id
            : this.defaultClient.currency &&
              typeof this.defaultClient.currency == "object"
            ? this.defaultClient.currency.id
            : this.defaultCurrencyId
          : null;
      } else {
        this.form.currency = this.defaultCurrencyId;
      }
    });
    this.getLanguages().then(() => {
      if (this.defaultClient) {
        this.form.language = this.defaultClient
          ? this.defaultClient.language_id
            ? typeof this.defaultClient.language_id == "object"
              ? this.defaultClient.language_id.id
              : this.defaultClient.language_id // If language -> language_id
            : this.defaultClient.language &&
              typeof this.defaultClient.language == "object"
            ? this.defaultClient.language.id
            : localStorage.getItem("languageId") || "en"
          : null;
      } else {
        this.form.language = localStorage.getItem("languageId") || "en";
      }
    });
    if (!this.defaultClient) {
      this.setDefaultFamilyClient();
    } else if (this.defaultClient && typeof this.defaultClient != "object") {
      this.setDefaultFamilyClient();
    } else if (this.defaultClient && typeof this.defaultClient == "object") {
      // Update phone and fax and gsm on mount
      this.form.contact_name_2 =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.contact_name_2
          : null;
      this.form.contact_name_3 =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.contact_name_3
          : null;

      this.form.phone =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.phone
          : null;
      this.form.phone_2 =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.phone_2
          : null;
      this.form.phone_3 =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.phone_3
          : null;
      this.form.fax =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.fax
          : null;
      this.form.gsm =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.gsm
          : null;
      this.form.gsm_2 =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.gsm_2
          : null;
      this.form.gsm_3 =
        this.defaultClient && typeof this.defaultClient == "object"
          ? this.defaultClient.gsm_3
          : null;
      if (this.defaultClient.representative_company_id) {
        this.getMembers(this.defaultClient.representative_company_id);
      }
    }
  },
  methods: {
    fetchClientsListDebounced() {
      if (!this.search_client || typeof this.search_client == "object") {
        return;
      }
      // This means it is in edit mode
      axios
        .get(
          API_BASE_URL +
            "/clients/search?companiesOnly=true&searchRegNumber=true" +
            (this.search_client
              ? "&search=" + encodeURIComponent(this.search_client)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          data.data.forEach((element) => {
            element.clientname =
              element.name + " " + (element.first_name ?? "");
          });
          this.company_client_names = data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //
        });
    },

    fetchDepartmentsListDebounced() {
      if (
        !this.search_department ||
        typeof this.search_department == "object"
      ) {
        return;
      }
      // This means it is in edit mode
      axios
        .get(
          API_BASE_URL +
            "/clients/departments?search=" +
            this.search_department,
          { headers: this.header }
        )
        .then(({ data }) => {
          this.member_departments = data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //
        });
    },
    makeClientObject(data) {
      return {
        id: data.id,
        company: data.company_id,
        language: data.language ? data.language : data.language_id,
        language_id: data.language ? data.language : data.language_id,
        department: data.department,
        name: data.name,
        first_name: data.first_name,
        full_name: data.full_name,
        contact_name_2: data.contact_name_2,
        contact_name_3: data.contact_name_3,
        phone: data.phone,
        phone_2: data.phone_2,
        phone_3: data.phone_3,
        gsm: data.gsm,
        gsm_2: data.gsm_2,
        gsm_3: data.gsm_3,
        fax: data.fax,
        email: data.email,
        email_2: data.email_2,
        email_3: data.email_3,
        web: data.web,
        tva:
          data.company && typeof data.company == "object"
            ? data.company.tva
            : null,
        denomination_id:
          data.company && typeof data.company == "object"
            ? data.company.denomination_id
            : null,
        type_id: data.type_id
          ? typeof data.type_id == "object"
            ? data.type_id.id
            : data.type_id
          : data.type && typeof data.type == "object"
          ? data.type.id
          : data.type,
        currency: data.currency
          ? typeof data.currency == "object"
            ? data.currency.key
            : data.currency
          : null,
        representative_company_id: data.representative_company_id,
        family_client_id: data.family_client_id,
      };
    },
    removeClientCompany() {
      (this.form.entityType = { id: 1 }),
        (this.form.id = null),
        (this.form.company = {}),
        (this.form.language = localStorage.getItem("languageId") || "en"),
        (this.form.name = null),
        (this.form.first_name = null),
        (this.form.contact_name_2 = null),
        (this.form.contact_name_3 = null),
        (this.form.phone = null),
        (this.form.phone_2 = null),
        (this.form.phone_3 = null),
        (this.form.gsm = null),
        (this.form.gsm_2 = null),
        (this.form.gsm_3 = null),
        (this.form.fax = null),
        (this.form.email = null),
        (this.form.email_2 = null),
        (this.form.email_3 = null),
        (this.form.web = null),
        (this.form.currency = this.defaultCurrencyId),
        (this.form.representative_company_id = null),
        (this.form.user = null),
        (this.form.note = null),
        (this.form.status = 10041001),
        (this.form.familyClient = null),
        (this.form.denomination = 10061001),
        (this.form.tva = null);
      this.form.company_client_names = [];
      this.form.company_client_tva = null;
      this.form.company_client_address = null;
      this.member_names = [];
      this.member_first_name = null;
      this.department = null;
      this.removeMember();
    },
    getMembers(representative_company_id) {
      axios
        .get(
          API_BASE_URL +
            "/clients?searchRegNumber=true&membersOnly=true" +
            (this.defaultClient
              ? "&representative_company_id=" + representative_company_id
              : ""),
          {
            headers: this.header,
          }
        )
        .then(({ data }) => {
          this.member_names = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeMember() {
      this.member_name = null;
      this.member_first_name = null;
      this.member_department = null;
      this.memberType = null;
      this.memberEmail = null;
      this.memberPhone = null;
      this.memberGsm = null;
    },
    getMemberTypes() {
      return new Promise((resolve) => {
        this.getCodes("member_types", "member_types").then((data) => {
          this.memberTypes = data.filter((element) => {
            return element.key != "not_specified";
          });
          this.memberType = this.memberTypes[0];
          resolve();
        });
      });
    },
    setDefaultFamilyClient() {
      this.familyClients.forEach((element) => {
        if (element.is_default == 1) {
          this.form.familyClient = element;
        }
      });
    },
    async getCompanyDenominations() {
      await this.getCodes("company_denomination", "company_denomination").then(
        (data) => {
          this.denominations = data;
        }
      );
    },
    getCurrencies() {
      return new Promise((resolve) => {
        this.getDataWithCache(
          "/currencies",
          "currencies",
          "currencies",
          true
        ).then(() => {
          resolve();
        });
      });
    },
    async getStatuses() {
      await this.getCodes("client_statuses", false).then((data) => {
        this.clientStatuses = data.map((item) => {
          item.key = /^[A-Z]/.test(item.key)
            ? item.key
            : this.$t("clients." + item.key);
          return item;
        });
      });
    },
    getLanguages() {
      return new Promise((resolve) => {
        this.getDataWithCache(
          "/languages",
          "languages",
          "languages",
          true
        ).then((data) => {
          resolve(data);
        });
      });
    },
    editClient(clientData) {
      axios
        .put(API_BASE_URL + "/clients/" + clientData.id, clientData, {
          headers: this.header,
        })
        .then((data) => {
          if (this.membersEnabled && this.member_name) {
            let memberData = this.makeMemberSubmitData(data.data.data);
            if (memberData.id) {
              this.editMember(data.data.data, memberData);
            } else {
              this.addMember(data.data.data, memberData);
            }
          } else {
            this.$emit("savedClient", this.makeClientObject(data.data.data));
            this.$emit("savedMember", null);

            this.cancelDialog("added", this.makeClientObject(data.data.data));
          }
          this.$toast.success(
            this.$t(
              "vehicles.clients_model.text_messages.client_added_successfully"
            )
          );
        })
        .catch(() => {})
        .finally(() => {});
    },
    addClient(clientData) {
      this.companyNameServerErrors = [];
      axios
        .post(API_BASE_URL + "/clients", clientData, { headers: this.header })
        .then((data) => {
          if (this.membersEnabled && this.member_name) {
            let memberData = this.makeMemberSubmitData(data.data.data);
            if (memberData.id) {
              this.editMember(data.data.data, memberData);
            } else {
              this.addMember(data.data.data, memberData);
            }
          } else {
            this.$emit("savedClient", this.makeClientObject(data.data.data));
            this.$emit("savedMember", null);
            this.cancelDialog("added", this.makeClientObject(data.data.data));
          }
          this.$toast.success(
            this.$t(
              "vehicles.clients_model.text_messages.client_added_successfully"
            )
          );
        })
        .catch((error) => {
          let errors = error?.response?.data?.errors ?? null;
          if (
            errors &&
            Array.isArray(errors) &&
            errors[0] === "company_name_already_exists"
          ) {
            return this.companyNameServerErrors.push(
              this.$t("clients.text_messages.company_name_already_exists")
            );
          }

          this.$toast.error(this.$t("unknown_error"));
        })
        .finally(() => {});
    },
    makeMemberSubmitData(client) {
      let data = {
        name:
          typeof this.member_name == "object"
            ? this.member_name.name
            : this.member_name,
        first_name:
          typeof this.member_first_name == "object"
            ? this.member_first_name.first_name
            : this.member_first_name,
        department: this.member_department ?? null,
        family_client_id: client.family_client_id,
        denomination_id: client.denomination_id,
        representative_company_id: client.representative_company_id,
        type_id: this.memberType
          ? typeof this.memberType == "object"
            ? this.memberType.id
            : this.memberType ?? null
          : null,
        status_id: client.status.id,
        currency_id: client.currency.id,
        email: this.memberEmail,
        phone: this.memberPhone ?? null,
        gsm: this.memberGsm ?? null,
        tva: null,
        language_id:
          typeof this.memberLanguage == "object"
            ? this.memberLanguage.id
            : this.memberLanguage,
      };

      if (typeof this.member_name == "object") {
        data["id"] = this.member_name.id;
      }
      return data;
    },
    addMember(clientData, memberData) {
      this.$emit("savedClient", this.makeClientObject(clientData));
      axios
        .post(API_BASE_URL + "/clients", memberData, { headers: this.header })
        .then((data) => {
          this.$emit("savedMember", this.makeClientObject(data.data.data));
          this.$toast.success(
            this.$t(
              "vehicles.clients_model.text_messages.client_added_successfully"
            )
          );
          this.cancelDialog("added", this.makeClientObject(clientData));
        })
        .catch(() => {})
        .finally(() => {});
    },
    editMember(clientData, memberData) {
      this.$emit("savedClient", this.makeClientObject(clientData));
      axios
        .put(API_BASE_URL + "/clients/" + memberData.id, memberData, {
          headers: this.header,
        })
        .then((data) => {
          this.$emit("savedMember", this.makeClientObject(data.data.data));
          this.$toast.success(
            this.$t(
              "vehicles.clients_model.text_messages.client_added_successfully"
            )
          );
          this.cancelDialog("added", this.makeClientObject(clientData));
        })
        .catch(() => {})
        .finally(() => {});
    },
    cancelDialog(operationPerformed, data) {
      this.$emit("update:showDialog", false);
      this.$emit("buttonClicked", operationPerformed == "added" ? data : false);
      this.internalShowDialog = false;
    },
    submit() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.$v.$invalid) {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
        return false;
      } else {
        if (this.form.id) {
          this.editClient(this.makeSubmitData(this.form.id));
        } else {
          this.addClient(this.makeSubmitData());
        }
      }
    },
    makeSubmitData(id) {
      let data = {
        name: this.form.name
          ? typeof this.form.name == "object"
            ? this.form.name.name.toUpperCase()
            : this.form.name
          : null,
        first_name: this.form.first_name
          ? typeof this.form.first_name == "object"
            ? this.form.first_name.first_name.toUpperCase()
            : this.form.first_name
          : null,
        family_client_id:
          typeof this.form.familyClient == "object"
            ? this.form.familyClient.id
            : this.form.familyClient,
        denomination_id:
          this.form.entityType.id > 0 ? this.form.denomination : 0,
        representative_company_id: null,
        status_id: this.form.status,
        currency_id: this.form.currency,
        contact_name_2: this.form.contact_name_2,
        contact_name_3: this.form.contact_name_3,
        email: this.form.email,
        email_2: this.form.email_2,
        email_3: this.form.email_3,
        phone: this.form.phone,
        phone_2: this.form.phone_2,
        phone_3: this.form.phone_3,
        gsm: this.form.gsm,
        gsm_2: this.form.gsm_2,
        gsm_3: this.form.gsm_3,
        tva: this.form.tva,
        language_id: this.form.language
          ? typeof this.form.language == "object"
            ? this.form.language.id
            : this.form.language
          : null,
      };
      if (id) {
        data["id"] = id;
      }
      return data;
    },
  },
  computed: {
    isClientCompanySelected() {
      return this.form.entityType.id === 1;
    },
    showMembers() {
      return this.form.entityType.id === 1;
    },
    companyNameErrorMessages() {
      if (this.companyNameServerErrors.length > 0) {
        return this.companyNameServerErrors;
      }
      return this.$store.state.validation && !this.$v.form.name.required
        ? [this.$t("vehicles.clients_model.text_messages.name_required")]
        : [];
    },
  },
  beforeDestroy() {
    this.$store.commit("setValidation", false);
  },
  watch: {
    "form.name": {
      handler: function (val) {
        if (val && typeof val == "object") {
          this.member_names = [];
          this.form.tva = val?.company?.tva ?? null;
          if (this.form.tva) {
            this.verifyTVA(this.form.tva);
          }
          if (val.representative_company_id) {
            this.getMembers(val.representative_company_id);
            this.form.entityType = { id: 1 };
            this.form.representative_company_id = val.representative_company_id;
          } else {
            this.form.entityType = { id: 0 };
          }
          if (val.company && val.company.denomination_id) {
            this.form.denomination = val.company.denomination_id;
            this.form.tva = val.company.tva;
          }
          this.form.id = val.id;
          this.form.language = val.language
            ? typeof val.language == "object"
              ? val.language.id
              : null
            : null;
          this.form.name = val.name;
          this.form.first_name = val.first_name;
          this.form.contact_name_2 = val.contact_name_2;
          this.form.contact_name_3 = val.contact_name_3;
          this.form.phone = val.phone;
          this.form.phone_2 = val.phone_2;
          this.form.phone_3 = val.phone_3;
          this.form.gsm = val.gsm;
          this.form.gsm_2 = val.gsm_2;
          this.form.gsm_3 = val.gsm_3;
          this.form.fax = val.fax;
          this.form.email = val.email;
          this.form.email_2 = val.email_2;
          this.form.email_3 = val.email_3;
          this.form.web = val.web;
          this.form.currency = val.currency
            ? typeof val.currency == "object"
              ? val.currency.id
              : null
            : null;
          this.form.user = null;
          this.form.note = val.note;
          this.form.status = val.status_id;
          this.form.familyClient = val.family_client_id;

          // Make member Language same as client
          this.memberLanguage = val.language
            ? typeof val.language == "object"
              ? val.language.id
              : null
            : null;
        }
      },
    },
    defaultMember(val) {
      // this.member_name = val
      if (val && typeof val == "object") {
        this.member_name = val;
      }
    },
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
      },
    },
    member_name(val) {
      if (val && typeof val == "object") {
        this.member_first_name = val.first_name;
        this.member_department = val.department;
        this.memberEmail = val.email;
        this.memberPhone = val.phone;
        this.memberGsm = val.gsm;
        this.memberLanguage = val.language;
        if (val.language) {
          this.memberLanguage = val.language;
        } else {
          this.memberLanguage = val.language_id;
        }
        if (val.type) {
          this.memberType = val.type;
        } else {
          this.memberTypes.forEach((element) => {
            if (element.id == this.defaultMember.type_id) {
              this.memberType = element;
            }
          });
        }
      } else if (val && typeof val != "object") {
        this.member_first_name = val.first_name;
        // this.member_department = null;
        this.memberEmail = null;
        this.memberPhone = null;
        this.memberGsm = null;
        this.memberLanguage = localStorage.getItem("languageId") || "en";
        // Reset Member Type to default
        if (!this.memberType) {
          this.memberType = this.memberTypes[0];
        }
        // if(val.type){
        //     this.memberType = val.type;
        // }
        // else{
        //     this.memberTypes.forEach((element) => {
        //         if(element.id == this.defaultMember.type_id){
        //             this.memberType = element;
        //         }
        //     })
        // }
      }
    },
    search_client(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchClientsListDebounced();
      }, 500);
    },
    search_department(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchDepartmentsListDebounced();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
