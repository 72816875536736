var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"65%"},on:{"click:outside":function($event){return _vm.closePopUpModel()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closePopUpModel()}},model:{value:(_vm.popUpModelVisible),callback:function ($$v) {_vm.popUpModelVisible=$$v},expression:"popUpModelVisible"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{staticClass:"mt-1 mb-1",attrs:{"justify":"space-between"}},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("cart.heading")))])])],1),_c('v-card-text',[_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
            'items-per-page-options': _vm.dataTableOptions,
          },"hide-default-footer":true,"headers":_vm.headers,"items":_vm.articles,"options":_vm.options,"server-items-length":_vm.totalItems,"items-per-page":2000,"item-key":"identifier","height":"700px"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getSlotChosen(item)))])]),_c('td',[_vm._v(" "+_vm._s(item.article.description)),_c('br'),_c('span',{staticClass:"text-muted"},[_c('TextComponentWithExtraIcons',{attrs:{"value":item.article.identifier}})],1)]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.fixDecimal(_vm.getClientPriceForOneWithoutWorkIfAny(item)))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.fixDecimal(_vm.fixDecimal(_vm.getWorkPriceForOne(item))))+" ")]),_c('td',{attrs:{"align":"right"}},[_c('v-text-field',{staticClass:"right-aligned-input",staticStyle:{"max-width":"50px","min-width":"50px"},on:{"keydown":function($event){return _vm.handleInput($event, 'int', 6)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.fixDecimal(_vm.getClientPriceTotalAfterAllCalculation(item)))+" ")]),_c('td',{attrs:{"align":"right"}},[(item)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItemConfirmation(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)])]}}])})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(
          _vm.selectedArticlePendingAppointment &&
          _vm.selectedArticlePendingAppointment.length > 0
        )?_c('v-btn',{staticClass:"text-white-color",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteAllSelectedArticlePendingAppointmentAfterConfirmation()}}},[_vm._v(" "+_vm._s(_vm.$t("cart.remove_button"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-delete ")])],1):_vm._e(),(
          _vm.selectedArticlePendingAppointment &&
          _vm.selectedArticlePendingAppointment.length > 0
        )?_c('v-btn',{staticClass:"ma-2 text-white-color",attrs:{"id":"top-pending-article","color":"warning"},on:{"click":function($event){return _vm.moveToSchedulerListing()}}},[_vm._v(" "+_vm._s(_vm.$t("nav_buttons.add_article_to_appointments"))+" ("+_vm._s(_vm.selectedArticlePendingAppointment.length)+") "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-calendar-import")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"grey-lighten-5"},on:{"click":function($event){return _vm.closePopUpModel()}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1)],1)],1),(_vm.confirmationDialog)?_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }