<template>
  <v-card flat>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <v-form
      ref="form"
      class="main_form"
      id="client-form"
      @submit.prevent="submit"
    >
      <v-btn type="submit" class="d-none" id="submit-client"></v-btn>
      <!-- ProfileContainer starts -->
      <v-container class="px-0 px-sm-4 ma-2 mt-8">
        <v-card flat>
          <!-- responsive grid starts -->
          <v-row>
            <v-col cols="12" lg="2">
              <v-row>
                <v-col lg="12" cols="auto">
                  <label class="logoImage ma-4">
                    <img
                      :src="logoImage"
                      style="
                        width: 150px;
                        height: 150px;
                        border-radius: 50%;
                        border: 3px dotted #efefef;
                      "
                    />
                    <input
                      type="file"
                      class="d-none"
                      id="files"
                      ref="files"
                      multiple
                      v-on:change="handleFilesUpload()"
                    />
                  </label>
                  <!-- <button v-on:click="submitFiles()">Submit</button> -->
                </v-col>
                <v-col>
                  <v-card flat>
                    <v-card-subtitle v-if="companyDetails">
                      <strong> {{ companyDetails.name }} </strong> <br />
                      <strong> {{ $t("company_settings.vat") }} : </strong>
                      {{ companyDetails.tva }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="name"
                class="to-upper"
                v-on:keydown="handleInput($event, 'name', 60)"
                :label="$t('company_settings.name')"
                :error-messages="
                  $store.state.validation && !$v.name
                    ? [this.$t('company_settings.text_messages.invalid_name')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="phone"
                class="to-upper"
                v-on:keydown="handleInput($event, 'phone', 60)"
                :label="$t('company_settings.phone')"
                :error-messages="
                  $store.state.validation && !$v.phone
                    ? [this.$t('company_settings.text_messages.invalid_phone')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="registrationNumber"
                class="to-upper"
                v-on:keydown="handleInput($event, 'registrationNumber', 60)"
                :label="$t('company_settings.registrationNumber')"
                :error-messages="
                  $store.state.validation && !$v.registrationNumber
                    ? [
                        this.$t(
                          'company_settings.text_messages.invalid_registrationNumber'
                        ),
                      ]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field
                :label="$t('company_settings.bank_name')"
                :placeholder="$t('company_settings.bank_name')"
                v-model="bank_name"
                class="to-upper"
                required
                v-on:keydown.enter.prevent=""
                v-on:keydown="$v.$touch()"
                :error-messages="
                  $store.state.validation && !$v.bank_name.required
                    ? [
                        this.$t(
                          'company_settings.text_messages.bank_name_required'
                        ),
                      ]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
              <v-text-field
                :label="$t('company_settings.account_number')"
                :placeholder="$t('company_settings.account_number')"
                v-model="bank_account"
                class="to-upper"
                required
                v-on:keydown.enter.prevent=""
                v-on:keydown="$v.$touch()"
                :error-messages="
                  $store.state.validation && !$v.bank_account.required
                    ? [
                        this.$t(
                          'company_settings.text_messages.bank_account_required'
                        ),
                      ]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
              <v-text-field
                :label="$t('company_settings.swift_code')"
                :placeholder="$t('company_settings.swift_code')"
                v-model="swift_code"
                class="to-upper"
                required
                v-on:keydown.enter.prevent=""
                v-on:keydown="$v.$touch()"
                :error-messages="
                  $store.state.validation && !$v.swift_code.required
                    ? [
                        this.$t(
                          'company_settings.text_messages.swift_code_required'
                        ),
                      ]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3">
              <v-select
                class="to-upper"
                :disabled="isEditingAllowed"
                v-model="denomination"
                :items="denominations"
                item-value="id"
                item-text="text"
                :label="$t('billings.denomination')"
                v-on:keydown.enter.prevent=""
                @change="$emit('update:comp_denomination', denomination)"
                :error-messages="
                  $store.state.validation && !$v.denomination.required
                    ? [this.$t('billings.text_messages.denomination_required')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-select>
              <v-select
                :autocomplete="autocompleteValueLocal"
                v-model="currency"
                :items="currencies"
                item-value="id"
                item-text="text"
                :label="$t('company_settings.currency')"
                :error-messages="
                  $store.state.validation && !$v.form.currency.required
                    ? [
                        this.$t(
                          'company_settings.text_messages.currency_required'
                        ),
                      ]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-select>
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="email"
                class="to-upper"
                v-on:keydown="handleInput($event, 'email', 60)"
                :label="$t('company_settings.email')"
                :error-messages="
                  $store.state.validation && !$v.email.email
                    ? [this.$t('company_settings.text_messages.invalid_email')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="fax"
                class="to-upper"
                v-on:keydown="handleInput($event, 'fax', 60)"
                :label="$t('company_settings.fax')"
                :error-messages="
                  $store.state.validation && !$v.fax
                    ? [this.$t('company_settings.text_messages.invalid_fax')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="gsm"
                class="to-upper"
                v-on:keydown="handleInput($event, 'gsm', 60)"
                :label="$t('company_settings.gsm')"
                :error-messages="
                  $store.state.validation && !$v.gsm
                    ? [this.$t('company_settings.text_messages.invalid_gsm')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
              <v-text-field
                :autocomplete="autocompleteValueLocal"
                v-model="web"
                class="to-upper"
                v-on:keydown="handleInput($event, 'web', 60)"
                :label="$t('company_settings.web')"
                :error-messages="
                  $store.state.validation && !$v.web
                    ? [this.$t('company_settings.text_messages.invalid_web')]
                    : []
                "
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- responsive grid ends -->
        </v-card>
      </v-container>
    </v-form>

    <v-btn
      @click="onSubmit()"
      class="d-none"
      id="submit-company-setting"
    ></v-btn>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { UPDATE_MODE } from "@/config";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    bank_account: {
      required: required,
    },
    bank_name: {
      required: required,
    },
    swift_code: {
      required: required,
    },
  },
  name: "CompanySetting",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      DeleteConfirm: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      logoImage: "",
      gradient: "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)",
      companyDetails: null,
      companyAddress: null,
      bank_name: null,
      bank_account: null,
      swift_code: null,
      denominations: [],
      denomination: null,
      currencies: [],
      currency: null,
      email: null,
      fax: null,
      gsm: null,
      name: null,
      phone: null,
      registrationNumber: null,
      web: null,
    };
  },
  mounted() {
    this.$store.commit("setValidation", false); // enable validation

    this.header = { Authorization: "Bearer " + this.token };
    this.getCompanySettings();
    this.getCompanyDenominations();
    this.getCurrencies();
    this.$store.commit("setFormMode", UPDATE_MODE);
  },
  methods: {
    getCurrencies() {
      axios
        .get(API_BASE_URL + "/currencies", { headers: this.header })
        .then(({ data }) => {
          this.currencies = this.translateKeys(data.data, "key", "currencies");
          let defaultCurrency = this.defaultCurrencyId;
          this.currencies.forEach((element) => {
            if (defaultCurrency == element.key) {
              this.currency = element;
            }
          });
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {});
    },
    getCompanySettings() {
      axios
        .get(API_BASE_URL + "/company-settings", { headers: this.header })
        .then(({ data }) => {
          data = data.data;
          this.logoImage = data.logo;
          this.companyDetails = data.detail;

          this.bank_name = data.detail.bank_name;
          this.bank_account = data.detail.bank_account;
          this.swift_code = data.detail.swift_code;
          this.companyAddress = data.address;

          this.denomination = data.detail.denomination;
          this.currency = data.detail.currency;
          this.email = data.detail.email;
          this.gsm = data.detail.gsm;
          this.fax = data.detail.fax;
          this.name = data.detail.name;
          this.phone = data.detail.phone;
          this.registrationNumber = data.detail.registration_number;
          this.web = data.detail.web;
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {});
    },
    onSubmit() {
      this.$store.commit("setValidation", true); // enable validation
      if (!this.$v.$invalid) {
        let formData = {
          bank_name: this.bank_name,
          bank_account: this.bank_account,
          swift_code: this.swift_code,
          denomination:
            typeof this.denomination == "object"
              ? this.denomination.id
              : this.denomination,
          currency:
            typeof this.currency == "object" ? this.currency.id : this.currency,
          email: this.email,
          gsm: this.gsm,
          fax: this.fax,
          name: this.name,
          phone: this.phone,
          registration_number: this.registrationNumber,
          web: this.web,
        };
        axios
          .post(API_BASE_URL + "/company-settings", formData, {
            headers: this.header,
          })
          .then(() => {
            this.$toast.success(
              this.$t("company_settings.data_saved_successfully")
            );
            localStorage.setItem(
              "currencyId",
              typeof this.currency == "object"
                ? this.currency.id
                : this.currency
            );
          })
          .catch(() => {
            this.$toast.error(this.$t("company_settings.save_failed"));
          })
          .finally(() => {});
      } else {
        this.$toast.warning(
          this.$t("company_settings.text_messages.fill_form_correctly")
        );
      }
    },
    handleFilesUpload() {
      let formData = new FormData();
      formData.append("logofile", this.$refs.files.files[0]);
      // this.header{ 'Content-Type': 'multipart/form-data'}
      axios
        .post(API_BASE_URL + "/company-settings", formData, {
          "Content-Type": "multipart/form-data",
          headers: this.header,
        })
        .then((response) => {
          this.$toast.success(this.$t("company_settings.image_uploaded"));
          this.logoImage = response.data.data.logo;
        })
        .catch(() => {
          this.$toast.error(this.$t("company_settings.image_format_error"));
        })
        .finally(() => {});
    },
    getCompanyDenominations() {
      this.getCodes("company_denomination", "company_denomination")
        .then(({ data }) => {
          this.denominations = data;
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
.logoImage:hover {
  opacity: 0.8 !important;
  cursor: copy;
}
</style>
