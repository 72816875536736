var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","min-width":"400","max-width":"500"},on:{"click:outside":function($event){return _vm.falseButtonClicked()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.falseButtonClicked()}},model:{value:(_vm.internalShowDialog),callback:function ($$v) {_vm.internalShowDialog=$$v},expression:"internalShowDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-title"},[_vm._v(" "+_vm._s(_vm.openedForOperation == "error" ? _vm.$t("error") : _vm.openedForOperation == "message" || _vm.openedForOperation == "warning" ? _vm.$t("message") : _vm.$t("confirmation"))+" ")]),_c('v-card-text',{staticClass:"dialog-text text-content",attrs:{"color":"black"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.checkboxText)?[_c('v-checkbox',{attrs:{"label":_vm.checkboxText,"color":"green","hide-details":"","readonly":""},on:{"click":function($event){_vm.isTrueButtonEnabled = !_vm.isTrueButtonEnabled}},model:{value:(_vm.isTrueButtonEnabled),callback:function ($$v) {_vm.isTrueButtonEnabled=$$v},expression:"isTrueButtonEnabled"}})]:_vm._e()],2),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),(_vm.falseText)?_c('v-btn',{staticClass:"px-4",attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.falseButtonClicked()}}},[_vm._v(" "+_vm._s(_vm.falseText)+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1):_vm._e(),(_vm.trueText)?_c('v-btn',{staticClass:"px-4",attrs:{"disabled":!_vm.isTrueButtonEnabled,"color":_vm.openedForOperation == 'delete' || _vm.openedForOperation == 'error'
            ? 'error'
            : _vm.openedForOperation == 'message'
            ? 'primary'
            : _vm.openedForOperation == 'warning'
            ? 'warning'
            : _vm.openedForOperation == 'make_bill_error'
            ? 'error'
            : 'success'},on:{"click":function($event){return _vm.trueButtonClicked()}}},[_vm._v(" "+_vm._s(_vm.trueText)+" "),(_vm.openedForOperation == 'delete')?_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-delete ")]):(_vm.openedForOperation == 'logout')?_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-logout ")]):_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-check ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }