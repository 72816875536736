<template>
  <div>
    <v-dialog :value="value" max-width="800">
      <v-form ref="form" @submit.prevent="handleSubmit">
        <v-card>
          <v-card-title>
            {{
              translate(employee ? "edit" : "add") + " " + translate("employee")
            }}
            <v-spacer />
            <v-btn color="error" icon>
              <v-icon
                v-if="employee && employee.id"
                @click="toggleDeleteConfirmation"
                >mdi-trash-can</v-icon
              >
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.first_name"
                  :label="translate('first_name')"
                  class="to-upper"
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.last_name"
                  :label="translate('last_name')"
                  class="to-upper"
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.national_id"
                  :label="translate('national_id')"
                  class="to-upper"
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.badge"
                  :label="translate('badge')"
                  class="to-upper"
                  required
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <span class="mr-5" @click="close"> <CloseButton /> </span>
            <span><SaveButton /></span>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </div>
</template>
<script>
import clockingMixin from "@/mixins/clockingMixin";
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
  name: "EmployeeForm",
  components: { ConfirmationModel, SaveButton, CloseButton },
  mixins: [clockingMixin],
  emits: ["input", "created", "updated", "deleted"],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    employee: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        national_id: "",
        badge: "",
      },
      confirmationDialog: false,
      confirmationDialogOperation: "",
      confirmationDialogText: "",
      confirmationDialogTrueText: "",
      confirmationDialogFalseText: "",
    };
  },
  computed: {
    loading() {
      return this.$store.state.progressBarLoading;
    },
  },
  watch: {
    employee(val) {
      this.form = val;
    },
    value(val) {
      if (!val) {
        this.resetForm();
      }

      if (this.employee) {
        this.form = this.employee;
      }
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    validate() {
      if (!this.isset(this.form.first_name)) {
        this.$toast.error(this.translate("first_name_error"));
        return false;
      }

      if (!this.isset(this.form.last_name)) {
        this.$toast.error(this.translate("last_name_error"));
        return false;
      }

      return true;
    },
    handleSubmit() {
      if (!this.validate()) return false;

      if (this.employee) {
        this.updateEmployee();
      } else {
        this.createEmployee();
      }
    },
    resetForm() {
      this.form = {
        first_name: "",
        last_name: "",
        national_id: "",
        badge: "",
      };
    },
    createEmployee() {
      axios
        .post(API_BASE_URL + "/employees", this.form, {
          headers: this.axiosOptions,
        })
        .then((response) => {
          this.$emit("created", response.data.data);
          this.$toast.success(this.translate("employee_created"));
          this.resetForm();
          this.$emit("input", false);
        })
        .catch((err) => {
          this.$toast.error(
            err?.response?.data?.message || this.translate("error")
          );
        });
    },
    updateEmployee() {
      axios
        .put(API_BASE_URL + "/employees/" + this.employee.id, this.form, {
          headers: this.axiosOptions,
        })
        .then((response) => {
          this.$emit("updated", response.data.data);
          this.$toast.success(this.translate("employee_updated"));
          this.$emit("input", false);
        })
        .catch((err) => {
          this.$toast.error(
            err?.response?.data?.message || this.translate("error")
          );
        });
    },
    toggleDeleteConfirmation() {
      this.confirmationDialog = false;
      setTimeout(() => {
        this.confirmationDialog = true;
        this.confirmationDialogOperation = "delete";
        this.confirmationDialogText = this.translate(
          "delete_confirmation_employee",
          { name: this.employee.name }
        );
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogFalseText = this.$t("no");
      }, 10);
    },
    confirmationButtonClicked(action) {
      if (!action) {
        return;
      }
      if (this.confirmationDialogOperation === "delete") {
        this.deleteEmployee();
      }
    },
    deleteEmployee() {
      axios
        .delete(API_BASE_URL + "/employees/" + this.employee.id, {
          headers: this.axiosOptions,
        })
        .finally(() => {})
        .then(() => {
          this.$toast.success(this.translate("deleted_successfully"));
          this.close();
          this.$emit("deleted", this.employee);
          this.resetForm();
        })
        .catch(() => {
          this.$toast.error(this.translate("contract_has_clocking_error"));
        });
    },
  },
};
</script>
