<template>
  <v-dialog
    persistent
    @click:outside="falseButtonClicked()"
    @keydown.esc="falseButtonClicked()"
    v-model="internalShowDialog"
    min-width="600"
    max-width="600"
  >
    <v-card>
      <!-- Card Title -->
      <v-card-title class="text-title">
        {{ $t("order_contributors.title") }}
      </v-card-title>
      <!-- Card Content -->
      <v-card-text class="dialog-text text-content" color="black">
        <v-row class="mb-2">
          <v-col cols="12" sm="12">
            <v-autocomplete
              autocomplete="off"
              class="to-upper mr-3"
              flat
              :items="employeesList"
              @change="addContributor()"
              item-text="internal_name"
              item-value="id"
              v-model="selected_employee_id"
              :return-object="false"
              :label="$t('order_contributors.employee_name')"
              :hide-details="true"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            fixed-header
            :footer-props="{
              'items-per-page-options': dataTableOptions,
            }"
            :hide-default-footer="true"
            :headers="headers"
            :items="contributors"
            :options="options"
            :server-items-length="totalItems"
            :loading="isLoading"
            :loading-text="this.$t('loading')"
            class="elevation-0"
            :items-per-page="itemsPerPage"
            item-key="identifier"
            height="400px"
            style="width: 100%"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.employee.internal_name }}
                </td>
                <td>
                  <v-icon
                    v-if="item"
                    color="warning"
                    class="mr-1"
                    @click="deleteContributor(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <!-- Card Actions -->
      <v-card-actions class="pr-2">
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-2"
          class="px-4"
          @click="falseButtonClicked()"
        >
          {{ $t("nav_buttons.close") }}
          <v-icon dark right> mdi-keyboard-return </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "OrderContributors",
  components: {},
  props: {
    showDialog: [Number, Boolean],
    order_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      internalShowDialog: this.showDialog,
      contributors: [],
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      options: {},
      employees: [],
      isLoading: false,
      totalItems: 0,
      selected_employee_id: null,
    };
  },
  async mounted() {
    this.employees = await this.getCompanyEmployees();
    this.resetData();
  },
  methods: {
    getCompanyEmployees() {
      return new Promise((resolve, reject) => {
        return axios
          .get(API_BASE_URL + "/employees", this.axiosHeaders)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteContributor(id) {
      this.isLoading = true;
      axios
        .delete(API_BASE_URL + "/order_contributors/" + id, {
          headers: this.header,
        })
        .then(() => {})
        .finally(() => {
          this.isLoading = false;
          this.resetData();
          this.getOrderContributors();
        });
    },
    addContributor() {
      if (this.selected_employee_id && this.order_id) {
        this.isLoading = true;
        let data = {
          order_id: this.order_id,
          employee_id: this.selected_employee_id,
        };
        axios
          .post(API_BASE_URL + "/order_contributors", data, {
            headers: this.header,
          })
          .then(() => {})
          .finally(() => {
            this.isLoading = false;
            this.resetData();
            this.getOrderContributors();
          });
      }
    },
    resetData() {
      this.selected_employee_id = null;
    },
    getOrderContributors() {
      this.isLoading = true;
      axios
        .get(API_BASE_URL + "/order_contributors?order_id=" + this.order_id, {
          headers: this.header,
        })
        .then(({ data }) => {
          this.contributors = data.data;
          this.totalItems = this.contributors.length;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    falseButtonClicked() {
      this.$emit("update:showDialog", false);
      this.internalShowDialog = false;
    },
  },
  computed: {
    employeesList() {
      return this.employees.filter((employee) => {
        let alreadyAddedInContributor = false;
        this.contributors.forEach((contributor) => {
          if (contributor.employee_id == employee.id) {
            alreadyAddedInContributor = true;
          }
        });
        return !alreadyAddedInContributor;
      });
    },
    headers() {
      let data = [
        // {
        //     text: this.$t("order_contributors.id"),
        //     value: "id",
        //     width: 100,
        //     sortable: false,
        // },
        {
          text: this.$t("order_contributors.employee_name"),
          value: "bill_date",
          width: "98%",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          width: "2%",
          sortable: false,
        },
      ];

      return data;
    },
  },
  watch: {
    showDialog: {
      handler: function (val) {
        this.internalShowDialog = val;
        if (val == true) {
          this.getOrderContributors();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 27px !important;
}
.text-content {
  font-size: 18px !important;
}
.text-title {
  font-size: 24px !important;
}
</style>
