<template>
  <v-row>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <v-data-table
      fixed-header
      :footer-props="{
        'items-per-page-options': dataTableOptions,
      }"
      :headers="headers"
      :items="rec_items"
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :sort-by="sortBy"
      :sort-desc.sync="sortDesc"
      :expanded.sync="expanded"
      show-expand
      :loading-text="this.$t('loading')"
      @update:page="updatePagination"
      @update:items-per-page="updateItemsPerPage"
      class="elevation-0"
      item-key="id"
      :height="'calc(-212px + 100vh)'"
      style="width: 100%"
      @update:sort-by="updateSort"
      @update:sort-desc="updateSortOrder"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-4">
          <v-col class="d-flex" cols="12" sm="12">
            <v-menu
              v-model="menu_rec_date"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="rec_date"
                  :label="$t('receptions.rec_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:append="rec_date = null"
                  append-icon="mdi-close"
                  class="mr-2 to-upper"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="rec_date"
                @input="menu_rec_date = false"
                no-title
                scrollable
                :locale="userLanguageId"
              ></v-date-picker>
            </v-menu>

            <v-autocomplete
              flat
              :items="suppliers"
              v-model="supplier"
              :label="$t('receptions.supplier')"
              class="mr-2 to-upper"
              item-value="id"
              item-text="name"
              @click:append="supplier = null"
              append-icon="mdi-close"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>

            <v-autocomplete
              flat
              :items="clients"
              v-model="client"
              :label="$t('receptions.ordered_for')"
              class="mr-2 to-upper"
              item-value="id"
              item-text="name"
              @click:append="client = null"
              :search-input.sync="searchClient"
              append-icon="mdi-close"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>

            <v-text-field
              class="mr-3 to-upper"
              :label="$t('receptions.reference')"
              v-bind:clearable="true"
              clear-icon="mdi-close"
              v-model="search_ref"
              @focus="reference = null"
              @input="debounceInput"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>

            <v-autocomplete
              v-model="status"
              :items="statuses"
              :label="$t('receptions.status')"
              item-value="id"
              item-text="text"
              class="mr-2"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              @click:append="status = null"
              append-icon="mdi-close"
            ></v-autocomplete>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr @click="addToExpand(item)">
          <td>{{ item.id }}</td>
          <td>
            <template v-if="item.supplier">
              {{ item.supplier.identifier }}
            </template>
          </td>
          <td>
            <template
              v-if="
                item.supplier_order_details &&
                item.supplier_order_details.article
              "
            >
              {{
                item.supplier_order_details.article.title_description[0]
                  .description
              }}
              <br />
              <i>
                <TextComponentWithExtraIcons
                  :value="item.supplier_order_details.article.identifier"
                ></TextComponentWithExtraIcons>
              </i>
            </template>
            <template v-else> </template>
          </td>
          <td align="right">
            <template v-if="item.supplier_order_details">
              {{ item.supplier_order_details.ordered_quantity }}
            </template>
            <template v-else> </template>
          </td>
          <td align="right">
            <template v-if="item.supplier_order_details">
              {{ item.supplier_order_details.confirmed_quantity }}
            </template>
            <template v-else> </template>
          </td>
          <td align="right">
            <template v-if="item.supplier_order_details">
              {{ item.supplier_order_details.received_quantity }}
            </template>
            <template v-else> </template>
          </td>
          <td>
            <template v-if="item.order">
              {{ item.order.client.name }}
            </template>
            <template v-else>
              {{ $t("receptions.internal_stock") }}
            </template>
          </td>
          <td>
            {{ item.reference }}
          </td>
          <td>
            <template v-if="item.supplier_order_reception.length == 1">
              {{ item.supplier_order_reception[0].delivery_note_reference }}
            </template>
          </td>
          <td>
            <template v-if="item.supplier_order_reception">
              {{ item.supplier_order_reception.reception_number }}
            </template>
            <template v-else> </template>
          </td>
          <td>
            {{ formatDateTime(item.supplier_order_details.delivery_date) }}
          </td>

          <td>
            {{ formatDateTime(item.created_at) }}
          </td>

          <td>
            <template v-if="item.supplier_order_reception">
              {{ formatDateTime(item.supplier_order_reception.created_at) }}
            </template>
            <template v-else> </template>
          </td>
          <td>
            <v-tooltip bottom z-index="9999">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.status.key == 'order_registered'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-document-multiple
                </v-icon>
                <v-icon
                  v-else-if="item.status.key == 'successfully_ordered'"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                >
                  mdi-cart-check
                </v-icon>
                <v-icon
                  v-else-if="item.status.key == 'order_supplier_error'"
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                >
                  mdi-close-network
                </v-icon>
                <v-icon
                  v-else-if="item.status.key == 'order_canceled'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-archivecancel
                </v-icon>
                <v-icon
                  v-else-if="item.status.key == 'partially_received'"
                  v-bind="attrs"
                  v-on="on"
                  color="success"
                >
                  mdi-archive-arrow-down</v-icon
                >
                <v-icon
                  v-else-if="item.status.key == 'fully_received'"
                  v-bind="attrs"
                  v-on="on"
                  color="success"
                >
                  mdi-archive-check
                </v-icon>
              </template>
              <span>
                {{
                  translateKeys(item.status.key, "key", "receptions.statuses")
                }}
              </span>
            </v-tooltip>
          </td>
          <td>
            <v-icon
              v-if="
                item.supplier_order_reception.length > 0 &&
                checkIfReceived(item.supplier_order_reception) &&
                !(expanded[0] && expanded[0].id == item.id)
              "
              >mdi-arrow-down-drop-circle-outline</v-icon
            >
            <v-icon
              color="green"
              v-if="expanded[0] && expanded[0].id == item.id"
              >mdi-arrow-up-drop-circle</v-icon
            >
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ item }">
        <tr style="background-color: #ebf4e4">
          <td colspan="3">
            <strong>
              {{ $t("receptions.subtable.delivery_note_reference") }}
            </strong>
          </td>
          <td colspan="3">
            <strong> {{ $t("receptions.subtable.reception_no") }} </strong>
          </td>
          <td colspan="3">
            <strong> {{ $t("receptions.subtable.received_quant") }} </strong>
          </td>
          <td colspan="3">
            <strong> {{ $t("receptions.subtable.received_at") }} </strong>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <template v-for="(reception, index) in item.supplier_order_reception">
          <tr
            v-if="reception.received_quantity != 0"
            style="background-color: #f7fff1"
            :key="index"
          >
            <td align="left" colspan="3">
              {{ reception.delivery_note_reference }}
            </td>
            <td align="left" colspan="3">{{ reception.reception_number }}</td>
            <td align="left" colspan="3">{{ reception.received_quantity }}</td>
            <td align="left" colspan="3">{{ reception.created_at }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "ReceptionListing",
  components: {},
  mounted() {
    //
    this.header = { Authorization: "Bearer " + this.token };
    this.$store.commit("setFormMode", VIEW_MODE);
    this.getStatuses();
    this.fetchData();
    // this.getSuppliers();
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "id",
      sortOrd: "DESC",
      sortDesc: true,
      options: {},
      pagination: {},
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      status: null,
      statuses: [],
      suppliers: [],
      supplier: null,
      searchSupplier: null,
      menu_rec_date: false,
      clients: [],
      client: null,
      rec_date: null,
      searchClient: null,
      search_ref: null,
      rec_items: [],
      isLoading: false,
      expanded: [],
      isDebounceCancelled: false,
    };
  },
  methods: {
    // getSuppliers(){
    // this.suppliers = [];
    // axios.get(API_BASE_URL + '/suppliers?internal_supplier=true&items_per_page=-1&parent_also=true&search='+ this.searchSupplier,{headers: this.header})
    // .then(({ data }) => {
    //     this.suppliers = data.data;
    // })
    // .catch(function (error) {

    // });
    // },
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      console.log("this input");
    }, 500),
    changeDialog() {
      this.dialog = false;
    },
    checkIfReceived(reception) {
      let received_quantity = 0;
      if (reception.length > 0) {
        reception.forEach((element) => {
          received_quantity += element.received_quantity;
        });
      }
      return received_quantity;
    },
    fetchData() {
      axios
        .get(
          API_BASE_URL +
            "/receptions/orders?page=" +
            this.page +
            "&items_per_page=" +
            this.itemsPerPage +
            "&sortBy=" +
            this.sortBy +
            "&sortOrd=" +
            (this.sortOrd ? this.sortOrd : "") +
            (this.supplier ? "&supplier_id=" + this.supplier.id : "") +
            (this.search_ref ? "&reference=" + this.search_ref : "") +
            (this.status ? "&status_id=" + this.status.id : "") +
            (this.rec_date ? "&reception_date=" + this.rec_date : "") +
            (this.client ? "&ordered_for=" + this.client.id : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.rec_items = data.data;
          this.retainJustSupplierWithOrders();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    retainJustSupplierWithOrders() {
      let newSuppliers = [];
      this.rec_items.forEach((rec_item) => {
        newSuppliers.push(rec_item.supplier);
      });
      this.suppliers = newSuppliers;
    },
    addToExpand(item) {
      if (
        item.supplier_order_reception.length > 0 &&
        this.checkIfReceived(item.supplier_order_reception)
      ) {
        let processedQuantity = 0;
        item.supplier_order_reception.forEach((reception) => {
          if (!(reception.modified && reception.modified == true)) {
            reception.received_quantity =
              reception.received_quantity - processedQuantity;
            processedQuantity += reception.received_quantity;
            reception.modified = true;
          }
        });
        if (this.expanded && this.expanded.length > 0) {
          if (this.expanded[0].id == item.id) {
            this.expanded = [];
          } else {
            this.expanded = [];
            this.expanded.push(item);
          }
        } else {
          this.expanded.push(item);
        }
      }
    },
    showImage() {
      alert("THE IMAGE");
    },
    deleteItem() {},
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    getStatuses() {
      return new Promise((resolve, reject) => {
        this.getCodes("supplier_order_statuses", "receptions.statuses")
          .then(({ data }) => {
            this.statuses = data;
            resolve();
            this.modifyStatusesList();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          });
      });
    },
    modifyStatusesList() {
      let modifiedStatus = [[], [], []];
      let statuses = [[], [], []];
      this.statuses.forEach((status) => {
        if (
          status.key == "order_registered" ||
          status.key == "order_supplier_error" ||
          status.key == "order_canceled"
        ) {
          modifiedStatus[0].push(status.id);
          statuses[0] = {
            text: this.$t("receptions.statuses.ordered"),
            id: modifiedStatus[0],
          };
        } else if (status.key == "fully_received") {
          modifiedStatus[1].push(status.id);
          statuses[1] = {
            text: this.$t("receptions.statuses.fully_received"),
            id: modifiedStatus[1],
          };
        } else if (
          status.key == "successfully_ordered" ||
          status.key == "partially_received"
        ) {
          modifiedStatus[2].push(status.id);
          statuses[2] = {
            text: this.$t("receptions.statuses.successfully_ordered"),
            id: modifiedStatus[2],
          };
        }
      });

      this.statuses = statuses;
      // this.status = this.statuses[2];
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }

      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("receptions.id"),
          align: "start",
          sortable: true,
          value: "id",
          width: "70",
        },
        {
          text: this.$t("receptions.supplier"),
          align: "start",
          sortable: false,
          value: "id",
          width: "50",
        },
        {
          text: this.$t("receptions.article"),
          align: "start",
          sortable: false,
          value: "id",
          width: "150",
        },
        {
          text: this.$t("receptions.ordered_quant"),
          align: "end",
          sortable: false,
          value: "id",
          width: "5",
        },
        {
          text: this.$t("receptions.confirmed_quant"),
          align: "end",
          sortable: false,
          value: "id",
          width: "5",
        },
        {
          text: this.$t("receptions.received_quant"),
          align: "end",
          sortable: false,
          value: "id",
          width: "5",
        },
        {
          text: this.$t("receptions.ordered_for"),
          align: "start",
          sortable: false,
          value: "id",
          width: "100",
        },
        {
          text: this.$t("receptions.reference"),
          align: "start",
          sortable: false,
          value: "id",
          width: "100",
        },
        {
          text: this.$t("receptions.delivery_noteref"),
          align: "start",
          sortable: false,
          value: "id",
          width: "50",
        },
        {
          text: this.$t("receptions.reception_no"),
          align: "start",
          sortable: false,
          value: "id",
          width: "50",
        },
        {
          text: this.$t("receptions.del_date"),
          align: "start",
          sortable: true,
          value: "supplier_order_details.delivery_date",
          width: "130",
        },
        {
          text: this.$t("receptions.ordered_date"),
          align: "start",
          sortable: true,
          value: "created_at",
          width: "130",
        },
        {
          text: this.$t("receptions.received_at"),
          align: "start",
          sortable: false,
          value: "id",
          width: "130",
        },
        {
          text: this.$t("receptions.status"),
          align: "start",
          sortable: false,
          value: "id",
          width: "80",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    searchClient: _.debounce(function () {
      if (
        this.searchClient &&
        this.searchClient.length > 0 &&
        (!this.client || (this.client && this.client.name != this.searchClient))
      ) {
        this.clients = [];
        axios
          .get(API_BASE_URL + "/clients?search=" + this.searchClient, {
            headers: this.header,
          })
          .then(({ data }) => {
            this.clients = data.data;
          })
          .catch(function () {})
          .finally(() => {
            this.isLoading = false;
          });
      }
    }, 1000),
    search_ref: _.debounce(function () {
      this.fetchData();
    }, 1000),
    rec_date: {
      handler: function () {
        this.fetchData();
      },
    },
    supplier: {
      handler: function () {
        this.fetchData();
      },
    },
    client: {
      handler: function () {
        this.fetchData();
      },
    },
    status: {
      handler: function () {
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped></style>
