<template>
  <div>
    <v-row>
      {{ message }}
    </v-row>
    <v-row class="pt-4">
      <v-col> </v-col>
      <v-spacer></v-spacer>
      <v-col class="pt-4">
        <v-btn
          x-small
          class="cancel-move-scheduler"
          @click="getElementById('cancel-move').click()"
          >{{ buttonText }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
  },
};
</script>
