<template>
  <div
    @mouseenter="showDeleteButton = 'visible'"
    @mouseleave="showDeleteButton = 'hidden'"
  >
    <input
      v-model="time"
      type="time"
      class="edit-time"
      :id="elementId"
      :readonly="disabled"
      :style="{
        cursor: 'text',
        width: '75px',
      }"
    />
    <v-btn
      v-if="
        action === 'update' &&
        target === 'check_in' &&
        !clockingIsForFirstColumn(clocking)
      "
      small
      icon
      @click="confirmDeleteAction(clocking, day)"
      class="mr-0"
      :style="{
        visibility: showDeleteButton,
        cursor: 'pointer',
      }"
    >
      <v-icon small color="red">mdi-close</v-icon>
    </v-btn>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    />
  </div>
</template>
<script>
import ConfirmationDialogMixin from "@/mixins/ConfirmationModelMixin";
import axios from "@/plugins/axios";
import mainAxios from "axios";
import _deounce from "lodash/debounce";
import { API_BASE_URL } from "@/config";
export default {
  name: "ClockingInput",
  props: ["clocking", "target", "type", "employee", "day", "disabled"],
  mixins: [ConfirmationDialogMixin],
  emits: ["updated"],
  data() {
    return {
      time: null,
      showDeleteButton: "hidden",
    };
  },
  watch: {
    clocking: {
      handler() {
        let getTime = this.clocking?.[this.target + "_time_only"];
        if (getTime === "00:00") {
          getTime = null;
        }

        this.time = getTime;
      },
      immediate: true,
    },
    time: _deounce(function () {
      this.saveHandler();
    }, 500),
  },
  computed: {
    action() {
      return this.clocking?.id && !this.clocking?.is_placeholder
        ? "update"
        : "create";
    },
    elementId() {
      // unique id for each clocking input using random number or uuid
      return this.action === "create"
        ? Math.floor(Math.random() * 1_000_000_000_000)
        : this.generateId(this.clocking);
    },
  },
  methods: {
    saveHandler() {
      let payload = {
        new_time: this.time,
        date: this.day,
        target: this.target,
        action: this.action,
        type: this.type,
        employee_contract_id: this.employee?.active_contract?.id,
        day: this.day,
      };
      let url =
        API_BASE_URL +
        "/employees/clocking/" +
        (this.clocking?.id || this.clocking);

      mainAxios
        .put(url, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
        .then((response) => {
          response.data.action = this.action;
          this.$emit("updated", response.data);
        });
    },
    confirmDeleteAction(clocking, day) {
      this.confirmationDialog = false;

      setTimeout(() => {
        this.confirmationDialog = true;
        this.confirmationDialogConfirmationText = this.translate(
          "delete_clocking_confirmation_text"
        );
        this.confirmationDialogFalseText = this.$t("no");
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogOperation = "deleteClocking";
        this.confirmationDialogOperationData = {
          clocking,
          day,
        };
      }, 50);
    },
    confirmationButtonClicked(action) {
      if (!action) return;

      if (this.confirmationDialogOperation === "deleteClocking") {
        this.deleteClocking(
          this.confirmationDialogOperationData.clocking,
          this.confirmationDialogOperationData.day
        );
      }
    },
    deleteClocking(clocking) {
      return new Promise((resolve) => {
        let url =
          API_BASE_URL +
          "/employees/clocking/" +
          clocking.id +
          "?employee_contract_id=" +
          this.employee.active_contract.id;
        axios
          .delete(url, { headers: this.axiosOptions })
          .finally(() => {})
          .then((response) => {
            this.$emit("updated", response.data);
            this.$store.state.forceReloadComponentKey++;
            resolve();
          });
      });
    },
    translate(key) {
      return this.$t("clocking_section." + key);
    },
    generateId(clocking, target = null) {
      return "clocking_" + clocking?.id + "_" + (target ?? this.target);
    },
    clockingIsForFirstColumn(clocking) {
      return !["break", "mission"].includes(clocking.type.key);
    },
  },
};
</script>
<style scoped>
.edit-time {
  outline: none;
  border: none;
}

input[type="time"] {
  border: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
