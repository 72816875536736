var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('h1',{attrs:{"align":"center"}},[_vm._v("Dynamic View Test for DEMO COMPANY")]),_c('v-form',{ref:"form",staticClass:"main_form",attrs:{"id":"client-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-btn',{staticClass:"d-none",attrs:{"type":"submit","id":"submit-client"}}),_c('v-container',{staticClass:"px-0 px-sm-4 ma-2 mt-8"},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12","cols":"auto"}},[_c('label',{staticClass:"logoImage ma-4"},[_c('img',{staticStyle:{"width":"150px","height":"150px","border-radius":"50%","border":"3px dotted #efefef"},attrs:{"src":_vm.logoImage}}),_c('input',{ref:"files",staticClass:"d-none",attrs:{"type":"file","id":"files","multiple":""},on:{"change":function($event){return _vm.handleFilesUpload()}}})])]),_c('v-col',[_c('v-card',{attrs:{"flat":""}},[(_vm.companyDetails)?_c('v-card-subtitle',[_c('strong',[_vm._v(" "+_vm._s(_vm.companyDetails.name)+" ")]),_vm._v(" "),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("company_settings.vat"))+" : ")]),_vm._v(" "+_vm._s(_vm.companyDetails.tva)+" ")]):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.name'),"error-messages":_vm.$store.state.validation && !_vm.$v.name
                  ? [this.$t('company_settings.text_messages.invalid_name')]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'name', 60)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.phone'),"error-messages":_vm.$store.state.validation && !_vm.$v.phone
                  ? [this.$t('company_settings.text_messages.invalid_phone')]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'phone', 60)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.registrationNumber'),"error-messages":_vm.$store.state.validation && !_vm.$v.registrationNumber
                  ? [
                      this.$t(
                        'company_settings.text_messages.invalid_registrationNumber'
                      ),
                    ]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'registrationNumber', 60)}},model:{value:(_vm.registrationNumber),callback:function ($$v) {_vm.registrationNumber=$$v},expression:"registrationNumber"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{staticClass:"to-upper",attrs:{"label":_vm.$t('company_settings.bank_name'),"placeholder":_vm.$t('company_settings.bank_name'),"required":"","error-messages":_vm.$store.state.validation && !_vm.$v.bank_name.required
                  ? [
                      this.$t(
                        'company_settings.text_messages.bank_name_required'
                      ),
                    ]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();},function($event){return _vm.$v.$touch()}]},model:{value:(_vm.bank_name),callback:function ($$v) {_vm.bank_name=$$v},expression:"bank_name"}}),_c('v-text-field',{staticClass:"to-upper",attrs:{"label":_vm.$t('company_settings.account_number'),"placeholder":_vm.$t('company_settings.account_number'),"required":"","error-messages":_vm.$store.state.validation && !_vm.$v.bank_account.required
                  ? [
                      this.$t(
                        'company_settings.text_messages.bank_account_required'
                      ),
                    ]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();},function($event){return _vm.$v.$touch()}]},model:{value:(_vm.bank_account),callback:function ($$v) {_vm.bank_account=$$v},expression:"bank_account"}}),_c('v-text-field',{staticClass:"to-upper",attrs:{"label":_vm.$t('company_settings.swift_code'),"placeholder":_vm.$t('company_settings.swift_code'),"required":"","error-messages":_vm.$store.state.validation && !_vm.$v.swift_code.required
                  ? [
                      this.$t(
                        'company_settings.text_messages.swift_code_required'
                      ),
                    ]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();},function($event){return _vm.$v.$touch()}]},model:{value:(_vm.swift_code),callback:function ($$v) {_vm.swift_code=$$v},expression:"swift_code"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{staticClass:"to-upper",attrs:{"disabled":_vm.isEditingAllowed,"items":_vm.denominations,"item-value":"id","item-text":"text","label":_vm.$t('billings.denomination'),"error-messages":_vm.$store.state.validation && !_vm.$v.denomination.required
                  ? [this.$t('billings.text_messages.denomination_required')]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();},"change":function($event){return _vm.$emit('update:comp_denomination', _vm.denomination)}},model:{value:(_vm.denomination),callback:function ($$v) {_vm.denomination=$$v},expression:"denomination"}}),_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.currencies,"item-value":"id","item-text":"text","label":_vm.$t('company_settings.currency'),"error-messages":_vm.$store.state.validation && !_vm.$v.form.currency.required
                  ? [
                      this.$t(
                        'company_settings.text_messages.currency_required'
                      ),
                    ]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.email'),"error-messages":_vm.$store.state.validation && !_vm.$v.email.email
                  ? [this.$t('company_settings.text_messages.invalid_email')]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'email', 60)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.fax'),"error-messages":_vm.$store.state.validation && !_vm.$v.fax
                  ? [this.$t('company_settings.text_messages.invalid_fax')]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'fax', 60)}},model:{value:(_vm.fax),callback:function ($$v) {_vm.fax=$$v},expression:"fax"}}),_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.gsm'),"error-messages":_vm.$store.state.validation && !_vm.$v.gsm
                  ? [this.$t('company_settings.text_messages.invalid_gsm')]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'gsm', 60)}},model:{value:(_vm.gsm),callback:function ($$v) {_vm.gsm=$$v},expression:"gsm"}}),_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.web'),"error-messages":_vm.$store.state.validation && !_vm.$v.web
                  ? [this.$t('company_settings.text_messages.invalid_web')]
                  : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'web', 60)}},model:{value:(_vm.web),callback:function ($$v) {_vm.web=$$v},expression:"web"}})],1)],1)],1)],1)],1),_c('v-btn',{staticClass:"d-none",attrs:{"id":"submit-company-setting"},on:{"click":function($event){return _vm.onSubmit()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }