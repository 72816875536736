<template>
  <v-card class="elevation-0">
    <v-row>
      <v-col cols="12" sm="1" md="1" class="py-0 px-1 mb-0">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-simple-checkbox
              v-model="with_depot"
              v-on="on"
              hide-details
              class="shrink pa-0 mt-5"
              color="primary"
              value="false"
              v-ripple
            ></v-simple-checkbox>
          </template>
          <span>{{ $t("tyre_hotel.with_depot") }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" sm="9" md="9" class="py-0 pl-4 pr-0">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="search"
          class="to-upper ml-0"
          :placeholder="$t('filter_customer.placeholder_search_customer')"
          append-icon="mdi-close"
          v-on:keydown="handleInput($event, 'alphanumeric', 20)"
          v-on:keydown.enter.prevent="getClientVehicles()"
          @keydown.enter="this.getClientVehicles()"
          @click:append="
            search = null;
            getClientVehicles();
          "
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="ml-n5 number-plate-area elevation-0">
      <v-list two-line>
        <v-list-item-group
          v-model="selected_index"
          return-object
          active-class="selected_customer"
        >
          <template v-for="(item, index) in client_vehicles">
            <v-list-item :key="item.title">
              <template>
                <v-list-item-content>
                  <!-- <template v-if="item" v-slot:prepend>

                  </template> -->
                  <v-list-item-title>
                    <template v-if="item.is_out_of_circulation">
                      <v-tooltip top z-index="9999">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="ma-0 mr-1"
                            color="warning"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-car-arrow-right
                          </v-icon>
                        </template>
                        {{ $t("vehicle_is_out_of_circulation") }}
                      </v-tooltip>
                    </template>
                    {{ item.registration_number }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text--primary">
                    {{ makeClientName(item) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-divider
              v-if="index < client_vehicles.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-card>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";

export default {
  name: "FilterCustomer",
  props: {
    selected_vehicle: [Object],
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      client_vehicles: {},
      search: null,
      with_depot: true,
      selected_index: null,
      isDebounceCancelled: false,
    };
  },
  mounted() {
    this.$store.state.apiAbortControllerMap = {};
    this.header = { Authorization: "Bearer " + this.token };
    this.autocompleteValueLocal = this.autocompleteValue();
    this.getClientVehicles(true);
  },
  methods: {
    makeClientName(item) {
      return (item.name ?? "") + " " + (item.first_name ?? "");
    },
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.getClientVehicles();
    }, 1500),
    getClientVehicles(autoSelectFirst = false) {
      this.selected_index = null;
      /*axios.get(API_BASE_URL + '/client_vehicles' +
          "?page=1" +
          "&items_per_page=20" +
          (this.search ? ('&search=' + encodeURIComponent(this.search)) : '')
          ,{headers: this.header}
      )*/
      axios
        .get(
          API_BASE_URL +
            "/depots/vehicles" +
            "?page=1" +
            "&items_per_page=20" +
            "&with_depot=" +
            parseInt(this.with_depot ? 1 : 0) +
            (this.search ? "&search=" + encodeURIComponent(this.search) : ""),
          {
            headers: this.header,
            signal: this.abortController(),
          }
        )
        .then(({ data }) => {
          this.client_vehicles = data.data;
          if (autoSelectFirst) {
            this.selected_index = 0;
          }
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {});
    },
  },
  watch: {
    selected_index() {
      this.$emit(
        "update:selected_vehicle",
        this.client_vehicles[this.selected_index]
      );
    },
    with_depot() {
      this.getClientVehicles(false);
    },
  },
};
</script>
<style scoped>
.number-plate-area {
  max-height: 82vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.selected_customer {
  background-color: rgb(230, 230, 230) !important;
}
</style>
