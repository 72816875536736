<template>
  <div>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <!-- <v-overlay :value="isLoadingInProgress">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay> -->

    <v-row class="pb-4">
      <v-col class="ml-2" cols="12" sm="1">
        <v-select
          v-model="viewType"
          :items="viewTypes"
          item-text="text"
          item-value="id"
          outlined
          dense
          return-object
          class="pt-1"
          hide-details="auto"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" class="pt-4 calendar-heading">
        <template v-if="worktypes.length > 0">
          <!-- {{ worktypes[worktype].name }} -->
          <v-select
            style="width: 180px"
            v-model="resourceType"
            :items="resourceTypes"
            item-text="text"
            item-value="id"
            menu-props="auto"
            hide-details
            outlined
            dense
            single-line
            return-object
          ></v-select>
        </template>
      </v-col>
      <v-col cols="12" sm="3" class="pt-4 ml-n14 mt-1 calendar-heading">
        <strong>
          <v-icon @click="setPreviousDate()" style="font-size: 24px"
            >mdi-chevron-left</v-icon
          >
          <v-icon right>mdi-calendar-month</v-icon>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                label="Picker in menu"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                style="color: #1976d2"
              >
                {{ getDay(selectedDate) }}
                {{ formatDate(selectedDate, "dd-mm-yyyy") }}
              </span>
            </template>
            <v-date-picker
              id="datepicker"
              :first-day-of-week="1"
              :weekday-format="getDay"
              v-model="selectedDate"
              :allowed-dates="allowedDates(checkNotAvailableDays())"
              elevation="2"
              no-title
              :locale="userLanguageId"
              full-width
              :events="functionEvents"
              :picker-date.sync="pickerDate"
            >
            </v-date-picker>
          </v-menu>
          <v-icon @click="setNextDate()" style="font-size: 24px"
            >mdi-chevron-right</v-icon
          >
        </strong>
      </v-col>
      <v-spacer></v-spacer>

      <v-col cols="12" sm="2" class="">
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn class="mt-2" color="primary" @click="resetDate()">
              {{ $t("scheduler.today") }}
              <v-icon dark right> mdi-calendar-today </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3" class="mr-0">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          class="mr-3 pt-1 to-upper"
          label="Search"
          prepend-icon="mdi-magnify"
          v-bind:clearable="true"
          clear-icon="mdi-close"
          v-model="search"
          @input="debounceInput"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    <br />
    <v-row :class="search && search.length > 0 ? 'inactive' : 'active'">
      <!-- <v-col cols="12" sm="2">
                <v-row>
                    <v-col class="ml-2 pr-3">
                        <v-date-picker 
                            :first-day-of-week="1"
                            :weekday-format="getDay"
                            v-model="selectedDate"
                            :allowed-dates="allowedDates(checkNotAvailableDays())"
                            elevation="2"
                            no-title
                            :locale="userLanguageId"
                            full-width
                            :events="functionEvents"
                            :picker-date.sync="pickerDate"
                        >
                        </v-date-picker>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-col> -->
      <v-col
        id="dayview"
        cols="12"
        sm="12"
        v-if="
          workpoints.length > 0 &&
          timeslots.length > 0 &&
          workresources.length > 0
        "
        style="max-height: 76vh; overflow: scroll !important"
      >
        <day-view
          v-if="
            workpoints.length > 0 &&
            timeslots.length > 0 &&
            workresources.length > 0
          "
          :workpoints.sync="workpoints"
          :worktype.sync="worktype"
          :timeslots.sync="timeslots"
          :workresources.sync="workresources"
          :appointments.sync="appointments"
          :selectedDate.sync="selectedDate"
          :minimumTimeTask.sync="minimumTimeTask"
          :resourceType.sync="resourceType"
          :readonlygrids="false"
        ></day-view>
      </v-col>
      <!-- In case of selected date is Sunday then will set default work point id   -->
      <v-col
        id="dayview"
        cols="12"
        sm="12"
        v-if="
          workpoints.length > 0 &&
          timeslots.length == 0 &&
          workresources.length > 0 &&
          new Date(selectedDate).getDay() == 0
        "
      >
        <day-view
          :workpoints.sync="workpoints"
          :worktype.sync="worktype"
          :timeslots="[
            {
              id: 13,
              work_point_id: 0,
              dow: 0,
              start_time: '07:00:00',
              end_time: '20:30:00',
              is_excluded: 0,
            },
          ]"
          :workresources.sync="workresources"
          :appointments.sync="appointments"
          :selectedDate.sync="selectedDate"
          :minimumTimeTask.sync="minimumTimeTask"
          :resourceType.sync="resourceType"
          :readonlygrids="true"
        ></day-view>
      </v-col>
      <v-col
        cols="12"
        v-if="
          (workpoints.length == 0 ||
            (timeslots.length == 0 && new Date(selectedDate).getDay() != 0) ||
            workresources.length == 0) &&
          isLoadedCriticalThingsForDayView == true
        "
        sm="12"
        style="max-height: 76vh"
      >
        <template>
          <div style="position: relative; top: 30vh; left: 15vw">
            {{
              $t(
                "scheduler.text_messages.work_points_or_work_resources_or_work_time_slots_not_defined_for_company"
              )
            }}
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row :class="search && search.length > 0 ? 'active' : 'inactive'">
      <v-data-table
        :footer-props="{
          'items-per-page-options': dataTableOptions,
        }"
        :options="options"
        :server-items-length="totalItems"
        :loading-text="this.$t('loading')"
        :headers="searchHeaders"
        :items="searchData"
        :items-per-page="5"
        class="elevation-0"
        :height="'calc(-240px + 100vh)'"
        style="width: 100%"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-by="updateSort"
        @update:sort-desc="updateSortOrder"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr
            :style="addTaskStyle(item)"
            :class="addClass(item)"
            @click="editScheduler(item)"
          >
            <td>
              <v-icon
                color="white"
                style="font-size: 20px"
                v-if="
                  item &&
                  item.appointment_status &&
                  item.appointment_status.key == 'appointment_is_executed'
                "
                >mdi-account-check</v-icon
              >
              {{ item.work_type.name }}
            </td>
            <td>
              {{ item.start_time }}
            </td>
            <td>
              {{ item.client.name }}
            </td>
            <td>
              <template v-if="item.vehicle">
                {{ item.vehicle.registration_number }}
              </template>
            </td>
            <td>
              {{ formatDateTime(item.created_at) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-row>
    <v-btn
      @click="createCalendar()"
      class="d-none"
      id="create-calendar"
    ></v-btn>
    <v-btn
      @click="printReportFromTopButton()"
      class="d-none"
      id="print-report"
    ></v-btn>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { SCHEDULER_LISTING_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import DayView from "@/components/Calendar/DayView.vue";

export default {
  name: "SchedulerListing",
  components: {
    "day-view": DayView,
  },
  mounted() {
    this.viewType = this.viewTypes[0];
    this.worktype = 0;
    this.autocompleteValueLocal = this.autocompleteValue();
    if (this.resourceTypes.length > 0) {
      this.resourceType = this.resourceTypes[0];
    }
  },
  activated() {
    if (this.$store.state.refreshList) {
      this.search = null;
      this.$store.commit("setRefreshList", false);
    }
    this.selectedDate = this.$store.state.schedulerDate
      ? this.$store.state.schedulerDate.substr(0, 10)
      : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
    this.pickerDate = this.$store.state.schedulerDate
      ? this.$store.state.schedulerDate.substr(0, 8)
      : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 8);
    this.$store.commit("setFormMode", SCHEDULER_LISTING_MODE);
    if (
      this.workpoints.length == 0 ||
      this.timeslots.length == 0 ||
      this.workresources.length == 0
    ) {
      this.isLoadedCriticalThingsForDayView = false;
      this.getWorkTypes().then(() => {
        this.getWorkPoints().then(() => {
          this.getWorkResources().then(() => {
            this.getTimeSlotsForWorkPoints().then(() => {
              // this.getAppointments().then(() => {
              this.isLoadedCriticalThingsForDayView = true;
              // });
            });
          });
          this.getWorkingDaysForThePoints();
        });
      });
    } else {
      this.getAppointments().then(() => {
        this.isLoadedCriticalThingsForDayView = true;
      });
      this.getMonthlyAppointments();
    }

    this.appointmentInterval = window.setInterval(() => {
      this.getAppointments();
    }, 60000);
  },
  deactivated() {
    clearInterval(this.appointmentInterval);
  },
  data() {
    return {
      appointmentInterval: null,
      resourceType: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      selectedDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      autocompleteValueLocal: null,
      viewType: null,
      viewTypes: [
        {
          id: 1,
          text: "Day",
        },
        {
          id: 2,
          text: "Week",
        },
        {
          id: 3,
          text: "Year",
        },
      ],
      worktype: null,
      worktypes: [],
      workpoint: null,
      workpoints: [],
      workpointstimings: [],
      workresources: [],
      storedworkresources: [],
      appointments: [],
      timeslots: [],
      search: null,
      minimumTimeTask: 1440,
      isLoadedCriticalThingsForDayView: false,
      isDebounceCancelled: false,
      monthlyappointments: [],
      pickerDate: null,
      searchData: [],
      menu: false,
      date: null,
      isLoading: false,
    };
  },
  methods: {
    editScheduler(item) {
      this.$store.commit("setAppointmentRedirectData", {
        id: item.id,
        resourceType: this.resourceType,
      });
      this.$router.push("/scheduler/edit").catch(() => {});
    },
    updatePagination(page) {
      this.page = page;
      this.searchAppointment(this.search);
    },
    updateItemsPerPage(itemsPerPage) {
      itemsPerPage == -1
        ? (this.itemsPerPage = this.totalItems)
        : (this.itemsPerPage = itemsPerPage);
      this.searchAppointment(this.search);
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
    },
    addClass(item) {
      let classToBeAdded = this.isBill(item) ? "bill" : "";
      if (
        item &&
        item.appointment_status &&
        item.appointment_status.key == "appointment_not_executed"
      ) {
        classToBeAdded += " not-executed";
      }
      return classToBeAdded;
    },
    isBill(item) {
      return item ? (item.order_type_id == 10241002 ? true : false) : false;
    },
    addTaskStyle(item) {
      let style = {
        "background-color": `#${item.color}`,
        color: "white",
        cursor: "pointer",
      };

      return style;
    },
    setPreviousDate() {
      let today = new Date(this.selectedDate);
      let previousDay = new Date(today);
      previousDay.setDate(today.getDate() - 1);
      this.selectedDate = this.getDatePartFromDateTimeStr(
        previousDay.toISOString()
      );
    },
    setNextDate() {
      let today = new Date(this.selectedDate);
      let nextDay = new Date(today);
      nextDay.setDate(today.getDate() + 1);
      this.selectedDate = this.getDatePartFromDateTimeStr(
        nextDay.toISOString()
      );
    },
    printReportFromTopButton() {
      axios
        .get(API_BASE_URL + "/report/1?date=" + this.selectedDate, {
          headers: this.header,
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "depot-report-" + this.selectedDate + ".csv"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        })
        .finally(() => {});
    },
    functionEvents(date) {
      const [, , day] = date.split("-");
      for (let [key, value] of Object.entries(this.monthlyappointments)) {
        if (value["day"] == parseInt(day, 10)) {
          console.log("key", key);
          return ["black"];
        }
      }
      return false;
      // let uniqueColorCodes = this.getUniqueColorCodesFromCurrentDayAppointments(this.monthlyappointments[0]);
    },
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
    }, 500),
    allowedDates: function (a) {
      return (val) => !a.includes(new Date(val).getDay());
    },
    resetDate() {
      this.selectedDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.pickerDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 8);
    },
    deleteItem() {},
    getAppointments() {
      return new Promise((resolve, reject) => {
        if (this.resourceType) {
          axios
            .get(
              API_BASE_URL +
                "/appointments?relation_data=true&date=" +
                this.selectedDate +
                "&resource_type_id=" +
                this.resourceType.id,
              { headers: this.header }
            )
            .then(({ data }) => {
              this.appointments = data.data;
              resolve();
            })
            .catch(function (error) {
              console.log("an error occured " + error);
              reject();
            })
            .finally(() => {});
        } else {
          resolve();
        }
      });
    },
    getMonthlyAppointments() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_BASE_URL +
              "/appointments/count?month=" +
              parseInt(this.pickerDate.split("-")[1], 10) +
              "&year=" +
              parseInt(this.pickerDate.split("-")[0], 10),
            { headers: this.header }
          )
          .then(({ data }) => {
            this.monthlyappointments = [];
            data.data.forEach((element) => {
              this.monthlyappointments.push(element);
            });
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {});
      });
    },
    getWorkTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/work_types", { headers: this.header })
          .then(({ data }) => {
            data.data.forEach((worktype) => {
              if (
                parseInt(worktype.work_duration) <
                parseInt(this.minimumTimeTask)
              ) {
                this.minimumTimeTask = parseInt(worktype.work_duration);
              }
            });
            this.worktypes = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {});
      });
    },
    getWorkResources() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/work_resources", { headers: this.header })
          .then(({ data }) => {
            // this.workresources = data.data;
            this.storedworkresources = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {});
      });
    },
    getWorkPoints() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/work_points", { headers: this.header })
          .then(({ data }) => {
            this.workpoints = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {});
      });
    },
    getWorkingDaysForThePoints() {
      if (this.workpoints.length > 0) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              API_BASE_URL +
                "/work_points/timings?work_point_id=" +
                this.workpoints[0].id,
              { headers: this.header }
            )
            .then(({ data }) => {
              this.workpointstimings = data.data;
              resolve();
            })
            .catch(function () {
              reject();
            })
            .finally(() => {});
        });
      }
    },
    checkNotAvailableDays() {
      let doweeks = [0, 0, 0, 0, 0, 0, 0];
      let notAvailableWeekDay = [];
      if (this.workpointstimings.length > 0) {
        this.workpointstimings.forEach((timing) => {
          doweeks[timing.dow]++;
        });

        doweeks.forEach((dow, index) => {
          if (dow == 0) {
            notAvailableWeekDay.push(index);
          }
        });
      }
      return notAvailableWeekDay;
    },
    getTimeSlotsForWorkPoints() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_BASE_URL + "/work_points/timings?date=" + this.selectedDate,
            { headers: this.header }
          )
          .then(({ data }) => {
            data.data.sort(function (a, b) {
              // sort by work point id in ascending order
              return a.work_point_id - b.work_point_id;
            });
            this.timeslots = data.data;
            resolve();
          })
          .catch(function (error) {
            console.log("an error occured " + error);
            reject();
          })
          .finally(() => {
            this.$nextTick(() => {
              let width = this.getDayViewWidth() / this.workresources.length;
              this.setTableThTdWidth(width);
            });
          });
      });
    },
    searchAppointment(searchVal) {
      const payload = {
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortOrd,
        search: searchVal ?? null,
      };
      axios
        .get(API_BASE_URL + "/appointments?relation_data=true&paginate=true", {
          headers: this.header,
          params: payload,
        })
        .then((data) => {
          this.searchData = data.data.data.data;
          this.totalItems = data.data.data.total;
        })
        .catch(function (error) {
          console.log("an error occured " + error);
        });
    },
    setWorkResources() {
      this.workresources = this.storedworkresources.filter((element) => {
        if (
          this.resourceType &&
          element.resource_type_id == this.resourceType.id
        ) {
          return true;
        }
        return false;
      });
    },
  },
  computed: {
    invertNavDrawerVisibility() {
      return this.$store.state.showNavDrawer;
    },
    searchHeaders() {
      return [
        { text: this.$t("scheduler.work"), value: "id", sortable: false },
        { text: this.$t("scheduler.datetime"), value: "id", sortable: false },
        {
          text: this.$t("scheduler.client_name"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("scheduler.registration_number"),
          value: "id",
          sortable: false,
        },
        { text: this.$t("scheduler.created_at"), value: "id", sortable: false },
        // { text: this.$t('scheduler.arrived_at'), value: 'id', sortable: false },
      ];
    },
  },
  watch: {
    invertNavDrawerVisibility() {
      this.$nextTick(() => {
        let width = this.getDayViewWidth() / this.workresources.length;
        this.setTableThTdWidth(width);
      });
    },
    resourceTypes() {
      this.resourceType = this.resourceTypes[0];
    },
    resourceType(val) {
      if (val) {
        this.appointments = [];
        this.setWorkResources();
        this.getAppointments();

        this.$nextTick(() => {
          let width = this.getDayViewWidth() / this.workresources.length;
          this.setTableThTdWidth(width);
        });
        this.$store.commit("setAppointmentRedirectData", {
          resourceType: this.resourceType,
        });
      }
    },
    storedworkresources(val) {
      if (this.resourceType) {
        this.setWorkResources();
      }
      if (val && val.length > 0) {
        let tempresourceTypes = [];
        val.forEach((element) => {
          if (element.resource_type_id && element.resource_type) {
            tempresourceTypes.push(element.resource_type);
          }
        });
        this.$store.commit(
          "setResourceTypes",
          this.translateKeys(tempresourceTypes, "key", "resource_types")
        );
      }
    },
    search: _.debounce(function () {
      if (this.search) {
        this.searchAppointment(this.search);
      } else {
        this.searchData = [];
      }
    }, 500),
    selectedDate() {
      this.appointments = [];
      this.getAppointments();
      this.getTimeSlotsForWorkPoints();
    },
    pickerDate() {
      this.getMonthlyAppointments();
    },
  },
};
</script>

<style scoped>
.articlenotset {
  display: flex !important;
}
.articleset {
  display: none !important;
}
.selectedArticleTextBox {
  background-color: rgb(220, 255, 220);
}
.calendar-heading {
  font-size: 20px;
}
.inactive {
  display: none;
}
.active {
  display: block;
}
#datepicker {
  padding-bottom: 12px !important;
}
</style>
