var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-combobox',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.members,"item-value":"id","item-text":"name","label":_vm.$t(_vm.label),"append-icon":"mdi-close","search-input":_vm.search_member,"no-filter":"","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"error-messages":_vm.errorMessages},on:{"keydown":[function($event){return _vm.handleInput($event, 'alphanumeric', 50)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}],"update:searchInput":function($event){_vm.search_member=$event},"update:search-input":function($event){_vm.search_member=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',{on:{"click":function($event){_vm.member = item}}},[_c('p',{staticClass:"member_name"},[_vm._v(" "+_vm._s((item.name ? item.name : "").toUpperCase())+" "+_vm._s((item.first_name ? item.first_name : "").toUpperCase())+" ")]),_c('p',{staticClass:"member_detail"},[(item.type)?[_vm._v(" "+_vm._s(_vm.$t("member_types." + item.type.key))+" ")]:_vm._e(),(item.department)?[_vm._v(" - "+_vm._s((item.department ? item.department : "").toUpperCase())+" ")]:_vm._e(),(item.email)?[_vm._v(" - "+_vm._s((item.email ? item.email : "").toUpperCase())+" ")]:_vm._e(),(item.phone)?[_vm._v(" - "+_vm._s((item.phone ? item.phone : "").toUpperCase())+" ")]:_vm._e(),(
              item.company_address &&
              item.company_address.length > 0 &&
              item.company_address[0].address
            )?[_vm._v(" - "+_vm._s(_vm.computeAddressLabel(item.company_address[0].address))+" ")]:_vm._e()],2)])]}},{key:"selection",fn:function({ item }){return [_c('v-list-item-content',[(item && typeof item == 'object')?[_c('p',{staticClass:"member_name"},[_c('strong',[_vm._v(_vm._s((item.name ? item.name : "").toUpperCase())+" "+_vm._s((item.first_name ? item.first_name : "").toUpperCase()))])]),_c('p',{staticClass:"member_detail"},[(item.type_id)?[(item.type && typeof item.type == 'object')?[_vm._v(" "+_vm._s(_vm.$t("member_types." + item.type.key))+" ")]:[_vm._v(" "+_vm._s(_vm.getMemberTypeByForNaming(item.type_id))+" ")]]:(!item.type_id && item.type)?[(item.type && typeof item.type == 'object')?[_vm._v(" "+_vm._s(_vm.$t("member_types." + item.type.key))+" ")]:_vm._e()]:_vm._e(),(item.department)?[_vm._v(" - "+_vm._s((item.department ? item.department : "").toUpperCase())+" ")]:_vm._e(),(item.email)?[_vm._v(" - "+_vm._s((item.email ? item.email : "").toUpperCase())+" ")]:_vm._e(),(item.phone)?[_vm._v(" - "+_vm._s(item.phone)+" ")]:_vm._e(),(
                item.company_address &&
                item.company_address.length > 0 &&
                item.company_address[0].address
              )?[_vm._v(" - "+_vm._s(_vm.computeAddressLabel(item.company_address[0].address))+" ")]:_vm._e()],2)]:_vm._e(),(item && typeof item == 'string')?[_vm._v(" "+_vm._s(item.toUpperCase())+" ")]:_vm._e()],2)]}}]),model:{value:(_vm.member),callback:function ($$v) {_vm.member=$$v},expression:"member"}},[_c('template',{slot:"append"},[_c('v-icon',{on:{"click":function($event){return _vm.editMember()}}},[_vm._v("mdi-pencil")]),(_vm.member)?_c('v-icon',{on:{"click":function($event){return _vm.removeMember()}}},[_vm._v("mdi-close")]):_vm._e()],1)],2),(_vm.openEditClientMemberModel)?_c('edit-client-model',{attrs:{"defaultClient":_vm.value,"addMemberToCompany":_vm.client,"showDialog":_vm.openEditClientMemberModel,"addMember":true,"saveToDB":false,"expeditor_tva":_vm.expeditor_tva},on:{"buttonClicked":_vm.updatedMember,"update:showDialog":function($event){_vm.openEditClientMemberModel=$event},"update:show-dialog":function($event){_vm.openEditClientMemberModel=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }