<template>
  <div>
    <!-- General Section Starts Here -->
    <v-row>
      <v-col cols="4">
        <v-text-field
          :label="translate('name')"
          class="to-upper"
          v-model="supplierConnection.name"
        >
          <!--        APPEND ICON SLOT  -->
          <template #prepend>
            <span>
              <v-btn @click="openHistoryTableDialog" icon>
                <v-icon class="mb-2" style="color: royalblue"
                  >mdi-clipboard-text-clock</v-icon
                >
              </v-btn>
            </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          :label="translate('supplier')"
          :items="suppliers"
          item-text="label"
          item-value="id"
          v-model="supplierConnection.supplier_id"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :label="translate('family')"
          :items="companyFamilyArticles"
          item-text="name"
          item-value="id"
          v-model="supplierConnection.family_id"
        >
        </v-select>
      </v-col>
      <v-col cols="5">
        <v-select
          :label="translate('stock_updated_by')"
          :items="connectionTypes"
          item-text="label"
          item-value="id"
          v-model="supplierConnection.type_id"
        ></v-select>
      </v-col>
      <v-col cols="5">
        <v-select
          :label="translate('currency')"
          :items="companyCurrencies"
          item-text="key"
          item-value="id"
          v-model="supplierConnection.currency_id"
        ></v-select>
      </v-col>
      <v-col class="mt-2" cols="2">
        <v-checkbox
          :label="translate('enabled')"
          v-model="supplierConnection.is_enabled"
        ></v-checkbox>
      </v-col>
    </v-row>
    <!-- General Section Ends Here -->

    <!--  Tab Section Starts Here  -->
    <v-card>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="item in connectionTypes" :key="item.id">
          {{ item.label }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in connectionTypes" :key="item.id">
          <v-card flat>
            <v-card-text v-if="isSame('ftp', item.value)">
              <!-- FTP Section Starts Here -->
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('url')"
                    v-model="supplierConnection.ftp_url"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('location')"
                    v-model="supplierConnection.ftp_location"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('port')"
                    v-model="supplierConnection.ftp_port"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    @keydown="handleInput($event, 'int', 2)"
                    :label="translate('timeout')"
                    v-model="supplierConnection.ftp_timeout"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('username')"
                    v-model="supplierConnection.ftp_user"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('password')"
                    v-model="supplierConnection.ftp_password"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :label="translate('file_name')"
                    v-model="supplierConnection.ftp_file_name"
                  />
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    :label="translate('passive_mode')"
                    v-model="supplierConnection.ftp_passive_mode"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <CronForm v-model="crons.ftp" :key="cronFormKey" />
              <v-row> </v-row>
              <!-- FTP Section Ends Here -->
            </v-card-text>

            <v-card-text v-if="isSame('edi', item.value)">
              <!-- EDI Section Starts Here -->
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('url')"
                    v-model="supplierConnection.edi_url"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('version')"
                    v-model="supplierConnection.edi_version"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('username')"
                    v-model="supplierConnection.edi_user"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('password')"
                    v-model="supplierConnection.edi_password"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('customer_id')"
                    v-model="supplierConnection.edi_customer_id"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('ship_to_customer')"
                    v-model="supplierConnection.edi_ship_to_customer_id"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('agency_code')"
                    v-model="supplierConnection.edi_agency_code"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    @keydown="handleInput($event, 'int', 2)"
                    :label="translate('timeout')"
                    v-model="supplierConnection.edi_timeout"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('max_article_to_request')"
                    v-model="supplierConnection.max_article_to_request"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('max_quantity_to_request')"
                    v-model="supplierConnection.max_quantity_to_request"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :label="translate('delivery_code')"
                    v-model="supplierConnection.delivery_code"
                  />
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    :label="translate('allow_order')"
                    v-model="supplierConnection.edi_allow_order"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <SupplierConnectionTypeHistory
                    :type="selectedTabType"
                    :supplierId="supplierConnection.supplier_id"
                  />
                </v-col>
              </v-row>
              <!-- EDI Section Ends Here -->
            </v-card-text>

            <v-card-text v-if="isSame('get', item.value)">
              <!-- Get Section Starts Here -->
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('url')"
                    v-model="supplierConnection.get_url"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('version')"
                    v-model="supplierConnection.get_version"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('username')"
                    v-model="supplierConnection.get_user"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('password')"
                    v-model="supplierConnection.get_password"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('customer_id')"
                    v-model="supplierConnection.get_customer_id"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('ship_to_customer')"
                    v-model="supplierConnection.get_ship_to_customer_id"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('agency_code')"
                    v-model="supplierConnection.get_agency_code"
                  />
                </v-col>
                <v-col cols="3" class="d-flex">
                  <v-text-field
                    @keydown="handleInput($event, 'int', 2)"
                    :label="translate('timeout')"
                    v-model="supplierConnection.get_timeout"
                  />
                  <v-checkbox
                    style="min-width: 150px"
                    class="ml-2"
                    :label="translate('allow_order')"
                    v-model="supplierConnection.get_allow_order"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <!-- Get Section Starts Here -->
            </v-card-text>

            <v-card-text v-if="isSame('email', item.value)">
              <!-- Email Section Starts Here -->
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('type')"
                    v-model="supplierConnection.email_type"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('server')"
                    v-model="supplierConnection.email_server"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('username')"
                    v-model="supplierConnection.email_user"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('password')"
                    v-model="supplierConnection.email_password"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('encryption_type')"
                    v-model="supplierConnection.email_encryption_type"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('from_address')"
                    v-model="supplierConnection.email_from_address"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('file')"
                    v-model="supplierConnection.email_file"
                  />
                </v-col>
                <v-col cols="3" class="d-flex">
                  <v-text-field
                    :label="translate('port')"
                    v-model="supplierConnection.email_port"
                  />
                  <v-text-field
                    :label="translate('encoding')"
                    v-model="supplierConnection.email_encoding"
                    class="ml-4"
                  />
                </v-col>
              </v-row>
              <!-- Email Section Ends Here -->
            </v-card-text>

            <v-card-text v-if="isSame('pricat', item.value)">
              <!-- Pricat Section Starts Here -->
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('version')"
                    v-model="supplierConnection.pricat_version"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('url')"
                    v-model="supplierConnection.pricat_ftp_url"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('location')"
                    v-model="supplierConnection.pricat_ftp_location"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('port')"
                    v-model="supplierConnection.pricat_ftp_port"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('file_name')"
                    v-model="supplierConnection.pricat_ftp_file_name"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('username')"
                    v-model="supplierConnection.pricat_ftp_user"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :label="translate('password')"
                    v-model="supplierConnection.pricat_ftp_password"
                  />
                </v-col>
                <v-col cols="1">
                  <v-text-field
                    :label="translate('timeout')"
                    v-model="supplierConnection.pricat_ftp_timeout"
                  />
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    :label="translate('passive_mode')"
                    v-model="supplierConnection.pricat_ftp_passive_mode"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <CronForm v-model="crons.pricat" :key="cronFormKey" />
              <v-row> </v-row>
              <!-- Precut Section Ends Here -->
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card class="mt-4">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <SupplierConnectionTypeHistory
              type="others"
              :supplierId="supplierConnection.supplier_id"
              :heading="translate('stock_import_history')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!--    Tab Section Ends Here    -->
    <!--  Invisible Buttons Section  -->
    <span id="save" @click="updateHandler"></span>
    <HistoryTable
      v-model="historyTableDialog"
      :model_id="historyTableModelId"
      :model="historyTableModel"
      v-if="connection"
    />
  </div>
</template>
<script>
import _axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import HistoryTable from "@/components/UserActionHistory/HistoryTable";
import historyTableMixin from "@/mixins/historyTableMixin";
import CronForm from "@/components/CompanySupplierConnection/CronForm.vue";
import SupplierConnectionTypeHistory from "@/components/CompanySupplierConnection/SupplierConnectionTypeHistory.vue";

export default {
  name: "SupplierConnectionForm",
  components: {
    SupplierConnectionTypeHistory,
    CronForm,
    HistoryTable,
  },
  mixins: [historyTableMixin],
  props: {
    connection: {
      default: null,
    },
  },
  data() {
    return {
      supplierConnection: {},
      crons: {
        ftp: {},
        pricat: {},
      },
      cronFormKey: 1,
      suppliers: [],
      supplierTypes: [],
      companyFamilyArticles: [],
      connectionTypes: [],
      companyCurrencies: [],
      tab: null,
      cronIdFields: ["cron_id", "pricat_cron_id"],
      cronIdFieldsMap: {
        cron_id: "ftp",
        pricat_cron_id: "pricat",
      },
    };
  },
  computed: {
    selectedTabType() {
      const inversedTabsMap = {
        0: "ftp",
        1: "email",
        2: "edi",
        3: "get",
        4: "pricat",
      };

      return inversedTabsMap[this.tab];
    },
  },
  async mounted() {
    this.supplierTypes = (await this.getCodes("supplier_types", false)).map(
      (i) => {
        i.label = this.$t("supplier_types." + i.key);
        return i;
      }
    );
    await this.getCompanySuppliers();
    await this.getFamilyArticles();
    await this.getConnectionTypes();
    await this.getCurrencyList();
  },
  watch: {
    connection: {
      handler: function (val) {
        if (!val) return;

        this.supplierConnection = this.clone(val);
        this.setHistoryModalData("CompanySupplierConnection", val.id);
        this.processCron(val);
      },
      deep: true,
    },
  },
  methods: {
    async getCompanySuppliers() {
      await this.getListOfItems("suppliers");
      this.suppliers = this.suppliers
        .filter((s) => s.id !== 0)
        .map((s) => {
          const type = this.supplierTypes.find((t) => t.id === s.type_id);

          if (!this.isset(s) || !this.isset(type)) return s;
          s.label = `${s.name} (${type.label})`;

          return s;
        });
    },
    async getFamilyArticles() {
      await this.getListOfItems("family_articles", "companyFamilyArticles");
      this.companyFamilyArticles = this.companyFamilyArticles.filter(
        (s) => s.is_stock_managed === 1
      );
    },
    getListOfItems(uri, keyResponse = null) {
      return new Promise((resolve, reject) => {
        _axios
          .get(API_BASE_URL + "/" + uri)
          .then((response) => {
            this[keyResponse ?? uri] = response.data?.data || response.data;
            resolve(response.data?.data || response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    isSame(needle, hayStack) {
      return needle === hayStack;
    },
    async getConnectionTypes() {
      let codes = await this.getCodes("connection_types", false);
      codes = codes.map((code) => {
        code.label = this.translate("types." + code.key);
        return code;
      });

      this.connectionTypes = codes;
    },
    getCurrencyList() {
      return this.getListOfItems("currencies", "companyCurrencies");
    },
    translate(translationKey, args = []) {
      return this.$t("supplier_connections_section." + translationKey, args);
    },
    updateHandler() {
      //  check if crons are well filled
      for (const cron in this.crons) {
        const targetColumns = ["min", "hour", "day", "month", "dow"];
        // all columns must be filled or none should be filled
        if (
          targetColumns.some((col) => !this.crons[cron][col]) &&
          targetColumns.some((col) => this.crons[cron][col])
        ) {
          this.$toast.error(this.translate("cron_error"));
          return;
        }
      }
      return new Promise((resolve) => {
        const lastPartOfUrl = this.connection
          ? "/" + this.supplierConnection.id
          : "";
        _axios[this.connection ? "put" : "post"](
          API_BASE_URL + "/company_supplier_connections" + lastPartOfUrl,
          {
            ...this.supplierConnection,
            crons: this.crons,
          }
        )
          .then((response) => {
            const newConnection = response.data.data;
            if (this.connection) {
              this.$emit("updateConnection", newConnection);
              this.processCron(newConnection);
              this.$toast.success(this.translate("update_success"));
              resolve(newConnection);
            } else {
              this.$store.state.newConnectionId = newConnection.id;
              this.$toast.success(this.translate("add_success"));
              this.$router.push({ name: "CompanySupplierConnections" });
            }
          })
          .catch((error) => {
            // check if error is 422
            if (error.response.status === 422) {
              this.$toast.error(
                this.filterFirstError(error.response.data.errors)
              );
            }
          });
      });
    },
    async processCron(connection) {
      this.crons = {
        ftp: {},
        pricat: {},
      };

      if (!connection) return false;
      // concatenate cron id fields with a comma, skip if  null
      let ids = "";
      this.cronIdFields.forEach((field) => {
        if (connection[field]) {
          ids += connection[field] + "_";
        }
      });

      // remove the last comma
      if (ids === "") return false;

      ids = ids.slice(0, -1);
      let crons = await this.getCronData(ids);

      this.cronIdFields.forEach((field) => {
        if (connection[field]) {
          this.crons[this.cronIdFieldsMap[field]] = crons.find(
            (cron) => cron.id === connection[field]
          );
        }
      });

      this.cronFormKey++;
    },
    getCronData(ids) {
      return new Promise((resolve) => {
        _axios
          .get(API_BASE_URL + "/crons?ids=" + ids)
          .then((response) => resolve(response.data.data));
      });
    },
    // getSupplierConnection(id){
    //   _axios.get(API_BASE_URL + '/company_supplier_connections/' + id)
    //       .then(({data}) => {
    //         this.supplierConnection = data.data.data
    //       })
    // }
  },
};
</script>
