<template>
  <div style="position: absolute; z-index: 9999; right: 10px">
    <div
      class="notification"
      v-for="(notification, index) in notifications"
      :key="index"
    >
      <v-slide-y-transition>
        <v-card
          v-if="notification.isVisible"
          class="mx-auto"
          max-width="344"
          style="margin: 20px"
          :color="determineColor(notification.type)"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                <v-icon v-if="notification.type == 'error'" style="color: red">
                  mdi-close-circle
                </v-icon>
                <v-icon
                  v-if="notification.type == 'success'"
                  style="color: green"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-if="notification.type == 'information'"
                  style="color: #d5d51d"
                >
                  mdi-alert-box
                </v-icon>
                {{ notification.title }}
              </v-list-item-title>
              <v-list-item-content>
                <p><span v-html="notification.message"></span></p>
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn color="red" rounded text @click="closeNotification(index)">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-slide-y-transition>
    </div>
  </div>
</template>
<style scoped></style>
<script>
export default {
  name: "Notification",

  //putting the notification data in the parent Vue instance
  //this allows other components to access and see them
  //that means we can also push new notifications to it,
  //and our notifications component will pick them up

  props: ["notificationData"],

  methods: {
    determineColor(type) {
      if (type == "error") {
        return "rgba(255, 232, 218, 0.95)";
      }
      if (type == "success") {
        return "rgba(213, 255, 207, 1)";
      }
      if (type == "information") {
        return "#fffef2";
      }
      if (type == "normal") {
        return "white";
      } else {
        return "white";
      }
    },

    closeNotification(sentNotificationIndex) {
      this.notifications[sentNotificationIndex].isVisible = false;
      setTimeout(() => {
        this.notifications = this.notifications.splice(
          sentNotificationIndex,
          1
        );
      }, 1000);
    },
  },
  data: function () {
    return {
      notifications: [],
      key: 0,
    };
  },
  watch: {
    notificationData: function (val) {
      this.notifications = val;
    },
  },
};
</script>
