<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      fixed-header
      class="elevation-1"
      @click:row="rowClickHandler"
    >
    </v-data-table>
    <bulk-operation-form
      v-model="returnRequestModalDialog"
      @updated="createHandler"
    />
    <depot-bulk-operation-detail
      v-model="depotBulkOperationDetailDialog"
      :depot-bulk-operation="depotBulkOperation"
      @updated="updateHandler"
    />
    <span id="add-new" @click="openReturnRequestModal()" class="d-none"></span>
  </div>
</template>
<script>
import { API_BASE_URL } from "@/config";
import axios from "axios";
import DepotBulkOperationDetail from "@/components/TyreHotel/DepotBulkOperationDetail.vue";
import BulkOperationForm from "@/components/Depot/BulkOperationForm.vue";
export default {
  name: "TyreHotelBulk",
  components: {
    BulkOperationForm,
    DepotBulkOperationDetail,
  },
  data() {
    return {
      returnRequestModalDialog: false,
      bulkDepotOperations: [],
      depotBulkOperationDetailDialog: false,
      depotBulkOperation: null,
      axiosOptions: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      depotStatuses: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("srno"), value: "serial", width: 50 },
        { text: this.$t("articles.status"), value: "status_label" },
        { text: this.translate("depot_manager"), value: "depot_manager" },
        { text: this.translate("date"), value: "date" },
        { text: this.translate("requested"), value: "requested_units" },
        { text: this.translate("completed"), value: "completed_units" },
        { text: this.translate("errors"), value: "errors_units" },
        {
          ext: "",
          value: "action",
          align: "left",
          sortable: false,
          width: 150,
        },
      ].map((header) => ({ ...header, align: "start", sortable: false }));
    },
    items() {
      return this.bulkDepotOperations.map((operation, index) => {
        let status = this.depotStatuses.find(
          (status) => status.id === operation.status_id
        );
        return {
          serial: index + 1,
          depot_manager: operation.depot_manager,
          date: this.formatDateWithLocale(
            operation?.requested_for || operation.depot_preparation,
            true
          ),
          requested_units:
            operation.requested.length + " " + this.translate("entries"),
          completed_units:
            operation.completed.length + " " + this.translate("entries"),
          errors_units:
            operation.errors.length + " " + this.translate("entries"),
          status_label: status.label,
          ...operation,
        };
      });
    },
  },
  async mounted() {
    localStorage.removeItem("bulk_operation_id");
    this.depotStatuses = (await this.getCodes("depot_statuses", null)).map(
      (status) => {
        status.label = this.$t("depot_hints." + status.key);
        return status;
      }
    );
    this.bulkDepotOperations = await this.getDepotBulkOperations();
  },
  methods: {
    openReturnRequestModal() {
      this.returnRequestModalDialog = false;
      setTimeout(() => (this.returnRequestModalDialog = true), 10);
    },
    translate(key) {
      return this.$t("tyre_hotel.bulk." + key);
    },
    getDepotBulkOperations() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_BASE_URL}/depots/bulk_operations`, {
            headers: this.axiosOptions,
          })
          .then((response) => {
            this.bulkDepotOperations = response.data.data.data;
            resolve(response.data.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createHandler() {
      this.getDepotBulkOperations();
    },
    rowClickHandler(item) {
      localStorage.setItem("bulk_operation_id", item.id);
      this.$router.push({ name: "TyreHotelBulkOperationDetails" });
    },
    printHandler() {
      // to be implemented
    },
    async updateHandler(data) {
      this.depotBulkOperation = null;
      this.depotBulkOperationDetailDialog = false;
      let index = this.bulkDepotOperations.findIndex(
        (operation) => operation.id === data.id
      );
      if (index !== -1) {
        this.bulkDepotOperations[index] = data;
      }
      this.bulkDepotOperations = await this.getDepotBulkOperations();
    },
  },
};
</script>
