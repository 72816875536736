<template>
  <v-row>
    <v-col>
      <v-row>
        <v-spacer></v-spacer>
        <v-col class="pa-0 pt-4 ma-0" cols="12" sm="3">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            class="mr-3 to-upper"
            :label="$t('schedulestab.search')"
            @input="debounceInput"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          fixed-header
          :headers="headers"
          :options="options"
          :server-items-length="totalItems"
          :loading="isLoading"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          style="width: 100%"
          :height="'calc(-600px + 100vh)'"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          :items="schedules"
          must-sort
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.title }}
              </td>
              <td>
                <span v-if="item.vehicle">
                  {{ item.vehicle.registration_number }}
                </span>
              </td>
              <td>
                {{ item.start_time }}
              </td>
              <td>
                {{ item.end_time }}
              </td>
              <td>
                <span v-if="item.appointment_status">
                  {{
                    $t(
                      "schedulestab.appointment_status." +
                        item.appointment_status.key
                    )
                  }}
                </span>
              </td>
              <td>
                {{ item.note }}
              </td>
              <td>
                <v-btn
                  class="ma-2 text-white-color"
                  color="primary"
                  small
                  @click="editAppointment(item)"
                >
                  {{ $t("btn_view") }}
                  <v-icon dark right> mdi-eye </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
export default {
  props: {
    client: [Object],
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      schedules: [],
      alerts: [],
      search: "",
      isLoading: true,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      options: {},
      sortBy: "title",
      sortDesc: false,
      sortOrd: "ASC",
      sort: {},
      showAlert: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      isDebounceCancelled: false,
    };
  },

  created() {
    (this.header = { Authorization: "Bearer " + this.token }), this.fetchData();
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  methods: {
    debounceInput: _.debounce(function () {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.fetchData();
    }, 500),
    fetchData() {
      this.isLoading = true;
      const payload = {
        client_id: this.client.id,
        order_by: this.sortBy ? this.sortBy : "title",
        order_direction: this.sortOrd ? this.sortOrd : "ASC",
      };
      axios({
        method: "get",
        url: API_BASE_URL + "/appointments",
        headers: this.header,
        params: payload,
      })
        .then((response) => {
          this.schedules = response.data.data;
          this.totalItems = response.data.data.length;
          this.isLoading = false;
        })
        .catch(() => {
          this.$toast.success(
            this.$t("schedulestab.something_went_wrong_while_fetching_data")
          );
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      itemsPerPage == -1
        ? (this.itemsPerPage = this.totalItems)
        : (this.itemsPerPage = itemsPerPage);
      this.fetchData();
    },
    editAppointment(item) {
      this.$store.commit("setAppointmentRedirectData", {
        id: item.id,
      });
      this.$router.push("/scheduler/edit").catch(() => {});
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$t("schedulestab.title"), value: "title", sortable: true },
        {
          text: this.$t("schedulestab.vehicle"),
          value: "vehicle_id",
          sortable: true,
        },
        { text: this.$t("schedulestab.start_time"), value: "start_time" },
        { text: this.$t("schedulestab.end_time"), value: "end_time" },
        {
          text: this.$t("schedulestab.status"),
          value: "appointment_status_id",
        },
        { text: this.$t("schedulestab.note"), value: "is_leasing" },
        { text: this.$t("schedulestab.actions"), value: "id" },
      ];
    },
  },
};
</script>
<style scoped></style>
