<template>
  <v-card flat>
    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="name"
            :label="$t('users.name')"
            :error-messages="
              $store.state.validation && !$v.name.required
                ? [this.$t('users.text_messages.name_required')]
                : []
            "
            :outlined="is_boxes"
            :dense="is_boxes"
            class="to-upper"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="to-upper"
            :disabled="!!this.$store.state.editId"
            :autocomplete="autocompleteValueLocal"
            v-model="email"
            :label="$t('users.email')"
            :error-messages="
              $store.state.validation
                ? returnErrorMessage(
                    [
                      !$v.email.required,
                      this.$t('users.text_messages.email_required'),
                    ],
                    [
                      !$v.email.email,
                      this.$t('users.text_messages.invalid_email'),
                    ]
                  )
                : []
            "
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="password"
            :label="$t('users.password')"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :error-messages="
              $store.state.validation
                ? returnErrorMessage(
                    [
                      !$v.password.required,
                      this.$t('users.text_messages.password_required'),
                    ],
                    [
                      !$v.password.minLength,
                      this.$t('users.text_messages.password_min_length'),
                    ]
                  )
                : []
            "
            @click:append="showPassword = !showPassword"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" sm="6">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="confirmPassword"
            :label="$t('users.confirmpassword')"
            :error-messages="$store.state.validation ? returnErrorMessage(
              [!$v.confirmPassword.required ,this.$t('users.text_messages.confirm_password_required')],
              [!$v.confirmPassword.sameAs ,this.$t('users.text_messages.confirm_password_not_same')]
            ) : [] "
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            :autocomplete="autocompleteValueLocal"
            v-model="role"
            :items="roles"
            item-text="text"
            item-value="id"
            :label="$t('users.role')"
            :error-messages="
              $store.state.validation && !$v.role.required
                ? [this.$t('users.text_messages.role_required')]
                : []
            "
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :autocomplete="autocompleteValueLocal"
            v-model="language"
            :items="languages"
            item-value="id"
            item-text="text"
            :label="$t('users.language')"
            :error-messages="
              $store.state.validation && !$v.language.required
                ? [this.$t('users.text_messages.language_required')]
                : []
            "
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-checkbox
            :autocomplete="autocompleteValueLocal"
            v-model="active"
            color="success"
            :label="$t('users.active')"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6">
          <v-checkbox
            :autocomplete="autocompleteValueLocal"
            v-model="approve"
            color="success"
            :label="$t('users.approve')"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>

    <v-btn @click="onSubmit()" class="d-none" id="submit-user"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-user"></v-btn>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  minLength,
  email,
} from "vuelidate/lib/validators";
import { API_BASE_URL } from "@/config";
import { ADD_MODE } from "@/config";
import { DETAILS_MODE } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  mixins: [validationMixin],
  components: {
    "confirmation-model": ConfirmationModel,
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email: (val) => email((val ?? "").toLowerCase()),
    },
    password: {
      required: requiredIf(function () {
        return !this.$store.state.editId;
      }),
      minLength: minLength(8),
    },
    // confirmPassword:{
    //   required: requiredIf(function (model) {
    //     return !this.$store.state.editId;
    //   }),
    //   sameAs: sameAs('password')
    // },
    role: {
      required,
    },
    language: {
      required,
    },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      roles: [],
      role: {},
      languages: [],
      language: {},
      name: null,
      email: null,
      showPassword: false,
      showConfirmPassword: false,
      password: null,
      confirmPassword: null,
      active: true,
      approve: false,
      DeleteConfirm: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    if (this.$store.state.editId) {
      this.$store.commit("setFormMode", DETAILS_MODE);
      this.fetchData();
    } else {
      this.$store.commit("setFormMode", ADD_MODE);
    }
    this.getRolesList();
    this.getLanguages();
  },

  methods: {
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "users.delete_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("users.cancel");
      this.confirmationDialogTrueText = this.$t("users.delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
    onDelete() {
      this.deletePopUp();
    },
    deleteUser() {
      axios
        .delete(API_BASE_URL + "/users/" + this.$store.state.editId, {
          headers: this.header,
        })
        .then(() => {
          this.$toast.success(
            this.$t("users.text_messages.user_deleted_successfully")
          );
          this.$router.push("/users").catch(() => {});
        })
        .catch(() => {})
        .finally(() => {});
    },
    getLanguages() {
      axios
        .get(API_BASE_URL + "/languages", { headers: this.header })
        .then(({ data }) => {
          this.languages = this.translateKeys(data.data, "key", "languages");
        })
        .catch(function () {})
        .finally(() => {});
    },
    getRolesList() {
      axios
        .get(API_BASE_URL + "/user_roles", { headers: this.header })
        .then((resRoles) => {
          this.roles = this.translateKeys(
            resRoles.data.data.result,
            "name",
            "users.roles"
          );
        })
        .catch(() => {})
        .finally(() => {});
    },
    createUser(data) {
      axios
        .post(API_BASE_URL + "/users", data, { headers: this.header })
        .then(() => {
          this.$toast.success(
            this.$t("users.text_messages.user_created_successfully")
          );
          this.$router.push("/users").catch(() => {});
        })
        .catch((error) => {
          this.$toast.error(
            this.$t("users.text_messages." + error.response.data.errors[0])
          );
        })
        .finally(() => {});
    },
    fetchData() {
      axios
        .get(API_BASE_URL + "/users/" + this.$store.state.editId, {
          headers: this.header,
        })
        .then((res) => {
          this.name = res.data.data.name;
          this.email = res.data.data.email;
          this.role = res.data.data.role_id;
          this.language = res.data.data.language_id;
          this.active = res.data.data.is_active;
          this.approve = res.data.data.is_approved;
        })
        .catch(() => {})
        .finally(() => {});
    },
    editUser(data) {
      axios
        .put(API_BASE_URL + "/users/" + this.$store.state.editId, data, {
          headers: this.header,
        })
        .then(() => {
          this.$toast.success(
            this.$t("users.text_messages.user_updated_successfully")
          );
          this.$router.push("/users").catch(() => {});
        })
        .catch((error) => {
          this.$toast.error(
            this.$t("clients.text_messages." + error.response.data.errors[0])
          );
        })
        .finally(() => {});
    },
    makeSubmitData() {
      let payload = {
        name: this.name,
        email: this.email,
        password: this.password,
        // confirm_password: this.confirmPassword,
        role_id: this.role,
        language_id: this.language,
        is_active: this.active ? 1 : 0,
        is_approved: this.approve ? 1 : 0,
      };

      if (!this.password) {
        delete payload.password;
        // delete payload.confirm_password;
      }
      return payload;
    },
    onSubmit() {
      this.$store.commit("setValidation", true);
      if (this.$v.$invalid) {
        this.$toast.warning(this.$t("please_fill_form_correctly"));
      } else {
        if (this.$store.state.editId) {
          this.editUser(this.makeSubmitData()); // remove password property if random password is equal to password
        } else {
          this.createUser(this.makeSubmitData());
        }
      }
    },
  },
  computed: {},
  watch: {
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "delete" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.deleteUser();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
};
</script>
<style scoped></style>
