<template>
  <div>
    <!-- Order Number and Interventions and other details -->
    <v-row>
      <!-- Order Number -->
      <v-col cols="12" sm="2">
        <v-text-field
          disabled
          :autocomplete="autocompleteValueLocal"
          v-model="expedition_identifier"
          class="to-upper"
          :label="$t('expeditions.expedition_number')"
          v-on:keydown.enter.prevent=""
          v-on:keydown="handleInput($event, 'alphanumeric', 80)"
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col class="" cols="12" sm="2">
        <v-select
          :label="$t('expeditions.operator')"
          :items="companyUsers"
          item-text="name"
          item-value="id"
          return-object="false"
          v-model="seconduser"
          clearable
        >
        </v-select>
      </v-col>
      <!-- Search date -->
      <v-col cols="12" sm="2">
        <v-menu
          v-model="menu_creation_date"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="created_date"
              :label="$t('expeditions.creation_date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:append="created_date = null"
              class="mr-2 to-upper"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            :first-day-of-week="1"
            :weekday-format="getDay"
            v-model="created_date"
            @input="menu_creation_date = false"
            no-title
            scrollable
            :locale="userLanguageId"
            hide-no-data
            hide-details
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- References -->
      <v-col cols="12" sm="2">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="reference"
          @change="
            checkReferenceInExpeditions(
              reference,
              isEditExpeditionPage ? $store.state.selectedExpedition.id : null,
              null
            )
          "
          class="to-upper"
          :label="$t('expeditions.reference')"
          v-on:keydown.enter.prevent=""
          v-on:keydown="handleInput($event, 'alphanumeric', 150)"
          :outlined="is_boxes"
          :dense="is_boxes"
          :error-messages="
            $store.state.validation
              ? returnErrorMessage(
                  [
                    !$v.reference.required,
                    this.$t('expeditions.text_messages.required'),
                  ],
                  [
                    !$v.reference.maxLength,
                    this.$t(
                      'expeditions.text_messages.max_150_character_allowed'
                    ),
                  ]
                )
              : []
          "
        >
          <template #label>
            <span class="red--text"><strong>* </strong></span>
            {{ $t("expeditions.reference") }}
          </template>
        </v-text-field>
      </v-col>
      <!-- Expeditions Status -->
      <v-col cols="12" sm="2">
        <v-select
          :disabled="
            expeditionStatus && typeof expeditionStatus == 'object'
              ? expeditionStatus.key == 'canceled'
              : false
          "
          :autocomplete="autocompleteValueLocal"
          v-model="expeditionStatus"
          :items="expeditionStatuses"
          item-value="id"
          class="to-upper"
          item-text="text"
          clearable
          :label="$t('expeditions.status')"
          :outlined="is_boxes"
          :dense="is_boxes"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true,
          }"
          :background-color="setExpeditionRowColorClass(expeditionStatus)"
        >
          <template slot="item" slot-scope="{ item }">
            <v-list-item
              @click="
                expeditionStatus = item;
                expeditionStatus &&
                typeof expeditionStatus == 'object' &&
                expeditionStatus.key == 'canceled'
                  ? cancelExpeditionDialog()
                  : '';
              "
              class="pa-0 ma-0 pl-2"
              :class="setExpeditionRowColorClass(item)"
              style="color: black !important; width: 100%"
            >
              {{ item.text }}
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <!-- Expedition Customer Balance -->
      <v-col cols="12" sm="2">
        <v-text-field
          v-model="customer_balance"
          :label="$t('billings.customer_balance')"
          readonly
          class="mr-2 to-upper"
          :class="customer_balance > 0 ? 'customerbalance' : ''"
          v-on:keydown.enter.prevent=""
          :outlined="is_boxes"
          :dense="is_boxes"
          :error-messages="
            parseFloat(customer_balance) > parseFloat(credit_limit)
              ? [
                  $t(
                    'expeditions.text_messages.credit_limit_reached_for_the_expeditor'
                  ),
                ]
              : []
          "
        >
        </v-text-field>
      </v-col>
    </v-row>
    <!-- Company Row -->
    <v-row
      :class="
        parseFloat(customer_balance) > parseFloat(credit_limit)
          ? 'mt-n6'
          : 'mt-n9'
      "
    >
      <!-- Company Client Name -->
      <v-col cols="12" sm="4">
        <NameInput
          :required="true"
          v-model="expeditor"
          @cleared="
            expeditor_address = null;
            expeditor_member_name = null;
          "
          @addressUpdated="updateExpeditorAddress"
          :label="'expeditions.expeditior_name'"
          :selectedDenomination.sync="expeditor_denomination"
          :tva.sync="expeditor_tva"
          :address="expeditor_address"
          :error-messages="
            $store.state.validation
              ? returnErrorMessage(
                  [
                    !$v.expeditor.required,
                    this.$t('expeditions.text_messages.expeditor_required'),
                  ],
                  [
                    !$v.expeditor.email.required,
                    this.$t('expeditions.text_messages.email_required'),
                  ],
                  [
                    !$v.expeditor_tva.required,
                    this.$t('expeditions.text_messages.tva_required'),
                  ],
                  [
                    !$v.expeditor_address.mustBeObject,
                    this.$t('expeditions.text_messages.address_required'),
                  ]
                )
              : []
          "
        ></NameInput>
      </v-col>
      <!-- Billing Address -->
      <v-col cols="12" sm="5">
        <WorkingPointInput
          :required="true"
          v-model="expeditor_billing_working_point_id"
          :client="expeditor"
          @cleared="&quot;&quot;;"
          :label="'billings.billing_address_title'"
          :error-messages="
            $store.state.validation
              ? returnErrorMessage(
                  [
                    !$v.expeditor_billing_working_point_id.required,
                    this.$t(
                      'expeditions.text_messages.billing_address_work_point_required'
                    ),
                  ],
                  [
                    !$v.expeditor_billing_working_point_id.email.required,
                    this.$t('expeditions.text_messages.email_required'),
                  ],
                  [
                    !$v.expeditor_billing_working_point_id.address.mustBeObject,
                    this.$t('expeditions.text_messages.address_required'),
                  ]
                )
              : []
          "
          :addAddress="true"
        ></WorkingPointInput>
      </v-col>
      <!-- Aggreed Price -->
      <v-col cols="12" sm="2">
        <custom-money-input
          :autocomplete="autocompleteValueLocal"
          class="pt-0 to-upper right-aligned-input shrink"
          hide-details="auto"
          outlined
          dense
          tabindex="3"
          :label="$t('expeditions.expeditor_price')"
          v-model="our_price"
          v-bind:properties="{
            prefix: currencySymbol,
            readonly: false,
            disabled: false,
            outlined: false,
            placeholder: '0.00',
            dense: false,
            hideDetails: 'auto',
          }"
          v-bind:options="{
            locale: userLanguageId,
            length: 12,
            precision: 2,
            empty: 0,
          }"
        />
      </v-col>
      <!-- Payment Terms -->
      <v-col cols="12" sm="1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          class="to-upper right-aligned-input shrink"
          hide-details="false"
          :outlined="is_boxes"
          :dense="is_boxes"
          tabindex="3"
          :label="$t('expeditions.expeditor_payment_terms')"
          v-model="expeditor_payment_terms"
          :suffix="$t('clients.days')"
        />
      </v-col>
    </v-row>

    <!-- Map -->
    <v-row class="mt-n5">
      <v-col
        sm="8"
        style="min-height: 200px !important; max-height: 200px !important"
      >
        <div
          id="map"
          class="map"
          style="width: 100%; min-height: 200px !important"
        ></div>
      </v-col>
      <!-- Document -->
      <v-col cols="12" sm="4">
        <v-row>
          <v-col
            @dragenter="showAttachmentDialog()"
            cols="12"
            sm="12"
            class="mt-0 mb-0 pt-0 pb-0"
          >
            <fieldset
              style="
                border: 1px solid grey;
                border-radius: 4px;
                height: 102px;
                min-width: 0px;
              "
            >
              <legend style="margin-left: 10px">Documents</legend>
              <document-selectors
                :data="selectedExpedition"
                :countKey="'expedition'"
                :resource="'expedition'"
                @cmradded="cmraddedChangeExpeditionStatus"
                @billadded="billAddedNowMakeExpense"
                @billRemoved="cancelExpenseAndRemoveFromExpedition"
                @attachments="documentsLoaded"
              ></document-selectors>
            </fieldset>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" class="mt-0 mb-0 pt-0 pb-0">
            <v-textarea
              :outlined="true"
              v-model="note"
              :label="$t('expeditions.note')"
              rows="3"
              class="to-upper mt-2"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Add Transport Type -->
    <v-row class="mt-6">
      <v-col class="d-flex">
        <h2 class="expedition_title" style="color: #af604c !important">
          {{ $t("expeditions.transport") }}
        </h2>
      </v-col>
    </v-row>

    <!-- Transport Row -->
    <v-row class="mt-n8">
      <!-- Search Transporter -->
      <v-col cols="12" sm="4">
        <!-- v-if="!selectedExpedition || (selectedExpedition && selectedExpedition.status.key != 'in_process')" -->
        <NameInput
          v-model="selectedTransporter"
          @cleared="transporter_address = null"
          @addressUpdated="updateTransporterAddress"
          :label="'expeditions.transporter_name'"
          :selectedDenomination.sync="transporter_denomination"
          :tva.sync="transporter_tva"
          :blocked="is_transporter_blocked"
          :address="transporter_address"
          :email_required="false"
          :error-messages="
            $store.state.validation
              ? returnErrorMessage(
                  [
                    !$v.transporter_tva.required,
                    this.$t('expeditions.text_messages.tva_required'),
                  ],
                  [
                    !$v.transporter_address.mustBeObject,
                    this.$t('expeditions.text_messages.address_required'),
                  ]
                )
              : []
          "
        ></NameInput>
        <!-- <NameInput
                    v-if="!(!selectedExpedition || (selectedExpedition && selectedExpedition.status.key != 'in_process'))"
                    v-model="selectedTransporter"
                    @cleared="transporter_address = null;"
                    @addressUpdated="updateTransporterAddress"
                    :label="'expeditions.transporter_name'"
                    :disabled="true"
                    :selectedDenomination.sync="transporter_denomination"
                    :tva.sync="transporter_tva"
                    :blocked="is_transporter_blocked"
                    :address="transporter_address"
                    :error-messages=" $store.state.validation ? returnErrorMessage(
                                [!$v.selectedTransporter.email.required, this.$t('expeditions.text_messages.email_required')],
                                [!$v.transporter_tva.required, this.$t('expeditions.text_messages.tva_required')],
                                [!$v.transporter_address.mustBeObject, this.$t('expeditions.text_messages.address_required')]
                            )
                        : []
                    "
                ></NameInput> -->
      </v-col>
      <v-col cols="12" sm="1">
        <v-checkbox
          :label="$t('expeditions.is_transporter_blocked')"
          v-model="is_transporter_blocked"
          :class="is_transporter_blocked ? 'checkboxblocked' : ''"
          @click="is_transporter_blocked = !is_transporter_blocked"
          color="red"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="3">
        <MemberInput
          :label="'expeditions.transporter_member_name'"
          v-model="selectedTransporterMember"
          :client="selectedTransporter"
          ref="transporter_member"
          @saveClientFirstAndThenTriggerBack="
            saveTransporterFirstAndThenTriggerBackSaveMemberDialog
          "
        ></MemberInput>
      </v-col>
      <!-- Transporter Price -->
      <v-col cols="12" sm="1">
        <custom-money-input
          :autocomplete="autocompleteValueLocal"
          class="to-upper right-aligned-input shrink mt-5"
          hide-details="auto"
          dense
          tabindex="3"
          :label="$t('expeditions.transporter_price')"
          v-model="transporter_price"
          v-bind:properties="{
            prefix: currencySymbol,
            readonly: false,
            outlined: false,
            placeholder: '0.00',
            dense: true,
            hideDetails: 'auto',
          }"
          v-bind:options="{
            locale: userLanguageId,
            length: 12,
            precision: 2,
            empty: 0,
          }"
        />
      </v-col>
      <!-- Payment Terms -->
      <v-col cols="12" sm="1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          class="to-upper right-aligned-input shrink"
          hide-details="false"
          :outlined="is_boxes"
          :dense="is_boxes"
          tabindex="3"
          :label="$t('expeditions.expeditor_payment_terms')"
          :suffix="$t('clients.days')"
          v-model="transporter_payment_terms"
        />
      </v-col>
      <!-- Truck Registration Number -->
      <v-col cols="12" sm="1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="truck_registration_number"
          class="to-upper"
          :label="$t('expeditions.truck_registration_number')"
          v-on:keydown.enter.prevent=""
          v-on:keydown="handleInput($event, 'alphanumeric', 80)"
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-details
        ></v-text-field>
      </v-col>
      <!-- Trolly Registration Number -->
      <v-col cols="12" sm="1">
        <v-text-field
          :autocomplete="autocompleteValueLocal"
          v-model="trolly_registration_number"
          class="to-upper"
          :label="$t('expeditions.trolly_registration_number')"
          v-on:keydown.enter.prevent=""
          v-on:keydown="handleInput($event, 'alphanumeric', 80)"
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Add Charging -->
    <v-row class="mt-n4">
      <v-col class="d-flex mt-4 pt-0 mb-0 pb-0">
        <h2 class="expedition_title" style="color: #2196f3">
          {{ $t("expeditions.charging.title") }}
        </h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex mt-0 pt-0 mb-0 pb-0" cols="12" sm="1">
        <v-btn
          :disabled="!expeditor"
          id="add-new-charging"
          class="ma-2"
          color="primary"
          @click="addChargingItem"
        >
          {{ $t("add") }}
          <v-icon dark right> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Charging Datatable -->
    <v-row>
      <v-data-table
        fixed-header
        :footer-props="{
          'items-per-page-options': dataTableOptions,
        }"
        :hide-default-footer="true"
        :hide-default-header="true"
        :headers="chargingHeaders"
        :items="chargings"
        :options="chargingOptions"
        class="elevation-0"
        :items-per-page="chargingItemsPerPage"
        item-key="identifier"
        @update:page="updateChargingPagination()"
        @update:items-per-page="updateItemsPerPage()"
        @click:row="editChargingItem"
        height="200px"
        style="width: 100%"
        :key="forceReloadComponentKey"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                v-for="(h, index) in headers"
                class="data-table-header"
                :key="index"
              >
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:footer>
          <v-row class="data-table-footer ma-0">
            <v-col>
              <span>{{ makeChargingFooterText }}</span>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{ item, index }">
          <tr>
            <td>
              <div
                class="d-flex flex-row information-class"
                @mouseover="toggleElementById(index, 'visible', 'charging')"
                @mouseleave="toggleElementById(index, 'hidden', 'charging')"
              >
                {{ item.srno }}
                <v-btn
                  v-if="index != 0"
                  @click="moveToUpCharge(item, index)"
                  x-small
                  :style="{
                    visibility: 'hidden',
                    marginLeft: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: '10px',
                  }"
                >
                  <v-icon
                    :id="'charging' + generateElementId(index)"
                    class="mdi mdi-arrow-up"
                  ></v-icon>
                </v-btn>
                <v-btn
                  v-if="index < chargings.length - 1"
                  @click="moveToDownCharge(item, index)"
                  x-small
                  :style="{
                    visibility: 'hidden',
                    marginLeft: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: '10px',
                  }"
                >
                  <v-icon
                    :id="'charging' + 'down_' + generateElementId(index)"
                    class="mdi mdi-arrow-down"
                  ></v-icon>
                </v-btn>
              </div>
            </td>
            <td @click="editChargingItem(item)">
              <template v-if="item.reference">
                {{ item.reference ? item.reference.toUpperCase() : "" }}
              </template>
            </td>
            <td @click="editChargingItem(item)">
              <template v-if="item.address">
                {{ computeAddressLabel(item.address) }}
              </template>
            </td>
            <td @click="editChargingItem(item)">
              <template v-if="item.selectedArticle">
                {{ item.selectedArticle.original_name }}
              </template>
            </td>
            <td @click="editChargingItem(item)">
              <template v-if="item.quantity">
                {{ item.quantity }}
              </template>
            </td>
            <td @click="editChargingItem(item)">
              <template v-if="item.search_from_date">
                {{ item.search_from_date }}
              </template>
              <template v-if="item.search_to_date">
                -{{ item.search_to_date }}
              </template>
            </td>
            <td @click="editChargingItem(item)">
              <template v-if="item.search_from_time">
                {{ item.search_from_time }}
              </template>
              <template v-if="item.search_to_time">
                -{{ item.search_to_time }}
              </template>
            </td>
            <td @click="editChargingItem(item)">
              <template v-if="item.is_appointment">
                <v-icon color="success"> mdi-check </v-icon>
              </template>
            </td>
            <td @click="editChargingItem(item)">
              <template v-if="item.note && item.note.length > 20">
                <v-tooltip bottom z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{
                        item.note
                          ? item.note.substr(0, 20).toUpperCase() + "..."
                          : ""
                      }}
                    </span>
                  </template>
                  {{ item.note ? item.note.toUpperCase() : "" }}
                </v-tooltip>
              </template>
              <template v-else>
                {{ item.note ? item.note.toUpperCase() : "" }}
              </template>
            </td>
            <td @click="editChargingItem(item)">
              <!-- {{ item }} -->
            </td>
            <td>
              <v-icon
                v-if="item"
                color="warning"
                class="mr-1"
                @click="deleteChargingAfterConfirmation(item, index)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-row>

    <!-- Add Discharging -->
    <v-row class="mt-5">
      <v-col class="d-flex mt-4 pt-0 mb-0 pb-0">
        <h2
          class="expedition_title mb-0 mt-1"
          style="color: #4caf50 !important"
        >
          {{ $t("expeditions.discharging.title") }}
        </h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex mt-0 pt-0 mb-0 pb-0" cols="12" sm="1">
        <v-btn
          :disabled="!expeditor || chargings.length == 0"
          id="add-new-discharging"
          class="ma-2"
          color="success"
          @click="addDischargingItem"
        >
          {{ $t("add") }}
          <v-icon dark right> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Discharging Datatable -->
    <v-row>
      <v-data-table
        fixed-header
        :footer-props="{
          'items-per-page-options': dataTableOptions,
        }"
        :hide-default-footer="true"
        :hide-default-header="true"
        :headers="deliveryHeaders"
        :items="dischargings"
        :options="dischargingOptions"
        :loading="isDisChargingLoading"
        :loading-text="this.$t('loading')"
        class="elevation-0"
        :items-per-page="disChargingItemsPerPage"
        item-key="identifier"
        @click:row="editDischargingItem"
        height="200px"
        style="width: 100%"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                v-for="(h, index) in headers"
                class="data-table-header"
                :key="index"
              >
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:footer>
          <v-row class="data-table-footer ma-0">
            <v-col>
              <span>{{ makeDisChargingFooterText }}</span>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{ item, index }">
          <tr>
            <td>
              <div
                class="d-flex flex-row information-class"
                @mouseover="toggleElementById(index, 'visible', 'discharging')"
                @mouseleave="toggleElementById(index, 'hidden', 'discharging')"
              >
                {{ item.srno }}
                <v-btn
                  v-if="index != 0"
                  @click="moveToUpDisCharge(item, index)"
                  x-small
                  :style="{
                    visibility: 'hidden',
                    marginLeft: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: '10px',
                  }"
                >
                  <v-icon
                    :id="'discharging' + generateElementId(index)"
                    class="mdi mdi-arrow-up"
                  ></v-icon>
                </v-btn>
                <v-btn
                  v-if="index < dischargings.length - 1"
                  @click="moveToDownDisCharge(item, index)"
                  x-small
                  :style="{
                    visibility: 'hidden',
                    marginLeft: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: '10px',
                  }"
                >
                  <v-icon
                    :id="'discharging' + 'down_' + generateElementId(index)"
                    class="mdi mdi-arrow-down"
                  ></v-icon>
                </v-btn>
              </div>
            </td>
            <td @click="editDischargingItem(item)">
              <template v-if="item.reference">
                {{ item.reference ? item.reference.toUpperCase() : "" }}
              </template>
            </td>
            <td @click="editDischargingItem(item)">
              <template v-if="item.address">
                {{ computeAddressLabel(item.address) }}
              </template>
            </td>
            <td @click="editDischargingItem(item)">
              <template v-if="item.selectedArticle">
                {{ item.selectedArticle.original_name }}
              </template>
            </td>
            <td @click="editDischargingItem(item)">
              <template v-if="item.palets">
                {{ item.palets }}
              </template>
            </td>
            <td @click="editDischargingItem(item)">
              <template v-if="item.search_from_date">
                {{ item.search_from_date }}
              </template>
              <template v-if="item.search_to_date">
                -{{ item.search_to_date }}
              </template>
            </td>
            <td @click="editDischargingItem(item)">
              <template v-if="item.search_from_time">
                {{ item.search_from_time }}
              </template>
              <template v-if="item.search_to_time">
                -{{ item.search_to_time }}
              </template>
            </td>
            <td @click="editDischargingItem(item)">
              <template v-if="item.is_appointment">
                <v-icon color="success"> mdi-check </v-icon>
              </template>
            </td>
            <td @click="editDischargingItem(item)">
              <template v-if="item.note && item.note.length > 20">
                <v-tooltip bottom z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{
                        item.note
                          ? item.note.substr(0, 20).toUpperCase() + "..."
                          : ""
                      }}
                    </span>
                  </template>
                  {{ item.note ? item.note.toUpperCase() : "" }}
                </v-tooltip>
              </template>
              <template v-else>
                {{ item.note ? item.note.toUpperCase() : "" }}
              </template>
            </td>
            <td>
              <v-icon
                v-if="item"
                color="warning"
                class="mr-1"
                @click="deleteDisChargingAfterConfirmation(item, index)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <expedition-charging
        v-if="showChargingDialogue"
        :openMode="open_mode"
        :passedData="passedData"
        :showDialog.sync="showChargingDialogue"
        :selectedItem="selectedItem"
        :languages="languages"
        :loadingEquipmentTypes="loadingEquipmentTypes"
        :expeditionMerchandiseTypes="expeditionMerchandiseTypes"
        :denominations="denominations"
        :expeditionVehicleType.sync="expeditionVehicleType"
        :expeditionVehicleTypes.sync="expeditionVehicleTypes"
        :expeditionVehicleBodyType.sync="expeditionVehicleBodyType"
        :expeditionVehicleBodyTypes.sync="expeditionVehicleBodyTypes"
        :expeditionVehicleBodyCharacteristicsType.sync="
          expeditionVehicleBodyCharacteristicsType
        "
        :expeditionVehicleBodyCharacteristicsTypes.sync="
          expeditionVehicleBodyCharacteristicsTypes
        "
        :memberType="memberType"
        @added="addedCharging"
        @updated="updatedCharging"
        @changeDialogue="changeDialogue"
        @reOpenDialog="reOpenDialog"
      ></expedition-charging>
      <expedition-dis-charging
        v-if="showDisChargingDialogue"
        :openMode="open_mode"
        :showDialog.sync="showDisChargingDialogue"
        :selectedItem="selectedItem"
        :languages="languages"
        :company_client_name="expeditor"
        :chargings="chargings"
        :dischargings="dischargings"
        :expeditionVehicleType.sync="expeditionVehicleType"
        :expeditionVehicleTypes.sync="expeditionVehicleTypes"
        :expeditionVehicleBodyType.sync="expeditionVehicleBodyType"
        :expeditionVehicleBodyTypes.sync="expeditionVehicleBodyTypes"
        :expeditionVehicleBodyCharacteristicsType.sync="
          expeditionVehicleBodyCharacteristicsType
        "
        :expeditionVehicleBodyCharacteristicsTypes.sync="
          expeditionVehicleBodyCharacteristicsTypes
        "
        :denominations="denominations"
        :memberType="memberType"
        @added="addedDischarging"
        @updated="updatedDischarging"
        @changeDialogue="changeDialogue()"
        @reOpenDialog="reOpenDialog"
      ></expedition-dis-charging>
    </v-row>

    <!-- Transport Types -->
    <v-row class="mb-5 mt-8 mb-10">
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              clearable
              :label="$t('expeditions.truck_types')"
              :items="expeditionVehicleTypes"
              v-model="expeditionVehicleType"
              item-value="id"
              item-text="text"
              menu-props="auto"
              multiple
              hide-details="auto"
              dense
            >
              <template v-slot:selection="{ item, index }">
                <span>
                  <template
                    v-if="index === 0 && expeditionVehicleType.length == 1"
                  >
                    {{
                      item.text.substr(
                        0,
                        expeditionVehicleType.length > 1 ? 10 : 12
                      )
                    }}
                  </template>
                  <template v-if="index === 1">
                    {{
                      item.text.substr(
                        0,
                        expeditionVehicleType.length > 1 ? 10 : 12
                      )
                    }}
                    (+{{ expeditionVehicleType.length - 1 }})
                  </template>
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              clearable
              :label="$t('expeditions.body_type')"
              :items="expeditionVehicleBodyTypes"
              v-model="expeditionVehicleBodyType"
              multiple
              item-value="id"
              item-text="text"
              menu-props="auto"
              hide-details="auto"
              dense
            >
              <template v-slot:selection="{ item, index }">
                <span>
                  <template
                    v-if="index === 0 && expeditionVehicleBodyType.length == 1"
                  >
                    {{
                      item.text.substr(
                        0,
                        expeditionVehicleBodyType.length > 1 ? 10 : 12
                      )
                    }}
                  </template>
                  <template v-if="index === 1">
                    {{
                      item.text.substr(
                        0,
                        expeditionVehicleBodyType.length > 1 ? 10 : 12
                      )
                    }}
                    (+{{ expeditionVehicleBodyType.length - 1 }})
                  </template>
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              clearable
              :label="$t('expeditions.characteristics')"
              :items="expeditionVehicleBodyCharacteristicsTypes"
              v-model="expeditionVehicleBodyCharacteristicsType"
              multiple
              item-value="id"
              item-text="text"
              menu-props="auto"
              hide-details="auto"
              dense
            >
              <template v-slot:selection="{ item, index }">
                <span>
                  <template
                    v-if="
                      index === 0 &&
                      expeditionVehicleBodyCharacteristicsType.length == 1
                    "
                  >
                    {{
                      item.text.substr(
                        0,
                        expeditionVehicleBodyCharacteristicsType.length > 1
                          ? 10
                          : 12
                      )
                    }}
                  </template>
                  <template v-if="index === 1">
                    {{
                      item.text.substr(
                        0,
                        expeditionVehicleBodyCharacteristicsType.length > 1
                          ? 10
                          : 12
                      )
                    }}
                    (+{{ expeditionVehicleBodyCharacteristicsType.length - 1 }})
                  </template>
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-btn
      @click="saveExpedition()"
      class="d-none"
      id="save-expedition"
    ></v-btn>
    <v-btn
      @click="archivePopUp()"
      class="d-none"
      id="make-expedition-archive"
    ></v-btn>
    <v-btn
      @click="makeExpeditionBill()"
      class="d-none"
      id="make-expedition-bill"
    ></v-btn>
    <v-btn
      @click="makeExpeditionExpensePopUp()"
      class="d-none"
      id="make-expedition-expense"
    ></v-btn>
    <v-btn
      @click="printExpeditionBill()"
      class="d-none"
      id="print-expedition-bill"
    ></v-btn>
    <v-btn
      @click="duplicateExpeditionDialog()"
      class="d-none"
      id="duplicate-expedition"
    ></v-btn>
    <v-btn
      @click="activateExpeditionEmailDialog"
      class="d-none"
      id="activate-expedition-email"
    ></v-btn>
    <loading-model :showLoadingModel.sync="showLoadingModel"></loading-model>

    <confirmation-model
      v-if="confirmationDialog"
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <send-pdf-via-email
      v-model="expeditionEmailDialog"
      target="expedition"
      :model="selectedExpedition"
      v-if="selectedExpedition"
    ></send-pdf-via-email>
    <DuplicateExpeditionModel
      v-if="duplicate_expedition_dialog"
      :dialog.sync="duplicate_expedition_dialog"
      @duplicateCount="duplicateExpedition"
    ></DuplicateExpeditionModel>
    <CancellationConfirmationDialogue
      v-if="cancel_expedition_dialog"
      :dialog.sync="cancel_expedition_dialog"
      @cancellationNote="cancelExpedition"
    ></CancellationConfirmationDialogue>
    <span id="launch-interventions" @click="openHistoryTableDialog"
      ><history-table
        v-model="historyTableDialog"
        :model_id="historyTableModelId"
        :model="historyTableModel"
    /></span>
  </div>
</template>
<script>
import { Map, Marker } from "maplibre-gl";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import DocumentSelectors from "@/components/CustomInputs/DocumentSelectors";
import ExpeditionChargingDialogue from "@/components/Expeditions/ExpeditionChargingDialogue";
import ExpeditionDisChargingDialogue from "@/components/Expeditions/ExpeditionDisChargingDialogue";
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { EXPEDITION_MODE } from "@/config";
import { validationMixin } from "vuelidate";
import LoadingModel from "@/components/LoadingModel";
import NameInput from "@/components/NameInput";
import MemberInput from "@/components/MemberInput";
import SendPdfViaEmail from "@/components/SendPdfViaEmail";
import historyTableMixin from "@/mixins/historyTableMixin";

import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import DuplicateExpeditionModel from "@/components/Expeditions/DuplicateExpedition";
import CancellationConfirmationDialogue from "@/components/Expeditions/CancellationConfirmationDialogue";
import ConfirmationModel from "@/components/ConfirmationModel";
import WorkingPointInput from "@/components/WorkingPointInput.vue";
import HistoryTable from "@/components/UserActionHistory/HistoryTable.vue";

const mustBeObject = (value) => {
  if (value) {
    return typeof value === "object";
  }
  return false;
};

export default {
  mixins: [validationMixin, historyTableMixin],
  name: "Expedition",
  components: {
    HistoryTable,
    SendPdfViaEmail,
    "custom-money-input": CutsomMoneyInput,
    "document-selectors": DocumentSelectors,
    "expedition-dis-charging": ExpeditionDisChargingDialogue,
    "expedition-charging": ExpeditionChargingDialogue,
    "send-pdf-via-email": SendPdfViaEmail,
    // "attachment-dialog": AttachmentDialog,
    "loading-model": LoadingModel,
    ConfirmationModel,
    NameInput,
    MemberInput,
    DuplicateExpeditionModel,
    CancellationConfirmationDialogue,
    // AddressInput,
    WorkingPointInput,
  },
  validations: {
    expeditor: {
      required,
      email: {
        required: requiredIf(function () {
          return this.expeditor;
        }),
      },
    },
    expeditor_address: {
      mustBeObject,
    },
    expeditor_tva: {
      required,
    },
    // selectedTransporter: {
    //     email: {
    //         required: requiredIf(function () {
    //                 return this.selectedTransporter;
    //         }),
    //     },
    // },
    transporter_address: {
      mustBeObject: requiredIf(function () {
        return this.selectedTransporter;
      }),
    },
    transporter_tva: {
      required: requiredIf(function () {
        return this.selectedTransporter;
      }),
    },
    expeditor_billing_working_point_id: {
      required,
      email: {
        required: requiredIf(function () {
          return this.expeditor_billing_working_point_id;
        }),
      },
      address: {
        mustBeObject: requiredIf(function () {
          return this.expeditor_billing_working_point_id;
        }),
      },
    },
    reference: {
      required,
      maxLength: maxLength(150),
    },
  },
  computed: {
    companyUsers() {
      if (!this.company) {
        return [];
      }
      return this.company.users;
    },
    billType() {
      return this.$store.state.billType;
    },
    billTypes() {
      return this.$store.state.billTypes;
    },
    chargingDischargigArticle() {
      return JSON.parse(localStorage.getItem("expedition_article"));
    },
    currencySymbol() {
      let symbol = null;
      if (this.$store.state.topCurrencyType) {
        symbol = this.parseSymbol(
          this.$store.state.topCurrencyType.symbol ?? ""
        );
        return symbol;
      }
      return symbol ?? "";
    },
    makeChargingFooterText() {
      return (
        this.$t("expeditions.total") +
        " " +
        this.chargings.length +
        " " +
        this.$t("expeditions.location") +
        ", " +
        this.$t("expeditions.approx_distance") +
        " " +
        this.calculateTotalDistanceOfCharging().toFixed(3) +
        " Km " +
        // this.$t('expeditions.approx_charge')  + " " + "1,67 tons";
        ""
      );
      // return "";
    },
    makeDisChargingFooterText() {
      return (
        this.$t("expeditions.total") +
        " " +
        this.dischargings.length +
        " " +
        this.$t("expeditions.location") +
        ", " +
        this.$t("expeditions.approx_distance") +
        " " +
        this.calculateTotalDistanceOfDisCharging().toFixed(3) +
        " Km " +
        // this.$t('expeditions.approx_charge')  + " " + "1,67 tons";
        ""
      );
      // return "";
    },
    chargingHeaders() {
      return [
        { text: this.$t("expeditions.srno"), value: "srno" },
        { text: this.$t("expeditions.reference"), value: "reference" },
        { text: this.$t("expeditions.location"), value: "location" },
        { text: this.$t("expeditions.goods"), value: "goods" },
        { text: this.$t("expeditions.quantity"), value: "quantity" },
        { text: this.$t("expeditions.date"), value: "date" },
        { text: this.$t("expeditions.time_interval"), value: "time_interval" },
        {
          text: this.$t("expeditions.appointment"),
          value: "appointment",
          sortable: false,
        },
        { text: this.$t("expeditions.note"), value: "note" },
        { text: this.$t("expeditions.operations"), value: "operations" },
        { text: this.$t("expeditions.actions"), value: "actions" },
      ];
    },
    deliveryHeaders() {
      return [
        { text: this.$t("expeditions.srno"), value: "srno" },
        { text: this.$t("expeditions.reference"), value: "reference" },
        { text: this.$t("expeditions.location"), value: "location" },
        { text: this.$t("expeditions.goods"), value: "goods" },
        { text: this.$t("expeditions.quantity"), value: "quantity" },
        { text: this.$t("expeditions.date"), value: "date" },
        { text: this.$t("expeditions.time_interval"), value: "time_interval" },
        {
          text: this.$t("expeditions.appointment"),
          value: "appointment",
          sortable: false,
        },
        { text: this.$t("expeditions.note"), value: "note" },
        { text: this.$t("expeditions.operations"), value: "operations" },
      ];
    },
  },
  activated() {
    this.reloadMap();
    this.getVehicleBodyTypes();
    this.getExpeditionVehicleTypes();
    this.getExpeditionStatuses();
    this.getVehicleBodyCharacteristicsTypes();
    this.getExpeditionExchangeContainersTypes();
    this.getExpeditionEquipmentTypes();
    this.getExpeditionLoadSecuringTypes();
    this.getExpeditionCertificatesTypes();
    this.getMerchandiseTypes();
    this.getLoadingEquipmentTypes();

    if (this.$route.path.indexOf("/edit") > 0) {
      this.fetchExpeditionData();
    }
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      expense_id: null,
      vat: 0,
      cached_vat: 0,
      taxationAllowedForTransporter: true,
      customer_balance: 0,
      expeditor_billing_working_point_id: null,
      expeditor_working_points: [],
      duplicate_expedition_dialog: false,
      cancel_expedition_dialog: false,
      expeditor_payment_terms: null,
      transporter_payment_terms: null,
      transporter_default_payment_terms: 45, // To be removed later because then we will manage it from company properties
      credit_limit: 0,
      companyDefaultPaymentTerms: 0,
      companyDefaultCreditLimit: 0,
      is_transporter_blocked: false,
      open_mode: "add",
      expeditionEmailDialog: false,
      passedData: null,
      transporter_address: null,
      transporter_tva: null,
      transporter_denomination: null,
      truck_registration_number: null,
      trolly_registration_number: null,
      attachmentDialog: false,
      expedition_identifier: null,
      interventions: null,
      menu_creation_date: false,
      creation_date: null,
      reference: null,
      created_date: this.formatDate(this.getCurrentDate(), "yyyy-mm-dd"),
      selectedTransporter: null,
      selectedTransporterMember: null,
      selectedTransporterMembers: [],
      search_transporter: null,
      isLoadingSearchTransporter: false,
      transporters: [],
      transporter_price: null,
      oldExpeditionStatus: null,
      expeditionStatus: null,
      note: null,
      expeditor: null,
      expeditor_member_name: null,
      search_client: null,
      expeditor_tva: null,
      expeditor_address: null,
      expeditor_denomination: null,
      memberType: null,
      memberPhone: null,
      memberEmail: null,
      chargings: [],
      isChargingLoading: false,
      chargingOptions: {},
      chargingItemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      totalChargingItems: null,
      expeditionVehicleType: null,
      expeditionVehicleBodyType: null,
      expeditionVehicleBodyCharacteristicsType: null,
      our_price: null,
      dischargings: [],
      dischargingOptions: {},
      disChargingItemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      totalDischargingItems: null,
      isDisChargingLoading: false,

      showChargingDialogue: false,
      showDisChargingDialogue: false,
      autocompleteValueLocal: "off",
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      languages: [],
      language: null,
      isEditingAllowed: false,
      search_member: null,
      chargingCordinates: [],
      dischargingCordinates: [],
      mapCordinates: [[5.951828, 49.603575]],
      selectedItem: null,
      selectedExpedition: null,
      attachmentsLoadedFromDialog: [],
      denominations: [],
      showLoadingModel: false,

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      confirmationDialogData: null,
      expeditionVehicleTypes: [],
      expeditionVehicleBodyTypes: [],
      expeditionVehicleBodyCharacteristicsTypes: [],
      loadingEquipmentTypes: [],
      expeditionMerchandiseTypes: [],
      company: null,
      seconduser: null,
    };
  },
  watch: {
    taxationAllowedForTransporter(val) {
      if (val == true) {
        this.vat = this.cached_vat;
      } else if (val == false) {
        this.vat = 0;
      }
    },
    expeditor_address(val) {
      if (val && typeof val == "object" && val.name) {
        if (!this.expeditor) {
          this.expeditor = val.name;
        }
      }
    },
    customer_balance() {
      // if(parseFloat(val) > parseFloat(this.credit_limit)){
      //     this.$toast.error(this.$t('expeditions.text_messages.credit_limit_reached_for_the_expeditor'));
      // }
    },
    expeditionStatus(newVal, oldVal) {
      this.oldExpeditionStatus = oldVal;
      this.$store.commit("setSelectedExpeditionStatus", newVal);
    },
    is_transporter_blocked() {
      // if(val && val == true){
      //     this.$toast.warning(this.$t('expeditions.text_messages.cannot_work_with_this_client'));
      // }
    },
    selectedTransporter(val) {
      this.is_transporter_blocked = false;
      this.$store.commit("setSelectedTransporter", val);
      if (val && typeof val == "object" && val.company) {
        this.transporter_payment_terms = val.company.payment_terms;
        // this.transporter_payment_terms = this.transporter_default_payment_terms;
        this.changeExpeditionStatusOnAddingTransporter();
        if (val.status_id == 10041003) {
          this.is_transporter_blocked = true;
        }
        this.transporter_tva = val.company.tva ?? null;

        // Set Taxation Reason
        this.taxationAllowedForTransporter = this.findTaxationReasonByCode(
          this.selectedTransporter.company.taxation_reason_id
        );
        // Transporter Address
        if (val.company_address && val.company_address.length > 0) {
          val.company_address.forEach((companyAddress) => {
            if (
              companyAddress.is_default &&
              companyAddress.status_id == 10071001 &&
              companyAddress.address_type_id == 10511001
            ) {
              // status_id = address_active && address_type = billing_address
              this.transporter_address = companyAddress.address;
            }
          });

          // If company address is not selected as it should be.. then select any address as default for billing address
          // if(!this.transporter_address){
          //     val.company_address.forEach((companyAddress) => {
          //         if(!this.transporter_address && companyAddress.status_id == 10071001){ // Only set the first one as default
          //             this.transporter_address = companyAddress.address
          //         }
          //     })
          // }
        }
      } else if (!val) {
        // this.transporter_payment_terms = this.companyDefaultPaymentTerms;
        this.transporter_payment_terms = this.transporter_default_payment_terms;

        // Set Taxation Reason
        this.taxationAllowedForTransporter = true;
      }
      if (val == null) {
        this.transporter_tva = null;

        // Set Taxation Reason
        this.taxationAllowedForTransporter = true;
      }
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
        if (this.confirmationDialogOperation == "delete_charging") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.deleteCharging(
              this.confirmationDialogData.data,
              this.confirmationDialogData.index
            );
          }
        }
        if (this.confirmationDialogOperation == "delete_discharging") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.deleteDisCharging(
              this.confirmationDialogData.data,
              this.confirmationDialogData.index
            );
          }
        }
        if (this.confirmationDialogOperation == "archive") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.expeditionArchive();
          }
        }
        if (this.confirmationDialogOperation == "makeExpense") {
          if (this.confirmationDialogButtonClickedIs == true) {
            this.makeExpeditionExpense();
          }
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
    chargings(val) {
      if (val && val.length > 0) {
        this.chargingCordinates = [];
        val.forEach((charging) => {
          this.chargingCordinates.push([
            charging.address.long,
            charging.address.lat,
          ]);
        });
      }
      this.makeMapCordinates();
    },
    dischargings(val) {
      if (val && val.length > 0) {
        this.dischargingCordinates = [];
        val.forEach((discharging) => {
          this.dischargingCordinates.push([
            discharging.address.long,
            discharging.address.lat,
          ]);
        });
      }
      this.makeMapCordinates();
    },
    search_transporter(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchTransporterListDebounced();
      }, 500);
    },
    search_client(val) {
      if (!val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchClientsListDebounced();
      }, 500);
    },
    expeditor_member_name() {
      //
    },
    expeditor: {
      handler: function (val) {
        if (this.$route.path.indexOf("/add")) {
          this.$store.commit("setSelectedExpeditor", val);
        } else {
          this.$store.commit("setSelectedExpeditor", null);
        }
        if (val && val.company) {
          this.expeditor_tva = val.company.tva ?? null;
          this.expeditor_payment_terms = val.company.payment_terms;
          this.credit_limit = val.company.credit_limit;
          if (val.company_address && val.company_address.length > 0) {
            val.company_address.forEach((companyAddress) => {
              if (
                companyAddress.is_default &&
                companyAddress.status_id == 10071001 &&
                companyAddress.address_type_id == 10511001
              ) {
                // status_id = address_active && address_type = main_address
                this.expeditor_address = companyAddress.address;
              }
            });

            // If company address is not selected as it should be.. then select any address as default for billing address
            // if(!this.expeditor_address){
            //     val.company_address.forEach((companyAddress) => {
            //         if(!this.expeditor_address && companyAddress.status_id == 10071001){ // Only set the first one as default
            //             this.expeditor_address = companyAddress.address
            //         }
            //     })
            // }
          }
          if (val && val.id) {
            this.fetchClientBalance(val.id);
          }
          this.getWorkPoints();
        } else if (!val) {
          this.expeditor_tva = null;
          this.expeditor_address = null;
          this.expeditor_payment_terms = this.companyDefaultPaymentTerms;
          this.credit_limit = this.companyDefaultCreditLimit;
        }
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.$store.commit("setFormMode", EXPEDITION_MODE);
      this.$store.commit("setSelectedExpedition", null);
      this.$store.commit("setSelectedExpeditionStatus", null);
      this.$store.commit("setSelectedExpeditionDocuments", null);
      this.$store.commit("setSelectedTransporter", null);
      this.$store.commit("setSelectedExpeditor", null);
      if (this.$route.path.indexOf("/edit") > 0 && !this.$store.state.editId) {
        this.$router.push("/expedition");
      }
      this.getCompany();
      let userData = localStorage.getItem("data");
      userData = JSON.parse(userData);
      let userCompany = userData.userDetails.company;
      this.companyDefaultCreditLimit = userCompany.credit_limit;
      this.companyDefaultPaymentTerms = userCompany.payment_terms;
      this.expeditor_payment_terms = this.companyDefaultPaymentTerms;
      // this.transporter_payment_terms = this.companyDefaultPaymentTerms;
      this.transporter_payment_terms = this.transporter_default_payment_terms;
      this.reloadMap();
      this.getVehicleBodyTypes();
      this.getLanguages();
      this.getexpeditionMerchandiseTypes();
      this.getExpeditionVehicleTypes();
      this.getExpeditionStatuses();
      this.getVehicleBodyCharacteristicsTypes();
      this.getExpeditionExchangeContainersTypes();
      this.getExpeditionEquipmentTypes();
      this.getExpeditionLoadSecuringTypes();
      this.getExpeditionCertificatesTypes();
      this.getMerchandiseTypes();
      this.getLoadingEquipmentTypes();
      this.getMemberTypes();
      this.getTvas();
      this.getBillTypes();
      // this.selectDefaultMemberType();

      if (this.$route.path.indexOf("/edit") > 0) {
        this.fetchExpeditionData();
        this.setHistoryModalData("Expedition", this.$store.state.editId);
      }
    },
    cancelExpenseAndRemoveFromExpedition() {
      if (!this.$store.state.editId) return;

      return axios
        .put(
          API_BASE_URL + "/expeditions/" + this.$store.state.editId,
          {
            remove_expense: true,
          },
          { headers: this.header }
        )
        .then(() => {
          this.init();
        });
    },
    getCompany() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/companies/" + this.$store.state.companyId, {
            headers: this.header,
          })
          .then((response) => {
            this.company = response.data.data;
            resolve(this.company);
          })
          .catch((error) => reject(error));
      });
    },
    documentsLoaded(documentsLoaded) {
      this.$store.commit("setSelectedExpeditionDocuments", documentsLoaded);
    },
    getBillTypes() {
      return new Promise((resolve) => {
        this.getCodes("order_types", "billings.types").then((data) => {
          this.$store.commit("setBillTypes", data);
          this.setBillType();
          resolve();
        });
      });
    },
    billAddedNowMakeExpense() {
      this.makeExpeditionExpensePopUp(true);
    },
    getWorkPoints() {
      return new Promise((resolve, reject) => {
        if (this.expeditor && typeof this.expeditor == "object") {
          axios
            .get(
              API_BASE_URL +
                "/work_points?id=" +
                this.expeditor.representative_company_id,
              { headers: this.header }
            )
            .then(({ data }) => {
              this.expeditor_working_points = data.data;
              if (
                this.expeditor_working_points &&
                this.expeditor_working_points.length == 0 &&
                this.expeditor.company_address &&
                this.expeditor.company_address.length > 0
              ) {
                this.saveDefaultWorkPoint(
                  this.expeditor.representative_company_id,
                  this.expeditor.name,
                  this.expeditor_address,
                  this.expeditor.company.email,
                  this.expeditor.company.phone,
                  this.expeditor.company.gsm,
                  this.expeditor.company.fax
                );
                // this.saveDefaultWorkPoint(this.expeditor.representative_company_id,this.expeditor.name, null, this.expeditor.company.email, this.expeditor.company.phone, this.expeditor.company.gsm, this.expeditor.company.fax );
              }
              resolve();
            })
            .catch(function (error) {
              console.log("an error occured " + error);
              reject();
            })
            .finally(() => {});
        }
      });
    },
    saveDefaultWorkPoint(
      representative_company_id,
      name,
      address,
      email,
      phone,
      gsm,
      fax
    ) {
      let data = {
        name: name,
        address: address ?? this.expeditor.company_address[0].address,
        company_id: representative_company_id,
        email: email ?? null,
        phone: phone ?? null,
        gsm: gsm ?? null,
        fax: fax ?? null,
        add_address: 1,
      };

      axios
        .post(API_BASE_URL + "/work_points", data, { headers: this.header })
        .then((data) => {
          this.expeditor_billing_working_point_id = data.data.data;
        })
        .catch(() => {})
        .finally(() => {});
    },
    updateExpeditorAddress(data) {
      this.expeditor_address = data;
    },
    updateTransporterAddress(data) {
      this.transporter_address = data;
    },
    // Note: In Process (En Cours) (when the transporter is selected); Finished (Terminé) when CMR is added); Billed (Facturé) (when billed) and Litige like is now
    changeExpeditionStatusOnAddingTransporter() {
      if (
        this.expeditionStatus &&
        typeof this.expeditionStatus == "object" &&
        this.expeditionStatus.key == "new"
      ) {
        this.expeditionStatuses.forEach((status) => {
          if (status.key == "in_process") {
            this.expeditionStatus = status;
          }
        });
      }
    },
    cmraddedChangeExpeditionStatus() {
      if (this.expeditionStatus && typeof this.expeditionStatus == "object") {
        if (
          this.expeditionStatus.key == "new" ||
          this.expeditionStatus.key == "in_process"
        ) {
          this.expeditionStatus = this.getExpeditionStatusFromKey("finished");
          this.saveExpedition(false, false);
        }
      }
    },
    getExpeditionStatusFromKey(key) {
      let expeditionStatus = null;
      this.expeditionStatuses.forEach((element) => {
        if (element.key == key) {
          expeditionStatus = element;
        }
      });
      return expeditionStatus;
    },
    fetchClientBalance(client_id) {
      axios
        .get(
          API_BASE_URL +
            "/clients/" +
            client_id +
            "/pending/bill?return_total=1",
          { headers: this.header }
        )
        .then(({ data }) => {
          this.customer_balance = this.fixDecimal(data.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    activateExpeditionEmailDialog() {
      this.expeditionEmailDialog = false;

      setTimeout(() => {
        this.expeditionEmailDialog = true;
      }, 50);
    },
    checkDischargeStillAvailable() {
      // TODO: If no dischargings available then disable button
      // let chargingArticlesAndQuantities = [];
      // this.chargings.forEach((addedCharging) => {
      //     if(addedCharging.selectedArticle.id == this.selectedArticle.id && this.selectedItem.srno != addedCharging.srno){
      //         totalAlreadyAddedCharging = parseInt(addedCharging) + parseInt(addedCharging.palets);
      //     }
      // })
      return true;
    },
    async getMemberTypes() {
      await this.getCodes("member_types", "member_types").then((data) => {
        this.memberTypes = data.filter((element) => {
          return element.key != "not_specified";
        });
        this.selectDefaultMemberType();
      });
      this.selectDefaultMemberType();
    },
    selectDefaultMemberType() {
      let foundDefaultMember = null;
      this.memberTypes.forEach((element) => {
        if (element.key === "unknown_position") {
          foundDefaultMember = element;
        }
      });
      if (foundDefaultMember) {
        this.memberType = foundDefaultMember;
      } else {
        this.memberTypes.map((element) => {
          if (element.key !== "unknown_position") {
            this.memberType = element;
          }
        });
      }
    },
    getInterventions(interventions) {
      let shortInterventions = "";
      for (let index = 0; index < interventions.length; index++) {
        if (
          interventions[index] &&
          interventions[index].user &&
          interventions[index].user.short_name
        ) {
          shortInterventions += interventions[index].user.short_name + " / ";
        }
      }
      return shortInterventions.slice(0, -2);
    },
    resetExpeditions() {
      (this.open_mode = "add"),
        (this.passedData = null),
        (this.truck_registration_number = null),
        (this.trolly_registration_number = null),
        (this.attachmentDialog = false),
        (this.expedition_identifier = null),
        (this.interventions = null),
        (this.menu_creation_date = false),
        (this.creation_date = null),
        (this.reference = null),
        (this.created_date = null),
        (this.selectedTransporter = null),
        (this.selectedTransporterMember = null),
        (this.selectedTransporterMembers = []),
        (this.search_transporter = null),
        (this.isLoadingSearchTransporter = false),
        (this.transporters = []),
        (this.transporter_price = null),
        (this.expeditionStatus = null),
        (this.note = null),
        (this.expeditors = []),
        (this.expeditor = null),
        (this.expeditor_member_name = null),
        (this.search_client = null),
        (this.expeditor_tva = null),
        (this.expeditor_address = null),
        (this.expeditor_denomination = null),
        (this.memberType = null),
        (this.memberPhone = null),
        (this.memberEmail = null),
        (this.chargings = []),
        (this.isChargingLoading = false),
        (this.totalChargingItems = null),
        (this.expeditionVehicleType = null),
        (this.expeditionVehicleBodyType = null),
        (this.expeditionVehicleBodyCharacteristicsType = null),
        (this.our_price = null),
        (this.dischargings = []),
        (this.totalDischargingItems = null),
        (this.isDisChargingLoading = false),
        (this.showChargingDialogue = false),
        (this.showDisChargingDialogue = false),
        (this.language = null),
        (this.isEditingAllowed = false),
        (this.search_member = null),
        (this.chargingCordinates = []),
        (this.dischargingCordinates = []),
        (this.mapCordinates = [[5.951828, 49.603575]]),
        (this.selectedItem = null),
        (this.selectedExpedition = null),
        (this.attachmentsLoadedFromDialog = []),
        (this.showLoadingModel = false),
        (this.confirmationDialog = false),
        (this.confirmationDialogConfirmationText = null),
        (this.confirmationDialogFalseText = null),
        (this.confirmationDialogTrueText = null),
        (this.confirmationDialogButtonClickedIs = null),
        (this.confirmationDialogOperation = null),
        (this.confirmationDialogData = null),
        (this.seconduser = null);
    },
    archivePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "expeditions.text_messages.archive_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("vehicles.Cancel");
      this.confirmationDialogTrueText = this.$t(
        "nav_buttons.expedition_archive"
      );
      this.confirmationDialogOperation = "archive";
      this.confirmationDialog = true;
    },
    deleteDisChargingAfterConfirmation(data, index) {
      this.confirmationDialogData = { data: data, index: index };
      this.confirmationDialogConfirmationText = this.$t(
        "expeditions.text_messages.delete_discharging_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t("attachment.delete");
      this.confirmationDialogOperation = "delete_discharging";
      this.confirmationDialog = true;
    },
    deleteChargingAfterConfirmation(data, index) {
      this.confirmationDialogData = { data: data, index: index };
      this.confirmationDialogConfirmationText = this.$t(
        "expeditions.text_messages.delete_charging_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t("attachment.delete");
      this.confirmationDialogOperation = "delete_charging";
      this.confirmationDialog = true;
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    researchTransporterWithTvaAndSetAgainClient() {
      return new Promise((resolve, reject) => {
        if (this.transporter_tva) {
          axios
            .get(
              API_BASE_URL +
                "/clients/search?companiesOnly=true&searchRegNumber=false" +
                (this.transporter_tva ? "&tva=" + this.transporter_tva : ""),
              { headers: this.header }
            )
            .then(({ data }) => {
              let clientsdata = data.data;
              if (clientsdata.length > 0) {
                clientsdata.forEach((element) => {
                  element.clientname =
                    element.name + " " + (element.first_name ?? "");
                });
                this.transporters = [clientsdata[0]];
                this.selectedTransporter = clientsdata[0];
                resolve();
              }
              reject();
            })
            .catch((err) => {
              console.log(err);
              reject();
            });
        }
      });
    },
    async researchClientWithTvaAndSetAgainClient() {
      return new Promise((resolve, reject) => {
        if (this.expeditor_tva) {
          axios
            .get(
              API_BASE_URL +
                "/clients/search?companiesOnly=true&searchRegNumber=true" +
                (this.expeditor_tva ? "&tva=" + this.expeditor_tva : ""),
              { headers: this.header }
            )
            .then(({ data }) => {
              let clientsdata = data.data;
              if (clientsdata.length > 0) {
                clientsdata.forEach((element) => {
                  element.clientname =
                    element.name + " " + (element.first_name ?? "");
                });
                this.expeditors = [clientsdata[0]];
                this.expeditor = clientsdata[0];
                resolve();
              }
              reject();
            })
            .catch((err) => {
              console.log(err);
              reject();
            });
        }
      });
    },
    async setExpeditorDetailFromTvaData(data) {
      await this.researchClientWithTvaAndSetAgainClient().finally(() => {
        if (data) {
          if (
            !this.expeditor_address &&
            data.address &&
            data.address !== "---"
          ) {
            this.expeditor_address = "AUTOSELECT_ADDRESS_FIRST" + data?.address;
          }
          if (!this.expeditor && data.name && data.name !== "---") {
            this.expeditor = data?.name;
          }
        }
      });
    },
    async setTransporterNameFromTvaData(data) {
      await this.researchTransporterWithTvaAndSetAgainClient().finally(() => {
        if (data) {
          if (!this.selectedTransporter && data?.name && data?.name !== "---") {
            this.selectedTransporter = data?.name;
          }
        }
      });
    },
    attachmentsLoaded(data) {
      this.attachmentsLoadedFromDialog = data;
    },
    showAttachmentDialog() {
      this.attachmentDialog = true;
    },
    fetchExpeditionData() {
      if (this.$store.state.editId) {
        return new Promise((resolve) => {
          axios
            .get(API_BASE_URL + "/expeditions/" + this.$store.state.editId, {
              headers: this.header,
            })
            .then(({ data }) => {
              this.selectedExpedition = data.data;
              this.$store.commit(
                "setSelectedExpedition",
                this.selectedExpedition
              );

              this.expeditor = data.data.expeditor;
              this.expeditor_member_name = data.data.expeditor_contact;
              if (
                data.data.transporter &&
                typeof data.data.transporter == "object"
              ) {
                this.transporters = [data.data.transporter];
                this.selectedTransporter = data.data.transporter;
                this.selectedTransporterMembers = [
                  data.data?.transporter_contact,
                ];
                this.selectedTransporterMember = data.data?.transporter_contact;
              }

              this.transporter_price = data.data.transport_price;
              this.our_price = data.data.expeditor_price;

              this.expeditionStatus = data.data.status;
              this.reference = data.data.reference;
              this.created_date = (data.data.created_date ?? "").substr(0, 10);
              this.document_number = data.data.document_number;

              this.creation_date = data.data.created_at;
              this.expedition_identifier = data.data.identifier;

              this.note = data.data.note;

              this.expeditionVehicleType = data.data.proposed_vehicle_types.map(
                (element) => {
                  return element.vehicle_type_id;
                }
              );
              this.expeditionVehicleBodyType =
                data.data.proposed_vehicle_body_types.map((element) => {
                  return element.vehicle_body_type_id;
                });
              this.expeditionVehicleBodyCharacteristicsType =
                data.data.proposed_characteristics.map((element) => {
                  return element.expedition_characteristic_type_id;
                });
              this.truck_registration_number =
                data.data.truck_registration_number;
              this.trolly_registration_number =
                data.data.trolly_registration_number;
              this.expeditor_billing_working_point_id =
                data.data.expeditorBillingWorkingPoint;
              this.expense_id = data.data.expense_id;
              this.seconduser = data.data.second_user_id;
              // this.our_price
              this.makeChargingsOnEdit(data.data.chargings);
              this.makeDischargingsOnEdit(data.data.dischargings);
              resolve(data.data);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    },
    makeSaveExpeditionData() {
      let transporter_id = this.selectedTransporter
        ? typeof this.selectedTransporter == "object"
          ? this.selectedTransporter.representative_company_id ??
            this.selectedTransporter.id
          : null
        : null;
      return {
        expeditor_billing_working_point_id: this
          .expeditor_billing_working_point_id
          ? typeof this.expeditor_billing_working_point_id == "object"
            ? this.expeditor_billing_working_point_id.id
            : null
          : null,
        expedition_main_address_id: this.expeditor_address
          ? typeof this.expeditor_address == "object"
            ? this.expeditor_address.id
            : this.expeditor_address
          : null,
        reference: this.reference,
        second_user_id:
          this.seconduser && typeof this.seconduser == "object"
            ? this.seconduser.id
            : this.seconduser ?? null,
        note: this.note,
        expeditor: this.expeditor.id,
        expeditor_payment_terms:
          this.expeditor && this.expeditor.id
            ? this.expeditor_payment_terms
            : 0,
        transporter_payment_terms:
          this.selectedTransporter && this.selectedTransporter.id
            ? this.transporter_payment_terms
            : 0,
        expeditor_address: this.expeditor_address,
        expeditor_contact: this.expeditor_member_name
          ? this.expeditor_member_name.id
          : null,
        transporter_id: transporter_id,
        transporter_is_blocked: transporter_id
          ? this.is_transporter_blocked
          : false,
        transporter_contact_id: this.selectedTransporterMember
          ? this.selectedTransporterMember.id
          : null,
        status_id: this.expeditionStatus
          ? typeof this.expeditionStatus == "object"
            ? this.expeditionStatus.id
            : this.expeditionStatus
          : null,
        expense_id: this.expense_id,
        // "order_id"                          => this.order_id,
        expeditor_price: this.our_price,
        created_date: this.created_date,
        transport_price: this.transporter_price,
        transport: {
          vehicle_types: this.expeditionVehicleType,
          vehicle_body_types: this.expeditionVehicleBodyType,
          vehicle_body_char_types:
            this.expeditionVehicleBodyCharacteristicsType,
          vehicle_client_price: this.our_price,
          truck_registration_number: this.truck_registration_number ?? null,
          trolly_registration_number: this.trolly_registration_number ?? null,
        },
        chargings: this.makeChargings(),
        dischargings: this.makeDischargings(),
      };
    },

    makeDischargings() {
      let dischargings = [];
      this.dischargings.forEach((discharging) => {
        dischargings.push({
          address: discharging.address,
          note: discharging.note,
          is_appointment: discharging.is_appointment ?? false,
          from_date: discharging.search_from_date,
          to_date: discharging.search_to_date,
          from_time: discharging.search_from_time,
          to_time: discharging.search_to_time,
          quantity: discharging.palets,
          tons: discharging.tons,
          meter: discharging.floor_meter,
          cubs: discharging.meter_cubs,
          height: discharging.meter_height,
          length: discharging.meter_length,
          width: discharging.meter_width,
          article_id:
            discharging.selectedArticle &&
            typeof discharging.selectedArticle == "object"
              ? discharging.selectedArticle.id
              : null,
          reference: discharging.reference,
          srno: discharging.srno,
        });
      });
      return dischargings;
    },
    makeDischargingsOnEdit(dischargingData) {
      let dischargings = [];
      let srno = 1;
      dischargingData.forEach((discharging) => {
        dischargings.push({
          address: discharging.address,
          note: discharging.note ?? "",
          is_appointment: discharging.is_appointment_required,
          search_from_date: discharging.charging_from_date,
          search_to_date: discharging.charging_to_date,
          search_from_time: discharging.charging_from_time,
          search_to_time: discharging.charging_to_time,
          tons: discharging.weight,
          floor_meter: discharging.loading_meters,
          meter_cubs: discharging.volume,
          meter_height: discharging.height,
          meter_length: discharging.length,
          meter_width: discharging.width,
          palets: discharging.quantity,
          selectedArticle: discharging.article,
          reference: discharging.reference,
          srno: srno++,
        });
      });
      this.dischargings = dischargings;
    },
    makeChargings() {
      let chargings = [];
      this.chargings.forEach((charging) => {
        chargings.push({
          address: charging.address,
          note: charging.note,
          is_appointment: charging.is_appointment,
          share_real_time_location: charging.share_real_time_location,
          expedition_merchandise_type: charging.expeditionMerchandiseType
            ? typeof charging.expeditionMerchandiseType == "object"
              ? charging.expeditionMerchandiseType.id
              : charging.expeditionMerchandiseType
            : null,
          loading_equipment_type: charging.loadingEquipmentType
            ? typeof charging.loadingEquipmentType == "object"
              ? charging.loadingEquipmentType.id
              : charging.loadingEquipmentType
            : null,
          quantity: charging.quantity,
          good_price: charging.good_price,
          tons: charging.tons,
          article_id:
            charging.selectedArticle &&
            typeof charging.selectedArticle == "object"
              ? charging.selectedArticle.id
              : null,
          from_date: charging.search_from_date,
          to_date: charging.search_to_date,
          from_time: charging.search_from_time,
          to_time: charging.search_to_time,
          // selectedFamily: charging.familyArticle,
          meter: charging.floor_meter,
          cubs: charging.meter_cubs,
          height: charging.meter_height,
          length: charging.meter_length,
          width: charging.meter_width,
          is_stackable: charging.is_stackable,
          exchange_charging_support: charging.exchange_charging_support,
          reference: charging.reference,
          srno: charging.srno,
        });
      });
      return chargings;
    },
    makeChargingsOnEdit(chargingsData) {
      let chargings = [];
      let srno = 1;
      chargingsData.forEach((charging) => {
        chargings.push({
          address: charging.address,
          note: charging.note,
          search_from_date: charging.charging_from_date,
          search_to_date: charging.charging_to_date,
          search_from_time: charging.charging_from_time,
          search_to_time: charging.charging_to_time,
          is_appointment: charging.is_appointment_required,
          share_real_time_location: charging.is_share_location,
          expeditionMerchandiseType: charging.merchandise_type_id
            ? typeof charging.merchandise_type == "object"
              ? charging.merchandise_type
              : charging.merchandise_type_id
            : null,
          loadingEquipmentType: charging.loading_equipment_type_id
            ? typeof charging.loading_equipment == "object"
              ? charging.loading_equipment
              : charging.loading_equipment_type_id
            : null,
          quantity: charging.quantity,
          good_price: charging.price,
          tons: charging.weight,
          article_id:
            charging.article && typeof charging.article == "object"
              ? charging.article.id
              : null,
          // selectedFamily: charging.familyArticle,
          selectedArticle: charging.article,
          floor_meter: charging.loading_meters,
          meter_cubs: charging.volume,
          meter_height: charging.height,
          meter_length: charging.length,
          meter_width: charging.width,
          is_stackable: charging.is_stackable,
          exchange_charging_support: charging.is_exchange_charging_support,
          reference: charging.reference,
          srno: srno++,
        });
      });
      this.chargings = chargings;
    },
    async saveTransporterFirstAndThenTriggerBackSaveMemberDialog() {
      if (typeof this.selectedTransporter == "string") {
        await this.saveTransporter();
        this.$refs.transporter_member.editMember();
      }
    },
    // async saveExpeditorFirstAndThenTriggerBackSaveMemberDialog(){
    //     if(typeof this.expeditor == 'string'){
    //         await this.saveExpeditor();
    //         this.$refs.expeditor_member.editMember();
    //     }
    // },
    async checkAndSaveClientAndItsMember() {
      // This Method is for save client and member
      if (this.expeditor) {
        if (
          (typeof this.expeditor == "object" &&
            (this.expeditor.company.tva != this.expeditor_tva ||
              this.expeditor.company.denomination_id !=
                this.expeditor_denomination.id)) ||
          typeof this.expeditor == "string"
        ) {
          await this.saveExpeditor();
        }
      }
      if (this.expeditor_member_name) {
        if (
          typeof this.expeditor_member_name == "string" ||
          (typeof this.expeditor_member_name == "object" &&
            !this.expeditor_member_name.id)
        ) {
          await this.saveExpeditorMember();
        }
      }
    },
    async checkAndSaveTransporterMember() {
      // This Method is for save client and member
      if (this.selectedTransporterMember) {
        if (
          typeof this.selectedTransporterMember == "string" ||
          (typeof this.selectedTransporterMember == "object" &&
            !this.selectedTransporterMember.id)
        ) {
          await this.saveTransporterMember();
        }
      }
    },
    saveExpedition(redirectBack, showNotificationMessages) {
      return new Promise((resolve, reject) => {
        this.saveTransporter().then(async () => {
          await this.checkAndSaveTransporterMember();
          await this.checkAndSaveClientAndItsMember();
          this.$store.commit("setValidation", true); // enable validation
          if (this.validateSubmit()) {
            if (this.$store.state.editId) {
              this.updateData(showNotificationMessages)
                .then((data) => {
                  resolve(data.data);
                  if (redirectBack != false) {
                    this.$router.push("/expedition").catch(() => {});
                  }
                })
                .catch(() => {
                  reject();
                });
            } else {
              this.saveData(showNotificationMessages)
                .then((data) => {
                  resolve(data.data);
                  if (redirectBack != false) {
                    this.$router.push("/expedition").catch(() => {});
                  }
                })
                .catch(() => {
                  reject();
                });
            }
          }
        });
      });
    },
    saveData(showNotificationMessages) {
      const data = this.makeSaveExpeditionData();

      return new Promise((resolve, reject) => {
        axios
          .post(API_BASE_URL + "/expeditions", data, { headers: this.header })
          .then((data) => {
            if (showNotificationMessages !== false) {
              this.$toast.success(
                this.$t(
                  "expeditions.text_messages.expedition_created_successfully"
                )
              );
            }
            // this.$router.push('/expedition').catch(() => {});
            resolve(data);
          })
          .catch(() => {
            reject();
          })
          .finally(() => {});
      });
    },
    updateData(showNotificationMessages) {
      const data = this.makeSaveExpeditionData();

      return new Promise((resolve, reject) => {
        axios
          .put(
            API_BASE_URL + "/expeditions/" + this.$store.state.editId,
            data,
            { headers: this.header }
          )
          .then((data) => {
            if (showNotificationMessages !== false) {
              this.$toast.success(
                this.$t(
                  "expeditions.text_messages.expedition_updated_successfully"
                )
              );
            }
            // this.$router.push('/expedition').catch(() => {});
            resolve(data);
          })
          .catch(() => {
            reject();
          })
          .finally(() => {});
      });
    },
    expeditionArchive() {
      axios
        .delete(API_BASE_URL + "/expeditions/" + this.$store.state.editId, {
          headers: this.header,
        })
        .then(() => {
          this.$toast.success(
            this.$t(
              "expeditions.text_messages.expedition_archived_successfully"
            )
          );
          this.$router.push("/expedition").catch(() => {});
        })
        .catch(() => {})
        .finally(() => {});
    },
    addDocumentToExpedition() {
      //
    },
    async makeExpeditionExpensePopUp(donotRedirect = false) {
      if (this.selectedTransporter) {
        if (this.expense_id && donotRedirect == false) {
          await this.saveExpedition(false, false);
          this.$store.commit("seteditId", this.expense_id);
          this.$router.push("/expenses/edit").catch(() => {});
        } else if (!this.expense_id) {
          this.confirmationDialogConfirmationText = this.$t(
            "expeditions.text_messages.make_expense_popup"
          );
          this.confirmationDialogFalseText = this.$t("vehicles.Cancel");
          this.confirmationDialogTrueText = this.$t("nav_buttons.expenses");
          this.confirmationDialogOperation = "makeExpense";
          this.confirmationDialog = true;
        }
      }
    },
    makeExpeditionExpense() {
      this.showLoadingModel = true;
      let data = this.makeExpenseSubmitData();
      return new Promise((resolve, reject) => {
        axios
          .post(API_BASE_URL + "/expenses", data, { headers: this.header })
          .then(async (response) => {
            this.expense_id = response.data.data.id;
            await this.saveExpedition(false, false);
            this.$toast.success(
              this.$t(
                "expeditions.text_messages.expense_created_for_transporter_successfully"
              )
            );
          })
          .catch((error) => {
            let errors = error?.response?.data?.errors ?? null;
            this.$toast.error(errors && Array.isArray(errors) && errors[0]);
            reject();
            // this.$toast.error(error);
          })
          .finally(() => {
            this.showLoadingModel = false;
          });
      });
    },
    getTvas() {
      return new Promise((resolve) => {
        this.first_row_tva = this.$store.state.tvasByAuth;
        this.cached_first_row_tva = this.$store.state.tvasByAuth;
        resolve();
      });
    },
    setBillType() {
      let val = this.billTypes;
      if (val && val.length > 0) {
        val.forEach((element) => {
          if (element && element.key == "bill") {
            this.$store.commit("setBillType", element);
          }
        });
      }
    },
    addExpeditionCharging(expedition) {
      let text = "";
      expedition.chargings.forEach((charging) => {
        text +=
          " " +
          (charging.reference ? charging.reference + ";" : "") +
          " " +
          (expedition.identifier ?? "") +
          "<br>" +
          " " +
          (charging.address ? charging.address.human_readable_address : "") +
          " " +
          ("(" + charging.charging_from_date + ")") +
          " " +
          "<br>";
      });
      return text.toUpperCase();
    },
    addExpeditionDisCharging(expedition) {
      let text = "";
      expedition.dischargings.forEach((discharging) => {
        text +=
          " " +
          (discharging.reference ? discharging.reference + ";" : "") +
          " " +
          (discharging.address
            ? discharging.address.human_readable_address
            : "") +
          " " +
          ("(" + discharging.charging_from_date + ")") +
          " " +
          "<br>";
        // RefCharging; ExpeditionNumber
        // ChargingAddress (charging date)
        // DischargingAddress (discharging date)
      });
      return text.toUpperCase();
    },
    makeExpenseSubmitData() {
      let isCompany = !!this.selectedTransporter.representative_company_id;
      let topBankAccountSelected = this.$store.state.topBankAccount;

      let tva = this.vat;
      let unit_price = parseFloat(this.transporter_price ?? 0);
      let quantity = 1;
      let thtva = parseFloat(unit_price * quantity);
      let total = parseFloat(
        parseFloat(thtva) + parseFloat((tva * thtva) / 100)
      );
      this.setBillType();

      let expeditionIdentifier =
        "REF: " + this.selectedExpedition.reference + "<br>";
      let chargingText = this.addExpeditionCharging(this.selectedExpedition);
      let dischargingText = this.addExpeditionDisCharging(
        this.selectedExpedition
      );
      expeditionIdentifier =
        expeditionIdentifier + chargingText + "" + dischargingText;
      return {
        type: this.billType.id,
        bank_id: topBankAccountSelected
          ? topBankAccountSelected.id
          : this.defaultBankAccount
          ? this.defaultBankAccount.id
          : null,
        next_event_code: null,
        status: null,
        payment_method: null,
        rdv_date: null,
        reception_date: this.currentDate,
        due_date: this.generateDueDate(
          this.currentDate,
          this.selectedTransporter.payment_terms
        ),
        reference_client: null,
        note: null,
        client: {
          client_id: this.selectedTransporter.id,
          entityType: isCompany
            ? this.entityTypeSelectItems[1].id
            : this.entityTypeSelectItems[0].id,
          denomination:
            this.selectedTransporter && this.selectedTransporter.company
              ? this.selectedTransporter.company.denomination_id
              : null,
          familyClient: this.selectedTransporter.family_client_id,
          name: this.selectedTransporter.name,
          first_name: this.selectedTransporter.first_name,
          email: this.selectedTransporter.email,
          language: this.selectedTransporter.language
            ? this.selectedTransporter.language.id
            : this.selectedTransporter.language,
          fax: this.selectedTransporter.fax,
          gsm: this.selectedTransporter.gsm,
          phone: this.selectedTransporter.phone,
          note: this.selectedTransporter.note,
          tva:
            this.selectedTransporter && this.selectedTransporter.company
              ? this.selectedTransporter.company.tva
              : null,
          web: this.selectedTransporter.web,
        },
        article: [
          {
            article_id: this.chargingDischargigArticle.article_id,
            quantity: 1,
            red_percentage: 0,
            tarrif: 0,
            thtva: thtva,
            total: total,
            tva: tva,
            stock_property: null,
            unit_price: unit_price,
            minimum_sales_price: 0,
            rounded_unit_price: 0,
            text: expeditionIdentifier,
            row: 1,
            text_position: 0,
            depot_out_id: null,
            depot_in_id: null,
            supplier_id: null,
            group: null,
            selectedmu: this.defaultMuToSelect.id,
            currency_id: this.$store.state.topCurrencyType
              ? this.$store.state.topCurrencyType.id
              : this.defaultBankAccount
              ? this.defaultBankAccount.currency_id
              : this.defaultCurrencyId,
            expedition_id: this.selectedExpedition.id,
          },
        ],
      };
    },
    makeExpeditionBill() {
      this.saveExpedition(false, false)
        .then(async () => {
          this.showLoadingModel = true;
          await this.fetchExpeditionData();
          this.$store.commit("setSelectedExpeditionIdsForBilling", [
            this.selectedExpedition,
          ]);

          let order_id = this.selectedExpedition.order_id;
          if (order_id) {
            // Already Set: setSelectedExpeditionIdsForBilling using computed property
            this.$store.commit("seteditId", order_id);
            this.$router.push("/billing/edit");
          } else {
            // Already Set: setSelectedExpeditionIdsForBilling using computed property
            this.$router.push("/billing/add");
          }
        })
        .then(() => {
          this.showLoadingModel = false;
        });
    },
    duplicateExpeditionDialog() {
      this.duplicate_expedition_dialog = true;
    },
    cancelExpeditionDialog() {
      this.cancel_expedition_dialog = true;
    },
    cancelExpedition(note) {
      // Cancel Only If their is a note
      if (note) {
        this.note = (this.note ? this.note + "" : "") + note;
        this.showLoadingModel = true;
        this.saveExpedition(false, false).then(() => {
          this.showLoadingModel = false;
        });
      } else {
        this.expeditionStatus = this.oldExpeditionStatus;
      }
    },
    duplicateExpedition(duplicateCount) {
      if (duplicateCount && duplicateCount >= 1) {
        this.saveExpedition(false, false)
          .then(() => {
            return new Promise((resolve, reject) => {
              this.showLoadingModel = true;
              axios
                .post(
                  API_BASE_URL +
                    "/expeditions/" +
                    this.$store.state.editId +
                    "/duplicate",
                  { count: duplicateCount },
                  { headers: this.header }
                )
                .then(() => {
                  this.$store.commit("setValidation", false); // enable validation
                  // let duplicatedData = data.data;
                  // this.resetExpeditions();
                  // this.$store.commit("seteditId", duplicatedData.id);
                  // this.fetchExpeditionData();
                  this.$toast.success(
                    this.$t(
                      "expeditions.text_messages.expedition_duplicated_successfully"
                    )
                  );
                  this.$router.push("/expedition").catch(() => {});
                  resolve();
                })
                .catch(() => {
                  reject();
                })
                .finally(() => {
                  this.showLoadingModel = false;
                });
            });
          })
          .then(() => {});
      }
    },
    printExpeditionBill() {
      this.$store.commit("setValidation", true); // enable validation
      this.saveExpedition(false, false).then(() => {
        // const data = this.makeSaveExpeditionData();
        if (this.selectedExpedition) {
          let url =
            API_BASE_URL +
            "/expeditions/" +
            this.selectedExpedition.md5_id +
            "/print?document=expedition&lang=" +
            (localStorage.getItem("languageId") || "en") +
            "&user=" +
            localStorage.getItem("loginUserId");
          let fileName = this.selectedExpedition
            ? this.selectedExpedition?.identifier
            : "";
          this.setPreviewData(url, fileName, true, "application/pdf");
        } else {
          let url =
            API_BASE_URL +
            "/expeditions/" +
            this.selectedExpedition.md5_id +
            "/print?document=expedition&lang=" +
            (localStorage.getItem("languageId") || "en") +
            "&user=" +
            localStorage.getItem("loginUserId");
          let fileName = this.selectedExpedition
            ? this.selectedExpedition?.identifier
            : "";
          this.setPreviewData(url, fileName, true, "application/pdf");
        }
      });
    },
    validateSubmit() {
      // if ( this.chargings.length == 0) {
      //     this.$toast.error( this.$t("expeditions.text_messages.please_select_atleast_one_charging"));
      //     return false;
      // }
      if (this.$v.$invalid) {
        return false;
      }
      return true;
    },
    calculateTotalDistanceOfCharging() {
      let totalDistance = 0;
      if (this.chargingCordinates && this.chargingCordinates.length > 1) {
        let lastCoordinates = null;
        this.chargingCordinates.forEach((coordinate, index) => {
          if (index == 0) {
            lastCoordinates = coordinate;
            return;
          }
          if (lastCoordinates) {
            totalDistance += this.distanceInKmBetweenEarthCoordinates(
              lastCoordinates[1],
              lastCoordinates[0],
              coordinate[1],
              coordinate[0]
            );
            lastCoordinates = coordinate;
          }
        });
      }

      return totalDistance;
    },
    calculateTotalDistanceOfDisCharging() {
      let totalDistance = 0;
      if (this.dischargingCordinates && this.dischargingCordinates.length > 1) {
        let lastCoordinates = null;
        this.dischargingCordinates.forEach((coordinate, index) => {
          if (index == 0) {
            lastCoordinates = coordinate;
            return;
          }
          if (lastCoordinates) {
            totalDistance += this.distanceInKmBetweenEarthCoordinates(
              lastCoordinates[1],
              lastCoordinates[0],
              coordinate[1],
              coordinate[0]
            );
            lastCoordinates = coordinate;
          }
        });
      }

      return totalDistance;
    },
    deleteCharging(item, index) {
      // Before Delete Charging, Delete all related Dischargings
      let removeIndexesInDischarging = [];
      this.dischargings.forEach((discharging, index) => {
        if (
          typeof discharging.selectedArticle == "object" &&
          discharging.selectedArticle.id == item.selectedArticle.id
        ) {
          removeIndexesInDischarging.push(index);
        }
      });
      for (let i = removeIndexesInDischarging.length - 1; i >= 0; i--) {
        this.dischargings.splice(removeIndexesInDischarging[i], 1);
      }

      // Delete Charging Now
      this.chargings.splice(index, 1);
      this.fixSrNo();
    },
    moveToDownCharge(item, index) {
      this.chargings = this.array_move(this.chargings, index, index + 1);
      this.fixSrNo();
    },
    moveToUpCharge(item, index) {
      this.chargings = this.array_move(this.chargings, index, index - 1);
      this.fixSrNo();
    },
    deleteDisCharging(item, index) {
      this.dischargings.splice(index, 1);
      this.fixSrNo();
    },
    moveToDownDisCharge(item, index) {
      this.dischargings = this.array_move(this.dischargings, index, index + 1);
      this.fixSrNo();
    },
    moveToUpDisCharge(item, index) {
      this.dischargings = this.array_move(this.dischargings, index, index - 1);
      this.fixSrNo();
    },
    toggleElementById(index, state, append) {
      let node = document.getElementById(
        append + this.generateElementId(index)
      );

      if (node) {
        node.style.visibility = state;
      }

      let downNode = document.getElementById(
        append + "down_" + this.generateElementId(index)
      );

      if (downNode) {
        downNode.style.visibility = state;
      }
    },
    generateElementId(index) {
      return "expedition_" + index;
    },
    makeMapCordinates() {
      // let defaultCoordinates = [[ 6.017648,49.580230]];

      // return defaultCoordinates
      let allCoordinates = this.chargingCordinates.concat(
        this.dischargingCordinates
      );
      this.mapCordinates = allCoordinates;
      this.reloadMap();
    },
    isset(payload) {
      return payload != undefined && payload != null && payload != "";
    },
    removeLettersFromWordsMixedWithNumber(sentence) {
      return sentence
        .split(" ")
        .map((word) => (/\d/.test(word) ? word.replace(/[^0-9]/g, "") : word))
        .join(" ");
    },
    removeClientCompany() {
      this.expeditor = null;
      this.expeditors = [];
      this.expeditor_tva = null;
      this.expeditor_address = null;
      this.removeMember();
    },
    removeTransporter() {
      this.transporters = [];
      this.selectedTransporter = null;
      this.selectedTransporterMember = null;
      this.selectedTransporterMembers = [];
    },
    removeMember() {
      this.expeditor_member_name = null;
      this.memberEmail = null;
      this.memberPhone = null;
    },
    fetchTransporterListDebounced() {
      if (
        !this.search_transporter ||
        typeof this.search_transporter == "object"
      ) {
        return;
      }
      // This means it is in edit mode
      axios
        .get(
          API_BASE_URL +
            "/clients/search?companiesOnly=true&searchRegNumber=true&searchTva=true" +
            (this.search_transporter
              ? "&search=" + encodeURIComponent(this.search_transporter)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          data.data.forEach((element) => {
            element.clientname =
              element.name + " " + (element.first_name ?? "");
          });
          this.transporters = data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //
        });
    },
    fetchClientsListDebounced() {
      if (!this.search_client || typeof this.search_client == "object") {
        return;
      }
      // This means it is in edit mode
      axios
        .get(
          API_BASE_URL +
            "/clients/search?companiesOnly=true&searchRegNumber=true&searchTva=true" +
            (this.search_client
              ? "&search=" + encodeURIComponent(this.search_client)
              : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          data.data.forEach((element) => {
            element.clientname =
              element.name + " " + (element.first_name ?? "");
          });
          this.expeditors = data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //
        });
    },
    updateChargingPagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    saveExpeditor() {
      return new Promise((resolve) => {
        if (typeof this.expeditor != "object") {
          // create client
          let data = {
            name: this.expeditor,
            denomination_id:
              typeof this.expeditor_denomination == "object"
                ? this.expeditor_denomination.id
                : this.expeditor_denomination,
            tva: this.expeditor_tva,
          };

          axios
            .post(API_BASE_URL + "/clients", data, { headers: this.header })
            .then(({ data }) => {
              this.expeditor = data.data;
              // this.$toast.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'));
              resolve(data.data);
            })
            .catch(() => {
              resolve("error");
            })
            .finally(() => {
              resolve("error");
            });
        } else {
          // update Client
          let data = {
            name: this.expeditor.name,
            denomination_id:
              typeof this.expeditor_denomination == "object"
                ? this.expeditor_denomination.id
                : this.expeditor_denomination,
            tva: this.expeditor_tva,
          };

          axios
            .put(API_BASE_URL + "/clients/" + this.expeditor.id, data, {
              headers: this.header,
            })
            .then(({ data }) => {
              this.expeditor = data.data;
              // this.$toast.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'));
              resolve(data.data);
            })
            .catch(() => {})
            .finally(() => {
              resolve();
            });
        }
      });
    },
    saveExpeditorMember() {
      let representative_company_id = this.expeditor.representative_company_id;
      return new Promise((resolve) => {
        if (!this.expeditor_member_name) {
          return resolve();
        }
        if (
          typeof this.expeditor_member_name != "object" ||
          (typeof this.expeditor_member_name == "object" &&
            !this.expeditor_member_name.id)
        ) {
          // create client
          let data =
            typeof this.expeditor_member_name != "object"
              ? {
                  name: this.expeditor_member_name,
                  type_id:
                    typeof this.memberType == "object"
                      ? this.memberType.id
                      : this.memberType,
                  language_id:
                    typeof this.expeditor.language == "object"
                      ? this.expeditor.language.id
                      : this.expeditor.language ?? null,
                }
              : this.expeditor_member_name;

          // Update Representative Company Id
          data.representative_company_id = representative_company_id;

          axios
            .post(API_BASE_URL + "/clients", data, { headers: this.header })
            .then(({ data }) => {
              this.expeditor_member_name = data.data;
              // this.$toast.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'));
              resolve(data.data);
            })
            .catch(() => {
              resolve("error");
            })
            .finally(() => {
              resolve("error");
            });
        } else {
          // update Member
          // let data = {
          //     name: this.expeditor_member_name.name,
          //     representative_company_id: representative_company_id,
          //     type_id: typeof this.memberType == 'object' ? this.memberType.id : this.memberType,
          //     language_id: typeof this.expeditor.language == 'object' ? this.expeditor.language.id : (this.expeditor.language ?? null),
          // }

          axios
            .put(
              API_BASE_URL + "/clients/" + this.expeditor_member_name.id,
              this.expeditor_member_name,
              { headers: this.header }
            )
            .then(({ data }) => {
              this.expeditor_member_name = data.data;
              // this.$toast.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'));
              resolve(data.data);
            })
            .catch(() => {})
            .finally(() => {
              resolve();
            });
        }
      });
    },
    saveTransporterMember() {
      let representative_company_id =
        this.selectedTransporter.representative_company_id;
      return new Promise((resolve) => {
        if (!this.selectedTransporterMember) {
          return resolve();
        }
        if (
          typeof this.selectedTransporterMember != "object" ||
          (typeof this.selectedTransporterMember == "object" &&
            !this.selectedTransporterMember.id)
        ) {
          // create client
          let data =
            typeof this.selectedTransporterMember != "object"
              ? {
                  name: this.selectedTransporterMember,
                  type_id:
                    typeof this.memberType == "object"
                      ? this.memberType.id
                      : this.memberType,
                  language_id:
                    typeof this.selectedTransporter == "object"
                      ? this.selectedTransporter.language.id
                      : null,
                }
              : this.selectedTransporterMember;

          // Update Representative Company Id
          data.representative_company_id = representative_company_id;

          axios
            .post(API_BASE_URL + "/clients", data, { headers: this.header })
            .then(({ data }) => {
              this.selectedTransporterMember = data.data;
              // this.$toast.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'));
              resolve(data.data);
            })
            .catch(() => {
              resolve("error");
            })
            .finally(() => {
              resolve("error");
            });
        } else {
          // update Member
          let data = {
            name: this.selectedTransporterMember.name,
            representative_company_id: representative_company_id,
            type_id:
              typeof this.memberType == "object"
                ? this.memberType.id
                : this.memberType,
            language_id:
              typeof this.selectedTransporter == "object"
                ? this.selectedTransporter.language.id
                : null,
          };

          axios
            .put(
              API_BASE_URL + "/clients/" + this.selectedTransporterMember.id,
              data,
              { headers: this.header }
            )
            .then(({ data }) => {
              this.selectedTransporterMember = data.data;
              // this.$toast.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'));
              resolve(data.data);
            })
            .catch(() => {})
            .finally(() => {
              resolve();
            });
        }
      });
    },
    saveTransporter() {
      return new Promise((resolve) => {
        if (this.selectedTransporter) {
          if (
            (typeof this.selectedTransporter == "object" &&
              (this.selectedTransporter.company.tva != this.transporter_tva ||
                this.selectedTransporter.company.denomination_id !=
                  this.transporter_denomination.id)) ||
            typeof this.selectedTransporter == "string"
          ) {
            if (this.selectedTransporter) {
              if (
                this.selectedTransporter &&
                typeof this.selectedTransporter != "object"
              ) {
                // create client
                let data = {
                  name: this.selectedTransporter,
                  denomination_id:
                    typeof this.transporter_denomination == "object"
                      ? this.expeditor_denomination.id
                      : this.transporter_denomination,
                  tva: this.transporter_tva,
                };

                axios
                  .post(API_BASE_URL + "/clients", data, {
                    headers: this.header,
                  })
                  .then(({ data }) => {
                    this.selectedTransporter = data.data;
                    // this.$toast.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'));
                    resolve(data.data);
                  })
                  .catch(() => {
                    resolve("error");
                  })
                  .finally(() => {
                    resolve("error");
                  });
              } else if (
                this.selectedTransporter &&
                typeof this.selectedTransporter == "object"
              ) {
                // update Client
                let data = {
                  name: this.selectedTransporter.name,
                  denomination_id:
                    typeof this.transporter_denomination == "object"
                      ? this.transporter_denomination.id
                      : this.transporter_denomination,
                  tva: this.transporter_tva,
                };

                axios
                  .put(
                    API_BASE_URL + "/clients/" + this.selectedTransporter.id,
                    data,
                    { headers: this.header }
                  )
                  .then(({ data }) => {
                    this.selectedTransporter = data.data;
                    // this.$toast.success(this.$t('vehicles.clients_model.text_messages.client_added_successfully'));
                    resolve(data.data);
                  })
                  .catch(() => {})
                  .finally(() => {
                    resolve();
                  });
              }
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    addChargingItem() {
      this.selectedItem = null;
      this.showLoadingModel = true;
      this.saveExpeditor().then(() => {
        this.saveExpeditorMember().then(() => {
          this.saveTransporter().then(async () => {
            await this.checkAndSaveTransporterMember();
            this.showLoadingModel = false;
            this.passedData = {
              chargings: this.chargings,
            };
            this.open_mode = "add";
            this.showChargingDialogue = true;
          });
        });
      });
    },
    addDischargingItem() {
      this.selectedItem = null;
      this.saveExpeditor().then(() => {
        this.saveExpeditorMember().then(() => {
          this.open_mode = "add";
          this.passedData = null;
          this.showDisChargingDialogue = true;
        });
      });
    },
    editChargingItem(item) {
      this.selectedItem = item;
      this.open_mode = "edit";
      this.passedData = null;
      this.showChargingDialogue = true;
    },
    editDischargingItem(item) {
      this.selectedItem = item;
      this.open_mode = "edit";
      this.passedData = null;
      this.showDisChargingDialogue = true;
    },
    reOpenDialog() {
      this.selectedItem = null;
      if (this.showChargingDialogue) {
        this.open_mode = "add";
        this.passedData = null;
        this.showChargingDialogue = false;
        this.showDisChargingDialogue = false;
        this.$nextTick(() => {
          this.showChargingDialogue = true;
        });
      } else if (this.showDisChargingDialogue) {
        this.open_mode = "add";
        this.passedData = null;
        this.showChargingDialogue = false;
        this.showDisChargingDialogue = false;
        this.$nextTick(() => {
          this.showDisChargingDialogue = true;
        });
      }
    },
    changeDialogue() {
      this.selectedItem = null;
      if (this.showChargingDialogue) {
        this.open_mode = "add";
        this.passedData = null;
        this.showChargingDialogue = false;
        this.showDisChargingDialogue = true;
      } else if (this.showDisChargingDialogue) {
        this.open_mode = "add";
        this.passedData = null;
        this.showChargingDialogue = true;
        this.showDisChargingDialogue = false;
      }
    },
    addedDischarging(data) {
      this.selectedItem = null;
      this.dischargings.push(data);
      this.totalDischargingItems = this.dischargings.length;
      this.fixSrNo();
    },
    addedCharging(data) {
      this.selectedItem = null;
      this.chargings.push(data);
      this.totalChargingItems = this.chargings.length;
      this.fixSrNo();
    },
    updatedCharging(item) {
      this.chargings[this.selectedItem.srno - 1] = item;
      this.selectedItem = null;
      this.fixSrNo();

      this.chargings.push({});
      this.$nextTick(() => {
        this.chargings.pop();
      });
    },
    updatedDischarging(item) {
      this.dischargings[this.selectedItem.srno - 1] = item;
      this.selectedItem = null;
      this.fixSrNo();

      this.dischargings.push({});
      this.$nextTick(() => {
        this.dischargings.pop();
      });
    },
    fixSrNo() {
      let srno = 1;
      this.chargings.forEach((data) => {
        data.srno = srno++;
      });

      let srnoofdis = 1;
      this.dischargings.forEach((data) => {
        data.srno = srnoofdis++;
      });
    },
    getLanguages() {
      if (this.languages.length == 0) {
        this.getDataWithCache("/languages", "languages", "languages");
      }
    },
    getExpeditionStatuses() {
      if (this.expeditionStatuses.length == 0) {
        this.getCodes("expedition_statuses", "expedition_statuses").then(
          (data) => {
            this.expeditionStatuses = data;
            if (!this.$store.state.editId) {
              this.expeditionStatus = this.expeditionStatuses[0];
            }
          }
        );
      }
    },
    getExpeditionVehicleTypes() {
      if (this.expeditionVehicleTypes.length == 0) {
        this.getCodes(
          "expedition_vehicles_types",
          "expedition_vehicles_types"
        ).then((data) => {
          this.expeditionVehicleTypes = data;
        });
      }
    },
    getexpeditionMerchandiseTypes() {
      if (this.expeditionMerchandiseTypes.length == 0) {
        this.getCodes("merchandise_types", "merchandise_types").then((data) => {
          this.expeditionMerchandiseTypes = data;
        });
      }
    },
    getVehicleBodyTypes() {
      if (this.expeditionVehicleBodyTypes.length == 0) {
        this.getCodes("vehicle_body_types", "vehicle_body_types").then(
          (data) => {
            this.expeditionVehicleBodyTypes = data;
          }
        );
      }
    },
    getVehicleBodyCharacteristicsTypes() {
      if (this.expeditionVehicleBodyCharacteristicsTypes.length == 0) {
        this.getCodes(
          "vehicle_body_characteristics_types",
          "vehicle_body_characteristics_types"
        ).then((data) => {
          this.expeditionVehicleBodyCharacteristicsTypes = data;
        });
      }
    },
    getExpeditionExchangeContainersTypes() {
      if (this.expeditionExchangeContainersTypes.length == 0) {
        this.getCodes(
          "expedition_exchange_containers_types",
          "expedition_exchange_containers_types"
        ).then((data) => {
          this.expeditionExchangeContainersTypes = data;
        });
      }
    },
    getExpeditionEquipmentTypes() {
      if (this.expeditionEquipmentTypes.length == 0) {
        this.getCodes(
          "expedition_equipment_types",
          "expedition_equipment_types"
        ).then((data) => {
          this.expeditionEquipmentTypes = data;
        });
      }
    },
    getExpeditionLoadSecuringTypes() {
      if (this.expeditionLoadSecuringTypes.length == 0) {
        this.getCodes(
          "expedition_load_securing_types",
          "expedition_load_securing_types"
        ).then((data) => {
          this.expeditionLoadSecuringTypes = data;
        });
      }
    },
    getExpeditionCertificatesTypes() {
      if (this.expeditionCertificatesTypes.length == 0) {
        this.getCodes(
          "expedition_certificates_types",
          "expedition_certificates_types"
        ).then((data) => {
          this.expeditionCertificatesTypes = data;
        });
      }
    },
    getMerchandiseTypes() {
      if (this.merchandiseTypes.length == 0) {
        this.getCodes("merchandise_types", "merchandise_types").then((data) => {
          this.merchandiseTypes = data;
        });
      }
    },
    getLoadingEquipmentTypes() {
      if (this.loadingEquipmentTypes.length == 0) {
        this.getCodes(
          "loading_equipment_types",
          "loading_equipment_types"
        ).then((data) => {
          this.loadingEquipmentTypes = data;
        });
      }
    },
    // 77.216721,28.644800
    async reloadMap() {
      if (this.mapCordinates && this.mapCordinates.length > 0) {
        const mapOptions = {
          container: "map",
          style:
            "https://api.maptiler.com/maps/streets-v2/style.json?key=f4IJi1mRlspltChrcUM3",
          center:
            this.mapCordinates && this.mapCordinates.length > 0
              ? this.mapCordinates[0]
              : [],
          zoom: 6,
        };
        setTimeout(() => {
          let map = new Map(mapOptions);
          this.mapCordinates.forEach((element) => {
            new Marker({ color: "#FF0000" }).setLngLat(element).addTo(map);
          });

          map.on("load", () => {
            map.addSource("charging", {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: this.chargingCordinates,
                },
              },
            });

            map.addSource("discharging", {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: this.dischargingCordinates,
                },
              },
            });
            map.addLayer({
              id: "charging",
              type: "line",
              source: "charging",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#2196f3",
                "line-width": 6,
              },
            });
            map.addLayer({
              id: "discharging",
              type: "line",
              source: "discharging",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#4caf50",
                "line-width": 8,
              },
            });

            map.fitBounds(this.mapCordinates);

            // Connect Charging and Discharging Coordinates
            if (
              this.chargings &&
              this.chargingCordinates.length > 0 &&
              this.dischargingCordinates &&
              this.dischargingCordinates.length > 0
            ) {
              map.addSource("chargingAndDischargingConnector", {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: [
                      this.chargingCordinates[
                        this.chargingCordinates.length - 1
                      ],
                      this.dischargingCordinates[
                        this.dischargingCordinates.length - 1
                      ],
                    ],
                  },
                },
              });

              map.addLayer({
                id: "chargingAndDischargingConnector",
                type: "line",
                source: "chargingAndDischargingConnector",
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": "#f48225",
                  "line-width": 8,
                },
              });
            }
          });
        }, 500);
      }
    },
  },
};
</script>
<style>
.data-table-header {
  background: #f7f7f7 !important;
}
.data-table-footer {
  background: #f7f7f7 !important;
  width: 100%;
}

/* .customerbalance .v-input__control .v-input__slot  {
    background-color: #ffc4c4 !important;
} */
.customerbalance .v-input__control .v-input__slot .v-text-field__slot input {
  color: red !important;
}

/* Blocked */
.checkboxblocked .v-input__control .v-input__slot label {
  color: red !important;
}

.work_point_name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.work_point_detail {
  font-size: 10px;
  margin: 0px !important;
  white-space: nowrap;
  overflow: hidden;
}
</style>
