<template>
  <v-dialog
    max-width="1200"
    :value="value"
    v-if="isset(order)"
    persistent
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title> {{ $t("order_email_history") }} </v-card-title>
      <v-card-text>
        <template v-if="isLoaded">
          <EmailTable
            :items="
              order.email_history ? order.email_history : history ? history : []
            "
          />
        </template>
        <template v-else>
          <v-container class="d-flex justify-center align-center">
            <v-progress-circular
              indeterminate
              size="20"
              width="2"
              color="primary"
            ></v-progress-circular>
          </v-container>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close">
          <CloseButton />
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";
import EmailTable from "@/components/Emails/EmailTable.vue";
import CloseButton from "@/components/Buttons/CloseButton.vue";
export default {
  name: "OrderEmailHistory",
  components: { CloseButton, EmailTable },
  props: {
    order: {
      type: [Object],
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      history: [],
      isLoaded: true,
      axiosHeaders: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    };
  },
  watch: {
    async value(val) {
      if (!val || !this.order) {
        return null;
      }

      if (this.isset(this.order)) {
        //
      }
    },
    order: {
      handler: function (val) {
        if (val && typeof val == "number") {
          this.getEmailHistory(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    getEmailHistory(billId) {
      return new Promise((resolve) => {
        this.history = [];
        this.isLoaded = false;
        return axios
          .get(API_BASE_URL + "/billings/" + billId, {
            headers: this.axiosHeaders,
          })
          .then((response) => {
            if (response.data.data && response.data.data.email_history) {
              this.history = response.data.data.email_history;
            }
            resolve(response.data.data);
          })
          .finally(() => {
            this.isLoaded = true;
          });
      });
    },
  },
};
</script>

<style scoped></style>
