<template>
  <div style="display: flex">
    <v-combobox
      class="to-upper"
      :autocomplete="autocompleteValueLocal"
      v-model="working_point"
      :items="working_points"
      item-value="id"
      item-text="name"
      :label="$t(label)"
      v-on:keydown="handleInput($event, 'alphanumeric', 50)"
      :search-input.sync="search_working_point"
      no-filter
      v-on:keydown.enter.prevent=""
      return-object
      :outlined="is_boxes"
      :dense="is_boxes"
      :style="showDenomination ? 'width: 80%; max-width: 80%;' : ''"
      :error-messages="errorMessages"
      :class="blocked ? 'blockedworking_point' : ''"
      :disabled="disabled"
    >
      <template #label>
        <span v-if="required" class="red--text"><strong>* </strong></span>
        {{ $t(label) }}
      </template>
      <template slot="append">
        <v-icon @click="editWorkingPoint()">mdi-pencil</v-icon>
        <v-icon v-if="working_point || value" @click="removeWorkingPoint()"
          >mdi-close</v-icon
        >
        <!-- <v-icon v-if="!working_point && typeof working_point" @click="addWorkingPoint()">mdi-plus</v-icon> -->
      </template>
      <template slot="item" slot-scope="{ item }">
        <v-list-item-content>
          <p class="working_point_name">
            {{ item.name }} {{ item.first_name }}
          </p>
          <p class="working_point_detail">
            <template v-if="item.email"> - {{ item.email }} </template>
            <template v-if="item.phone"> - {{ item.phone }} </template>
            <template v-if="item.address">
              <br />
              <span class="address_text">{{
                computeAddressLabel(item.address)
              }}</span>
            </template>
          </p>
        </v-list-item-content>
      </template>

      <template slot="selection" slot-scope="{ item }">
        <v-list-item-content :class="blocked ? 'blockedtext' : ''">
          <template v-if="item && typeof item == 'object'">
            <p class="working_point_name">
              <strong>{{ item.name }} {{ item.first_name }}</strong>
            </p>
            <p class="working_point_detail">
              <template v-if="item.email"> - {{ item.email }} </template>
              <template v-if="item.address">
                <br />
                <span class="address_text">{{
                  computeAddressLabel(item.address)
                }}</span>
              </template>
            </p>
          </template>
          <template v-if="item && typeof item == 'string'">
            {{ item.toUpperCase() }}
          </template>
        </v-list-item-content>
      </template>
    </v-combobox>
    <v-select
      v-if="showDenomination"
      class="to-upper mt-1"
      v-model="denomination"
      :items="denominations"
      item-value="id"
      item-text="text"
      v-on:keydown.enter.prevent=""
      :outlined="is_boxes"
      hide-details
      return-object
      style="width: 20%; max-width: 20%"
    ></v-select>

    <WorkingPointModel
      v-if="openEditWorkingPointModel"
      @buttonClicked="getAndSelectWorkingPoint"
      :workpointprop="working_point"
      :client="client"
      :label="label"
      :showDialog.sync="openEditWorkingPointModel"
      :addAddress="addAddress"
    ></WorkingPointModel>
  </div>
</template>
<script>
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
import WorkingPointModel from "@/components/WorkingPoint/WorkingPointModel.vue";
export default {
  model: { prop: "value", event: "input" },
  components: {
    WorkingPointModel,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    label: {
      type: String,
      default: "billings.comp_name",
    },
    tva: {
      type: String,
      default: null,
    },
    address: {
      type: Object,
      default: null,
    },
    selectedDenomination: {
      type: [Object, String, Number],
      default: null,
    },
    showDenomination: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array],
      default: Array,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    client: {
      type: Object,
      default: null,
    },
    addAddress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      working_point: null,
      working_points: [],
      search_working_point: null,
      denominations: [],
      denomination: null,
      openEditWorkingPointModel: false,
    };
  },
  mounted() {
    // this.getCompanyDenominations();
  },
  methods: {
    addWorkingPoint() {
      this.value = null;
      this.openEditWorkingPointModel = true;
    },
    editWorkingPoint() {
      if (
        (typeof this.working_point == "string" || !this.working_point) &&
        this.search_working_point
      ) {
        this.working_point = this.search_working_point;
      }
      this.openEditWorkingPointModel = true;
    },
    removeWorkingPoint() {
      this.search_working_point = null;
      this.working_point = null;
      this.value = null;
      this.$emit("cleared", true);
    },
    getWorkingPoints(client) {
      return new Promise((resolve, reject) => {
        if (
          client &&
          typeof client == "object" &&
          client.representative_company_id
        ) {
          axios
            .get(
              API_BASE_URL +
                "/work_points?id=" +
                client.representative_company_id,
              { headers: this.header }
            )
            .then(({ data }) => {
              this.working_points = data.data;
              resolve();
            })
            .catch(function (error) {
              console.log("an error occured " + error);
              reject();
            })
            .finally(() => {});
        }
      });
    },
    getAndSelectWorkingPoint(toSelectWorkingPoint) {
      if (toSelectWorkingPoint) {
        this.working_points = [];
        this.getWorkingPoints(this.client).then(() => {
          this.$nextTick(() => {
            this.working_points.forEach((element) => {
              if (element.id == toSelectWorkingPoint.id) {
                this.working_point = element;
              }
            });
          });
        });
      }
    },
  },
  watch: {
    client(val) {
      if (val) {
        this.getWorkingPoints(val);
      } else {
        this.working_point = null;
        this.working_points = [];
      }
    },
    working_point(val) {
      this.$emit("input", val);
    },
    value(val) {
      if (val && typeof val == "object") {
        this.working_points = [val];
        this.working_point = val;
      } else if (val && typeof val == "string") {
        this.search_working_point = val;
      }
    },
  },
};
</script>
<style>
.working_point_name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.working_point_detail {
  font-size: 10px;
  margin: 0px !important;
  white-space: nowrap;
  overflow: hidden;
}
.working_point_more_detail {
  font-size: 10px;
}
.v-list-item__content {
  padding: 0px;
  margin: 0px;
}
.v-list-item__content ~ input {
  width: 2px !important;
  min-width: 2px !important;
  max-width: 2px !important;
}
.blockedworking_point .v-input__control .v-input__slot {
  color: #ffc4c4 !important;
}
.blockedtext {
  color: #ff5252 !important;
}
/* .blockedworking_point .v-input__control .v-input__slot .v-select__slot label{
    background-color: #ffc4c4 !important;
} */
</style>
