<template>
  <v-row class="mt-3">
    <v-col>
      <v-row class="mr-2">
        <v-col></v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-0 ma-0" cols="12" sm="3">
          <v-text-field
            class="mr-3 to-upper"
            :label="$t('search')"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <v-col class="pa-0 ma-0" cols="12" sm="1">
          <v-btn
            class="mt-3 mr-2"
            color="primary"
            @click="openEditClientWorkingPointModel = true"
          >
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-data-table
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :items="working_points"
          :options="options"
          :server-items-length="totalItems"
          :loading="isLoading"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :items-per-page="itemsPerPage"
          item-key="id"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          @click:row="editClientWorkingPoint(item)"
          :height="'20vh'"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          mustSort
          :key="forceReloadComponentKey"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td @click="editClientWorkingPoint(item)">
                <template v-if="item.name">
                  {{ item.name }}
                </template>
              </td>
              <td @click="editClientWorkingPoint(item)">
                <span>{{ formatToPhoneNumber(item.phone) }}</span>
              </td>
              <td @click="editClientWorkingPoint(item)">
                <span>{{ formatToPhoneNumber(item.gsm) }}</span>
              </td>
              <td @click="editClientWorkingPoint(item)">
                <span>{{ formatToPhoneNumber(item.fax) }}</span>
              </td>
              <td @click="editClientWorkingPoint(item)">
                <span>{{ item.email }}</span>
              </td>
              <td @click="editClientWorkingPoint(item)">
                <span>{{ computeAddressLabel(item.address) }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
      <working-point-model
        v-if="openEditClientWorkingPointModel"
        @buttonClicked="updatedWorkPoint"
        :workpointprop="selectedWorkPoint"
        :client="client"
        :showDialog.sync="openEditClientWorkingPointModel"
      ></working-point-model>
    </v-col>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

import WorkingPointModel from "@/components/WorkingPoint/WorkingPointModel.vue";

export default {
  name: "ClientsMembers",
  components: {
    "working-point-model": WorkingPointModel,
  },
  props: {
    client: [Object],
  },
  data() {
    return {
      openEditClientWorkingPointModel: false,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      isLoading: true,
      totalItems: 0,
      lastPage: 1,
      sortBy: "name",
      sortDesc: true,
      sortOrd: "ASC",
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      working_points: [],
      search: "",
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      selectedWorkPoint: null,
    };
  },
  mounted() {
    this.header = { Authorization: "Bearer " + this.token };
    this.fetchData();
  },
  created() {
    this.$watch(
      "search",
      _.debounce(() => {
        this.isLoading = true;
        this.fetchData();
      }, 500)
    );
  },
  methods: {
    updatedWorkPoint() {
      this.fetchData();
      this.openEditClientWorkingPointModel = false;
      this.selectedWorkPoint = null;
    },
    editClientWorkingPoint(item) {
      this.selectedWorkPoint = item;
      this.openEditClientWorkingPointModel = true;
    },
    addClientMember() {
      this.selectedWorkPoint = null;
      this.openEditClientWorkingPointModel = true;
    },
    fetchData(currentPage = null) {
      this.resetDataTable(currentPage);
      this.isLoading = true;
      axios
        .get(
          API_BASE_URL +
            "/work_points?page=" +
            this.page +
            "&items_per_page=" +
            this.itemsPerPage +
            (this.client
              ? "&id=" + this.client.representative_company_id
              : "") +
            (this.search ? "&search=" + encodeURIComponent(this.search) : "") +
            ("&sortby=" + this.sortBy + "&sorttype=" + this.sortOrd),
          {
            headers: this.header,
          }
        )
        .then(({ data }) => {
          this.working_points = data.data;
          this.totalItems = this.working_points.length;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    editItem(item) {
      this.$store.commit("seteditId", item.id);
      this.$router.push("/client/edit").catch(() => {});
    },
    deleteItem() {
      console.log("Delete Item");
    },

    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }

      this.fetchData();
    },
    onSearch() {
      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$t("clients.name"), value: "department" },
        { text: this.$t("clients.phone"), value: "phone" },
        { text: this.$t("clients.gsm"), value: "gsm" },
        { text: this.$t("clients.fax"), value: "fax" },
        { text: this.$t("clients.email"), value: "email" },
        { text: this.$t("clients.tabs.addresses"), value: "address" },
      ];
    },
  },
};
</script>

<style scoped></style>
